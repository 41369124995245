import { useEffect } from "react";

export const useHandleClickOutsideHighlight = ({targetFragmentKey}) => {

      
    const handleClickOutside = (event) => {
      const elems = document.getElementsByClassName(targetFragmentKey);
      if (Array.from(elems).every(el => !el.contains(event.target))) {
        for (let d of elems) {
          d.classList.remove("-active-");
        }
      }
    }
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside) //its very important for this to be mousedown. 'click' acts unpredictable
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])
  }