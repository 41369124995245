import React, { useEffect } from "react";
import { replaceEntityData } from "../../../utils/richInputUtils";

import { imageUploader } from "../../../utils/general";

import PDFEmbedV2 from "./PDFEmbedV2";


import { colors, Loader, useBannerContext } from 'oolib' 
const { greyColor10 } = colors; 
 
const DEPRECATED_KPPDFDisplay = (props) => {

  const {SET_ALERT_BANNER} = useBannerContext()

  useEffect(() => {
    if (props.formData) {
      imageUploader(props.formData, { type: "files", SET_ALERT_BANNER }, (status, resData) => {
        
        replaceEntityData(
          props.editor,
          props.entityKey,
          { 
            src: resData[0].mediaLink, 
            fileName: resData[0].originalFilename || resData[0].mediaLink, 
            loading: false 
          } //replace the image blocks entity data with actual image data
        );
      });
    }
  }, []);

  

  return (
    <div className={`kp-pdf-display ${props.className}`}>
      {props.loading ? (
        <div
          style={{
            height: "10rem",
            borderRadius: "2px",
            backgroundColor: greyColor10,
          }}
        >
          <Loader text="Loading PDF..." />
        </div>
      ) : (
        <PDFEmbedV2
          filePath={props.src}
          fileID={props.id}
          fileName={props.fileName || 'pdfDocOnOKE.pdf'}
        />
      )}
    </div>
  );
};

export default DEPRECATED_KPPDFDisplay;
