import axios from "axios";
import { useMutation } from "react-query";
import { queryClient } from "../..";
import { useBannerContext } from "oolib";
import { useHistory } from "react-router-dom";
import { UIContent } from "../../UIContent";

const useSendBackUnderModeration = () => {
  const { SET_ALERT_BANNER } = useBannerContext();
  const history = useHistory();

  return useMutation(
    ({ contentType, id, reviewerNotes }) =>
      axios.post(
        `/api/moderation2/sendBackUnderModeration/${contentType}/${id}`,
        { reviewerNotes }
      ),
    {
      onSuccess: (undefined,{contentType,ogDocId}) => {
        history.push("/my-dashboard/ModQueue")

        queryClient.invalidateQueries("reviewDashboard");
        queryClient.removeQueries(["ContributionCloneForModeration", "moderate", contentType, ogDocId])
       

        SET_ALERT_BANNER({
          color: "green",
          msg: UIContent.sendBackSuccess,
          timeOut: 4000,
        });
      },
      onError: () => {
        SET_ALERT_BANNER({
          color: "red",
          msg:UIContent.sendBackError ,
          timeOut: 4000,
        });
      },
    }
  );
};

const useDeleteUnderModeration = () => {
  const { SET_ALERT_BANNER } = useBannerContext();
  const history = useHistory();
  return useMutation(
    ({ contentType, id }) =>
      axios.post(`/api/moderation2/deleteByModerator/${contentType}/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("reviewDashboard");

        history.push("/my-dashboard/ModQueue")
        SET_ALERT_BANNER({
          color: "green",
          msg: UIContent.deleteContentSuccess,
          timeOut: 4000,
        });
      },
      onError: () => {

        SET_ALERT_BANNER({
          color: "red",
          msg: UIContent.deleteContentError,
          timeOut: 4000,
        });
      },
    }
  );
};

const useMoveUnderModerationToAwaiting = () => {
  const { SET_ALERT_BANNER } = useBannerContext();
  const history = useHistory();

  return useMutation(
    ({ contentType, modCloneId }) =>
      axios.post(
        `/api/moderation2/backToAwaitingModeration/${contentType}/${modCloneId}`
      ),
    {
      onSuccess: (undefined,{contentType,ogDocId}) => {
        history.push("/my-dashboard/ModQueue")
        queryClient.invalidateQueries("reviewDashboard");
        queryClient.removeQueries(["ContributionCloneForModeration", "moderate", contentType, ogDocId])
       

        SET_ALERT_BANNER({
          color: "green",
          msg: UIContent.moveToAwaitingSuccess,
          timeOut: 4000,
        });
      },
      onError: () => {
        SET_ALERT_BANNER({
          color: "red",
          msg: UIContent.moveToAwaitingError,
          timeOut: 4000,
        });
      },
    }
  );
};

export {
  useSendBackUnderModeration,
  useDeleteUnderModeration,
  useMoveUnderModerationToAwaiting,
};
