import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

export const useGetPlaces = (searchString) => {
	return useQuery(['PlacesAPI', searchString], () =>
		axios
			.get(`/api/placesapi?search_string=${searchString}`)
			.then((res) => res.data.predictions || []),
	)
}

export const useGetQueryData = (configKey) => {
	const queryClient = useQueryClient()
	return queryClient.getQueryData(configKey)
}

export const useGetQueryState = (configKey) => {
	const queryClient = useQueryClient()
	return queryClient.getQueryState(configKey)
}
