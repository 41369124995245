import React, { useEffect, useState } from "react";

import {Loader} from 'oolib';


//socion pdawidget
const WQMCourse = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  const loadScript = (source) => {
    return new Promise((resolve, reject)=> {
      const script = document.createElement("script");
      for (let prop in source) {
        script[prop] = source[prop];
      }

      script.onload =()=> resolve(script);
      script.onError =()=> reject(`file from ${script.src} failed to load!`);

      document.body.append(script);
    })
  }

  const socionScripts = [
    {
      src: "https://widget.socion.io/assets/socionWidgetApp-es2015.js",
    },
    {
      src: "https://widget.socion.io/assets/socionWidgetApp-es5.js",
      nomodule: true,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/crypto-js.min.js",
      type: "text/javascript",
    },
  ];

  useEffect(()=> {
    const scriptsExist = socionScripts.some(script=> !!document.querySelector(`script[src="${script.src}"]`))
    if (scriptsExist) {
      setIsLoading(false);
      return
    };
    //else then it is first time mounting

    Promise
    .all(socionScripts.map(script=> loadScript(script)))
    .then(()=> {
      console.log('socion resource is loaded');
      setIsLoading(false);
    })
    .catch((err)=> console.log(err))
  }, [])

  useEffect(()=> { //styles fix (removing effect of styles overriding ours)
    const rootElment = document.documentElement;
    rootElment.style.setProperty("--font-family-montserrat", "Montserrat");
    rootElment.style.fontSize = '10px';

    return ()=> rootElment.style.setProperty("--font-family-montserrat", 5)
    // numbers aren't a valid value, so the browser will neglect it and  take other set font families (ours)
  }, [])


  return(

    <div className='WQMCourse'>
      {isLoading?
        <Loader M isBlock/>
        :
        <pda-data-widget/>
      } 
    </div>
  );
}

export default WQMCourse;
