import { useEffect, useState } from "react";



export const useHandleTableColWidths = ({
  tableConfig
}) => {
  const setInitConf = (tableConfig) => {
    let initConf = {};
    const { colWidths, defaultColWidth } = tableConfig;
    /**
     * the way this works is :
     * if explicit width is defined, it is applied in pixels.
     *
     * for the remaining columns, that do not have an explicit width,
     * they use a default width of 300px
    */

    tableConfig.cellData.forEach(
      (d, i) =>
        (initConf[d.colId] = colWidths && colWidths[d.colId]
            ? colWidths[d.colId] + 'px'
            : defaultColWidth ? defaultColWidth+'px' : '300px'
        )
    );

    return initConf;
  };

  const [colWidthConfig, setColWidthConfig] = useState(
    setInitConf(tableConfig)
  );
  
  useEffect(() => {
    setColWidthConfig(setInitConf(tableConfig));
  }, [tableConfig]);

  return { colWidthConfig, setColWidthConfig };
};
