import { transition, useHandleClickOutside } from "oolib";
import React, { useState } from "react";
import styled from "styled-components";
import { useRef } from "react";
import { ToolbarButton } from "../ToolbarButton";

const StyledChildButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
`;

const StyledChildButtonsMask = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  overflow: hidden;
  width: 100%;
  height: ${({ openHeight, open }) => (open ? openHeight + "px" : 0)};
  ${transition("height")}
`;



export const ToolbarDropdownButton = ({
  isActive,
  genChildButtons,
  noOfChildButtons,
  icon
}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  useHandleClickOutside(buttonRef, setOpen); //allows for the open dropdown to shut
  return (
    <div ref={buttonRef} style={{ position: "relative" }}>
      <StyledChildButtonsMask
        open={open}
        openHeight={noOfChildButtons * 40 /*cuz 40 is the height of each btn*/}
      >
        <StyledChildButtonsWrapper>{genChildButtons(setOpen)}</StyledChildButtonsWrapper>
      </StyledChildButtonsMask>
      <ToolbarButton
        isActive={isActive}
        onClick={() => setOpen((prev) => !prev)}
        icon={icon}
      />
    </div>
  );
};
