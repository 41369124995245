import React from "react";
import { SANS_2, colors } from "oolib";
import { createContentCardDate } from "../../../../../../../../../../utils/momentManipulate";
import { __GetContentTypeConfigNew } from "../../../../../../../../../../utils/getters/gettersV2";

const { greyColor70 } = colors;

export const NotifMsgRenderer = ({ activityType, dateCreated, content }) => {
  switch (activityType) {
    case "APPROVE":
      return (
        <div>
          {/* <SANS_2>{`Your Content Type: ${content.title} has been approved by the reviewer`}</SANS_2> */}
          <SANS_2>
            Your{" "}
            {
              __GetContentTypeConfigNew(content.contentType).general.content
                .singular
            }
            : <b className="semibold">{content.title}</b>, has been approved by
            the reviewer{" "}
          </SANS_2>
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );
    case "PUBLISH":
      return (
        <div>
          <SANS_2>
            You have been mentioned in a{" "}
            {
              __GetContentTypeConfigNew(content?.contentType)?.general?.content?.singular
            }
            : <b className="semibold">{content?.title}</b>, authored by{" "}{content?.moderatorName}
          </SANS_2>
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );
    case "MODERATE_APPROVE":
      return (
        <div>
          <SANS_2>
            Your{" "}
            {
              __GetContentTypeConfigNew(content.contentType).general.content
                .singular
            }
            : <b className="semibold">{content.title}</b>, has been reviewed and
            approved by {content.moderatorName}
          </SANS_2>
          {/* <SANS_2>{`Your Content Type: ${content.title} has been reviewed and published by ${content.moderatorName}`}</SANS_2> */}
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );
    case "TAKEN_UNDER_MODERATION":
      return (
        <div>
          <SANS_2>
            Your{" "}
            {
              __GetContentTypeConfigNew(content.contentType).general.content
                .singular
            }
            : <b className="semibold">{content.title}</b>, is currently under
            review by {content.moderatorName}
          </SANS_2>
          {/* <SANS_2>{`Your Content Type: ${content.title}, is currently under review by ${content.moderatorName}`}</SANS_2> */}
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );
    case "ADD_COMMENT":
      return (
        <div>
          <SANS_2>{`New comment on Content Type: ${content.title}`}</SANS_2>
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );
    case "MENTION_IN_COMMENT":
      return (
        <div>
          <SANS_2>
            You have been mentioned in a comment inside of a{" "}
            {
              __GetContentTypeConfigNew(content?.contentType)?.general?.content?.singular
            }
            : <b className="semibold">{content?.title}</b>
          </SANS_2>
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );
    case "ADD_REACTION":
    case "ADD_REACTION_LIKE": //purely for backwards compatibility
      return (
        <div>
          <SANS_2>{`Someone reacted to your ${
            __GetContentTypeConfigNew(content.contentType)?.general?.content
              ?.singular || content.contentType
          } : "${content.title}"`}</SANS_2>
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );
    case "DELETE":
      return (
        <div>
          {" "}
          <SANS_2>
            Your{" "}
            {
              __GetContentTypeConfigNew(content.contentType).general.content
                .singular
            }
            : <b className="semibold">{content.title}</b>, has been deleted by{" "}
            {content.moderatorName}
          </SANS_2>
          {/* <SANS_2>{`Your ${content.contentType}: ${content.title}, has been deleted ${content.moderatorName}`}</SANS_2> */}
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );
    case "SENT_BACK":
      return (
        <div>
          <SANS_2>
            Your{" "}
            {
              __GetContentTypeConfigNew(content.contentType).general.content
                .singular
            }
            : <b className="semibold">{content.title}</b>, has been sent back by{" "}
            {content.moderatorName}
          </SANS_2>
          {/* <SANS_2>{`Your Content Type: ${content.title}, has been sent back by ${content.moderatorName}`}</SANS_2> */}
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );
    case "MOVED_TO_AWAITING_REVIEW":
      return (
        <div>
          <SANS_2>
            Your{" "}
            {
              __GetContentTypeConfigNew(content.contentType).general.content
                .singular
            }
            : <b className="semibold">{content.title}</b>, has been moved to
            awaiting review by {content.moderatorName}
          </SANS_2>
          {/* <SANS_2>{`Your Content Type: ${content.title}, has been moved to awaiting review by ${content.moderatorName}`}</SANS_2> */}
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );
    case "CREATE_UPDATE":
      return (
        <div>
          {" "}
          <SANS_2>{`There is an update on your ${
            __GetContentTypeConfigNew(content.contentType).general.content
              .singular
          }`}</SANS_2>
          <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
            {createContentCardDate(dateCreated)}
          </SANS_2>
        </div>
      );

    default:
      return <div>New type of notif msg!</div>;
  }
};
