import { useState } from "react";
import { useDebounce } from "../../../../../../../../../utils/customHooks";
import { trackEventGA } from "../../../../../../../../../trackers/GA/trackEventGA";

export const useSearchInputText = () => {
  // 2 separate states to manage the same thing, since one is debounced and one is not.
  const [searchInputText, setSearchInputText] = useState(""); // this is being read by the TextInput comp
  const [searchInputTextDebounced, setSearchInputTextDebounced] = useState(""); // this is being read by the react-query hook
  const debounce = useDebounce();
  const onSearchInputChange = (id, value) => {
    setSearchInputText(value);
    debounce(() =>{ 
      trackEventGA("Search Flow","LinkEmbedModule Searched")
      setSearchInputTextDebounced(value)
    });
  };

  return ({
    searchInputText,
    searchInputTextDebounced,
    onSearchInputChange
  })
}
