import React from 'react'

const MaintenanceScreen = (props) => {
  const { platformConfigs } = props

  return (
    <div className="kp-container-fluid" style={{ textAlign: 'center' }}>
      <img
        style={{height: '5rem'}}
        src={platformConfigs.deployment._Logos?.logo}
        alt={platformConfigs.deployment._DeploymentDisplayName}
      />
      {platformConfigs.deployment._UnderMaintenence.title && (
        <h1 className="h1 semibold">
          {platformConfigs.deployment._UnderMaintenence.title}
        </h1>
      )}
      {platformConfigs.deployment._UnderMaintenence.desc && (
        <h3 className="h3 semibold">
          {platformConfigs.deployment._UnderMaintenence.desc}
        </h3>
      )}
    </div>
  )

  // go here : `}<a className='OKE-WarningScreen__link' href='#'>{'<PENDING PROD URL CONFIG>'}</a>
}

export default MaintenanceScreen
