import {
    TextInput,
    getBlockLabelProps
} from "oolib";
import React, { useState } from "react";
import { useGetDataQuery } from "../../../../utils/react-query-hooks/getData";




export function PrivateTagsInputSingle({ value, onChange }) {
  const props = arguments[0];
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [textInputState, setTextInputState] = useState("");
  const { data, status } = useGetDataQuery({
    contentTypes: ["colors"],
    findQuery: { "main.title": searchTerm },
    queryOptions: {
      enabled: !!searchTerm,
      onSuccess: (res) => {
        console.log({res})
      }
    },
    
  });

//   console.log({ data });

  return (
    <TextInput
      {...getBlockLabelProps(props)}
      value={textInputState}
      onChange={(k, v) => setTextInputState(v)}
      actionBtn={{
        text: "Search",
        onClick: () => setSearchTerm(textInputState),
      }}
    />
  );
}
