import axios from "axios";
import { useQuery } from "react-query";
import { serialize } from "../general";

export const useGetActivityAnalytics = ({ filters, populate }) => {
  let filtersQuery = filters ? `filters=${serialize(filters)}` : "";

  let populateQuery = populate ? `populate=${serialize(populate)}` : "";

  let and = filtersQuery && populateQuery ? "&" : "";

  return useQuery("activityAnalytics", () =>
    axios
      .get(`/api/activityAnalytics?${filtersQuery}${and}${populateQuery}`)
      .then((res) => res.data)
  );
};
