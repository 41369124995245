import { Loader, toArray } from 'oolib'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
	__CheckAccessToProfileType
} from '../../../utils/accessControl'
import { useGetQueryState } from '../../../utils/react-query-hooks/general'

const RouteAccessOneProfile = ({
	component: Component,
	ACTION,
	location,
	apiAccessCheck, //0 = name of apiAccessConfig key in backend ; 1 = paramString
	path,
	...rest
}) => {
	let { status, data: userData } = useGetQueryState('userData')

	const hasAccess = () => {
		let pathSplit = path.split('/')
		let paramIdx = pathSplit.findIndex(
			(d) => [":profileType"].indexOf(d) !== -1,
		)
	
		let profileType = location.pathname.split('/')[paramIdx]
	
		/** in grouped published listing, we need to extract each content type, and check access against them */
		let hasGroupKey = pathSplit.indexOf(':profileType') !== -1
		if (hasGroupKey) profileType = profileType.split('+')

		if (!userData?.user) {
			// not logged in
			return {
				has: toArray(profileType).some((c) =>
					__CheckAccessToProfileType(undefined, ACTION || 'READ', c),
				),
				redirectTo: {
					pathname: '/login',
					state: {
						from: location.pathname + location.search,
					},
				},
			}
		}

		// else is logged in, and either has access to this content type or not.
		// if has, then let them through.
		// if not then simply redir back to the page they were coming from.
		return {
			has: toArray(profileType).some((c) =>
				__CheckAccessToProfileType(userData?.user, ACTION || 'READ', c),
			),
			redirectTo: '/',
		}
	}

	return (
		<Route
			{...rest}
			path={path}
			render={(props) =>
				status === 'loading' ? (
					<Loader debug={'route access one content'} />
				) : !hasAccess().has ? (
					<Redirect to={hasAccess().redirectTo} />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

export default RouteAccessOneProfile
