import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from "lexical";
import { useLayoutEffect, useRef, useState } from "react";

/**
 * the way this works is:
 * 1// if editor is focussed, hasFocus = true (i.e toolbar is shown in the parent component)
 * 2// if editor is blurred, we check to see if the blur was caused by a click on the toolbar 
 * ------- if yes, then maintain hasFocus as true
 * ------- if no, then hasFocus = false
 * 3// if toolbar is blurred then, check to see if the blur was cause by a click either on the toolbar or on the contentEditable
 * ------- if yes, then maintain hasFocus as true
 * ------- if no, then hasFocus = false
 */
export const useEditorAndToolbarFocus = () => {
  // Commands are subscriptions so the default state is important!

  const [editor] = useLexicalComposerContext();
  const [hasFocus, setHasFocus] = useState(
    editor.getRootElement() === document.activeElement
  );
  const toolbarRef = useRef(null)
  
  useLayoutEffect(() => {
    setHasFocus(editor.getRootElement() === document.activeElement);
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setHasFocus(true);
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        (e) => {
          /**
           * when the editor is blurred, we check to see if the click that caused the blur
           * happened to be on the toobar. if it did, then DO NOT setFocus to false.
           * if it did, then setFocus to false
           */
         
          if(toolbarRef.current && !toolbarRef.current.contains(e.relatedTarget)){
            setHasFocus(false);
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);

  return ({
    hasFocus, 
    setHasFocus,
    toolbarRef,
    onToolbarBlur: e => {
      if(
        toolbarRef.current && !toolbarRef.current.contains(e.relatedTarget) &&
        !document.getElementById('contentEditableLex').contains(e.relatedTarget)
      ){
        setHasFocus(false);
      }
    }
  });
};
