import { ButtonGhost, colors, useHandleClickOutside } from "oolib";
import React, { useRef } from "react";
import styled from "styled-components";
import { checkIfSortPathsAreMatching } from "../../../../utils/tableSortUtils";

const StyledHeaderCellDropdown = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  z-index: 100;
  background-color: ${colors.white};
`

export const HeaderCellDropdown = ({  
  setShowHeaderCellDropdown, 
  onSortClick, 
  isBackendSort, 
  backendActiveSort, 
  showHeaderCellDropdown, 
  activeSorts
}) => {
  const dropdownRef = useRef(null)
  useHandleClickOutside(dropdownRef, setShowHeaderCellDropdown);

  let activeSortForDisplay;

  if (isBackendSort) {
    activeSortForDisplay = !checkIfSortPathsAreMatching(backendActiveSort.sort, showHeaderCellDropdown?.backendSortConfig.sort)
          ? undefined
          : Object.values(backendActiveSort.sort)[0] === 1 
            ? 'ascending'
            : 'descending' 
  } else {
    activeSortForDisplay = activeSorts[showHeaderCellDropdown?.colId] === "none" 
      ? undefined
      : activeSorts[showHeaderCellDropdown?.colId] === "a" 
        ? 'ascending' 
        : 'descending' 
  }

  return (
    <StyledHeaderCellDropdown ref={dropdownRef}>
      <ButtonGhost
        icon = 'ArrowUp'
        children = 'Sort Ascending'
        onClick={() => activeSortForDisplay !== 'ascending' && onSortClick('ascending')}
        active={activeSortForDisplay === 'ascending'}
        />
      <ButtonGhost
        icon = 'ArrowDown'
        children = 'Sort Descending'
        onClick={() => activeSortForDisplay !== 'descending' && onSortClick('descending')}
        active={activeSortForDisplay === 'descending'}
        />
    </StyledHeaderCellDropdown>
  )

}