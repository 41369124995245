import { SANS_2, toArray } from "oolib";
import React from "react";
import { _Locale } from "../../../../../locale/Locale";

const CommaSeparatedWords = ({ value, typoComp }) => {
  let comps = { SANS_2 };
  let Comp = comps[typoComp];
  let ary = toArray(value);
  let len = ary.length;
  return (
    <Comp>
      {ary.map((v, i) => (
        <span key={i}>{`${_Locale(v)}${i !== len - 1 ? "," : ""} `}</span>
      ))}
    </Comp>
  );
};

export default CommaSeparatedWords;
