/**
 *
 * @description this can be a very useful and reusable function
 * Its purpose is:
 * given a ListingWrapper comp OR a string name of a valid ListingWrapper
 * comp, it will return the final ListingWrapper component.
 *
 * If no ListingWrapper is defined / a string name of a Wrapper isnt
 * available in the ListingWrappersLookup, then whichever fallback wrapper
 * has been provided to the function is returned. If fallback wrapper is also
 * not there, then a basic 'div' wrapper is returned
 */

export const listingWrapperSelector = ({ ListingWrapper, FallbackWrapper }) => {
  if (!ListingWrapper) return FallbackWrapper || "div";
  if (typeof ListingWrapper === "string") {
    return ListingWrappersLookup[ListingWrapper] || FallbackWrapper || "div";
  }
  //else
  return ListingWrapper;
};

const ListingWrappersLookup = {
  div: 'div' //basically if you wanna render no wrapper..
};
