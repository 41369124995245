import styled from "styled-components";
import { colors } from "oolib";

const { greyColor5, white } = colors;


export const MobileFilterListStyled = styled.section`
  padding: 2rem 0;
`;

export const MobileFilterListItemStyled = styled.div`
  padding-bottom: 2rem;
`;

export const MobileFilterBottomHeaderStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  background-color: ${white};
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border: 1px solid ${greyColor5};
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

