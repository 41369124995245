import React, { useEffect, useRef } from "react";

import Lightbox from "./LightBox";

const AllGUIList = {
  LightBox: Lightbox,
};

export default function AnnotationGUI(props) {
  const {
    GUI,
    onCreate,
    onRemove,
    onEdit,
    data,
    position,
    editorWrapperRef,
    specialProps,
    onCancel,
  } = props;

  const containerRef = useRef();

  // handleOutside click
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      onCancel();
    }
  };

  const GUIComp = AllGUIList[GUI.comp];
  return (
    <div ref={containerRef}>
      <GUIComp
        onCreate={onCreate}
        onRemove={onRemove}
        onEdit={onEdit}
        data={data}
        config={GUI.config}
        position={position}
        editorWrapperRef={editorWrapperRef}
        specialProps={specialProps}
        onCancel={onCancel}
      />
    </div>
  );
}
