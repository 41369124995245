import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection } from "lexical";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { config } from "../config";

export const useGetActiveFormatInSelection = () => {
  const [editor] = useLexicalComposerContext();
  const [activeFormat, setActiveFormat] = useState(undefined);
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const _activeFormat = config
            .map(({ format }) => {
              return selection?.hasFormat(format) ? format : undefined;
            })
            .filter(Boolean);
          if (!isEqual(_activeFormat, activeFormat))
            setActiveFormat(_activeFormat); //basically only if a change has happened, then update state. optimization this is
        }
      });
    });
  }, [editor, activeFormat]);

  return activeFormat;
};
