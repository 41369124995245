import { colors, injectHttps, SANS_2, transition } from "oolib";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { usePlatformBuilderContext } from "../../../../../../../contexts/platformBuilderContext";
import { __CheckAccessViaConfig } from "../../../../../../../utils/accessControl";
import { useGetQueryData } from "../../../../../../../utils/react-query-hooks/general";
import { useGetDisplayForLandingPageLinks } from "../../../../../utils/useGetDisplayForLandingPageLinks";
import { useIsThisLinkActive } from "../../utils/useIsThisLinkActive";
const { none, greyColor5 } = colors;

const StyledLinkWrapper = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: ${({ isActive }) => (isActive ? greyColor5 : none)};
  ${transition("background-color")}
  &:hover {
    background-color: ${greyColor5};
  }
`;

const StyledInternalLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledExternalLink = styled.a`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const PRIMLink = ({
  id,
  to, // can be a location obj or a string
  display: _display,
  thisNavConfig, // needed specifically for the type = landingPage, wherein, we wanna make an api call to get the latest title and set that as the display
  accessControl,
  dontRender = false,
}) => {
  const { platformBuilderMode } = usePlatformBuilderContext();
  const { isThisLinkActive } = useIsThisLinkActive();
  const { user } = useGetQueryData("userData") || {};

  const { display, TextLoader } = useGetDisplayForLandingPageLinks({
    thisNavConfig,
    _display,
  });

  const genDisplay = () => (
    <StyledLinkWrapper isActive={isThisLinkActive({ to })} key={to}>
      {TextLoader ? <TextLoader /> : <SANS_2>{display}</SANS_2>}
    </StyledLinkWrapper>
  );

  if (dontRender === true) return null; // checkAccessAndGen(Content/Profile)PageLinkProps can send such a prop cuz of access control

  if (accessControl && !__CheckAccessViaConfig({ ...accessControl, user }))
    return null;

  let genInternalOrExternalLinkComp =
    to.substring(0, 1) === "/" ? "internal" : "external";

  return platformBuilderMode ? (
    genDisplay()
  ) : genInternalOrExternalLinkComp === "internal" ? (
    <StyledInternalLink
      id={id}
      to={to} // passing null/undefined to deactivate link causes issue, page breaks
      children={genDisplay()}
    />
  ) : (
    <StyledExternalLink
      id={id}
      href={injectHttps(to)}
      target="_blank"
      children={genDisplay()}
    />
  );
};

export const LoginLink = ({ to, display }) => {
  const { isThisLinkActive } = useIsThisLinkActive();

  return (
    <StyledLinkWrapper isActive={isThisLinkActive({ to })} key={to}>
      <StyledInternalLink to={to} children={<SANS_2>{display}</SANS_2>} />
    </StyledLinkWrapper>
  );
};
