import { cloneDeep } from "lodash";

export const carefullyMergeRawAnnosIntoSynthLex = ({ prevObj, newLexValue, thisBlockValuePath }) => {
  let newConsolidated = cloneDeep(prevObj) || {};
  const { annoData = {} } = newLexValue || {};
  
  /**
   * first remove all fragments from prev, that belong to this rte valuepath 
   * cuz in the next step we will refill the data with the fragments in this
   * latest updated value from this valuepath
   */
  Object.keys(newConsolidated).map((tagType) => {
    if (newConsolidated[tagType].data?.length > 0) {
      newConsolidated[tagType].data.forEach((tag, tagIdx) => {
        if (tag.fragments?.length > 0) {
          newConsolidated[tagType].data[tagIdx].fragments = newConsolidated[
            tagType
          ].data[tagIdx].fragments.filter(
            (frag) => frag.extractedFromValuePath !== thisBlockValuePath
          );
        }
      });
  }
  });


  //now inject this new data without a care into whatever is left of newConsolidated
  Object.keys(annoData).map((annoDataId) => {
    const { tags, fragment = {} } = annoData[annoDataId];
    const tagTypes = Object.keys(tags || {});
    if (tagTypes.length > 0) {
      tagTypes.forEach((tagType) => {
        const value = tags[tagType];
        
        if (newConsolidated[tagType]?.data?.length > 0) {
          value.data.forEach((d) => {
            const foundTagIdx = newConsolidated[tagType].data.findIndex(
              (dd) => dd._id === d._id
            );
            if (foundTagIdx !== -1) {
              const fragsAry =
                newConsolidated[tagType].data[foundTagIdx].fragments || [];
              if (fragsAry.length === 0) {
                newConsolidated[tagType].data[foundTagIdx].fragments = [
                  {
                    ...fragment,
                    annoDataId,
                    extractedFromValuePath: thisBlockValuePath,
                  },
                ];
              } else {
                newConsolidated[tagType].data[foundTagIdx].fragments.push({
                  ...fragment,
                  annoDataId,
                  extractedFromValuePath: thisBlockValuePath,
                });
              }
            } else {
              newConsolidated[tagType].data.push({
                ...d,
                fragments: [
                  {
                    ...fragment,
                    annoDataId,
                    extractedFromValuePath: thisBlockValuePath,
                  },
                ],
              });
            }
          });
        } else {
          newConsolidated[tagType] = {
            collectionId: value.collectionId,
            data: value.data.map((d) => ({
              ...d,
              fragments: [
                { ...fragment, annoDataId, extractedFromValuePath: thisBlockValuePath },
              ],
            })),
          };
        }
      });
    }
  });

  //remove the tags that have no fragments against them anymore
  Object.keys(newConsolidated).forEach(tagType => {
    newConsolidated[tagType].data.forEach((d, i) => {
      
      if (!d.fragments?.length) {
        newConsolidated[tagType].data.splice(i, 1);
      }
    });
  })
  

  return newConsolidated;
};
