import React from "react";
import { EmptyStates } from "oolib";
import { __GetContentTypeConfigNew } from "../../../../../utils/getters/gettersV2";

const EmptyStateListing = ({
  contentTypes,
  emptyPageConfigSearch,
  listingStyle,

}) => {
  const { emptyStatePreset, onClick: actionOnClick, searchTerm } =
    emptyPageConfigSearch || {};

  const contentTypesGeneral = contentTypes?.map(
    (contentType) => __GetContentTypeConfigNew(contentType).general
  );

  const allArePublishing = contentTypesGeneral?.every(
    (general) => general.segment === "publishing"
  );
  const allAreCollections = contentTypesGeneral?.every(
    (general) => general.segment === "collections"
  );
  
  const isSinglePublishing =
    allArePublishing && contentTypesGeneral.length === 1;
  const isMultiplePublishing =
    allArePublishing && contentTypesGeneral.length > 1;
  const isSingleCollections =
    allAreCollections && contentTypesGeneral.length === 1;

  let preset;

  switch (true) {
    case !!emptyPageConfigSearch:
      preset = emptyStatePreset;
      break;
    case listingStyle === "UserProfilesStyle":
      preset = "profileListing";
      break;
    case isSinglePublishing:
      preset = "singlePublishingListing"; //done
      break;
    case isMultiplePublishing:
      preset = "multiplePublishingListing";
      break;
    case isSingleCollections:
      preset = "singleCollectionsListing";
      break;
    default:
      preset = "multipleCollectionsListing";
      break;
  }

  const contentTypeTitle = (isSinglePublishing || isSingleCollections)
    ? contentTypesGeneral && contentTypesGeneral[0].content.title
    : '';

  const tags = contentTypesGeneral?.map((general) => general.content.title);

  return (
    <EmptyStates
      preset={preset}
      onClick={actionOnClick}
      contentTypeTitle={ searchTerm || contentTypeTitle}
      tags={tags}
    />
  );
};

export default EmptyStateListing;
