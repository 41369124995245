import { makeArrayFromLength, sortData } from "oolib";
import { propertyExists, segrigateDocs } from "../../../utils/general"
const colors = [ "#E7A04E", "#476AC0", "#DC7956", "#5399F2", "#58BE5B", "#E24F68", "#578F3F", "#C1C1C1" ]

export const getParalegalOptions = (data) => {
    let paralegals = data
      .map((d) => d.tags.paralegals?.data[0])
      .filter(Boolean);
    let paraSeg = segrigateDocs(paralegals, "_id");
    Object.values(paraSeg).map((d, i) => {
      let key = Object.keys(paraSeg)[i];
      paraSeg[key] = d[0];
    });
    let paraOps = Object.values(paraSeg).map((d) => ({
      display: d.display,
      value: d._id,
    }));
    paraOps = [{ display: "All Paralegals", value: "all" }, ...paraOps];
    return paraOps;
  };

export const countViolationTypes = data => {
  let violationEntries = data.map(d => d.meta.typeOfViolation)
  let totalViolationEntries = violationEntries.length;
  let counts = {}
  let options = []
  violationEntries.forEach(d => {
    // d= array of violations per casereport
    propertyExists(d) && d.forEach(dd => {
      if(counts[dd.value]){
        counts[dd.value].count = counts[dd.value].count + 1
      }else{
        counts[dd.value] = {count: 1, display: dd.display}
        options.push({...dd, color: colors[options.length]});
      }
    })
  })

  Object.keys(counts).forEach((key,i) => {
    let {count} = counts[key];
    let percent = Math.round((count/totalViolationEntries)*100);
    counts[key].percent = percent;
    options[i].desc = `${count} (${percent}%)`
    options[i].count = count 
    options[i].percent = percent
  })

  options = sortData({
    data: options, 
    fn: 'numerical',
    path: 'percent'
  })

  let sortedCounts = {}
  options.map(({value}) => {
    sortedCounts[value] = counts[value]
  })

  return {counts : sortedCounts, options }
}

export const reShapeData = (data, activeView) => {
    if (!data || data.length === 0) return undefined

    let statuses =
      activeView.value === 'all'
        ? data.map((d) => d.meta.status || {display: 'Open', value: 'open'})
        : data
            .filter(
              (d) => d.tags.paralegals.data[0]._id === activeView.value
            )
            .map((d) => d.meta.status)
    let totalCases = statuses.length
    let seg = segrigateDocs(statuses, 'value')
    seg = {
      total: makeArrayFromLength(totalCases).map((d) => ({
        value: 'total',
        display: 'Total Cases',
      })),
      ...seg,
    }
    let newData = JSON.parse(JSON.stringify(seg))
    // console.log('DATA',JSON.parse(JSON.stringify(newData)))
    Object.values(newData).map((d, i) => {
      let key = Object.keys(newData)[i]
      newData[key] = d.length
    })
    let dataPointsConfig = Object.values(seg).map((d) => {
      return {
        id: d[0].value,
        display: d[0].value === 'total' 
          ? d[0].display
          : `${d[0].display} (${Math.round((d.length / seg.total.length) * 100)}%)`,
        valuePath: d[0].value,
      }
    })

    return {
      newData,
      dataPointsConfig,
    }
  }