import React from 'react';


import 'draft-js/dist/Draft.css';





import { RTEContextProvider } from './RTEContext';
import { Main } from './Main';
import { useAppSettingsContext } from '../../../../contexts/appSettingsContext';


function RichTextEditor({
  enableConductor = false,
  id,
  invert,
  isRequired,
  onChange,
  placeholder = 'write here',
  readOnly: readOnlyProp = false,
  typo = 'CONTENT_BODY',
  textAlignment,
  value,
  charLimit,
  placeholderColor,
  disableNewline = false,

  // these two are set to false for simple editor
  variant = 'rich', //alt 'simple'
  //variant affects:
  // enableToolbar
  // enableKeyCommands
  // allowPastedOrDroppedFiles
  // advancedPastedText : handling html paste & conductor stuff
  // handleBeforeInput : handling keyboard short cut for list creation

  annotation,
  forceValue,
  style
}){
  const props = arguments[0];

  const { APP_SETTINGS } = useAppSettingsContext();



  return (
    <RTEContextProvider
      {...{
        enableConductor,
        id,
        invert,
        isRequired,
        onChange,
        placeholder,
        readOnlyProp,
        typo,
        value,
        charLimit,
        placeholderColor,
        disableNewline,
        variant,
        annotation,
        textAlignment,
        forceValue,
        style
      }}
      >
      <Main
        {...props} 
        key = {APP_SETTINGS.lang.value}
        //need to pass these entire props for genBlockLabelProps inside Main to work
        />
    </RTEContextProvider>
  )
}

export default RichTextEditor;

