import React from "react";
import { SANS_3, SERIF_4_5, SERIF_5_6 } from 'oolib'

const WarningScreen = (props) => {

  const { platformConfigs, onClose} = props;
  
  return (
    <div className='OKE-WarningScreen'>
      <img src={platformConfigs.deployment.logo} style={{height : '50px'}}/>
      <SERIF_5_6>This website is for testing purposes only.</SERIF_5_6>
      <SERIF_4_5 style={{marginBottom: '1rem'}}>Any data entered here is likely to be erased.</SERIF_4_5>
      <SANS_3>{`If you intended to visit the live '${platformConfigs.deployment._DeploymentDisplayName}' platform, please contact the appropriate person for the correct URL.`}</SANS_3>
      <SANS_3>Else, if you know why you are here, <span className='OKE-WarningScreen__link' onClick={onClose}>click here to proceed</span></SANS_3>
    </div>
  );

  // go here : `}<a className='OKE-WarningScreen__link' href='#'>{'<PENDING PROD URL CONFIG>'}</a>

};

export default WarningScreen;
