import React from "react";
import { AudioInput } from "oolib";
import { DecoratorNodeShell } from "../comps/DecoratorNodeShell";

export const AudioInputNodeComp = ({
  //props for comp
  id,
  files,
  value,

  //props for shell
  nodeKey,

  //handlers
  onChange,
  onRemove,

  readOnly
}) => {

  return (
    <DecoratorNodeShell
      nodeKey={nodeKey}
      onRemove={onRemove}
      actions={
        [
              {
                icon: "AudioEmbedIcon",
                display: "Re-upload Audio",
                onClick: () => {
                  const actualReuploadButton =
                    document.getElementById(`reuploadAudio`);
                  actualReuploadButton.click();
                },
              },
            ]
      }
    >
      <AudioInput
        isInRTE={true}
        id={id}
        files={files}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
    </DecoratorNodeShell>
  );
};
