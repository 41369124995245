import React, { createContext, useReducer, useContext } from 'react'

export const ResearchNotesV1Context = createContext()

const ResearchNotesV1Context_reducer = (state, action) => {
  const { type, payload } = action


  switch (type) {
    case 'SET_MODE':
      if (payload.data) {
        return { ...state, mode: { value: payload.mode, data: payload.data } }
      } else {
        return { ...state, mode: { ...state.mode, value: payload.mode } }
      }
    case 'SET_VIEW':
      if (payload.data) {
        return { ...state, view: { value: payload.view, data: payload.data } }
      } else {
        return { ...state, view: { value: payload.view } }
      }
    case 'CLEAR_ONE':
      delete state[payload]
      return { ...state }
    case 'CLEAR':
      return {}
    default:
      return state
  }
}

const getMode = (state) => {

  return {mode:state.mode?.value,data:state.mode?.data}
}

const getView = (state) => {

  return {view:state.view?.value,data:state.view?.data}


}

export const ResearchNotesV1ContextProvider = ({ children }) => {
  const initialState = {
    mode: undefined,
    view: undefined,
    scrollToElem: undefined,
  }
  // mode = "view"|"edit", data = annotation data, view= "raw"|"synth", scrollToElem: dom element to scroll to
  const [ResearchNotesV1Context_STATE, ResearchNotesV1Context_DISPATCH] = useReducer(
    ResearchNotesV1Context_reducer,
    initialState
  )

  console.log({ ResearchNotesV1Context_STATE })

  return (
    <ResearchNotesV1Context.Provider
      value={{
        ResearchNotesV1Context_GET_MODE: () => getMode(ResearchNotesV1Context_STATE),
        ResearchNotesV1Context_GET_VIEW: () => getView(ResearchNotesV1Context_STATE),
        ResearchNotesV1Context_DISPATCH,
      }}
    >
      {children}
    </ResearchNotesV1Context.Provider>
  )
}

export const useResearchNotesV1Context = () => {
  return useContext(ResearchNotesV1Context)
}
