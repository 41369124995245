import React from "react";
import { SANS_2, SANS_3, TagDisplay, SANS_5_6, SERIF_3_4, colors, getText, Divider, SANS_4_5 } from "oolib";
import {
  ClampedWrapperStyled,
  SummaryWrapperStyled,
} from "../AnnotationCard/styled";

import { isArray } from "lodash";
import { NotesWrapperStyled } from "../SideCardExpand/styled";
import { StyledHighlightWrapper } from "../../../inputs/KPRichInput/annotation/comps/RichHighlight";
import { getHighlightBgColor } from "../SideCardExpand/utils";
import Repeater from "../../../inputs/Repeater";
import LexicalTextEditor from "../../../inputs/TextEditor/LexicalTextEditor";

const { greyColor80, greyColor100 } = colors;

const getValue = (value) => {
  if (!value) return null;

  if (isArray(value)) {
    return value[0];
  }

  return value;
};

export const Meta = ({ value }) => {
  return value ? <SANS_2>{value}</SANS_2> : null;
};

export const Attributes = ({ value, label }) => {
  const key = getValue(value);
  return key ? (
    <SANS_2 bold uppercase color={greyColor80}>
      {`${label}  :  ${key}`}
    </SANS_2>
  ) : null;
};

export const Title = ({ value }) => {
  const title = getText(value);

  return title ? (
    <SANS_5_6 bold capitalize>
      {title}
    </SANS_5_6>
  ) : null;
};

export const TitleSmall = ({ value }) => {
  const title = getText(value);

  return title ? (
    <SANS_4_5 bold capitalize>
      {title}
    </SANS_4_5>
  ) : null;
};

// export const Summary = (props) => {
//   const { compProps, value, annoTagType } = props;

//    const summary =
//       value.annotations.tags[annoTagType].data[0]?.note?.text?.blocks ||
//       value.annotations.tags[annoTagType].data[0].fragments[0]?.description
//         ?.blocks;

//   return summary ? (
//     <SummaryWrapperStyled {...compProps}>
//       {summary.map((d) => (
//         <SERIF_3_4 {...compProps}>{ d.text}</SERIF_3_4>
//       ))}
//     </SummaryWrapperStyled>
//   ) : null;
// };

export const KeywordHighlight = ({
  value,
  showMax,
  suffix = "",
  maxChars,
  keyword,
}) => {
  if (!value) return null;
  const combileAll = Object.keys(value).map((d) => value[d].map((d) => d)).flat();
  // console.log({ value, combileAll });
  //  return null

  return combileAll.slice(0, showMax).map((d) => {
    const keywordIdx = d.lastIndexOf("</b>");
    const stringEndsAt = keywordIdx > maxChars ? keywordIdx + 10 : maxChars;
    return (
      <SANS_3>
        <div
          dangerouslySetInnerHTML={{
            __html:
              d.substring(0, stringEndsAt) +
              (d.length > maxChars ? suffix : ""),
          }}
        ></div>
      </SANS_3>
    );
  });
};

export const Summary = ({ value }) => {
  return value ? (
    <SummaryWrapperStyled>
      <span>{value.map((d) => d && <SERIF_3_4>{d?.text}</SERIF_3_4>)}</span>
    </SummaryWrapperStyled>
  ) : null;
};

export const SimpleTextClamped = ({ value, noOfLines }) => {
  return value ? (
    <SummaryWrapperStyled noOfLines={noOfLines}> 
      <span><SANS_3 value={value} /></span>
    </SummaryWrapperStyled>
  ) : null;
};



export const ClampedRichInput = (props) => {
  const { value, tagId } = props;
  if (!value) return null;

  let _value = value;
  if (!Array.isArray(value)) {
    _value = [value];
  }

  // find the tagId

  const tagIdData = _value.find((d) => d.tagId === tagId.value);

  if (!tagIdData) return null;

  const fragments = tagIdData.fragments;

  if (fragments?.length === 0) {
    throw new Error("fragments data is missing -> probably a data loss")
  };

  // check is lexical structure?

  const isLexical = fragments[0].isLexical;

  let newVal;
  if (isLexical) {
    newVal = fragments[0];
  } else {
    newVal = { blocks: [{ text: fragments[0].text[0].text }], entityMap: {} };
  }

  return (
    <ClampedWrapperStyled>
      <LexicalTextEditor
        value={newVal}
        readOnly={true}
        typo="SERIF_3_4 italic"
        variant={"simple"}
      />
    </ClampedWrapperStyled>
  );
};

export const Tags = (props) => {
  const { 
    // compProps,
    value, annoTagType } = props;

  const ctUsed = value.annotations.tags[annoTagType].data[0].ctUsed;
  const ctConsidered = value.annotations.tags[annoTagType].data[0].ctConsidered;

  if (!ctUsed || !ctConsidered) return null;

  return (
    <div>
      <SANS_3 bold capitalize>
        {ctUsed ? "ct used" : "ct considered"}
      </SANS_3>
      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          gap: "1rem",
          marginTop: ".7rem",
        }}
      >
        {(ctUsed?.data || ctConsidered?.data).map((ct) => {
          // return <TagDisplay key={ct.value} display={ct.display} />;
          return <TagDisplay variant="secondary" tagColor={greyColor100} textColor={greyColor100} key={ct?.value} display={ct?.display} />

        })}
      </div>
    </div>
  );
};

export const NewTags = ({ value, showMax, label, tagId }) => {


  

  // hack for now 
  // investigate in detail
  const allTags = []


  for(let i of value.filter(d=>d)){
    for(let j of i){
      allTags.push(j)
    }
  }


  const filteredTags = allTags?.filter((d) => d?.tagId !== tagId?.value);

  return filteredTags && filteredTags[0] ? (
    <div>
      <SANS_3 bold>{label}</SANS_3>
      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          gap: "1rem",
          marginTop: ".7rem",
        }}
      >
        {filteredTags.slice(0, showMax)?.map((v) => (
          <TagDisplay variant="secondary" tagColor={greyColor100} textColor={greyColor100} key={v?.value} display={v?.display} />
        ))}
      </div>
    </div>
  ) : null;
};

export const NewAttributes = ({ value, label }) => {
  return value && value[0] ? (
    <div style={{ display: "flex", gap: "2rem" }}>
      <SANS_3 bold capitalize>
        {label}
      </SANS_3>
      <SANS_2 uppercase color={greyColor80} bold>
        {value}
      </SANS_2>
    </div>
  ) : null;
};

export const NewSummary = ({ value, label, showMax }) => {
  return value ? (
    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <SANS_3 bold>{label}</SANS_3>
      <NotesWrapperStyled>
        {value.slice(0, showMax).map((d) => (
          <SERIF_3_4>{d?.text}</SERIF_3_4>
        ))}
      </NotesWrapperStyled>
    </div>
  ) : null;
};

export const NewAnnotationsClone = ({ value, label, annoTagType, tagId }) => {
  return value ? (
    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <SANS_3 bold>{label}</SANS_3>
      <NotesWrapperStyled>
        {value?.fragments.map((fragment) => {
          const isLexical = fragment.isLexical ? true : false;

          return (
            <>
              <StyledHighlightWrapper
                backgroundColor={getHighlightBgColor(annoTagType)}
              >
                <LexicalTextEditor
                  readOnly={true}
                  variant="simple"
                  value={
                    isLexical
                      ? fragment
                      : {
                          blocks: fragment.text,
                          entityMap: {},
                        }
                  }
                />
                <div style={{ marginTop: '2rem' }}>
                  <Divider />
                </div>
              </StyledHighlightWrapper>
            </>
          );
        })}
      </NotesWrapperStyled>
    </div>
  ) : null;
};

export const NewAnnotations = ({ value, label, annoTagType, tagId }) => {
  if (!value) return null;

  const filteredValue = value.filter((d) => d.tagId === tagId.value);

  if (filteredValue.length === 0) return null;

  console.log({ filteredValue });

  // return null

  return value ? (
    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <SANS_3 bold>{label}</SANS_3>
      <NotesWrapperStyled>
        {filteredValue.map((d) => {
          return d.fragments.map((fragment) => {
            const isLexical = fragment.isLexical ? true : false;

            return (
              <>
                <StyledHighlightWrapper
                  backgroundColor={getHighlightBgColor(annoTagType)}
                >
                  <LexicalTextEditor
                    readOnly={true}
                    variant="simple"
                    value={
                      isLexical
                        ? fragment
                        : {
                            blocks: fragment.text,
                            entityMap: {},
                          }
                    }
                  />
                  <div style={{ marginTop: '2rem' }}>
                    <Divider />
                  </div>
                </StyledHighlightWrapper>
                {/* {fragment?.description?.blocks.map((d) => (
                  <SERIF_3_4>{d.text}</SERIF_3_4>
                ))} */}
              </>
            );
          });
        })}
      </NotesWrapperStyled>
    </div>
  ) : null;
};

export const RepeaterWrapper = ({ value, blocks, label, readOnly }) => {
  console.log({ RepeaterWrapper: value });
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <SANS_3 bold>{label}</SANS_3>
      <Repeater value={value} blocks={blocks} readOnly={readOnly} />
    </div>
  );
};
