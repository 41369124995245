import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection } from "lexical";
import React, { useEffect, useRef } from "react";
import { AnnoLightboxShell } from "./AnnoLighboxShell";
import { AnnoLightboxDisplay } from "./AnnoLightboxDisplay";
import { AnnoLightboxInput } from "./AnnoLightboxInput";

const AnnoLightbox = ({
  handleRemove,
  handleConfirmEdit,
  handleCancel,
  handleConfirmCreate,

  mode,
  setEditMode,

  dataId,
  data = {},
  nodeKeys,
  setData,
  position,
  handleClickOutside,
  annoConfig,
  readOnly,

  activeData_with_relatedNodeKeys
}) => {
  

  const { tagTypesConfig, toggleTagTypesConfig } = annoConfig || {};


  /**
   * this becomes relevant only when, 'Add Other Tag' is clicked
   * in the TagsSubmenu, and a new tag is actually added. 
   * Why? because adding a new tag involves typing in another
   * text input which obviously removes the text selection from the
   * rich text editor. So in such a case, we need to remember what the selection
   * was, and pass it to handleConfirmCreate so that the annotation is properly
   * applied in the end.
   * 
   * So to just simplify things for ourselves, we just use this as the 
   * standard pattern for handleConfirmCreate irrespective of whether it 
   * involves new tag creation or no.
   */
  const [editor] = useLexicalComposerContext();
  const targetSelection = useRef(null);
  useEffect(() => {
  return editor.getEditorState().read(() => {
    targetSelection.current = $getSelection()?.clone()
  })
  },[])


  /**
   * PENDING
   */
  // const endToEndSelectionData = editorState
  // ? isEndToEndSelection(editorState)
  // : false

  return (
    <AnnoLightboxShell {...{handleClickOutside, position}}>
      {mode === "display" ? (
        activeData_with_relatedNodeKeys.map(({textFragment, data : _data, dataId : _dataId, nodeKeys: _nodeKeys}) => (
          <AnnoLightboxDisplay
            //show textFrag only if its an overlapping annotations situation
            textFragment={activeData_with_relatedNodeKeys.length > 1 && textFragment}
            data={_data}
            onRemove={() => handleRemove({ nodeKeys: _nodeKeys, dataId: _dataId })}
            onEdit={() => {
              setEditMode({data: _data, dataId: _dataId, nodeKeys: _nodeKeys});
            }}
            readOnly={readOnly}  
          />
        ))
      ) : (
        // using this id to get the light box container element, to fix the lightbox hiding when there's no scroll up space
        <div id="input-light-box">
          <AnnoLightboxInput
            tagTypesConfig={tagTypesConfig || []}
            toggleTagTypesConfig={toggleTagTypesConfig}
            onChange={(tagType, value) => setData({ tagType, value })}
            value={data}
            handleOnConfirm={() =>
              mode === "edit" ? handleConfirmEdit({ data, dataId }) : handleConfirmCreate({ data, targetSelection: targetSelection.current })
            }
            handleOnCancel={handleCancel}
          />
        </div>
      )}
    </AnnoLightboxShell>
  );
};

export default AnnoLightbox;
