
import AtomicBlockRenderer from "../comps/AtomicBlockRenderer";


const renderAtomicBlock =({contentBlock, editorState, handleChange, readOnlyProp, setReadOnly})=> {
	// used by draftjs editor to render blocks with type 'atomic'
	
  if (contentBlock.getType() === 'atomic') {
		return {
			component: AtomicBlockRenderer,
			editable: false,
			props: { setReadOnly, readOnlyProp, editorState, handleChange },
		}
	}
}

export default renderAtomicBlock;