

export const executeInQueue = async ({
    dataAry,
    callback,
    log = false,
    idx = 0,
    results = [],
  }) => {
    try{
    if (idx === dataAry.length) return results;
    //else if idx !== dataAry.length
    let d = dataAry[idx];
  
    let result = await callback(d, idx);
  
    if (log === true) console.log(`successfully completed dataAry[${idx}]`);
    results.push({status: 'success', result});
  
    return executeInQueue({
      dataAry,
      callback,
      log,
      idx: idx + 1,
      results,
    });
  } catch (err) {
    // console.log({ err });
    results.push({status: "error", err});
    return results
  }
  };