import { useMemo } from "react";
import { useGetTpl } from "../../../../../utils/react-query-hooks/tpls";

import { extractAllBlocksFromTpl } from "../../../../../utils/templating/extractAllBlocksFromTpl";
import { parseSpecialSyntax } from "../../../../../utils/parseSpecialSyntax";
import { mergeStaticConfsIntoTplExtractedConfs } from "./mergeStaticConfsIntoTplExtractedConfs";

import { transformTplBlocksToCellData } from "./transformTplBlocksToCellData";
import { queryClient } from "../../../../..";

/**
 *
 * in future, will prob need the ability to attach multiple tpls.
 */
export const useParseTplToTableConfig = ({
  tableConfigProp,
  isBackendSort,
}) => {
  const { contentType: tplToParse } = tableConfigProp;

  // const { data, status } = useGetTpl(tplToParse, {
  //   enabled: !tplToParse ? false : true,
  // });
  const data = tplToParse && queryClient.getQueryData(['tpl',tplToParse])

  const toReturn = useMemo(() => {
    if (tplToParse) {
      let allTplBlockConfigs = extractAllBlocksFromTpl({ tpl: data });

      let cellDataExtractedFromTpl = transformTplBlocksToCellData({
        allTplBlockConfigs,
        isBackendSort,
        tableConfigProp
      });

      /**
       * now if you want to overwrite some properties of a celldata config that has
       * been extracted from the tpl, you can do so by explicitly defining those properties
       * in the tableConfigProp.cellData. ONLY MAKE SURE that the colId matches the colId
       * of the cellData extracted from the tpl.
       */

      let parsedTableConfig = {
        ...tableConfigProp,
        cellData: mergeStaticConfsIntoTplExtractedConfs({
          extractedConfs: cellDataExtractedFromTpl,
          staticConfs: tableConfigProp.cellData
            ? tableConfigProp.cellData.map((cD) => ({ //this parses special syntax if used for headerDisplay
                ...cD,
                ...(cD.headerDisplay
                  ? {
                      headerDisplay: parseSpecialSyntax({
                        string: cD.headerDisplay,
                      }) || cD.headerDisplay,
                    }
                  : {}),
              }))
            : [],
        }),
      };

      return { tableConfig: parsedTableConfig, parseStatus: "completed" };
    } else {
      return {
        tableConfig: tableConfigProp,
        parseStatus: !tplToParse ? "noParseRequired" : "inProgress",
      }; //serve the base config while the tpl data is loading
    }
  }, [tableConfigProp, isBackendSort]);
  /**
   * important to pass isBackendSort, because, on listingPage for example,
   * an async call is made in order to extract backendSortConfig.
   * So initially isBackendSort is false, and it then switches to true.
   *
   * Which means the table config needs to be reparsed
   */

  return {
    tableConfig: toReturn.tableConfig,
    parseStatus: toReturn.parseStatus,
  };
};
