import styled from "styled-components";
import { blurPage } from "../Shell/styled";

export const StyledTabsPanelWrapper = styled.div`
  border-bottom: 1.5px solid #E9E9E9;
  position: sticky;
  z-index: 10;
  border-top: 1.5px solid #E9E9E9;
  top: 0;
  ${blurPage}
`