import React from 'react'
import { VideoInput } from 'oolib'
import { mergeEntityData } from '../../utils/mergeEntityData'
import AtomicBlockShell from '../AtomicBlockShell'

export const RTEVideoInput = ({
    id,
    value,
    files,
    readOnlyProp, 
    setReadOnly, 
    editorState, 
    handleChange,
    entityKey, 
    block,
    isInRTE 
}) => {
  
  const isUploadedVideo = value => value && Object.keys(value?.[0]).length > 1 // in RTE Video component url is stored similar to object structure for both extrenal url (embeded) and uploaded video (publicUrl in object array
  
  return (
    <>
    { (files || value) && 
    <AtomicBlockShell {...{ 
      id, 
      readOnlyProp, 
      setReadOnly, 
      editorState, 
      handleChange, 
      block,
      actions: [
      (value && !isUploadedVideo(value)) ?
      { icon: "LinkSimple",
        display: "Edit URL",
        onClick: () => {
            const actualReplaceButton = document.getElementById('replaceUrl');
            actualReplaceButton.click()
            // setShowLinkOrEmbedModal({mode: 'embed'})
        }
      }
      :
      { icon: "UploadSimple",
        display: "Re-upload Video",
        onClick: () => {
          const actualReuploadButton = document.getElementById('uploadNewVideo');
          actualReuploadButton.click()
        }
      },
      ] 
      }}>
        <VideoInput 
          value={value}
          files={files}
          onChange={(id, v) => {
            mergeEntityData(
              editorState,
              handleChange,
              entityKey,
              { value: v, files: undefined }
              );
            }}
          readOnly={readOnlyProp}
          isInRTE={isInRTE}
          />
   </AtomicBlockShell>
    }
   </>
  )
}
