import { useBannerContext } from 'oolib';
import {useEffect} from 'react';


export const isDeviceOnline = () => navigator.onLine;



export const useOnlineStatusChange = () => {
	const { SET_INFO_BANNER, REMOVE_INFO_BANNER } = useBannerContext();
	
	
	useEffect(() => {
		
		const handleOnlineStatusChange = (status) => {
			
			
			let bannerConfig = status === 'online'
			? { msg: 'Device Is Online.', color: 'green', timeOut: 3000, id: 'deviceOnline' }
			: { msg: `Device Is Offline. ${window.location.pathname.includes('login') ? 'Please try logging in once you have Internet connection.' : ''}`, color: 'red', id: 'deviceOffline' }
			
			SET_INFO_BANNER(bannerConfig);

			if(status === 'online') {
				REMOVE_INFO_BANNER(null, 'deviceOffline')
			}
			
		}
		
		//on mount, show banner if device is offline
		if(!isDeviceOnline()) handleOnlineStatusChange('offline')

		window.addEventListener('offline', () => handleOnlineStatusChange('offline'))
		window.addEventListener('online', () => handleOnlineStatusChange('online'))
		
	}, [])
}