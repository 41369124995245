import { transition } from "oolib";
import styled from "styled-components";

export const StyledExpandCompContainer = styled.div`
    position: relative;
    overflow: hidden;
    height: ${({showExpandComp, contentsHeight}) => {
        return showExpandComp ? `${contentsHeight}px` : 0
    }};
    ${transition('height')}
`

export const StyledExpandCompWrapper = styled.div`
    position: absolute;
    bottom: 0;
`