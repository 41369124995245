import React from "react";

import { useGetActivityAnalytics } from "../../../../utils/react-query-hooks/activityAnalytics";
import { SANS_2, SANS_0, SANS_3, SkeletonLoader, OKELink, getText, makeArrayFromLength, ProfileImageInput } from "oolib";
import { createContentCardDate } from "../../../../utils/momentManipulate";
import { colors } from "oolib";
import {
  __GetAllContentTypeConfig,
} from "../../../../utils/getters/gettersV2";


const { greyColor5, greyColor40 } = colors;

const genMessage = (d, allContentTypes) => {
  switch (d.activityType) {
    case "CREATE_UPDATE":
      return (
        <SANS_2>
          <strong>{d.user.name}</strong>
          <span>{` added an update: ${d.content.update.updateType} to `}</span>
          <OKELink
            to={`/published-page/${d.content.contentType}?id=${d.content.id}`}
          >
            {getText(d.content.title)}
          </OKELink>
        </SANS_2>
      );
    case "UPDATE_UPDATE":
      return (
        <SANS_2>
          <strong>{d.user.name}</strong>
          <span>{` edited an update: ${d.content.update.updateType} in `}</span>
          <OKELink
            linkType="internal"
            to={`/published-page/${d.content.contentType}?id=${d.content.id}`}
          >
            {getText(d.content.title)}
          </OKELink>
        </SANS_2>
      );
      case "SUBMIT":
        return (
          <SANS_2>
            <strong>{d.user.name}</strong>
            <span>{` submitted a ${
              allContentTypes.find(
                (dd) => dd.kp_content_type === d.content.contentType
              )?.general.content.singular
            } called `}</span>
            <OKELink
              linkType="internal"
              to={`/published-page/${d.content.contentType}?id=${d.content.id}`}
            >
              {getText(d.content.title)}
            </OKELink>
          </SANS_2>
        );
        case "RE_SUBMIT":
          return (
            <SANS_2>
              <strong>{d.user.name}</strong>
              <span>{` edited & re-submitted a ${
                allContentTypes.find(
                  (dd) => dd.kp_content_type === d.content.contentType
                )?.general.content.singular
              } called `}</span>
              <OKELink
                linkType="internal"
                to={`/published-page/${d.content.contentType}?id=${d.content.id}`}
              >
                {getText(d.content.title)}
              </OKELink>
            </SANS_2>
          );
    case "PUBLISH":
      return (
        <SANS_2>
          <strong>{d.user.name}</strong>
          <span>{` published a ${
            allContentTypes.find(
              (dd) => dd.kp_content_type === d.content.contentType
            )?.general.content.singular
          } called `}</span>
          <OKELink
            linkType="internal"
            to={`/published-page/${d.content.contentType}?id=${d.content.id}`}
          >
            {getText(d.content.title)}
          </OKELink>
        </SANS_2>
      );
    case "RE_PUBLISH":
      return (
        <SANS_2>
          <strong>{d.user.name}</strong>
          <span>{` edited a ${
            allContentTypes.find(
              (dd) => dd.kp_content_type === d.content.contentType
            )?.general?.content.singular
          } called `}</span>
          <OKELink
            linkType="internal"
            to={`/published-page/${d.content.contentType}?id=${d.content.id}`}
          >
            {getText(d.content.title)}
          </OKELink>
        </SANS_2>
      );
    case "APPROVE":
      return (
        <SANS_2>
          <strong>{d.user.name}</strong>
          <span>{` reviewed & approved a ${
            allContentTypes.find(
              (dd) => dd.kp_content_type === d.content.contentType
            )?.general?.content.singular
          } called `}</span>
          <OKELink
            linkType="internal"
            to={`/published-page/${d.content.contentType}?id=${d.content.id}`}
          >
            {getText(d.content.title)}
          </OKELink>
        </SANS_2>
      );
    case "DELETE":
      return (
        <SANS_2>
          <strong>{d.user.name}</strong>
          <span>{` deleted a ${
            allContentTypes.find(
              (dd) => dd.kp_content_type === d.content.contentType
            )?.general?.content.singular
          } called ${getText(d.content.title)}`}</span>
        </SANS_2>
      );
    default:
      return <SANS_2>{"hello world"}</SANS_2>;
  }
};

const ActivityAnalyticsList = ({ data, status }) => {
  const allContentTypes = __GetAllContentTypeConfig();

  const genAnalyticsList = (data) => (
    <div>
      <div style={{ padding: "2rem" }}>
        <SANS_3>Recent Activity</SANS_3>
      </div>
      {data.map((d) => (
        <div
          style={{ padding: "2rem", borderBottom: `1px solid ${greyColor5}` }}
        >
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <div style={{ flex: "0 0 auto" }}>
              <ProfileImageInput
              readOnly
                value={d.user.avatar?.userUploaded}
                size={30}
                imageTitle={d.user.name}
              />
            </div>
            <div>{genMessage(d, allContentTypes)}</div>
          </div>
          <div style={{ paddingLeft: "4rem", paddingTop: "0.5rem" }}>
            <SANS_0 color={greyColor40}>
              {d.dateCreated && createContentCardDate(d.dateCreated)}
            </SANS_0>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      {status === "loading" ? (
        makeArrayFromLength(2).map((d) => (
          <SkeletonLoader style={{ height: "10rem" }} />
        ))
      ) : status === "error" ? (
        <SANS_2>{"Error getting analytics!"}</SANS_2>
      ) : (
        status === "success" && genAnalyticsList(data)
      )}
    </div>
  );
};

export const AdminActivityAnalyticsList = () => {
  let { data, status } = useGetActivityAnalytics({});

  return <ActivityAnalyticsList data={data} status={status} />;
};

export default ActivityAnalyticsList;
