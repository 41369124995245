import React, { useEffect, useState } from 'react';

import MovingCoachmark from './comps/MovingCoachmark';
import { StyledOverlay } from './styled';
import { useCoachmark } from './utils';


const Coachmark =(props)=> {
  const {
    active = true,
    observerThreshold = 0.6,
    currentMark,
    handleSkip,
    handleNext,
    rank
  } = props;

  const [isElementInView, setIsElementInView] = useState(false)
  
  useEffect(()=> {
    if (currentMark?.element instanceof Element ){
      if (currentMark.mark.position && currentMark.mark.position !== 'center') {
        if (isElementInView) currentMark.element.style.zIndex = 160;
      }
      return ()=> {
        if (currentMark.element) currentMark.element.style.zIndex = 'auto';
      }
    }
  })

  if (!currentMark || !active) return null

  const {element, mark} = currentMark

  const targetElm = element instanceof Element && element;

  if (!targetElm) return null;

  const observer = new IntersectionObserver((entries, observer)=>{
    setIsElementInView(entries[0].isIntersecting);
  },
  {threshold: observerThreshold}); // if 60% of the element is covered, it is intersecting

  observer.observe(targetElm);


  return isElementInView &&  (
    <StyledOverlay >
      <MovingCoachmark
        observer={observer}
        targetElm={targetElm}
        handleNext={handleNext}
        handleSkip={handleSkip}
        rank={rank}
        {...mark}
      />
    </StyledOverlay>
  );

}

export {Coachmark, useCoachmark}