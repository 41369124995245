import React, { Fragment } from "react";
//config
import { __CheckSomeAccessToSomeContentType } from "../../../utils/accessControl";
import { useGetQueryData } from "../../../utils/react-query-hooks/general";
import { CardRadio } from "../../inputs/CardRadioInput";
import { Container, LoaderOverlay, PaddingTopBottom40, WrapperCardGrid } from "oolib";
import { Wrapper1000 } from "oolib";
import { useBeginPublishingFlow } from "../../../utils/contributionFlowUtils";
import { __GetConfigsBySegmentNew } from "../../../utils/getters/gettersV2";
import { trackEventGA } from "../../../trackers/GA/trackEventGA";

const ChooseContentTypeBody = () => {
  let data = useGetQueryData("userData");

  const { generateFn, thisTemplateIsGenerating } = useBeginPublishingFlow();

  const configs = __GetConfigsBySegmentNew("publishing").filter((d) => {
    return (
      data?.user &&
      __CheckSomeAccessToSomeContentType({
        user: data?.user,
        actions: ["SUBMIT", "PUBLISH"],
        contentTypes: d.kp_content_type,
      })
    );
  });

  const isTemplateGenerating = configs.some((d) => thisTemplateIsGenerating(d));

  return (
    <Fragment>
      <div>
        <Container>
          <Wrapper1000>
            <PaddingTopBottom40>
              <WrapperCardGrid>
                {isTemplateGenerating && <LoaderOverlay />}
                {configs.map((d) => (
                  <CardRadio
                    display={d.general?.content?.title}
                    // desc={d.general?.content?.listingDesc} //this is causing problems...
                    action={{ text: d.general?.content?.ctaText }}
                    onClick={() => {
                      trackEventGA(
                        `Publishing Flow`,
                        `Clicked Content Type On Select Content Type Page`,
                        `${d.general?.content?.title}`
                      ); //this is also a 'generate tpl'
                      generateFn(d);
                    }}
                  />
                ))}
              </WrapperCardGrid>
            </PaddingTopBottom40>
          </Wrapper1000>
        </Container>
      </div>
    </Fragment>
  );
};

export default ChooseContentTypeBody;
