import { SANS_2, colors, transition } from "oolib";
import React from "react";
import styled from "styled-components";
import { RotatingCaret } from "../../../../../generalUI/RotatingCaret";

const StyledCustomSelectTopbarFilter = styled.button`
  border: none;
  height: 4rem;
  background-color: ${({isActive}) => isActive ? colors.greyColor5 : colors.none};
  padding: 0 1.5rem;
  ${transition("background-color")};
  &:hover {
    background-color: ${colors.greyColor5};
  }
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
`

export const CustomSelectTopbarFilter = ({ showOptions, display}) => {
  
    return (
        <StyledCustomSelectTopbarFilter>
          <SANS_2>{display}</SANS_2>
          <RotatingCaret position={showOptions ? 'up' : 'down'}/>
        </StyledCustomSelectTopbarFilter>
      );
}

