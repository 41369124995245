import {
  colors,
  Container,
  ProgressBar,
  Wrapper700,
  PaddingTopBottom45,
  ImageInput,
  ProfileImageInput
} from "oolib";
import React, { useState } from "react";


import { exampleImages1, exampleImages2 } from "./configs";

export const ImageInputTest = () => {
  const [galleryPublished, 
    // setGalleryPublished
  ] = useState(false);

  const [singleImg, setSingleImg] = useState(exampleImages2);
  const [imgs, setImgs] = useState(exampleImages1);
  const [imgsInv, setImgsInv] = useState(exampleImages2);

  return (
    <div>
      <Container>
        <Wrapper700>
          <PaddingTopBottom45>
            <ProgressBar progress={50} />
          </PaddingTopBottom45>
          <PaddingTopBottom45>
         
            <ImageInput
              multiple
              aspectRatio="4/3"
              onChange={(k, v) => {
                setImgs(v);
              }}
              value={imgs}
              label="heeeeey I am a label"
            />
          </PaddingTopBottom45>
          <PaddingTopBottom45>
            <div style={{ width: "300px" }}>
              <ImageInput
                // multiple
                aspectRatio="4/3"
                onChange={(k, v) => {
                  setSingleImg(v);
                }}
                defaultImageSpread="cover"
                value={singleImg}
                label="heeeeey I am a label"
              />
            </div>
          </PaddingTopBottom45>
          <PaddingTopBottom45>
            <ProfileImageInput
              value={singleImg}
              onChange={(k, v) => {
                setSingleImg(v);
              }}
              // content={{
              //   name: "Abrar",
              // }}
              size={200}
              imageTitle='Abrar'
              // imageTitlePath="name"
              // readOnly
              disableImageBorder
            />
          </PaddingTopBottom45>
          <PaddingTopBottom45>
            <ProfileImageInput
              // value={singleImg}
              // content={{
              //   name: "Abrar",
              // }}
              size={30}
              imageTitle='Abrar'
              // imageTitlePath="name"
              readOnly
              disableImageBorder
            />
          </PaddingTopBottom45>
          <PaddingTopBottom45>
            <ImageInput
              multiple
              aspectRatio="4/3"
              value={imgs}
              readOnly
              label="Read Only multi"
            />
          </PaddingTopBottom45>
          <PaddingTopBottom45>
            <ImageInput
              // multiple
              aspectRatio="4/3"
              onChange={(k, v) => {
                setSingleImg(v);
              }}
              value={singleImg}
              readOnly
              label="Read Only single"
            />
          </PaddingTopBottom45>
        </Wrapper700>
      </Container>
      <div style={{ backgroundColor: colors.greyColor100 }}>
        <Container>
          <Wrapper700>
            <PaddingTopBottom45>
              <ImageInput
                multiple
                aspectRatio="4/3"
                onChange={(k, v) => {
                  setImgsInv(v);
                }}
                value={imgsInv}
                readOnly={galleryPublished}
                label="heeeeey I am a label"
                invert={true}
              />
            </PaddingTopBottom45>
          </Wrapper700>
        </Container>
      </div>
    </div>
  );
};
