
import EditorState from "draft-js/lib/EditorState";

/**
 * 
 * @param {*} editorState 
 * @param {*} handleChange 
 * @param {*} entityKey 
 * @param {*} data 
 * @description this is a shallow merge. so merge is performed only on the first level keys. 2nd level nested keys
 * wont be merged which is what we want. Also when we say merge, we mean, same first level keys are replaced with new 
 * values, and new first level key-value pairs are appended to the object. Currently, we don't know how the merge behaves
 * if 'data' is an array and not an object.
 * 
 */
export const mergeEntityData = (editorState, handleChange, entityKey, data) => {

	const contentStateWithNewEntityData = editorState.getCurrentContent().mergeEntityData(
		entityKey,
		data,
	)
	
  const newEditorState = EditorState.push(
	editorState, 
	contentStateWithNewEntityData,
	'change-block-data'
	)

	

	handleChange(newEditorState, {firedFrom: 'RTEInsertLink'})
}