import { useQuery, useQueries, useInfiniteQuery } from "react-query";
import axios from "axios";
import { useAppSettingsContext } from "../../contexts/appSettingsContext";

const getDataQuery = (configs) => ({
  queryKey: ["contentListing", configs.contentTypes, configs],
  queryFn: () =>
    axios
      .get(`/api/discovery/getData`, {
        params: { configs: JSON.stringify(configs) },
        // paramsSerializer: (params) => qs.stringify(params),
      })
      .then((res) => res.data),
  ...(configs.queryOptions || {}),
});
const getUserAnalyticsQuery = ({usersConfig}) => ({
  queryKey: ["analyticsListing", "user", usersConfig],
  queryFn: () =>
    axios
      .get(`/api/users/userAnalyticsData`, {
        params: { usersConfig: JSON.stringify(usersConfig) },
        // paramsSerializer: (params) => qs.stringify(params),
      })
      .then((res) => res.data),
  //...(configs.queryOptions || {}),
});
export const useGetUserAnalyticsQuery=({usersConfig})=>{
  return (useQuery(
    getUserAnalyticsQuery({
      usersConfig
    })))
}

// this is used only inside TagsManager, hence a few things are hardcoded 
export const useGetDataQueries = ({ contentTypes }) => {
  return useQueries(
    contentTypes?.map((d) =>
      getDataQuery({
        contentTypes: [d],
        useCountDAL: true,
        findQuery: {
          kp_published_status: { $in: ["published", "draft"] },
        },
      })
    )
  );
};
export const useGetDataQueriesContentType = ({ configs }) => {
  return useQueries(
    configs?.map((d) =>
      getDataQuery({
        contentTypes: [d.contentType],
        //useCountDAL: true,
        findQuery: d.mostPublishedByQuery.findQuery,
        population:[
          {
            path: "meta.kp_contributed_by",
            model: "user",
            
          },
        ],
      })
    )
  );
};

export const useGetDataQuery = ({
  contentTypes,
  profileTypes,
  activeFilters,
  activeSort,
  searchTerm,
  population,
  projection,
  findQuery,
  taggedResourcesCount,
  taggedResourcesCount_unoptimized,
  populateTaggedResources,
  lookupConfig,
  queryOptions,
  limit,
  facet,
  countData,
  env,
  pluginId,
  useAggregation,

  useCountDAL
}) => {
  const { APP_SETTINGS } = useAppSettingsContext();
  const activeLang = APP_SETTINGS.lang.value;

  return useQuery(
    getDataQuery({
      contentTypes,
      profileTypes,
      activeFilters,
      activeSort,
      searchTerm,
      population,
      projection,
      findQuery,
      populateTaggedResources,
      lookupConfig,
      taggedResourcesCount,
      taggedResourcesCount_unoptimized,
      queryOptions,
      limit,
      facet,
      countData,
      env,
      pluginId,
      useAggregation,

      useCountDAL,
      activeLang
    })
  );
};

export const useGetDataLazy = ({
  profileTypes,
  contentTypes,
  activeFilters,
  activeSort,
  searchTerm,
  population,
  projection,
  findQuery,
  populateTaggedResources,
  lookupConfig,
  queryOptions,
  limit,
  facet,
  countData,
  collectionId,
  ksConfig,
  taggedResourcesCount,
  taggedResourcesCount_unoptimized,
  combineFields,
  useAggregation,
  useCountDAL,
  pluginId,
  /**
   * when being used to fetch the related content of a content type,
   * collectionId simply makes the query key more semantic and
   * guarantees uniqueness
   */
}) => {
  const { APP_SETTINGS } = useAppSettingsContext();
  const activeLang = APP_SETTINGS.lang.value;

  const configs = {
    profileTypes,
    contentTypes,
    activeFilters,
    activeSort,
    searchTerm,
    population,
    projection,
    findQuery,
    populateTaggedResources,
    lookupConfig,
    queryOptions,
    limit,
    facet,
    countData,
    ksConfig,
    taggedResourcesCount,
    taggedResourcesCount_unoptimized,
    combineFields,
    useAggregation,
    useCountDAL,
    pluginId,
    activeLang,
  };

  // const str = qs.stringify(ksConfig);

  // const par = qs.parse(str,{depth:100,arrayLimit:100,plainObjects:true})

  // console.log({str,par,ksConfig})

  // console.log({filters,length:filters.length})
  return useInfiniteQuery({
    queryKey: [
      "contentListing",
      "lazy",
      configs.contentTypes,
      configs,
      collectionId,
      activeLang,
    ],
    queryFn: ({ pageParam: skip = 0 }) => {
      return axios
        .get(`/api/discovery/getData`, {
          params: { configs: JSON.stringify({ ...configs, skip }) },
        })
        .then((res) => res.data);
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.skip;
    },
    ...(queryOptions || {}),
  });
};
