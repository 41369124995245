import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React from "react";

import { ToolbarButton } from "../comps/ToolbarButton";
import { config } from "./config";
import { handleClick } from "./handleClick";

export const QuoteTool = ({ activeNodeInfo }) => {
  const [editor] = useLexicalComposerContext();

  const checkIsActive = ({ type, activeNodeInfo }) =>
    type === activeNodeInfo?.__type;

  const { type, icon } = config[0];

  const isActive = checkIsActive({ type, activeNodeInfo });

  return (
    <ToolbarButton
      isActive={isActive}
      onClick={() => {
        handleClick({ isActive, editor });
      }}
      icon={icon}
    />
  );
};
