import React from 'react';
import { LABEL, TagDisplay, BlockLabel, colors } from "oolib";
import { StyledSectionTagsWrapper, StyledTagsWrapper } from '../styled';
import TagsInputMulti from '../../../../../components/inputs/DynamicTagsInputs/TagsInputMulti';

const TagsSection = ({ activeItem, allTpls }) => {
  return (
    <StyledSectionTagsWrapper>
      <div style={{ position: 'sticky', top: 0, backgroundColor: colors.greyColor5, padding: '1rem 0', width: '100%', zIndex: 1 }}>
        <LABEL >TAGS</LABEL>
      </div>
      <StyledTagsWrapper>
      <div>
        <BlockLabel label="Content Type"/>
        <TagDisplay
          display={
            allTpls.find(
              (d) => d.kp_content_type === activeItem.metaData?.contentType
            ).general.content.title
          }
        />
      </div>
        {activeItem.metaData?.subject?.data?.length > 0 && (
          <TagsInputMulti
            label="Subject"
            readOnly
            value={activeItem.metaData.subject}
          />
        )}
        {activeItem.metaData?.stakeholder?.data?.length > 0 && (
          <TagsInputMulti
            label="Stakeholder"
            readOnly
            value={activeItem.metaData?.stakeholder}
          />
        )}
        {activeItem.metaData?.facilityType && (
          <div>
            <BlockLabel label="Facility Type" />
            <TagDisplay
              display={activeItem.metaData?.facilityType.display}
            />
          </div>
        )}
        {activeItem.metaData?.country?.data.length > 0 && (
          <div>
            <BlockLabel label="Country" />
            <TagDisplay
              display={activeItem.metaData?.country.data[0].display}
            />
          </div>
        )}
        {activeItem.metaData?.states?.data.length > 0 && (
          <div>
            <BlockLabel label="State" />
            <TagDisplay
              display={activeItem.metaData?.states.data[0].display}
            />
          </div>
        )}
        {activeItem.metaData?.districts?.data.length > 0 && (
          <div>
            <BlockLabel label="District" />
            <TagDisplay
              display={activeItem.metaData?.districts.data[0].display}
            />
          </div>
        )}
        {activeItem.metaData?.teams?.data.length > 0 && (
          <div>
            <BlockLabel label="Team" />
            <TagDisplay
              display={activeItem.metaData?.teams.data[0].display}
            />
          </div>
        )}
      </StyledTagsWrapper>
    </StyledSectionTagsWrapper>
  );
};

export default TagsSection;
