import { TabBarStyle1, Wrapper1500 } from "oolib";
import React from "react";
import { StyledTabsPanelWrapper } from "./styled";

export const TabsPanel = ({ options, onChange, value, tabsRef, isMobile, showSideBarLeft }) => {
  return (
    <StyledTabsPanelWrapper ref={tabsRef} isMobile={isMobile} sidebar={showSideBarLeft}>
      <Wrapper1500 style={{ margin: isMobile ? '0 2rem' : "0 0 0 2rem" }}>
        <TabBarStyle1
          options={options}
          onChange={(id, v) => onChange(id, v)}
          value={value}
          saveValueAsString
        />
      </Wrapper1500>
    </StyledTabsPanelWrapper>
  );
};
