import { Modal, SANS_3, TabBarStyle1 } from 'oolib'
import React, { useState } from 'react'
import ListingPage from '../../../pageTpls/ListingPage'
import { defaultProfileCardConfig } from '../../../pageTpls/ProfilesListing/configs/defaultProfileCardConfig'
import { useGetQueryData } from '../../../utils/react-query-hooks/general'

const ReactionProfilesModal = ({reactionOnContent, title, onClose, typesOfReaction, onlyLikesExists}) => {

  const tpl = useGetQueryData(["tpl"]);

  const { listing } = tpl || {};

  const tabBarOptions = !onlyLikesExists ? [{display: 'All', value: 'all'} , ...Object.keys(typesOfReaction)?.map((reaction) => {
    return {
      display: reaction.charAt(0).toUpperCase() + reaction.slice(1),
      value: reaction
    }
  }).filter(Boolean)] : [{display: 'Likes', value: 'like'}]

  const [ activeView, setActiveView ] = useState(tabBarOptions[0])

  const userIds = reactionOnContent?.filter((reaction) => reaction?.type === activeView.value || activeView.value === 'all').map((reaction) => reaction.userId ?? null);

  const genConfig = () => ({
    getDataQuery: "useGetAllUsersLazy",
    getDataConfig: {
      findQuery: {
        _id: userIds,
		    kp_published_status: undefined 
	  },
      limit: 10,
      projection: {
        name: 1,
        avatar: 1,
        profileType: 1,
        userProfile: 1, 
      },
    },
    listingStyle: "UserProfilesStyle",
    listingConfig: listing?.listingConfig || { configs: defaultProfileCardConfig},
  })

  return (
    <Modal
        onClose={onClose}
        desktopWidth='50rem'
        title={title}
    >
     {!onlyLikesExists && <TabBarStyle1 options={tabBarOptions} value={activeView} onChange={(id, value) => setActiveView(value)} style={{ padding: "0 2rem" }}/>}
     {  reactionOnContent.length > 0 
     ?
        <ListingPage
            {...{
              configs: genConfig(),
              enableContainerQuery: true,
            }}
        />
      :  
        <SANS_3>There are no reactions to this content</SANS_3>
      }
    </Modal>
  )
}

export default ReactionProfilesModal