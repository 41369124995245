import { SANS_2, colors, ActionMenu, TagDisplay } from "oolib";
import React, { useRef, useEffect } from "react";

import { __GetTagTypeConfig } from "../../../../../../../../utils/getters/gettersV2";

import {
  TagDisplayContainerStyled,
  TagsContainerStyled,
  TagListStyled,
  StyledTextFragmentPreview,
  StyledActionMenuWrapper
} from "./styled";

const { annoPink, annoBlue } = colors;

const bgColor = {
  quotes: annoPink, // lightPink
  vocabs: annoBlue, // vocab
};

export function AnnoLightboxDisplay({
  readOnly,
  data,
  onRemove,
  onEdit,
  textFragment,
}) {
  const ContainerRef = useRef(null);

  const createArrayFromObj = (obj) => {
    if (!obj) return;
    const vocabAndQuote = [];
    const tags = [];
    Object.keys(obj).forEach((key) => {
      if (key === "vocabs" || key === "quotes") {
        vocabAndQuote.push({
          type: key,
          tags: obj[key].data,
        });
      } else {
        tags.push({
          type: key,
          tags: obj[key].data,
        });
      }
    });

    return { tags, vocabAndQuote };
  };

  useEffect(() => {
    const toolbarOffset = 120;
    const screenHeight = window.screen.height - toolbarOffset;

    /*
   using setTimout here because the bottom value returned is incorrect so putting a delay always gets the correct bottom value.

   when it happens : when you click on any annotation's "view in raw notes" buttom in synth view, it will take you to that annotation position in the raw view


   */

    setTimeout(() => {
      const { bottom } = ContainerRef.current?.getBoundingClientRect() || {};

      if (bottom && bottom > screenHeight) {
        window.scrollBy({ top: bottom - screenHeight, behavior: "smooth" });
      }
    }, 300);
  }, []);

  const { tags, vocabAndQuote } = createArrayFromObj(data);

  return (
    <TagDisplayContainerStyled ref={ContainerRef}>
      
      
        <TagsContainerStyled>
          {textFragment && <StyledTextFragmentPreview semibold>{textFragment}</StyledTextFragmentPreview>}
          {tags?.map((d) => {
            let tagTypeDisplay =
              __GetTagTypeConfig(d.type)?.content?.title || d.type;
            return d.tags?.length ? (
              <div>
                <SANS_2 semibold capitalize>
                  {tagTypeDisplay}
                </SANS_2>

                <TagListStyled>
                  {d.tags.map((dd, i) => (
                    <SANS_2>{`${dd.display}${
                      i !== d.tags.length - 1 ? "," : ""
                    }`}</SANS_2>
                  ))}
                </TagListStyled>
              </div>
            ) : null;
          })}

          {vocabAndQuote.length > 0 && (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                gap: "1rem",
                border: "none",
                marginTop: "0.5rem",
              }}
            >
              {vocabAndQuote.map((d) => (
                <TagDisplay
                  display={d.tags[0].display}
                  style={{ backgroundColor: bgColor[d.type] }}
                />
              ))}
            </div>
          )}
        </TagsContainerStyled>

        <StyledActionMenuWrapper>
          {!readOnly && (
            <ActionMenu
              align={"left"}
              actions={[
                { display: "Edit Tags", onClick: () => onEdit() },
                {
                  display: "Clear Tags",
                  onClick: () => onRemove(),
                },
              ]}
            />
          )}
        </StyledActionMenuWrapper>
      
    </TagDisplayContainerStyled>
  );
}
