import React, { useState } from 'react';
import { handleDataExport } from '../../../../utils/general';
import { OKELink, SANS_3 } from 'oolib';

const DownloadDataButton = ({ csvRefetch }) => {
    const[ exporting,setExporting] = useState(false)
  return (

    <SANS_3 style={{ marginLeft: "1rem" }}>
      <OKELink
        onClick={() => {
            handleDataExport({ refetch: csvRefetch, setExporting });
        }}
        color={"black"}
      >
        {exporting?"Downloading...":"Download Data"}
      </OKELink>
    </SANS_3>
  );
};

export default DownloadDataButton;