import React from 'react';
import { ButtonContentEngagement } from './ButtonContentEngagement';


export const ShareBtn = ({handleShareButtonClick, genShareUrl, invert, showText=true, sharesCount}) => {
  const text = sharesCount !== undefined ? `${sharesCount} Share${sharesCount !== 1 ? 's' : ''}` : showText ? "Share" : ''
 
  return(
    <ButtonContentEngagement
      invert={invert}
      icon='ShareNetwork'
      isLoading={genShareUrl.isLoading}
      text={text}
      onClick={handleShareButtonClick}
      />
  )  
};
