import { OKELink, SANS_3 } from "oolib";
import React from "react";


export const ListingSectionTitle = ({ sectionTitle, sectionLink }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
      <SANS_3 semibold>{sectionTitle}</SANS_3>
      <SANS_3>
        <OKELink
          to={sectionLink?.to}
          >
          {sectionLink?.text}
        </OKELink>
      </SANS_3>
    </div>
  );
};