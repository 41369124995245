import { cloneDeep } from "lodash";
import { getVal } from "oolib";

  export const handleDragEnd = ({
    dragState,
    ary,
    idValPath,
    handleDragOver,
    resetDragState,
    setDragState,
    handleReposition
  }) => {
    const { 
      draggedOnElemId, 
      isWhere, 
      dragElemId,
      draggedOn_draggableInstanceId,
      dragElem_draggableInstanceId 
    } = dragState;

    if (
      //basically same element on itself
      draggedOnElemId === dragElemId
      ||
      draggedOnElemId === undefined /**sometimes its undefined. not sure why. should investigae*/
    ){ return }; //then do nothing
      
      let newAry = [...ary];
    
      if(dragElem_draggableInstanceId === draggedOn_draggableInstanceId){
      
      

      //cuz this elem to be REMOVED from this idx 
      let toReposIdx = newAry.findIndex(
        (d) => getVal(d, idValPath) === dragElemId
      );

      let draggedOnElemIdx = newAry.findIndex(
        (d) => getVal(d, idValPath) === draggedOnElemId
      );
      let opToReposData = JSON.parse(JSON.stringify(newAry[toReposIdx]));

      let newIdx;
      if (draggedOnElemIdx < toReposIdx) {
        newIdx = isWhere === "above" ? draggedOnElemIdx : draggedOnElemIdx + 1;
      } else {
        //newIdx (draggedOn) > prevIdx (toRepos) ? then all elems drop idx by 1
        newIdx =
          isWhere === "above" ? draggedOnElemIdx - 1 : draggedOnElemIdx + 1 - 1;
      }
      newAry.splice(toReposIdx, 1);
      newAry.splice(newIdx, 0, opToReposData);

      handleDragOver.cancel();
      resetDragState(); //reset
    }else{
      /**
       * we are in the instance of the dragElem.
       * so here we can only do the remove action.
       */
       let toReposIdx = newAry.findIndex(
        (d) => getVal(d, idValPath) === dragElemId
      );
      let dragElemData = cloneDeep(newAry[toReposIdx])
      newAry.splice(toReposIdx, 1);
      /**
       * ...and then somehow pass a message to the other 
       * asking it to make the placement action
       */
      setDragState(prev => ({
        ...prev,
        beginInsertInOtherInstance: true,
        dragElemData
      }))
    }
    

    
    handleReposition(newAry)
    

    
  };