import React, { useState } from 'react'
import { CheckboxButton, Container,  PaddingBottom20,  SANS_2,  Wrapper700, GroupQuestionsInputSingle } from 'oolib'

export const RadioListMulti = () => {
  
  const [ value, setValue ] = useState({})

  const [readOnly, setReadOnly] = useState(false);
  console.log({ value })
  return (
    <Container>
      <PaddingBottom20 />
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <CheckboxButton
            value={readOnly}
            saveValueAsString
            onChange={(k, v) => setReadOnly(v)}
          />
          <SANS_2>Read Only</SANS_2>
        </div>
      <PaddingBottom20 />

            <Wrapper700>
            <GroupQuestionsInputSingle 
              label={"When shopping online, how often do you do each of the following?"}
              subQuestionLabels={[
                { display: 'Visit multiple websites', value: 'visit_multiple_websites' },
                { display: 'Read customer reviews', value: 'read_customer_reviews' },
                { display: 'Watch a video demonstration', value: 'watch_video_demo' },
                { display: 'Check the delivery date', value: 'check_delivery_date' },
                { display: 'Check for payment options', value: 'check_payment_options' },
              ]}
              options={[
                { display: 'Never', value: 'never' },
                { display: 'Rarely', value: 'rarely' },
                { display: 'Sometimes', value: 'sometimes' },
                { display: 'Often', value: 'often' },
                { display: 'Always', value: 'always' },
              ]} 
              value={value} onChange={(id, value) => setValue(value)}
              readOnly={readOnly}
              />
            </Wrapper700>
    </Container>
  )
}
