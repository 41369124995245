import React, { useState } from "react";
import ToggleBar from '../../../../generalUI/ToggleBar';

import KPInternalResSelect from '../KPInternalResSelect';
import KPExternalResSelect from '../KPExternalResSelect';
import { Modal } from "oolib";


const KPResourceSelectPopUp = ({
  id,
  allowNestedPublishing,
  cardDisplayBlockId,
  onConfirm : parentOnConfirm,
  selectedAry : parentSelectedAry,
  onCloseModal,
  contentTypeEmbedOptions,
  profileTypeEmbedOptions,
  linkTypesToShow,
  maxSelectLimit
}) => {

  const namesOfLinkTypes = {
    internal : 'Internal Link',
    external : 'External Link'
  }

  const getToggleOps = () => {
    return linkTypesToShow.map(type => {
      return { value: type, name : namesOfLinkTypes[type] }
    })
  }

  const [activeTab, setActiveTab] = useState(linkTypesToShow[0])
  const handleToggle = value => setActiveTab(value)

  const genToggleBar = () => (
    <ToggleBar
      className='kp-res-select-popup__link-type-toggle'
      options={getToggleOps()}
      onChange={ (key, value) => handleToggle(value) }
      />
  )

  return (
    
    <Modal title = 'Insert Link' onClose = {onCloseModal} >
    { getToggleOps().length > 1 && genToggleBar()}
    { activeTab === 'internal'
      ? <KPInternalResSelect
          id = {id}
          allowNestedPublishing = {allowNestedPublishing}
          cardDisplayBlockId = {cardDisplayBlockId}
          onConfirm = {parentOnConfirm}
          selectedAry = {parentSelectedAry}
          onCloseModal = {onCloseModal}
          contentTypeEmbedOptions =  {contentTypeEmbedOptions}
          profileTypeEmbedOptions =  {profileTypeEmbedOptions}
          maxSelectLimit = {maxSelectLimit}
        />
      : <KPExternalResSelect
          onConfirm = {parentOnConfirm}
          onCloseModal = {onCloseModal}
          linkTypesToShow = {linkTypesToShow}
          /> }
    </Modal>
    
  )
}

KPResourceSelectPopUp.defaultProps = {
  linkTypesToShow : ['internal', 'external']
}

export default KPResourceSelectPopUp;
