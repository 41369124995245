import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { __GetContentTypeConfigNew } from '../getters/gettersV2'

// import { useSocketContext } from '../../contexts/socketContext'
import { useBannerContext } from 'oolib'
import { executeInQueue } from '../executeInQueue'
import { useGenErrorMsgBanners } from '../useGenErrorMsgBanners'



//only used in the tci context
export const useGetTpls = () => {
	/**
	 * tplId is called kp_content_type in the tpl config.
	 * should rename eventually to tplId
	 */

	return useQuery(
		['tci', 'tpls'],
		() => axios.get(`/api/tpl/getAllTpls`).then((res) => res.data)
	)
}

export const useGetTplForEdit = (contentType) => {
	return useQuery(
		['tci', 'tplForEdit', contentType],
		() => axios.get(`/api/tpl/getTplForEdit/${contentType}`).then((res) => res.data)
	)
}

export const useCreateTpl = () => {
	const queryClient = useQueryClient()
	const { genErrorMsgBanners } = useGenErrorMsgBanners()
	return useMutation(
		({ kp_content_type, category, tpl, content, action, customBlocksInjectConfig }) =>
			axios.post(
				`/api/tpl/createTpl/${category}/${kp_content_type}`,
				{
					tpl,
					content,
					customBlocksInjectConfig
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
					params: {
						action
					}
				},
			),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['tci', 'tpls'])
				queryClient.invalidateQueries(['allTpls']) // this is the useGetTplsAndCacheSeparately query
			},
			onError: (err) => {
				genErrorMsgBanners({ err })
			}
		},
	)
}

const createMultipleTpls = async (queryPayloads) => {
	const results = await executeInQueue({
		dataAry: queryPayloads,
		callback: async ({
			kp_content_type, category, tpl, content, action, customBlocksInjectConfig
		}) => {
			try {
				const result = await axios.post(
					`/api/tpl/createTpl/${category}/${kp_content_type}`,
						{
							tpl,
							content,
							customBlocksInjectConfig
						},
						{
							headers: {
								'Content-Type': 'application/json',
							},
							params: {
								action
							}
						}
				)
				return ({status: 'success', result})
			} catch (err) {
				return ({status: 'error', err})
			}
			
		}
	})
	console.log({results})
	if(results.some(r => r.status === 'error')) throw ({errors: [{msg: 'Some tpls were not created'}]})
	return results
}

const generateOKFWorld = async ({queryPayloads, updateBasePlatformIsSetupFlag = false}) => {
	const multiTplResults = await createMultipleTpls(queryPayloads);
	let updateFlagResult;
	if(updateBasePlatformIsSetupFlag){
      updateFlagResult = await axios.patch(
        "/api/platformConfigs/updateOnePlatformConfig_new",
        { type: 'deployment', data: {_BASE_PLATFORM_IS_SETUP: true} },
        { headers: { "Content-Type": "application/json" } }
      )
	}
	return ({multiTplResults, updateFlagResult})
}
 
export const useGenerateOKFWorld = () => {
	const queryClient = useQueryClient()
	const {SET_INFO_BANNER} = useBannerContext();
	const { genErrorMsgBanners } = useGenErrorMsgBanners()
	return useMutation(
		({queryPayloads, updateBasePlatformIsSetupFlag}) => generateOKFWorld({queryPayloads, updateBasePlatformIsSetupFlag}),
		{
			onSuccess: (res) => {
				queryClient.invalidateQueries(['tci', 'tpls'])
				queryClient.invalidateQueries(['allTpls']) // this is the useGetTplsAndCacheSeparately query
				console.log({res})
				SET_INFO_BANNER('Templates created successfully!','green', 5000);
			},
			onError: (err) => {
				genErrorMsgBanners({ err })
			}
		},
	)
}

export const useForceUpdateTpl = () => {
	const { genErrorMsgBanners } = useGenErrorMsgBanners()
	return useMutation(
		(body) =>
			axios.post(
				"/api/tpl/forceUpdateTpl",
				body,
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			),
			{
				
				onError: (err) => {
					genErrorMsgBanners({ err })
				}
			},

	)
}

export const useSaveTplChanges = () => {

	// const { socket, UPDATE_TCI_TPL } = useSocketContext();

  const queryClient = useQueryClient()
  const {genErrorMsgBanners} = useGenErrorMsgBanners()
	return useMutation(
		({ tplConfig, contentType: _contentType }) => {
			const contentType = _contentType || tplConfig.kp_content_type
			return axios.patch(
				`/api/tpl/updateTpl/${contentType}`,
				tplConfig,
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			)
		},
		{
			onSuccess: (res, { type }) => {
        // update this tpl cache
				queryClient.setQueryData(['tpl', res.data.kp_content_type], res.data)
				// socket.emit(
				// 	UPDATE_TCI_TPL,
				// 	{ newTplData: res.data }
				// )
			},
	
				
				onError: (err) => {
					genErrorMsgBanners({ err })
				}
			
		}

	)
}

export const usePublishTpl = () => {
	const { SET_ALERT_BANNER,  } = useBannerContext()
    const { genErrorMsgBanners } = useGenErrorMsgBanners();
	const queryClient = useQueryClient()
	const history = useHistory();
	return useMutation(
		({ contentType }) =>
			axios.patch(
				`/api/tpl/publishTpl/${contentType}`,
				{},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			),
		{
			onSuccess: (res, {contentType}) => {
				SET_ALERT_BANNER({
					color: 'green',
					msg: 'Template published successfully!',
					timeOut: 3000,
				})

				queryClient.invalidateQueries(['tci', 'tpls'])


				const segment = res.data.general.segment
				if (segment === "collections") {
          history.push(`/platformBuilder/tagsManager`);
        } else {
          history.push(
            `/platformBuilder/contentTypesManager?activeTplSegment=${
              segment || "uncategorized"
            }`
          );
        }

				//this has to be after redirect since we dont want a refetch
				queryClient.removeQueries(['tci', 'tplForEdit', contentType])
			},
			onError: (err) => {
				genErrorMsgBanners({err})
			  },
			}
		
	)
}

export const useRevertTpl = () => {
	const { SET_ALERT_BANNER } = useBannerContext()
	const queryClient = useQueryClient()
	const history = useHistory();
	const { genErrorMsgBanners } = useGenErrorMsgBanners();
	return useMutation(
		({ contentType }) =>
			axios.patch(
				`/api/tpl/revertTplChanges/${contentType}`,
				{},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			),
		{
			onSuccess: (res, { contentType }) => {
				SET_ALERT_BANNER({
					color: 'green',
					msg: 'Template reverted successfully!',
					timeOut: 3000,
				})
				queryClient.invalidateQueries(['tci', 'tpls'])

				const config = __GetContentTypeConfigNew(contentType)
				if (config.general?.segment === "collections") {
					history.push(`/platformBuilder/tagsManager`);
				  } else {
					history.push(
					  `/platformBuilder/contentTypesManager?activeTplSegment=${
						config.general?.segment || "uncategorized"
					  }`
					);
				  }

				//this has to be after redirect since we dont want a refetch
				queryClient.removeQueries(['tci', 'tplForEdit', contentType])
			},
			
				
				onError: (err) => {
					genErrorMsgBanners({ err })
				}
			
		},
	)
}


export const useDeleteTpl = () => {
	const { SET_ALERT_BANNER } = useBannerContext()
	const queryClient = useQueryClient()
	const { genErrorMsgBanners } = useGenErrorMsgBanners();
	return useMutation(
		({ contentType }) =>
			axios.delete(`/api/tpl/deleteTpl/${contentType}`),

		{
			onSuccess: (res, { contentType }) => {
				SET_ALERT_BANNER({
					color: 'green',
					msg: `${contentType} template deleted successfully!`,
					timeOut: 3000,
				})
				queryClient.invalidateQueries(['tci', 'tpls'])
				queryClient.invalidateQueries(['allTpls'])
			},
			
				
				onError: (err) => {
					genErrorMsgBanners({ err })
				}
			
		},
	)
}


