import {
  AudioInput,
  Container,
  PaddingTopBottom50,
  Wrapper700
} from "oolib";
import React, { useState } from "react";



export const AudioUploadPreview = () => {


const [v, setV] = useState(undefined)


  return (
    <Container>
      <Wrapper700>
      <PaddingTopBottom50>
        <AudioInput
          label='Audio Input'
          value={v}
          onChange={(k,v) => setV(v)}
          />
     </PaddingTopBottom50>
      </Wrapper700>
    </Container>
  );
};
