import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { StyledSearchInputWrapper } from "./styled";

import { ButtonGhost, getBreakPoint, TextInput, useScreenWidth } from "oolib";



const KeywordSearch = ({
  setSearchInUse: parentSetSearchInUse,
  searchTermInputText,
  handleSearch,
  totalWidthAvailable,
}) => {
  const screenWidth = useScreenWidth();

  const [searchInUse, setSearchInUse] = useState(false);

  const [forceTextInputFocus, setForceTextInputFocus] = useState(false);

  const handleSetSearchInUse = (bool) => {
    setSearchInUse(bool);
    if (bool === false) setForceTextInputFocus(false);
    parentSetSearchInUse && parentSetSearchInUse(bool);
  };

  const closeSearchRef = useRef(null);
  const wrapperRef = useRef(null);

  const [closeSearchBtnWidth, setCloseSearchBtnWidth] = useState(0);
  const [initSearchBarWidth, setInitSearchBarWidth] = useState(0);
  useEffect(() => {
    setCloseSearchBtnWidth(
      closeSearchRef.current?.getBoundingClientRect().width || 0
    );
    setInitSearchBarWidth(wrapperRef.current?.getBoundingClientRect().width);
  }, []);

  const searchBarInitWidth = screenWidth < getBreakPoint("md") ? 0 : "100%";
  const searchBarAnimateWidth =
    screenWidth < getBreakPoint("md")
      ? searchInUse
        ? totalWidthAvailable - closeSearchBtnWidth
        : searchBarInitWidth
      : "100%";

  const genSearchIconButtonForMobile = () => (
    <div style={{ position: "absolute", marginLeft: "0.5rem" }}>
      <ButtonGhost
        icon="MagnifyingGlass"
        M
        onClick={() => {
          /**
           * so the way this works is, when this button is clicked,
           * we force focus on the textinput, which in turn, fires it's
           * onFocus event, which in turn sets 'searchInUse' state to true,
           * which in turn triggers the 'open text input animation
           */
          setForceTextInputFocus(true);
          // handleSetSearchInUse(true)
        }}
      />
    </div>
  );

  return (
    <div ref={wrapperRef} style={{ display: "flex", flex: "1 0 auto" }}>
      {
        <div style={{ position: "relative" }} id="okf_keyword_search"> {/** Id is v. important for coachmark to work */}
          {genSearchIconButtonForMobile()}
          <motion.div
            initial={{ width: searchBarInitWidth }}
            animate={{ width: searchBarAnimateWidth }}
            transition={{
              type: "tween",
              ease: "easeOut",
            }}
          >
            <StyledSearchInputWrapper
              // onBlur={() => handleSetSearchInUse(false)}
            >
              <TextInput
                type="text"
                icon="MagnifyingGlass"
                value={searchTermInputText}
                onChange={handleSearch}
                placeholder={"Search Here"}
                onFocus={() => handleSetSearchInUse(true)}
                forceFocus={forceTextInputFocus}
              />
            </StyledSearchInputWrapper>
          </motion.div>
        </div>
      }

      {
        <div
          style={{
            width:
              searchInUse && screenWidth < getBreakPoint("md") ? "auto" : 0,
            overflow: "hidden",
          }}
        >
          <div style={{ width: "fit-content" }} ref={closeSearchRef}>
            <ButtonGhost
              icon="X"
              M
              onClick={(ev) => {
                ev.stopPropagation();
                handleSearch(null,'')
                handleSetSearchInUse(false);
              }}
              style={{ flexShrink: 0 }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default KeywordSearch;
