export const tagCategoryFormConfig = [
  {
    comp: "TextToIDInput",
    valuePath: "general.content.title",
    isRequired: true,

    setValuePath: "kp_content_type",
    setValuePathFn: "genCleanCamelCaseId",
    props: {
      label: "Enter Tag Category name",
      placeholder: "Write here",
    },
  },

  {
    comp: "TextInput",
    valuePath: "general.content.listingDesc",
    isRequired: false,
    props: {
      label: "Description",
      placeholder: "Description for how the category works",
    },
  },
];

export const tagsFormConfig = [
  {
    comp: "Repeater",
    isRequired: true,
    valuePath: "tags",
    props: {
      label: "Create tags for this tag category",
      UIStyle: "minimal",
      repeatButtonText: "Create Tag",
      blocks: [
        {
          comp: "TextInput",
          isRequired: true,
          valuePath: "value",
          props: {
            label: "Tag Name",
            placeholder: "Write here",
          },
        },
      ],
    },
  },
];



