import axios from "axios";
import { useQuery } from "react-query";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";

export const extractValuePathAndDisplayPath = ({ blockValuePath, tpl }) => {
  if (!tpl) return {};
  const allBlocks = extractAllBlocksFromTpl({ tpl });
  const thisBlock = allBlocks.find((d) => d.valuePath === blockValuePath);
  switch (thisBlock.comp) {
    case "DropdownSingle":
    case "DropdownMulti":
    case "RadioList":
    case "CheckboxList":
      return {
        valuePath: blockValuePath + ".value",
        displayPath: blockValuePath + ".display",
      };
    default:
      return {};
  }
};

export const useGetCountForQuantField = ({
  contentType,
  tpl,
  blockValuePath,
  queryOptions = {},
  vizCardId,
  activeBlock
}) => {
  
  const getConfigs = () => ({
    contentTypes: [contentType],
    blockValuePath,
    ...(blockValuePath ? extractValuePathAndDisplayPath({ blockValuePath, tpl }): {}),
  })
  

  return useQuery({
    queryKey: ["useGetCountForQuantField", vizCardId, getConfigs()],
    queryFn: () =>
      axios
        .get(`/api/insightMatrix/getCountForQuantField`, {
          params: {
            configs: JSON.stringify(getConfigs()),
          },
        })
        .then((data) => data.data),
    ...queryOptions,
  });
};