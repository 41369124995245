import React from "react";
import { ButtonPrimary, ImageInput, PaddingTopBottom10, UI_HEADLINE, UI_PARAGRAPH, UI_TITLE, VideoInput } from "oolib";
import { StyledButtonPrimary, StyledCoverSection, StyledMetaSection, StyledTPLActionsWrapper } from "./styled";
import { MetaPrimary } from "../../../../components/generalUI/V2_MetaComps/MetaPrimary";
import TplActions from "../../../../components/generalUI/TplActions";
import Tags__Intro from "../../../../components/generalUI/Tags__Intro";

export const CoverSection = ({ content, title, desc, btn, coverImage,  coverVideo, metaPrimary, subtitle, tagsIntro, isMobile, showSideBarLeft }) => {

  return (
    <StyledCoverSection
      sidebar={showSideBarLeft} 
      isMobile={isMobile}
      style={{ padding: (title || desc) && "2rem" }}
    >   
        <StyledMetaSection>
          {metaPrimary && (
              <div style={{ width: '100%' }}>
                <MetaPrimary 
                  {...metaPrimary} 
                  content={content}
                  align={'left'}
                  />
              </div>   
          )}


          {title && <UI_HEADLINE>{title}</UI_HEADLINE>}

          {subtitle && ( <UI_TITLE>{subtitle}</UI_TITLE> )}

          {desc && (
              <UI_PARAGRAPH style={{ alignSelf: "flex-start" }}>{desc}</UI_PARAGRAPH>
          )}

          {tagsIntro ? (
              <Tags__Intro
              {...tagsIntro}
              content={content}
              Wrapper={PaddingTopBottom10}
              />
            ) : null}

          {(btn?.action && btn?.text) && ( 
            <StyledButtonPrimary icon={"Plus"} onClick={() => btn.action()}>
            {btn.text || "Publish"}
            </StyledButtonPrimary>
          )}
        </StyledMetaSection>

        <div> 
        { 
          coverVideo?.[0]?.publicUrl ?  <VideoInput value={coverVideo} readOnly={true}/> 
          : 
          coverImage?.[0]?.publicUrl ?  <ImageInput value={coverImage} readOnly={true} 
              onChange={() => {}} // RTE caption needs onChange even in readOnly
            />
          : 
          null
        }
        </div>

        {content && ( // positioned absolute at top right of Cover Section
            <StyledTPLActionsWrapper>
              <TplActions content={content} kebabStyle tplActionsIcon="DotsThreeCircle"/>
            </StyledTPLActionsWrapper>
        )}

    </StyledCoverSection>
  );
};
