import React, { useState, useRef } from "react";
import {
  convertSingleValueToTagsValue,
  convertTagDocsDataToOptions,
  useConvertTagsValueToSingleValue,
  useGetTypeCategoryAndTags,
} from "../utils";
import { useCreateTagV3 } from "../../../../utils/react-query-hooks/tags";
import Dropdown from "./comp/Dropdown";
import Radio from "./comp/Radio";
import { getKeyCode, useBannerContext } from "oolib";
import { useDebounce } from "../../../../utils/customHooks";

export default function TagsInputSingle(props) {
  const {
    id,
    onChange,
    value: valueProp,
    tagType: tagTypeProp,
    filters: filtersProp,
    postDALFilter, // this is hack config, check getAllUsers in okf-be to learn more
    allowCreateTag,
    siblingValuePath,
    content,
    rememberIsUserGenerated,
    optionConfig,
    incExcTagsList,
    invert,
    // ... can have several other props as well, check the Dropdown component to know which those are.

    /** these have to be controllable ALTHOUGH TagsInputSingle will ALWAYS be isTagsStyle true & isSearchable false
     * but, Dropdowns of optionDataType === 'collectionsData', also use this component, and for dropdowns, these
     * two props have to be controllable
     */
    isTagsStyle = true,
    isSearchable = false,
    readOnly,
    variant = "dropdown",

    displayCompStyle,
  } = props;

  const { SET_INFO_BANNER } = useBannerContext();
  const [createTagText, _setCreateTagText] = useState(undefined);
  const [showOptionsState, setShowOptionsState] = useState(false);
  const filteredOptions = useRef([]);
  const createTag = useCreateTagV3();


  const [searchInputTextDebounced, setSearchInputTextDebounced] = useState(""); // this is being read by the react-query hook
  const debounce = useDebounce();
  const setCreateTagText = (value) => {
    _setCreateTagText(value);
    debounce(() =>{ 
      setSearchInputTextDebounced(value)
    });
  };
  // const { searchInputText, searchInputTextDebounced, onSearchInputChange } = useSearchInputText();

  const { data, status, tagType, tagCategory, tagSegment, createTagAccess, observerRef, isFetchingNextPage } =
    useGetTypeCategoryAndTags({
      tagTypeProp,
      filtersProp,
      content,
      siblingValuePath,
      incExcTagsList,
      searchTerm: searchInputTextDebounced,
      enableDataFetch: variant === "radio" ? true : showOptionsState,
      postDALFilter,
      lazyLoad: variant === 'dropdown' && true
    });

  const tagCreateIsEnabled =
    isTagsStyle &&
    allowCreateTag === true &&
    (tagCategory === "tags" || tagSegment === "collections") &&
    createTagAccess;

  const handleOnChange = (k, v) => {
    let forParent = !v //this can happen if 'X' is hit on the tag comp
      ? undefined
      : convertSingleValueToTagsValue(tagType, v);

    onChange && onChange(id, forParent);
  };

  const handleCreateTag = () => {
    createTag.mutate(
      {
        type: tagType,
        display: createTagText,
        isUserGenerated: rememberIsUserGenerated !== false ? true : undefined,
        tagCategory,
      },
      {
        onSuccess: (res) => {
          const forParent = convertTagDocsDataToOptions(
            [res.data],
            tagCategory,
            optionConfig
          )[0];
          handleOnChange(id, forParent);
          setCreateTagText(undefined);
        },
        onError: ({ err }) => {
          SET_INFO_BANNER(err.response?.data?.errors[0]?.msg, "red", 3000);
        },
      }
    );
  };

  const handleCreateTagOnKeyDown = (e) => {
    if (!tagCreateIsEnabled || createTag.isLoading) return;
    let code = getKeyCode(e);

    if (
      code === "Enter" &&
      createTagText &&
      filteredOptions.current.every((op) => op.display !== createTagText)
    )
      handleCreateTag();
  };

  const value = useConvertTagsValueToSingleValue({
    value: valueProp,
    tagCategory,
    optionConfig,
  });

  return variant === "dropdown" ? (
    <Dropdown
    {...props}
      id={id}
      createTagText={createTagText}
      setCreateTagText={setCreateTagText}
      fetchTagStatus={status}
      createTagStatus={createTag.status}
      handleOnChange={handleOnChange}
      value={value}
      options={data}
      handleCreateTag={handleCreateTag}
      readOnly={readOnly}
      invert={invert}
      tagCreateIsEnabled={tagCreateIsEnabled}
      
      //
      isSearchable={isSearchable}
      observerRef={observerRef}
      isFetchingNextPage={isFetchingNextPage}
      isTagsStyle={isTagsStyle}
      displayCompStyle={displayCompStyle}
      optionConfig={optionConfig}
      tagCategory={tagCategory}
      filteredOptions={filteredOptions}
      handleCreateTagOnKeyDown={handleCreateTagOnKeyDown}
      setShowOptionsState={setShowOptionsState}
    />
  ) : (
    <Radio
    {...props}
      id={id}
      value={value}
      options={data}
      fetchTagStatus={status}
      handleOnChange={handleOnChange}
      handleCreateTag={handleCreateTag}
      createTagText={createTagText}
      setCreateTagText={setCreateTagText}
      createTagStatus={createTag.status}
      readOnly={readOnly}
      invert={invert}
      tagCreateIsEnabled={tagCreateIsEnabled}
    />
  );
}
