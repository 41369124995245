import React from "react";
import { convertToLexicalText, getRichTextAsPlainTextLex } from "../../../../../../utils/getters/gettersV2";
import { _Locale } from "../../../../../locale/Locale";
import LexicalTextEditor from "../../../LexicalTextEditor";
import {
  StyledSubtitleInputWrapper,
  StyledSummaryInputWrapper,
  StyledTitleInputWrapper,
} from "./styled";

const injectOptionalIntoPlaceholder = (placeholder, isRequired) => {
  if (isRequired === true) return placeholder;
  //else
  return placeholder ? placeholder + ` (${_Locale('optional')})` : `(${_Locale('optional')})`;
};

export function TitleInput({
  textAlignment = 'center',
  invert = true,
  placeholder = 'Enter a title',
  isRequired,
  value,
  onChange : parentOnChange,
  typeStyle= 'SERIF_8_9' // alt SERIF_7_8
}){

  const props = arguments[0]

  //title & subtitle is always saved as a string (also useful in the case of title to do unique string validation at db level)
  const onChange = (k,v) => {
    parentOnChange && parentOnChange(
      k, 
      getRichTextAsPlainTextLex({value: v})
    )
  }  

  return (
    <StyledTitleInputWrapper invert={invert}>
      <LexicalTextEditor
        hideOptionalLabel
        {...props}
        invert={invert}
        onChange={onChange}
        placeholder={injectOptionalIntoPlaceholder(
          placeholder,
          isRequired
        )}
        textAlignment={textAlignment}
        typo={typeStyle}
        variant={'simple'}
        value={convertToLexicalText(value)}
      />
    </StyledTitleInputWrapper>
  );
};

export function SubtitleInput({
  textAlignment = 'center',
  invert = true,
  placeholder,
  isRequired,
  value,
  onChange : parentOnChange,
  typeStyle= 'SERIF_3' // alt SERIF_7_8
}){

  const props = arguments[0]

  //title & subtitle is always saved as a string
  const onChange = (k,v) => {
    parentOnChange && parentOnChange(k, getRichTextAsPlainTextLex({value: v}))
  }

  return (
    <StyledSubtitleInputWrapper invert={invert}>
       <LexicalTextEditor
        hideOptionalLabel
        {...props}
        invert={false}
        onChange={onChange}
        placeholder={injectOptionalIntoPlaceholder(
          placeholder,
          isRequired
        )}
        textAlignment={textAlignment}
        typo={typeStyle}
        variant={'simple'}
        value={convertToLexicalText(value)}
      />
    </StyledSubtitleInputWrapper>
  );
};

export const SummaryInput = (props) => {
  return (
    <StyledSummaryInputWrapper>
      <LexicalTextEditor
        {...props}
        typo="SERIF_3_4 italic"
        variant={'simple'}
      />
    </StyledSummaryInputWrapper>
  );
};
