import React from "react";

import NCTWGData from '../../../../../../components/dataviz/NCTWGData';
import WQNDataDb from '../../../../../../components/dataviz/WQNDataDb';
import { AiChat } from "../../../../../../components/AiChat";

export const CustomCompRenderer = ({
  configs,
  content,
}) => {

  if(!configs?.comp) return null

  const comps = {
    NCTWGData,
    WQNDataDb,
    AiChat
  }
  const CompToRender = comps[configs.comp];

  return CompToRender
    ? <CompToRender {...{content}}/> 
    : null;
 
};
