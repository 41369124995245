import React from "react";
import {
  BlockLabel,
  DropdownSingle,
  NumberInput,
  getBlockLabelProps,
  colors,
} from "oolib";

function InputYearRange(props) {
  const { value: yearRange = [], onChange, id } = props;

  const handleYearChange = (index, value) => {
    const newVal = [...yearRange];
    newVal[index] = value;

    onChange(id, newVal);
  };

  return (
    <div>
      <BlockLabel {...getBlockLabelProps(props)} />
      <div
        style={{
          display: "flex",
          gap: ".5rem",
        }}
      >
        <div style={{ width: "8rem" }}>
          <NumberInput
            id={0}
            value={yearRange[0]}
            onChange={handleYearChange}
            placeholder="YYYY"
          />
        </div>

        <span
          style={{
            width: "1rem",
            height: ".2rem",
            background: "#383838",
            marginTop: "2rem",
          }}
        ></span>
        <div style={{ width: "8rem" }}>
          <NumberInput
            id={1}
            value={yearRange[1]}
            onChange={handleYearChange}
            placeholder="YYYY"
          />
        </div>
      </div>
    </div>
  );
}

export const DropdownOptionsAs = (props) => {
  const { value, onChange } = props;

  const handleOnChange = (id, val) => {
    const newVal = id === "fn" ? { [id]: val } : { ...value, [id]: val };
    onChange("custom", newVal);
  };
  return (
    <div
      style={{
        border: `1px solid ${colors.greyColor15}`,
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <DropdownSingle
        label="Choose An Option Function"
        value={value?.fn}
        id="fn"
        onChange={handleOnChange}
        placeholder={"Set years as options"}
        saveValueAsString={true}
        options={[
          { display: "Set years as options", value: "genYearsArray" },
          { display: "Set months as options", value: "genMonthsArray" },
        ]}
      />

      <div style={{ border: "1px solid #e1e1e1", padding: "2rem" }}>
        {value?.fn === "genYearsArray" ? (
          <InputYearRange
            value={value?.args}
            id="args"
            onChange={handleOnChange}
            label="Set Year"
          />
        ) : (
          <DropdownSingle
            label="Choose An Option"
            sublabel="Default value is Long Month Name"
            id="args"
            onChange={(id, val) => handleOnChange(id, [val])}
            value={value && value.args && value.args[0]}
            saveValueAsString={true}
            options={[
              { display: "Long Month Name", value: "long" },
              { display: "Short Month Name", value: "short" },
            ]}
          />
        )}
      </div>
    </div>
  );
};
