import React, { useEffect, useState, useRef } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw
 } from "draft-js";

import KPRichEditor from '../../../KPRichInput/comps/KPRichEditor';

const KPFAQInput = (props) => {

  //#1 : init refs for editor & editor-wrapper. wrapper ref helps us track focus change. editor ref literally helps us control focus change.
  // const editorRef = useRef(null);
  const wrapperRef = useRef(null);
  const [editorFocussed, setEditorFocussed] = useState(false);

  useTrackEditorFocus(wrapperRef/*, editorRef*/);

  function useTrackEditorFocus(wrapperRef/*, editorRef*/){
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) { //clicked outside editor
        setEditorFocussed(false);
      }else{ //clicked inside editor
        setEditorFocussed(true);
        // editorRef.current.focus(); //---> suddenly dont need this :/
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside)
      return () => document.removeEventListener("mousedown", handleClickOutside)
    });
  }

  const editorValue = props.value;
  if(editorValue && !editorValue.entityMap) editorValue.entityMap = {}; //for cases when data fetched from the DB doesnt already have the entitymap obj

  const [editorState, setEditorState] = useState(
    editorValue
    ? EditorState.createWithContent( convertFromRaw(editorValue) )
    : EditorState.createEmpty()
  );

  useEffect(() => {
    let contentStateRaw = convertToRaw(editorState.getCurrentContent());
    props.onChange && props.onChange(props.id, contentStateRaw); //send state data to parent comp.
  },[editorState])

  //#4 : manage editor state everytime it changes
  const onEditorChange = (key, editorState) => setEditorState(editorState)

  const richEditor = (
    <KPRichEditor
      id = {props.id}
      className = 'kp-faq-input'
      editorFocussed = {editorFocussed}
      readOnly = {props.readOnly}
      editorPrefix = {props.editorPrefix}
      editorState = {editorState}
      onChange = {(editorState) => onEditorChange('', editorState)}

      placeholder = {props.placeholder}
      /> )
      // ref = {editorRef} //used to be a prop on KPRichEditor
  //#8 : finally render the html
  return (
    <div className={`kp-faq-input-wrapper`} style={props.style}>
      <div ref={ wrapperRef } style={{ position : 'relative' }}>
      { richEditor }
      </div>
    </div>
  );
};


KPFAQInput.defaultProps = {
  id: 'kp_faq_input',
  readOnly: false
}

export default KPFAQInput;
