
import { getTenant } from "../../../../TENANT_CONFIGS";
import { annotationExplorerConfigs } from "../configs/_EXPORTS";
  

  
  
  export const getAnnoExplorerConfigsForThisTenant = () => {
    return annotationExplorerConfigs[getTenant()]
  }
  