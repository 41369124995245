import { SANS_2, SANS_3 } from "oolib";
import React from "react";
import { lexicalTextEditorHasValue } from "../../../../../../utils/validation/validatorFns";
import { reshapeDraftJSToLex_variantSimple } from "../../../../../inputs/TextEditor/LexicalTextEditor/utils/backwardsCompatibility/reshapeDraftJSToLex";
import { getRichTextAsPlainTextLex } from "../../../../../../utils/getters/gettersV2";




/**
 * @note maybe in the future this functionality could be integrated within TableText itself
 */
const RichTextAsPlainTextLex = ({ value: _value, typoComp = 'SANS_3' }) => {

  /*
  we need to do this cuz, SummaryInput has been shifted to Lex, but older instances of it
  will still have the draftjs structure. hence, in such cases, first we reshape draftjs to lex
  then we get plan text from it using getRichTextAsPlainTextLex
  */
  const value = reshapeDraftJSToLex_variantSimple(_value)

  if(!lexicalTextEditorHasValue(value)) return null;

  let comps = { SANS_2, SANS_3 };
  let Comp = comps[typoComp];
  let textString = getRichTextAsPlainTextLex({value})
    // console.log({textString})
  
  return (
    <Comp>
      {textString}
    </Comp>
  );
};

export default RichTextAsPlainTextLex;
