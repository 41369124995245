import {
  ButtonGhost,
  RadioList,
  SANS_3,
  TextInput,
  SANS_0,
  OKELink,
  genTagComp,
  BlockLabel,
  getBlockLabelProps,
} from "oolib";
import React, { Fragment } from "react";
import {
  convertTagDocsDataToOptions,
} from "../../../utils";

export default function Radio(props) {
  const {
    handleOnChange,
    id,
    fetchTagStatus,
    options,
    value,
    tagCategory,
    createTagText,
    setCreateTagText,
    handleCreateTag,
    tagCreateIsEnabled,
    createTagStatus,
    readOnly,
    invert,
  } = props;

  const genDisplayComp = () => {
    if(!value) return null
    return (
      <div className="OKE-Dropdown__tags-wrapper">
        {genTagComp(id, value, { display: true, invert })}
      </div>
    );
  };

  return readOnly ? (
    <div>
      <BlockLabel {...getBlockLabelProps(props)} />
      {genDisplayComp()}

    </div>
  ) : (
    <Fragment>
      {fetchTagStatus === "loading" ? (
        <SANS_0>{"Loading..."}</SANS_0>
      ) : fetchTagStatus === "error" ? (
        <SANS_0>{"Error loading options"}</SANS_0>
      ) : (
        fetchTagStatus === "success" && (
          <div>
            <RadioList
              {...props}
              onChange={handleOnChange}
              value={value}
              options={convertTagDocsDataToOptions(options, tagCategory)}
            />
            {tagCreateIsEnabled ? (
              <div style={{ paddingTop: "1rem" }}>
                {createTagText === undefined && (
                  <SANS_3>
                    <OKELink
                      text={"Add Other"}
                      icon="Plus"
                      onClick={() => setCreateTagText("")}
                    />
                  </SANS_3>
                )}
                {createTagText !== undefined && (
                  <div style={{ maxWidth: "32rem" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <SANS_3>{"Other, Please Specify"}</SANS_3>
                      <ButtonGhost
                        S
                        icon="X"
                        onClick={() => setCreateTagText(undefined)}
                      />
                    </div>
                    <TextInput
                      value={createTagText}
                      onChange={(k, v) => setCreateTagText(v)}
                      placeholder="Enter Tag Name"
                      // disabled={!!createTagText === false}
                      actionBtn={{
                        text:
                          createTagStatus === "loading" ? "Creating" : "Create",
                        onClick: handleCreateTag,
                        // disabled:  // prop disable is not working here
                        //   createTagStatus === "loading" ||
                        //   !!createTagText === false,
                      }}
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        )
      )}
    </Fragment>
  );
}
