
export const config = [
        {
         
            type: 'quote',
            icon: "Quotes",
            
        }
    ]
  
