import React from 'react'
import { ButtonSecondary, colors } from 'oolib';
import { removeBlock } from '../../../utils/richInputUtils';

const {primaryColor100} = colors;

const ContentBlockShell = ({
  richInputProps,
  readOnly,
  className,
  children,
  id,
}) => {
  
  
  
  const handleRemoveBlock = () => {
    const { editor, block } = richInputProps
    removeBlock(editor, block)
    richInputProps.handleSetTempReadOnly(false) //remove readOnly the minute block is deleted;
  }

  const handleOnFocus = (e) => {
    richInputProps.handleSetTempReadOnly &&
      richInputProps.handleSetTempReadOnly(true)
  }

  const handleOnBlur = (e) => {
    richInputProps.handleSetTempReadOnly &&
      richInputProps.handleSetTempReadOnly(false)
  }

  const handleMouseHover =(e)=> {
    if (richInputProps.isInEditor && !readOnly) {
      e.currentTarget.style.outline = (
        e.type === 'mouseenter'? `1px solid ${primaryColor100}` : 'none'
      );
    }
  }

  return (
    <div
      className={`kp-content-block-shell ${className}`}
      id={id}
      onFocus={(e) => handleOnFocus(e)}
      onBlur={(e) => handleOnBlur(e)}
      style={{ position: 'relative' }}
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseHover}
    >
      {children}
      {richInputProps.isInEditor && !readOnly && ( //actions only when block is rendered in editor and is not readonly
        <div style={{
          position: 'absolute',
          top: '2rem',
          right: '2rem'
        }}>
          <ButtonSecondary
            onClick={handleRemoveBlock}
            icon="Trash"
            />
        </div>
      )}
    </div>
  )
}

export default ContentBlockShell
