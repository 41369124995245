import { colors, globalLightboxStyle, mediaQuery, transition } from "oolib";
import styled, { css } from "styled-components";

export const StyledReactionsPanel = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 1rem;
    display: flex;
    color: #383838;
    width: max-content;
    cursor: pointer;
    ${({ fixPos, panelDimensions }) => 
        fixPos ? 
          css`  
                position: fixed;
                top: ${fixPos.y + panelDimensions.btnHeight}px;
                left: ${fixPos.x}px; 
                transform: translateX(-80%); // align to right as likes button is on the right side of viewport
            `
            : 
          css`
                position: absolute;
                bottom: -${panelDimensions?.btnHeight}px;
            `
    
    }

`

export const StyledButtons = styled.button`
    border: none;
    display: flex;
    padding: 0.4rem;
    background-color: ${colors.none};
    cursor: pointer !important;
    &:hover {
        background-color: ${colors.primaryColor10};
    }

    color: ${({ invert }) =>
      invert ? colors.primaryColor40 : colors.greyColor100};
    & > * {
      ${transition("color")};
    }
    ${transition("background-color")}
`

export const StyledWrapperIcons = styled.div`
    display: flex;
    padding: 0.3rem;
    /* position: absolute; */
    /* bottom: 0; */
    ${globalLightboxStyle}
`

export const StyledWrapperText = styled.div`
    padding: 0.3rem;
    width: ${({ panelDimensions }) => panelDimensions.width ? `${panelDimensions.width}px` : 'inherit'};
    ${globalLightboxStyle}
    
    ${({ fixPos, panelDimensions }) => 
        fixPos ? 
          css`  
                position: fixed;
                top: ${fixPos.y + panelDimensions.btnHeight + panelDimensions.height}px;
                left: ${fixPos.x}px; 
                transform: translateX(-80%); // align to right as likes button is on the right side of viewport
            `
            : 
          css`
                position: absolute;
                bottom: -${panelDimensions?.btnHeight + panelDimensions.height - 10}px;
            `
    
    }
`