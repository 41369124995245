import {
  ButtonGhost,
  ButtonPrimary,
  ButtonSecondary,
  Container,
  DropdownMulti,
  Loader,
  PaddingBottom20,
  PaddingTopBottom45,
  SANS_5_6,
  SANS_7_8,
  Wrapper700,
  colors,
  formatTextToCamelCase,
  setVal,
} from "oolib";
import React, { useState } from "react";
import { useGetQueryData } from "../utils/react-query-hooks/general";
import { useGenerateOKFWorld } from "../utils/react-query-hooks/tci";
import { TextToIDInput } from "./PlatformBuilder/globalUtilityComps/TextToIDInput";
import { useHistory } from "react-router-dom";
import { queryClient } from "..";

const Shell = ({ children }) => (
  <div
    style={{
      marginBottom: "1rem",
    }}
  >
    <div
      style={{
        minHeight: "8rem",
        padding: "1rem",
        backgroundColor: colors.white,
      }}
    >
      {children}
    </div>
  </div>
);

export const WorldEngine = ({ updateBasePlatformIsSetupFlag }) => {
  const history = useHistory()
  const [step, setStep] = useState(1);
  const [tags, setTags] = useState([{ tag: { display: "", value: "" } }]);
  const [allPossibleTags, setAllPossibleTags] = useState([]);
  //this is a test for the contettypes section
  const allTpls = useGetQueryData("allTpls");

  //in the case of nested tags, we extract only the deepest one
  //currently the possibility to track nested tags does not exist, so we just
  //apply that logic for nested tags in the above tags state.
  const extractAllPossibleTags = ({ allTpls, tagsLocalState }) => {
    const tagsFromTpls = allTpls
      .filter(
        (d) =>
          d.status !== "unpublished" && d.general?.segment === "collections"
      )
      .map((d) => ({
        value: d.kp_content_type,
        display: d.general.content?.title || d.kp_content_type,
      }));

    const tagsFromLocalState = tagsLocalState
      .map((d) => {
        const recurs = (obj) => {
          if (obj.nested) {
            return recurs(obj.nested);
          } else {
            return obj.tag;
          }
        };
        return recurs(d);
      })
      .filter((d) => !!d.value);

    return [...tagsFromTpls, ...tagsFromLocalState];
  };

  const [contentTypes, setContentTypes] = useState([
    { contentType: { display: "", value: "" } },
  ]);

  const generateOKFWorld = useGenerateOKFWorld();

  const recursGenNestedTagNameInput = ({
    datum,
    zeroDepthDatumIdx,
    pathToDatum = null, //null means the datum is at the root itself (in context of the getVal/setVal function)
    depthIdx = 0,
  }) => {
    return (
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              ...(datum.nested ? { paddingBottom: "1rem" } : {}),
              paddingLeft: `${depthIdx * 2}rem`,
            }}
          >
            <TextToIDInput
              S
              value={datum.tag.display}
              onChange={(k, v) => {
                setTags((p) => {
                  const newP = [...p];
                  newP[zeroDepthDatumIdx] = setVal(
                    newP[zeroDepthDatumIdx],
                    pathToDatum === null ? "tag" : pathToDatum + ".tag",
                    { display: v, value: formatTextToCamelCase(v) }
                  );
                  return newP;
                });
              }}
            />
            {!datum.nested && (
              <ButtonGhost
                icon="Plus"
                onClick={() =>
                  setTags((p) => {
                    const newP = [...p];
                    newP[zeroDepthDatumIdx] = setVal(
                      newP[zeroDepthDatumIdx],
                      pathToDatum === null ? "nested" : pathToDatum + ".nested",
                      { tag: { display: "", value: "" } }
                    );
                    console.log({ newGonBe: newP });
                    return newP;
                  })
                }
              />
            )}
          </div>
        </div>
        {datum.nested &&
          recursGenNestedTagNameInput({
            datum: datum.nested,
            zeroDepthDatumIdx,
            pathToDatum: depthIdx === 0 ? "nested" : pathToDatum + ".nested",
            depthIdx: depthIdx + 1,
          })}
      </>
    );
  };

  const handleCreateAllTpls = () => {
    //we need to prep the payload for each of the createTpl queries
    //for nested ones:
    // --- each nested on will be tagged with every tag above it
    //for first in nested ones and independent first ones:
    // --- customBlocksInjectConfig will not be required

    const queryPayloads = [];
    const genQueryPayloadObj = ({
      kp_content_type,
      title,
      customBlocksInjectConfig,
      segment,
      category,
    }) => ({
      kp_content_type,
      category,
      tpl: {
        general: {
          segment,
          content: {
            title,
          },
        },
      },
      ...(customBlocksInjectConfig
        ? {
            customBlocksInjectConfig,
          }
        : {}),
      // content: tags.map((tag) => ({ //will use this later in case we decide to generate test data
      //   main: { title: tag.value },
      //   meta: { kp_content_type },
      // })),
      action: "CREATE_AND_PUBLISH",
    });
    tags.forEach((d) => {
      queryPayloads.push(
        genQueryPayloadObj({
          segment: "collections",
          category: "groupsStyle1",
          kp_content_type: d.tag.value,
          title: d.tag.display,
        })
      );
      const prepPayloadForNestedTags = ({
        nestedDatum,
        queryPayloads,
        parentTags,
      }) => {
        queryPayloads.push(
          genQueryPayloadObj({
            segment: "collections",
            category: "groupsStyle1",
            kp_content_type: nestedDatum.tag.value,
            title: nestedDatum.tag.display,
            customBlocksInjectConfig: {
              valuePath: "kp_templates.body.subSpaces.0.configs",
              blocks: parentTags.map((tag) => ({
                comp: "TagsInputSingle",
                valuePath: `tags.${tag.value}`,
                props: {
                  label: tag.display,
                  id: `tags.${tag.value}`,
                  tagType: tag.value,
                },
              })),
            },
          })
        );
        if (nestedDatum.nested) {
          prepPayloadForNestedTags({
            nestedDatum: nestedDatum.nested,
            queryPayloads,
            parentTags: [...parentTags, nestedDatum.tag],
          });
        }
      };

      if (d.nested) {
        prepPayloadForNestedTags({
          nestedDatum: d.nested,
          queryPayloads,
          parentTags: [d.tag],
        });
      }
    });

    contentTypes.forEach((d) => {
      queryPayloads.push(
        genQueryPayloadObj({
          segment: "publishing",
          category: "knowledgeResources2",
          kp_content_type: d.contentType.value,
          title: d.contentType.display,
          ...(d.tagCategories
            ? {
                customBlocksInjectConfig: {
                  valuePath: "kp_templates.body.configs",
                  blocks: [
                    {
                      sectionTitle: "Settings",
                      sectionId: "settingsSection",
                      blocks: d.tagCategories.map((ddd) => ({
                        comp: "TagsInputSingle",
                        valuePath: `tags.${ddd.value}`,
                        props: {
                          label: ddd.display,
                          id: `tags.${ddd.value}`,
                          tagType: ddd.value,
                        },
                      })),
                      SectionWrapperComp: "SettingsSectionShell",
                    },
                    {
                      comp: "LexicalTextEditor",
                      isRequired: true,
                      valuePath: "main.content",
                      props: {
                        id: "main.content",
                        label: "Content",
                        placeholder: "Write here",
                      },
                    },
                  ],
                },
              }
            : {}),
        })
      );
    });

    console.log({ queryPayloads });
    generateOKFWorld.mutate(
      {
        queryPayloads,
        updateBasePlatformIsSetupFlag,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("platformConfigs");
          if(updateBasePlatformIsSetupFlag){
            history.push('/platformBuilder/contentTypesManager')
          }else{
            setStep(3);
          }
          
        },
      }
    );
  };

  console.log({ tagsState: tags });
  return (
    <div style={{ backgroundColor: colors.greyColor3, height: "100vh" }}>
      <Container>
        <Wrapper700>
          {generateOKFWorld.isLoading ? (
            <Loader isBlock blockHeight="300px">
              Generating Platform...
            </Loader>
          ) : step === 3 ? (
            <SANS_7_8>Platform Generated Successfully!</SANS_7_8>
          ) : (
            <PaddingTopBottom45>
              <PaddingBottom20>
                <SANS_5_6 semibold>
                  {step === 1
                    ? "1/2 Define Tag Categories"
                    : "2/2 Define Content Types"}
                </SANS_5_6>
              </PaddingBottom20>
              {step === 1 ? (
                <>
                  {tags.map((d, i) => (
                    <Shell>
                      {recursGenNestedTagNameInput({
                        datum: d,
                        zeroDepthDatumIdx: i,
                      })}
                    </Shell>
                  ))}
                  <ButtonGhost
                    icon="Plus"
                    onClick={() =>
                      setTags((p) => [
                        ...p,
                        { tag: { display: "", value: "" } },
                      ])
                    }
                  />
                </>
              ) : (
                <>
                  {contentTypes.map((d, i) => (
                    <Shell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2rem",
                        }}
                      >
                        <TextToIDInput
                          label="Name of Content Type"
                          S
                          value={d.contentType.display}
                          onChange={(k, v) => {
                            setContentTypes((p) => {
                              const newP = [...p];
                              newP[i] = {
                                ...(newP[i] || {}),
                                contentType: {
                                  display: v,
                                  value: formatTextToCamelCase(v),
                                },
                              };
                              return newP;
                            });
                          }}
                        />
                        <div>
                          <DropdownMulti
                            label={"Tag Categories"}
                            isTagsStyle={true}
                            options={allPossibleTags}
                            value={d.tagCategories}
                            onChange={(k, v) => {
                              setContentTypes((prev) => {
                                const newP = [...prev];
                                newP[i].tagCategories = v;
                                return newP;
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Shell>
                  ))}
                  <ButtonGhost
                    icon="Plus"
                    onClick={() =>
                      setContentTypes((p) => [
                        ...p,
                        {
                          tagCategories: allPossibleTags,
                          contentType: { display: "", value: "" },
                        },
                      ])
                    }
                  />
                </>
              )}
              <div style={{ paddingTop: "2rem" }}>
                {step === 1 ? (
                  <ButtonPrimary
                    iconAfter={"CaretRight"}
                    onClick={() => {
                      const _allPossibleTags = extractAllPossibleTags({
                        tagsLocalState: tags,
                        allTpls,
                      });
                      setAllPossibleTags(_allPossibleTags);
                      if (contentTypes[0] && !contentTypes[0].tagCategories) {
                        //this suggests contentTypes state is in its initial untouched state. hence we should set the extractedalltags
                        setContentTypes((prev) => {
                          const newP = [...prev];
                          newP[0].tagCategories = _allPossibleTags;
                          return newP;
                        });
                      }
                      setStep(2);
                    }}
                  >
                    Proceed to Define Content Types
                  </ButtonPrimary>
                ) : (
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <ButtonSecondary
                      M
                      icon="CaretLeft"
                      onClick={() => setStep(1)}
                    />
                    <ButtonPrimary
                      iconAfter={"CaretRight"}
                      onClick={handleCreateAllTpls}
                    >
                      Generate Platform
                    </ButtonPrimary>
                  </div>
                )}
              </div>
            </PaddingTopBottom45>
          )}
        </Wrapper700>
      </Container>
    </div>
  );
};
