import styled from "styled-components";
import { mediaQuery } from 'oolib';

export const StyledChartWrapper = styled.figure`
  ${mediaQuery('md')}{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 250px 1fr;
    align-items: center;
    
    .legend, .pie {
      grid-row: 1 / 2;
    }
    .legend { grid-column: 1 / 2; }
    .pie { grid-column: 2 / 3; }
  }
`;

