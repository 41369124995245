import { getVal } from "oolib";
import { extractAllBlocksFromTpl } from "../../../../utils/templating/extractAllBlocksFromTpl";

export const separateEachAnnoIntoIndependentDoc = ({ data, selectedTpls }) => {
  const annoFields = selectedTpls
    .map((tpl) => {
      const allBlocks = extractAllBlocksFromTpl({ tpl });
      return {
        contentType: tpl.kp_content_type,
        annoFields: allBlocks
          .filter((d) => d.props.annotation?.enable === true)
          .map((d) => d.valuePath),
      };
    })
    .reduce((a, b) => ({ ...a, [b.contentType]: b.annoFields }), {});

  return data.data
    .filter((doc) =>
      selectedTpls.some(
        (tpl) => tpl.kp_content_type === doc.meta.kp_content_type
      )
    )
    .map((doc) => {
      const allAnnosInThisDoc = annoFields[doc.meta.kp_content_type]
        .map((valuePath) => {
          const annoDataForThisField = getVal(doc, valuePath)?.annoData;
          return (
            annoDataForThisField &&
            Object.keys(annoDataForThisField).map((d, i) => ({
              ...annoDataForThisField[d],
              annoKey: d,
            }))
          );
        })
        .filter(Boolean)
        .reduce((a, b) => [...a, ...b], []);

      return allAnnosInThisDoc.map((d) => {
        const toReturn = {
          ...d,
          annoTags: d.tags,
          docTags: doc.tags,
          _id: doc._id,
          title: doc.main?.title || '',
          meta: {
            kp_content_type: doc.meta.kp_content_type,
            contentTypeTitle: selectedTpls.find(
              (d) => d.kp_content_type === doc.meta.kp_content_type
            )?.general?.content?.title,
          },
        };
        delete toReturn.tags;
        return toReturn;
      });
    })
    .reduce((a, b) => [...a, ...b], []);
};
