  import axios from "axios";

export const cata_uniqueStringValidationPlugin = async ({value, passValidationErrorToFormValidation, content}) => {
  try {
    const query = value.replace(/\s+/g, "").toLowerCase().trim(); //the regex replaces contagious spaces with an empty string.

    const configs = {
      findQuery: {
        "main.fakeName_lower": query, //has a participant with the same fake name
        kp_published_status: "published", //should be published
        _id: { $nin: [content._id] }, //ignore THIS document itself in case this is an editPublished
      },
      contentTypes: ["pSenegal"],
    };
    const res = await axios.get(`/api/discovery/getData`, {
      params: { configs: JSON.stringify(configs) },
    });

    let foundDuplicates = res.data?.data || [];
    // /** below two lines is to ignore this 'no duplicate' rule between clones of the same document. */
    let ogDocId = content.kp_pre_mod_doc || content.kp_og_published_doc; //if this exists, it suggests this doc is either an under mod clone or an edit pbl clone
    if (ogDocId)
      foundDuplicates = foundDuplicates.filter((d) => d._id !== ogDocId);

    if (foundDuplicates?.length > 0) {
      let errorObj = {
        type: "error",
        msg: "This fake name already exists in the system. Please try a different one.",
        link: {
          text: "View Duplicate",
          to: `${
            process.env.NODE_ENV === "production"
              ? `${window.location.protocol}//${window.location.hostname}`
              : "http://localhost:3000"
          }/published-page/pSenegal?id=${
            res.data.data[0]._id
          }`,
        },
      };
      if (passValidationErrorToFormValidation)
        passValidationErrorToFormValidation(errorObj.type);
      return errorObj;
    }
    //else
    return undefined;
    // return res;
  } catch (err) {
    console.log({ err });
  }
};
