import { motion } from "framer-motion";
import styled from "styled-components";
import { blurPage } from "../../styled";


export const StyledContentSection = styled(motion.div)`
  height: inherit;
  padding: 2rem;
  display: flex;
  flex-grow: 1;  
  transition: width 0.5s ease-in-out;
  position: relative;
  ${blurPage}

  transition: width 0.5s ease-in-out;
  width: ${({ sidebar, sidebarWidth }) => sidebar ? `calc(100% - ${sidebarWidth}px)` : '100%'};
`;


export const StyledSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  align-items: ${({ sidebar }) => sidebar ? 'start' : 'center'}; 
  min-width: 0;
  /* margin-right: ${({ listingStyle }) => listingStyle === "TableStyle" ? "-2rem" : 0}; */
  transition: all 0.5s ease-in-out;
  margin-top: -2rem; // temp padding for top PaddingTopBottom20 in 
`
