import React, { createContext, useContext, useEffect, useMemo, useState, useRef, useCallback } from "react";
import { useGetContribution } from "../contributions";
import { useLocation, useParams } from "react-router-dom";
import { getQueryParam } from "../../general";
import { useGetQueryData } from "../general";
import { __GetContentTypeConfigNew } from "../../getters/gettersV2";
import { useCreateReaction, useDeleteReaction } from ".";
import { useDebounce } from "../../customHooks";
import { useQueryClient } from "react-query";

export const ReactionsContext = createContext();



export const ReactionsContextProvider = ({ children, onboardingProps, contributionQueryData }) => {



    const deleteReaction = useDeleteReaction();
    const createReaction = useCreateReaction()
    const [isLoading, setIsLoading] = useState(false);
    let queryClient = useQueryClient();
    const debounce = useDebounce();
    const isInitialClickRef = useRef(true);
    const timeoutRef = useRef(null);
    

    const { content_type } = useParams();
    const contentType = onboardingProps?.contentType || content_type;
    const location = useLocation();
    const userData = useGetQueryData("userData");
    const contentTypeConfig = __GetContentTypeConfigNew(contentType);
    
    const { reactions } = contentTypeConfig?.general;

    const contentId = getQueryParam(location.search, "id");

    const typesOfReactions = Object.fromEntries( Object.entries(reactions).filter(([key, value]) => value.enable !== undefined) );


    const reactionOnContent = contributionQueryData?.contribution?.reactions || []

    const { hasThisUserAlreadyReacted, reactionUserId, usersCurrentReaction } = useMemo(() => 
      reactionOnContent?.reduce(
        (acc, reaction) => {
          if (reaction?.userId === userData?.user?._id) {
            acc.hasThisUserAlreadyReacted = reaction?.userId;
            acc.reactionUserId = userData?.user?._id;
            acc.usersCurrentReaction = reaction?.type;
          }
          return acc;
        },
        { hasThisUserAlreadyReacted: undefined, reactionUserId: undefined, usersCurrentReaction: undefined }
      ), [reactionOnContent])


    const reactionsKeys = Object.keys(typesOfReactions)

    const [ reacted, setReacted ] = useState(hasThisUserAlreadyReacted ? true : false)
    const defaultIcon = reactionsKeys?.length > 1 ? "Reactions" :  "ThumbsUp" 
    const [ reactionCount, setReactionCount ] = useState(reactionOnContent?.length || 0)
    const [ reactionIcon, setReactionIcon ] = useState(typesOfReactions[usersCurrentReaction]?.icon || defaultIcon )
    
     // keeps track of current reaction type for UI to stay updated with latest reaction
    const [ currentReactionType, setCurrentReactionType ] = useState(usersCurrentReaction);

    const handleReaction = (type) => {
      
      if ( isLoading || deleteReaction.isLoading || createReaction.isLoading) return;

      setIsLoading(true);
        
      const isSameReaction = currentReactionType === type;

      if (reacted && isSameReaction) {

              if(isSameReaction){ // user is removing reaction so updating local state for UI
                setReacted(false)
                setReactionCount(prev => prev === 0 ? 0 : prev - 1) // never show negative value 
                setReactionIcon(defaultIcon)
                setCurrentReactionType(null);
              }

          deleteReaction.mutate({ data: userData, contentType, contentId, reactionUserId, type },
              {
                onError: handleError,
                onSuccess: () =>  invalidateReactions(),
                onSettled: () => setIsLoading(false)
              }, 
              );
  
           
        }else {
          // User is adding or changing the reaction
          setReacted(true);
          setReactionCount(prev => reacted ? prev : prev + 1);
          setReactionIcon(typesOfReactions[type]?.icon);
          setCurrentReactionType(type);  // Set new reaction type

          // if(hasThisUserAlreadyReacted){ // user is changing reaction so updating local state for UI
          //   setReacted(true)
          //   setReactionCount(prev => prev)
          //   setReactionIcon(typesOfReactions[type]?.icon)
          // }
          // if(!hasThisUserAlreadyReacted){
          //   setReacted(true)
          //   setReactionCount(reactionOnContent.length + 1)
          //   setReactionIcon(typesOfReactions[type]?.icon)
          // }
          

          createReaction.mutate(
            { data: userData, contentType, contentId, type }, 
            {
              onSuccess: () => invalidateReactions(),
              onError: handleError,
              onSettled: () => setIsLoading(false)
            }
          );

        }
    }
    
    const invalidateReactions = useCallback(() => { // sync actual data with local state
        queryClient.invalidateQueries(['Contributions', 'view', contentType, contentId], {
            isLoading: () => setIsLoading(true),
            onSuccess: () => {
              setReacted(hasThisUserAlreadyReacted);
              setReactionCount(reactionOnContent.length);
              setReactionIcon(typesOfReactions[usersCurrentReaction]?.icon);
              setCurrentReactionType(usersCurrentReaction); 
              setIsLoading(false);
            }
        });
    }, [ contributionQueryData ]);

    const handleError = () => { // revert everything back to inital state
      setReacted(hasThisUserAlreadyReacted);
      setReactionCount(reactionOnContent.length);
      setReactionIcon(hasThisUserAlreadyReacted ? typesOfReactions[usersCurrentReaction]?.icon : defaultIcon);
      setCurrentReactionType(usersCurrentReaction); 
      setIsLoading(false);
    };

    const handleReactionClick = useCallback((type) => {
        if (isInitialClickRef.current) {
            isInitialClickRef.current = false;
            handleReaction(type);
            
            // Reset the initial click state after a delay
            timeoutRef.current = setTimeout(() => {
                isInitialClickRef.current = true;
            }, 2000);
        } else {
            debounce(() => handleReaction(type), 2000);
        }
    }, [handleReaction, debounce]);

    useEffect(() => {
      return () => {
          if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
          }
      };
    }, []);

    return(
        <ReactionsContext.Provider
            value={{ 
                reactionOnContent,
                userData,
                reacted, 
                setReacted,
                reactionCount,
                setReactionCount,
                reactionIcon,
                setReactionIcon,
                usersCurrentReaction,
                hasThisUserAlreadyReacted,
                reactionUserId,
                defaultIcon,
                typesOfReactions,
                handleReactionClick,
                createReaction,
                deleteReaction,
                isLoading
             }}
            >
            {children}
        </ReactionsContext.Provider>
    )
}

export const useReactionsContext = () => useContext(ReactionsContext)