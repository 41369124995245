import React from 'react';
import { ButtonPrimary, ImageInput, icons } from "oolib";
import { StyledCaretRight, StyledCaretLeft, StyledModalWrapper, StyledModal, StyledActionWrapper, StyledImageWrapper, StyleContentWrapper, StyledLink, StyledImage } from "../styled";
import { colors } from "oolib";
import TagsSection from './TagsSection';


const { CaretRight, CaretLeft } = icons;
const ImageModal = ({ 
  showModal,
  onClose,
  activeIndex,
  onPrevious,
  onNext,
  mediaCollection,
  allTpls
}) => {
  if (!showModal) return null;
  
  const someTagExists = (d) =>
    d.metaData?.contentType ||
    d.metaData?.subject?.data?.length > 0 ||
    d.metaData?.stakeholder?.data?.length > 0 ||
    !!d.metaData.facilityType === true ||
    d.metaData?.teams?.data?.length > 0;

  const activeItem = mediaCollection[activeIndex];

  return (
    <StyledModalWrapper>
      <StyledCaretLeft onClick={onPrevious}>
        <CaretLeft weight="duotone" size={75} color={colors.white} />
      </StyledCaretLeft>
      <StyledCaretRight onClick={onNext}>
        <CaretRight weight="duotone" size={75} color={colors.white} />
      </StyledCaretRight>

      <StyledModal>
        <StyledActionWrapper>
          <ButtonPrimary
            icon="DownloadSimple"
            onClick={() => window.open(activeItem.mediaLink, "_blank")}
          />
          <ButtonPrimary icon="X" onClick={onClose} />
        </StyledActionWrapper>

        <StyleContentWrapper tagExists={someTagExists(activeItem)}>
          {someTagExists(activeItem) && (
            <TagsSection activeItem={activeItem} allTpls={allTpls} />
          )}
          <StyledLink to={`/published-page/${activeItem.metaData.contentType}?id=${activeItem.id}`}>
            <StyledImageWrapper>
              <StyledImage>
                <ImageInput
                  stretchToFullHeight
                  readOnly
                  value={[activeItem]}
                  onChange={() => {}}
                />
              </StyledImage>
            </StyledImageWrapper>
          </StyledLink>
        </StyleContentWrapper>
      </StyledModal>
      
    </StyledModalWrapper>
  );
};

export default ImageModal;