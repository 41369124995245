import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useBannerContext } from 'oolib'



export const useCreateReaction  = () => {
  const queryClient = useQueryClient()
  const {SET_ALERT_BANNER}  = useBannerContext()
  return useMutation(
    ({data, contentType, contentId, type}) =>
    axios.patch(
      `/api/reactions/createReaction/${contentType}/${contentId}/${type}`, 
      data, 
      { headers: { 'Content-Type': 'application/json' }}
    ),
    {
      onSuccess: (res, { contentType, contentId}) => {
        queryClient.invalidateQueries(['Contributions', 'view', contentType, contentId])
        // SET_ALERT_BANNER('Like Created Successfully', 'green', 5000 )
      },
      onError : () => {
        // SET_ALERT_BANNER( "Create Like Error", "red", 5000 )
      }
    }
  )
}

export const useDeleteReaction = () => {
  const queryClient = useQueryClient()
  const {SET_ALERT_BANNER}  = useBannerContext();
  return useMutation(
    ({data, contentType, contentId, reactionUserId, type}) => axios.patch(`/api/reactions/deleteReaction/${contentType}/${contentId}/${reactionUserId}`, data), 
    {
      onSuccess: (res, { contentType, contentId}) => {
        queryClient.invalidateQueries(['Contributions', 'view', contentType, contentId])
        // SET_ALERT_BANNER('Like Deleted Successfully', 'green', 5000 )
      },
      onError : () => {
        // SET_ALERT_BANNER( "Delete Like Error", "red", 5000 )
      }
    }
  )
}




