import React from "react";
import {
  EditBlockGenerator,
  ViewBlockGenerator,
} from "../../../../../../utils/blockGenerators";

export const GeneratorComp = ({
  block,
  blockWrapper,
  readOnly,
  memo,
  handleOnChange,
  content,
  formValidation,
  setFormValidation,
  indexList
}) => {
  /**
   * readOnly : applied at THIS genBlocks function level
   * readOnlyProp : applied at the entire template level
   */
  let Comp = readOnly ? ViewBlockGenerator : EditBlockGenerator;
 
  return (
    <Comp
      memo={memo}
      block={block}
      onChange={handleOnChange && handleOnChange}
      content={content}
      Wrapper={blockWrapper}
      formValidation={formValidation}
      setFormValidation={setFormValidation}
      isNewTemplate={true}
      indexList={indexList}
    />
  );
};
