import { SANS_2 } from "oolib";
import React from "react";
import {
  StyledButtonColorTag,
  StyledButtonContents,
  StyledLegendButton
} from "./styled";

export const LegendButton = ({
  option,
  isActive,
  onClick,
  onMouseOver,
  onMouseOut,
  onTouchStart,
  onTouchEnd,
}) => {
  
  return (
    <StyledLegendButton
      active={isActive}
      {...{
        onClick,
        onMouseOver,
        onMouseOut,
        onTouchStart,
        onTouchEnd,
      }}
    >
      <StyledButtonContents>
        {option.color && (
          <StyledButtonColorTag style={{ backgroundColor: option.color }} />
        )}
        <SANS_2 bold={!!option.desc}>{option.display}</SANS_2>
        {option.desc && <SANS_2>{option.desc}</SANS_2>}
      </StyledButtonContents>
    </StyledLegendButton>
  );
};
