import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useState } from "react";
import { ToolbarButton } from "../comps/ToolbarButton";
import { useCheckRangeSelectionExists } from "../utils/useCheckRangeSelectionExists";
import { LinkOrEmbedModalLex } from "./LinkOrEmbedModalLex";
import { checkIsActive } from "./checkIsActive";
import { handleToggleLink } from "./handlers/handleToggleLink";
import { handleInsertEmbed } from "./handlers/handleInsertEmbed";

const linkToolConfig = {
  icon: "LinkSimple",
};

export const LinkTool = () => {
  const [editor] = useLexicalComposerContext();
  const selectionExists = useCheckRangeSelectionExists();
  const [modal, setModal] = useState(false);

  return (
    <>
      {modal && (
        <LinkOrEmbedModalLex
          onClose={() => setModal(undefined)}
          modalTitle={"Insert Link"}
          onSelect={({ link }) =>
            handleToggleLink({
              editor,
              link,
              callback: () => setModal(undefined),
            })
          }
        />
      )}
      <ToolbarButton
        isDisabled={selectionExists === false}
        isActive={checkIsActive({ editor })}
        onClick={() => setModal(true)}
        icon={linkToolConfig.icon}
      />
    </>
  );
};

const embedToolConfig = {
  icon: "Cards",
};

export const EmbedTool = () => {
  const [editor] = useLexicalComposerContext();
  const [modal, setModal] = useState(false);

  return (
    <>
      {modal && (
        <LinkOrEmbedModalLex
          onClose={() => setModal(undefined)}
          modalTitle={"Insert Embed"}
          onSelect={({ link, embedType }) => {
            handleInsertEmbed({
              editor,
              embedType,
              link,
              callback: () => setModal(undefined)
            })
            //embed whatever needs to be embedded logic
          }}
        />
      )}
      <ToolbarButton
        isActive={checkIsActive({ editor })}
        onClick={() => setModal(true)}
        icon={embedToolConfig.icon}
      />
    </>
  );
};
