import React from "react";
import { IFrameInput } from "oolib";
import { DecoratorNodeShell } from "../comps/DecoratorNodeShell";

export const IFrameInputNodeComp = ({
  //props for comp
  id,
  value,

  //props for shell
  nodeKey,

  //handlers
  onChange,
  onRemove,

  readOnly
}) => {

  return (
    <DecoratorNodeShell
      nodeKey={nodeKey}
      onRemove={onRemove}
    >
      <IFrameInput
        isInRTE
        id={id}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
    </DecoratorNodeShell>
  );
};