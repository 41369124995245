import { useEffect, useRef, useState } from "react";


export const useSectionHeightTracker = ({screenWidth }) => {
  
  const actionRef = useRef()
const tabsRef = useRef()
  const [tabsPanelHeight, setTabsPanelHeight] = useState(undefined)
  const [ actionBarHeight, setActionBarHeight ] = useState(undefined); 

  useEffect(() => { 
    
    if(actionRef?.current){
      setActionBarHeight(actionRef?.current?.getBoundingClientRect().height )
    }

    if(tabsRef?.current){
      setTabsPanelHeight(tabsRef?.current?.getBoundingClientRect().height )
    }
  }, [ screenWidth ]);

  return ({
    actionBarHeight,
    tabsRef,
    tabsPanelHeight,
    actionRef
  })
}