import React from "react";
import {
  getBreakPoint,
  makeArrayFromLength,
  SkeletonLoader,
  useScreenWidth,
} from "oolib";
import { Fragment } from "react";
import ErrorStates from "../../../../ErrorStates";

export const MasonryCardGridShell = ({
  dataFetchStatus,
  dataFetchError,
  data,
  renderCard,
}) => {
  const screenWidth = useScreenWidth();
  const cols =
    screenWidth < getBreakPoint("xl")
      ? 1
      : screenWidth >= getBreakPoint("xl") && screenWidth < getBreakPoint("xxl")
      ? 2
      : 3;

  return (
    <div
      style={{
        gap: "2rem",
        display: "grid",
        gridTemplateColumns: makeArrayFromLength(cols)
          .map((d) => "1fr")
          .join(" "),
      }}
    >
      {dataFetchStatus === "loading" ? (
        makeArrayFromLength(20).map((d, i) => (
          <SkeletonLoader style={{ height: "300px" }} />
        ))
      ) : dataFetchStatus === "error" ? (
        <ErrorStates errorResponseObject={dataFetchError} />
      ) : (
        data && (
          <Fragment>
            {makeArrayFromLength(cols).map((col, colI) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                {data
                  .filter((d, i) => i % cols === colI)
                  .map((d, i, ary) => renderCard(cols, colI, d, i, ary))}
              </div>
            ))}
          </Fragment>
        )
      )}
    </div>
  );
};
