import useResizeObserver from '@react-hook/resize-observer'
import React, { Fragment, useState } from 'react'
import { getVal, SANS_2, TagDisplay, toArray } from 'oolib'
import { useOverflowItems } from '../../../utils/customHooks/useOverflowItems'
import { formatters } from '../../cards/TableElem/CONFIG_PARSER/TableCellBlocks/utils/formatters'

const Tags__Intro = ({
  content: _content,
  value,
  
  XS,
  S = true,
  M,

  valuePath,

  style = {},
  invert,
  align='center',

  formatter,

  Wrapper = 'div',

}) => {

  


  // eventually this will be phased out when we define value paths in tpl conf
  // wrt. the root datastructure of contribution content
  // backupparentobj will become the only obj + `|| props.value`
  let content = _content || value

  let initItemsConfig = valuePath !== undefined //because valuePath could be 'null', and that is valid
    ? toArray( getVal(content,valuePath) ) 
    : [];

  /**
   * now, old data structure of this was [String, String ,String] 
   * we need to convert this to [ {display ,value} ]
   * 
   * the lines below do this conversion
   */
  if(initItemsConfig.every(d => ['string', 'number', 'boolean'].indexOf(typeof d) !== -1)){
    initItemsConfig = initItemsConfig.map(d => ({
      display: (typeof d === 'string' && formatter)
        ? formatters[formatter](d)
        : d, 
      value: d
    }))
  }

  /**
   * now if the array of values is that of the tags data stru cture i.e [ {display, _id, tagId}, ... ]
   * then lets normalize that down to [ {display, value}, ... ]
   */
  if(initItemsConfig.every(d => d._id && d.display)){
    initItemsConfig = initItemsConfig.map(d => ({
      display: d.display,
      value: d._id
    }))
  }

  /**
   * if init items config does not have a display and value now for each item, then simply reject it.
   * make initItemsConfig an empty array in such a case so that nothing is renderer
   */
  if(initItemsConfig.some(item => !item?.display || !item?.value)){
    initItemsConfig = []
  }
  let [rerender, setRerender] = useState(0)



  const {
    storeItemRef,
    containerRef,
    itemOptions,
    overflowOptions
  } = useOverflowItems({
    initItemsConfig,
    offsetAvailableSpace: -20, //for the plus something text
    rerunArray: [rerender]
  })

  
  
  useResizeObserver(containerRef, (entry) => {
    setRerender(prev => prev+1) //so that useOverflowItems can recalculate again
  })


  const size = XS 
    ? { XS : true } 
    : S 
      ? { S : true } 
      : M 
        ? { M : true } 
        : {}


  const genTags = () => {
    return itemOptions.map((item) => {
      return (
        <div 
          ref={el => storeItemRef(el, item)}
          key={item.value}
          style={{flexShrink: 0, display: item.hide ? 'none' : 'block'}}
          >
          <TagDisplay invert={invert} {...size} variant={'secondary'} display={item.display}/>
        </div>
      );
    });
  };

  const genPlusNumber = () => {
    return overflowOptions.length > 0 && (
      <div /*title={hiddenTags.join(', ')}*/> {/*title attr creates tooltip*/}
        <SANS_2>{`+ ${overflowOptions.length}`}</SANS_2>
      </div>
    );
  };

  return (
    <Fragment>
      {valuePath !== undefined && itemOptions?.length > 0 && (
        <Wrapper style={{width: '100%'}}> {/** very imp the 100%, else hide/show of tags wont work */}
          <div ref={containerRef} className="introTags" style={{...style, justifyContent: align === 'center' ? 'center' : 'left' && 'flex-start'}}>
            { genTags() }
            { genPlusNumber() }
          </div>
        </Wrapper>
      )}
    </Fragment>
  )
}

export default Tags__Intro
