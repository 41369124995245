import React, { Fragment, useState, useEffect } from "react";
import { URLInput, getBreakPoint, SANS_0, useScreenWidth} from "oolib";
import ContentBlockShell from '../../generalUI/ContentBlockShell';
import {replaceEntityData as DEPRECATED_replaceEntityData} from '../../../utils/richInputUtils';

import { Coachmark, useCoachmark} from '../../Coachmark';
import coachingList from "../../Coachmark/config";

import { useGetQueryData } from "../../../utils/react-query-hooks/general";

import { replaceEntityData } from "../TextEditor/RichTextEditor/utils";
import AtomicBlockShell from "../TextEditor/RichTextEditor/comps/AtomicBlockShell";

/*
   -> Entity Update
   -> Wrap the component in shell
   -> handleSetTempReadOnly()
*/

const DEPRECATED_KPPPTEmbed = ({
	label,
	sublabel,
	id,
	className,
	value,
	placeholder,
	onChange: parentOnChange,
	readOnly,
	isRequired,
	errorMsgs,

	// --- RTE EMBED PROPS --- //
	entityKey,
	block,

	// -- deprecated
	isInEditor : DEPRECATED_isInEditor, //todeprecate
	editor,
	handleSetTempReadOnly,
	// -- end deprecated
	
	//from new RTE
	isInRTE,
	editorState,
	handleChange,
    readOnlyState,
    
}) => {
	const screenWidth = useScreenWidth()
	const [url, setUrl] = useState(value || null);
	
	const {
		activeMark,
		rank,
		removeCoachmark,
		showNextMark,
		useReliableRef
	} = useCoachmark({coachmarks: coachingList.H5PEmbed, compIdentifier:"H5PEmbed"});
	
	const embedElement = useReliableRef([0, 1]);

	const {user} = useGetQueryData('userData');


	const optimizeForResize =(func, delay = 500)=> {
		let timerId;
		return ()=>{
			clearTimeout(timerId);
			timerId = setTimeout(func, delay)
		}
	}
	

	function filterPPTURL(address) {
		if (address) {
			if (address.search('google') > -1) {
				return /\/edit/.test(address)? address.split('/edit')[0] : address;
			}
		}
	}

	const onChangeUrl = (v) => {
		setUrl(v)
		parentOnChange && parentOnChange(id, v)

		// ----- to remove soon ----- //
		if (DEPRECATED_isInEditor === true) {
			DEPRECATED_replaceEntityData(editor, entityKey, { value: v })
		}
		// -------------------------- //

		if(isInRTE){
			replaceEntityData(
				editorState,
				handleChange,
				entityKey,
				{ value: v }
			  )
		}
	}

	const createPPTPreview = () => {
		switch (true) {
			case url === null || url.trim() === '': // No URL
				return (
					<SANS_0 bold invert>
						paste a url above to view preview of Google Slide
					</SANS_0>
				)
			default:
				// Load iFrame for URL
				return (
					<div className='kp-ppt-embed-wrapper'>
						<iframe
							className='kp-ppt-embed'
							src={
								url.includes('docs.google.com')
									? filterPPTURL(url) +
									  '/embed?start=false&loop=false&delayms=3000'
									: url
							}
							frameBorder='0'
							width='100%'
							height='100%'
							allowFullScreen='true'
							mozallowfullscreen='true'
							webkitallowfullscreen='true'></iframe>
					</div>
				)
		}
	}

	

	
	/* a hack to to avoid google slides bug of 
		height not autoAdjusting upon phone screen rotation,
		we adjus it for 1 time (the time we show users the pop up to tilit the screen)
		however.. it's unrelated to the normal flow of coachmarks
	*/
	useEffect(()=> {
		if (window.innerWidth < getBreakPoint('md')){ // onlu use the hack for mobile users
			let windowWidth = window.innerWidth;

			const adjustSlidesHeight =()=>{
				if (windowWidth === window.innerWidth) return; // if resize was called for height change not width change
				windowWidth = window.innerWidth;
				
				setUrl('');
				setTimeout(()=> setUrl(url), 300);
				window.removeEventListener('resize', adjustOptimized)
			}

			const adjustOptimized = optimizeForResize(adjustSlidesHeight, 100)
			window.addEventListener('resize', adjustOptimized)

			return ()=> window.removeEventListener('resize', adjustOptimized)
		}
	}, [])


	
	const showNextMarkOnTilt =()=> {
		const {clientWidth, clientHeight} = document.documentElement;
		if (activeMark === undefined){ // we are done coaching, this func ic called bec of the too many resize events registered
			window.removeEventListener('resize', showNextOptimized);
			return;
		}
		if (clientWidth > clientHeight) { // user tilited then pressed done
			showNextMark()
		} else { // temporarily remove the mark & show next mark on tilt (recursion)
			showNextMark(false);
			window.addEventListener('resize', showNextOptimized)
		}
	}
	const showNextOptimized = optimizeForResize(showNextMarkOnTilt)

	//////////////////////////////////////////////////////////////block display options///////////////////////////////////////////////

	const inputBlock = () => (
		<Fragment>
			<URLInput
				placeholder={placeholder || 'Enter a valid embed url'}
				onChange={(k, v) => onChangeUrl(v)}
				value={url}
				errorMsgs={errorMsgs}
				isRequired={isRequired}
				label={label}
				sublabel={sublabel}
			/>
			<div className='kp-ppt-embed__preview'>{createPPTPreview()}</div>
		</Fragment>
	)

	const displayBlock = () => (
		<div className='kp-ppt-embed__display'>
			
			<div className='kp-ppt-embed-wrapper'>
				<iframe
					ref={embedElement}
					className='kp-ppt-embed'
					src={ url &&
						url.includes('docs.google.com')
							? filterPPTURL(url) +
								'/embed?start=false&loop=false&delayms=3000'
							: url
					}
					frameBorder='0'
					width='100%'
					height='100%'
					allowFullScreen={true}
					mozallowfullscreen={true}
					webkitallowfullscreen={true}
					title="learning document"
				/>
			</div>

			{ screenWidth < getBreakPoint('lg') &&
					<Coachmark
						active={user.coachmarkChecklist.H5PEmbed === false && url && url.includes("h5p")}
						rank={rank}
						observerThreshold={0.3}
						currentMark={activeMark}
						handleNext={showNextMarkOnTilt}
						handleSkip={removeCoachmark} // code to remove the comp from coachList
					/>
				}
			
		</div>
	)

	const createBlock = () => {
		switch (true) {
			case readOnly:
				return displayBlock()
			default:
				return inputBlock()
		}
	}

	const ShellComp = isInRTE
		? AtomicBlockShell
		: DEPRECATED_isInEditor
			? ContentBlockShell
			: Fragment

	const ShellProps = isInRTE
		? {id, readOnlyState, editorState, handleChange, block, readOnly}
		: DEPRECATED_isInEditor 
			? {
			richInputProps: {
				isInEditor: DEPRECATED_isInEditor,
				editor: editor,
				block: block,
				handleSetTempReadOnly: handleSetTempReadOnly,
			},
			readOnly,
			className: `kp-ppt-embed-container ${className}`,
			id
		} : {}

	return (
		<ShellComp {...ShellProps}>
			{createBlock()}
		</ShellComp>
	)
}



export default DEPRECATED_KPPPTEmbed

