import { isEmpty } from "lodash";
import {
  ButtonGhost,
  ButtonPrimary,
  SANS_2,
  TagSelect,
  colors,
  icons,
} from "oolib";
import React, { useEffect, useRef, useState } from "react";

import {
  useCreateTagV3,
  useMultipleGetTagsV3,
} from "../../../../../../../../utils/react-query-hooks/tags";

import {
  convertSingleValueToTagsValue,
  convertTagDocsDataToOptions,
} from "../../../../../../DynamicTagsInputs/utils";

import {  __GetContentTypeConfigNew } from "../../../../../../../../utils/getters/gettersV2";

import { BadgeNumber } from "../../../../../../../generalUI/BadgeNumber";
import { TagsSubmenu } from "./comps/TagsSubmenu";
import {
  ActionButtonsContainerStyled,
  Divider,
  MenuContainerStyled,
  MenuItemStyled,
  OtherOptionsContainerStyled,
  TagSelectWrapperStyled,
} from "./styled";
import { toggleTagTypesLookup } from "../configs/toggleTagTypesLookup";

const { greyColor15, annoPink, annoBlue } = colors;
const { CaretRight } = icons;

const bgColor = {
  quotes: annoPink, // lightPink
  vocabs: annoBlue 
};

//------------------------------------------------------------- PARENT COMP ----------------------------------------------------

export const AnnoLightboxInput = ({
  // id,
  onChange,
  value,
  tagTypesConfig,
  toggleTagTypesConfig = [], // shape: [ { tagType: 'quotes' }, ... ]
  handleOnConfirm,
  handleOnCancel,
}) => {

  const toggleTagTypes = toggleTagTypesConfig.map(d => {
    const thisTagType = d.tagType;
    return toggleTagTypesLookup.find(dd => dd.tagType === thisTagType)
  }).filter(Boolean)

  const containerRef = useRef(null);

  const [showTagsLightbox, setShowTagsLightbox] = useState({});

  //---------------------------------------QUERY HOOKS----------------------------------------------------------
  

  const MainMenuRef = useRef();

  useEffect(() => {
    const headeroffSet = 70;
    const toolbarOffset = 120;

    const { top, bottom } = MainMenuRef.current.getBoundingClientRect();

    if (top - headeroffSet < 0) {
      window.scrollBy({ top: top - headeroffSet, behavior: "smooth" });

      // hacky solution to fix that one issue where the lighbox is not visibile when there's no scroll up space available
      setTimeout(() => {
        const { top } = MainMenuRef.current.getBoundingClientRect();
        if (top < 60) {
          const elem = document.getElementById("input-light-box");
          elem.style.top = `${headeroffSet + 10}px`;
        }
      }, 1000);
    }

    const screenHeight = window.screen.height - toolbarOffset;

    if (bottom > screenHeight) {
      window.scrollBy({ top: bottom - screenHeight, behavior: "smooth" });
    }
  }, []);

  const handleShowTagsLightbox = (e, thisTagTypeConfig) => {
    e.preventDefault();
    setShowTagsLightbox({
      top: e.currentTarget.offsetTop,
      height: e.currentTarget.offsetHeight,
      ...thisTagTypeConfig, //tagType && //allowCreateTag
    });
  };

  const genToggleTags = () => {
    if (!toggleTagTypes?.length === 0) return null;

    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          gap: "1rem",
          border: "none",
        }}
      >
        {toggleTagTypes.map((d) => {
          let option = { ...d, value: d._id };
          let isSelected = value[option.tagType]?.data?.length > 0;

          return (
            <TagSelectWrapperStyled>
              <TagSelect
                display={option.singular}
                isSelected={isSelected}
                style={
                  isSelected
                    ? {
                        color: "black",
                        backgroundColor: bgColor[option.tagType],
                        border: `2px solid ${bgColor[option.tagType]}`,
                        ':hover': {
                          backgroundColor: 'red',
                          color: 'white',
                          cursor: 'pointer',
                        },
                      }
                    : { border: `2px solid ${greyColor15}`,  color: 'black' }
                }
                onMouseDown={(e) => {
                  e.preventDefault();
                  onChange(
                    option.tagType,
                    isSelected
                      ? undefined
                      : convertSingleValueToTagsValue(option.tagType, option)
                  );
                }}
              ></TagSelect>
            </TagSelectWrapperStyled>
          );
        })}
      </div>
    );
  };

  return (
   
    <div ref={containerRef} onMouseDown={(ev) => ev.preventDefault()}>
      <MenuContainerStyled ref={MainMenuRef}>
        {tagTypesConfig.map((d) => {
          const title =
          __GetContentTypeConfigNew(d.tagType).general?.content?.title || d.tagType;          
          const active = showTagsLightbox.tagType === d.tagType;
          const selectCount = value[d.tagType] && value[d.tagType].data.length;

          return (
            <MenuItemStyled
              key={d.tagType}
              onMouseDown={(e) => handleShowTagsLightbox(e, d)}
              active={active}
            >
              <div style={{ display: "flex", gap: "1rem" }}>
                <SANS_2 semibold={active}>{title}</SANS_2>{" "}
                {selectCount ? <BadgeNumber number={selectCount} /> : null}
              </div>
              <CaretRight weight={active ? "bold" : "regular"} size={12} />
            </MenuItemStyled>
          );
        })}

        <OtherOptionsContainerStyled>
          <Divider />
          {genToggleTags()}
          <Divider />
          <ActionButtonsContainerStyled>
            <ButtonGhost onClick={(e) => handleOnCancel(e)}>Cancel</ButtonGhost>
            <ButtonPrimary disabled={isEmpty(value)} onClick={handleOnConfirm}>
              Apply
            </ButtonPrimary>
          </ActionButtonsContainerStyled>
        </OtherOptionsContainerStyled>
      </MenuContainerStyled>

      {showTagsLightbox.tagType && (
        <TagsSubmenu
          value={value}
          onChange={onChange}
          containerRef={containerRef}
          showTagsLightbox={showTagsLightbox}
        />
      )}
    </div>
  );
};


