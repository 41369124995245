import { mediaQuery } from "oolib";
import styled from "styled-components";

export const StyledSortWrapper = styled.div`
display: flex;
justify-content: end;
align-items: center;
grid-column: 3 / 4;
gap: 2rem;
${mediaQuery('md')}{
  & .sortDropdown {
    min-width: 18rem;
  }
}
`;