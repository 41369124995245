
import React from 'react'
import { _findAnnoEntities } from './functions/helpers';
import RichHighlight from './comps/RichHighlight'

export const genAnnoDecorator = directPropsFromRTEComp => {

    const RichHighlightWithRichInputProps = (props) =>  {
        return (
            <RichHighlight 
                isActive = {directPropsFromRTEComp.isActive} 
                EditorRef = {directPropsFromRTEComp.EditorRef || directPropsFromRTEComp.wrapperRef}  // wrapperRef is deprecated in favour of EditorRef
                onAnnoClick = {directPropsFromRTEComp.onAnnoClick}   
                readOnly= {directPropsFromRTEComp.readOnly} {...props} />
        )
    }
    return ({ strategy: _findAnnoEntities, component: RichHighlightWithRichInputProps, })
}
export { useHandleAnnoSelection, insertAnno, removeAnnotation, editAnnotation } from './functions/handlers';

export {default as Lightbox} from './comps/GUIs/LightBox';
