import React from "react";
import { StyledToolbarButton } from "./styled";
import { icons } from "oolib";

const ToolbarIcon = ({ icon }) => {
  if (!icon) return null;
  const Icon = icons[icon];
  return <Icon size={16} weight="bold" />;
};

export const ToolbarButton = ({ icon, isActive, onClick, isDisabled }) => {
  return (
    <StyledToolbarButton
      isActive={isActive}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      <ToolbarIcon icon={icon} />
    </StyledToolbarButton>
  );
};
