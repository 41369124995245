import { SANS_2, testJSON } from "oolib";
import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import d3 from "../../../../../importGroups/d3Modules";

import { genResponsiveSvgCanvas } from "../../../utils";


const Viz = (props) => {
  const config = {
    width: 700,
    height: 600,
    margin: { top: 50, right: 50, bottom: 50, left: 50 },
    tooltipStyles: {
      offsetX: 20,
      offsetY: 0,
    },
    defaultColorMap: [ "#E7A04E", "#476AC0", "#DC7956", "#5399F2", "#58BE5B", "#E24F68", "#578F3F", "#C1C1C1" ],
    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    genTooltipHtml: (d) => {
      return (
        <div>
          {["string", "number"].indexOf(typeof d) !== -1
            ? d
            : "unreadable default tooltip"}
        </div>
      );
    },
  };

  const width = props.width || config.width;
  const height = props.height || config.height;
  const radius = Math.min(width, height) / 2;
  const marginProp = testJSON(props.margin)
    ? JSON.parse(props.margin)
    : undefined;
  const margin = marginProp
    ? {
        top: marginProp.top || config.margin.top,
        bottom: marginProp.bottom || config.margin.bottom,
        left: marginProp.left || config.margin.left,
        right: marginProp.right || config.margin.right,
      }
    : config.margin;
  const colorMap = props.colorMap || config.defaultColorMap;
  const { data, id, className} = props;
  const wrapperId = `OKE_PiChart${id ? "_" + id : ""}`;
  const wrapperClass = `OKE-PiChart ${className || ""}`;

  const { legendValue, setLegendValue, piChartLegendEvent } = props;

  useEffect(
    () => drawChart(data),
    [
      data
    ]
  );

  useEffect(() => updateChart(legendValue), [piChartLegendEvent]);

  const highlightPi = (pieceOfPi, tooltipPos) => {
    let thisD = pieceOfPi.datum();

    d3.selectAll(`.pieceOfPi_${id}`)
      .filter((d) => d.data.key !== thisD.data.key)
      .style("opacity", 0.25);
    if(tooltipPos){
      d3.select(`#tooltip_${id}`)
      .html(
        ReactDOMServer.renderToStaticMarkup(
          <div>
            <SANS_2 bold>{thisD.data.value.display}</SANS_2>
            <SANS_2>{`${thisD.data.value.count} (${thisD.data.value.percent}%)`}</SANS_2>
          </div>
        )
      )
      .style("display", "block")
      .style("left", tooltipPos.x)
      .style("top", tooltipPos.y);
    }
    
  };

  const makePiBackToNormal = () => {
    d3.selectAll(`.pieceOfPi_${id}`).style("opacity", 1);

    d3.select(`#tooltip_${id}`).style("display", "none");
  };

  const updateChart = (legendValue) => {
    if (!legendValue) {
      makePiBackToNormal();
    } else {
      let thisPieceOfPi = d3.selectAll(`.pieceOfPi_${id}`)
        .filter((d) => d.data.key === legendValue.value)
      // console.log({thisPieceOfPi : thisPieceOfPi.datum()})
      highlightPi(thisPieceOfPi)
    }
  };

  /**
   *
   * @param {*} data
   * data structure:
   * {
   *  key1: { count: Number , display: String },
   *  ...
   * }
   */

  const drawChart = (data) => {
    // console.log({DATA : data})

    //first clear the div
    d3.select("#" + wrapperId + ">svg").remove();

    //then draw

    let svg = genResponsiveSvgCanvas({
      width,
      height,
      wrapperId,
      margin,
    });

    // set the color scale
    var color = d3.scaleOrdinal().domain(data).range(colorMap);

    // Compute the position of each group on the pie:
    var pie = d3.pie().value(function (d) {
      return d.value.count;
    });
    var data_ready = pie(d3.entries(data));

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll()
      .data(data_ready)
      .enter()
      .append("path")
      .attr("d", d3.arc().innerRadius(0).outerRadius(radius))
      .attr("fill", function (d) {
        return color(d.data.key);
      })
      .attr("class", `pieceOfPi_${id}`)
      .style("stroke-width", "2px")
      .style("opacity", 1)
      .style("transition", "opacity 0.3s ease-out")
      .style("cursor", "pointer")
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut)
      .on("mousemove", handleMouseMove);

    //tooltip
    d3.select("#" + wrapperId)
      .append("div")
      .attr("class", "piChartTooltip")
      .style("bottom", 0)
      .attr("id", `tooltip_${id}`)
      .style("display", "none");
  };

  function handleMouseOver(d, i) {
    let thisPieceOfPi = d3.select(this);
    let tooltipPos = {
      x: d3.event.clientX + "px",
      y: d3.event.clientY + "px",
    };
    highlightPi(thisPieceOfPi, tooltipPos);

    /**
     * because d3 pi does this:
     * if i pass data like : { key: { display, value }, .. }
     * pi converts it to : { key: key, value : { display, value} }
     */
    setLegendValue({
      value: thisPieceOfPi.datum().data.key,
      display: thisPieceOfPi.datum().data.value.display,
    });
  }

  function handleMouseOut(d, i) {
    makePiBackToNormal();
    setLegendValue(undefined);
  }

  function handleMouseMove(d, i) {
    d3.select(`#tooltip_${id}`)
      .style("left", d3.event.clientX + "px")
      .style("top", d3.event.clientY + "px");
  }

  return <div id={wrapperId} className={wrapperClass} />;
};

export default Viz;
