import { $insertNodeToNearestRoot } from "@lexical/utils";
import { $createExternalLinkEmbedNode } from "../../../../nodes/ExternalLinkEmbedNode";
import { $createImageInputNode } from "../../../../nodes/ImageInputNode";
import { $createInternalLinkEmbedNode } from "../../../../nodes/InternalLinkEmbedNode";
import { $createVideoInputNode } from "../../../../nodes/VideoInputNode";
import { $createPDFInputNode } from "../../../../nodes/PDFInputNode";
import { $createAudioInputNode } from "../../../../nodes/AudioInputNode";
import { $createIFrameInputNode } from "../../../../nodes/IFrameInputNode";

export const handleInsertEmbed = ({ editor, embedType, link, callback }) => {
  if (!embedType) return;

  editor.update(() => {
    switch (embedType) {
      case "image":
        $insertNodeToNearestRoot(
          $createImageInputNode({
            value: [{ publicUrl: link }],
          })
        );
        break;

      case "external-link":
        $insertNodeToNearestRoot(
          $createExternalLinkEmbedNode({
            value: link,
          })
        );
        break;

      case "internal-link":
        $insertNodeToNearestRoot(
          $createInternalLinkEmbedNode({
            value: link,
          })
        );
        break;

      case "audio":
        $insertNodeToNearestRoot(
          $createAudioInputNode({
            value: [{ publicUrl: link }],
          })
        );
        break;

      case "video":
        $insertNodeToNearestRoot(
          $createVideoInputNode({
            value: [{ publicUrl: link }],
          })
        );
        break;

      case "pdf":
        $insertNodeToNearestRoot(
          $createPDFInputNode({
            value: [{ publicUrl: link }],
          })
        );
        break;

      case "ppt":
        $insertNodeToNearestRoot(
          $createIFrameInputNode({
            value: link,
          })
        );
        break;

      default:
        break;
    }
    callback && callback()
  });
  
};


// switch (embedType) {
//   case "image":
//     return createAtomicBlockEntity(
//       editorState,
//       handleChange,
//       "RTEImageInput",
//       {
//         value: [{ publicUrl: urlString }],
//       }
//     );

//   case "video":
//     return createAtomicBlockEntity(
//       editorState,
//       handleChange,
//       "RTEVideoInput",
//       {
//         value: [{ publicUrl: urlString }],
//       }
//     );

//   case "ppt":
//     return createAtomicBlockEntity(
//       editorState,
//       handleChange,
//       "RTEIFrame",
//       {
//         value: urlString,
//       }
//     );

//   case "internal-link":
//     return createAtomicBlockEntity(
//       editorState,
//       handleChange,
//       "RTEInternalEmbed",
//       {
//         value: urlString,
//       }
//     );

//   case "external-link":
//     return createAtomicBlockEntity(
//       editorState,
//       handleChange,
//       "RTEExternalEmbed",
//       {
//         value: urlString,
//       }
//     );

//   default:
//     return;
// }