import { getAccessControlEnabledPLBModules } from "../../../../../../../../pageTpls/PlatformBuilder/config";
import {

  __CheckAccessToModule,
  __CheckSomeAccessToSomePlatformBuilderModule,
  __GetAccessibleContentTypes,
} from "../../../../../../../../utils/accessControl";

import { useGetQueryData } from "../../../../../../../../utils/react-query-hooks/general";
import { genMyDashboardMenuOptions } from "../../../../../../MyDashboardMenu/utils";


export const useGetMyAccountMenuOptions = () => {
  const userData = useGetQueryData("userData");

  const {
    deployment: { _ShowModerationFunctions, _EnableLearningModule },
  } = useGetQueryData("platformConfigs");

  /**
   * my dashboard options are dynamic, and they change from
   * tenant to tenant, role to role. hence the function below, to fetch
   * the 'landing view' of my dashboard for THIS user on THIS tenant
   */
  const getFirstMyDashboardOp = () => {
    return genMyDashboardMenuOptions(userData).find((d) => d.links.length > 0)
      .links[0];
  };

  const myDashboardOp = {
    display: "My Dashboard",
    value: 'myDashboard', 
    to: getFirstMyDashboardOp().link,
  };

  const myProfileSettingsOp =
   { 
    display: "Profile Settings", 
    value: 'profileSettings', 
    to: `/my-dashboard/MyProfile` 
  };

  const myContentOp = {
    display: "My Content",
    value: 'myContent', 
    to: { pathname: `/my-dashboard/MyContent`, search: `?activeTab=draft` },
  };

  const platformBuilderOp =  (
    __CheckSomeAccessToSomePlatformBuilderModule({
      user: userData?.user,
      actions: ['READ', 'UPDATE'],
      modules: getAccessControlEnabledPLBModules().map(m => m.value)
    }) && 
    {
      display: 'Admin Dashboard', 
      value: 'platformBuilder', 
      to: `/platformBuilder`,
    }
  )

  const logoutOp = { display: "Logout", value: "logout" };

  return [
    myDashboardOp,
    myProfileSettingsOp,
    myContentOp,
    
    // myLearningModulesOp,
    platformBuilderOp,
    logoutOp,
  ].filter(Boolean);
};
