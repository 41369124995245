import { cloneDeep } from "lodash";
import { useLayoutEffect, useRef, useState } from "react";

import { sortData, useScreenWidth } from "oolib";

  export const useOverflowItems = ({
    offsetAvailableSpace = 0, //e.g minus out margins and stuff
    initItemsConfig,
    rerunArray //if not passed, uses screenWidth change to rerun
  }) => {

    const containerRef= useRef(null);
    const itemsRef = useRef({})
    const allWidths = useRef({})

    const [itemOptions, setItemOptions] = useState(initItemsConfig || []);
    const [overflowOptions, setOverflowOptions] = useState([]);

    const screenWidth = useScreenWidth()

    useLayoutEffect(() => {
      //extract and store all widths of individual links
      Object.keys(itemsRef.current).forEach((k) => {
        allWidths.current[k] = itemsRef.current[k].getBoundingClientRect().width;
      })
    },[])
  
    useLayoutEffect(() => {
     
      //get tot width of the navbar
      let totAvail = containerRef.current?.getBoundingClientRect().width
      totAvail = totAvail + offsetAvailableSpace 
  
      let newItemOptions = cloneDeep(itemOptions).map((d,i) => ({...d, posIdx: i})); //posIdx so that we can keep items in the right order in overflow menu
      let newOverflowOptions = cloneDeep(overflowOptions);
      //cycle through each link
      for(let k in itemsRef.current){
        let confIdx = newItemOptions.findIndex(d => d.value === k)
        //get width of link from prev stored allWidths
        let lWidth = allWidths.current[k];
        
        if(lWidth < totAvail){ //if space is there for link to fit in
          if(newItemOptions[confIdx].hide){ 
            newItemOptions[confIdx].hide = false; //then unhide if it is hidden
            newOverflowOptions = newOverflowOptions.filter( //remove this linkconf from kebab menu
              d => d.value !== newItemOptions[confIdx].value
            )
          }
        }else{ //space is not available
          if(!newItemOptions[confIdx].hide){ //if not already hidden
            newItemOptions[confIdx].hide = true;
            newOverflowOptions.push(newItemOptions[confIdx])
          }
        }

        totAvail = totAvail - lWidth;
        newOverflowOptions = sortData({
          data: newOverflowOptions, 
          fn: 'numerical', 
          path: 'posIdx', 
          sortBy: 'a'
        })
      }
  
      setItemOptions(newItemOptions)
      setOverflowOptions(newOverflowOptions)
      
    },rerunArray || [screenWidth])

    return ({
      overflowOptions: overflowOptions,
      itemOptions: itemOptions,
      containerRef: containerRef,
      storeItemRef: (el, item) => itemsRef.current[item.value] = el,
    })
  }