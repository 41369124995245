import { getBreakPoint } from "oolib"

export const calculateTooltipPosition = ({ dimension, position, screenWidth }) => {
    
    if(!dimension) return

    if(screenWidth <= getBreakPoint('xl')) {
        if(position === "right-corner" || "left-corner"){
            position = position
        }else if(dimension.top <= 300){
            position = 'bottom';
        }else if(dimension.bottom <= 300){
            position = 'top';
        }
    }
    

    if (position === 'top' || position === 'bottom-right-top') {
        return {
            bottom: dimension?.bottom + dimension?.height + 30,
            left: dimension?.left,
        }
    }

    if (position === 'right' || position === "left-corner" ) {
        return {
            left: dimension?.width + dimension?.left + 30,
            top: dimension?.top,
        }
    }   

    if (position === 'left' || position === "right-corner") {
        return {
            right: dimension?.width + dimension?.right + 30,
            top: position === "right-corner" ? dimension?.top + 5 : dimension?.top,
        }
    }

    if(position === 'bottom' ) {
        return{
            top: dimension?.height + dimension?.top + 30,
            left: dimension?.left,
        }
    }

    return{
        top: dimension?.height + dimension?.top + 30,
        left: dimension?.left,
    }

}