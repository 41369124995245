import { TagClear, useScreenWidth } from 'oolib'
import { ButtonGhost } from 'oolib'
import { SANS_3 } from 'oolib'
import { mediaQuery, Section, Container } from 'oolib'
import React, { useRef, useEffect } from 'react'


import { WrapperFlex } from '../../../../layout/Wrappers'
import {
  FilterLabelStyled,
  FilterListStyled,
  TopbarFilterStyled,
} from './styled'
import { FilterInput } from '../../../../inputs/DynamicTagsInputs/FilterInput'

export default function TopbarFilter({
  selectedValues,
  onChange,
  // setSelectedValue,
  updateActiveFilters,
  setFilterListDOMRect,
  filterConfig,
  activeFilters,
  presetActiveFilters,
  contentTypes
}) {
  const screenWidth = useScreenWidth()
  const filterListRef = useRef()


  //removes one individual selected tag at a time
  const handleRemoveTag = (filterId, removedFilter) => {
    let values = { ...selectedValues }
    let selectedValue = values[filterId]
    selectedValue = selectedValue.filter(
      (data) => data.value !== removedFilter.value
    )
    if (selectedValue.length > 0) {
      values = { ...values, [filterId]: selectedValue }
    } else {
      delete values[filterId]
    }

    // setSelectedValue(values)
    updateActiveFilters(removedFilter.value)
  }
  //removes all selected tags
  const handleClearAllTags = () => {
    // setSelectedValue({})
    //empty all selected filter values
    updateActiveFilters(null, true)
  }

  // useEffect(() => {
  //   if (filterListRef.current) {
  //     const DOMRect = filterListRef.current.getClientRects()
  //     setFilterListDOMRect(DOMRect[0])
  //   }
  // }, [filterListRef])

  if (!mediaQuery('sm', screenWidth)) {
    return null
  }
  
  return (
    <TopbarFilterStyled>
      <Section primaryColor10>
        <Container>
          <WrapperFlex>
            <FilterLabelStyled>
              <SANS_3 semibold>Filter By:</SANS_3>
            </FilterLabelStyled>
            <FilterListStyled ref={filterListRef}>
              {Object.keys(filterConfig).map((key) => {
                 let {             
                  filterId,            
                  
                } = filterConfig[key];

                return (                  
                  <FilterInput                 
                    comp={"DropdownMulti"}
                    key={filterId}
                    value={selectedValues[filterId]}                
                    filterConfig={filterConfig[key]}
                    onChange={(_, value) => {
                      onChange({
                        id: filterId,                    
                        value,
                      })
                    }}
                    className="__filterInput"
                    activeFilters={activeFilters}
                    presetActiveFilters={presetActiveFilters}
                    contentTypes={contentTypes}
                  />
                )
              })}
            </FilterListStyled>
          </WrapperFlex>
        </Container>
      </Section>
      {Object.keys(selectedValues).length > 0 && (
        <Section borderBottom>
          <Container>
            <WrapperFlex>
              <FilterLabelStyled>
                <SANS_3 bold>Selected:</SANS_3>
              </FilterLabelStyled>
              <FilterListStyled>
                {Object.keys(selectedValues).map((filterId) => {
                  return (
                    selectedValues[filterId] &&
                    selectedValues[filterId].map((currentFilter) => {
                      return (
                        <TagClear
                          variant='ghost'
                          key={currentFilter.value}
                          id={filterId}
                          display={currentFilter.display}
                          onClick={() => handleRemoveTag(filterId, currentFilter)}
                        />
                      )
                    })
                  )
                })}
                <ButtonGhost value="Clear All" onClick={handleClearAllTags} />
              </FilterListStyled>
            </WrapperFlex>
          </Container>
        </Section>
      )}
    </TopbarFilterStyled>
  )
}


