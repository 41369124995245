import React, { Fragment, useEffect } from "react";
import { getVal } from 'oolib';
import { StickyHorizontalScrollbar } from "../../generalUI/StickyHorizontalScrollbar";
import TableHeaderElem from "./comps/TableHeaderElem";
import TableRowElem from "./comps/TableRowElem";
import { TableSkeletonLoader } from "./comps/TableSkeletonLoader";
import { useParseTplToTableConfig } from "./CONFIG_PARSER/useParseTplToTableConfig";
import { StyledDataTableRowsContainer } from "./styled";
import { filterOutHiddenCols } from "./utils/filterOutHiddenCols";
import { sortCellDataAsPerOrderConfig } from "./utils/sortCellDataAsPerOrderConfig";
import { useHandleTableColWidths } from "./utils/useHandleTableColWidths";
import { useTableSort } from "./utils/tableSortUtils/useTableSort";



function TableElem({
  id,
  status,
  data: dataProp = [],
  
  tableConfig: tableConfigProp,
  actionsConfig,
  conditionsConfig,
  handleRowClick,
  TableWrapper = "div",
  
  lazyLoad: {
    observerRef,
    isFetchingNextPage,
    backendActiveSort,
    setBackendActiveSort,
  } = {},

  //builder props
  builderProps,

  handleCellChange,
  //for future
  addRow,
  enableContainerQuery

}){

  // const props = arguments[0]
  // useTraceUpdateOnLocal(props);  

  const { tableConfig } = useParseTplToTableConfig({ 
    tableConfigProp,
    isBackendSort : !!backendActiveSort 
  });

  /**
   * We feel that it might be more 'correct' to have the sidebar controlling hide/show & column order, 
   * within the table comp itself (rather than outsourcing to ContentTypeConfigDashboard). 
   * 
   * If we do make that change, then the below useEffect will become irrelevant
   * this is a bit problematic, cuz it led to infinite loop 
   * when sortCellData was done in useParseTplToTable...
   */
  useEffect(() => {
    builderProps?.trackParsedTableConfig && 
    builderProps.trackParsedTableConfig(
      sortCellDataAsPerOrderConfig(tableConfig)
    )
  },[tableConfig])
  
  /**
   * having this as a state here can be used for
   * giving user control over col widths in the future
   */
  const { colWidthConfig, setColWidthConfig } = useHandleTableColWidths({ tableConfig });

  //sort related stuff
  const { data, activeSorts, handleTableSort } = useTableSort({
    tableConfig,
    dataProp,
    backendActiveSort,
    setBackendActiveSort,
  });


  return (
    <StickyHorizontalScrollbar>
      <div style={{ width: "fit-content" }}>
      <TableWrapper enableContainerQuery={enableContainerQuery}>
        <TableHeaderElem
          id={id + "_header"}
          tableConfig={filterOutHiddenCols(sortCellDataAsPerOrderConfig(tableConfig))}
          handleTableSort={handleTableSort}
          activeSorts={activeSorts}
          actions={tableConfig.actions}
          colWidthConfig={colWidthConfig}
          setColWidthConfig={setColWidthConfig}
          backendActiveSort={backendActiveSort}
          builderProps={builderProps}
        />
        <StyledDataTableRowsContainer style={{ borderRight: !builderProps && !tableConfig.actions && 'none'}}>
          {status !== undefined && status === "loading" ? (
            <TableSkeletonLoader rowsCount={10} />
          ) : (
            data.map((d, i) => {
              let rowId = getVal(d, tableConfig.rowIdPath);
              return (
                <Fragment key={rowId}>
                <TableRowElem
                  key={rowId}
                  tableConfig={filterOutHiddenCols(sortCellDataAsPerOrderConfig(tableConfig))}
                  id={rowId}
                  data={d}
                  rowIdx={i}
                  actions={tableConfig.actions}
                  actionsConfig={actionsConfig}
                  conditionsConfig={conditionsConfig}
                  colWidthConfig={colWidthConfig}
                  handleRowClick={handleRowClick}
                  handleCellChange={handleCellChange}
                />
                { i === data.length - 1 &&
                  <div 
                    ref={observerRef} 
                    id={'observerAttached_'+ d._id + '_' + data.length }
                    /**
                     * it is extremely important that this id changes everytime a new set is rendered
                     * because, if the last item in the newly rendered set continues to be 'inView', then
                     * useElemInView checks to see if the id has changed. if it has, then it will make the fetch
                     * request for the next set of data.
                     */
                    />}
                </Fragment>
              );
            })
          )}

          {isFetchingNextPage && (
            <TableSkeletonLoader rowsCount={5} dataLength={data?.length || 0} />
          )}
          
        </StyledDataTableRowsContainer>
      </TableWrapper>
      </div>
    </StickyHorizontalScrollbar>
  )
};

export default TableElem;
