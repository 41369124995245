import { OKELink } from "oolib";
import React from "react";

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, (s, e) => {
    console.log({s,e})
    callback(s,e)
  });
};

export const genLinkDecorator = (directPropsFromRTEComp) => ({
  strategy: findLinkEntities,
  component: (props) => {
    
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    
    return (
        
      <OKELink invert={directPropsFromRTEComp.invert} color={"black"} to={url}>
        {props.children}
      </OKELink>
      
    );
  },
});
