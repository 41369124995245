import { EditorState, Modifier, SelectionState } from "draft-js";
import { cloneDeep } from "lodash";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import {
  _createAndInsertEntityIntoEditorState,
  _insertOverlayAnnoValues,
  // _getTextSelection,
  // _injectTextFragmentIntoAnnoData,
  _getBlocksWithSelection,
  _getSelectionStartEndOffset,
  getAllBlocksForFragmentKey,
} from "./helpers";

export const useHandleAnnoSelection = ({ annotation }) => {
  const [forceReadOnly, setForceReadOnly] = useState(undefined); //for when the editor's child comp is focussed
  const [showAnoBtn, setShowAnoBtn] = useState(false);
  const [showAnoLightbox, setShowAnoLightbox] = useState(false);

  const editorBoundsRef = useRef(null);

  const _handleAnnoSelection = () => {
    let selection = window.getSelection();
    let commonAncestorContainers = [];
    let ranges = [];

    /**
     * we want to get the common ancestor divs that contain the whole range of selection
     * i dont completely understand how separate range objects are defined,
     * but what we know for sure is, no matter what, all range objects needs to be within
     * THIS 'editorBoundsRef' ( because that contains the render of the draftjs data object ),
     * only then can we allow for annotation.
     * ( otherwise the anno lightbox will apear on all editors in the DOM,
     * because the selectionchange event is being heard at the document level )
     */
    for (let i = 0; i < selection.rangeCount; i++) {
      ranges[i] = selection.getRangeAt(i);
      commonAncestorContainers[i] =
        selection.getRangeAt(i).commonAncestorContainer;
    }

    let thisEditorContainsSelection = commonAncestorContainers.every((d) =>
      editorBoundsRef.current?.contains(d)
    );

    

    if (thisEditorContainsSelection) {
      if (
        window.getSelection().anchorOffset !== window.getSelection().focusOffset
      ) {
        setShowAnoBtn(true);
        setForceReadOnly(false);
      } else {
        setForceReadOnly(undefined);
      }
    }
  };

  useEffect(() => {
    if (annotation?.enable)
      document.addEventListener("selectionchange", _handleAnnoSelection);

    return () => {
      if (annotation?.enable)
        document.removeEventListener("selectionchange", _handleAnnoSelection);
    };
  }, []);

  return {
    showAnoBtn,
    setShowAnoBtn,
    forceReadOnly,
    setForceReadOnly,
    editorBoundsRef,
    showAnoLightbox,
    setShowAnoLightbox,
  };
};

export const insertAnno = ({
  editorState,
  value,
  onEditorChange,
  setCursorToEnd,
  cursorToEnd,
},callback) => {
  let contentState = editorState.getCurrentContent();
  let selectionState = editorState.getSelection();

  // getblocks that contain selection state
  var blocksWithSelection = _getBlocksWithSelection({
    blocks: contentState.getBlockMap(),
    selectionState,
  });




  let selStartEndPerBlock = _getSelectionStartEndOffset({
    blocksWithSelection,
    selectionState,
  });
  //get selStart & selEnd per block
  let dataBehindSelectionPerBlock = selStartEndPerBlock.map(
    ({ start, end, block }) => {
      let initAry = [];
      let prevVal = { range: [] };

      /**
       * loop through selStart to selEnd,
       * get all entityKeys and populate with related data. -> [ {...}, {...} ]
       * get all continuous ranges of no entity found -> [ [s,e],[s,e] ]
       * EDIT: better to have them in the same array :
       * initAry: [ { type: entity, range: [s,e], data: [{...}] }, { type: plain, range: [s,e] } ... ]
       */
      for (let i = start; i <= end; i++) {
        let thisEntityKey = block.getEntityAt(i);

        if (prevVal.key !== thisEntityKey) {
          //a new 'range has been detected. so first lets increase range[1] of prev range in order to close it correctly.
          if (initAry.length > 0) initAry[initAry.length - 1].range[1] = i;

          if (!thisEntityKey) {
            initAry.push({
              type: "plain",
              key: thisEntityKey,
              range: [i, i], // init range as same.
            });
          } else {
            let thisEntity = contentState.getEntity(thisEntityKey);

            if (thisEntity.getType() === "ANNO") {
              initAry.push({
                type: "entity",
                key: thisEntityKey,
                range: [i, i], // init range as same.
                data: cloneDeep(thisEntity.getData()),
              });
            }
          }
        } else {
          // i dont think this is required... since line 122 already does this WHEN it is required to do this.
          //doing this incr. every cycle is inefficient.
          initAry[initAry.length - 1].range[1] = i; //incr range
        }
        prevVal = initAry[initAry.length - 1];
      }
      /**
       * if initAry is just 1 type = 'entity' obj
       */
      if(initAry.length === 1 && initAry[0].type === 'entity'){
        let thisEntityKey = initAry[0].key;
        let beforeEntityKey = initAry[0].range[0] === 0 
          ? null
          : block.getEntityAt(initAry[0].range[0]-1)
        let afterEntityKey = block.getEntityAt(initAry[0].range[1]+1)
        switch(true){
          /**
           * if no entitykey match found on start & end then 
           * this is an exact selection of a underlayentity. 
           * save this info into the obj in initAry. 
           * will help us trigger the edit box i guess
           */
          case beforeEntityKey !== thisEntityKey && afterEntityKey !== thisEntityKey :
            initAry[0].isExactMatchOfUnderlayEntity = true;
            break;
            /*
             * else if this range is zero on start but ++ on end 
             * OR ++ on start & zero on end then do nothing,
             */
          default:
            break;
        }
      }

      return { block, data: initAry };
    }
  );


  



  let newEditorState = editorState;
  let thisFragmentKey = nanoid(4) //uuid.v4().replace(/-/g, "_");

  dataBehindSelectionPerBlock.map(
    ({ data: underlayEntityStatesAry, block }) => {

      selectionState = SelectionState.createEmpty(block.getKey())

      //if initAry is just one plain obj, then do a simple entity insert
      if (underlayEntityStatesAry.length === 1 && underlayEntityStatesAry[0].type === "plain") {
        //set selection
        let state = underlayEntityStatesAry[0];
        selectionState = selectionState.merge({
          anchorOffset: state.range[0],
          focusOffset: state.range[1],
          isBackward: false,
        })

        newEditorState = _createAndInsertEntityIntoEditorState({
          editorState: newEditorState,
          value: [{ 
            ...value, 
            fragmentKey: thisFragmentKey
          }],
          selection: selectionState,
        });
        
      } else {
      /**
       * PENDING
       * else if ()
       * if initAry is just 1 entity range, and has property isExactMatchOfUnderlayEntity = true
       * then it is the exact match of another entity range
       * then trigger lightbox with existing tags
       */
        //loop through initAry
        // let prevUnderlayEntityState;
        underlayEntityStatesAry.forEach((state, stateI) => {
          
          //setSelection
          selectionState = selectionState.merge({
            anchorOffset: state.range[0],
            focusOffset: state.range[1],
            isBackward: false,
          })

          // if entityData
          // createAndInsertEntity by updating prevData
          if (state.type === "entity") {
            // if in this loop iteration there is an entity behind me,
            // YES : because state.type === 'entity'
           
            
            
            let valueToInsert = _insertOverlayAnnoValues({
                prevVal: state.data,
                newVal: value, 
                newFragmentKey: thisFragmentKey,
              
                insertNewValAt: underlayEntityStatesAry.every( //this is done so that we get a proper mouse over on annos that are 'contained' within a larger anno. however, this doesnt work properly
                  (d) => d.type !== "plain"
                )
                  ? "start"
                  : "end",
              })

            newEditorState = _createAndInsertEntityIntoEditorState({
              editorState: newEditorState,
              value: valueToInsert,
              selection: selectionState,
            });

            // prevUnderlayEntityState = updatedUnderlayEntityState //this way prev state stay updated for the next iteration. (ghostState needs this in _updateFragIdx )
            // if plain
            // createAndInsertEntity with value = [value + fragKey + fragIdx (incremented as per loop iteration)]
          } else if (state.type === "plain") {
            newEditorState = _createAndInsertEntityIntoEditorState({
              editorState: newEditorState,
              value: [
                {
                  ...value,
                  fragmentKey: thisFragmentKey
                },
              ],
              selection: selectionState,
            });
          }
        });
      }

      onEditorChange && onEditorChange("", newEditorState);

      setCursorToEnd && setCursorToEnd(cursorToEnd + 1);


      callback();

      // if (setForceReadOnly) setForceReadOnly(undefined);
      // if (setShowAnoLightbox) setShowAnoLightbox(undefined);
    }
  );
};


export const removeAnnotation = (
  { editorState, targetFragmentKey, onEditorChange },
  callback
) => {
  const blockKeys = getAllBlocksForFragmentKey({ targetFragmentKey });

  const editReadyData = [];
  let i = 0;

  let contentState = editorState.getCurrentContent();
  const selectionState = SelectionState.createEmpty();

  // findEntityRanges gets execute for each entity range for a gi ven block

  //forEach   Blockkey

  // get the block key from props

  for (let key in blockKeys) {
    const block = contentState.getBlockForKey(key);

    block.findEntityRanges(
      (characterdata) => {
        const entityKey = characterdata.getEntity();

        if (
          !entityKey ||
          contentState.getEntity(entityKey).getType() !== "ANNO"
        )
          return false; // plain text

        const entityInstance = contentState.getEntity(entityKey);

        const entityData = entityInstance.getData();

        // loop through each entity data
        for (let e of entityData) {
          if (e.fragmentKey === targetFragmentKey) {
            editReadyData[i] = { data: entityData, entityKey, blockKey: key };

            // the moment a match is found that means i can add the whole entityData to my editReadyData
            // and return true

            return true;
          }
        }

        // if return true the below callback will execute with entity start and end
        return false; // if return false the below callback will not execute
      },
      (start, end) => {
        editReadyData[i++].range = { start, end };
        // currently using this entity range to create selection state to remove the entity range for this selection
        // see handleRemoveAllTags
      }
    );
  }

  editReadyData.forEach((d) => {
    const oldData = d.data;

    // for current entity data filter out all items that does not have a matching fragment key

    const filterData = oldData.filter(
      (d) => d.fragmentKey !== targetFragmentKey
    );

    // if filterData is empty that, entity range for this can be safely removed
    // else if filterData is not empty then simple replace the entity data for the entity key

    if (filterData.length === 0) {
      const range = d.range;
      const updatedSelection = selectionState.merge({
        focusOffset: range.end,
        anchorOffset: range.start,
        isBackward: false,
        focusKey: d.blockKey,
        anchorKey: d.blockKey,
      });

      // from docs: not deprecated
      contentState = Modifier.applyEntity(contentState, updatedSelection, null);
    } else {
      const newData = [...filterData];

      contentState.replaceEntityData(d.entityKey, newData);
    }
  });

  const newEditorState = EditorState.set(editorState, {
    currentContent: contentState,
  });

  onEditorChange(null, newEditorState);

  callback();
};

export const editAnnotation = (
  { editorState, targetFragmentKey, onEditorChange, data },
  callback
) => {
  const blockKeys = getAllBlocksForFragmentKey({ targetFragmentKey });

  const editReadyData = [];
  let i = 0;

  let contentState = editorState.getCurrentContent();

  // findEntityRanges gets execute for each entity range for a gi ven block

  //forEach   Blockkey

  // get the block key from props

  for (let key in blockKeys) {
    const block = contentState.getBlockForKey(key);

    block.findEntityRanges(
      (characterdata) => {
        const entityKey = characterdata.getEntity();

        if (
          !entityKey ||
          contentState.getEntity(entityKey).getType() !== "ANNO"
        )
          return false; // plain text

        const entityInstance = contentState.getEntity(entityKey);

        const entityData = entityInstance.getData();

        // loop through each entity data
        for (let e of entityData) {
          if (e.fragmentKey === targetFragmentKey) {
            editReadyData[i] = { data: entityData, entityKey, blockKey: key };

            // the moment a match is found that means i can add the whole entityData to my editReadyData
            // and return true

            return true;
          }
        }

        // if return true the below callback will execute with entity start and end
        return false; // if return false the below callback will not execute
      },
      (start, end) => {
        editReadyData[i++].range = { start, end };
        // currently using this entity range to create selection state to remove the entity range for this selection
        // see handleRemoveAllTags
      }
    );
  }

  editReadyData.forEach((d) => {
    const oldData = d.data;

    for (let i = 0; i < oldData.length; i++) {
      if (oldData[i].fragmentKey === targetFragmentKey) {
        // if a match, just replace the tags object with the new selectedTags data

        oldData[i].tags = data;
      }
    }

    const newData = [...oldData];

    //not doing a set editor state
    // from docs : replaceEntityData modifies the contentState instance, do not returns anything
    // this will not work when re-render does not happens, so call the editorChange to make sure this contentState gets updated
    contentState.replaceEntityData(d.entityKey, newData);
  });

  const newEditorState = EditorState.set(editorState, {
    currentContent: contentState,
  });

  onEditorChange(null, newEditorState);

  callback();
};
