


export const fixedGetDataConfig = {
    
    findQuery: { "kp_published_status": "published"},

    population: [{path: "meta.kp_contributed_by", model: 'user', select: ['name', 'avatar']}],
    useAggregation: true,
    lookupConfig: [
        {
            $lookup: {
                from: 'users',
                localField: "meta.kp_contributed_by",
                foreignField: '_id',
                as: 'meta.kp_contributed_by',
                pipeline:[
                { $project:{name:1,avatar:1}}	
                ]
              }
        },
        {
            $unwind: {
                path: `$meta.kp_contributed_by`,
                preserveNullAndEmptyArrays: true,
            }
        }
    ]
}