import React from 'react'


import PrimaryHeaderV2 from './PrimaryHeaderV2'



export const Header = () => {
    
    return <PrimaryHeaderV2/>
}