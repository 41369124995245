import axios from "axios";
import { useMutation } from "react-query";

// import { useBannerContext } from "oolib";

export const useCreateAndPublishMultipleContent = () => {
//   const { SET_ALERT_BANNER } = useBannerContext();
  return useMutation(
    ({ data, contentType }) =>
      axios.post(`/api/content/createAndPublishMultipleContent/${contentType}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      }),
    // {
    //   onSuccess: (res) => {
    //     SET_ALERT_BANNER({
    //       color: "green",
    //       msg: UIContent.general.batchRegisterSuccessfulMsg,
    //       timeOut: 3000,
    //     });
    //   },
    // }
  );
};
