import { isDeviceOnline } from "../../../../utils/environmentConfig";
import { useGetOneTagV3 } from "../../../../utils/react-query-hooks/tags";
import { getVal } from 'oolib'
export {useGetTypeCategoryAndTags} from './useGetTypeCategoryAndTags'


export const handleSiblingValuePath = (
  tagTypeProp,
  siblingValuePath,
  content
) => {
  let tagType = tagTypeProp;
  /** read about siblingValue use case in DynamicDropdown comp */
  if (tagType === "siblingValue" && siblingValuePath) {
    tagType = getVal(content, siblingValuePath);
  }
  return tagType || undefined; //cuz get val returns null if it doesnt find a value (i think), and we want to depend on a clean undefined rather than a null
};

export const handleSiblingValuePathForFilters = (
  filtersProp,
  siblingValuePath,
  content
) => {
  let filters = filtersProp;
  if(filtersProp){
    filters = {};
    Object.keys(filtersProp).forEach(key => {
      let val = handleSiblingValuePath(filtersProp[key], siblingValuePath, content)
      if(val !== undefined) filters[key] = val;
    })
  }
  return filters;
}

/**
 * 
 * @param {*} data 
 * @param {*} tagCategory 
 * @param {*} optionConfig 
 * option config is an Object with following properties:
 * display: String/Array of Strings (valuePath) first succesful path data is returned
 
 * desc: String/Array of Strings (valuePath) first succesful path data is returned
 * 
 * @returns OptionsArray
 */
export const convertTagDocsDataToOptions = (data, tagCategory = "tags", optionConfig = {}) => {

  const optionConfigLookupFns = {
    joinDesc: (d, {valuePaths}) => {    
      return valuePaths.map(path => getVal(d, path)).join(' | ')
    }
  }

  const getDisplay = (d) => {
    if(optionConfig.display) return getVal(d,optionConfig.display)
    return (
      d.display || //deprecated tags
      d.name || //user docs
      (d.main?.title?.blocks && d.main.title.blocks[0]?.text) || d.main?.title || d.main?.kp_link?.title || d.meta.caseNumber || 'undefined'
      )
  };
  
  const getDesc = (d) => optionConfig.desc
      ? optionConfig.desc.fn
        ?  optionConfigLookupFns[optionConfig.desc.fn](d, optionConfig.desc.args)
        : getVal(d, optionConfig.desc)
      : undefined

    return data.map((d) => ({
    ...d,
    display: getDisplay(d),
    value: d._id,
    tagCategory,

    ...(getDesc(d) ? {desc: getDesc(d)} : {})

  }));
};

export const convertSingleValueToTagsValue = (tagType, v) => {
  //in change handler
  return {
    collectionId: tagType,
    data: [
      {
        _id: v.value,
        display: v.display,
        tagId: v.tagId,
        display_desc: v.display_desc, //only if injectOtherOption = true, then this is possible (rare)
        value_desc: v.value_desc //only if injectOtherOption = true, then this is possible (rare)
      },
    ],
  };
};

export const convertSingleTagDocToTagsValue = (tagType, v) => {
  return {
    collectionId: tagType,
    data: [
      {
        _id: v._id,
        display: v.display,
        tagId: v.tagId,
      },
    ],
  };
}

export const convertMultiValueToTagsValue = (tagType, v) => {
  //in change handler
  return {
    collectionId: tagType,
    data: v.map((vv) => ({
      _id: vv.value,
      display: vv.display,
      tagId: vv.tagId,
      display_desc: vv.display_desc, //only if injectOtherOption = true, then this is possible (rare)
      value_desc: vv.value_desc //only if injectOtherOption = true, then this is possible (rare)
    })),
  };
};

export const useConvertTagsValueToSingleValue = ({value, tagCategory, optionConfig}) => {


  const {data, status } =  useGetOneTagV3(
    value?.collectionId, 
    value?.data[0]?._id,
    {
      tagCategory,
      tagId: value?.data[0]?.tagId,
      enabled: value?.data?.length > 0 && isDeviceOnline()
    }
  );

  if(!value?.data || value.data.length === 0) return undefined;

  
  return (status === 'loading' || !isDeviceOnline()) //basically if device is offline, display the stored value, dont depend on api call, cuz that has been disabled when offline
    ? {display:value.data[0].display,value:value.data[0]._id} 
    : data //what if the tag gets deleted in the future? this is a hack fix for now...
      ? convertTagDocsDataToOptions([data], tagCategory, optionConfig)[0]
      : undefined

}

export const convertTagsValueToSingleValue = (value, tagCategory) => {
  return (
    value?.data?.length === 1 && {
      ...value.data[0],
      value: value.data[0]._id,
      tagType: value.collectionId,
      tagCategory,
    }
  );
};

export const convertTagsValueToMultiValue = (value, tagCategory) => {
  return value?.data?.map((v) => ({
    ...v,
    value: v._id,
    tagType: value.collectionId,
    tagCategory,
  }));
};
