import React from "react";
import { InfoTextStyled } from "./styled";
import { SANS_3, colors, icons } from "oolib";

const { Stars } = icons;

export default function InfoText({ title, description, showIcon, bgColor="greyColor10" }) {
  return (
    <InfoTextStyled bgColor={colors[bgColor]}>
      {showIcon ? (
        <div style={{ flex: 0 }}>
          {" "}
          <Stars size={20} />
        </div>
      ) : null}
      <div>
        {" "}
        {title ? <SANS_3 semibold value={title} /> : null}
        {description ? <SANS_3 value={description} /> : null}
      </div>
    </InfoTextStyled>
  );
}
