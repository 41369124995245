import { colors } from "oolib";
import React from "react";
import styled from "styled-components";
import { TitleComp } from "../comps/TitleComp";

const StyledSectionWrapper = styled.div`
  /* padding-top: 3rem;
  
  &:first-of-type{
    padding-top: 0
  } */
  ${({ depthIdx }) =>
    depthIdx === 0
      ? `border-bottom : 1px solid ${colors.greyColor15};
       padding-bottom: 2rem;
       margin-bottom: 2rem`
      : ""}
`;

export const DefaultSectionWrapperComp = ({
  children,
  title,
  subtitle,
  depthIdx,
}) => {
  return (
    <StyledSectionWrapper depthIdx={depthIdx}>
      {<TitleComp {...{ title, subtitle, depthIdx }} />}
      {children}
    </StyledSectionWrapper>
  );
};
