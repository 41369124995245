import { CardEmbed, SkeletonLoader } from "oolib";
import React, { useMemo } from "react";
import { getQueryParam } from "../../../../../../utils/general";
import { useGetContribution } from "../../../../../../utils/react-query-hooks/contributions";
import LinkEmbed from "../../../../_V2/LinkEmbed";
import AtomicBlockShell from "../AtomicBlockShell";

import { RTEDefaultInternalEmbedConfig } from "../LinkOrEmbedModal/configs/RTEDefaultInternalEmbedConfig";

/*
   -> Entity Update
   -> Wrap the component in shell
   -> handleSetTempReadOnly()
*/

export const RTEInternalEmbed = ({
  id,
  value,

  // --- RTE EMBED PROPS --- //
  entityKey,
  block,
  editorState,
  handleChange,
  readOnlyProp,
  setReadOnly,
  // embedType = "external-link",
}) => {

  /**
   * because this can become all sorts of complex, for now we are doing a simple thing:
   * - if the link is a '/published-page/' link, then make api call to get contr data,
   *   and use that data to Embed proper Card with 'configs'
   * - if it is any other internal link, for now, simply embed it using the LinkEmbed component 
   *   (which is a poorly named comp. it actually is an external embed comp, that does the whole open graph fetching etc)
   */
  const {
    compToUse,
    publishedPageLinkExtracts
  } = useMemo(() => {
    let compToUse, publishedPageLinkExtracts;
    if(value.includes('/published-page/')){ //isPblPageLink
      //e.g pbl page link : www.abc.com/published-page/stories?id=1234
      compToUse = 'CardEmbed';
      const urlSplit = value.split('/published-page/')[1].split('?') //becomes: [ stories, id=1234 ]
      publishedPageLinkExtracts = {
        contentType: urlSplit[0],
        contentId: getQueryParam(`?${urlSplit[1]}`, 'id').trim()
      }
    }else{
      compToUse = 'LinkEmbed'
    }

    return ({
      compToUse,
      publishedPageLinkExtracts
    })
    
  })

  // console.log({compToUse, publishedPageLinkExtracts})

  const {data, status: queryStatus} = useGetContribution({
    contentType: publishedPageLinkExtracts?.contentType,
    contentId: publishedPageLinkExtracts?.contentId,
    mode: 'view',
    enabled: !!publishedPageLinkExtracts //if this exists, then it means its a published page link
  })

  
  return (
    <AtomicBlockShell
      {...{ id, readOnlyProp, setReadOnly, editorState, handleChange, block }}
      >
      {
        compToUse === 'LinkEmbed'
        ? <LinkEmbed value={value}/>
        : queryStatus === 'loading'
          ? <SkeletonLoader style={{height: '150px'}}/>
          : queryStatus === 'error'
            ? <CardEmbed
                config={{title: 'title'}}
                data={{
                  title: 'Content Not Found :('
                }}
                disabled
                />
          : <CardEmbed
              config={RTEDefaultInternalEmbedConfig}
              data={data.contribution}
              to={value}
              />
      }
      {/* <div>internal link embed here</div> */}
    </AtomicBlockShell>
  );
};
