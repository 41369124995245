import React from "react";
import { makeArrayFromLength, ButtonGhost, DropdownMulti, colors } from "oolib";
import { convertTagDocsDataToOptions } from "../../../utils";

export default function Dropdown(props) {
  const {
    handleCreateTagOnKeyDown,
    handleOnChange,
    createTagText,
    setCreateTagText,
    fetchTagStatus,
    setShowOptionsState,
    isSearchable,
    isTagsStyle,
    displayCompStyle,
    optionConfig,
    value,
    options,
    tagCategory,
    filteredOptions,
    tagCreateIsEnabled,
    createTagStatus,
    handleCreateTag,
    readOnly,
  } = props;

  return (
    <div onKeyDown={handleCreateTagOnKeyDown}>
      <DropdownMulti
        {...props}
        broadcastShowOptions={(bool) => setShowOptionsState(bool)}
        isSearchable={isSearchable}
        displayCompStyle={displayCompStyle}
        isTagsStyle={isTagsStyle}
        // variant={"tags"}
        readOnly={readOnly}
        onChange={(k, v) => handleOnChange(k, v)}
        value={value}
        options={
          fetchTagStatus === "loading"
            ? makeArrayFromLength(8).map((d) => {
                return { loading: true };
              })
            : fetchTagStatus === "error"
            ? [{ display: "error loading options", value: "error" }]
            : fetchTagStatus === "success" &&
              convertTagDocsDataToOptions(options, tagCategory, optionConfig)
        }
        searchString={createTagText}
        setSearchString={setCreateTagText}
        setFilteredOptions={(ops) => {
          if (ops && ops.length !== filteredOptions.current.length)
            filteredOptions.current = ops;
        }}
        genCreateTagButton={(options) => {
          return (
            tagCreateIsEnabled &&
            createTagText &&
            options.every((op) => op.display !== createTagText) && (
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: colors.white,
                }}
              >
                <ButtonGhost
                  style={{ width: "100%", justifyContent: "flex-start" }}
                  disabled={createTagStatus === "loading"}
                  children={
                    createTagStatus === "loading"
                      ? "Creating..."
                      : `Create ${createTagText}`
                  }
                  icon="Plus"
                  iconSize="S"
                  onClick={(e) => {
                    handleCreateTag(e);
                  }}
                />
              </div>
            )
          );
        }}
      />
    </div>
  );
}
