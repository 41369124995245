
import { queryClient } from "../../../../../..";
import { __CheckAccessToContentType } from "../../../../../../utils/accessControl";
import { __GetAllContentTypeConfig } from "../../../../../../utils/getters/gettersV2";



  //i.e segment is collections | access CHECK | showListingPage CHECK
  export const getViewableOptionsBySegment = ({segment}) => {
    const {user} = queryClient.getQueryData('userData') || {}
    return __GetAllContentTypeConfig()
      .filter(
        (c) =>
          c.general.segment === segment &&
          c.showListingPage !== false &&
          __CheckAccessToContentType(user, "READ", c.id)
      )
      .map((c) => ({
        display: c.content?.title || c.id,
        value: c.id,
        to: `/published-listing/${c.id}`,
      }));
  }
    
