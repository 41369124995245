import React from "react";
import { useFormValidationContext } from "../../../contexts/formValidationContext";
import { EditBlockGenerator } from "../../../utils/blockGenerators";
import { blockMapper } from "../../../utils/templating/blockMapper";
import { spaceOps } from "../../../utils/templating/spaceAndDivisionConfigs";
import { PaddingTopBottom30 } from "oolib";
import "./_style.scss";

const Layout = ({ handleOnChange, content, tpl, memo, rightWrongResults }) => {
  const { formValidation } = useFormValidationContext();

  const noReadOnlyComps = [
    "RadioList",
    "CheckboxList"
  ];
  const injectReadOnlyProp = (block) => {
    return {...block, props: {...block.props, readOnly: true}}
  }

  return (
    <div>
      {blockMapper({
        content,
        activeSpace: spaceOps.worksheets.find((d) => d.value === "templates"),
        tplConfig: tpl,
        blockGenerator: ({ block, blockPath }) => (
          <EditBlockGenerator
            block={
              !!rightWrongResults && !noReadOnlyComps.includes(block.comp)
                ? injectReadOnlyProp(block)
                : block
            }
            onChange={handleOnChange && handleOnChange}
            content={content}
            Wrapper={PaddingTopBottom30}
            memo={memo}
            rightWrongResults={rightWrongResults}
            formValidation={formValidation}
            />
        )
      })}
    </div>
  );
};

export default Layout;
