import { createAtomicBlockEntity } from "../../../utils";
import { toggleTextFormat } from "../handlers";
import { RichUtils } from "draft-js";

const { getCurrentBlockType } = RichUtils;

export const rteToolbarConfig = [
  {
    id: "headers_dropdown",
    icon: "LetterH",
    tooltipText: "header",
    menu: [
      {
        id: "header-one",
        btnComp: "StyledOptionBtn",
        icon: "TextHOne",
        selectedTextTool: true,
        onClick: ({ editorState, handleChange }) => {
          toggleTextFormat({
            editorState,
            handleChange,
            scope: "block",
            style: "header-one",
          });
        },
        checkIfActive: ({ editorState }) => {
          return getCurrentBlockType(editorState) === "header-one";
        },
      },
      {
        id: "header-two",
        btnComp: "StyledOptionBtn",
        icon: "TextHTwo",
        selectedTextTool: true,
        onClick: ({ editorState, handleChange }) => {
          toggleTextFormat({
            editorState,
            handleChange,
            scope: "block",
            style: "header-two",
          });
        },
        checkIfActive: ({ editorState }) => {
          return getCurrentBlockType(editorState) === "header-two";
        },
      },
      {
        id: "header-three",
        btnComp: "StyledOptionBtn",
        icon: "TextHThree",
        selectedTextTool: true,
        onClick: ({ editorState, handleChange }) => {
          toggleTextFormat({
            editorState,
            handleChange,
            scope: "block",
            style: "header-three",
          });
        },
        checkIfActive: ({ editorState }) => {
          return getCurrentBlockType(editorState) === "header-three";
        },
      },
    ],
  },
  {
    id: "BOLD",
    btnComp: "StyledOptionBtn",
    icon: "TextBolder",
    tooltipText: "Bold",
    selectedTextTool: true,
    onClick: ({ editorState, handleChange }) => {
      toggleTextFormat({
        editorState,
        handleChange,
        scope: "inline",
        style: "BOLD",
      });
    },
    checkIfActive: ({ editorState }) => {
      return editorState.getCurrentInlineStyle().has("BOLD");
    },
  },
  {
    id: "ITALIC",
    btnComp: "StyledOptionBtn",
    icon: "TextItalic",
    tooltipText: "Italic",
    selectedTextTool: true,
    onClick: ({ editorState, handleChange }) => {
      toggleTextFormat({
        editorState,
        handleChange,
        scope: "inline",
        style: "ITALIC",
      });
    },
    checkIfActive: ({ editorState }) => {
      return editorState.getCurrentInlineStyle().has("ITALIC");
    },
  },
  {
    id: "UNDERLINE",
    btnComp: "StyledOptionBtn",
    icon: "TextUnderline",
    tooltipText: "Underline",
    selectedTextTool: true,
    onClick: ({ editorState, handleChange }) => {
      toggleTextFormat({
        editorState,
        handleChange,
        scope: "inline",
        style: "UNDERLINE",
      });
    },
    checkIfActive: ({ editorState }) => {
      return editorState.getCurrentInlineStyle().has("UNDERLINE");
    },
  },
  {
    id: "blockquote",
    btnComp: "StyledOptionBtn",
    icon: "Quotes",
    tooltipText: "set line as a quote",
    selectedTextTool: true,
    onClick: ({ editorState, handleChange }) => {
      toggleTextFormat({
        editorState,
        handleChange,
        scope: "block",
        style: "blockquote",
      });
    },
    checkIfActive: ({ editorState }) => {
      return getCurrentBlockType(editorState) === "blockquote";
    },
  },
  {
    id: "lists_dropdown",
    icon: "ListPlus",
    tooltipText: "list item",
    menu: [
      {
        id: "unordered-list-item",
        btnComp: "StyledOptionBtn",
        icon: "ListDashes",
        selectedTextTool: true,
        onClick: ({ editorState, handleChange }) => {
          toggleTextFormat({
            editorState,
            handleChange,
            scope: "block",
            style: "unordered-list-item",
          });
        },
        checkIfActive: ({ editorState }) => {
          return getCurrentBlockType(editorState) === "unordered-list-item";
        },
      },
      {
        id: "ordered-list-item",
        btnComp: "StyledOptionBtn",
        icon: "ListNumbers",
        selectedTextTool: true,
        onClick: ({ editorState, handleChange }) => {
          toggleTextFormat({
            editorState,
            handleChange,
            scope: "block",
            style: "ordered-list-item",
          });
        },
        checkIfActive: ({ editorState }) => {
          return getCurrentBlockType(editorState) === "ordered-list-item";
        },
      },
    ],
  },
  {
    id: "uploads_dropdown",
    icon: "UploadSimple",
    tooltipText: "upload",
    menu: [
      {
        id: "upload_multi_images",
        btnComp: "UploadButton",
        icon: "MultipleImages",
        accept: "image/*",
        multiple: true,
        onChange: ({ e, editorState, handleChange }) => {
          createAtomicBlockEntity(editorState, handleChange, "RTEImageInput", {
            files: e.target.files,
            multiple: true,
          });
        },
      },
      {
        id: "upload_inline_image",

        btnComp: "UploadButton",
        icon: "ImageSquare",
        accept: "image/*",
        onChange: ({ e, editorState, handleChange }) => {
          createAtomicBlockEntity(editorState, handleChange, "RTEImageInput", {
            files: e.target.files,
          });
        },
      },
      {
        id: "upload_inline_video",
        btnComp: "UploadButton",
        icon: "VideoCamera",
        accept: "video/*",
        onChange: ({ e, editorState, handleChange }) => {
          createAtomicBlockEntity(editorState, handleChange, "RTEVideoInput", {
            files: e.target.files,
          });
        },
      },
      {
        id: "upload_inline_audio",
        btnComp: "UploadButton",
        icon: "AudioEmbedIcon",
        accept: "audio/*",
        onChange: ({ e, editorState, handleChange }) => {
          createAtomicBlockEntity(editorState, handleChange, "RTEAudioInput", {
            files: e.target.files,
          });
        },
      },
      {
        id: "upload_inline_pdf",
        btnComp: "UploadButton",
        icon: "FilePdf",
        accept: "application/pdf",
        onChange: ({ e, editorState, handleChange }) => {
          createAtomicBlockEntity(editorState, handleChange, "RTEPDFInput", {
            files: e.target.files,
          });
        },
      },
    ],
  },
  {
    id: "embed",
    btnComp: "StyledOptionBtn",
    icon: "Cards",
    tooltipText: "Embed",
    onClick: ({ editorState, handleChange, setShowLinkOrEmbedModal }) => {
      setShowLinkOrEmbedModal({ mode: "embed" });
      // createAtomicBlockEntity(editorState, handleChange, 'IFrameInput')
    },
  },
  {
    id: "simpleTable",
    btnComp: "StyledOptionBtn",
    icon: "Table",
    tooltipText: "Table",
    onClick: ({ editorState, handleChange }) => {
      createAtomicBlockEntity(editorState, handleChange, "RTESimpleTable", {});
    },
  },
  {
    id: "link",
    btnComp: "StyledOptionBtn",
    icon: "LinkSimple",
    tooltipText: "Link",
    selectedTextTool: true,
    disableIfNoSelect: true,
    onClick: ({ editorState, handleChange, setShowLinkOrEmbedModal }) => {
      setShowLinkOrEmbedModal({ mode: "link" });
    },
    checkIfActive: ({ editorState }) => {
      console.log("check if has inline entity range which is a link");
      // return editorState.getCurrentInlineStyle().has('UNDERLINE')
    },
  },
  {
    id: "anno", // this id NEEDS to stay this way. because we use it for some logic, to enable / disable annotation on the RTE. (inside Toolbar comp)
    btnComp: "StyledOptionBtn",
    icon: "HighlighterCircle",
    tooltipText: "Annotate",
    selectedTextTool: true,
    disableIfNoSelect: true,
    onClick: ({
      editorState,
      handleChange,
      setShowLinkOrEmbedModal,
      setShowAnnoLightbox,
      EditorRef,
    }) => {
      let boundingRect = window
        .getSelection()
        .getRangeAt(0)
        .getBoundingClientRect();
      let wrapperRect =
        EditorRef.current && EditorRef.current.getBoundingClientRect();
      let position = {
        x: boundingRect.x + boundingRect.width / 2 - wrapperRect.x,
        y: boundingRect.top - wrapperRect.top - 7.71, //7.71 is the height of that triangular tip. (used pythagoras, considering the sides of that 45 degree rotated square are 10px)
      };
      setShowAnnoLightbox({ position });
    },
    // checkIfActive: ({editorState}) => {
    //   console.log('check if has inline entity range which is a link')
    //   // return editorState.getCurrentInlineStyle().has('UNDERLINE')
    // }
  },
];
