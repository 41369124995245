import React from "react";
import { scrollModuleInView } from "../../../../../utils/scrollModuleInView";
import { ButtonContentEngagement } from "./ButtonContentEngagement";
import { _Locale } from "../../../../../components/locale/Locale";


const getText = (comments) => {
  if(!comments || comments.length===0) return _Locale('Comment')
  return `${comments.length} ${_Locale('Comments')}`
}

export const CommentsButton = ({ _comments, invert, textVariant = "long" }) => {
  return (
    <ButtonContentEngagement
      icon="ChatText"
      invert={invert}
      onClick={() => scrollModuleInView("commentsModule")}
      text={
        textVariant === "short"
          ? ""
          :getText(_comments) 
      }
    />
  );
};
