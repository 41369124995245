import { OKELink, SANS_2 } from "oolib";
import React from "react";
import { stitchLink } from "../../../../../../utils/general";


const TableLink = ({
  value,
  to: toConfig,
  icon,
  tableRowData,
  typoComp = "SANS_2",
}) => {
  let comps = { SANS_2 };
  let Comp = comps[typoComp];

  let to = stitchLink(tableRowData, toConfig);

  return (
    <Comp>
      <OKELink {...{ to, icon }}>{value}</OKELink>
    </Comp>
  );
};

export default TableLink;
