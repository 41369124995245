import React from "react";
import { SANS_3 } from 'oolib'

const KPDataSummaryCardHeader = ({ icon, title }) => (
      <div className="kp-data-card-header">
        <div className="kp-data-card-header__icon">{icon}</div>
        <SANS_3 semibold className="t-case kp-data-card-header__title">{title}</SANS_3>
      </div>
);

export default KPDataSummaryCardHeader;
