import React from 'react'
import { Container, PaddingTop50, SANS_0, SANS_3_4, Wrapper700 } from 'oolib'
import { _Locale } from '../../../components/locale/Locale'

export const LocalTest = () => {

    const variables = {
        userName : "kishan singh",
        userName2: "Harsh",
        userName3: "Rahul"
    }

  return (
    <Container>
            <Wrapper700>
                <PaddingTop50 />
                <SANS_3_4>{_Locale(`Type to search`)}</SANS_3_4>
                <SANS_3_4>{_Locale(`Page currently under edit by <word1 : ${variables.userName}>. They need to publish their changes before you can edit it.`)}</SANS_3_4>
                <SANS_3_4>{_Locale(`Page currently under edit by <word1 : ${variables.userName}>. They need to publish their changes before you can edit it.`)}</SANS_3_4>
            </Wrapper700>
    </Container>
  )
}
