import { colors, ModalSmall, icons, DropdownSingle } from 'oolib';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { domainToTenantMap } from '../../TENANT_CONFIGS';



const { UserSwitch } = icons;
const StyledToggleModalButton = styled.div`
  width: 4rem;
  height: 4rem;
  z-index: 1000000;
  border-radius: 50%;
  position: fixed;
  bottom: 7px;
  border: 2px solid ${({ theme }) => colors.greyColor100};
  right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryColor40};
  cursor: pointer;
`;

export const LocalhostTenantSwitcher = (props) => {
  //this is only for localhost

  let localhost_tenantId =
    localStorage.getItem('localhost_tenantId') ||
    Object.values(domainToTenantMap)[0];

  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <StyledToggleModalButton onClick={() => setShowModal((prev) => !prev)}>
        <UserSwitch color={colors.greyColor100} weight="bold" size={20} />
      </StyledToggleModalButton>
      {showModal && (
        <ModalSmall title="Switch Tenant" onClose={() => setShowModal(false)}>
          <DropdownSingle
            options={Object.values(domainToTenantMap)
              .map((d) => ({
                display: d,
                value: d,
              }))
              .filter(
                (d, i, self) =>
                  i === self.findIndex((dd) => dd.value === d.value)
              )}
            value={{ display: localhost_tenantId, value: localhost_tenantId }}
            onChange={(k, v) => {
              localStorage.setItem('localhost_tenantId', v.value);
              window.location.reload();
            }}
          />
        </ModalSmall>
      )}
    </div>
  );
};
