import { useMemo, useState } from "react";

import { fetchFilterOptionsConfigs } from "../configs";
import { getFilterType } from "./getFilterType";
import { makeArrayFromLength } from "oolib";
import { useTheme } from "styled-components";

export const useGetOptionsByFilterType = ({
  filterConfig,
  contentTypes,
  activeFilters,
  initEnableDataFetch = false
}) => {
  const [enableDataFetch, _setEnableDataFetch] = useState(initEnableDataFetch);
  const theme = useTheme()
  const setEnableDataFetch = () => {
    /**
     * this needs to be toggled to true only once. then we dont want to mess with it, since  
     * we want to only enable the query hook the first time the filter is opened. 
     * After that, we do not want to disable it again
     */
    if(enableDataFetch) return;
    //else enableDataFetch is false so..
    _setEnableDataFetch(true)
  }

  /**
   * this is temporary in order to separate 'tags' from 'contentTags'.
   * AND to merge profileTagType & usersType into a single 'usersType'.
   * Eventually 'tags' & 'profileTagType' will be deprecated,
   * and we wont need this function anymore.
   */
  const { filterType } = getFilterType(filterConfig);
  const useDoNothing = () => {};

  const { hook, getArgs, convertToOptions, getFilterDisplay } =
    fetchFilterOptionsConfigs[filterType] || {};

  const useGetFilterOptions = hook || useDoNothing;

  const { data, status, isRefetching } = useGetFilterOptions(
    ...getArgs({ filterConfig, enableDataFetch, contentTypes, activeFilters, theme })
  )
  
  const options = useMemo(() => {
    switch (status) {
      case "loading":
        return makeArrayFromLength(8).map((d) => {
          return { loading: true };
        });
      case "error":
        return [{ display: "error loading options", value: "error" }];
      case "success":
        return convertToOptions({ data, filterConfig });
    }
  }, [status, isRefetching]);

  return {
    enableDataFetch,
    setEnableDataFetch,
    options,
    display: getFilterDisplay({filterConfig}) || filterConfig.filterId
  };
};
