import styled from "styled-components";
import {colors, globalHoverOnWhiteBG} from 'oolib'
const {greyColor15}  = colors

export const ContentSectionStyled = styled.div`
  padding: 1rem 0;

  /* border-top: 1px solid ${greyColor15}; */
`;

export const ContentSectionItemsStyled = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem 3rem;

  justify-content: space-between;
 ${globalHoverOnWhiteBG}
`;
