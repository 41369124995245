import { Loader } from 'oolib'
import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import {
	__CheckAccessToModule
} from '../../../utils/accessControl'
import { useGetQueryState } from '../../../utils/react-query-hooks/general'


const RouteAccessModule = ({
	moduleId,
	ACTION,
	component: Component,
	path,
	...rest
}) => {
	let location = useLocation()

	let { status, data: userData } = useGetQueryState('userData')

	const hasAccess = () => {
		if (!userData?.user) {
			// not logged in
			return {
				has: __CheckAccessToModule(undefined, ACTION, moduleId),
				redirectTo: {
					pathname: '/login',
					state: {
						from: location.pathname + location.search,
					},
				},
			}
		}

		// else is logged in, and either has access to this content type or not.
		// if has, then let them through.
		// if not then simply redir back to the page they were coming from.


		console.log({access:__CheckAccessToModule(userData?.user, ACTION, moduleId)})

		return {
			has: __CheckAccessToModule(userData?.user, ACTION, moduleId),
			redirectTo: '/',
		}
	}



	return (
		<Route
			{...rest}
			path={path}
			render={(props) =>
				status === 'loading' ? (
					<Loader debug={'route access module'} />
				) : !hasAccess().has ? (
					<Redirect to={hasAccess().redirectTo} />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

export default RouteAccessModule
