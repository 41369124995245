import React from "react";
import { mergeEntityData } from "../../utils/mergeEntityData";
import AtomicBlockShell from "../AtomicBlockShell";
import { SimpleTable } from "oolib";

export const RTESimpleTable = (props) => {
  const {
    // the 3 below recieved from renderAtomicBlock (blockProps)
    editorState,
    handleChange,
    readOnlyProp,
    setReadOnly,
    // the 3 below recieved from entity.data (isnide createAtomicBlocEntity)
    value,
    //the following 2 is from atomicBlockRenderer
    block,
    entityKey,
    isInRTE,
  } = props;

  return (
    <AtomicBlockShell
      {...{
        setReadOnly,
        readOnlyProp,
        editorState,
        handleChange,
        block
      }}
    >
      <SimpleTable
        value={value}
        onChange={(id, v) => {
          mergeEntityData(
            editorState,
            handleChange,
            entityKey,
            /**
             * the minute a value is set, we want to remove the files, so that on page refresh
             * the ImageInput doesnt try to re-upload the files again.
             */
            { value: v }
          );
        }}
        readOnly={readOnlyProp}
        />
    </AtomicBlockShell>
  );
};
