import { $getSelection } from "lexical";
import { $isHeadingNode } from "@lexical/rich-text";

export const checkIsActive = ({ tag, editor }) => {
  let toReturn = false;
  // const editorState = editor.getEditorState();
  // editorState.read(() => {
  //   const selection = $getSelection();
  //   const nodesInSel = selection?.getNodes();
  //   if (
  //     nodesInSel &&
  //     nodesInSel.every((node) => {
  //       /**
  //        * .getNodes() for some reason always returns the final node in the tree. i.e the text node.
  //        * Hence we need to parent to find out if its a Heading Node
  //        */
  //       let parentNode = node.getParent();
  //       // console.log({node, parentNode})
  //       // console.log({isHeadingNode: $isHeadingNode(parentNode)})
  //       return $isHeadingNode(parentNode) && parentNode.__tag === tag;
  //     })
  //   ) {
  //     toReturn = true;
  //   }
  // });
  return toReturn;
};
