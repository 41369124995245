import axios from "axios"




export const validationPlugin = async ({value, passValidationErrorToFormValidation, content}) => {
    try{
        
        let urlWOHttps = value.replace('https://', '');
        const configs = {
            findQuery: {
                'main.link.url' : { $regex: urlWOHttps }, //has a newsreport link that matches the link being shared in THIS document
                kp_published_status: 'published', //should be published
                _id: { $nin: [content._id] } //shouldnt be THIS doc
            },
            contentTypes: ['newsReports']
        }
        const res = await axios.get(`/api/discovery/getData`, {
            params: { configs: JSON.stringify(configs) }
        })


        let foundDuplicates = res.data?.data || [];
        /** below two lines is to ignore this 'no duplicate' rule between clones of the same document. */
        let ogDocId = content.kp_pre_mod_doc || content.kp_og_published_doc //if this exists, it suggests this doc is either an under mod clone or an edit pbl clone
        if(ogDocId) foundDuplicates = foundDuplicates.filter(d => d._id !== ogDocId)    
        
        
        if(foundDuplicates?.length > 0){
            let errorObj = {
                type: 'error', 
                msg: 'This news report has been published before.', 
                link: { 
                    text: 'View Duplicate', 
                    to: `${process.env.NODE_ENV === 'production'
                        ? `${window.location.protocol}//${window.location.hostname}`
                        : 'http://localhost:3000'}/published-page/newsReports?id=${res.data.data[0]._id}`
                }
            }
            if(passValidationErrorToFormValidation) passValidationErrorToFormValidation(errorObj.type)
            return errorObj
        }
        //else
        return undefined
        // return res;
    }catch(err){
        console.log({err})
    }
}