import { colors, mediaQuery, transition } from "oolib";
import styled, { css } from "styled-components";

export const StyledToolbar = styled.div`
  background-color: ${({ invert }) =>
    invert ? colors.greyColor100 : colors.greyColor5};
  border-radius: 0.3rem;
  /* display: flex;
  align-items: center; */
  width: 100vw;
  z-index: 120;
  display: ${({ showToolbar }) => (showToolbar ? "flex" : "none")};
  position: fixed;
  bottom: 0;
  left: 0;
  ${mediaQuery("sm")} {
    position: sticky;
    width: 100%;
  }
`;