import moment from "moment";
import { Divider, EmptyStates, SANS_2 } from "oolib";
import React from "react";
import ContentSection from "../ContentSection";
import { NotificationContentStyled } from "./styled";

const ContentSectionTitles = {
  today: "Today",
  yesterday: "Yesterday",
  older: "Older",
};

export default function NotificationContent({ data, onClick }) {
  const segregated = {
    today: [],
    yesterday: [],
    older: [],
  };

  for (let i of data) {
    const currentDate = moment(new Date().toDateString());
    const dateCreated =  i.dateCreated || i.kp_date //kp_date = deprecated (backward compat)
    const nDate = moment(new Date(dateCreated).toDateString());

    const diff = currentDate.diff(nDate, "days");

    const key = diff === 0 ? "today" : diff === 1 ? "yesterday" : "older";

    segregated[key].unshift(i);
  }

  return data.length === 0 ? (
    <div
      style={{
        textAlign: "center",
        marginTop: "2rem",
        padding: '0 2rem'
      }}
    >    
       <EmptyStates  preset={"notificationEmpty"}/>
    </div>
  ) : (
    <NotificationContentStyled>
      {Object.keys(segregated)
        .filter((d) => segregated[d].length > 0)
        .map((d, index) => {
          const notifications = segregated[d];
          return notifications.length > 0 ? (
            index === 0 ? (    
              <ContentSection
                title={ContentSectionTitles[d]}
                onClick={onClick}
                items={notifications}
              />
            ) : (
              <>
                <div style={{ padding: "0 1rem" }}>
                  {" "}
                  <Divider />{" "}
                </div>
                <ContentSection
                  title={ContentSectionTitles[d]}
                  onClick={onClick}
                  items={notifications}
                />
              </>
            )
          ) : null;
        })}
    </NotificationContentStyled>
  );
}
