export const debugTpl = {
    "_id": "63367be31ce746000a510920",
    "status": "published",
    "version": 6,
    "category": "worksheets",
    "kp_settings": [],
    "kp_content_type": "worksheets_lessons_63355c321482c5000a0c8d22",
    "kp_templates": {
      "data": [
        {
          "comp": "RadioList",
          "valuePath": "radioTest",
          "props": {
            "id": "radioTest",
            "listType": "vertical",
            "label": "3. टेक्नोलॉजी को स्कूल प्रबंधन के लिए अपनाने से क्या लाभ हो सकता है?",
            "commonIsCorrectDesc": "Some explanation text",
            "options": [
              {
                "value": "समयकीबचत",
                "display": "समय की बचत ",
                "isCorrect": true,
                "isCorrectDesc": 'Some explanation text'
              },
              {
                "value": "जानकारीकोसभीकेसाथसाझाकरनेमेंसहजता",
                "display": "जानकारी को सभी के साथ साझा करने में सहजता "
              },
              {
                "value": "दस्तावेज़ोंकारिकॉर्डरखनेमेंआसानी",
                "display": "दस्तावेज़ों का रिकॉर्ड रखने में आसानी  ",
              },
              {
                "value": "check",
                "display": "Hello world",
              }
            ]
          },
          "isRequired": true
        },
        {
          "comp": "CheckboxList",
          "valuePath": "schoolChange",
          "props": {
            "id": "schoolChange",
            "listType": "vertical",
            "label": "3. टेक्नोलॉजी को स्कूल प्रबंधन के लिए अपनाने से क्या लाभ हो सकता है?",
            "commonIsCorrectDesc": "जानने के लिए हमेशा उत्सुक होना और खोज करना, समस्या समाधान कर्ता के गुण हैं। ये अंदरूनी गुण हैं और समस्या के समाधान के लिए जुझारूपन पैदा करते हैं। ये गुण और जुझारूपन होने से संसाधन और समर्थन भी जुटाए जा सकते हैं। ",
            "options": [
              {
                "value": "समयकीबचत",
                "display": "समय की बचत ",
                "isCorrect": true
              },
              {
                "value": "जानकारीकोसभीकेसाथसाझाकरनेमेंसहजता",
                "display": "जानकारी को सभी के साथ साझा करने में सहजता ",
                "isCorrect": true
              },
              {
                "value": "दस्तावेज़ोंकारिकॉर्डरखनेमेंआसानी",
                "display": "दस्तावेज़ों का रिकॉर्ड रखने में आसानी  ",
              },
              {
                "value": "check",
                "display": "Hello world",
              }
            ]
          },
          "isRequired": true
        },
        {
          "comp": "CheckboxList",
          "valuePath": "schoolChange2",
          "props": {
            "id": "schoolChange2",
            "listType": "vertical",
            "label": "3. टेक्नोलॉजी को स्कूल प्रबंधन के लिए अपनाने से क्या लाभ हो सकता है?",
            "commonIsCorrectDesc": "जानने के लिए हमेशा उत्सुक होना और खोज करना, समस्या समाधान कर्ता के गुण हैं। ये अंदरूनी गुण हैं और समस्या के समाधान के लिए जुझारूपन पैदा करते हैं। ये गुण और जुझारूपन होने से संसाधन और समर्थन भी जुटाए जा सकते हैं। ",
            "options": [
              {
                "value": "समयकीबचत",
                "display": "समय की बचत ",
                "isCorrect": true
              },
              {
                "value": "जानकारीकोसभीकेसाथसाझाकरनेमेंसहजता",
                "display": "जानकारी को सभी के साथ साझा करने में सहजता ",
                "isCorrect": true
              },
              {
                "value": "दस्तावेज़ोंकारिकॉर्डरखनेमेंआसानी",
                "display": "दस्तावेज़ों का रिकॉर्ड रखने में आसानी  ",
              },
              {
                "value": "check",
                "display": "Hello world",
              }
            ]
          },
          "isRequired": true
        }
      ]
    }
  }