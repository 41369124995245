import React, { useRef, useState } from "react";
import { fixedGetDataConfig } from "../../../pageTpls/PublishedListing/config/fixedGetDataConfig";
import { __GetAllContentTypeConfig } from "../../../utils/getters/gettersV2";
import { useGetDataLazy } from "../../../utils/react-query-hooks/getData";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";
import PageCover from "../../organisms/PageCover";
import { useGetCountForQuantField } from "../InsightMatrix/queries";
import { getValidFieldsForQuantViz } from "../InsightMatrix/utils/getValidFieldsForQuantViz";
import { CompRendererDataPivot } from "./CompRendererDataPivot";

export const DataFetcherDataPivot = ({contentType, coverConfig}) => {
  
  const allTpls = useRef(__GetAllContentTypeConfig())

  const getPivotableBlocks = (contentType) => {
    const allBlocks = extractAllBlocksFromTpl({ tpl: allTpls.current.find(d => d.kp_content_type === contentType)});
    return allBlocks.filter((bl) => bl.comp !== "StaticRichText" && bl.inputOnly !== true);
  };

  const [activeBlock, setActiveBlock] = useState(contentType ? getPivotableBlocks(contentType)[0] : undefined);

  const {data: countData, status: countStatus, error: countError } = useGetCountForQuantField({
    vizCardId: activeBlock?.valuePath,
    contentType,
    tpl: allTpls.current.find(d => d.kp_content_type === contentType),
    blockValuePath: activeBlock?.valuePath,
    activeBlock,
    queryOptions: {
      enabled: !!activeBlock === true && getValidFieldsForQuantViz([activeBlock]).some(d => d.valuePath === activeBlock.valuePath),
    }
  })

  const { data, status, error , fetchNextPage, hasNextPage, isFetchingNextPage } = useGetDataLazy({
    
    contentTypes: [contentType],
    ...fixedGetDataConfig,
    projection: {
      [activeBlock?.valuePath]: 1,
      'meta.kp_contributed_by': 1,
      'kp_date_published': 1
    },
    queryOptions: {
      enabled: !!activeBlock === true,
    },
  });

  return <div>
     {coverConfig && (
        <PageCover
          coverHeadline={coverConfig.title}
          coverSubheadline={coverConfig.desc}
          btn={coverConfig.btn}
        />
      )}
      
      <CompRendererDataPivot
      pivotableBlocks={contentType && getPivotableBlocks(contentType)}
      setActiveBlock={setActiveBlock}
      activeBlock={activeBlock}
      data={data}
      dataFetchStatus={status}
      dataFetchError={error}
      dataVizWidget={countData && { 
        data:countData?.countsArray,
        comp:'PercentBarChart',
        colorIdx:0,
        valuePath:"count",
        labelPath:"display"
      }}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      />
    </div>
};
