import React from 'react'
import { useGetQueryData } from '../../../utils/react-query-hooks/general'
import FooterV2 from './FooterV2'



export const Footer = () => {
    const {_Nav, deployment} = useGetQueryData('platformConfigs')

    return <FooterV2 config={{_Nav, deployment}}/>

}