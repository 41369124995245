
/**
 * Scrolls the specified module into view.
 *
 * @param id @param Ref- The ID or the ref of the module to scroll into view.
 */
export const scrollModuleInView = (element) => {

  let elementToScroll;

  if (typeof element === 'string') {
    // If the argument is a string, assume it's an element ID
    elementToScroll = document.getElementById(element);
  } else if (element && element.current) {
    // If it's an object with a "current" property, it's a ref
    elementToScroll = element.current;
  }

  if (elementToScroll) {
    const scrollOffset = elementToScroll.getBoundingClientRect().top - (window.innerHeight / 4);

    window.scrollTo({
      top: window.pageYOffset + scrollOffset,
      behavior: 'smooth',
    });
  }
  }