import {
  SANS_3,
  TextInput,
  ellipsis,
  formatTextToCamelCase
} from "oolib";
import React, { useRef } from "react";
import styled from "styled-components";
import { genCleanCamelCaseId } from "../../../../utils/general";


const STYLED_SANS_3 = styled(SANS_3)`
  ${ellipsis}
`;

export const TextToIDInput = ({
  id,
  value,
  onChange,
  prefix,
  lockIDDisplay, // this is useful in some cases like in InitTplSettingsModal, wherein, in 'edit' mode, we do not want the ID to change even if the text in the textinput is changed
  ...restOfProps
}) => {

  const initValue = useRef(value);

  return (
    <div id={id}>
      <TextInput
        id={`${id}_textInput`}
        {...restOfProps}
        value={value}
        onChange={(k, v) => onChange(id, v)}
      />
      {value && (
        <STYLED_SANS_3 style={{ paddingTop: "0.5rem" }} italic>
          {`${prefix || "ID: "}`}
          <span>'{genCleanCamelCaseId(lockIDDisplay ? initValue.current : value)}'</span>
        </STYLED_SANS_3>
      )}
    </div>
  );
};
