import { useEffect, useState } from "react";

import { useParseTplToTableConfig } from "../../../components/cards/TableElem/CONFIG_PARSER/useParseTplToTableConfig";

/**
 * returns : { sort: .... }
 */
const extractInitBackendSortFromTableConfig = (tableConfig) => {
  return tableConfig.cellData.find(
    (d) => d.backendSortConfig?.defaultSort === true
  )?.backendSortConfig;
};

export const useExtractInitBackendSortFromTableConfig = ({
  tableConfig: _tableConfig,
  enabled = true,
  fallbackIfDisabled,
}) => {
  const [activeSort, setActiveSort] = useState(
    enabled
      ? extractInitBackendSortFromTableConfig(_tableConfig)
      : fallbackIfDisabled
  );

  /**
   * this parsing is important because,
   * cellData configs are 'dynamically generated' (and by association, so are backendSortConfig),
   * if a contentType property is passed to the tableConfigProp
   *
   * Hence we need for this dynamic generation to happen, before we can extract the backendSortConfig
   */
  const { tableConfig, parseStatus } = useParseTplToTableConfig({
    tableConfigProp: _tableConfig || {}, //empty object is needed just so that this hook doesnt throw error in case no config
    isBackendSort: true,
    enabled,
  });

  useEffect(() => {
    if (enabled && parseStatus === "completed") {
      setActiveSort(extractInitBackendSortFromTableConfig(tableConfig));
    }
  }, [parseStatus]);

  return [activeSort, setActiveSort];
};
