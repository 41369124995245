import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
  MenuOption,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { useCallback, useMemo, useState, useEffect } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { $createMentionNode } from '../../nodes/MentionNode';
import { AtSignMentionsRegex, AtSignMentionsRegexAliasRegex } from './utils/regex';
import { useMentionLookupService } from './utils/useMentionLookupService';
import { UI_PARAGRAPH } from 'oolib';
import { StyledLexicalTypeaheadMenuPlugin, StyledTypeahead, StyledTypeaheadWrapper, StyledUserListItem } from './styled';

// *** Regular expressions and constants for matching mentions:
// PUNCTUATION, NAME, DocumentMentionsRegex, TRIGGERS, VALID_CHARS, VALID_JOINS,
// LENGTH_LIMIT, ALIAS_LENGTH_LIMIT, AtSignMentionsRegex, AtSignMentionsRegexAliasRegex
//
// *** Caching mechanism for mention results:
// mentionsCache (Map object)
//
// *** Custom hook for fetching mention suggestions:
// useMentionLookupService(mentionString)
// 
// *** Helper functions for checking and matching mentions:
// checkForAtSignMentions(text, minMatchLength)
// getPossibleQueryMatch(text)
//
// *** Custom class for mention typeahead options:
// MentionTypeaheadOption class
//
// *** Component for rendering individual mention suggestions:
// MentionsTypeaheadMenuItem component
//
// *** Main MentionsPlugin component:
// MentionsPlugin component
//
// *** LexicalTypeaheadMenuPlugin with custom rendering:
// Used within the MentionsPlugin component
// Custom menuRenderFn property
//
// *** Additional important elements:
//
// *** Dummy lookup service (for demonstration purposes):
// dummyLookupService object
//
// *** Utility hooks for fetching user data:
// useGetAllUsersLazy (imported from external file)
//
// *** Callback functions within MentionsPlugin:
// checkForSlashTriggerMatch
// onSelectOption
// checkForMentionMatch

// At most, 5 suggestions are shown in the popup.

const SUGGESTION_LIST_LENGTH_LIMIT = 5;

function checkForAtSignMentions(text, minMatchLength) {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text) {
  return checkForAtSignMentions(text, 1);
}

class MentionTypeaheadOption extends MenuOption {
  constructor(name, picture) {
    super(name);
    this.name = name;
    this.picture = picture;
  }
}

function MentionsTypeaheadMenuItem({
    index,
    isSelected,
    onClick,
    onMouseEnter,
    option,
  }) {
    let className = 'item';
    if (isSelected) {
      className += ' selected';
    }
    return (
      <StyledUserListItem
        key={option.key}
        tabIndex={-1}
        className={className}
        ref={option.setRefElement}
        role="option"
        aria-selected={isSelected}
        id={'typeahead-item-' + index}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        style={{ cursor: "pointer" }}
        >
        {/* {option.picture} */}
        <UI_PARAGRAPH className="text">{option.name}</UI_PARAGRAPH>
      </StyledUserListItem>
    );
  }
  
  export default function MentionsPlugin() {
    const [editor] = useLexicalComposerContext();
    const [queryString, setQueryString] = useState(null);
    const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
    const [anchorElementRef, setAnchorElementRef] = useState(null); 
    const results = useMentionLookupService(queryString);

    const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
      minLength: 0,
    });
  
    const options = useMemo(
      () =>
        results
          .map(
            (result) => {
              return (new MentionTypeaheadOption(result, <i className="icon user" />))
            })
          .slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
      [results]
    );
  
    const onSelectOption = useCallback(
      (selectedOption, nodeToReplace, closeMenu) => {
        // console.log('onSelectOption called', selectedOption);
        editor.update(() => {
          const mentionNode = $createMentionNode(selectedOption);
          if (nodeToReplace) {
            nodeToReplace.replace(mentionNode);
          }
          mentionNode.select();
          closeMenu();
        });
      },
      [editor]
    );
  
    const checkForMentionMatch = useCallback(
      (text) => {
        const slashMatch = checkForSlashTriggerMatch(text, editor);
        if (slashMatch !== null) {
          return null;
        }
        return getPossibleQueryMatch(text);
      },
      [checkForSlashTriggerMatch, editor]
    );
  
    const updateAnchorPosition = useCallback(() => {
      if (anchorElementRef && anchorElementRef.current) {
        const rect = anchorElementRef.current.getBoundingClientRect();
        setAnchorPosition({
          top: rect.bottom,
          left: rect.left,
        });
      }
    }, [anchorElementRef]);
  
    useEffect(() => {
      updateAnchorPosition();
    }, [anchorElementRef, updateAnchorPosition]);
  
    useEffect(() => {
      const handleScroll = () => {
        updateAnchorPosition();
      };
  
      window.addEventListener('scroll', handleScroll, true);
  
      return () => {
        window.removeEventListener('scroll', handleScroll, true);
      };
    }, [updateAnchorPosition]);
  
    useEffect(() => {
      if (anchorElementRef && anchorElementRef.current) {
        const updatePosition = () => {
          const rect = anchorElementRef.current.getBoundingClientRect();
          if (rect.top !== 0 || rect.left !== 0) {
            setAnchorPosition({
              top: rect.bottom,
              left: rect.left,
            });
          }
        };
        
        updatePosition();
        
        const resizeObserver = new ResizeObserver(updatePosition);
        resizeObserver.observe(anchorElementRef.current);
        
        window.addEventListener('scroll', updatePosition, true);
        
        return () => {
          resizeObserver.disconnect();
          window.removeEventListener('scroll', updatePosition, true);
        };
      }
    }, [anchorElementRef]);

    return (
      <StyledLexicalTypeaheadMenuPlugin
        onQueryChange={setQueryString}
        onSelectOption={onSelectOption}
        triggerFn={checkForMentionMatch}
        options={options}
        menuRenderFn={(
          anchorElementRefFromMenu, 
          { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
        ) => {
          if (anchorElementRefFromMenu) {
            setAnchorElementRef(anchorElementRefFromMenu);
          }

          return results.length
            ? ReactDOM.createPortal(
                <StyledTypeaheadWrapper
                  style={{
                    left: anchorPosition.left,
                    top: anchorPosition.top,
                  }}
                >
                  <StyledTypeahead className="typeahead-popover mentions-menu">
                    <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>

                      {options.map((option, i) => {
                        return(<MentionsTypeaheadMenuItem
                          index={i}
                          isSelected={selectedIndex === i}
                          onClick={() => {
                            setHighlightedIndex(i);
                            selectOptionAndCleanUp(option.name);
                          }}
                          onMouseEnter={() => {
                            setHighlightedIndex(i);
                          }}
                          key={option.id}
                          option={option.name}
                        />)
                      })}

                    </ul>
                  </StyledTypeahead>
                </StyledTypeaheadWrapper>,
                document.body
              )
            : null;
        }}
      />
    );
  }
  