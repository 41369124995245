
import React, { useEffect, useMemo, useState } from "react";
import CompRenderer from "../CompRenderer";
import { Container, EmptyStates, Loader, SANS_3, WrapperCardGrid } from "oolib";
import { useElemInView } from "../../../../utils/customHooks/useElemInView";
import CardBuilder from "../CardBuilder";
import { getTenant } from "../../../../TENANT_CONFIGS";
import { AnimatePresence } from "framer-motion";
import CardExpandComps from "../CardExpandComps";
import { useGetDataLazy } from "../../../../utils/react-query-hooks/getData";
import { TotalCount } from "../../../../pageTpls/ListingPage/comps/TotalCount";
import { __CheckAccessViaConfig } from "../../../../utils/accessControl";
import { useGetQueryData } from "../../../../utils/react-query-hooks/general";
import {dashboardConfig} from './configs'

const filterByAccess = (array, user) => {
  return array.filter((d) =>
    __CheckAccessViaConfig({
      user,
      action: d.action,
      resourceType: d.resourceType,
      checkAccessTo: "contentTypeTemp",
    })
  );
};

const buildQuery = ({ keyword, sort, config }) => {
  if (!config) return;

  const { fields, highlights,commonSearchFields } = config;

  return {
    query: {
      bool: {
        must: [
          {
            multi_match: {
              fields: [...commonSearchFields, ...fields],
              query: keyword,
              fuzziness: "AUTO",
              prefix_length: 3,
              analyzer:"standard"
            },
          },
        ],
        filter: [
          {
            term: {
              kp_published_status: "published",
            },
          },
        ],
      },
    },
    highlight: highlights
      ? {
          pre_tags: "<b>",
          post_tags: "</b>",
          fragment_size: "200",
          number_of_fragments: "100",
          fields: {
            ...highlights,
          },
        }
      : {},
    sort: sort
      ? [{ [sort.valuePath]: { order: sort.value === -1 ? "desc" : "asc" } }]
      : {},
    size: 10,
  };
};

export const SearchResults = ({
  searchTerm,
  activeFilters,
  setTotalResult,
  searchbarRef,
}) => {
  const { user } = useGetQueryData("userData") || {};

  const tenantDashboardConfig = dashboardConfig[getTenant()];



  const [contentTypes, setContentTypes] = useState([]);
  const [expandCard, setExpandCard] = useState("");

  useEffect(() => {

    if(tenantDashboardConfig){
    setContentTypes(filterByAccess(tenantDashboardConfig?.contentTypes, user));

    }
  }, []);

  

  const fields =
    useMemo(
      () =>
        contentTypes?.reduce(
          (a, c) => [...a, ...c.searchAndHighlightFields],
          []
        ),
      [contentTypes]
    ) || [];

  // return null;

  const getData = useGetDataLazy({
    activeFilters,
    contentTypes: contentTypes?.map((d) => d?.resourceType),
    searchTerm,
    ksConfig: buildQuery({
      keyword: searchTerm,
      index: contentTypes?.map((d) => d?.resourceType),
      config: {
        fields: fields,
        highlights: fields?.reduce((a, c) => ({ ...a, [c]: {} }), {}),
        commonSearchFields:tenantDashboardConfig?.commonSearchFields||[]
      },
      
    }),
    queryOptions: { enabled: searchTerm && tenantDashboardConfig ? true : false },
    /**
     * collectionId is only relevant in order to give a unique query key (react query)
     * to the related content that might be fetched against a given collection page
     */
  });

  const { observerRef } = useElemInView({
    callback: getData.fetchNextPage,
  });

  const onCardClick = (d) => {
    if (expandCard && d._id === expandCard._id) {
      setExpandCard(false);
      return;
    }

    const cloneD = JSON.parse(JSON.stringify(d));

    cloneD.meta.search_content_type = cloneD.meta.kp_content_type + "KS";

    setExpandCard(cloneD);
  };

  if(!tenantDashboardConfig) return <SANS_3>No configs defined</SANS_3>


  return (
    <div>
      {!searchTerm && (
        <SANS_3 style={{ padding: "2rem" }}>
          {" "}
          Start typing to see results{" "}
        </SANS_3>
      )}
      <CompRenderer
        useQueryResponse={getData}
        returnPlainResponse={true}
        render={(data) => (
          <div style={{ display: "flex" }}>
            {setTotalResult(data.data?.pages[0].count)}
            <div style={{ flexGrow: "1" }}>
              <Container>
                {searchTerm ? (
                  <TotalCount
                    totalCount={data.data?.pages[0].count}               
                  />
                ) : null}
                {                 
                    searchTerm && data.data?.pages[0].count===0?
                    <EmptyStates preset={"searchDashBoardListing"} />:
                
                <WrapperCardGrid style={{ padding: "2rem 0" }}>
                  {data.data?.pages?.map((page, I) => (
                    // <Link >
                    <CardBuilder
                      searchCardConfig={tenantDashboardConfig.cardConfig}
                      data={page.data}
                      onCardClick={onCardClick}
                      observerRef={ getData.hasNextPage? observerRef:null}
                      index={data.data.pages.length === I + 1}
                    />
                  ))}
                </WrapperCardGrid>
        }

                {getData.isFetchingNextPage && (
                  <div style={{ padding: "2rem", textAlign: "center" }}>
                    <Loader />
                  </div>
                )}
              </Container>
            </div>

            <AnimatePresence>
              {expandCard && (
                <CardExpandComps
                  expandCard={expandCard}
                  handleClose={() => setExpandCard(false)}
                  searchTerm={searchTerm}
                  stickBelowElementRef={searchbarRef}
                />
              )}
            </AnimatePresence>
          </div>
        )}
      />
    </div>
  );
};
