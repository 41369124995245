// import { colors} from "oolib";
import React from "react";
import { DraggableBlock } from "../../../../../../../../PlatformBuilder/comps/DraggableBlock";
import { useTCIContext } from "../../../../../../../context";

import { getVal } from 'oolib';
import { StyledSectionWrapper } from "../../../../../../../comps/SidebarContents/styled";
// const {white, greyColor100} = colors

export const SidebarSectionShell = ({ 
    children, 
    title, 
    subtitle, 
    sectionPath 
}) => {

    // const sectionTitleRef = useRef({});
    // const sectionSubtitleRef = useRef({});
  
    const {tplConfig} = useTCIContext()

    const {
        // sectionTitleEdit,
        // setSectionTitleEdit,
        setShowConfirmDeleteBlockOrSectionModal,
        HANDLE_DUPLICATE_SECTION,
        setSectionToConfigure,
    } = useTCIContext()

    // const editableStyle = {
    //   backgroundColor: greyColor100,
    //   color: white,
    // };

    // const thisSectionTitleIsUnderEdit = sectionTitleEdit === sectionPath;
const handleEditSection = () => {
  let elem = document.getElementById(getVal(tplConfig,`${sectionPath}.sectionId`));
  elem.style.zIndex = 1000;
  setSectionToConfigure({
    sectionPath
  })
}
    const sectionActionsConfig = [
              {
                display: "Delete",
                onClick: () =>
                  setShowConfirmDeleteBlockOrSectionModal({
                    blockPath: sectionPath,
                    block_section: "section",
                  }),
              },
              {
                display: "Duplicate",
                onClick: () => {
                  
                  HANDLE_DUPLICATE_SECTION({sectionPath});
                },
              },
              {
                display: 'Edit Section',
                onClick: handleEditSection
              }
            ]

    return (
      //optional. else default wrapper will be rendered
      <div>
      <DraggableBlock 
          title={title}
          subtitle={subtitle}
          titleWeight={'semibold'}
          onClick={handleEditSection}
          actionMenu={{
            align: 'right',
            actions: sectionActionsConfig
          }}
          icon={'SelectionAll'}
          iconProps={{size: 20, weight: 'regular'}}
          // disableDrag
          />
          <StyledSectionWrapper>
            {children}
          </StyledSectionWrapper>
  </div>
      //////// <div
      ////////   style={{
      ////////     position: "relative",
      ////////     border: `1px solid ${greyColor10}`,
      ////////     padding: "0.5rem",
      ////////   }}
      //////// >
      //   <div style={{ position: "absolute", top: 0, right: 0 }}>
      //     <ActionMenu
      ///////       icon="DotsThreeVertical"
      ///////       align="right"
      ///////       actions={[
      ///////         {
      // ////          display: "Delete",
      ///////           onClick: () =>
      // ////            setShowConfirmDeleteBlockOrSectionModal({
      // ////              blockPath: sectionPath,
      // ////              block_section: "section",
      // ////            }),
      ///////         },
      // ////        {
      //  ///         display: "Duplicate",
      //  ///         onClick: () => {
                  
      // ////            HANDLE_DUPLICATE_SECTION({sectionPath});
      // ////          },
      // ////        },
      //        {
      //           display: 'Edit Title',
      //           onClick: () => {
      //             setSectionToConfigure({
      //               title: sectionTitleRef.current[sectionPath]?.innerText || '',
      //               subtitle: sectionSubtitleRef.current[sectionPath]?.innerText || '',
      //               wrapper: getVal(tplConfig, `${sectionPath}.wrapper`),
      //               sectionPath
      //             })
      //           }
      //         }
      //       ]}
      //     />
      //   </div>
      //  //// <div
      //  ////   style={{ paddingBottom: "0.5rem" }}
      //  ////   onDoubleClick={(e) => {
      //  ////     setSectionTitleEdit(sectionPath);
      //  ////   }}
      //  ////   onBlur={() => thisSectionTitleIsUnderEdit && 
      //  ////     handleApplySectionTitleEdits({
      //  ////       title: sectionTitleRef.current[sectionPath]?.innerText,
      //  ////       subtitle: sectionSubtitleRef.current[sectionPath]?.innerText,
      //  ////       sectionPath,
      //  ////       callback: () => setSectionTitleEdit(undefined)
      //  ////     }) }
      //  //// >
      // /////    {title && (
      // /////      <div
      //  ////       style={thisSectionTitleIsUnderEdit ? editableStyle : undefined}
      // /////        contentEditable={thisSectionTitleIsUnderEdit}
      // /////        ref={el => sectionTitleRef.current[sectionPath] = el}
      // /////      >
      ////////         <SANS_2>{title}</SANS_2>
      //  ////     </div>
      // /////    )}
      //  ////   {subtitle && (
      ////////       <div
      // /////        style={thisSectionTitleIsUnderEdit ? editableStyle : undefined}
      // /////        contentEditable={thisSectionTitleIsUnderEdit}
      // /////        ref={el => sectionSubtitleRef.current[sectionPath] = el}
      // /////      >
      /////////        <SANS_0>{subtitle}</SANS_0>
      ////////       </div>
      ///////////     )}
      ///////   </div>
      /////////   <div className="sidebarBlocksWrapper">{children}</div>
      // </div>
    );
  };