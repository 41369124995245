import React, { Fragment, useEffect, useRef, useState } from "react";

import KPTooltip from './comps/KPTooltip';

import {
	applyBlockFormatting, createAtomicBlockEntity, handleUploadChange
} from '../../../../../utils/richInputUtils';
import { richToolbar } from './config';

import { ButtonPrimary, SANS_3, UploadButton, useScreenWidth } from 'oolib';


const KPRichToolbar = ({
	editor,
	onEditorChange,
	editorFocussed,
	handleShowCardLinkInput,
	superRichFormattable,
	formattingOptions,
	hintsExists,
	
	setTextLinkState,
	textLinkState,
	setLinkInputOnConfirmHandler,
}) => {
	//col settings must be the bootstrap codes

	const screenWidth = useScreenWidth();

	let toolbarRef = useRef(null)
	let advBtnRef = useRef(null)

	const [spaceForSimpleBlocksInToolbar, setSpaceForSimpleBlocksInToolbar] =
		useState(null)

	const [showAdvancedBlocksList, setShowAdvancedBlocksList] = useState(false)

	useEffect(() => {
		if (setShowAdvancedBlocksList && editorFocussed === false) {
			setShowAdvancedBlocksList(false)
		}
	}, [editorFocussed])

	useEffect(() => {
		if (toolbarRef.current && editorFocussed) {
			let toolbarWidth = toolbarRef.current.getBoundingClientRect().width
			let hintsBtnWidth = hintsExists ? 120 : 0 //make that 120 a dynamic variable passed from sass and used to fix the width of the hints btn as well.
			let advancedBlockWidth = advBtnRef.current.getBoundingClientRect().width //need to figure proper ref usage and do this properly

			let availSpace = toolbarWidth - hintsBtnWidth - advancedBlockWidth
			// let surplusSpace = availSpace - (totSimpleBlocks*50)
			// if(surplusSpace > 160 && advBtn === 'ButtonPrimary') setAdvBtn('ButtonPrimary')
			setSpaceForSimpleBlocksInToolbar(availSpace)
		}
	}, [editorFocussed, screenWidth])

	const { editorState } = editor

	const BtnComps = {
		ButtonPrimary,
		UploadButton
	}

	const onClickHandlers = {
		applyBlockFormatting: (blockStyle) => {
			applyBlockFormatting(blockStyle, editorState, onEditorChange)
		},
		createAtomicBlockEntity: (compToRender) => {
			createAtomicBlockEntity(
				{ editorState, onEditorChange },
				compToRender,
				'IMMUTABLE',
				{ value: null },
			)
		},
		handleShowCardLinkInput: handleShowCardLinkInput,
		handleShowLinkInput: () => {
			setTextLinkState({ ...textLinkState, showLinkInput: true })
			setLinkInputOnConfirmHandler('handleInsertCTA')
		},
	}

	const onChangeHandlers = {
		handleUploadChange: (e, atomicBlockComp, folderName, allowedFormats) => {
			handleUploadChange(e, atomicBlockComp, folderName, allowedFormats, {
				...editor,
				onEditorChange,
			})
		},
	}

	const [scrolledToolbar, setScrolledToolbar] = useState(0)

	const genAdvBtn = () => {
		return (
			<div
				ref={advBtnRef}
				style={{
					flexShrink: 0,
					display:
						superRichFormattable || brokeAtIndex.length > 0 ? 'block' : 'none',
				}}>
				<ButtonPrimary
					onClick={() => setShowAdvancedBlocksList(!showAdvancedBlocksList)}
					className={`kp-rich-format-toolbar__tool ${
						showAdvancedBlocksList ? 'selected' : ''
					}`}
					icon={'DotsThree'}
				/>
			</div>
		)
	}

	const [showTooltip, setShowTooltip] = useState({ show: false, idx: -1 })

	const brokeAtIndex = []

	const conditionsToRenderSimpleFormatOption = (d, i) => {
		let toReturn
		switch (true) {
			case d.advancedBlock:
				toReturn = false
				break
			case formattingOptions === 'textFormatting' && d.textFormatBlock !== true:
				toReturn = false
				break
			case formattingOptions === 'textAndImageFormatting' &&
				d.textFormatBlock !== true &&
				d.imageBlock !== true:
				toReturn = false
				break
			case spaceForSimpleBlocksInToolbar < 50 * (i + 1):
				toReturn = false
				break
			default:
				toReturn = true
		}
		return toReturn
	}


	return (
		<Fragment>
			<div className='kp-rich-format-toolbar-wrapper' style={{ display: editorFocussed ? 'block' : 'none' }}>
				<div
					className='kp-rich-format-toolbar'
					style={{ display: editorFocussed ? 'flex' : 'none' }}
					onScroll={() => setScrolledToolbar(scrolledToolbar + 1)}
					ref={toolbarRef}>
					<div
						className='kp-rich-format-toolbar__tools-group'
						style={{
							display: 'flex',
							justifyContent: 'flexStart',
							alignItems: 'center',
						}}>
						{richToolbar.map((d, i) => {
							if (conditionsToRenderSimpleFormatOption(d, i)) {
								let BtnComp = BtnComps[d.btnComp]

								let onClickHandler = onClickHandlers[d.onClickHandler]
								let onChangeHandler = onChangeHandlers[d.onChangeHandler]
								return (
									<div
										key={d.id}
										className='kp-rich-format-toolbar__tool-wrapper'>
										{
											showTooltip.idx === i && d.tooltipText && (
												<KPTooltip
													className='kp-rich-format-toolbar__tool-tooltip'
													text={d.tooltipText}
												/>
											)
										}
										<BtnComp
											className='kp-rich-format-toolbar__tool'
											onClick={() =>
												onClickHandler && onClickHandler(...d.clickHandlerArgs)
											}
											onMouseEnter={() =>
												setShowTooltip({ show: true, idx: i })
											}
											onMouseLeave={() =>
												setShowTooltip({ show: false, idx: -1 })
											}
											onChange={(e) =>
												onChangeHandler &&
												onChangeHandler(...[e, ...d.changeHandlerArgs])
											}
											multiple={d.multiple}
											id={`${editor.editorId}_${d.id}`}
											icon={d.icon}
											{...(d.btnComp === 'UploadButton'? {
												variant:'primary'
											}:{})}
										/>
									</div>
								)
							} else if (
								d.advancedBlock !== true &&
								spaceForSimpleBlocksInToolbar &&
								spaceForSimpleBlocksInToolbar < 50 * (i + 1)
							) {
								// if(advBtn === 'ButtonPrimary') setAdvBtn('ButtonPrimary');
								brokeAtIndex.push(d)
							}
						})}
					</div>

					{genAdvBtn()}
				</div>
				{showAdvancedBlocksList && (
					<div className='kp-advanced-blocks-list'>
						<div className='kp-adv-blocks-list-items-wrapper'>
							{richToolbar.map((d, i) => {
								if (superRichFormattable && d.advancedBlock) {
									let onClickHandler = onClickHandlers[d.onClickHandler]
									return (
										<div
											key={d.id}
											className='kp-adv-blocks-list-item'
											onClick={() => {
												onClickHandler && onClickHandler(...d.clickHandlerArgs)
												setShowAdvancedBlocksList(false)
											}}>
											<SANS_3 semibold>{d.title}</SANS_3>
											<SANS_3 className='EditorToolbar__toolSubtitle'>
												{d.desc}
											</SANS_3>
										</div>
									)
								} else if (
									brokeAtIndex.some((simpleBlock) => simpleBlock.id === d.id)
								) {
									let BtnComp = BtnComps[d.btnComp]
								
									let onClickHandler = onClickHandlers[d.onClickHandler]
									let onChangeHandler = onChangeHandlers[d.onChangeHandler]
									return (
										<div key={d.id} className='kp-simple-blocks-list-item'>
											<BtnComp
												className='kp-simple-blocks-btn'
												onClick={() => {
													onClickHandler &&
														onClickHandler(...d.clickHandlerArgs)
													setShowAdvancedBlocksList(false)
												}}
												onMouseEnter={() =>
													setShowTooltip({ show: true, idx: i })
												}
												onMouseLeave={() =>
													setShowTooltip({ show: false, idx: -1 })
												}
												onChange={(e) => {
													onChangeHandler &&
														onChangeHandler(...[e, ...d.changeHandlerArgs])
													setShowAdvancedBlocksList(false)
												}}
												multiple={d.multiple}
												{...(d.btnComp === 'UploadButton'? {
													variant:'primary'
												}:{})}
												id={`${editor.editorId}_${d.id}`}>
												{d.tooltipText}
											</BtnComp>
										</div>
									)
								}
							})}
						</div>
					</div>
				)}
			</div>
		</Fragment>
	)
}


export default KPRichToolbar;
