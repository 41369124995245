import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const StyledFilterHeading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 1px solid #E9E9E9;
  position: sticky;
  top: 0;
  z-index: 20000;
  background-color: white;
`

export const StyledSideBar = styled(motion.aside)`
  border-right: 1.5px solid #E9E9E9;
  /* min-height: 70svh;
  max-height: 100svh; */
  height: ${({ top }) => `calc(100svh - ${top}px)`};
  width: ${({ showSideBarLeft, sidebarWidth }) => showSideBarLeft ? `${sidebarWidth}px` : '0px'};
  z-index: 9;
  position: sticky;
  top: ${({ top }) => `${top}px`};
  overflow-y: auto;
  overflow-x: hidden;
  
  ${({ isMobile}) => isMobile && css`
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11000;
      height: 100svh;
      background-color: white;
  `}
`