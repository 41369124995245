import handleBlockDisplayConditions from "../../../../../utils/handleBlockDisplayConditions";

export const handleFilterInputDisplayConditions = ({
  activeFilters,
  presetActiveFilters,
  filterConfig,
}) => {
  const allActiveFiltersAsObject = [
    ...activeFilters,
    ...presetActiveFilters,
  ].reduce((a, b) => ({ ...a, [b.filterId]: b }), {});

  return handleBlockDisplayConditions({
    content: allActiveFiltersAsObject,
    block: filterConfig,
  });
};
