import { CardContent, Container, LABEL, ListContent, Wrapper700, WrapperCardGrid } from "oolib";

import React from "react";
const Cards = () => {
  const data = {
    meta: { kp_contributed_by: { name: "rajiv Ranjan Singh" } },
    title:
      "Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply  Renovation and Door to Door Water supply Door to Door Water supply Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply  Renovation and Door to Door Water supply Door to Door Water supply",
    kp_date_published: "2023-01-12T07:10:11.081Z",
    cardLabel: "Pneumonoult ramicroscop icsilicovolcanoconiosis",
    main: {
      images: [
        {
          kind: "storage#object",
          id: "wqn_dev__media/images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg/1673507383933894",
          selfLink:
            "https://www.googleapis.com/storage/v1/b/wqn_dev__media/o/images%2Fmountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          mediaLink:
            "https://storage.googleapis.com/download/storage/v1/b/wqn_dev__media/o/images%2Fmountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg?generation=1673507383933894&alt=media",
          name: "images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          bucket: "wqn_dev__media",
          generation: "1673507383933894",
          metageneration: "1",
          contentType: "image/jpeg",
          storageClass: "STANDARD",
          size: 67736,
          md5Hash: "L7+UQq57JsIfoxIy3vAASA==",
          crc32c: "14w7wA==",
          etag: "CMb3l+m8wfwCEAE=",
          timeCreated: "2023-01-12T07:09:43.936Z",
          updated: "2023-01-12T07:09:43.936Z",
          timeStorageClassUpdated: "2023-01-12T07:09:43.936Z",
          originalFilename: "Mountain.png",
          mediaHost: "gcs",
          publicUrl:
            "https://storage.googleapis.com/wqn_dev__media/images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          format: "jpeg",
          width: 1400,
          height: 700,
          cropX: 0,
          cropY: 0,
        },
      ],
      video: [
        {
          kind: "storage#object",
          id: "wqn_dev__media/video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4/1670394807648130",
          selfLink:
            "https://www.googleapis.com/storage/v1/b/wqn_dev__media/o/video%2F%2Fcda36a40-8157-4450-a239-a0f5c09709c4.mp4",
          mediaLink:
            "https://storage.googleapis.com/download/storage/v1/b/wqn_dev__media/o/video%2F%2Fcda36a40-8157-4450-a239-a0f5c09709c4.mp4?generation=1670394807648130&alt=media",
          name: "video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4",
          bucket: "wqn_dev__media",
          generation: "1670394807648130",
          metageneration: "1",
          contentType: "video/mp4",
          storageClass: "STANDARD",
          size: 16422874,
          md5Hash: "8ivmgxqLXSJCEvaygbagaw==",
          crc32c: "EYqPbg==",
          etag: "CIL/k8nx5vsCEAE=",
          timeCreated: "2022-12-07T06:33:27.649Z",
          updated: "2022-12-07T06:33:27.649Z",
          timeStorageClassUpdated: "2022-12-07T06:33:27.649Z",
          mediaHost: "gcs",
          originalFilename: "Screen sharing - 2022-12-07 10_59_42 AM.mp4",
          format: "mp4",
          publicUrl:
            "https://storage.googleapis.com/wqn_dev__media/video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4",
        },
      ],
    },
  };

  const data1 = {
    meta: { kp_contributed_by: { name: "WhatIfFirstNameIsAVeryLongName R." } },
    kp_date_published: "2023-01-12T07:10:11.081Z",
    title: "Pneumonoult ramicroscop icsilicovolcanoconiosis",
    cardLabel: "Tools And Toolkits",

    main: {
      images: [
        {
          kind: "storage#object",
          id: "wqn_dev__media/images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg/1673507383933894",
          selfLink:
            "https://www.googleapis.com/storage/v1/b/wqn_dev__media/o/images%2Fmountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          mediaLink:
            "https://storage.googleapis.com/download/storage/v1/b/wqn_dev__media/o/images%2Fmountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg?generation=1673507383933894&alt=media",
          name: "images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          bucket: "wqn_dev__media",
          generation: "1673507383933894",
          metageneration: "1",
          contentType: "image/jpeg",
          storageClass: "STANDARD",
          size: 67736,
          md5Hash: "L7+UQq57JsIfoxIy3vAASA==",
          crc32c: "14w7wA==",
          etag: "CMb3l+m8wfwCEAE=",
          timeCreated: "2023-01-12T07:09:43.936Z",
          updated: "2023-01-12T07:09:43.936Z",
          timeStorageClassUpdated: "2023-01-12T07:09:43.936Z",
          originalFilename: "Mountain.png",
          mediaHost: "gcs",
          publicUrl:
            "https://storage.googleapis.com/wqn_dev__media/images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          format: "jpeg",
          width: 1400,
          height: 700,
          cropX: 0,
          cropY: 0,
        },
      ],
      video: [
        {
          kind: "storage#object",
          id: "wqn_dev__media/video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4/1670394807648130",
          selfLink:
            "https://www.googleapis.com/storage/v1/b/wqn_dev__media/o/video%2F%2Fcda36a40-8157-4450-a239-a0f5c09709c4.mp4",
          mediaLink:
            "https://storage.googleapis.com/download/storage/v1/b/wqn_dev__media/o/video%2F%2Fcda36a40-8157-4450-a239-a0f5c09709c4.mp4?generation=1670394807648130&alt=media",
          name: "video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4",
          bucket: "wqn_dev__media",
          generation: "1670394807648130",
          metageneration: "1",
          contentType: "video/mp4",
          storageClass: "STANDARD",
          size: 16422874,
          md5Hash: "8ivmgxqLXSJCEvaygbagaw==",
          crc32c: "EYqPbg==",
          etag: "CIL/k8nx5vsCEAE=",
          timeCreated: "2022-12-07T06:33:27.649Z",
          updated: "2022-12-07T06:33:27.649Z",
          timeStorageClassUpdated: "2022-12-07T06:33:27.649Z",
          mediaHost: "gcs",
          originalFilename: "Screen sharing - 2022-12-07 10_59_42 AM.mp4",
          format: "mp4",
          publicUrl:
            "https://storage.googleapis.com/wqn_dev__media/video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4",
        },
      ],
    },
  };
  return (
    <Container>
      <LABEL style={{ padding: "2rem" }}>Card label cases</LABEL>
      <WrapperCardGrid>
        <CardContent
          data={data}
         
          // to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
          to={"google.com"}
          actions={[
            {
              display: "Edit",
              icon: "PencilSimple",
              onClick: () => {},
            },
          ]}
          config={{
            // notClickable:true,
            cardLabel: "cardLabel",
            title: "title",

            //   video: "main.video",
            image: ["main.coverImage", "main.images"],
            metaBlock: [
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
              // { key: "name", valuePath: "meta.kp_contributed_by.name" },
              // { key: "date", valuePath: "kp_date_published" },
            ],
          }}
        />
        <CardContent
          data={data1}
          
          to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
          // onClick={() => window.alert("If both props (to and onclick() passed to compnent it will give an error)")}
          config={{
            // notClickable:true,
            cardLabel: "cardLabel",
            titleOptional:"title",

            title: "title",
            //   video: "main.video",
            image: ["main.coverImage", "main.images"],
            metaBlock: [
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
            ],
          }}
        />
        <>
        {/* <Card
          data={data}
          to={"google.com"}
        
          // to={cardLinkUrl}
          actions={[
            {
              display: "Edit",
              icon: "PencilSimple",
              onClick: () => {},
            },
          ]}
          config={{
            // notClickable:true,
            titleOptional:"optional title",
            cardLabel:
              "Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply",
            title: "Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply  Renovation and Door to Door Water supply Door to Door Water supply Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply  Renovation and Door to Door Water supply Door to Door Water supply",
            video: "main.video",
            image: ["main.coverImage", "main.images"],
            metaBlock: [
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
            ],
          }}
        /> */}
        </>
      </WrapperCardGrid>
        <>
      {/* <LABEL style={{ padding: "2rem" }}>Card title cases</LABEL>

      <WrapperCardGrid>
        <Card
          data={data}
       
          to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
          config={{
            // notClickable:true,
            cardLabel: "single word",
            title: "Pneumonoultramicroscopicsilicovolcanoconiosis",
            //   video: "main.video",
            image: ["main.coverImage", "main.images"],
            metaBlock: [
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
            ],
          }}
        />
        <Card
        data={data}
     
        to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
        config={{
          // notClickable:true,
          cardLabel: "multi line title",
          title:
          "Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply Community Contributed for Safe Source Renovation",

          //   video: "main.video",
          image: ["main.coverImage", "main.images"],
            metaBlock: [
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
            ],
          }}
        />
        </WrapperCardGrid>

      <LABEL style={{ padding: "2rem" }}>Card meta cases</LABEL>

      <WrapperCardGrid>
      <Card
      data={data}
     
      to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
      config={{
        // notClickable:true,
            cardLabel: "single word",
            title: "Pneumonoultramicroscopicsilicovolcanoconiosis",
            //   video: "main.video",
            image: ["main.coverImage", "main.images"],
            metaBlock: [
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
            ],
          }}
          />
          <Card
          data={data}
         
          to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
          config={{
            // notClickable:true,
            cardLabel: "multi line title",
            title:
            "Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply",
            
            //   video: "main.video",
            image: ["main.coverImage", "main.images"],
            metaBlock: [
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
            ],
          }}
          />
      </WrapperCardGrid> */}
      <LABEL style={{ padding: "2rem" }}>List label cases</LABEL>
        </>

      <Wrapper700>
        <ListContent
          data={data}
          to={"google.com"}
            openInNewTab
          // to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
          actions={[
            {
              display: "Edit",

              icon: "PencilSimple",
              onClick: () => {},
            },
          ]}
          config={{
            // notClickable:true,
            cardLabel: "cardLabel",
            title: "title",
            titleOptional:"title",

            //   video: "main.video",
            image: ["main.coverImage", "main.images"],
            metaBlock: [
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
            ],
          }}
        />
        <ListContent
          data={data1}
          //   openInNewTab
          to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
          actions={[
            {
              display: "Edit",
              icon: "PencilSimple",
              onClick: () => {},
            },
          ]}
          config={{
            // notClickable:true,
            cardLabel: "cardLabel",
            title: "title",
            //   video: "main.video",
            metaBlock: [
              { key: "name", valuePath: "meta.kp_contributed_by.name" },
              { key: "date", valuePath: "kp_date_published" },
            ],
          }}
        />
      </Wrapper700>
      <>
      {/* <List
        data={data}
        to={"google.com"}
        openInNewTab
        // to={cardLinkUrl}
        config={{
          // notClickable:true,
          cardLabel:
          "Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply",
          title: ["multi line label"],
          // image: ["main.coverImage", "main.images"],
          metaBlock: [
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "date", valuePath: "kp_date_published" },
          ],
        }}
        />  
        <List
        data={data}
        //   openInNewTab
        to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
        config={{
          // notClickable:true,
          cardLabel: "Pneumonoult ramicroscop icsilicovolcanoconiosis",
          title: "multi words card label",
          //   video: "main.video",
          image: ["main.coverImage", "main.images"],
          metaBlock: [
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "date", valuePath: "kp_date_published" },
          ],
        }}
        />
      <List
      data={data}
      to={"google.com"}
        openInNewTab
        // to={cardLinkUrl}
        config={{
          // notClickable:true,
          cardLabel:
          "Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply",
          title: ["multi line label"],
          video: "main.video",
          // image: ["main.coverImage", "main.images"],
          metaBlock: [
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "date", valuePath: "kp_date_published" },
          ],
        }}
      />

      <LABEL style={{ padding: "2rem",textAlgin:"center" }}> List title cases</LABEL>
      
      <List
        data={data}
        //   openInNewTab
        to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
        actions={[
          {
            display: "Edit",
            icon: "PencilSimple",
            onClick: () => {},
          },
        ]}
        config={{
          // notClickable:true,
          cardLabel: "single word",
          title:
          "PneumonoultramicroscopicsilicovolcanoconiosisPneumonoultramicroscopicsilicovolcanoconiosis",
          //   video: "main.video",
          image: ["main.coverImage", "main.images"],
          metaBlock: [
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "date", valuePath: "kp_date_published" },
          ],
        }}
        />
        <List
        data={data}
        //   openInNewTab
        to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
        config={{
          // notClickable:true,
          cardLabel: "multi line title",
          title:
          "Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply  Renovation and Door to Door Water supply Door to Door Water supply Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply  Renovation and Door to Door Water supply Door to Door Water supply",
          
          //   video: "main.video",
          image: ["main.coverImage", "main.images"],
          metaBlock: [
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "date", valuePath: "kp_date_published" },
          ],
        }}
      />
      </Wrapper700>
      <LABEL style={{ padding: "2rem" }}>List meta cases</LABEL>
      <Wrapper700>
      <List
        data={data}
        //   openInNewTab
        to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
        config={{
          // notClickable:true,
          cardLabel: "6 card meta",
          title: "Pneumonoultramicroscopicsilicovolcanoconiosis",
          //   video: "main.video",
          image: ["main.coverImage", "main.images"],
          metaBlock: [
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "date", valuePath: "kp_date_published" },
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "date", valuePath: "kp_date_published" },
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "date", valuePath: "kp_date_published" },
          ],
        }}
        />
        <List
        data={data}
        //   openInNewTab
        to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
        actions={[
          {
            display: "Edit",
            icon: "PencilSimple",
            onClick: () => {},
          },
        ]}
        config={{
          // notClickable:true,
          cardLabel: "4 card meta",
          title:
          "Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply",

          //   video: "main.video",
          image: ["main.coverImage", "main.images"],
          metaBlock: [
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "date", valuePath: "kp_date_published" },
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "date", valuePath: "kp_date_published" },
          ],
        }}
        />
        <List
        data={data}
        //   openInNewTab
        to={"/published-page/stories?id=63bfb217d06e0a55409bd31d"}
        actions={[
          {
            display: "Edit",
            icon: "PencilSimple",
            onClick: () => {},
          },
        ]}
        config={{
          // notClickable:true,
          cardLabel: "Optional Title",
          title:
          "Community Contributed for Safe Source Renovation and Door to Door Water supply Door to Door Water supply",
          titleOptional: "Lorem ipsum dolor sit amet",
          //   video: "main.video",
          image: ["main.coverImage", "main.images"],
          metaBlock: [
            { key: "name", valuePath: "meta.kp_contributed_by.name" },
            { key: "dokf-fe/client/src/pageTpls/Playground/pages/Cards.jsate", valuePath: "kp_date_published" },
          ],
        }}
        />
      </Wrapper700> */}
      </>
      
    </Container>
  );
};

export { Cards };
