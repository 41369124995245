import { DecoratorNode } from "lexical";
import React from "react";
import { ImageInputNodeComp } from "./Comp";
import { nanoid } from "nanoid";

export class ImageInputNode extends DecoratorNode {

  static getType() {
    return "ImageInputNode";
  }

  static clone(node) {
    return new ImageInputNode({
      value: node.__value,
      files: node.__files,
      multiple: node.__multiple,
      id: node.__id,
      key: node.__key,
    });
  }

  static importJSON(serializedNode) {
    return $createImageInputNode({
      value: serializedNode.value,
      multiple: serializedNode.multiple,
      id: serializedNode.id,
    });
  }

  exportJSON() {
    return {
      type: this.constructor.getType(),
      value: this.__value,
      multiple: this.__multiple,
      id: this.__id,
    };
  }

  constructor({ value, files, multiple, id, key }) {
    super(key);
    this.__value = value;
    this.__files = files;
    this.__multiple = multiple;
    this.__id = id || nanoid(6);
  }

  createDOM() {
    return document.createElement("div");
  }

  updateDOM() {
    return false;
  }

  onChange(editor, value) {
    editor.update(() => {
      const self = this.getWritable();
      self.__value = value;
      if (self.__files) {
        self.__files = undefined;
      }
    });
  }

  onRemove(editor) {
    editor.update(() => {
      this.remove();
    });
  }

  decorate(editor, editorConfig) {
    return (
      <ImageInputNodeComp
        nodeKey={this.__key}
        id={this.__id}
        files={this.__files}
        value={this.__value}
        multiple={this.__multiple}
        onChange={(k, v) => this.onChange(editor, v)}
        onRemove={() => this.onRemove(editor)}
        readOnly={editorConfig.theme.RTE_COMP_PROPS.readOnly}
      />
    );
  }

  isInline(){ //somehow this is important so that if this block is at end of editor, and we do arrow down + enter, then a new line will be created. Else it will not.
    return false
  }

}

export function $createImageInputNode({ value, files, multiple, id, key }) {
  return new ImageInputNode({ value, files, multiple, id, key });
}

export function $isImageInputNode(node) {
  return node instanceof ImageInputNode;
}
