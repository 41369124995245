import { SERIF_3_4, SERIF_5_6, SERIF_7_8, SERIF_6_7 } from "oolib";
import React from "react";
import styled from "styled-components";

const StyledSectionHeaderWrapper = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

export const TitleComp = ({ title, subtitle, depthIdx }) => {
  if (!title && !subtitle) return null;

  const genTitle = () => {
    switch (depthIdx) {
      case undefined:
      case 0:
        return <SERIF_7_8>{title}</SERIF_7_8>;
      case 1:
        return <SERIF_6_7>{title}</SERIF_6_7>;
      case 2:
        return <SERIF_5_6>{title}</SERIF_5_6>;
      default: //3 and above
        return <SERIF_3_4>{title}</SERIF_3_4>;
    }
  };

  return (
    <StyledSectionHeaderWrapper>
      {title && genTitle()}
      {subtitle && <SERIF_3_4>{subtitle}</SERIF_3_4>}
    </StyledSectionHeaderWrapper>
  );
};
