import React, { useEffect } from "react";
import PDFEmbed from "./commonFunction";

function PDFEmbedV2(props) {

  const {filePath,fileName,fileID} = props;

  useEffect(() => {
    const pdfEmbed = new PDFEmbed()
    pdfEmbed.ready().then(() => {
      /* Invoke file preview */
      pdfEmbed.previewFile(
        fileID,
        {
          /* Pass the embed mode option here */
          embedMode: "SIZED_CONTAINER",
        },
        // pass pdf meta deta here
        {
          name:fileName,
          path: filePath,
            // "http://www.pdf995.com/samples/pdf.pdf",
        }
      );
    });
  }, []);

  return <div id={`${fileID}`} style={{height: "450px" }}></div>;
}

export default PDFEmbedV2;
