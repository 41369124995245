import {
  Container, 
  Wrapper700, 
  PaddingTopBottom45
} from "oolib";
import React from "react";

import { WorksheetsDebugComp } from "../../../components/inputs/WorksheetsModule/debug/WorksheetsDebugComp";


export const WorksheetTest = () => {
  

  return (
    
      <Container>
        <Wrapper700>
          <PaddingTopBottom45>
            <WorksheetsDebugComp />
          </PaddingTopBottom45>
        </Wrapper700>
      </Container>
      
    
  );
};


