import React from "react";
import styled, { css } from "styled-components";
import { colors } from "oolib";
import { toggleRichHighlightUIState } from "./utils/toggleRichHighlightUIState";
import { useHandleClickOutsideHighlight } from "./utils/useHandleClickOutsideHighlight";
const { none, blue } = colors;

let yellowHighlight = "#FFE600";
let blueHighlight = "#B5F2FA";
let pinkHighlight = "#FFE5F2";

const commonCss = css`
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  cursor: ${({ readOnly }) => (readOnly ? "pointer" : "text")};
  border-bottom: 2px solid;
  border-color: #33a4ff00;
`;

const StyledRichHighlight = styled.span`
  ${commonCss}
  background-color: ${({ data }) => {
    if (!data) return none;
    if (data.some((d) => d.tags?.vocabs)) return blueHighlight;
    if (data.some((d) => d.tags?.quotes)) return pinkHighlight;
    if (!data) return yellowHighlight + "25";
    let opacity = data.length === 3 ? "90" : data.length * 25;
    if (opacity >= 100) opacity = "";
    return yellowHighlight + opacity;
  }};
  &.-hovered- {
    background-color: ${({ data }) => {
      if (!data) return "rgba(0,0,0,0)";
      if (data.some((d) => d.tags?.vocabs)) return blueHighlight;
      if (data.some((d) => d.tags?.quotes)) return pinkHighlight;
      //else
      return yellowHighlight + "75";
    }};
    border-color: ${blue};
  }
  &.-active- {
    background-color: ${({ theme: { colors } }) => colors.primaryColor40};
    border-color: ${blue};
  }
`;

let highlightColors = {
  yellowHighlight: yellowHighlight + "25",
  blueHighlight,
  pinkHighlight,
};

let highlightHoverColors = {
  yellowHighlight: yellowHighlight + "75",
  blueHighlight,
  pinkHighlight,
};

export const StyledHighlightWrapper = styled.div`
  span {
    ${commonCss}
    ${({ backgroundColor }) =>
      backgroundColor &&
      `background-color: ${highlightColors[backgroundColor]};`}
    &.-hovered- {
      ${({ backgroundColor }) =>
        backgroundColor &&
        `background-color: ${highlightHoverColors[backgroundColor]};`}
      border-color: ${blue};
    }
  }
`;

const RichHighlight = ({
  contentState,
  entityKey,
  start,
  end,
  EditorRef,
  onAnnoClick,
  readOnly,
  blockKey,
  children  
}) => {

  const data = contentState.getEntity(entityKey).getData();

  const targetFragmentKey = data[0].fragmentKey;

  const handleMouseOver = (e) => {
    toggleRichHighlightUIState({
      targetFragmentKey,
      UIState: 'hover',
      add_remove: 'add'
    })
  };

  const handleMouseOut = (e) => {
    toggleRichHighlightUIState({
      targetFragmentKey,
      UIState: 'hover',
      add_remove: 'remove'
    })
  };

  const handleOnClick = (e) => {
    e.preventDefault();



    // this check is important.
    /* if you are creating an annotation, starting and ending within an annotation, it will create a problem because the display lightbox will get rendered 
    due to onClick and now if you click on the annotation button to create a new annotation, you won't be able to do so because of the handleClickOutside handler resetting the state ( it arises a race condition of the setState, as diplay and input lightbox is controlled by the same state).
    so to fix this, the following condition if true, i.e someone is creating a new annotation simply returns and do nothing  */
    if (
      window.getSelection().anchorOffset !== window.getSelection().focusOffset
    )
      return;


    toggleRichHighlightUIState({
      targetFragmentKey,
      UIState: 'active',
      add_remove: 'add'
    })
    
    const elems = document.getElementsByClassName(targetFragmentKey);
    const EditorElem = EditorRef.current

    const length = elems.length;

    const targetElem = elems[length - 1];

    const { top: EditorElemTop } = EditorElem.getBoundingClientRect();
    const { top: targetElemTop } = targetElem.getBoundingClientRect();

    const left = targetElem.offsetLeft;
    const top = targetElemTop - EditorElemTop;

    const height = targetElem.offsetHeight;

    

    

    const payload = {
      data: {
        targetFragmentKey,
        mode: "display",
        data: data[0].tags,
      },
      position: { x:left, y: top + height + 2 },

      // data: data[0].tags,
      // position: { left, top: top + height + 2 },
      // // position: targetElem.getBoundingClientRect(),

      // targetFragmentKey,
      // mode: "display",
    };

    onAnnoClick(payload);
  };

  useHandleClickOutsideHighlight({targetFragmentKey})

  return (
    <>
      <StyledRichHighlight
        className={`${data.map((d) => d.fragmentKey).join(" ")}`}
        data={data}
        readOnly={readOnly}
        data-blockKey={blockKey}
        data-start={start}
        data-end={end}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleOnClick}
      >
        {children}
      </StyledRichHighlight>
    </>
  );
};

export default RichHighlight;
