import * as React from "react"
import { SANS_0 , colors, icons} from 'oolib'

const {red, white, greyColor100} = colors; 
const { Bell } = icons;
const OkeBell = (props) => {

  let notifNumStyle = {
    position: 'absolute',
    top: '0.4rem',
    right: '1rem',
    backgroundColor: red,
    padding: '0 0.5rem',
    borderRadius: '0.5rem',
    color: white,
    transform: 'translate(100%,-50%)'
  }

  return (
    <div style={{position: 'relative'}}>
    { props.number > 0 && 
      <SANS_0 medium style={notifNumStyle}>
        {props.number}
      </SANS_0> }
      <Bell {...props} />
    </div>
  )
}

export default OkeBell
