import React, { useState } from "react";
import { DropdownSingle, OKELink, SANS_3 } from "oolib";
import CreateTagCategoryAndTags from "./comp/CreateTagCategoryAndTags";

export default function AddTagsInTCI({ id, value, onChange = () => {} }) {
  const [open, setOpen] = useState(false);
//
  return (
    <div>
      <DropdownSingle
        isSearchable={true}
        label="Tag Categories"
        sublabel="Choose a tag category"
        saveValueAsString={true}
        optionsFn={{ fn: "getTagTypes" }}
        value={value}
        onChange={onChange}
      />
      <div
        style={{
          padding: "1rem 2rem 1rem 1rem",
          width: "fit-content",
          marginTop:".5em"
        }}
      >
        <SANS_3>
          <OKELink
            color={"greyColor100"}
            text={"New Tag Catgeory"}
            icon="Plus"
            onClick={() => setOpen(true)}
          />
        </SANS_3>
      </div>

      {open ? (
        <CreateTagCategoryAndTags
          onDone={({ tagCategory }) => onChange(id, tagCategory)}
          onClose={() => setOpen(false)}
        />
      ) : null}
    </div>
  );
}
