import React from "react";
import { TextInput, toArray} from 'oolib';







const TextInputToArray = (props) => {
/**
 * if input is comma separated the value returnned is converted to an array, split at ','
 * if input doesnt contain comma then the value is returned as a string
 */

	return (
		<TextInput 
			{...props}
			value = {props.value && toArray(props.value).join(',')}
			onChange={(k,v) => {
				props.onChange && props.onChange(
					props.id, 
					v.includes(',') ? v.split(',').map(vv => vv.trim()) : v
				)
			}}
			/>
	)
}



export default TextInputToArray