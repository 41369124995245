import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useGenErrorMsgBanners } from "../../../utils/useGenErrorMsgBanners";

export const useQuickUpdateContent = () => {
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  const queryClient = useQueryClient();
  return useMutation(
    ({ contentType, contentId, body }) => {
      return axios.post(
        `/api/content/quickUpdateContent/${contentType}/${contentId}`,
        body,
        { headers: { "Content-Type": "application/json" } }
      );
    },
    {
      onError: (err) => {
        genErrorMsgBanners({ err });
      },
      onSuccess: (res, {contentType}) => {
        queryClient.invalidateQueries([
          "contentListing",
          "lazy",
          [contentType],
        ])
            }
    }
  );
};
