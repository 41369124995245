import React from "react";

const KPDataSummaryCardShell = ({ colWidth, children }) => (
      <div className={`${colWidth} kp-data-summary-card-wrapper`}>
        <div className="kp-data-summary-card">
        {children}
        </div>
      </div>
);

export default KPDataSummaryCardShell;
