import { colors, transition } from "oolib";
import styled from "styled-components";


export const StyledCustomSelect = styled.div`
  border: none;
  height: 6rem;
  background-color: ${({isActive}) => isActive ? colors.greyColor5 : colors.none};
  padding: 0 1.5rem;
  ${transition("background-color")};
  &:hover {
    background-color: ${colors.greyColor5};
  }
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
`;

