import styled from "styled-components";
import { Link } from 'react-router-dom';
import { colors, LABEL, transition, mediaQuery} from "oolib";

const { 
  greyColor100, greyColor80,
  greyColor15,
  white,
} = colors;

const StyledHumburgerMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${white};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 10000;
  padding: 0 2rem;
  padding-bottom: 2rem;
  transform: translateX(${({opened})=> opened? '0': '-100%'});
  
  ${transition('transform')};

  ${mediaQuery('sm')}{ padding: 0 6rem; }

  ${mediaQuery('lg')}{
    display: none;
  }
  
`;

const StyledMenuHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  height: 6rem;
  background-color: ${white};
  box-shadow: 0px 4px 5px rgba(235, 235, 235, 0.4);
  display: grid;
  place-items: center stretch;
  grid-template-columns: 3fr;
  grid-template-areas: "left center right";
  & > .left {grid-area: left}
  & > .center {grid-area: center}
  & > .right {grid-area: right}

  margin: 0 -2rem;
  padding: 0 2rem;
  ${mediaQuery('sm')}{
    margin: 0 -6rem;
    padding: 0 6rem;
  }

`;

const StyledMenuSection = styled.div`//
  padding: 2rem 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${greyColor15};
`;

const StyledMenuSectionLabel = styled(LABEL)`
  color: ${greyColor80};
  padding-bottom: 1rem;
`;

const StyledLinksWrapper = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 0 -2rem;
  ${mediaQuery('sm')}{margin: 0 -6rem;}
`;

const StyledMenuLink = styled(Link)`
  color: ${greyColor100};
  display: inline-block;
  padding: 1rem 2rem;
  width: 100%;
  background-color: ${({active, theme})=> active? theme.colors.primaryColor10: white};

  ${transition('background-color')}
  &:hover {background-color: ${({theme}) => theme.colors.primaryColor10}}
  ${mediaQuery('sm')}{padding: 1rem 6rem;}
`;


export {
  StyledHumburgerMenu,
  StyledMenuHeader,
  StyledMenuSection,
  StyledMenuSectionLabel,
  StyledLinksWrapper,
  StyledMenuLink
}