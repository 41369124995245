import React from "react";



import {SANS_0, colors} from 'oolib'
import { _Locale } from "../../../../../../locale/Locale";

const { red } = colors; 
 
const KPTooltip = ({className, style, text, type}) => {

  const typeMapping = { 'danger' : red }

  return (
  <div className={`kp-tooltip-wrapper ${className}`} style={style}>
    <SANS_0 style={type && {color: typeMapping[type]}}>{ _Locale(text) }</SANS_0>
  </div>
)}

export default KPTooltip;
