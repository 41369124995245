import React, { Fragment, useState } from "react";
import ReactPlayer from "react-player";

import { UploadButton, URLInput,  BlockLabel, useBannerContext } from "oolib";
import { imageUploader } from "../../../utils/general";
import ContentBlockShell from "../../generalUI/ContentBlockShell";
import { replaceEntityData } from "../../../utils/richInputUtils";


const DEPRECATED_KPVideoEmbed = (props) => {
  const {
    id,
    className,
    label,
    sublabel,
    isRequired,
    value,
    onChange: parentOnChange,
    isInEditor,
    editor,
    entityKey,
    block,
    multiple,
    uploadBucketName,
    handleSetTempReadOnly,
    placeholder,
    readOnly,
    light: lightPlayer,
    folderName,
    allowedFormats,
    passChangeHandlerOps,
    disableVideoUpload
  } = props;

  const [chosenMethod, setChosenMethod] = useState(
    !value ? null : typeof value == "string" ? "url" : "upload"
  );
  let srcURL = Array.isArray(value) ? value[0].mediaLink : value;

  const [url, setUrl] = useState(srcURL || null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const {SET_ALERT_BANNER} = useBannerContext()

  const reactPlayerRootStyle = {};

  /// pasting a url handling part

  const onChangeUrl = (v) => {
    // let canPlay = ReactPlayer.canPlay(v); // what is this doing...?? there was a prupose to this. maybe to check if vidoe is playable, and only thewn updating state. but it is clearly not being used right now.
    if (v) {
      setChosenMethod("url");
    } else {
      setChosenMethod(null);
      setUrl(null);
    }

    setUrl(v);
    parentOnChange && parentOnChange(id, v);
    if (isInEditor === true) {
      replaceEntityData(editor, entityKey, { value: v });
    }
  };

  /// uploading a video handling part

  const handleErrorFeedback = (msg) => {
    setErrorMsg(msg);
    setUploading(false);
    setUploadProgress(null);
  };

  const handleUploadProgess = (loadedPercent) =>
    setUploadProgress(loadedPercent);

  const callback = (status, resData) => {
    if (status === "success") {
      parentOnChange &&
        parentOnChange(id, resData, passChangeHandlerOps && props);
      if (isInEditor === true) {
        replaceEntityData(editor, entityKey, { value: resData });
      }
      setUrl(resData[0].mediaLink);
    }
    setUploading(false);
    setUploadProgress(null);
  };

  const handleUpload = (ev) => {
    if (ev.target.files.length === 0) return;

    setChosenMethod("upload");
    setUploading(true);
    setErrorMsg(null);

    const files = Array.from(ev.target.files);

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append(i, file);
    });
    formData.set("folderName", folderName);
    formData.set("allowedFormats", allowedFormats);
    if (uploadBucketName) {
      formData.set("uploadBucketName", uploadBucketName);
    }
    imageUploader(
      formData,
      {
        type: "files",
        jsonFileData: files,
        folderName,
        allowedFormats,
        handleUploadProgess,
        handleErrorFeedback,
        SET_ALERT_BANNER
      },
      callback
    );
  };

  //// displaying part

  const createVideoPreview = () => {
    
    switch (true) {
      case url === null || url === "":
        return null;
      case ReactPlayer.canPlay(url) === false:
        return (
          <h5 className="h5">
            woops, something is wrong with the link you have pasted. we can't
            seem to play this video.
          </h5>
        );
      default:
        return chosenMethod === "upload" ? (
          <video controls={true} src={url} width="100%"></video>
        ) : (
          <ReactPlayer
            url={url}
            controls={true}
            width="100%"
            height="0"
            className="kp-video-embed__player"
            style={reactPlayerRootStyle}
          />
        );
    }
  };

  const inputBlock = () => (
    <Fragment>
      {!disableVideoUpload && (!chosenMethod || chosenMethod === "upload") && (
        <UploadButton
          id={`${id}_videoUploadBtn`}
          variant={url && "primary"}
          multiple={multiple}
          value={
            uploading && uploadProgress === 100
              ? "Almost Done..."
              : uploading
              ? `Uploading... ${
                  uploadProgress ? "( " + uploadProgress + " / 100 )" : ""
                }`
              : errorMsg
              ? errorMsg
              : url
              ? "replace video"
              : "Upload Video"
          }
          onChange={handleUpload}
        />
      )}
      {!disableVideoUpload && !chosenMethod && <p>OR</p>}
      {(!chosenMethod || chosenMethod === "url") && (
        <URLInput
          placeholder={placeholder || "Paste a url here"}
          onChange={(k, v) => onChangeUrl(v)}
          value={url}
          autofocus={chosenMethod === "url" && 'on'}
        />
      )}
      <div className="kp-video-embed__preview">{createVideoPreview()}</div>
    </Fragment>
  );

  const displayBlock = () => (
    <div className="kp-video-embed__display">
      {chosenMethod === "upload" ? (
        <video controls={true} src={url} width="100%"></video>
      ) : (
        <ReactPlayer
          className="kp-video-embed__player"
          url={url}
          controls={true}
          width="100%"
          height="0"
          style={reactPlayerRootStyle}
          light={lightPlayer}
        />
      )}
    </div>
  );
  const createBlock = () => {
    switch (true) {
      case readOnly:
        return displayBlock();
      default:
        return inputBlock();
    }
  };
  return (
    <ContentBlockShell
      richInputProps={{
        isInEditor,
        editor,
        block,
        handleSetTempReadOnly,
      }}
      readOnly={readOnly}
      className={`kp-video-embed ${className}`}
      id={id}
    >
      <BlockLabel
        label={label}
        sublabel={sublabel}
        isRequired={isRequired}
        readOnly={readOnly}
      />
      {createBlock()}
    </ContentBlockShell>
  );
};

DEPRECATED_KPVideoEmbed.defaultProps = {
  multiple: false,
  folderName: "video",
  allowedFormats: ["mp4", "webm"],
};

export default DEPRECATED_KPVideoEmbed;