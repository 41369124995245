import { cloneDeep } from "lodash";
import { ButtonPrimary, getVal, setVal } from "oolib";
import React, { useEffect, useState } from "react";
import { useFormValidationContext } from "../../../../contexts/formValidationContext";
import { spaceOps } from "../../../../utils/templating/spaceAndDivisionConfigs";
import { formValidationCheckV2 } from "../../../../utils/validation/formValidation";
import { isRightWrongCheck } from "../../../../utils/validation/formValidation/isRightWrongValidationCheck";
import { debugTpl } from "./debugTpl";
import Layout from "../Layout";
// import "./_style.scss";

export const WorksheetsDebugComp = () => {
  // const [isCompleted, setCompleted] = useState(false);

  let activeSpace = spaceOps.worksheets.find((d) => d.value === "templates");
  let getBlocks = (tpl) => getVal(tpl, activeSpace.valuePath);

  const [responses, setResponses] = useState({});

  const {
    setFormValidation,
    formValidation,
    resetFormValidation,
    makeFormValidationVisible,
  } = useFormValidationContext();

  useEffect(() => {
    if (debugTpl) {
      const { failedBlocks } = formValidationCheckV2({
        blocks: getBlocks(debugTpl),
        content: responses,
      });

      setFormValidation((p) => ({ ...p, failedBlocks }));
    }

    return () => resetFormValidation();
  }, [debugTpl]);

  const handleOnChange = (block, value) => {
    setRightWrongResults(undefined);
    setResponses((prev) => {
      let newResp = cloneDeep(prev);

      setVal(newResp, block.valuePath, value);

      const { failedBlocks } = formValidationCheckV2({
        blocks: getBlocks(debugTpl),
        content: newResp,
      });
      setFormValidation((p) => ({ ...p, failedBlocks }));

      return newResp;
    });
  };

  const [rightWrongResults, setRightWrongResults] = useState(undefined);

  const handleSubmit = () => {
    // setAlertIfRightWrong(true)

    let { results } = isRightWrongCheck({
      blocks: getBlocks(debugTpl),
      content: responses,
    });
    setRightWrongResults(results);
  };

  const genSubmitResponsesBtn = () => (
    <div
      onClick={() => {
        if (formValidation.failedBlocks.length > 0)
          makeFormValidationVisible(
            "You need to answer all questions in the worksheet"
          );
        if (formValidation.invalidInputBlocks.length > 0){
          makeFormValidationVisible(
            "Make sure none of your inputs are invalid"
          );
        }
      }}
    >
      <ButtonPrimary
        value="Submit Response"
        onClick={handleSubmit}
        disabled={
          formValidation.failedBlocks.length > 0 ||
          formValidation.invalidInputBlocks.length > 0
        }
      />
    </div>
  );

  return (
    <div>
      <Layout
        tpl={debugTpl}
        content={responses}
        handleOnChange={handleOnChange}
        rightWrongResults={rightWrongResults}
      />
      {genSubmitResponsesBtn()}
    </div>
  );
};


