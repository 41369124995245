export const config = {
  icon: "UploadSimple",
  options: [
    {
      icon: "MultipleImages",
      accept: "image/*",
      nodeType: "ImageInputNode",
      props: {
        multiple: true,
      },
    },
    {
      icon: "ImageSquare",
      accept: "image/*",
      nodeType: "ImageInputNode",
      props: {},
    },
    {
      icon: "VideoCamera",
      accept: "video/*",
      nodeType: "VideoInputNode",
      props: {},
    },
    {
      icon: "FilePdf",
      accept: "application/pdf",
      nodeType: "PDFInputNode",
    },
    {
      icon: "SpeakerHigh",
      accept: "audio/*",
      nodeType: "AudioInputNode",
      props: {},
    },
  ],
};
