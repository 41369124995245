
import styled,{css} from "styled-components"


// const { cyan, lightPink } = colors

// const bgColor = {
//     quotes: lightPink, // lightPink
//     vocabs: cyan, // vocab
//   }

export const TagDisplayContainerStyled = styled.div`
  background: #ffffff;

  border: 1px solid #ebebeb;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  max-width: 45rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

  border-radius: 3px;
  z-index: 1000;

  /* position: absolute;
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}
  ${({ left }) =>
    left &&
    css`
      left: ${left}px;
    `} */
`

export const TagsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 20rem;
  padding: 0.5rem;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    width: 0px; /* webkit */
  }
`

export const TagListStyled = styled.div`
  display: flex;
  column-gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  div {
    white-space: normal;
  }
`

