import React from "react";
import { ToolbarDropdownButton } from "../comps/ToolbarDropdownButton";

import { ToolbarButton } from "../comps/ToolbarButton";
import { config } from "./config";
import { handleClick } from "./handleClick";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

export const HeadingTools = ({ activeNodeInfo }) => {
  const [editor] = useLexicalComposerContext()
  const checkIsActive = ({ tag, activeNodeInfo }) =>
    tag === activeNodeInfo?.__tag;

  return (
    <>
      <ToolbarDropdownButton
        isActive={config.options.some((conf) =>
          checkIsActive({ tag: conf.tag, activeNodeInfo })
        )}
        icon={config.icon}
        noOfChildButtons={config.options.length}
        genChildButtons={(setOpen) =>
          config.options.map((conf) => {
            const { tag } = conf;
            const isActive = checkIsActive({ tag, activeNodeInfo });
            return (
              <ToolbarButton
                isActive={isActive}
                onClick={() => {
                  handleClick({ isActive, tag, editor });
                  setOpen(false);
                }}
                icon={conf.icon}
              />
            );
          })
        }
      />
    </>
  );
};
