import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export const useElemInView = ({callback}) => {
    
    // /*
    //  * rootMargin property allows us to trigger a 'inview' 1600px below the screen. 
    //  * however this seems to be causing wierd bugs on the search dashboard, hence
    //  * commented out for now.
    //  */

    const { ref, inView, entry } = useInView({ threshold: 0 });

    const [isLoading, setIsLoading] = useState(false); // Using loading state to avoid multiple requests 
  
    useEffect(() => {
      if (inView && !isLoading && entry && entry?.target) {
        if(!callback()) return
        
        setIsLoading(true);
        callback().then(() => setIsLoading(false));
      }
    }, [inView, isLoading, entry?.target.id]);
  
    return { observerRef: ref };
}
