import { getVal } from 'oolib'
import * as isRightWrongChecks from './isRightWrongChecks'


export const isRightWrongCheck = ({
  blocks,
  content,
  depthIdx = 0,
  results = [],
  sectionStack = []
}) => {
  blocks.map((block, i) => {
    let isSection = !!block.blocks;

    if (isSection === true) {
      isRightWrongCheck({
        blocks: block.blocks,
        content,
        depthIdx: depthIdx + 1,
        results,
        sectionStack: [
          ...sectionStack,
          { sectionId: block.sectionId, sectionTitle: block.sectionTitle },
        ],
      });
    } else {
    
        let value = getVal(content,block.valuePath);
        let result = isRightWrongChecks[block.comp]
            ? isRightWrongChecks[block.comp]({value, block})
            : undefined // means right wrong doesnt apply to this block
      
        results.push({
            sectionStack,
            valuePath: block.valuePath,
            result
        })
      
      
      
    }
  });

  return { results };
};


export const getThisBlockRightWrongResult = (block, rightWrongResults) => {
    let result = rightWrongResults?.find(b => b.valuePath === block.valuePath )
    return { rightWrongResult: result }
}
