import React from "react";
import { createContentCardDate } from "../../../../utils/momentManipulate";
import {
  CardStyled,
  CardFooterStyled,
  ClampedWrapperStyled,
  SummaryWrapperStyled,
  CardBodyStyled,
  CardHeaderStyled,
} from "./styled";
import { SANS_2, SANS_5_6, SERIF_3_4, colors, SANS_3 } from "oolib";
import KPRichInput from "../../../inputs/KPRichInput";
import { TagDisplay } from "oolib";

const ClampedKPRichInput = ({ blocks, annoTagType }) => {
  const blockToShow = { ...blocks[0] };
  blockToShow.text =
    blockToShow.text.length > 135
      ? blockToShow.text.substring(0, 135) + "..."
      : blockToShow.text;

  return (
    <ClampedWrapperStyled annoTagType={annoTagType}>
      <KPRichInput
        readOnly={true}
        value={{
          blocks: [blockToShow],
          entityMap: {},
        }}
      />
    </ClampedWrapperStyled>
  );
};

const { greyColor80 } = colors;

export default function AnnotationCard({
  title,
  annotations,
  summary,
  district,
  age,
  ctMethods: { ctUsed, ctConsidered },
  onCardClick,
  date,
  annoTagType,
  active,
}) {
  return (
    <CardStyled active={active} onClick={onCardClick}>
      <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <CardHeaderStyled>
          {age && (
            <SANS_2 bold uppercase color={greyColor80}>
              Age: {age}
            </SANS_2>
          )}
          <SANS_5_6 bold capitalize>
            {title}
          </SANS_5_6>
        </CardHeaderStyled>
        <CardBodyStyled>
          <SummaryWrapperStyled>
            {summary?.map((d) => (
              <SERIF_3_4>{d.text}</SERIF_3_4>
            ))}
          </SummaryWrapperStyled>
          {annotations.map((dd) => (
            <ClampedKPRichInput annoTagType={annoTagType} blocks={dd.text} />
          ))}
          {(ctUsed?.data.length || ctConsidered?.data.length) && (
            <div>
              <SANS_3 bold capitalize>
                {ctUsed ? "ct used" : "ct considered"}
              </SANS_3>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  gap: "1rem",
                  marginTop: ".7rem",
                }}
              >
                {(ctUsed?.data || ctConsidered?.data).map((ct) => {
                  return <TagDisplay key={ct.value} display={ct.display} />;
                })}
              </div>
            </div>
          )}
        </CardBodyStyled>
      </div>
      <CardFooterStyled>
        <SANS_2>
          {district ? district.display + "|" : ""}
          {createContentCardDate(date)}
        </SANS_2>
      </CardFooterStyled>
    </CardStyled>
  );
}
