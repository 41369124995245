export const disablePageInteraction = () => {
    const root = document.documentElement;
    root.style.setProperty('overflow-y', "hidden");
    root.style.setProperty('pointer-events', 'none');
};

export const enablePageInteraction = () => {
    const root = document.documentElement;
    root.style.setProperty('overflow-y', "");
    root.style.setProperty('pointer-events', '');
};