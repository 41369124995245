import { forceSelection } from 'draft-js/lib/EditorState'

const highlightPreviousBlocks =({
	blocksCount,
	selectionState,
	editorState,
	handleChange,
	startKey,
	contentState
})=> {
	let endBlockKey = startKey;
	for (let i = 1; i < blocksCount; i++){
		endBlockKey = contentState.getBlockBefore(endBlockKey).getKey()
	}
	const newSelection = selectionState.merge({
		focusKey: endBlockKey, //focusOffset is 0 anyways
		isBackward: true
	})
	const newEditorSate = forceSelection(editorState, newSelection);
	handleChange(newEditorSate)
}

export default highlightPreviousBlocks;