import { isEqual } from 'lodash'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { __GetProfileTypeConfig } from '../utils/getters/gettersV2'
import { useGetQueryData } from '../utils/react-query-hooks/general'

export const PreAccessChecklistContext = createContext()


const addUserAgreementToChecklist = ({deployment: deploymentConfig, userData}) => (
	deploymentConfig._EnableUserAgreement && 
	[{ 	
		key: "userAgreement",
		redirectRoute: { pathname: '/userAgreement' }, 
		complete: userData.user.userAgreementAccepted 
	}]
)

const addGaAgreementToChecklist = ({deployment: deploymentConfig, userData}) => (
	deploymentConfig._GoogleAnalytics?.enable && 
	[{ 
		key: "gaAgreement",
		redirectRoute: { pathname: '/gaAgreement' }, 
	complete: userData.user.gaAgreementAccepted  
	}]
)

const addOnboardingToChecklist = ({userData}) =>  (
	__GetProfileTypeConfig(userData.user.profileType).onboardingFlow && 
	[{ 
		key: "onboarding",
		redirectRoute: { pathname: '/onboarding' }, 
		complete: userData.user.onboardingChecklist.onboardingFlow.completed 
	}]
)

const addResetPasswordToChecklist = ({userData}) => (
	userData.user.forceUpdatePassword === true &&
	[{
		key: 'resetPassword',
		redirectRoute: { pathname: '/reset-password', state: { isForceUpdatePassword: true } },
		complete: userData.user.onboardingChecklist.passwordUpdatedOnFirstLogin 
	}]
)


const prepareChecklist = ({deployment, userData}) => {
	let toReturn = [
		...( addUserAgreementToChecklist({deployment, userData}) || [] ),
		...( addGaAgreementToChecklist({deployment,userData}) || [] ),
		...( addOnboardingToChecklist({userData}) || [] ),
		...( addResetPasswordToChecklist({userData}) || [] ),
		

	]
	return toReturn;
}

const getFromRoute = ({location, redirectRoute}) => {
	if(location.pathname+location.search !== redirectRoute.pathname+(redirectRoute.search || '')){
		return location.pathname+location.search
	}else{
		return undefined
	}
}

export const PreAccessChecklistContextProvider = ({ children }) => {
	
	
	const location = useLocation()
	const history = useHistory()
	const userData = useGetQueryData('userData')
	const platformConfigs = useGetQueryData('platformConfigs')

	/**
	 * undefined  means it hasnt been set. 
	 * if it is an array (even an empty array), means it has been set.
	 * empty array means that there are NO THINGS to be done before app access
	 * in which case, simply let the user through
	 */
	
	const [
		preAccessChecklist, 
		setPreAccessChecklist
	] = useState(undefined)

	useEffect(() => {
		
		if(userData?.user && platformConfigs){
			const {deployment} = platformConfigs
			
			//means this is a login or first app load
			if(!preAccessChecklist){
				// console.log('create pre access checklist. if incomplete things exist. redir to the first one')
				let _preAccessChecklist = prepareChecklist({deployment, userData})
				setPreAccessChecklist(_preAccessChecklist)
				let toRedirInfo = _preAccessChecklist.find(d => !d.complete)
				// console.log({toRedirInfo})
				if(toRedirInfo){
					// console.log({stateWillBeSet:getFromRoute({location, redirectRoute: toRedirInfo.redirectRoute}) })
					history.push({
						...toRedirInfo.redirectRoute,
						state: {
							...(toRedirInfo.redirectRoute.state || {}),
							from: getFromRoute({location, redirectRoute: toRedirInfo.redirectRoute})
						}
					})
				}
				//means this is a userData update AFTER first mount. and the checklist already existsx
			}else {
				if(preAccessChecklist.some(d => !d.complete)){
					// console.log('update checklist. then redir to the next incomplete. if it is fully complete, then redir to home')
					
					//if checklist as it stands has some incomplete items, then lets update it
					//by evaluating the latest userData
					let updatedPreAccessChecklist = prepareChecklist({deployment, userData})
					let toRedirInfo = updatedPreAccessChecklist.find(d => !d.complete)
					// console.log({toRedirInfo})

					//if checklist is exactly the same as before, it means an irrelevant property (from a preaccess checklist standpoint) got updated in userData or platformConfigs
					//in which case we want to do nothing.
					
					//if however, it is not the same...
					if(!isEqual(preAccessChecklist, updatedPreAccessChecklist)){
						//if it is now complete then redirect to initial route or home
						if(!toRedirInfo){ 
							// console.log('history push', location.state?.from || '/')
							history.push(location.state?.from || '/')
						}else{ 
							history.push({
								...toRedirInfo.redirectRoute, // no state.from in here, since this will fire only once user is already in the checklist flow
							}) //else redir to next incomplete
						}
						setPreAccessChecklist(updatedPreAccessChecklist)
					}else{
						// console.log('is equal as before, so do nothing')
					}
					
				}
			} 
			
		//this is a logout. so reset the checklist
		}else if(!userData && preAccessChecklist){
			// console.log('this is a logout. so reset the checklist')
			setPreAccessChecklist(undefined)
		}
	},[userData, platformConfigs])

	const isFirstMount = useRef(true);

	useEffect(() => {
		if(userData && !isFirstMount.current){ //cuz on first mount the [userData, platformConfigs] useEffect runs. if this is also allowed to run, then the redir state.from gets overwritten bu this path. which is wrong.
			//gets the first incomplete item in checklist
			let toRedirInfo = preAccessChecklist?.find(d => !d.complete)

			if(
				toRedirInfo && 
				toRedirInfo.redirectRoute !== location.pathname+location.search
			){
				// console.log('redir to appr. checklist screen')
				// console.log({stateWillBeSet:getFromRoute({location, redirectRoute: toRedirInfo.redirectRoute}) })
				history.push({
					...toRedirInfo.redirectRoute,
					state: {
						...(toRedirInfo.redirectRoute.state || {}),
						from: getFromRoute({location, redirectRoute: toRedirInfo.redirectRoute})
					}
				})

			}
		}
		
	},[location.pathname+location.search])

	useEffect(() => isFirstMount.current = false,[])

	return (
		<PreAccessChecklistContext.Provider
			value={{
				preAccessChecklist
			}}>
			{children}
		</PreAccessChecklistContext.Provider>
	)
}

export const usePreAccessChecklistContext = () => {
	return useContext(PreAccessChecklistContext)
}
