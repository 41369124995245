import { colors, SANS_3 } from "oolib";
import styled from "styled-components";


export const StyledPageCover = styled.div`
    background-color: ${colors.greyColor100};
    /* min-height: 20rem; */
    padding: 4rem;
`

export const StyledPageCoverContentWrapper1 = styled.div`
    height: 100%;
      text-align: center;
`

export const StyledPageCoverContentWrapper2 = styled.div`
    max-width: 60rem;
        color: $white;
        text-align: center;
        margin: 0 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
`

//subtitle
export const STYLED_SANS_3 = styled(SANS_3)`
padding-top: 1rem;
color: ${colors.greyColor40};
`

export const StyledCTA = styled.div`
 margin-top: 2rem;  
`
