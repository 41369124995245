import { motion } from "framer-motion";
import { SANS_2, icons } from "oolib";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { usePlatformBuilderContext } from "../../../../../../../contexts/platformBuilderContext";
import { __CheckAccessViaConfig } from "../../../../../../../utils/accessControl";
import { useGetQueryData } from "../../../../../../../utils/react-query-hooks/general";
import { DropdownSingle } from "oolib";
import { useIsThisLinkActive } from "../../utils/useIsThisLinkActive";
import { StyledCustomSelect } from "./styled";
import { RotatingCaret } from "../../../../../../generalUI/RotatingCaret";
const { CaretDown } = icons;



export const PRIMMenu = ({
  options: _options, // can be a location obj or a string
  display,
  id,
  builderProps, //if exists means header is being rendered in NavBuilder
}) => {
  const { user } = useGetQueryData("userData") || {};
  const { platformBuilderMode } = usePlatformBuilderContext();
  //access control
  const options = _options.filter((op) => {
    return (
      op.dontRender !== true && //dontRender will be true if in genLinksConfigsAsOptions > checkAccessAndGenContentPageLinkProps, the access is denied
      (!op.accessControl || //allow if check doesnt exist
        __CheckAccessViaConfig({ ...op.accessControl, user })) //OR allow if check says its allowed
    );
  });

  const { getActiveMenuOption } = useIsThisLinkActive();

  const history = useHistory();

  const handleChange = (k, v) => {
    !platformBuilderMode && history.push(v.to);
  };
  const activeMenuOption = getActiveMenuOption({ options });

  const [showOptions, setShowOptions] = useState(undefined);

  if (!builderProps && options.length === 0) return null;

  return (
    <div style={{ height: "100%" }} id={id}>
      <DropdownSingle
        id={id}
        disableSelectTextUpdate
        value={activeMenuOption}
        options={options}
        onChange={(key, value) => handleChange(id, value)}
        optionsClassName={"SANS_2"}
        broadcastShowOptions={(showOptions) => setShowOptions(showOptions)}
        genCustomSelectComp={(onClick) => {
          return (
            <StyledCustomSelect isActive={!!activeMenuOption} onClick={onClick}>
              <SANS_2>{display}</SANS_2>
              <RotatingCaret position={showOptions ? 'up' : 'down'}/>
            </StyledCustomSelect>
          );
        }}
      />
    </div>
  );
};
