import { injectHttps } from "oolib";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { isURLRegexTest } from "./isURLRegexTest";

export const useGetEmbedTypeFromUrl = (urlArg) => {
  
  const [embedType, setEmbedType] = useState(undefined);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if(!urlArg || !isURLRegexTest(urlArg)){ 
      setLoading(false)
      setEmbedType(false)
    }else{
      //so we have a valid url. lets get its embed type
      setLoading(true);
      getEmbedTypeFromUrl(
        urlArg,
        (embedType) => {
          setEmbedType(embedType)
          setLoading(false)
        }
      )
    }
  },[urlArg])

  return ({embedType, loading})
}

const getEmbedTypeFromUrl = async (urlArg, cb) => {
  let url;
  if(urlArg.startsWith('<') && urlArg.endsWith('>')) url = urlArg // Don't create URL Object if URL contains HTML tags
  else url = new URL(injectHttps(urlArg));
  console.log({ urlHost: url.host, host: window.location.host });
  try {
    let isImage = await checkImage(url);
    if (isImage) cb("image");
    else if (urlArg.includes('docs.google.com/presentation') || urlArg.includes('h5p') || urlArg.includes('iframe')) cb('ppt')
    else if (ReactPlayer.canPlay(url)) cb('video')
    else if (url.host === window.location.host) cb("internal-link");
    else cb("external-link");
  } catch (err) {
    cb("external-link");
  }
};

const checkImage = (url) =>
  new Promise((resolve) => {
    var image = new Image();
    image.onload = function () {
      if (this.width > 0) resolve(true);
    };
    image.onerror = function () {
      resolve(false);
    };
    image.src = url;
  });

