


export const RadioList = ({block, value}) => {
    if(!value) return undefined;
    if(block.props.options.every(op => !op.isCorrect)) return undefined;
    return value.isCorrect ? 'IS_CORRECT' : 'IS_WRONG'
}

//--

export const CheckboxList  = ({block, value}) => {
    /**
     * can be
     * EVERY_CORRECT : all correct
     * SOME_CORRECT : some correct, some more correct not selected
     * SOME_CORRECT_SOME_WRONG : some correct, some wrong
     * EVERY_WRONG : all wrong
     * 
     */
     if(!value || value.length === 0) return undefined;
    if(block.props.options.every(op => !op.isCorrect)) return undefined;

    let correctValueLen = value.filter(v => v.isCorrect).length;
    let correctOptionsLen = block.props.options.filter(op => op.isCorrect).length

    let someCorrect = value.some(v => v.isCorrect)
    let someWrong = value.some(v => !v.isCorrect)

    switch(true){
        case correctValueLen === 0:
            return 'EVERY_WRONG'
        case correctValueLen === correctOptionsLen && !someWrong:
            return 'EVERY_CORRECT'
        case correctValueLen !== 0 && correctValueLen !== correctOptionsLen && !someWrong:
            return 'SOME_CORRECT'
        case someCorrect && someWrong:
        default:
            return 'SOME_CORRECT_SOME_WRONG'
    }
    
}

