import React from "react";
import { DecoratorNode } from "lexical";
import { nanoid } from "nanoid";
import { IFrameInputNodeComp } from "./Comp";

export class IFrameInputNode extends DecoratorNode {

  static getType() {
    return "IFrameInputNode";
  }

  static clone(node) {
    return new IFrameInputNode({
      value: node.__value,
      id: node.__id,
      key: node.__key,
    });
  }

  static importJSON(serializedNode) {
    return $createIFrameInputNode({
      value: serializedNode.value,
      id: serializedNode.id,
    });
  }

  exportJSON() {
    return {
      type: this.constructor.getType(),
      value: this.__value,
      id: this.__id,
    };
  }

  constructor({ value, id, key }) {
    super(key);
    this.__value = value;
    this.__id = id || nanoid(6);
  }

  createDOM() {
    return document.createElement("div");
  }

  updateDOM() {
    return false;
  }

  onChange(editor, value) {
    editor.update(() => {
      const self = this.getWritable();
      self.__value = value;
    });
  }

  onRemove(editor) {
    editor.update(() => {
      this.remove();
    });
  }

  decorate(editor, editorConfig) {
    return (
      <IFrameInputNodeComp
        nodeKey={this.__key}
        id={this.__id}
        value={this.__value}
        onChange={(k, v) => this.onChange(editor, v)}
        onRemove={() => this.onRemove(editor)}
        readOnly={editorConfig.theme.RTE_COMP_PROPS.readOnly}
      />
    );
  }

  isInline(){
    return false
  }

}

export function $createIFrameInputNode({ value, id, key }) {
  return new IFrameInputNode({ value, id, key });
}

export function $isIFrameInputNode(node) {
  return node instanceof IFrameInputNode;
}
