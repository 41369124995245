import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React from "react";
import { ToolbarButton } from "../comps/ToolbarButton";
import { useCheckRangeSelectionExists } from "../utils/useCheckRangeSelectionExists";
import { checkIsActive } from "./checkIsActive";


import { SHOW_ANNO_LIGHTBOX_COMMAND } from '../../AnnoPlugin';

const config = {
  icon: "HighlighterCircle",
};

const handleClick = ({editor}) => {
  editor.dispatchCommand(SHOW_ANNO_LIGHTBOX_COMMAND, undefined);
}


export const AnnoTool = () => {
  const [editor] = useLexicalComposerContext();
  const selectionExists = useCheckRangeSelectionExists();

  return (
    <>
      <ToolbarButton
        isDisabled={selectionExists === false}
        isActive={checkIsActive({ editor })}
        onClick={() => selectionExists && handleClick({editor})}
        icon={config.icon}
      />
    </>
  );
};
