import { EditorState } from 'draft-js';
import { replaceText } from 'draft-js/lib/DraftModifier';

const autoCapitalize =({char, editorState, handleChange, selectionState,})=> {
    
	const newContentState = replaceText(
		editorState.getCurrentContent(),
		selectionState,
		char.toUpperCase(),
		editorState.getCurrentInlineStyle()
	)
	const newEditorState = EditorState.push(
		editorState,
		newContentState,
		'insert-characters'
	)
	handleChange(newEditorState)
}

export default autoCapitalize;