import { useEffect, useState } from "react";
import { useGetAllUsersLazy } from "../../../../../../../utils/react-query-hooks/users/useGetAllUsersLazy";

const dummyLookupService = {
    search(string, data, callback) {
      setTimeout(() => {
        const results = data.filter((mention) =>
          mention.name.toLowerCase().includes(string.toLowerCase())
        );
        callback(results);
      }, 500);
    },
  };

export function useMentionLookupService(mentionString) {
    const mentionsCache = new Map();

    const [results, setResults] = useState([]);
  
    const {data: allUsers, status: allUsersLoadStatus} = useGetAllUsersLazy({
      limit: Infinity,
      projection: {
          _id: 1,
          name: 1
      }
  })
  
    useEffect(() => {
      const cachedResults = mentionsCache.get(mentionString);
  
      if (mentionString == null) {
        setResults([]);
        return;
      }
  
      if (cachedResults === null) {
        return;
      } else if (cachedResults !== undefined) {
        setResults(cachedResults);
        return;
      }
  
      mentionsCache.set(mentionString, null);
     
      dummyLookupService.search(
              mentionString, // string
              allUsersLoadStatus === 'loading' 
                  ? ['loading...'] 
                  : allUsersLoadStatus === 'success' 
                  && allUsers.pages[0].data.map(d => ({ name:d.name, id:d._id })), //data
             (newResults) => { // callback
                  mentionsCache.set(mentionString, newResults);
                  setResults(newResults);
             }
          );

    }, [mentionString]);

    return results;
  }