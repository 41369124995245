import { getBreakPoint, icons, useScreenWidth } from "oolib";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { __CheckAccessToPrivatePlatform } from "../../../../../../utils/accessControl";

import { useOverflowItems } from "../../../../../../utils/customHooks/useOverflowItems";
import {
  useGetQueryData
} from "../../../../../../utils/react-query-hooks/general";
import { DropdownSingle } from "oolib";
import { SECHeaderRouteWhitelist } from "../../../../config";
import { SECLink } from "../comps/SECLink";
import { useIsThisLinkActive } from "../utils/useIsThisLinkActive";

import { genLinksConfig } from "./utils/genLinksConfig";
const { DotsThree } = icons;
export const SECNavbar = ({ _Nav, builderProps }) => {
  
  const { getActiveMenuOption } = useIsThisLinkActive();
  const {_Theme} = useGetQueryData('platformConfigs')
  const {user} = useGetQueryData('userData') || {}

  const history = useHistory();
  const location = useLocation()
  
  const screenWidth = useScreenWidth();
  const LG = screenWidth >= getBreakPoint("lg");

  const {
    storeItemRef,
    containerRef,
    itemOptions,
    overflowOptions
  } = useOverflowItems({
    initItemsConfig: genLinksConfig(_Nav),
    offsetAvailableSpace: -120 -40 //padding left right + kebab menu btn
  })

  // console.log({itemOptions})

  const genLinks = () => {
    return itemOptions.map((item) => {
      return (
        <div 
          ref={el => storeItemRef(el, item)}
          key={item.value}
          style={{flexShrink: 0, display: item.hide ? 'none' : 'flex'}}
          >
          <SECLink {...item} key={item.value} />
        </div>
      );
    });
  };

  const genKebabMenu = () => {
    return overflowOptions.length > 0 && (
      <DropdownSingle
        id="primaryLinksKebabMenu"
        className="OKE-Header__primaryLinksKebabMenu"
        genCustomSelectComp={() => (
          <DotsThree size={24} weight="light" color={_Theme.colors.primaryColorText} />
        )}
        value={getActiveMenuOption({ options: overflowOptions })}
        options={overflowOptions}
        onChange={(k, v) => {
          history.push(v.to);
        }}
        optionsAnchor="right"
      />
    );
  };

  /**
   * the .reduce inside itemOptions checks for accessibility at the time of
   * config gen itself. so if no links are accessible, the SECNav won't render at all.
   */
  if (
    itemOptions.length === 0 ||
    !__CheckAccessToPrivatePlatform(user) ||
    !LG ||
    (
      !SECHeaderRouteWhitelist.some((d) => location.pathname.includes(d)) &&
      location.pathname !== "/" /* homepage */ &&
      !!builderProps !== true /** is builder mode in superadmin tools */
    )
  ) return null;

  return (
    <div ref={containerRef} className="OKE-Header2">
      <div  className="OKE-Header2__primaryLinks-wrapper">
        {genLinks()}
        {genKebabMenu()}
      </div>
    </div>
  );
};
