import styled from "styled-components/macro";

import { colors } from "oolib";

const { greyColor40} = colors;

export const StyledShell = styled.div`
  border: ${({readOnlyProp }) => !readOnlyProp  && `1px solid ${greyColor40}` };
  width: 100%;
  margin: auto;
  /* padding: 1rem; */
  padding: ${({readOnlyProp }) => !readOnlyProp  && `1rem`};
  
  & > * {width: 100%;}

  
`;


export const StyledShellHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 1rem;
`;

