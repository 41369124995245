// to be moved in the future .. being here is not optimal (oolib wise)
// this file contain all coachmarks data for all comps using it
import phoneTilt from '../../images/phone-tilt.png';
import expandArrows from '../../images/expand-arrows.png';

const coachingList = {
  H5PEmbed : [
    {
      element: 'set inside comp',
      mark: {
        headline: "Rotate your device",
        text: "Enhance accesibility by rotating device to landscape mode",
        image: phoneTilt,
      }
    },
    {
      element: 'set inside comp',
      mark: {
        headline: "Expand screen",
        text: "Click on the expand button to access the presentation",
        image: expandArrows,
        position: 'bottom right',
        arrow: true,
      }
    }
  ],
  AnalysisDashboard : [
    {
      element: 'set inside comp',
      mark: {
        headline: "Set Country",
        text: "Select All to see data across countries or select any one country to see it's data",
        position: "bottom",
        arrow: true
      }
    },
    {
      element: 'set inside comp',
      mark: {
        headline: "Set Research Areas",
        text: "Select the research area from here",
        position: "bottom",
        arrow: true
      }
    },
    {
      element: 'set inside comp',
      mark: {
        headline: "Set Participant Type ",
        text: "Select the participant type whose data you want to see",
        position: "bottom",
        arrow: true
      }
    }
  ]
}


export default coachingList;