import { createAtomicBlockEntity } from "../../../utils";



 export const createAtomicBlockForEmbedType = ({editorState, handleChange, embedType, urlString}) => {
      
      if(!embedType) return undefined;
      
      switch(embedType){
        case 'image':
          return createAtomicBlockEntity(editorState, handleChange, 'RTEImageInput', {
            value: [{ publicUrl: urlString}]
          });

        case 'video':
          return createAtomicBlockEntity(editorState, handleChange, 'RTEVideoInput', {
            value: [{publicUrl: urlString}]
          })

        case 'ppt':
          return createAtomicBlockEntity(editorState, handleChange, 'RTEIFrameInput', {
            value: urlString
        })

        case 'internal-link':
          return createAtomicBlockEntity(editorState, handleChange, "RTEInternalEmbed", {
            value: urlString
          });
        
        case 'external-link':
          return createAtomicBlockEntity(editorState, handleChange, "RTEExternalEmbed", {
            value: urlString
          });

        default:
          return; 
      }
      
    }
