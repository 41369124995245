import { ButtonSecondary, ModalSmall, SANS_3, TextInput } from 'oolib'
import React, { useEffect, useState } from 'react'
import { copyToClipboard } from '../../../utils/general'
import { useGetQueryData } from '../../../utils/react-query-hooks/general'


const KPShareActions = (props) => {
	

	const [showShareBtn, setShowShareBtn] = useState(false)

	let prevScroll = window.scrollY
	let scrollThreshold = 10
	let bottomThreshold = 10

	const {
		deployment: { _DeploymentDisplayName },
	} = useGetQueryData('platformConfigs')

	useEffect(() => {
		if (document.body.offsetHeight < window.innerHeight) {
			//if page content is shorter than screen height
			setShowShareBtn(true)
		}

		const handleScroll = (e) => {
			const window = e.currentTarget

			if (prevScroll - window.scrollY >= scrollThreshold) {
				setShowShareBtn(true)
				prevScroll = window.scrollY
			} else if (window.scrollY - prevScroll >= scrollThreshold) {
				setShowShareBtn(false)
				prevScroll = window.scrollY
			}

			if (
				window.innerHeight + window.scrollY >=
				document.body.offsetHeight - bottomThreshold
			) {
				setShowShareBtn(true)
			}
		}

		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	const getLinkWhastapp = (message) => {
		var url =
			'https://api.whatsapp.com/send?text=' + encodeURIComponent(message)
		return url
	}

	const getLinkFb = (message) => {
		var url =
			'https://www.facebook.com/sharer/sharer.php?u=' +
			encodeURIComponent(message)
		return url
	}

	const getLinkTwitter = (message) => {
		var url = 'https://twitter.com/share?url=' + encodeURIComponent(message)
		return url
	}

	const getLinkLinkedin = (message) => {
		var url =
			'https://www.linkedin.com/shareArticle?mini=true&url=' +
			encodeURIComponent(message)
		return url
	}


	const resetShareActions = () => {
		props.setShortUrl(undefined)
	}

	return (
		<div className='kp-share-actions-wrapper'>
			{ 
				<ModalSmall title='Share' onClose={() => resetShareActions()}>
					<div>
						<SANS_3 style={{paddingBottom: '1rem'}}>{'Copy Link'}</SANS_3>
						<TextInput
							restrictValUpdate={true}
							type='text'
							size='small'
							value={props.shortUrl && props.shortUrl}
							id='share_popup__link_text'
							actionBtn={{
								onClick: () => copyToClipboard('share_popup__link_text'),
								text: 'Copy',
							}}
							/>
						
						<SANS_3 style={{padding: '1rem 0'}}>{'or'}</SANS_3>
						<div style={{display: 'flex'}}>
						<ButtonSecondary
								onClick={() =>
									window.open(
										getLinkWhastapp(
											`Read this piece from ${_DeploymentDisplayName} :  ${props.shortUrl}`,
										),
										'_blank',
									)
								}
								icon='WhatsappLogo'
								className='ShareActions__socialBtn'></ButtonSecondary>
							<ButtonSecondary
								onClick={() =>
									window.open(getLinkTwitter(`${props.shortUrl}`), '_blank')
								}
								icon='TwitterLogo'
								className='ShareActions__socialBtn'></ButtonSecondary>
							<ButtonSecondary
								onClick={() =>
									window.open(getLinkFb(`${props.shortUrl}`), '_blank')
								}
								icon='FacebookLogo'
								className='ShareActions__socialBtn'>
							</ButtonSecondary>
							<ButtonSecondary
								onClick={() =>
									window.open(getLinkLinkedin(`${props.shortUrl}`), '_blank')
								}
								icon='LinkedinLogo'
								className='ShareActions__socialBtn'>
							</ButtonSecondary>
						</div>
					</div>
				</ModalSmall>
			}
		</div>
	)
}

export default KPShareActions
