import React from "react";
import { SANS_2 } from 'oolib'

export const StatusTag = (props) => {
  //this passes a props.status.type which gives success | failed | inactive , but we are not using it right now

  const statusTypeMap = props.statusTypeMap || {
    neutral: null,
    success: true,
    danger: false,
  };

  const getStatusType = () => {
    let type = "";
    
    const getValueString = () => typeof props.value === 'string' ? props.value : props.value?.value;

    Object.values(statusTypeMap).map((conf, i) => {
      if(Array.isArray(conf)){
        if(conf.includes(getValueString())){
          type = Object.keys(statusTypeMap)[i];
        }
      }else{ //else it is string | boolean | null
        if(conf === getValueString()){
          type = Object.keys(statusTypeMap)[i];
        }
      }
    });
    
    return type || 'neutral';
  };

  return (
    <div
      className={`statusTag-wrapper ${getStatusType()} ${props.className}`}
      style={props.style}
      >
      {props.value && (
        <SANS_2 className="statusTag" style={{ fontWeight: "normal" }}>
          {props.value.display || props.value}
        </SANS_2>
      )}
    </div>
  );
};

export const StatusTagOutlineStyle = (props) => {
  return <StatusTag {...props} className="statusTag-wrapper--outlineStyle" />;
};
