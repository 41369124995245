import axios from "axios"
import { useQuery } from "react-query"

export const useGetThisContentTypeAnalyticsData = ({id, contentType, enabled}) => {
  

    return useQuery(
      ['analyticsData', contentType, id],
      () => axios.get(`/api/content/getThisContentTypeAnalyticsData/${contentType}/${id}`).then(res => res.data),
      {enabled}
    )
  }