import { useHandleClickOutside } from "oolib";
import React, { useState, useRef } from "react";
import { useGetQueryData } from "../../../../../../../utils/react-query-hooks/general";
import { useUpdateNotifIsRead } from "../../../../../../../utils/react-query-hooks/notifications";
import OkeBell from "../../../../../../icons/New/OkeBell";
import { NotificationBox } from "./comps";
import { NotificationButtonStyled } from "./styled";

// const user = {
//     notifications: [
//       {
//         kp_msg_desc:
//           "Your story “Indias Daughter” Has been posted in the story section",
//         kp_isRead: false,
//         kp_link: "/published-page/caseReports?id=6172993e13cd9d000ac24b48",
//         kp_date: new Date("2022-08-25T06:20:25.238+00:00"),
//       },
//       {
//         kp_msg_desc:
//           "Your story “Indias Daughter” Has been posted in the story section",
//         kp_isRead: true,
//         kp_link: "/published-page/caseReports?id=6172993e13cd9d000ac24b48",
//         kp_date: new Date("2022-08-25T06:20:25.238+00:00"),
//       },
//       {
//         kp_msg_desc:
//           "Your story “Indias Daughter” Has been posted in the story section",
//         kp_isRead: false,
//         kp_link: "/published-page/caseReports?id=6172993e13cd9d000ac24b48",
//         kp_date: new Date("2022-08-24T06:20:25.238+00:00"),
//       },
//       {
//         kp_msg_desc:
//           "Your story “Indias Daughter” Has been posted in the story section",
//         kp_isRead: true,
//         kp_link: "/published-page/caseReports?id=6172993e13cd9d000ac24b48",
//         kp_date: new Date("2022-08-24T06:20:25.238+00:00"),
//       },
//       {
//         kp_msg_desc:
//           "Your story “Indias Daughter” Has been posted in the story section",
//         kp_isRead: false,
//         kp_link: "/published-page/caseReports?id=6172993e13cd9d000ac24b48",
//         kp_date: new Date("2022-08-23T06:20:25.238+00:00"),
//       },
//       {
//         kp_msg_desc:
//           "Your story “Indias Daughter” Has been posted in the story section",
//         kp_isRead: true,
//         kp_link: "/published-page/caseReports?id=6172993e13cd9d000ac24b48",
//         kp_date: new Date("2022-08-23T06:20:25.238+00:00"),
//       },
//       {
//         kp_msg_desc:
//           "Your story “Indias Daughter” Has been posted in the story section",
//         kp_isRead: true,
//         kp_link: "/published-page/caseReports?id=6172993e13cd9d000ac24b48",
//         kp_date: new Date("2022-07-23T06:20:25.238+00:00"),
//       },
//     ],
//   };

export default function MyNotifications() {
  const { user } = useGetQueryData("userData") || {};

  const updateNotifIsRead = useUpdateNotifIsRead();
  const [open, setOpen] = useState(false);
  const notifDdRef = useRef(null);

  useHandleClickOutside(notifDdRef, setOpen);

  const getTotUnreadNotifs = (notifData) => {
    return notifData.filter((d) => d.kp_isRead === false).length;
  };

  const handleOnClick = () => {
    if (
      !open &&
      user &&
      user.notifications.some((d) => d.kp_isRead === false)
    ) {
      updateNotifIsRead.mutate({ userId: user._id });
    }

    setOpen((prev) => !prev);
  };

  return (
    <div ref={notifDdRef} style={{ marginLeft: "1rem", position: "relative" }}>
      <NotificationButtonStyled onClick={handleOnClick} open={open}>
        <OkeBell
          size={24}
          weight={"light"}
          number={user && getTotUnreadNotifs(user.notifications)}
        />
      </NotificationButtonStyled>

      {open && (
        <NotificationBox
          data={user && user.notifications}
          onClick={() => setOpen(false)}
        />
      )}
    </div>
  );
}
