import React, { createContext, useContext, useState } from "react";

// import { useUploadMedia } from "../../../utils/react-query-hooks/media";

export const RTEContext = createContext();

export const RTEContextProvider = ({
  children,
  enableConductor,
  id,
  invert,
  isRequired,
  onChange,
  placeholder,
  readOnlyProp,
  typo,
  textAlignment,
  value,
  charLimit,
  placeholderColor,
  disableNewline,
  variant,
  annotation,
  forceValue,
  style
}) => {
  const [showLinkOrEmbedModal, setShowLinkOrEmbedModal] = useState(undefined);
  const [someTextIsSelected, setSomeTextIsSelected] = useState(false);
  const [showConvertToEmbedLightbox, setShowConvertToEmbedLightbox] = useState(undefined)

  const [showAnnoLightbox, setShowAnnoLightbox] = useState(undefined)
  const onAnnoClick = data => {
    setShowAnnoLightbox({
      position: data.position,
      data: data.data
    })
  }

  const [retainAnnoOnPaste, setRetainAnnoOnPaste] = useState(false)

  return (
    <RTEContext.Provider
      value={{
        enableConductor,
        id,
        invert,
        isRequired,
        onChange,
        placeholder,
        readOnlyProp,
        typo,
        textAlignment,
        value,
        charLimit,
        placeholderColor,
        disableNewline,
        variant,
        annotation,
        forceValue,
        style,
        
        onAnnoClick,
        showAnnoLightbox,
        setShowAnnoLightbox,

        showLinkOrEmbedModal,
        setShowLinkOrEmbedModal,
        someTextIsSelected, 
        setSomeTextIsSelected,
        showConvertToEmbedLightbox, 
        setShowConvertToEmbedLightbox,

        retainAnnoOnPaste, 
        setRetainAnnoOnPaste
      }}
    >
      {children}
    </RTEContext.Provider>
  );
};

export const useRTEContext = () => {
  return useContext(RTEContext);
};
