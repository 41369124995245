
export const _handleRepositionBlock = ({ 
    value, 
    valuePath,
    HANDLE_UPDATE_TPL_CONFIG 
}) => {
    HANDLE_UPDATE_TPL_CONFIG({
      valuePath,
      value,
    });
  };