import styled from 'styled-components';




export const StyledLegend = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
    margin-bottom: -1rem;

`