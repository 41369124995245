import { queryClient } from "../../../../..";
import { extractAllBlocksFromTpl } from "../../../../../utils/templating/extractAllBlocksFromTpl";

/**
 * 
 * @param {*} param0 
 * @description how the filterOptionsBy config works:
    it expects one property -> filterId.
    from this filterId we get the 'selected filter values' against this filterId.
    what it does is, it filters down the options of THIS FILTER to only those options that are 
    linked via either tags or taggedResources to the 'selected filter values'.
    an example to understand this:
    if a states and districts filter exists, and in states filter i have selected maharastra,
    then in the districts filter, only the maharashtra districts will show.
 */
    export const convertFilterOptionsByConfIntoFindQuery = ({
        filterOptionsBy,
        activeFilters,
        thisFilterConfig,
      }) => {
        
        if(!activeFilters) return {} //abort

        const { filterId } = filterOptionsBy;
        const masterFilter = activeFilters.find((f) => f.filterId === filterId); //i.e the filter whose value will trim down the options of THIS filter
        
        if(!masterFilter) return {} //abort

        switch (true) {
          case masterFilter.source?.filterType === "tagType":
          case masterFilter.target.filterType === "tagType": //this is for backward compat, cuz previously we wouldnt pass the source to activeFilters
            const masterContentType =
              masterFilter.source?.tagType || masterFilter.target.tagType;
            const masterTpl = queryClient.getQueryData(["tpl", masterContentType]);
            const masterTplBlocks = extractAllBlocksFromTpl({ tpl: masterTpl });
            //if the masterTplBlocks contains a thisFilterConfig.source.tagType as a tags field,
            //then the findQuery on thisFilter will be taggedResources.<masterCT>.data bla bla
            //else, we assume that thisFilter's tpl has a tags.<masterCT> input on it. in which case
            //the findQuery on thisFilter will be tags.<masterCT> blabla
            return masterTplBlocks.some(
              (block) => block.valuePath === `tags.${thisFilterConfig.source.tagType}`
            )
              ? {
                  [`taggedResources.${masterContentType}.data._id`]: {
                    $in: masterFilter.values.map((v) => v.value),
                  },
                }
              : {
                  [`tags.${masterContentType}.data._id`]: {
                    $in: masterFilter.values.map((v) => v.value),
                  },
                };
      
          default:
            return {};
        }
      };