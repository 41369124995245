import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import InputTagTypes from "./comps/Input";
import DisplayTagTypes from "./comps/Display";

const StyledWrapper = styled.div`
  position: absolute;
  ${({ mode }) =>
    !mode &&
    css`
      transform: translateY(-100%);
    `}

  ${({ y }) =>
    y &&
    css`
      top: ${y}px;
    `}
  ${({ x }) =>
    x &&
    css`
      left: ${x}px;
    `}
`;




const Lightbox = ({
  data: annoData,
  onRemove,
  onEdit,
  editorWrapperRef,
  config,
  position,
  specialProps,
  onCancel,
  onCreate,
  readOnly,
}) => {
  const { isEndToEndRes } = specialProps;


  const DEFAULT_INITIAL_STATE = {
    data: {},
    targetFragmentkey: "",
    mode: "",
  };

  const intialLocalState = annoData || isEndToEndRes || DEFAULT_INITIAL_STATE;

  const [localData, setLocalData] = useState(intialLocalState);


  const ContainerRef = useRef();

  const handleChange = (tagType, v) => {
    if (v?.data.length) {
      setLocalData((prev) => {
        prev.data[tagType] = v;
        return { ...prev };
      });
    } else {
      setLocalData((prev) => {
        delete prev.data[tagType];
        return { ...prev };
      });
    }
  };

  return (
    <div
      style={{ position: "relative", zIndex: "101" }}
      ref={ContainerRef}
    >
      <StyledWrapper {...position} mode={localData.mode}>
        {localData.mode === "display" ? (
          <DisplayTagTypes
            data={localData.data}
            onRemove={() => onRemove(localData)}
            onEdit={() => {
              setLocalData((prev) => ({ ...prev, mode: "edit" }));
            }}
            readOnly={readOnly}
          />
        ) : (
          // using this id to get the light box container element, to fix the lightbox hiding when there's no scroll up space
          <div id="input-light-box">
            <InputTagTypes
              tagTypesConfig={config.tagTypesConfig || []}
              toggleTagTypes={config.toggleTagTypesConfig}
              onChange={(tagType, v) => handleChange(tagType, v)}
              value={localData.data}
              handleOnConfirm={() =>
                localData.mode === "edit"
                  ? onEdit(localData)
                  : onCreate(localData)
              }
              handleOnCancel={onCancel}
            />
          </div>
        )}
      </StyledWrapper>
    </div>
  );
};

export default Lightbox;
