import { queryClient } from "../../../..";




export const getProperTitleFromContentTypeId = (contentTypeId ) => {
    const allTpls = queryClient.getQueryData('allTpls')
    const thisTpl = allTpls.find((d) => d.kp_content_type === contentTypeId);
    if (!thisTpl || !thisTpl.general?.content?.title) return contentTypeId;
    //else
    return thisTpl.general.content.title;
  };