/** copy pasted as is from : https://codesandbox.io/s/lexical-rich-text-example-5tncvy?file=/src/plugins/CodeHighlightPlugin.js:0-371 */

import { registerCodeHighlighting } from "@lexical/code";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

export default function CodeHighlightPlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return registerCodeHighlighting(editor);
  }, [editor]);
  return null;
}
