import d3 from '../../../importGroups/d3Modules'
import { getVal } from 'oolib';

export const genResponsiveSvgCanvas = ({
    margin,
    width,
    height,
    wrapperId,
    translate
}) => d3
.select("#" + wrapperId)
.append("svg")
// Responsive SVG needs these 2 attributes and no width and height attr.
.attr("preserveAspectRatio", "xMinYMin meet")
.attr(
  "viewBox",
  `0 0 ${width + margin.left + margin.right} ${
    height + margin.top + margin.bottom
  }`
)
.append("g")
.classed("vizCanvas", true)
.attr("transform", `translate(${translate || `${width/2},${height/2}`})`)
.attr("font-family", "'noto_sans', 'noto_sans_devanagari', 'noto_sans_gu','noto_sans_te', 'noto_sans_kn', 'noto_sans_or', sans-serif")


const getOnlyNumbersAry = ({data, valuePath}) => data.map((d) => parseInt(getVal(d, valuePath)));

export const getMinMax = ({ data, valuePath }) => {
  let onlyNumbers = getOnlyNumbersAry({data, valuePath})
  return [Math.min(...onlyNumbers), Math.max(...onlyNumbers)];
};

export const getMax = ({ data, valuePath }) => {
  let onlyNumbers = getOnlyNumbersAry({data, valuePath})
  return Math.max(...onlyNumbers)
};
export const getMin = ({ data, valuePath }) => {
  let onlyNumbers = getOnlyNumbersAry({data, valuePath})
  return Math.min(...onlyNumbers);
};

export const calcSvgElemScaleFactor = ({
  setWidth,
  actualWidth,
  setValueToScale,
}) => {
  let scaleFactor = actualWidth / setWidth;
  return setValueToScale * (1 / scaleFactor);
};