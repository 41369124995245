import React from "react";
import { Divider, ModalSmall, OKELink, SANS_2, SANS_3 } from "oolib";
import { AIMessageStyled } from "./styled";
import { useState } from "react";
import Markdown from "react-markdown";
import { Copy } from "../UserActions/Copy";
import { Feedback } from "../UserActions/Feedback";
import { __GetContentTypeConfigNew } from "../../../../utils/getters/gettersV2";
import { SummaryWrapperStyled } from "../../../Synthesis/comps/AnnotationCard/styled";

export const AIMessage = ({ message, streaming = false }) => {
  return (
    <AIMessageStyled key={message.id}>
      <SANS_3>
        <Markdown>{streaming ? message : message.content.value}</Markdown>
      </SANS_3>

      {message.vectorSearchInfo?.chunks?.length > 0 ? (
        <>
          {" "}
          <Divider />
          <Chunks chunks={message.vectorSearchInfo?.chunks.slice(0, 3)} />
        </>
      ) : null}

      {streaming ? null : (
        <>
          {" "}
          <Divider />
          <Actionables message={message} />
        </>
      )}
    </AIMessageStyled>
  );
};

const Actionables = ({ message }) => {
  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <Copy color="#e5e5e5" />
      {/* <LineVertical size={30} /> */}
      <Feedback
        reaction={message.userFeedback?.reaction}
        comments={message.userFeedback?.comments}
        messageId={message._id}
      />
    </div>
  );
};

const Chunks = ({ chunks }) => {
  const [modalContent, setModalContent] = useState("");
  return (
    <>
      <div style={{ padding: "1rem" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <SANS_2>TOP REFERENCES</SANS_2>{" "}
          <SANS_2 color="#e5e5e5">View all</SANS_2>
        </div>
        <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
          {chunks.map((chunk) => (
            <div
              style={{
                backgroundColor: "#F3F3F3",
                borderRadius: "6px",
                padding: "1rem",
                flex: 1,
                cursor: "pointer",
              }}
              onClick={() => setModalContent(chunk)}
            >
              <SummaryWrapperStyled noOfLines={5}>
                <SANS_2>{chunk.text}</SANS_2>
              </SummaryWrapperStyled>
            </div>
          ))}
        </div>
      </div>

      {modalContent ? (
        <ModalSmall
          onClose={() => setModalContent("")}
          title={
            __GetContentTypeConfigNew(modalContent.metaData.contentType).general
              .content.title
          }
        >
          <div
            style={{
              padding: "0 2rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <SANS_3>
              <Markdown>{modalContent.text}</Markdown>
            </SANS_3>
            <OKELink
              link={`/published-page/${modalContent.metaData.contentType}?id=${modalContent.metaData.parentDoc}`}
              style={{ width: "fit-content" }}
            >
              <SANS_2>Go to source</SANS_2>{" "}
            </OKELink>
          </div>
        </ModalSmall>
      ) : null}
    </>
  );
};
