import React from 'react'
import { ButtonGhost } from 'oolib';

import { Container, Section } from 'oolib'
import {  WrapperAbsoluteHeaderButton, WrapperPageTitleCenter, } from '../../components/layout/Wrappers';
import { Wrapper1000 } from 'oolib';
import ChooseContentTypeBody from '../../components/organisms/ChooseContentTypeBody'
import { SERIF_7_8 } from 'oolib'
import { usePrevLocations } from '../../utils/customHooks';

import './_style.scss'
export default function ChooseTemplate() {

  const {goBack} = usePrevLocations();

  return (
    <div className="ChooseTemplate">
      <Section borderBottom>
          <Container>
            <Wrapper1000>
              <WrapperPageTitleCenter>
                <SERIF_7_8>Choose Template</SERIF_7_8>
                <WrapperAbsoluteHeaderButton right>
                  <ButtonGhost icon="X" onClick={() => goBack()} />
                </WrapperAbsoluteHeaderButton>
              </WrapperPageTitleCenter>
            </Wrapper1000>
          </Container>
        </Section>
      <ChooseContentTypeBody />
    </div>
  )
}
