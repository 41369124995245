import React, { useEffect, useRef, useState } from "react";
import { ButtonContentEngagement } from "./ButtonContentEngagement";
import ReactionProfilesModal from "../../../../../components/generalUI/ReactionProfilesModal";
import { useDebounce } from "../../../../../utils/customHooks";
import { _Locale } from "../../../../../components/locale/Locale";
import { ReactionsPanel } from "./ReactionsPanel";
import { useReactionsContext } from "../../../../../utils/react-query-hooks/Reactions/context";
import { usePopOutOfOverflowHiddenParent } from "oolib";

export const ReactionBtn = ({
  invert,
  textVariant = "long", //alt 'short'
  popOutOfOverflowHiddenParent=false
}) => {

  const [ reactionModal, setReactionModal ] = useState(false);
  const debounce = useDebounce();

  const {
    reactionOnContent,
    reacted, 
    reactionCount,
    reactionIcon,
    typesOfReactions,
    handleReactionClick,
    createReaction,
    deleteReaction,
    isLoading
  } = useReactionsContext() 

  
  const [ showReactionPanel, setShowReactionPanel ] = useState(false)
  const reactionsBtnRef = useRef(null)
  const reactionsKeys = Object.keys(typesOfReactions)
  const onlyLikesExists = reactionsKeys[0] === "like" && reactionsKeys.length === 1

  const handleOnClick = (type) => {
    if(isLoading) return

      handleReactionClick(type)
      showReactionPanel && setShowReactionPanel(false)  
  } 

  const { fixPos, applyFixedPos, removeFixedPos, trackerRef } = usePopOutOfOverflowHiddenParent(popOutOfOverflowHiddenParent);

  useEffect(() => {
    if (popOutOfOverflowHiddenParent) {
      if (showReactionPanel) {
        applyFixedPos();
      } else {
        removeFixedPos();
      }
    }
  }, [showReactionPanel]);

  const genReactionText = () => {
      if (onlyLikesExists && !reactionCount) {
        _Locale('Like');
      } else if (!reactionCount) {
        _Locale('Reaction');
      }

      if (onlyLikesExists && reactionCount === 1) {
        return `${reactionCount} ${_Locale('Like')}`;
      } else if (onlyLikesExists && reactionCount > 1) {
        return `${reactionCount} ${_Locale('Likes')}`;
      } else if (!onlyLikesExists && reactionCount > 1) {
        return `${reactionCount} ${_Locale('Reactions')}`;
      } else {
        return `${reactionCount} ${_Locale('Reaction')}`;
      }
}


  return (
    <>
      {reactionModal && (
        <ReactionProfilesModal
          title={onlyLikesExists  ? "All Likes" : "All Reactions"}
          onClose={() => setReactionModal(false)}
          reactionOnContent={reactionOnContent}
          typesOfReaction={typesOfReactions}
          onlyLikesExists={onlyLikesExists}
        />
      )}
      <div style={{ position: "relative"}}
          // onMouseEnter={() => { setShowReactionPanel(true);}}
          // onMouseLeave={() => { debounce(() => showReactionPanel && setShowReactionPanel(false), 3000); }}
          ref={reactionsBtnRef}
      >     
          <div ref={trackerRef}>
          {(showReactionPanel && !onlyLikesExists) && <ReactionsPanel {...{typesOfReactions, fixPos, handleOnClick, reactionIcon, reactionsBtnRef }}/>}
            <ButtonContentEngagement
              icon={reactionIcon}
              type={"reaction"}
              iconWeight={reacted ? "fill" : "regular"}
              invert={invert}
              onClick={onlyLikesExists ? handleOnClick : () => {}} // hover in case of multiple reactions and onclick of single reaction
              onTextClick={() => setReactionModal(true)}
              disabled={createReaction.isLoading || deleteReaction.isLoading}
              text={ textVariant==="short"? (reactionCount||"") : genReactionText() }
              setShowReactionPanel={setShowReactionPanel}
              showReactionPanel={showReactionPanel}
              reactionsKeys={reactionsKeys}
              />
          </div>    
       </div> 
    </>
  );
};

