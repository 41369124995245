export const mergeStaticConfsIntoTplExtractedConfs = ({staticConfs, extractedConfs}) => {
    const finalConfs = [...extractedConfs]

    for(let c of staticConfs){
      let matchingIdx = finalConfs.findIndex(cc => cc.colId === c.colId );
            if(matchingIdx !== -1){ //match found so merge with the matching obj
        finalConfs[matchingIdx] = { ...finalConfs[matchingIdx], ...c }
      }else{ //no match found, so simply push into final confs
        finalConfs.push(c)
      }
    }
    
    return finalConfs
  }