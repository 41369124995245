import styled from "styled-components";

//variable cuz its used in handleDragOver fn.
export const indicatorThickness = 5

export const StyledIndicator = styled.div`
    background-color: ${({theme}) => theme.colors.primaryColor100};
    position: absolute;
    height: ${indicatorThickness}px;
    width: 100%;
    transform: translateY(-50%);
    left: 0;
`


//these two only exist to accomodate for an automatic 'drag handle'
export const StyledDraggableElemWrapper = styled.div`
    padding-left: 2rem;
    position: relative;
`

export const StyledHandle = styled.div`
 position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: grab;
`

export const StyledEmptyDraggableGhostDetector = styled.div`
    height: 1rem;
`