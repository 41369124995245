import styled from 'styled-components';

import { colors, mediaQuery, transition } from 'oolib';

const { greyColor10, greyColor3} = colors;

export const StyledLegendButton = styled.button`
    background-color: inherit;
    border: 0;
    padding: 1rem;
    &:hover{
        background-color: inherit;
    }

    border: 1px solid ${greyColor10};
    ${transition('background-color, box-shadow')}

    min-height: 4rem;

    border-radius: 2px;

    ${mediaQuery('md')}{
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0);

    &:hover{
          background-color: ${greyColor3};
    }
    &:active{
        -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    }
    ${({active}) => 
        active && 
        `
            -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        `
    }

`

export const StyledButtonContents = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
`

export const StyledButtonColorTag = styled.div`
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
`