
export const config = {
    icon: "ListPlus",
    options: [
        {
            listType: 'bullet',
            tag: 'ul',
            icon: "ListDashes",
        },
        {
            listType: 'number',
            tag: 'ol',
            icon: "ListNumbers",
        },
        {
            listType: 'check',
            icon: 'CheckSquare',
            tag: 'checklist'
        }
    ]
}