import { mediaQuery, transition } from "oolib";
import styled from "styled-components";

export const StyledContentDiscoveryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  
  

`;


export const StyledFilterSortWrapper = styled.div`
  ${transition('opacity')};
  opacity: ${({searchInUse}) => searchInUse ? 0 : 1};
  display: flex;
  margin-left: auto; 
  /* gap: 1rem; */
`

export const StyledFilterWrapper = styled.div`
  ${mediaQuery('md')}{
    grid-row: 1 / 2;
    grid-column: 1 / 4;
  }
`
