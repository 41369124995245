import {
  Container, SimpleTable, Wrapper900,
  
} from "oolib";
import React, { useState } from "react";


import { PaddingTopBottom45 } from "oolib";


export const SimpleTableTest = () => {
  
  const [val, setVal] = useState(undefined);

  console.log(val);

  const props = {
    config: {
      noOfRows: 2,
      noOfCols: 3,
      colHeaderData: [
        "Verticle Edit",
        "Latitude",
        "Longitude"
      ],
      blockConfig: [
        undefined,
        {
          comp: 'DropdownSingle',
          props: {
            options: [
              {
                display: 'Option 1',
                value: 'option1'
              },
              {
                display: 'Option 2',
                value: 'option2'
              }
            ]
          }
        },
        {
          comp: 'DropdownSingle',
          props: {
            options: [
              {
                display: 'Option 1',
                value: 'option1'
              },
              {
                display: 'Option 2',
                value: 'option2'
              }
            ]
          }
        }
      ],
    },
    
    value: val,
    onChange: (k,v) => setVal(v)
  }

  

  return (
    
      <Container>
          <Wrapper900>
            <PaddingTopBottom45>

            <SimpleTable
              {...props}
              />
              {/* <SimpleTableNew
              {...props}
              /> */}
            </PaddingTopBottom45>
          </Wrapper900>
      </Container>
      
    
  );
};


