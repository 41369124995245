import { useMutation } from "react-query"
import axios from 'axios'

export const useGenShareUrl = () => {
	
	return useMutation(
		({
            contentId,
            contentType,
            url,
            title,
            description,
            image
        }) => {
            const body = {
                contentId,
                contentType,
                meta: {
                    url,
                    title,
                    description,
                    image
                }
            }
			return axios.post(`/api/staticHtml`, body, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
        }
	)
}
