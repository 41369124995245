
/**
 * 
 * @description this is used only if source.filterType === 'tagType'
 * because thats the only scenario in which the rollups are used for the target configs (for now)
 * 
 * this findQuery will be applied on the 'contentType' that is being fetched as options. 
 * typical use case would be a collection type, such as 'states'
 */
export const prepQueryToFetchOnlyUsedTags = ({ filterConfig, contentTypes }) => {
  switch (filterConfig.target.filterType) {
    case "rollupRelationshipType": // typical use case is : find 'newsReports' (for example) that have been written by authors that are tagged with 'maharashtra' state.
      return {
        $or: filterConfig.target.rollupResourceTypes.map((c) => ({
          [`taggedResources.${c}.data.0`]: { $exists: true }, //e.g taggedResources.veditumAdmins.data.0 applied to 'states' collection
        })),
      };

    case "rollupTagType": //pending
    case "rollupTaggedResourceType": //pending
      return {};
    /**
     * Since  in some of the  filter Option there is no contentType passed 
     * in that case if we dont apply the condition below the default will 
     * return $or:[] and hence its an invalid query in mongodb so the server will return 
     * error hence the condition !contentTypes || contentTypes.length === 0 is 
     * used
     *  */    
default:
      return !contentTypes || contentTypes.length === 0 
      ? {}:{
        $or: contentTypes.map((c) => ({
          [`taggedResources.${c}.data.0`]: { $exists: true }, //e.g taggedResources.newsReports.data.0 applied to 'states' collection
        })),
      };
  }
};
