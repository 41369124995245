import { boxShadow1, colors } from "oolib";
import React from "react";
import styled from "styled-components";
import { TitleComp } from "../comps/TitleComp";
import { useGetQueryData } from "../../../react-query-hooks/general";

const StyledSettingsSectionWrapper = styled.div`
  ${({ depthIdx }) =>
    depthIdx === 0
      ? `
       padding-left: 3rem;
       padding-right: 3rem;
       padding-bottom: 3rem;
       padding-top: 1.5rem;
       margin-top: 1.5rem;
       margin-bottom: 1.5rem;
       margin-left: -1.5rem;
       margin-right: -1.5rem;
       background: ${colors.greyColor10};
       ${boxShadow1}
       `
      : ""}
`;

export const SettingsSectionShell = ({
  children,
  title,
  subtitle,
  depthIdx,
  contentType,
}) => {
  const tpl = useGetQueryData(["tpl",contentType]);


  return (
    <StyledSettingsSectionWrapper depthIdx={depthIdx}>
      {
        <TitleComp
          {...{
            title: title || "Tags",
            subtitle,
            depthIdx,
          }}
        />
      }
      {children}
    </StyledSettingsSectionWrapper>
  );
};
