import { Loader } from 'oolib'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
	__GetAccessibleContentTypes
} from '../../../utils/accessControl'
import { useGetQueryState } from '../../../utils/react-query-hooks/general'
import { __GetAllContentTypeConfig } from '../../../utils/getters/gettersV2'

const RouteAccessSomeContent = ({
	component: Component,
	ACTION = "READ",
	location,
	contentTypes: _contentTypes,
	apiAccessCheck, //0 = name of apiAccessConfig key in backend ; 1 = paramString
	...rest
}) => {
	let { status, data: userData } = useGetQueryState('userData');
// console.log({contentTypes})
	const allContentTypes = __GetAllContentTypeConfig().map(d => d.kp_content_type);
	const contentTypes = _contentTypes || allContentTypes

	const hasAccess = () => {
		
		if (!userData?.user) {
			// not logged in
			return {
				has: __GetAccessibleContentTypes({user:undefined, ACTION, contentTypes}).length > 0,
				redirectTo: {
					pathname: '/login',
					state: {
						from: location.pathname + location.search,
					},
				},
			}
		}

		// else is logged in, and either has access to this content type or not.
		// if has, then let them through.
		// if not then simply redir back to the page they were coming from.
		return {
			has: __GetAccessibleContentTypes({user: userData?.user, ACTION, contentTypes}).length > 0,
			redirectTo: '/',
		}
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				status === 'loading' ? (
					<Loader debug={'route access some content'}  />
				) : !hasAccess().has ? (
					<Redirect to={hasAccess().redirectTo} />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

export default RouteAccessSomeContent
