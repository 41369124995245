import { $getNodeByKey } from "lexical";
import { useEffect, useState } from "react";

/**
 * this basically first cycles thru the activeAnnoDataIDs,
 * refers to the markNodeMap to get the nodekeys linked to these ids,
 * and then applies the <classToApply> class to them nodes, so that
 * they get highlighted
 */
export const useHighlightAnnoOnEvent = ({
  hover_select = 'hover',
  eventBasedCallback = {}, //only used to show the annolightbox when anno is clicked. i.e hover_select = 'select'
  markNodeMap,
  editor,
  annoData,
  classToApply = '-hovered-'
}) => {
  const [activeAnnoDataIDs, setActiveAnnoDataIDs] = useState([]);

  const resetActiveAnnoDataIDs = () => {
    setActiveAnnoDataIDs((_activeIds) =>
      _activeIds.length === 0 ? _activeIds : []
    );
  }

  useEffect(() => {
    const changedElems = [];
    for(const id of activeAnnoDataIDs){
      
      const keys = markNodeMap.get(id);
      
      if (keys !== undefined) {
        for (const key of keys) {
          const elem = editor.getElementByKey(key);
          if (elem !== null) {
            elem.classList.add(classToApply);
            changedElems.push(elem);
          }
        }
      }

    }
    
    if(activeAnnoDataIDs?.length > 0 && eventBasedCallback[hover_select]){
      
      
      /**
       * structure of this looks something like:  
       * [
       *   { dataId, data: {...} nodeKeys: [...] },
       *   ...
       * ]
       */
      const activeData_with_relatedNodeKeys = activeAnnoDataIDs.map(id => {
        const keys = markNodeMap.get(id);
        const nodeKeys = keys ? Array.from(keys) : []
        let textFragment;
        editor.getEditorState().read(() => {
          textFragment = nodeKeys.map(k => {
            const node = $getNodeByKey(k);
            const text = node.getTextContent();
            return text;
          }).join('');
        })
        return ({
          dataId: id, 
          data: annoData[id].tags,
          nodeKeys,
          textFragment
        })
      })
      
      eventBasedCallback[hover_select]({
        activeData_with_relatedNodeKeys,
        editor
      })
    }
    
    /**
     * everytime this effect remounts, we first clear out the
     * previous highlights, so that the highlighting logic can run
     * all over again and redo fresh highlights.
     */
    return () => {
      for (let i = 0; i < changedElems.length; i++) {
        const changedElem = changedElems[i];
        changedElem.classList.remove(classToApply);
      }
    };
  }, [activeAnnoDataIDs, editor, markNodeMap, annoData]);

  return ({
    setActiveAnnoDataIDs,
    resetActiveAnnoDataIDs
  })
}