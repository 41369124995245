import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $getSelection, $isNodeSelection, $isRangeSelection} from "lexical";
import { useEffect, useState } from "react";

/**
 * 
 * @returns boolean
 * @description checks to see if range selection exists AND that it isn't collapsed
 */
export const useCheckRangeSelectionExists = () => {
  const [editor] = useLexicalComposerContext();
  const [exists, setExists] = useState(false)
  useEffect(() => {
    return editor.registerUpdateListener(({editorState}) => {
      editorState.read(() => {
        const selection = $getSelection();
        // console.log({selection})
        const _exists = !$isRangeSelection(selection)
          ? false
          : (selection && !selection.isCollapsed()) ? true : false
        if(_exists !== exists) setExists(_exists) //basically only if a change has happened, then update state. optimization this is
      });
    })
  },[editor, exists])
  return exists;
}