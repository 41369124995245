import { createAtomicBlockEntity } from "../utils"

const handlePastedorDroppedFiles =({files, editorState, handleChange})=>{
  
  createAtomicBlockEntity(
    editorState,
    handleChange,
    'RTEImageInput',
    { files, multiple: files.length > 1}
  )
}

export default handlePastedorDroppedFiles;