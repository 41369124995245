import React from 'react'

import { CardProfile, Container, LABEL, ListProfile, PaddingBottom50, Wrapper650, Wrapper800 } from 'oolib'
import styled from 'styled-components';

const ProfileCards = () => {

  const listElemProfileData = {
    meta: {
      profile_type: "Change Leaders",
      tpl: "60f0231632a9b63c726ac94a",
      date_modified: "2022-11-10T01:56:50.468Z",
      created_by: "60c7393b2454d25d2133774c",
      date_created: "2022-11-09T13:21:27.565Z",
      state: "Mumbai, Maharashtra"
    },
    _id: "636ba957ba3f513588ca9ad3",
    user: {
      avatar: {
        userUploaded :[
        { 
          kind: "storage#object",
          id: "ash_dev__media/images/abrar_eb9815c6-0e2f-4732-a7e2-20ff205859be.jpeg/1661625294225534",
          selfLink: "https://www.googleapis.com/storage/v1/b/ash_dev__media/o/images%2Fabrar_eb9815c6-0e2f-4732-a7e2-20ff205859be.jpeg",
          mediaLink: "https://storage.googleapis.com/download/storage/v1/b/ash_dev__media/o/images%2Fabrar_eb9815c6-0e2f-4732-a7e2-20ff205859be.jpeg?generation=1661625294225534&alt=media",
          name: "images/abrar_eb9815c6-0e2f-4732-a7e2-20ff205859be.jpeg",
          bucket: "ash_dev__media",
          generation: "1661625294225534",
          metageneration: "1",
          contentType: "image/jpeg",
          storageClass: "STANDARD",
          size: 87119,
          md5Hash: "N1tOA2ZUj6AdUnuSf9ygWg==",
          crc32c: "8SSAQA==",
          etag: "CP6ApsvU5/kCEAE=",
          timeCreated: "2022-08-27T18:34:54.227Z",
          updated: "2022-08-27T18:34:54.227Z",
          timeStorageClassUpdated: "2022-08-27T18:34:54.227Z",
          originalFilename: "abrar.jpeg",
          mediaHost: "gcs",
          publicUrl: "https://storage.googleapis.com/ash_dev__media/images/abrar_eb9815c6-0e2f-4732-a7e2-20ff205859be.jpeg",
          format: "jpeg",
          width: 2048,
          height: 1151,
          cropX: -39.3408203125,
          cropY: 0,
        },
      ]
      },
      _id: "60c7393b2454d25d2133774c",
      role: "superAdmin",
      name: "Abrar",
      tagDisplay: "Super Admin",
    },
  };
  const listElemProfileData2 = {
    meta: {
      profile_type: "changeLeaders",
      tpl: "60f0231632a9b63c726ac94a",
      created_by: "60c7393b2454d25d2133774c",
      date_created: "2022-01-13T03:01:58.102Z",
      date_modified: "2022-02-22T05:09:35.772Z",
      state: "Bangalore , Karnataka"
    },
    _id: "61df9626c2a28050c19e982f",
    title: "Kishan Singh",
    user: {
      avatar: {
        userUploaded: [],
      },
      _id: "61df9625c2a28050c19e97c6",
      role: "changeLeader",
      name: "Ajana kumari",
      tagDisplay: "Admin",
    }
  };

  const listElemProfileData3 = {
    meta: {
      profile_type: "changeLeaders",
      tpl: "60f0231632a9b63c726ac94a",
      created_by: "60c7393b2454d25d2133774c",
      date_created: "2022-01-13T03:01:58.102Z",
      date_modified: "2022-02-22T05:09:35.772Z",
      state: "Bangalore , Karnataka"
    },
    _id: "61df9626c2a28050c19e982f",
    title: "Kishan Singh",
    user: {
      avatar: {
        userUploaded: [],
      },
      _id: "61df9625c2a28050c19e97c6",
      role: "changeLeader",
      name: "whatifnamewasreallyreallylong LongerMiddleName andhadlongersurname",
      tagDisplay: "Admin",
    }
  };

  const StyeldCardWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    @media (max-width: 599px) {
      grid-template-columns: 1fr;
    }
  `
  return (
    <Container>
      <LABEL style={{ padding: "2rem" }}>List Element Profile cases</LABEL>
      <Wrapper650>
        
        <ListProfile
          data={listElemProfileData} 
          to={"google.com"}
          openInNewTab
          firstChild
          config={{title: 'user.name',
                   image:[ "user.avatar.userUploaded"] }} 
                  />
        
        <ListProfile 
          to={"google.com"}
          openInNewTab
          data={listElemProfileData2} 
          config={{title: 'user.name', 
                  image:[ "user.avatar.userUploaded" ]}}/>
        
        <ListProfile 
          data={listElemProfileData} 
          to={"/profile/userProfiles/changeLeaders/60c7393b2454d25d2133774c?activeTab=about"}
          // onClick={() => window.alert("Passing both to and onClick prop is not possible")}
          actions={[
          {
            display: "Edit",
            icon: "PencilSimple",
            onClick: () => {},
          },
          ]}
          config={{title: 'user.name',
                   cardLabel: 'user.role',
                   metaBlock: [
                    { key: "meta1", valuePath: "meta.profile_type" },
                    { key: "meta2", valuePath: "meta.state" },
                  ],
                   image:[ "user.avatar.userUploaded"] }} 
                   />

        <ListProfile 
          data={listElemProfileData2} 
          to={"/profile/userProfiles/changeLeaders/61df9625c2a28050c19e97c6?activeTab=about"}
          actions={[
          {
            display: "Edit",
            icon: "PencilSimple",
            onClick: () => {},
          },
          ]}
          config={{title: 'user.name',
                  cardLabel: 'user.role',
                  metaBlock: [
                    { key: "meta1", valuePath: "meta.profile_type" },
                    { key: "meta2", valuePath: "meta.state" },
                  ],
                  image:[ "user.avatar.userUploaded"] }} 
                  />

      </Wrapper650>
      
      <LABEL style={{ padding: "2rem" }}>Profile Cards cases</LABEL>
      
      <Wrapper800>
        <StyeldCardWrapper>
          <CardProfile 
            data={listElemProfileData} 
            to={"google.com"}
            openInNewTab
            config={{title: 'user.name', 
                     image:[ "user.avatar.userUploaded" ]}}
            />
          
          <CardProfile 
            openInNewTab
            data={listElemProfileData2}
            to={'google.com'} 
            // onClick={() => window.alert("On clicked is passed")}
            config={{title: 'user.name', 
                     image:[ "user.avatar.userUploaded" ]}}
            />
          
          <CardProfile 
            to={"/profile/userProfiles/changeLeaders/60c7393b2454d25d2133774c?activeTab=about"}
            data={listElemProfileData} 
            config={{title: 'user.name',
                      cardLabel: 'user.role',
                      metaBlock: [
                        { key: "meta1", valuePath: "meta.profile_type" },
                        { key: "meta2", valuePath: "meta.state" },
                      ],
                      tagDisplay: "user.tagDisplay",
                      image:[ "user.avatar.userUploaded"] }}
              />
          <CardProfile 
            data={listElemProfileData2}
            to={"/profile/userProfiles/changeLeaders/61df9625c2a28050c19e97c6?activeTab=about"}
            config={{title: 'user.name',
                      cardLabel: 'user.role',
                      metaBlock: [
                        { key: "meta1", valuePath: "meta.profile_type" },
                        { key: "meta2", valuePath: "meta.state" },
                      ],
                      tagDisplay: "user.tagDisplay",
                      image:[ "user.avatar.userUploaded"] }}
              />
          <CardProfile 
            data={listElemProfileData3}
            to={"/profile/userProfiles/changeLeaders/61df9625c2a28050c19e97c6?activeTab=about"}
            config={{title: 'user.name',
                      cardLabel: 'user.role',
                      metaBlock: [
                        { key: "meta1", valuePath: "meta.profile_type" },
                        { key: "meta2", valuePath: "meta.state" },
                      ],
                      tagDisplay: "user.tagDisplay",
                      image:[ "user.avatar.userUploaded"] }}
              />
        </StyeldCardWrapper>
      </Wrapper800>
      <PaddingBottom50/>
    </Container>
  )
}

export {ProfileCards}

