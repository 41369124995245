import { extractAllBlocksFromTpl } from "../../../../utils/templating/extractAllBlocksFromTpl";

export const getShortLabelOfThisBlockFromTpl = ({vizData, allTpls,}) => {
    const tpl = allTpls.find(d => d.kp_content_type === vizData.contentType);
    if(!tpl) return undefined;
    //else
    const allBlocks = extractAllBlocksFromTpl({tpl});
    const thisBlock = allBlocks.find(bl => bl.valuePath === vizData.blockValuePath);
    if(!thisBlock) return undefined;
    //else
    return thisBlock.props?.shortlabel || thisBlock.props?.label
  }