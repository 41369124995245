import { valueNotFalsyOrEmptyAryObj } from './validation/validatorFns'
import { getVal, toArray, getDaysDiff } from 'oolib';


/**
 * display condition checks are applied when readOnly === false
 * because when readOnly === true, the 'blockIsPopulated' check is
 * the only real check that matters.
 * 
 * displayConditions.custom ofcourse is an exception to this rule.
 * ( although this is only used for events )
 */
export default ({content = {}, block, parentContent = {} }) => {
	if (!block.displayConditions) return true
	if ( // this one is mainly to deal with autosave not throwing a blank screen on tci.
		Array.isArray(block.displayConditions) && 
		block.displayConditions.some(obj => 
			!valueNotFalsyOrEmptyAryObj(obj.value) || 
			!obj.valuePath || 
			!obj.operator
		)
	) return true
	
	/**
	 * meaning if no display condition is defined, then
	 * return 'true' against 'shouldDisplayBlock'
	 */

	const operators = {
		//toArray wraps the answer obj in an array, if it isnt already. this standardizes some operations

		
		HIDE: (hide /** answer boolean */) => hide 
			? false /*dont show block */ 
			: true, /* show block */
		SOME_IN: (answer, conditionValue /*is an array */) =>
			toArray(answer).some((d) => conditionValue.includes(d.value)),
		SOME_EXACT: (answer, conditionValue /*is an array */) =>
			toArray(answer).some((d) => conditionValue.includes(d)),
		NOT_IN: (answer, conditionValue) => {
			if (!!answer === false) return false //dont show block
			//else
			let showBlock = toArray(answer).every(
				(d) => !conditionValue.includes(d.value),
			)

			return showBlock
		},
		NOT_EXACT: (answer, conditionValue) => {
			//else
			let showBlock = toArray(answer).every(
				(d) => !conditionValue.includes(d),
			)

			return showBlock
		},
		EVENT_DATE_CONDITION: (content, block, options) => {
			const modObjects = {
				block,
				props: block.props,
			}

			const isDateOrDateRange = (dateTimeObj) => {
				return dateTimeObj.date ? dateTimeObj.date : dateTimeObj.dateRange[1]
			}

			switch (true) {
				case content.main &&
					content.main.kp_date_time &&
					getDaysDiff(isDateOrDateRange(content.main.kp_date_time))
						.dateIsPassed === true: //means today IS AFTER EVENT
					switch (true) {
						case options.modifier === 'show-after-event':
							return true
						case options.modifier === 'hide-after-event':
							return false
						case options.modifier === 'mod-after-event':
							modObjects[options.modWhat][options.key] = options.modValue
							return true
						case options.modifier === 'mod-after-event-and-hide': //probably unnecesary now
							modObjects[options.modWhat][options.key] = options.modValue
							return false
						default:
							return true
					}

				default:
					//means today IS BEFORE EVENT
					switch (true) {
						case options.modifier === 'show-after-event':
							return false
						case options.modifier === 'show-before-event':
							return true
						case options.modifier === 'mod-after-event':
							modObjects[options.modWhat][options.key] = undefined
							return true
						case block.displayCondition === 'mod-after-event-and-hide': //probably unnecesary now
							modObjects[options.modWhat][options.key] = options.ogValue
							return true
						default:
							return true
					}
			}
		},
	}

	if (block.displayConditions) {
		let shouldDisplay = block.displayConditions.every((condition) => {
			if(condition.operator === 'HIDE'){
				return operators.HIDE(condition.value)
			}
			//else
   
			let answer = condition.valuePath.includes('$PARENT_CONTENT')
				? getVal(parentContent, condition.valuePath.replace('$PARENT_CONTENT.', ''))
				: getVal(content, condition.valuePath)
			return operators[condition.operator](
				answer,
				condition.value,
			)
		})
		
		//if we are hiding this block, then delete the value saved against it in the content Object
		// if (!shouldDisplay && block.valuePath)
		// 	console.log('delete' + block.valuePath)
		

		return shouldDisplay
	} else {
		return true //always return true if there is no condition at all.
	}
}