import React from 'react'
import { SANS_4_5, SERIF_5_6, SERIF_9_10, LABEL } from 'oolib'
import KPDataSummaryCardHeader from '../KPDataSummaryCardHeader'
import KPDataSummaryCardShell from '../KPDataSummaryCardShell'
import KPGauge from '../KPGauge'

export const KPDataSummaryCardTypeHeadline = ({
  icon,
  title,
  headlinePart1,
  headlinePart2,
  subheadline,
  colWidth,
}) => {
  return (
    <KPDataSummaryCardShell colWidth={colWidth}>
      <KPDataSummaryCardHeader icon={icon} title={title} />
      <div className="kp-data-card-body--headline">
        <div className="kp-data-card-headline">
          <SERIF_9_10 className="kp-data-card-headline__headline-part-1">
            {headlinePart1}
          </SERIF_9_10>
          <SERIF_5_6 className="kp-data-card-headline__headline-part-2">
            {headlinePart2}
          </SERIF_5_6>
        </div>
        <SERIF_5_6 className="kp-data-card-subheadline">
          {subheadline}
        </SERIF_5_6>
      </div>
    </KPDataSummaryCardShell>
  )
}

export const KPDataSummaryCardTypePercent = ({
  icon,
  title,
  colWidth,
  data,
  dataDescrip,
}) => (
  <KPDataSummaryCardShell colWidth={colWidth}>
    <KPDataSummaryCardHeader icon={icon} title={title} />
    <div className="kp-data-card-body--percent">
      <SERIF_5_6 className="kp-data-descrip">{dataDescrip}</SERIF_5_6>
      <div className="row kp-data-comp">
        {data &&
          data.map((d) => (
            <div className={`col-${12 / data.length} kp-data-molecule`}>
              <SANS_4_5 bold className="kp-data-val">
                {d.val}
              </SANS_4_5>
              <LABEL className="kp-data-key">{d.key}</LABEL>
            </div>
          ))}
      </div>
    </div>
  </KPDataSummaryCardShell>
)

export const KPDataSummaryCardTypePercentPlusViz = ({
  icon,
  title,
  colWidth,
  data,
  dataDescrip,
}) => (
  <KPDataSummaryCardShell colWidth={colWidth}>
    <KPDataSummaryCardHeader icon={icon} title={title} />
    <div className="kp-data-card-body--percent-plus-viz">
      <SERIF_5_6 className="kp-data-descrip">{dataDescrip}</SERIF_5_6>
      <div className="row kp-data-comp">
        {data &&
          data.map((d, i) => {
            return (
              <div className={`col-${12 / data.length} kp-data-molecule`}>
                <KPGauge
                  className="kp-data-molecule__gauge-viz"
                  kpGaugeIdSufix={`-${d.who}`}
                  arcData={d}
                />
                <div className="kp-data-keyval-wrapper">
                  <SANS_4_5 bold className="h1 kp-data-val">
                    {d.yellow_value[0] + d.orange_value[0] + '%'}
                  </SANS_4_5>
                  <LABEL className="kp-data-key">{d.who}</LABEL>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  </KPDataSummaryCardShell>
)
