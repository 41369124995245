import { LABEL, mediaQuery } from "oolib";
import styled from "styled-components";

import { colors } from "oolib";
const {white, greyColor80, greyColor40} = colors;


export const StyledOuterGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1rem;
`

export const StyledOuterLeftCol = styled.div`
grid-column: 1/13;
${mediaQuery('md')}{
    grid-column: 1/7;
}
${mediaQuery('lg')}{
    grid-column: 1/6;
}
    
`

export const StyledOuterRightCol = styled.div`
    grid-column: 1/13;
${mediaQuery('md')}{
    grid-column: 7/13;
}
${mediaQuery('lg')}{
    grid-column: 6/13;
}
`

export const StyledInnerGrid = styled.div`
display: grid;
grid-gap: 1rem;
`

export const StyledInnerGridCol = styled.div`
/* grid-column: 1/4; */
/* ${mediaQuery('lg')}{
    grid-column: ${({gridColumn}) => gridColumn};
} */
`

export const StyledAboutAndLinksContainer = styled.div`
 margin-top: -3rem;
    margin-bottom: -3rem;
`

export const StyledAboutSectionWrapper = styled.div`
 padding-top: 3rem;
padding-bottom: 3rem;

      
    color: ${white};
    ${mediaQuery('md')}{
        padding-right: 6rem;
    }

`

export const StyledLinksSectionWrapper = styled.div`
padding-top: 3rem;
padding-bottom: 3rem;
border-top: 1px solid ${greyColor80};

${mediaQuery('md')}{
  border-top: none;
}
`

export const StyledLinksSectionContentsWrapper = styled.div`
margin-top: -1.5rem;
margin-bottom: -1.5rem;
`

export const StyledInnerGridColContentsWrapper = styled.div`

    margin-top: -0.5rem;
    margin-bottom: 2.5rem; // actully 3rem. half rem is being added by the last navLink

    &:last-of-type{
      margin-bottom: 0;
    }
`

export const StyledFooterSectionLabel = styled(LABEL)`
    color: ${greyColor40};
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
`

export const StyledLinksGroupWrapper = styled.div`
padding-top: 1.5rem;
padding-bottom: 1.5rem;
`