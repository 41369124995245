import { CardEmbed, ImageInput, SANS_3_4, SERIF_3_4, SkeletonLoader, colors, icons, transition, IFrameInput } from "oolib";
import React from "react";


import { VideoInput } from "oolib";
import LinkEmbed from "../../../../../_V2/LinkEmbed";
import { isURLRegexTest } from "./isURLRegexTest";
import styled from "styled-components";

const { greyColor5, greyColor15, greyColor50 } = colors

const {CheckCircle} = icons
const StyledPreviewCardWrapper = styled.div`
  height: 14rem;
  border: .1px solid ${greyColor15};
  overflow: hidden;
  display: flex;
  &:hover{
    background-color: ${greyColor5};
  }
  cursor: pointer;
  ${transition("background-color 0.5s")}
`;

const StyledLink = styled(SERIF_3_4)`
  text-decoration: underline;
  text-underline-position: under;
`;

const PreviewShell = ({ urlString, children }) => (
  <StyledPreviewCardWrapper>
    <div style={{width: '20.1rem', overflow: 'hidden'}}>
      {children}
    </div>
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '1rem'}}>
        <StyledLink style={{textDecoration: 'underline', paddingBottom: '5px'}}>
            {urlString}
        </StyledLink>
      <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        <CheckCircle color="green" size={22}/>
        <SANS_3_4 color='green'>URL link is verified</SANS_3_4>
      </div>
    </div>
  </StyledPreviewCardWrapper>
);

export const createLinkPreviewForEmbedType = ({
  embedType,
  loadingEmbedType,
  urlString,
}) => {
  // if invalid url, show 'invalid url card'
  if (!isURLRegexTest(urlString)) {
    return (
      <CardEmbed
        disabled
        data={{
          title: urlString,
          desc: "Type a complete url or link",
        }}
        config={{
          title: "title",
          metaBlock: [{ key: "text", valuePath: "desc" }],
        }}
      />
    );
  }
  // if embedtype is being identified, show a skeleton loader
  if (loadingEmbedType) return <SkeletonLoader style={{ height: "100px" }} />;

  //else
  switch (embedType) {
    case "image":
      return (
        <PreviewShell
          urlString={urlString}
          children={<ImageInput readOnly value={[{ publicUrl: urlString }]} />}
        />
      );

    case "video":
      return (
        <PreviewShell
          urlString={urlString}
          children={<VideoInput readOnly value={[{ publicUrl: urlString }]} />}
        />
      );

    case "ppt":
      return (
        <PreviewShell
          urlString={urlString}
          children={<IFrameInput readOnly value={urlString} />}
        />
      );

    /**
     * PENDING
     * ideally, internal link, if published page, should not show a preview at all
     * instead it should narrow down and show the correct cardfrom the listingmodule
     * in LinkOrEmbedModal comp. But thats a bit complicated. So later.
     */
    case "internal-link":
    case "external-link":
      return <LinkEmbed value={urlString} disableTo />;
  }
};
