import { colors, InlineAlert, OKELink, SANS_3, TextInput, Tooltip, icons, ellipsis} from "oolib";
import React, { useState } from "react";
import styled from "styled-components";

const { Info } = icons;

const STYLED_SANS_3 = styled(SANS_3)`
  ${ellipsis}
`

export const AutoValuePathManager = ({
  id,
  value,
  onChange,
  disabled,
  prefix,
  errorMsg,
  infoTooltip,
  editLinkText
}) => {
 

  const [editId, setEditId] = useState(false);

  if(!value) return null

  return (
    <div>
      {!disabled && editId && value ? (
        <TextInput
          S
          value={value}
          onChange={(k, v) => onChange(id, v)}
          actionBtn={{
            text: "Done",
            onClick: () => {
              setEditId(false);
            },
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <STYLED_SANS_3 italic>{`${prefix || "Value Path is "}`}<span style={errorMsg && {color: colors.red}}>'{value}'</span></STYLED_SANS_3>
          <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
            {!disabled && (
              <SANS_3>
                <OKELink onClick={() => setEditId(true)} children={editLinkText || "Edit"} />
              </SANS_3>
            )}
            {
              infoTooltip &&
              <Tooltip {...infoTooltip}>
                <Info size={15}/>
              </Tooltip>
            }
          </div>
        </div>
      )}
      {errorMsg && (
        <InlineAlert
          text={errorMsg.text || errorMsg}
          type={"danger"}
          // link={errLink}
          // invert={invert}
          />
      )}
    </div>
  );
};
