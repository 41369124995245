import React from "react";
import LinkEmbed from "../../../../_V2/LinkEmbed";
import { DecoratorNodeShell } from "../comps/DecoratorNodeShell";

export const ExternalLinkEmbedNodeComp = ({
  //props for comp
  id,
  value,
  
  //props for shell
  nodeKey,
  
  //handlers
  onRemove
  
}) => {
  return (
    <DecoratorNodeShell
      nodeKey={nodeKey}
      onRemove={onRemove}
      >
      <LinkEmbed id={id} isInRTE={true} value={value} />
    </DecoratorNodeShell>
  );
};


