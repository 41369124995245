import React, { Fragment } from "react";

import { useGetQueryData } from "../../../../../../../utils/react-query-hooks/general";
import { MyAccountMenu } from "../MyAccountMenu";
import MyNotifications from "../MyNotifications";

export const AuthLinks = (props) => {
  const {
    deployment: { _EnableNotifications },
  } = useGetQueryData("platformConfigs");

  const {user} = useGetQueryData('userData')

  return (

    <Fragment>
      {_EnableNotifications &&     
         (
          <MyNotifications />
        ) }
      {<MyAccountMenu />}
    </Fragment>


  )
}