import { motion } from "framer-motion";
import { ButtonGhost, SANS_2, SANS_3, colors, OKELink } from "oolib";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CommonBodyComp } from "../CardExpandComps";
import {
  ExpandingCardBodyStyled,
  ExpandingCardContainerStyled,
  ExpandingCardHeaderStyled,
} from "./styled";

const { greyColor15 } = colors;

const StickWrapperStyled = styled.div`
  position: sticky;
  top: ${({ top }) => top}px;
  height: ${({ top, fullHeight }) =>
    fullHeight ? `calc(100vh - ${top}px)` : "auto"};
  z-index: ${({ zIndex }) => zIndex};
`;

export const StickyWrapper = React.forwardRef(
  ({ children, fullHeight, zIndex }, ref) => {
    const [top, setTop] = useState();

    useEffect(() => {
      if (!ref?.current) {
        setTop(0);
        return;
      }

      const { height } = ref.current.getBoundingClientRect();

      const cssValue = getComputedStyle(
        ref.current.parentElement
      ).getPropertyValue("top");

      const numericValue = cssValue === "auto" ? 0 : parseFloat(cssValue);


      setTop(height + numericValue);
    }, []);

    return (
      <StickWrapperStyled zIndex={zIndex} fullHeight={fullHeight} top={top}>
        {children}
      </StickWrapperStyled>
    );
  }
);

export default function SideCardExpand({
  linkCardTo,
  title,
  annoTagType,
  handleClose,
  tagId,
  date,
  resource,
  listingExpandCompConfig
}) {
  const containerRef = useRef();

 

  return (
    <motion.div
      initial={{ width: 0, opacity: 1, height: "100%" }}
      animate={{ width: 400 }}
      transition={{ type: "tween" }}
      exit={{ width: 0, opacity: 0 }}
      ref={containerRef}
    >
      <div style={{ height: "100%" }}>
        <ExpandingCardContainerStyled>
          <ExpandingCardHeaderStyled>
            {/* <TitleWrapperStyled> */}
            <SANS_3 semibold>
              <OKELink
                iconAfter="ArrowLineUpRight"
                linkType="action"
                onClick={() => window.open(linkCardTo)}
                iconSize={24}
                color={"black"}
                style={{maxWidth:"32rem"}}
              >
                {title}
              </OKELink>
            </SANS_3>
            {/* </TitleWrapperStyled> */}
            <ButtonGhost S icon={"X"} onClick={handleClose} />
          </ExpandingCardHeaderStyled>
          <div
            style={{
              padding: ".5rem 2rem",
              borderBottom: `1px solid ${greyColor15}`,
            }}
          >
            <SANS_2>
              {tagId?.display} | {date}
            </SANS_2>
          </div>

          <ExpandingCardBodyStyled>
            <CommonBodyComp
              annoTagType={annoTagType}
              resource={resource}
              tagId={tagId}
              listingExpandCompConfig={listingExpandCompConfig}
            />
          </ExpandingCardBodyStyled>
        </ExpandingCardContainerStyled>
      </div>
    </motion.div>
  );
}
