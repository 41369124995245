import { mediaQuery } from "oolib";
import styled from "styled-components";

export const NoDataStyled = styled.div`
  padding: 5rem;

  height: 100%;

  background-color: #ebf6ff;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
`;

export const SVGWrapperStyled = styled.div`
  height: auto;
  width: 100%;

  ${mediaQuery("md")} {
    height: 31rem;
    width: 48.5rem;
  }
`;
