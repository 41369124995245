import styled from 'styled-components';
import {colors, mediaQuery, SANS_2} from 'oolib'; // to be relative path when moved to oolib

const { white_opacity80, primaryColor100, red } = colors

export const StyledEditor = styled.div`
  position: relative;
  color: ${( {invert} )=> invert && white_opacity80 };
  margin-bottom: ${( {bottomMargin} )=> bottomMargin && '2rem' };
  
  .paragraph {
    margin-bottom: 8px;
  }

  .CONTENT_H1,
  .CONTENT_H2,
  .CONTENT_H3,
  .CONTENT_QUOTE, blockquote {
    margin: 28px 0 !important;
  }

  h1.CONTENT_H1:first-child,
  h2.CONTENT_H2:first-child,
  h3.CONTENT_H3:first-child,
  blockquote.CONTENT_QUOTE:first-child, blockquote {
    margin-top: 0 !important;
    margin-bottom: 28px !important;
  }

  .CONTENT_H1 + .paragraph,
  .CONTENT_H2 + .paragraph,
  .CONTENT_H3 + .paragraph{
    margin-top: -20px;
  }

  & .public-DraftEditorPlaceholder-root {
    color: ${( {invert, placeholderColor, theme: {colors}} )=> placeholderColor? `${placeholderColor} !important` 
    :!invert && (colors?.primaryColor100 || primaryColor100)
    };
    text-transform: capitalize;
  }
  & .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before { 
    left: -32px; //default is -36px resulting in a ol li marker that is more to the left than the marker of ul li
  }
  & .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
    right: -32px;
  }
  /* h1 {margin-bottom: 0.8rem;} */
  /* .paragraph, h2, h3 { margin-bottom: 0.5rem; } */
  /* .paragraph:last-child, h2:last-child, h3:last-child{ */
    /* margin-bottom: 0; */
  /* } */


  & .public-DraftStyleDefault-ol, 
  & .public-DraftStyleDefault-ul {
    margin-top: 7px;
    margin-bottom: 14px;
    & > li {
      margin-bottom: 5px;
    }
  }
  ${mediaQuery('md')}{
    margin-bottom: 0;
    /* h1 {margin-bottom: 1rem;} */
    /* .paragraph, h2, h3 { margin-bottom: 0.6rem; } */
    
    & .public-DraftStyleDefault-ol, 
    & .public-DraftStyleDefault-ul {
      margin-top: 10px;
      margin-bottom: 20px;
      & > li {
        margin-bottom: 7px;
      }
    }
  }
`;

export const StyledEditorAdjunct = styled.div`
  position: ${(props)=> props.noToolbar? 'relative': 'fixed'};
  bottom: 0px;
  right: 0;
  z-index: 50;
  ${mediaQuery("sm")} {
    position: sticky;
  }
`;

export const StyledCharLimitBanner = styled(SANS_2)`
  display: flex;
  justify-content: space-between;
  /* padding: 0rem 1rem; */
  //background-color: ${white_opacity80}; //it's important to be slightly white for mobile and sticky situation, commenting it out for the caption 
  & .warning {
    color: ${red};
  }
  
`;
