import styled, { css } from 'styled-components'
import { transition } from 'oolib'
import { motion } from 'framer-motion'

export const blurPage = css`
    filter: ${({ sidebar, isMobile }) => (sidebar && isMobile) ? 'blur(2px)' : 'none'};
    /* ${transition("filter")}; */

    transform: ${({ sidebar, isMobile }) => (sidebar && isMobile) ? 'translateX(75px)' : 'translateX(0)'};
    transition: transform 0.5s ease-in-out 0.2s, filter 1s ease-in-out;
`

export const StyledShellContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledFilterMobileButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    cursor: pointer;
    padding: 1rem 0rem;
    padding-right: 2rem;
`

export const StyledMainContent = styled(motion.div)`
  display: flex;
  width: 100%;
  /* margin-left: ${({ showSideBarLeft, sidebarWidth, isMobile }) => (!isMobile && showSideBarLeft) ? `${sidebarWidth}px` : '0px'}; */
  /* transition: margin-left 0.5s ease-in-out 1s; */
`;
