

export const PRIMHeaderRouteBlacklist = [ 
    '/onboarding',
    '/questionnaire',
    '/contribute-config',
    '/my-edit',
    '/edit/',
    '/moderate/',
    '/choose-template',
    '/userAgreement',
    '/tci',
    '/platformBuilder',
    '/OKFPublishSuccessful'
  ];
  export const SECHeaderRouteWhitelist = [ 
    '/grouped-published-listing',
    '/published-listing',
    // '/onboarding',
    // '/questionnaire',
    // '/my-edit'
  ];
  export const footerRouteBlacklist = [ 
    '/edit',
    '/moderate',
    '/contribute-config',
    '/search',
    '/profile/userProfiles',
    '/my-learning-modules',
    '/learning-modules',
    '/my-dashboard',
    '/questionnaire',
    '/published-page/lessons',
    '/onboarding',
    '/my-edit',
    '/choose-template',
    '/userAgreement',
    '/tci',
    '/synthesisV2',
    '/playground',
    '/platformBuilder',
    '/OKFPublishSuccessful',
    '/insightMatrix',

    '/annoBase',
    '/aiChat',
    '/annotation-explorer'


   ]