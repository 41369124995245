import { PercentBarChart, UI_BODY_BOLD_SM, colors } from "oolib";
import React from "react";

export const DataVizWidget = ({data, comp = 'PercentBarChart', colorIdx, valuePath, labelPath, title}) => {
  const vizCompsLookup = {
    PercentBarChart
  }
  
const VizComp = vizCompsLookup[comp]
  return (
    <div
    style={{
      background: colors.white,
      // padding: "18px",
      borderRadius: "4px",
      border: `1px solid ${colors.greyColor10}`
    }}
  >
    {title && <div style={{padding: '12px 18px', borderBottom: `1px solid ${colors.greyColor10}`}}>
    <UI_BODY_BOLD_SM>{title}</UI_BODY_BOLD_SM>
    </div>}
    <div style={{padding: '18px'}}>
    <VizComp
      data={data}
      {...{colorIdx, valuePath, labelPath}}
      />
      </div>
      </div>
  )
}