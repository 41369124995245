import { TextLoader } from "oolib";
import { useGetDataQuery } from "../../../utils/react-query-hooks/getData";


export const useGetDisplayForLandingPageLinks = ({thisNavConfig = {}, _display}) => {

  const {data, status} = useGetDataQuery({
    contentTypes: [thisNavConfig.contentType],
    findQuery: { _id: thisNavConfig._id },
    queryOptions: {
      enabled: thisNavConfig.type === 'landingPage'
    }
  })
  
  return ({
    display: thisNavConfig.type === 'landingPage'
      ? status === 'success' 
        && data?.data[0].main.title
      : _display,
    TextLoader: status === 'loading' ? TextLoader : undefined
  })
}