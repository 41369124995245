import { $getNodeByKey, RootNode } from "lexical";
import { segrigateDocs } from "../../../../../../../utils/general";

/**
 *
 * @description markNodeMap looks something like this:
 * { <annoDataId> : [nodeKey1, nodeKey2], ... }
 * 
 * since this function is used inside of editorState.read, we can freely
 * use '$' functions in it such as $getNodeByKey
 *
 * @returns { <annoDataId> : <LexicalEditorValue>, ... }
 */
export const constructAndInjectLexicalValueForEachAnnoDataId = (
  markNodeObj
) => {
  return Object.keys(markNodeObj).reduce(
    (a, annoDataId) => ({
      ...a,
      [annoDataId]: constructLexicalValueForThisAnnoDataId({ markNodeObj, annoDataId }),
    }),
    {}
  );
};

const constructLexicalValueForThisAnnoDataId = ({ markNodeObj, annoDataId }) => {
  let markNodes = Array.from(markNodeObj[annoDataId]).map((key) => ({
    markNode: $getNodeByKey(key),
    parentKey: $getNodeByKey(key).getParentKeys()[0],
  }));
  markNodes = segrigateDocs(markNodes, "parentKey");

  return {
    isLexical: true,
    editorState: {
      root: {
        ...new RootNode().exportJSON(),
        children: Object.keys(markNodes).map((parentKey) => {
          const paraNode = $getNodeByKey(parentKey).exportJSON();
          const markNodeChildren = markNodes[parentKey].map(
            ({ markNode }) => markNode
          );
          return {
            ...paraNode,
            children: markNodeChildren.map((thisMarkNode) =>
              createJSONForThisMarkNodeAndChildren(thisMarkNode)
            ),
          };
        }),
      },
    },
  };
};

const createJSONForThisMarkNodeAndChildren = (thisMarkNode) => {
  if (thisMarkNode.getChildren) {
    const children = thisMarkNode.getChildren();
    return {
      ...thisMarkNode.exportJSON(),
      children: children.map((childNode) =>
        createJSONForThisMarkNodeAndChildren(childNode)
      ),
    };
  } else {
    return thisMarkNode.exportJSON();
  }
};
