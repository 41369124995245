
import React, { Fragment, useState } from "react";
import { SidebarLayoutV2 } from "../../../../components/layout/SidebarLayoutV2";

export const SidebarLayoutTest = () => {

  const [toggleState, setToggleState] = useState({
    lsb: true,
    rsb: true
  })

  const genTestButtons = () => (
    <Fragment>
      <button onClick={() => setToggleState(prev => ({...prev, lsb: prev.lsb ? false : true}))}>toggle left</button>
      <button onClick={() => setToggleState(prev => ({...prev, rsb: prev.rsb ? false : true}))}>toggle right</button>
    </Fragment>
  )


  return (
    <Fragment>
      {genTestButtons()}
      <SidebarLayoutV2
        lsbComp={<div style={{height: '2000px', background: 'lightpink'}}>section1</div>}
        lsbAllowCollapse={true}
        
        rsbAllowCollapse={true}
        rsbComp={<div style={{height: '2000px', background: 'lightpink'}}>section1</div>}
        
        toggleState={toggleState}
        setToggleState={setToggleState}
        ><div>this is the body</div></SidebarLayoutV2>
    </Fragment>
  );
};
