import { __GetContentTypeConfigNew } from "../../../../utils/getters/gettersV2";
import { createContentCardDate } from "../../../../utils/momentManipulate";

const buildFakeName = (value) => {
  if (value.fakename) {
    return value.fakename;

  } else {
    return value.fakenames.map((d) => d.name).join(", ");

  }
};

export const applyModifier = (modifier, value) => {
  switch (modifier) {
    case "fakeName":
      return buildFakeName(value);
    case "date":
      return createContentCardDate(value);
    case "annoSummary":
      return getSummaryFromAnnotation(value);
    case 'extractTag':
      return extractAsTag(value)
    case 'tplTitle':
      return __GetContentTypeConfigNew(value).general.content.title
    default:
      return `${modifier}${value}`;
  }
};

export const getSummaryFromAnnotation = (value) => {
  console.log({ dynamic: value });

  const summary =
    value[0]?.note?.text?.blocks || value[0]?.fragments[0]?.description?.blocks;

  return summary;
};

export const extractAsTag = (value) => {

  const obj = {};
  obj.display = value['display'];
  obj.value = value['value'];


  return [obj]


}



