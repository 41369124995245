import { motion } from "framer-motion";
import { icons } from "oolib";
import styled from "styled-components";
import { blurPage } from "../Shell/styled";

const { CaretRight } = icons 

export const StyledActionBar = styled.div`
  max-width: 100svw;
  display: flex;
  
  background-color: white;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  padding: 0 20px;
  gap: 2rem;
  &::-webkit-scrollbar {
    display: none; 
  }
`;

export const StyledActionBlock = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 2rem; */
  flex-grow:1 ;
`

export const StyledActionsPanelWrapper = styled.div`
  position: sticky;
  z-index: 10;
  top: ${({ tabsPanelHeight }) =>  tabsPanelHeight  ? `${tabsPanelHeight}px` : '0px'};
  border-bottom: 1.5px solid #E9E9E9;
  border-top: 1.5px solid #E9E9E9;
`;

export const StyledAnimatedCaret = styled(motion(CaretRight))`
  display: inline-block;
`;

export const StyledFilterButtonWrapper = styled(motion.div)`
  border-right: 1px solid #E9E9E9; 
  height: 100%; 
  display: flex;
  margin-left: -2rem;
`

export const StyledFilterButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 2rem;
  background: none;
  border: none;
  cursor: pointer;
`

export const StyledMobileActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  
  background-color: white;
  z-index: 1000;
  
`