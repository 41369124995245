import React, { createContext, useContext, useState } from "react";

export const PlatformBuilderContext = createContext();

export const PlatformBuilderContextProvider = ({ children }) => {
  const [platformBuilderMode, setPlatformBuilderMode] = useState(false);

  return (
    <PlatformBuilderContext.Provider value={{ platformBuilderMode, setPlatformBuilderMode }}>
      {children}
    </PlatformBuilderContext.Provider>
  );
};

export const usePlatformBuilderContext = () => {
  return useContext(PlatformBuilderContext);
};
