import React, { Fragment, useState } from "react";
import { getBreakPoint, useScreenWidth } from 'oolib'
//actions
import {
  __CheckAccessToPrivatePlatform
} from "../../../../../utils/accessControl";
import { icons } from 'oolib'

//config
import {
  useGetQueryState
} from "../../../../../utils/react-query-hooks/general";

// import { logout } from "../../../actions/auth";
//KP components

import { HeaderLogo } from "./comps/HeaderLogo";
import { HeaderPublishButton } from "./comps/HeaderPublishButton";

import HamburgerMenuV2 from "../../HamburgerMenuV2";
import { AuthLinks } from "./comps/AuthLinks";
import { AuthLinksLoader } from "./comps/AuthLinks/AuthLinksLoader";
import { GuestLinks } from "./comps/GuestLinks";
import { HeaderLanguageSelect } from "./comps/HeaderLanguageSelect";
import { PRIMLinksGenerator } from "./PRIMLinksGenerator";
import { SECNavbar } from "./SECNavbar";
import { useSearchParamsState } from "../../../../../utils/customHooks";

const { List } = icons;

const HeaderUI = ({ builderProps, config }) => {
  const screenWidth = useScreenWidth();
  const {_Nav} = config;

  const { status: loadUserStatus, data: userData } =
    useGetQueryState("userData");

  const [hamburgerOpen, setHamburgerOpen] = useSearchParamsState({
      key: "menu",
      value: false
    });
  

  const genHamburger = () => (
    <Fragment>
      <button
        className="OKE-Header__hamburgerButton"
        id={"oke_Header_hamburgerButton"} 
        onClick={() => setHamburgerOpen(!hamburgerOpen)}
      > {/* Id required of Coachmarks */}
        <List size={24} weight="light" />
      </button>
      <HamburgerMenuV2
        builderProps={builderProps}
        _Nav={_Nav}
        hamburgerOpen={hamburgerOpen}
        setHamburgerOpen={setHamburgerOpen}
      />
    </Fragment>
  );

  return (
    <div className="OKE-Header" style={{zIndex: 10000}}>
      <div className="OKE-Header1">
        <div className="OKE-Header1-content"> {/* This className is important for platformBuilder highlight to work*/}
          <div className="OKE-Header1__module1">
            {
              screenWidth < getBreakPoint("lg") &&            
                loadUserStatus !== 'loading' &&
                genHamburger()
              // : <div style={{width: '2rem'}}/> /*only to get the spacing right */
            }
            {<HeaderLogo config={config} />}
            {<HeaderLanguageSelect />}
          </div>
          {__CheckAccessToPrivatePlatform(userData?.user) && (
            <div className="OKE-Header1__module2">
              { loadUserStatus !== 'loading' &&  //wait for a verdict on userData, cuz PRIMLinks uses it for accesscontrol
                screenWidth >= getBreakPoint("lg") && (
                <PRIMLinksGenerator builderProps={builderProps} _Nav={_Nav} />
              )}
              {screenWidth >= getBreakPoint('sm') && <HeaderPublishButton config={config} />}
              {builderProps ? (
                builderProps.guest_authLinks === "guest" ? (
                  <GuestLinks />
                ) : (
                  <AuthLinks />
                )
              ) : loadUserStatus === "loading" ? (
                <AuthLinksLoader />
              ) : loadUserStatus === "error" ? (
                <GuestLinks />
              ) : (
                loadUserStatus === "success" && <AuthLinks />
              )}
            </div>
          )}
        </div>
      </div>
      {screenWidth < getBreakPoint('sm') && <HeaderPublishButton isMobile config={config} />}
      { _Nav.secondary &&
        __CheckAccessToPrivatePlatform(userData?.user) &&  
        loadUserStatus !== 'loading' && ( //wait for a verdict on userData, cuz SECbar uses it for accesscontrol
          <SECNavbar _Nav={_Nav} builderProps={builderProps}/>
        )}
    </div>
  );
};

export default HeaderUI;