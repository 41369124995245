import { handleSiblingValuePath, handleSiblingValuePathForFilters } from ".";
import { __CheckAccessToContentType, __CheckSomeAccessToSomeContentType } from "../../../../utils/accessControl";
import { useGetTagCategory } from "../../../../utils/customHooks";
import { useElemInView } from "../../../../utils/customHooks/useElemInView";
import { useGetQueryData } from "../../../../utils/react-query-hooks/general";
import { useGetTagsV3 } from "../../../../utils/react-query-hooks/tags";
import { useGetTagsV4 } from "../../../../utils/react-query-hooks/useGetTagsV4.js";

export const useGetTypeCategoryAndTags = ({
    tagTypeProp,
    siblingValuePath,
    content,
    filtersProp,
    incExcTagsList,
    enableDataFetch,
    postDALFilter,
    lazyLoad,
    searchTerm
}) => {
    
    /** read about siblingValue use case in DynamicDropdown comp */
    let tagType = handleSiblingValuePath(tagTypeProp, siblingValuePath, content)
    let filters = handleSiblingValuePathForFilters(
        filtersProp,
        siblingValuePath,
        content  
    );
    
    const {category, segment } = useGetTagCategory(tagType)

    const getQuery = lazyLoad === true
        ? useGetTagsV4
        : useGetTagsV3

    const { 
        data, 
        status, 
        isRefetching,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage, 
    } = getQuery(
        tagType, 
        { 
            tagCategory : category, 
            ...(filters ? {filters} : {}),
            postDALFilter,
            enabled: enableDataFetch,
            searchTerm
        }
    );
    //only used if lazyLoad is true.
    const { observerRef } = useElemInView({ callback: fetchNextPage });

    const userData = useGetQueryData('userData')
    const createTagAccess = category === 'contentTags'
        ? __CheckSomeAccessToSomeContentType({user: userData?.user, actions: ['PUBLISH'],contentTypes:[tagType]})
        : true

    //works only for tagId type of tags
    //if tagId is included in both include and exlude lists, it is excluded.
    const filterTagsByIncludeExcludeLists = (data) => {
        let includeList = incExcTagsList?.include || []
        let excludeList = incExcTagsList?.exclude || []
        if(includeList.length === 0 && excludeList.length === 0) return data;
        
        return data.filter(d => {
            return (
                (includeList.length > 0 ? includeList.includes(d.tagId) : true ) &&
                !excludeList.includes(d.tagId)
            )
        })
    }

    const flattenPages = (data) => {
        //condition because for the old tags structure AND for scenarios where lazyload is false, 
        //we aint doing lazy load, so the data structure returned will be an array
        return data.pages 
            ? data.pages.reduce((a,b) => [...a, ...(Array.isArray(b) ? b : b.data)] ,[]) // because the old tags structure api returns simply the data array and not inside a {data: [] } object. old tags structure will get deprecated soon, then we can simply do b.data
            : data 
            
    }
    

    return {
        data: data && filterTagsByIncludeExcludeLists(flattenPages(data)),
        status,
        isRefetching,
        tagType,
        tagCategory : category,
        tagSegment: segment,
        createTagAccess,
        observerRef: lazyLoad && hasNextPage ? observerRef : null,
        isFetchingNextPage
    }
}