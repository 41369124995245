import React from "react"

function Unaware(props) {
  return (
    <svg width={42} height={42} viewBox="0 0 42 42" fill="none" {...props} >
      <circle cx={21} cy={21} r={21} fill="#EDEFF1" />
      <path
        d="M16.34 15.803a7.5 7.5 0 019.39 0l5.057 4.06a.5.5 0 010 .78L25.73 24.7a7.5 7.5 0 01-9.39 0l-5.057-4.059a.5.5 0 010-.78l5.056-4.058z"
        fill="#E3F2FD"
        stroke="#399EFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path stroke="#399EFF" d="M28.771 8.244L14.503 33.776" />
      <path
        d="M20.823 17.006a3.65 3.65 0 00-1.079 6.575l3.476-6.389a3.634 3.634 0 00-2.397-.186z"
        fill="#399EFF"
      />
    </svg>
  )
}

export default Unaware
