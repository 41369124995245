import React from "react";
import { LegendButton } from "../LegendButton";
import { StyledLegend } from "../styled";

const LegendRadioList = ({
  id,
  options,
  handleChangeOn = "click", //alt = 'click'
  value,
  onChange,
}) => {
  //col settings must be the bootstrap codes

  const handleChange = d => onChange(id, d)

  return (
    <StyledLegend>
      {options.map((d) => (
        <LegendButton
			option={d}
			isActive={value?.value === d.value}
			{...( handleChangeOn === 'hover'
				? {
					onMouseOver: () => handleChange(d),
					onTouchStart: () => handleChange(d),
					onMouseOut: () => handleChange(undefined),
					onTouchEnd: () => handleChange(undefined)
				} : {
					onClick: () => handleChange(d)
				})}
			/>
      ))}
    </StyledLegend>
  );
};

export default LegendRadioList;
