import { queryClient } from "../../..";
import { __CheckAccessToModule } from "../../../utils/accessControl";
import { __GetContentTypeConfigNew } from "../../../utils/getters/gettersV2";

export const checkAccessToDownloadCsv = ({ resourceType }) => {
  let { data : userData } = queryClient.getQueryState('userData')
  const tplConfig = __GetContentTypeConfigNew(resourceType);

  const exportToCsvEnabled = tplConfig?.general.csvExport?.enable;
  const hasAccess = exportToCsvEnabled&&  __CheckAccessToModule(userData?.user,"READ","csvExport")

  return hasAccess;
};
