import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'


export const useUpdateNotifIsRead = () => {
	const queryClient = useQueryClient();


	return useMutation(
		({userId}) => {
			// const config = { headers: { 'Content-Type': 'application/json' } }
			return axios.patch(`/api/notifications/set_is_read/${userId}`)
			
		},
		{
			onError: (error) => {
				console.log('error updating notif is read', error)
			},
			onSuccess: () => {
				queryClient.invalidateQueries(["userData"])
				console.log('Notif is read updated successfully!')
			},
		},
	)
}


export const useSendNotification = () => {
	const queryClient = useQueryClient();


	return useMutation(
		({ userIds, body }) => {
			const requests = userIds?.map(({ id }) => {
				return axios.post(`/api/notifications/${id}`, body)
			});
			return Promise.all(requests);
		},
		{
			onError: (error) => {
				console.log('error', error)
			},
			onSuccess: () => {
				queryClient.invalidateQueries(["userData"])
				console.log('Notif sent sucessfully!')
			},
		},
	)
}
