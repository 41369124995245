import { cloneDeep } from "lodash";
import { toArray, setVal } from "oolib";

export const _HANDLE_UPDATE_TPL_CONFIG = ({
    updateData,
    setTplConfig,
    SAVE_TPL_CHANGES
}) => {
    
    // --> { valuePath, value } || [ { valuePath, value } ]
    setTplConfig((prev) => {
      let newTplConfig = cloneDeep(prev);
      toArray(updateData).map(({ valuePath, value }) => {
        newTplConfig = setVal( newTplConfig, valuePath, value);
      });
      SAVE_TPL_CHANGES({newTplConfig});
      return newTplConfig;
    });
  };