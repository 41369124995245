import styled, { keyframes} from "styled-components";
import { colors } from "oolib";


const {black_opacity30} = colors;

const fadeIn = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;


const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${black_opacity30};
  z-index: 150;
  animation: ${fadeIn} 0.5s linear; 
  display: grid;// centering the coachmark below by default, will have no
  place-items: center;// effect if a position (other than 'center') is passed in the tooltip pbject
`;




export {StyledOverlay}