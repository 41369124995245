import { getVal } from "oolib";
import { queryClient } from "../../../..";

export const filterAsPerActiveFilters = ({
  separatedData,
  activeFilters,
  selectedTpls,
  rollupValuePathDocuments
}) => {
  //first filter down to only the selected tpls
  return separatedData.filter((d) => {
    if (
      activeFilters.every((a) => {
        if (a.target.filterType === "rollupTagType_v2") {
          if(a.target.childTagType){
            const allQueryMatches = queryClient.getQueriesData([
              "contentListing",
              [a.target.childTagType],
            ]);
            const allChildTagTypeDocs =
              allQueryMatches.find(
                ([key, _data]) => key[2]?.queryOptions?.enabled
              )?.[1]?.data || [];
  
            const childDocsMatchingActiveFilterValue = allChildTagTypeDocs.filter(
              (doc) => a.values.some((vv) => vv.value === doc._id)
            );
  
            //get the valid separatedData doc ids.
            const allowedDocIds = childDocsMatchingActiveFilterValue
              .flatMap((doc) => {
                return selectedTpls
                  .map((s) => s.kp_content_type)
                  .flatMap((ct) => {
                    return doc.taggedResources?.[ct]?.data || [];
                  });
              })
              .map((datum) => datum._id);
  
            return allowedDocIds.includes(d._id);
          }else if(a.target.childValuePath){
            const relevantDocIds = rollupValuePathDocuments.data.filter(rD => {
              return a.values.some(aa => aa.value === getVal(rD, a.target.childValuePath))
            }).map(rD => rD._id)
            return d.docTags[a.target.tagType].data.some(dd => relevantDocIds.includes(dd._id));
          }
        }

        return a.values.some((v) => {
          return d[a.source.scope]?.[
            a.source.tagType || a.source.profileTypes?.[0]
          ]?.data.some((dd) => dd._id === v.value);
        });
      })
    ) {
      return true;
    } else {
      return false;
    }
  });
};
