import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
	__CheckAccessToContentType
} from '../../../utils/accessControl'
import { useGetQueryState } from '../../../utils/react-query-hooks/general'
import {Loader} from 'oolib'
import { getQueryParam } from '../../../utils/general'

const RouteAccessOneContent = ({
	component: Component,
	ACTION,
	location,
	ignoreExtendedIsMemberCheck,
	path,
	...rest
}) => {
	let { status, data: userData } = useGetQueryState('userData')

	const hasAccess = () => {
		let pathSplit = path.split('/')
		let paramIdx = pathSplit.findIndex(
			(d) => [':contentType', ':content_type'].indexOf(d) !== -1,
		)
		let contentType = location.pathname.split('/')[paramIdx]

		if (!userData?.user) {
			// not logged in
			return {
				has: __CheckAccessToContentType(
					undefined, 
					ACTION || 'READ', 
					contentType, 
					{
						contentId: getQueryParam(location.search, 'id'),
						ignoreExtendedIsMemberCheck
					}
				),
				redirectTo: {
					pathname: '/login',
					state: {
						from: location.pathname + location.search,
					},
				},
			}
		}

		// else is logged in, and either has access to this content type or not.
		// if has, then let them through.
		// if not then simply redir back to the page they were coming from.

		return {
			has: __CheckAccessToContentType(
				userData?.user, 
				ACTION || 'READ', 
				contentType, 
				{
					contentId: getQueryParam(location.search, 'id'),
					ignoreExtendedIsMemberCheck
				}
			),
			redirectTo: '/',
		}
	}

	return (
		<Route
			{...rest}
			path={path}
			render={(props) =>
				status === 'loading' ? (
					<Loader S debug={'route access one content'} />
				) : !hasAccess().has ? (
					<Redirect to={hasAccess().redirectTo} />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

export default RouteAccessOneContent
