import { ActionMenu, ModalConfirm, colors } from 'oolib'; //"../../generalUI/ActionMenu";
import React, { useContext, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import * as ExpandComps from "../../CONFIG_PARSER/ExpandComps";
import {
  __CheckAccessToContentType,
  __IsAuthor
} from "../../../../../utils/accessControl";
import { stitchLink } from "../../../../../utils/general";
import { TableCell, TableRow } from "./comps";
import { StyledExpandCompContainer, StyledExpandCompWrapper } from "./styled";
import {  __GetContentTypeConfigNew } from '../../../../../utils/getters/gettersV2';
import { _Locale } from '../../../../locale/Locale';
import { useModal } from '../../../../../utils/customHooks';
import { AppSettingsContext } from '../../../../../contexts/appSettingsContext';
import { useDeleteContent } from '../../../../../pageTpls/ContributeTpl/queryHooks/useDeleteContent';


const TableRowElem = ({
  id: rowId,
  data,
  tableConfig,
  actions,
  actionsConfig: actionsConfigProp,
  conditionsConfig: conditionsConfigProp,
  colWidthConfig,
  handleRowClick: parentHandleRowClick,
  handleCellChange,
  rowIdx
}) => {
  let queryClient = useQueryClient();
  let { data: userData } = queryClient.getQueryState('userData');

  const tableElemRow_ref = useRef(null);
  
  const history = useHistory();
  const location = useLocation();
  

  const {link : linkConfig, expandComp : expandCompConfig } = tableConfig
  const link = linkConfig ? stitchLink(data, linkConfig) : undefined

  const ExpandComp = expandCompConfig && ExpandComps[expandCompConfig.comp];

  const ExpandCompWrapperRef = useRef(null);

  const [showExpandComp, setShowExpandComp] = useState(false)
  const { APP_SETTINGS } = useContext(AppSettingsContext);
  const {mutate,isSuccess:isDeleted} = useDeleteContent();
  const [showDeleteModal, setShowDeleteModal] = useModal(false)
 
  const genDeleteModal = () => (        
    <ModalConfirm
      title={
        // if localizatio doesnt exist at all OR if it exists and lang === english then..
        // be more specific with this statment
        !APP_SETTINGS.lang || APP_SETTINGS.lang?.value === 'en'
          ? `Are you sure you want to delete this ${
              __GetContentTypeConfigNew(data.meta.kp_content_type).general.content
            ?.singular || 'content'
            } ?`
        : _Locale('Are you sure you want to delete this content ?')
      }
      onClose={() => setShowDeleteModal(false)}
      onConfirm={() => handleDelete()}
    /> 
  )
  const handleDelete = (e) => {
    setShowDeleteModal(false)
    mutate(
      { contentType:data.meta.kp_content_type, id:data.kp_og_published_doc || data._id  },
      {
        onSuccess: () => {         
          queryClient.invalidateQueries("contentListing", [data.meta.kp_content_type])
          queryClient.invalidateQueries("myContent")
        },
      }
    )
  }
  const handleRowClick = (e, data) => {

    if (expandCompConfig) {
      
      setShowExpandComp(prev => !prev)

    }else if(parentHandleRowClick) {
      
      parentHandleRowClick(data)
    
    }

    e.stopPropagation();
  };


  const conditionsConfig = {
    //functions that decide weather an action should be shown to current user
    allowEdit: (data) => {
      let contentTypeConfig = __GetContentTypeConfigNew(data.meta.kp_content_type);
      switch(contentTypeConfig.general.segment){
        case 'publishing':
        default: 
          return data.meta.kp_contributed_by?._id === userData?.user._id
        case 'staticPages':
        case 'collections':
          return (
            __CheckAccessToContentType(userData?.user, "UPDATE", data.meta.kp_content_type) &&
            (
              data.kp_published_status === 'published' ||
              (data.currentEditor?._id || data.currentEditor)  === userData?.user._id
            )
          )
      }
    },
    allowModerate: (data) => {
      return !__IsAuthor(
        data.meta.kp_contributed_by?._id,
        userData?.user?._id
      ) &&
        __CheckAccessToContentType(
          userData.user,
          "MODERATE",
          data.meta.kp_content_type
        )
        ? true
        : false;
    },
    allowDelete: (data) => {
      let contentTypeConfig = __GetContentTypeConfigNew(data.meta.kp_content_type);

      // if content is under edit then don't show the delete option, because the api doesn't delete the clone doc used for editing "

      if(data.currentEditor) return false

      switch(contentTypeConfig.general.segment){
        case 'publishing':
        default: 
          return __IsAuthor(
            data.meta.kp_contributed_by?._id,
            userData?.user._id
          ) ||
            __CheckAccessToContentType(
              userData.user,
              "MODERATE",
              data.meta.kp_content_type
            )
        case 'staticPages':
        case 'collections':
          return (
            __CheckAccessToContentType(userData?.user, "DELETE", data.meta.kp_content_type) &&
            (
              data.kp_published_status === 'published' ||
              (data.currentEditor?._id || data.currentEditor)  === userData?.user._id
            )
          )
      }
    },
    ...(conditionsConfigProp ? conditionsConfigProp : {}),
  };

  const actionsConfig = {
    fireUpdateAction: () => {
      if (link) {
        /*
        '&' because data.link will be 'www.foo.bar?id=123'
        */
       let path = `${link}&createUpdate=create`
        history.push({
          pathname: path.split('?')[0],
          search: `?${path.split('?')[1]}`,
          state: { postPblRedirect: `${location.pathname}${location.search}`}
        });
      }
      //redirect to the case report page
    },
    editContent: () => {
      //redirect to the case report page
      history.push(`/edit/${data.meta.kp_content_type}?id=${data.kp_og_published_doc || data._id}`); // ----> edit
    },
    viewPublishedContent: () => {
      //redirect to the case report page
      const docId = data.kp_published_status === 'editPublished'
        ? data.kp_og_published_doc
        : data._id
      history.push(`/published-page/${data.meta.kp_content_type}?id=${docId}`); // ----> edit
    },
    fireEditReport: () => { // deprecate after replaced in nazdeek table config
      //redirect to the case report page
      history.push(`/edit/${data.meta.kp_content_type}?id=${rowId}`); // ----> edit
    },
    fireModerateReport: () => {
      //redirect to the case report page
      history.push(`/moderate/${data.meta.kp_content_type}?id=${rowId}`); // ----> moderate
    },
    deleteContent:()=>{
       setShowDeleteModal(true)
     
    },
    ...(actionsConfigProp ? actionsConfigProp : {}),
  };

  return (
    <div className="TableElem__Row-Wrapper">
       {showDeleteModal && genDeleteModal()}
      <TableRow
        link={link}
        parentRef={tableElemRow_ref}
        className={`TableElem__Row ${(expandCompConfig || link || parentHandleRowClick) ? '-clickable-' : ''}`}
        style={{
          ...(expandCompConfig || link || parentHandleRowClick
            ? { cursor: "pointer" }
            : {}),
          position: "relative",
        }}
        onClick={(e) => handleRowClick(e, data)}
      >
        {tableConfig.cellData.map((d,i) => (
          <TableCell
            tableConfig={tableConfig}
            rowIdx={rowIdx}
            cellIdx={i}
            key={d.colId}
            colWidthConfig={colWidthConfig}
            rowId={rowId}
            data={data}
            colConfig={d}
            handleCellChange={handleCellChange}
          />
        ))}
        {actions && (



         
          
            <ActionMenu
              M
              popOutOfOverflowHiddenParent
              align="right"
              actions={actions
                .map((d) =>
                  d.condition
                    ? conditionsConfig[d.condition](data)
                      ? {
                          display: d.display,
                          onClick: (e) => {
                            e.stopPropagation()
                            actionsConfig[d.action](e, data)
                          },
                        confirmAction:d.confirmAction
                        }
                      : undefined
                    : {
                        display: d.display,
                        onClick: (e) => {
                          e.stopPropagation()
                          actionsConfig[d.action](e, data)
                        },
                        confirmAction:d.confirmAction
                      }
                )
                .filter(Boolean)}
            />
          
         
        )}
      </TableRow>

      {expandCompConfig && ( //we need tat showExpandedComp stuff for the out-transition cuz it has a delay
          
          <StyledExpandCompContainer 
            id='ExpandCompContainer' 
            {...{
              showExpandComp, 
              contentsHeight: ExpandCompWrapperRef.current?.getBoundingClientRect().height
              }}
              >
            <StyledExpandCompWrapper>
              <div 
                style={{padding: '1rem', backgroundColor: colors.greyColor10}}
                ref={ExpandCompWrapperRef}
                >
                <ExpandComp
                  rowId={rowId}
                  parentRowRawData={data}
                  {...expandCompConfig.props}
                />
              </div>
            </StyledExpandCompWrapper>
          </StyledExpandCompContainer>
        )}
    </div>
  );
};

export default React.memo(TableRowElem);
