import React, { useEffect, useMemo, useRef } from 'react';
import { nanoid } from 'nanoid';
import { getVal, icons } from 'oolib';
import { handleDragOver } from './utils/handleDragOver';
import { handleDragEnd } from './utils/handleDragEnd';
import { handleInsertInOtherDraggableInstance } from './utils/handleInsertInOtherDraggableInstance';
import { useDraggableContext, useDragState } from './draggableContext';
import {
  StyledDraggableElemWrapper,
  StyledEmptyDraggableGhostDetector,
  StyledHandle,
  StyledIndicator,
} from './styled';

/**
 * We think that...
 *
 * This system with Draggable + draggableContext should work for
 * complex nested array situations as well.
 *
 * ------------------
 *
 * Known Issue:
 *
 * if all the elements are dragged out of a 'section',
 * and then you try to drag an element back in, that doesn't work
 * because the drag over listeners are the elements themselves, and not
 * the wrapper div
 *
 */
const { DotsSixVertical } = icons;
const Draggable = ({
  id,
  ary,
  renderChildren,
  handleReposition,
  idValPath: idValPathProp,
  className,
  style: styleProp,
  enableDraggableHandle = false,
  Wrapper = 'div',
}) => {
  let idValPath = idValPathProp || '_id';

  const parentRef = useRef(null);

  /**
   * If using context, very important that the elements across
   * different draggable should still have overall unique ids.
   *
   * Else bugs can be expected
   */

  /**
   * Use context if context is available. else use local state
   *
   * convenient to use localstate if using only one Draggable
   * cuz you dont want to have the overhead of wrapping it in a
   * context in the parent.
   */

  let dragState, setDragState, resetDragState;
  const localState = useDragState();
  const contextState = useDraggableContext();
  if (!contextState) {
    dragState = localState.dragState;
    setDragState = localState.setDragState;
    resetDragState = localState.resetDragState;
  } else {
    dragState = contextState.dragState;
    setDragState = contextState.setDragState;
    resetDragState = contextState.resetDragState;
  }

  const draggableInstanceId = useMemo(() => (id || '') + '_' + nanoid(8), []);

  useEffect(() => {
    /**
     * once the instance from where the dragElem is from,
     * is done removing the elem, it broadcasts a msg via context saying
     * 'begin the insert process of the elem in the instance where it has
     * been dragged over.
     *
     * This effect is handling that process
     */

    if (dragState?.beginInsertInOtherInstance) {
      handleInsertInOtherDraggableInstance({
        thisDraggableInstanceId: draggableInstanceId,
        dragState,
        handleDragOver,
        ary,
        idValPath,
        resetDragState,
        handleReposition,
      });
    }
  }, [dragState?.beginInsertInOtherInstance]);

  return (
    <Wrapper
      className={className || ''}
      ref={parentRef}
      style={{ ...styleProp, position: 'relative' }}
    >
      <StyledIndicator
        style={
          dragState.draggedOn_draggableInstanceId === draggableInstanceId
            ? { ...dragState.indicatorStyle }
            : { display: 'none' }
        }
      />
      {ary &&
        (ary.length === 0 ? [{ emptyDraggableGhostDetector: true }] : ary).map(
          (item, itemIdx) => {
            let id, ItemWrapper, renderedChildren;
            if (item.emptyDraggableGhostDetector) {
              id = 'emptyDraggableGhostDetector';
              ItemWrapper = StyledEmptyDraggableGhostDetector;
            } else {
              id = getVal(item, idValPath) || nanoid(4);
              ItemWrapper = enableDraggableHandle
                ? StyledDraggableElemWrapper
                : 'div';
              renderedChildren =
                renderChildren && renderChildren(item, itemIdx);
            }

            return (
              <ItemWrapper
                /** V.V Important. used by handleDragOver for touch events */
                id={id}
                //apply this style if currently this elem is being dragged
                style={{
                  opacity: dragState.dragElemId === id ? 0.99 : 1,
                }} /**super imp. else dragg shows 'ghosts' of siblings as well (on chrome) */
                className="dropzone" /** dropzone is V.V Important. used by handleDragOver for touch events */
                key={id}
                draggable={true}
                onTouchStart={(e) => {
                  e.stopPropagation();
                  setDragState((prev) => ({
                    ...prev,
                    dragElemId: id,
                    dragElem_draggableInstanceId: draggableInstanceId,
                  }));
                }}
                onDragStart={(e) => {
                  e.stopPropagation();
                  setDragState((prev) => ({
                    ...prev,
                    dragElemId: id,
                    dragElem_draggableInstanceId: draggableInstanceId,
                  }));
                }}
                onTouchMove={(e) => {
                  e.stopPropagation();
                  let overElem = document.elementFromPoint(
                    e.touches[0].clientX,
                    e.touches[0].clientY
                  );
                  handleDragOver(
                    e.touches[0].clientY,
                    overElem.id,
                    overElem,
                    [dragState, setDragState],
                    {
                      draggableInstanceId,
                      parentContainerTop:
                        parentRef.current.getBoundingClientRect().top,
                    }
                  );
                }}
                onDragOver={(e) => {
                  e.persist();
                  e.preventDefault();
                  e.stopPropagation();
                  let currentTarget = e.currentTarget;
                  handleDragOver(
                    e.clientY,
                    id,
                    currentTarget,
                    [dragState, setDragState],
                    {
                      draggableInstanceId,
                      parentContainerTop:
                        parentRef.current.getBoundingClientRect().top,
                    }
                  );
                }}
                onTouchEnd={(e) => {
                  e.stopPropagation();
                  handleDragEnd({
                    dragState,
                    ary,
                    idValPath,
                    handleDragOver,
                    resetDragState,
                    setDragState,
                    handleReposition,
                  });
                }}
                onDragEnd={(e) => {
                  e.stopPropagation();
                  handleDragEnd({
                    dragState,
                    ary,
                    idValPath,
                    handleDragOver,
                    resetDragState,
                    setDragState,
                    handleReposition,
                  });
                }}
              >
                {enableDraggableHandle && (
                  <StyledHandle>
                    <DotsSixVertical size={16} />
                  </StyledHandle>
                )}
                {renderedChildren}
              </ItemWrapper>
            );
          }
        )}
    </Wrapper>
  );
};

export default Draggable;
