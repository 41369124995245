import React, { useState } from 'react';
import { parseSpecialSyntax } from "../../../utils/parseSpecialSyntax";
import { useGenShareUrl } from '.';
import {  __GetContentTypeConfigNew } from "../../../utils/getters/gettersV2";
import { defaultListingConfigForCardOrList } from '../../../pageTpls/PublishedListing/config/defaultListingConfigForCardOrList';
import { getText, getVal, parseCardConfig } from 'oolib';
import { useQueryClient } from 'react-query';

const useShareContent = (contentId, contentType, content) => {
  const [shortUrl, setShortUrl] = useState(null);

  const contentTypeConfig = __GetContentTypeConfigNew(contentType);
   
  const listingConfig = ['CardsStyle', 'ListStyle'].includes(contentTypeConfig?.listing?.listingStyle)
        ? contentTypeConfig?.listing?.listingConfig.configs
        : defaultListingConfigForCardOrList.configs //read inside defaultListingConfigForCardOrList why we are doing this

  const extractedToShare = parseCardConfig({data:content, config: listingConfig, parseSpecialSyntax }) || {}

  const genShareUrl = useGenShareUrl()
  const queryClient = useQueryClient()

  const handleShareButtonClick = () => {
    genShareUrl.mutate(
      {
        contentId,
        contentType,
        title: extractedToShare.title,
        description: getText(getVal(content, [
          "main.subtitle",
          "main.summary",
          "main.kp_summary",
          "main.description",
          "main.kp_about",
        ])),
        url: window.location.href,
        image: extractedToShare.image && extractedToShare.image[0]?.publicUrl,
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(['Contributions', 'view', contentType, contentId])
          setShortUrl(res.data.shortUrl)
        },
      }
    );
  };

  return { handleShareButtonClick,  setShortUrl, shortUrl, genShareUrl };
};

export default useShareContent;