  import React from "react";
import { StyledCharLimitBanner } from "../../../styled";

export const CharLimitBanner = ({invert, charLimit, plainTextInEditor}) => {


    return (
        <StyledCharLimitBanner invert={invert}>
            <span className='warning'>{plainTextInEditor.length >= charLimit && "Character Limit Reached"}</span>
            <span >{plainTextInEditor.length}/{charLimit}</span>
          </StyledCharLimitBanner>
    )
}