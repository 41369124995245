import React from 'react'
import LexicalTextEditor from './TextEditor/LexicalTextEditor';
import { useAppSettingsContext } from '../../contexts/appSettingsContext';

export const StaticRichText = ({ label, richContent: _richContent }) => {
  const { APP_SETTINGS } = useAppSettingsContext();

  const richContent = _richContent.isLexical
    ? _richContent
    : _richContent[APP_SETTINGS.lang.value]; //else we have agreement in multiple languages

  return (
      <LexicalTextEditor
          label={label}
          value={richContent}
          readOnly={true}
      />
  )
}

