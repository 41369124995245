import {
  $applyNodeReplacement,
  TextNode,
} from 'lexical';

function $convertMentionElement(domNode) {
  const textContent = domNode.textContent;
  const id = domNode.getAttribute('data-mention-id');
  if (textContent !== null) {
    const node = $createMentionNode({ name: textContent, id });
    return {
      node,
    };
  }
  return null;
}

const mentionStyle = 'background-color:  #F4E8C3 ';

export class MentionNode extends TextNode {
  __mention;
  __id;

  static getType() {
    return 'mention';
  }

  static clone(node) {
    return new MentionNode(node.__mention, node.__id, node.__text, node.__key);
  }

  static importJSON(serializedNode) {
    const node = $createMentionNode({ name: serializedNode.mentionName, id: serializedNode.mentionId });
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(mentionName, id, text, key) {
    super(text ?? mentionName, key);
    this.__mention = mentionName;
    this.__id = id;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      mentionName: this.__mention,
      mentionId: this.__id,
      type: 'mention',
      version: 1,
    };
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    dom.style.cssText = mentionStyle;
    dom.className = 'mention';
    dom.setAttribute('data-mention-id', this.__id);
    return dom;
  }

  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention', 'true');
    element.setAttribute('data-mention-id', this.__id);
    element.textContent = this.__text;
    return { element };
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute('data-lexical-mention')) {
          return null;
        }
        return {
          conversion: $convertMentionElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true;
  }

  canInsertTextBefore() {
    return false;
  }

  canInsertTextAfter() {
    return false;
  }
}

export function $createMentionNode(mention) {
  const mentionNode = new MentionNode(mention.name, mention.id);
  mentionNode.setMode('segmented').toggleDirectionless();
  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(node) {
  return node instanceof MentionNode;
}