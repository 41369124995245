import styled from 'styled-components'
import {colors} from 'oolib'
const {none} = colors;
export const TopbarFilterStyled = styled.section`
  width: 100%;
  position: relative;

  .__filterInput {
    //dropdown override #cssoverride
    .OKE-Dropdown__Select,
    .OKE-Dropdown__ComboboxSelect {
      background-color: ${none} !important;

      &::after {
        background-color: ${none} !important;
      }
      &.focussed {
        background-color: ${none} !important;
      }
    }
  }
`

export const FilterLabelStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
`

export const FilterListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
