
import { ButtonGhost, icons, SANS_3, SANS_4_5 } from 'oolib';
import React, { useEffect } from "react";
import { DropdownSingle } from 'oolib';
import { useAppSettingsContext } from '../../../../contexts/appSettingsContext';
import { __CheckAccessToPrivatePlatform } from "../../../../utils/accessControl";

//config
import { useGetQueryData } from "../../../../utils/react-query-hooks/general";
import { HamLink } from './comps/HamLink';
import {
  StyledHumburgerMenu, StyledLinksWrapper, StyledMenuHeader, StyledMenuSection,
  StyledMenuSectionLabel
} from './index.styled';
import { genLinksConfigAsOptions } from './utils/genLinksConfigAsOptions';
import { trackEventGA } from '../../../../trackers/GA/trackEventGA';


const { LanguageIcon } = icons;


const HamburgerMenuV2 = ({ hamburgerOpen, setHamburgerOpen, _Nav, builderProps }) => {
  


  const { deployment: { _AppLanguageOptions }, } = useGetQueryData('platformConfigs');
  const { APP_SETTINGS, APP_SETTINGS_DISPATCH} = useAppSettingsContext();
  const userData = useGetQueryData('userData');


  useEffect(()=> {
    document.body.style.overflow = hamburgerOpen ? 'hidden' : 'auto';
    
    return ()=> document.body.style.overflow = 'auto'; //uppon comp removal reset body overflow to normal
  }, [hamburgerOpen])


  const closeHamburgerMenu = () => setHamburgerOpen(false);

  return (
    <StyledHumburgerMenu opened={hamburgerOpen} id={"okf-HamburgerMenuV2"}> {/* Id required of Coachmarks */}
      <StyledMenuHeader>
          <ButtonGhost
            className="right"
            icon="X"
            onClick={closeHamburgerMenu}
          />
          <SANS_3 bold className="left">Menu</SANS_3>
      </StyledMenuHeader>

    {_AppLanguageOptions && _AppLanguageOptions.length > 1 && 
        <StyledMenuSection style={{display: 'flex', alignItems: 'center', gap: '1.5rem'}} >
          <LanguageIcon />
          <SANS_4_5>Language</SANS_4_5>
          <DropdownSingle
            value= {APP_SETTINGS.lang}
            options={_AppLanguageOptions}
            onChange={(k, v) => {
              
              trackEventGA("Language Flow","HamBurger Language Change",`${v?.value}`)
              APP_SETTINGS_DISPATCH({ type: "SET_LANG", payload: v})
            }}
          />
        </StyledMenuSection>
      }
      { __CheckAccessToPrivatePlatform(userData?.user) &&
        genLinksConfigAsOptions(_Nav).map(item => {
          if(item.folder || item.menu){ //menu is the deprecated term
            let thisFolder = item.folder || item.menu;
            return (thisFolder.length > 0 || !!builderProps) && (
              <StyledMenuSection id={item.display || item.value} key={item.display}>
                  {item.display &&
                      <StyledMenuSectionLabel id={item.display}>
                        {item.display}
                      </StyledMenuSectionLabel> }
                      <StyledLinksWrapper>
                          {thisFolder.map(d => <HamLink key={d.value} thisNavConfig={d} to={d.to} display={d.display} onClick={closeHamburgerMenu}/>)}
                      </StyledLinksWrapper>
                  </StyledMenuSection>
            )   
          }else{
            return <StyledMenuSection key={item.value} id={item.display || item.value}>
              <StyledLinksWrapper>
                <HamLink thisNavConfig={item} to={item.to} display={item.display} onClick={closeHamburgerMenu}/>
              </StyledLinksWrapper>
            </StyledMenuSection>
          }
        })
      }
      
    </StyledHumburgerMenu>
  );
  
}

export default HamburgerMenuV2