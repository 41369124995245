import styled from 'styled-components'
import { ellipsis } from 'oolib';
export const ExpandingCardContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 40rem;
  height: 100%;
  /* overflow: hidden; */


  border-left: 1px solid #e1e1e1; //grey15
`;

export const ModalLargeWrapperStyled = styled.div`
#StyledLinkText{
   ${ellipsis}
  }
`


export const ExpandingCardHeaderStyled = styled.div`
  background-color: #f5f5f5; //greyColor5
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;

  #StyledLinkText{
    ${ellipsis}
  }
`;

export const ExpandingCardBodyStyled = styled.div`
  padding: 2rem;
  overflow-y: auto;
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;


export const NotesWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;