/*
* The function takes an element as a parameter and calculates the position of the element within the viewport using the 
* getBoundingClientRect() method. It then finds out the height of the element and the height of the viewport.

* If the element's height is smaller than the viewport height, 
* then function calculates the target scroll position by subtracting half of the difference between the viewport height and 
* the element height from the top position of the element. 
* This centers the element vertically.

* If the element's height is larger than the viewport height, 
* the function sets the target scroll position to the top position of the element.

* at the end, the function calculates the maximum scroll position that the viewport allows and ensures that 
* the target scroll position does not exceed it. It then scrolls the window to the target scroll position using the 
* scrollTo() method with the behavior option set to "smooth".*/


export const scrollElementToCenter = (element, locationPath) => {
    if(locationPath.includes("menu=true")){

        element?.scrollIntoViewIfNeeded ? element.scrollIntoViewIfNeeded(true) :  element.scrollIntoView()
    
    }else{  
        const elementRect = element.getBoundingClientRect();
        const elementHeight = elementRect.height;
        const viewportHeight = window.innerHeight;
        
        let targetScrollTop;
        
        if (elementHeight < viewportHeight) {
            // Element is smaller than viewport, center it
            targetScrollTop = elementRect.top + window.scrollY - (viewportHeight - elementHeight) / 2;
        } else {
            // Element is larger than viewport, scroll to top
            targetScrollTop = elementRect.top + window.scrollY;
        }
        
        const maxScrollTop = document.documentElement.scrollHeight - viewportHeight;
        targetScrollTop = Math.min(targetScrollTop, maxScrollTop);
    
  
        window.scrollTo({
            top: targetScrollTop,
            behavior: "smooth"
        });
    }
}
