import {
  colors,
  getBreakPoint,
  Modal,
  SANS_2,
  getVal,
  useScreenWidth,
  getText,
} from "oolib";
import React from "react";

import { createContentCardDate } from "../../../../utils/momentManipulate";

import { BlocksBuilder } from "../CardBuilder";
import SideCardExpand, { StickyWrapper } from "../SideCardExpand";
import { ModalLargeWrapperStyled } from "../SideCardExpand/styled";

const { greyColor15 } = colors;

// const CommonBodyConfig = {
//   immersion1KenyaKS: {
//     content: [
//       {
//         comp: "KeywordHighlight",
//         valuePath: "highlight",
//       },
//     ],
//   },
//   SecondImmersionKenyaKS: {
//     content: [
//       {
//         comp: "KeywordHighlight",
//         valuePath: "highlight",
//       },
//     ],
//   },
//   ThirdImmersionKenyaKS: {
//     content: [
//       {
//         comp: "KeywordHighlight",
//         valuePath: "highlight",
//       },
//     ],
//   },
//   gigWorkerSurveysKS: {
//     content: [
//       {
//         comp: "KeywordHighlight",
//         valuePath: "highlight",
//       },
//     ],
//   },
//   secondaryResearchGigWorkersResearchStudiesKS: {
//     content: [
//       {
//         comp: "KeywordHighlight",
//         valuePath: "highlight",
//       },
//     ],
//   },
//   stockResearchNotesKS: {
//     content: [
//       {
//         comp: "KeywordHighlight",
//         valuePath: "highlight",
//       },
//     ],
//   },


//   get: function (ct) {
//     const map = {
//       immersion1KenyaKS: "immersion1KenyaKS",
//       SecondImmersionKenyaKS: "SecondImmersionKenyaKS",
//       ThirdImmersionKenyaKS: "ThirdImmersionKenyaKS",
//       gigWorkerSurveysKS: "gigWorkerSurveys",
//       secondaryResearchGigWorkersResearchStudiesKS:
//         "secondaryResearchGigWorkersResearchStudies",
//       stockResearchNotesKS: "stockResearchNotes",
//     };

//     return this[`${map[ct]}`];
//   },
// };

export const CommonBodyComp = ({
  annoTagType,
  resource,
  tagId,
  listingExpandCompConfig,
}) => {
  const config =
    (listingExpandCompConfig &&
      listingExpandCompConfig[resource?.meta?.kp_content_type]) ||
    listingExpandCompConfig ||
    {
      content: [
        {
          comp: "KeywordHighlight",
          valuePath: "highlight",
        },
      ],
    }; // for search dashboard cards

  if (!config) return null;

  return (
    <div
      style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      key={resource._id}
    >
      <BlocksBuilder
        annoTagType={annoTagType}
        resource={resource}
        config={config.content}
        tagId={tagId}
      />

      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          gap: "2rem",
        }}
      >
        <BlocksBuilder
          annoTagType={annoTagType}
          resource={resource}
          config={config.tags}
          tagId={tagId}
        />
      </div>
      <div>
        <BlocksBuilder
          annoTagType={annoTagType}
          resource={resource}
          config={config.tplBlocks}
          tagId={tagId}
        />
      </div>
    </div>
  );
};

export default function CardExpandComps({
  handleClose,
  expandCard,
  annoTagType,
  tagId,
  searchTerm,
  stickBelowElementRef,
  listingExpandCompConfig,
}) {
  const linkCardTo = searchTerm
    ? `/published-page/${expandCard.meta.kp_content_type}?id=${expandCard._id}`
    : `/published-page/${expandCard.meta.kp_content_type}?id=${expandCard._id}&activeView=synth&activeSection=${annoTagType?.value}&activeAccordion=${tagId?.value}`;

  const title = getText(getVal(expandCard, "main.title"));

  const screenWidth = useScreenWidth();

  const isMobile = screenWidth <= getBreakPoint("sm");

  const date = createContentCardDate(
    expandCard.meta.date || expandCard.kp_date_published
  );

  return (
    <>
      {isMobile ? (
        <ModalLargeWrapperStyled>
          <Modal title={title} onClose={handleClose} linkTo={linkCardTo}>
            <div
              style={{
                padding: ".5rem 2rem",
                borderBottom: `1px solid ${greyColor15}`,
              }}
            >
              <SANS_2>
                {tagId?.display} | {date}
              </SANS_2>
            </div>
            <div
              style={{
                padding: "2rem",
                display: "flex",
                gap: "2rem",
                flexDirection: "column",
              }}
            >
              <CommonBodyComp
                annoTagType={annoTagType?.value}
                resource={expandCard}
                tagId={tagId}
                listingExpandCompConfig={listingExpandCompConfig}
              />
            </div>
          </Modal>
        </ModalLargeWrapperStyled>
      ) : (
        <StickyWrapper ref={stickBelowElementRef} fullHeight>
          <SideCardExpand
            handleClose={handleClose}
            annoTagType={annoTagType?.value}
            linkCardTo={linkCardTo}
            title={title}
            tagId={tagId}
            date={date}
            resource={expandCard}
            listingExpandCompConfig={listingExpandCompConfig}
          />
        </StickyWrapper>
      )}
    </>
  );
}
