import React from "react";
import {icons} from 'oolib'

const {CopySimple} = icons

export const Copy = ({color}) => {
  return (
    <div>
      <CopySimple color={color} size={20} />
    </div>
  );
};
