import React, { useEffect, useState } from "react";


import {
  ButtonPrimary,
    DropdownSingle,
    ModalSmall,
    PaddingTop20,
    SANS_4_5,
    SANS_5_6
} from "oolib";
import { useAppSettingsContext } from "../contexts/appSettingsContext";
import { useGetQueryData } from "../utils/react-query-hooks/general";

/**
 * this shows up only if the application has more than 1 language configured,
 * AND the user has never actively selected one language.
 * 
 * Once the user makes this selection via the modal, it never
 * shows up again.
 * 
 * Unless local storage is cleared. since we are using localstorage to remember
 * this user selection
 */
export const LangSelectModal = () => {

  const { APP_SETTINGS, APP_SETTINGS_DISPATCH } = useAppSettingsContext();

  const {deployment} = useGetQueryData('platformConfigs')

  const [showFirstTimeLangSelectModal, setShowFirstTimeLangSelectModal] =
    useState(false);

  useEffect(() => {
    if (!localStorage.getItem("APP_LANG") && _AppLanguageOptions?.length > 1) {
      setShowFirstTimeLangSelectModal(true);
    }
  }, []);

  const {
    deployment: { _AppLanguageOptions },
  } = useGetQueryData("platformConfigs");

  const LangDropdown = (
    <DropdownSingle
    label='Choose a platform language'
      value={APP_SETTINGS.lang}
      options={_AppLanguageOptions}
      onChange={(k, v) => {
        APP_SETTINGS_DISPATCH({
          type: "SET_LANG",
          payload: v,
        });
        
      }}
      optionsClassName={"SANS_2"}
      selectClassName={"SANS_2"}
    />
  );

  return (
    <>
      {showFirstTimeLangSelectModal && (
        <ModalSmall 
          disableHeader
          title="Choose a platform language">
            <SANS_5_6>{`Welcome to ${deployment._DeploymentDisplayName}!`}</SANS_5_6>
            <PaddingTop20/>
          {LangDropdown}
          <PaddingTop20/>
          <ButtonPrimary
            children={'Proceed'}
            onClick={() => {
              APP_SETTINGS_DISPATCH({
                type: "SET_LANG",
                payload: APP_SETTINGS.lang,
              });
              if(showFirstTimeLangSelectModal){
                setShowFirstTimeLangSelectModal(false)
              }
            }}
            />
        </ModalSmall>
      )}
    </>
  );
};
