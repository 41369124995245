import {
    $isMarkNode
} from "@lexical/mark";
import { toggleTagTypesLookup } from "../AnnoLightbox/configs/toggleTagTypesLookup";

/**
 * @description this function is called inside the
 * Mark node mutation listener in AnnoPlugin.
 *
 * Its job is to:
 * For every mutated Mark Node, identify if it is either
 * - an overlapping one : in which case apply the appropriate 'overlap className' to its DOM element
 * - a toggle tag type anno : in which case apply the appropriate 'color className' to its DOM element
 */
export const applyClassesForAnnoColor = ({
    element,
    latestAnnoDataRef,
    ids,
    node
}) => {
  
  const prevClassesAry = element?.classList.toString().split(" ") || [];

  if (element !== null) {
    /**
     * ids represents the dataIds behind this annotation.
     * so lets get the actual data behind. And if in even one of those datas,
     * tags.vocabs.data[0] exists then: -is-vocabs-
     * tags.quotes.data[0] exists then: -is-quotes-
     */
    const annoDataAgainstTheseIds = latestAnnoDataRef?.current
      ? ids.map((dataId) => latestAnnoDataRef.current[dataId])
      : [];

    toggleTagTypesLookup.forEach(({ tagType, className }) => {
      if (
        annoDataAgainstTheseIds.some((aD) => {
          return aD?.tags && aD?.tags[tagType]?.data?.length > 0;
        })
      ) {
        //if this class doesnt already exist then add it.
        if (!prevClassesAry.find((cN) => cN === className)) {
          element.classList.add(className);
        }
      } else {
        //if this class already exists then remove it.
        if (prevClassesAry.find((cN) => cN === className)) {
          element.classList.remove(className);
        }
      }
    });

    /**
     * handle the setting of overlap classes.
     */
    if (ids.length > 1 && $isMarkNode(node)) {
      //then we need to inject the overlap depth class which will control the css opacity of overlapping annotations

      const prevOverlapDepthClass = prevClassesAry.find((className) =>
        className.startsWith("-overlap-depth-")
      );
      if (prevOverlapDepthClass)
        element.classList.remove(prevOverlapDepthClass);
      const newOverlapDepthClass =
        ids.length >= 4
          ? "-overlap-depth-4plus-"
          : `-overlap-depth-${ids.length}-`;
      element.classList.add(newOverlapDepthClass);
    } else if (
      //this could happen if a double anno existed on this node,
      //and then one of the annos is removed
      element !== null &&
      ids.length === 1
    ) {
      const prevClassesAry = element.classList.toString().split(" ");
      const prevOverlapDepthClass = prevClassesAry.find((className) =>
        className.startsWith("-overlap-depth-")
      );
      if (prevOverlapDepthClass) {
        element.classList.remove(prevOverlapDepthClass);
      }
    }
  }
};
