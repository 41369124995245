import { $getSelection, $isRangeSelection, $setSelection } from "lexical";
import { toggleLink } from "@lexical/link";
import { injectHttps } from "oolib";

//if link is set to null, it will remove the link
export const handleToggleLink = ({ editor, link, selection: _selection, callback }) =>
  editor.update(() => {
    
    if(_selection) $setSelection(_selection) //if selection is explicitly provided, then set that to the editor
    
    const selection = _selection || $getSelection();

    if ($isRangeSelection(selection)) {
      toggleLink(link ? injectHttps(link) : link, { target: "_blank" });
    }

    callback && callback();
  });
