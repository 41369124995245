import React, { memo, useCallback, useState } from "react";

import {
  ButtonPrimary,
  ImageInput,
} from "oolib";


export const MediaItem = memo(({ item, index, onItemClick }) => {
    const [showDwBtn, setShowDwBtn] = useState(false);
  
    const handleMouseOver = useCallback(() => setShowDwBtn(true), []);
    const handleMouseOut = useCallback(() => setShowDwBtn(false), []);
    const handleClick = useCallback(() => onItemClick(index), [index, onItemClick]);
  
    const handleDownload = useCallback((e) => {
      e.stopPropagation();
      window.open(item.mediaLink, "_blank");
    }, [item.mediaLink]);
  
    return (
      <div
        key={item.id}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        onClick={handleClick}
        style={{ position: "relative", cursor: "pointer" }}
      >
        {showDwBtn && (
          <ButtonPrimary
            style={{
              position: "absolute",
              right: "5px",
              top: "5px",
              zIndex: 10,
            }}
            icon="DownloadSimple"
            onClick={handleDownload}
          />
        )}
        <ImageInput
          readOnly
          value={[{ ...item, imageSpread: "cover" }]}
          enableCaptions={false}
        />
      </div>
    );
  });