import React from 'react'
import { StyledMainContent } from './styled'
import { Sidebar } from './comps/Sidebar'
import { ContentSection } from './comps/ContentSection'

export const SIDEBAR_WIDTH = 300


export const Shell = ({ filter, contentConfig, isMobile, actionBarHeight, tabsPanelHeight, showSideBarLeft, setShowSideBarLeft }) => {
  return (
    <StyledMainContent showSideBarLeft={showSideBarLeft} sidebarWidth={SIDEBAR_WIDTH} isMobile={isMobile}>
      <Sidebar
        showSideBarLeft={showSideBarLeft}
        filter={filter}
        actionBarHeight={actionBarHeight}
        tabsPanelHeight={tabsPanelHeight}
        isMobile={isMobile}
        setShowSideBarLeft={setShowSideBarLeft}
      />
      <ContentSection
        showSideBarLeft={showSideBarLeft}
        contentConfig={contentConfig}
        isMobile={isMobile}
      />
    </StyledMainContent>
  )
}