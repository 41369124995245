import axios from 'axios';
import { useMutation, useQuery } from "react-query";
import { useGetQueryData } from './general';

export const useGetFile =(fileName)=> {
  return useQuery(
    [fileName],
    async()=> {
      const {data} = await axios.get(
        `/api/mediaUpload/getFile/${encodeURIComponent(
          fileName,
        )}`,
      );
      return data;
  })
}


export const useUploadMedia = ({mediaConfigs, handleUploadProgess}) => {
  const {deployment: { _MaxBackendUploadSize }} = useGetQueryData('platformConfigs');

  mediaConfigs = JSON.stringify(mediaConfigs);

  return useMutation(
    (files) => uploadMedia({
      files,
      mediaConfigs,
      handleUploadProgess,
      _MaxBackendUploadSize
    })
  )
}

const splitBasedOnSize =({files, _MaxBackendUploadSize})=> {
  const smallFiles = [];
  const largeFiles = [];
  for (let file of files){
    (file.size / 1000000 > _MaxBackendUploadSize? largeFiles: smallFiles).push(file)
  }
  return {largeFiles, smallFiles}
}



const postSmallFiles = async({formData, mediaConfigs, axiosConfig})=> {
  return axios.post(
    `/api/media/uploadMediaToGCS?configs=${encodeURIComponent(mediaConfigs)}`,
    formData,
    axiosConfig
  )
}
const postLargeFiles = async({files, axiosConfig, mediaConfigs})=>{

  let promises = files.map(async file=> {
    try{
        const formData = new FormData();
        formData.set('0',  new File([file.slice(0, 500001)], file.name, {type: file.type}))
        
        const signedUrlsResp = await axios.post(
          `/api/media/createSignedUrl?configs=${encodeURIComponent(mediaConfigs)}`,
          formData
        );
        
        const newAxiosInstance = axios.create();

        // the signed url api doesn't support withCredential header
        // can't override the default header, so creating a new instance
        delete newAxiosInstance.defaults.withCredentials;



        await newAxiosInstance.put(signedUrlsResp.data[0].signedUrl, file, {
          ...axiosConfig,
          headers: { "Content-Type": file.type },
        });
          
        const fileResp = await axios.get(`/api/mediaUpload/getFile/${encodeURIComponent(signedUrlsResp.data[0].fileNameInGcs)}`)

        return {status: 'success', fileData: fileResp.data}

    } catch(err){
        return ({status: 'error', message: err.response?.data.errors[0].msg || err.message})
    }
  })

  let results = await Promise.all(promises);

  return results;

}

const uploadMedia = async ({files, mediaConfigs, handleUploadProgess, _MaxBackendUploadSize}) => {
  const axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
    onUploadProgress: (ev) => {
      handleUploadProgess &&
        handleUploadProgess(
          Math.round((100 * ev.loaded) / ev.total),
        )
    },
  }
  try{ 
    const {largeFiles, smallFiles} = splitBasedOnSize({files, _MaxBackendUploadSize})
    
    let smallRes = [];
    let largeRes = [];

    if (smallFiles.length){
      const smallFormData = smallFiles.reduce((formData, f, idx)=> {
        formData.set(idx, f)
        return formData;
      }, new FormData());
      

      smallRes = await postSmallFiles({axiosConfig, mediaConfigs, formData: smallFormData});
      /*
      * if all statuses of smallRes = 'error' the promise would be rejected (error raised on tha backen)
      * which would reject this promise altogether, thereby causing the mutation to fire the 'onError' callback'
      * if however, even 1 status === success, promise resolves, and moves to the next step
      */
    }
    
    if (largeFiles.length){

      largeRes = await postLargeFiles({
        files:largeFiles,
        axiosConfig,
        mediaConfigs
      });
      /*
      * no matter what, postLargeFiles will always resolve the promise
      * i.e even if all largeRes statuses = 'error' (assuming at least one small file is sucessfully uploaded)
      */
    }
    
    const allRes = largeRes.concat(smallRes.data || smallRes); // in case no smallFiles uploaded (.data === undefined), so I'm falling back to the emoty arra instead
  
    /**
     * i think:
     * this scenario is only possible if 
     * there were no small file uploads
     * AND all large file uploads fail
     */
    if (allRes.every(res=> res.status !== 'success')) {
      if (allRes.length === 1) throw new Error(allRes[0].message)
      throw new Error(allRes.map(
        (r,i) => i <= 3 
          ? r.message
          : r === 3 
            ? `+ ${allRes.length - 3} more`
            : ''
      ).join(', '))
    }

    return allRes;

  }catch(err){
      throw err;
  }

      
}