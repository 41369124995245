import React, { useState, createContext, useContext } from "react";

export const DraggableContext = createContext();

export const useDragState = () => {
  const initDragState = {
    indicatorStyle: {
      top: 0,
      display: "none",
    },
    dragElemId: undefined,
    dragElem_draggableInstanceId: undefined
  };
  const [dragState, setDragState] = useState(initDragState);

  const resetDragState = () => {
    setDragState(initDragState);
  } //reset

  return {
    dragState,
    setDragState,
    resetDragState
  }
}

export const DraggableContextProvider = ({ children }) => {
  

  
  const {dragState, setDragState, resetDragState : _resetDragState} = useDragState()

  const resetDragState = () => {
    console.log('CONTEXTTTTT');
    _resetDragState()
  }

  return (
    <DraggableContext.Provider
      value={{dragState, setDragState, resetDragState}}
    >
      {children}
    </DraggableContext.Provider>
  );
};

export const useDraggableContext = () => useContext(DraggableContext);
