
export function getCursorPosRect(nativeSelection, editorHTMLNode) {
  const domRange =
  nativeSelection && nativeSelection.rangeCount !== 0 && nativeSelection.getRangeAt(0);

  if (!domRange) return undefined;

  //no idea what this condition means. picked this code up from lexical-playground/src/utils
  let rect;
  
  const calcXAndBottomFromEditor = (rect) => ({
    xFromEditor: rect.x - editorHTMLNode?.getBoundingClientRect()?.x,
    bottomFromEditor: rect.bottom - editorHTMLNode?.getBoundingClientRect()?.y
  })

  if (nativeSelection.anchorNode === editorHTMLNode) {
    let inner = editorHTMLNode;
    while (inner.firstElementChild != null) {
      inner = inner.firstElementChild;
    }
    rect = inner?.getBoundingClientRect() 
    return ({
      ...rect,
      ...calcXAndBottomFromEditor(rect)  
    });
  } else {
    rect = domRange?.getBoundingClientRect()
    return ({
      ...rect,
      ...calcXAndBottomFromEditor(rect)
    })
  }
  
}