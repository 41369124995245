import { useHandleClickOutside } from "oolib";
import React, { useRef } from "react";

export const AnnoLightboxShell = ({
  position,
  handleClickOutside,
  children
}) => {
  const ContainerRef = useRef();
  // handleOutside click
  useHandleClickOutside(ContainerRef, handleClickOutside);

  return (
    <div
      ref={ContainerRef}
      style={{
        position: "absolute",
        zIndex: 200,
        left: `${position?.x}px`,
        top: `${position?.y}px`,
      }}
    >
      {children}
    </div>
  );
};