import {sortData} from 'oolib'


export const handleTableSort_FRONTEND = ({
	thisColConfig,
	data,
	activeSorts,
	setData,
	setActiveSorts,
	value
}) => {
	let { sortedData, newActiveSorts } = handleSortTableData(
		data,
		thisColConfig,
		activeSorts,
		value
	);
	setData(sortedData);
	setActiveSorts(newActiveSorts)
}


const handleSortTableData = (
	data,
	thisColConfig,
	activeSorts,
	value
) => {
	let newActiveSorts = updateActiveSorts(activeSorts, thisColConfig, value)
	let sortBy = newActiveSorts[thisColConfig.colId]
	
	 //sort v3 break ref to og object
	
	console.log('this fired')
	 let sortedData = sortData({
		data, 
		sortBy,
		...thisColConfig.sortConfig 
	})

	
	return ({sortedData, newActiveSorts})
}


const updateActiveSorts = (activeSorts, d, value) => {
	let prev = { ...activeSorts }

	let newActiveSorts = {}
	Object.keys(prev).map((prevK) => {
		if (prevK === d.colId) {
			newActiveSorts[prevK] = value
		} else {
			newActiveSorts[prevK] = 'none'
		}
	})

	return newActiveSorts
}