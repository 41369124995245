import { sortData } from "oolib";

export const sortCellDataAsPerOrderConfig = (tableConfig) => {

    const { cellDataOrder = [] } = tableConfig;
  
    let ordered = [], unordered = [];
    tableConfig.cellData.forEach(bl => {
      let orderIndex = cellDataOrder.findIndex(d => d === bl.colId);
      if(orderIndex !== -1){
        ordered.push({...bl, orderIndex})
      }else{
        unordered.push(bl)
      }
    })
    ordered = sortData({
      data: ordered, 
      fn: 'numerical', 
      path: 'orderIndex',
      sortBy: 'a'
    })
  
    return {
      ...tableConfig,
      cellData: [
        ...ordered,
        ...unordered
      ]
    } 
  }