import { SANS_4_5, getBreakPoint, useScreenWidth } from "oolib";
import React from "react";
import { Link } from "react-router-dom";
import { __CheckAccessToPrivatePlatform } from "../../../../../../../utils/accessControl";

import { useGetQueryData } from "../../../../../../../utils/react-query-hooks/general";
import { usePlatformBuilderContext } from "../../../../../../../contexts/platformBuilderContext";
export const HeaderLogo = ({config}) => {
  const {
    deployment: { _Logos = {} },
  } = config;

  const { user } = useGetQueryData("userData") || {};
  const {platformBuilderMode} = usePlatformBuilderContext()
  const screenWidth = useScreenWidth();

  const {
    logo,
    logoShort,
    logoHeight,
    logoAltText,
    logoShortAltText,
    logoShortHeight,
    fallbackText,
    fallbackInitials,
  } = _Logos;

  const LG = screenWidth >= getBreakPoint("lg");

  const getLogoHeight = () => {
    if(LG){
        return (!logoHeight || logoHeight > 60)
            ? '100%' //which will take the height of the parent container i.e 40px
            : `${logoHeight}px`
    }else{
        return (!logoShortHeight || logoShortHeight > 60)
            ? '100%' //which will take the height of the parent container i.e 40px
            : `${logoShortHeight}px`
    }
  }

  const genLogo = () => {
    if (logo) {
      return (
        <img
          style={{height: getLogoHeight()}}
          src={LG ? logo : logoShort}
          alt={LG ? logoAltText : logoShortAltText}
        />
      );
    } else {
      //fallback text
      return (
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <SANS_4_5 semibold>
            {LG
              ? fallbackText || "Open Knowledge Framework"
              : fallbackInitials || "OKF"}
          </SANS_4_5>
        </div>
      );
    }
  };

  const logoShellStyle = {
    height: '4rem',
    display: 'flex',
    alignItems: 'center'
  }

  return __CheckAccessToPrivatePlatform(user) ? (
    platformBuilderMode ? 
      <div style={logoShellStyle}>{genLogo()}</div> 
    :
      <Link style={logoShellStyle} to="/">
        {genLogo()}
      </Link> 
  ) : (
    <div style={logoShellStyle}>{genLogo()}</div>
  );
};
