

import { SANS_2, colors } from "oolib";
import React from "react";
import styled from "styled-components";


const CountContainerStyled = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: ${colors.greyColor100};
  border-radius: 50%;
  text-align: center;
`;

export const BadgeNumber = ({ number }) => {
    return (
      <CountContainerStyled>
        <SANS_2 color="white" bold>
          {number}
        </SANS_2>
      </CountContainerStyled>
    );
  };