import { LanguageIcon } from "oolib/dist/icons/custom";
import React from "react";


import { useGetQueryData } from "../../../../../../../utils/react-query-hooks/general";

import {
  DropdownSingle,
  getBreakPoint,
  useScreenWidth
} from "oolib";
import { trackEventGA } from "../../../../../../../trackers/GA/trackEventGA";
import { useAppSettingsContext } from "../../../../../../../contexts/appSettingsContext";

export const HeaderLanguageSelect = ({
  showOnlyOnLG = true,
  paddingLeft = "2rem"
}) => {
  const screenWidth = useScreenWidth();

  const { APP_SETTINGS, APP_SETTINGS_DISPATCH } = useAppSettingsContext();

  const {
    deployment: { _AppLanguageOptions },
  } = useGetQueryData("platformConfigs");

  const LangDropdown = (
    <DropdownSingle
      value={APP_SETTINGS.lang}
      options={_AppLanguageOptions}
      onChange={(k, v) => {
        trackEventGA(
          "Translation Flow",
          "PrimaryHeader Translate",
          `${v?.value}`
        );
        APP_SETTINGS_DISPATCH({
          type: "SET_LANG",
          payload: v,
        });
      }}
      optionsClassName={"SANS_2"}
      selectClassName={"SANS_2"}
    />
  );
const screenWidthCheck = showOnlyOnLG 
?  screenWidth >= getBreakPoint("lg")
: true
  return (
    <>
      {_AppLanguageOptions &&
        _AppLanguageOptions.length > 1 &&
        screenWidthCheck && (
          <div
            style={{
              paddingLeft,
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
            id="header_language_select" /** Id is v. important for coachmark to work */
          >
            <LanguageIcon />
            {LangDropdown}
          </div>
        )}
    </>
  );
};
