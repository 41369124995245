import { cloneDeep } from "lodash";
import React, { createContext, useContext, useRef, useState } from "react";

export const AnnoContextLex = createContext();

export const AnnoContextProviderLex = ({children, value}) => {

  /**
   * note that we have a listener in OnChangePlugin
   * which listens for any changes in annoData, and 
   * triggers off the parentOnChange function to send
   * updated data to parent component
   */
  const [annoData, setAnnoData] = useState(value?.annoData);
  /**
   * need this, to access latest annoData inside of MarkNode mutation listener in AnnoPlugin.
   * Why? cuz this latest annoData helps us figure out whether to paint an anno yellow OR
   * blue or pink (cuz of toggle tag types)
   * 
   * Hacky yes. but a lot of this anno stuff is quite hacky..
   */
  const latestAnnoDataRef = useRef(value?.annoData)

/**
 * @description
 * 
 * type can be 'tags' for now. in the future it could 
 * be 'comments' and such. This separation will help
 * in the future
 */
const handleSetAnnoData = ({type = 'tags', dataId, data, disableParentOnChange}) => {
  setAnnoData(prev => {
    const toReturn = ({
      ...(disableParentOnChange !== undefined ? {disableParentOnChange} : {}), //this is important, else it will cause bugs in OnChangePlugin > filterOutUnusedAnnoData
      ...(prev || {}),
      [dataId]: {
        ...(prev ? (prev[dataId] || {}) : {}),
        [type]: data
      }
    })
    latestAnnoDataRef.current = toReturn;
    return toReturn
  }
  )
}


/**
 * marknode obj looks like:
 * {
 *   <annoDataId> : <fragment_as_serialized_editorState>,
 *   ...
 * }
 * 
 * annoData looks like: 
 * {
 *   <annoDataId> : { tags: {...}, fragment: {...} }
 * }
 * 
 * so we simply replace all fragments with the new fragments from markNodeObj
 */
const handleUpdateFragmentsInAnnoData = (markNodeObj) => {
  setAnnoData(prev => {
    const newAnnoData = prev ? cloneDeep(prev) : {};
    delete newAnnoData.disableParentOnChange;
    const toReturn = Object.keys(newAnnoData).reduce(
      (a,b) => {
        const thisFragment = markNodeObj[b];
        /**
         * basically if this fragment doesnt exist
         * anymore, simply leave it as it is, and make it as IS_UNUSED
         * 
         * We dont want to delete the annoData because if a redo happens, we
         * should be able to link the fragment with its data via the id
         */
        if(thisFragment){
          const newB = cloneDeep(newAnnoData[b])
          delete newB.IS_UNUSED;
          return ({
            ...a,
            [b]: {
              ...newB,
              fragment: markNodeObj[b]
            }
          })  
        }else{
          return ({
            ...a,
            [b]: {
              ...newAnnoData[b],
              IS_UNUSED: true
            }
          })
        }
        },
      {}
    )
    latestAnnoDataRef.current = toReturn;
    return toReturn;
  })
}


  return (
    <AnnoContextLex.Provider
      value={{
       annoData,
       latestAnnoDataRef,
       handleSetAnnoData,
       handleUpdateFragmentsInAnnoData
      }}
    >
      {children}
    </AnnoContextLex.Provider>
  );
};

export const useAnnoContextLex = () => {
  return useContext(AnnoContextLex);
};
