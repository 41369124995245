import axios from "axios"
import { useQuery } from "react-query"




export const useGetOgs = ({url, ...options}) => {
	const query = useQuery(['ogs', url], 
		() => axios.get(`/api/ogs/${encodeURIComponent(url)}`).then(res => res.data),
		{
			...options
		}
	)
	 return query
}
