import { getTenant } from "../../../TENANT_CONFIGS";

export const defaultListingStyle = "CardsStyle";

//this is a very quick shabby thing that i have done.
//but my sense is, we should fallback to a default sort
//irrespective of configs being there in the database
export const defaultSortConfig =
  getTenant() === "gelabs"
    ? [
        {
          display: "Latest",
          sort: { kp_date_published: -1 },
          value: "newest",
        },
        {
          display: "Oldest",
          sort: { kp_date_published: 1 },
          value: "oldest",
        },
        {
          display: "Title (A-Z)",
          sort: { "main.title": 1 },
          value: "acending",
        },
        {
          display: "Title (Z-A)",
          sort: { "main.title": -1 },
          value: "decending",
        },
      ]
    : [
        {
          display: "Latest",
          sort: { kp_date_published: -1 },
          value: "newest",
        },
        {
          display: "Oldest",
          sort: { kp_date_published: 1 },
          value: "oldest",
        },
      ];
