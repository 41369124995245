import React, { Fragment, useEffect } from "react";
//components
import { ButtonSecondary , colors, Loader, useBannerContext} from 'oolib'

//colors

//utils
import { removeBlock, replaceEntityData } from '../../../../../utils/richInputUtils';
import { imageUploader } from "../../../../../utils/general";



const { greyColor10 } = colors; 
 
const KPRichInlineImage = (props) => {
	const handleRemoveImage = () => {
		const { editor, block } = props
		removeBlock(editor, block)
	}

	const {SET_ALERT_BANNER} = useBannerContext()

	useEffect(() => {
		if (props.formData) {
			imageUploader(props.formData, { type: 'images', SET_ALERT_BANNER }, (status, resData) => {
				replaceEntityData(
					props.editor,
					props.entityKey,
					{ src: resData[0].publicUrl, loading: false }, //replace the image blocks entity data with actual image data
				)
			})
		}
	}, [])

	return (
		<Fragment>
			{props.loading ? (
				<div
					style={{
						height: '10rem',
						borderRadius: '2px',
						backgroundColor: greyColor10,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Loader S />
				</div>
			) : (
				<div className='kp-inline-img-wrapper'>
					{props.readOnly === false && (
						<ButtonSecondary
							onClick={handleRemoveImage}
							icon='Trash'></ButtonSecondary>
					)}
					<img src={props.src} style={{ width: '100%' }} />
				</div>
			)}
		</Fragment>
	)
}

export default KPRichInlineImage;
