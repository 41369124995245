import styled,{css} from "styled-components";
import { colors } from "oolib";

const { white, greyColor10, greyColor100, greyColor15, red } =
  colors;

//------------------------------------- MAIN MENU STYLED COMPS ----------------------------------------------------

export const MenuContainerStyled = styled.div`
  background-color: ${white};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${greyColor10};
  min-width: 23.3rem;
`;

export const MenuItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  gap: 1rem;
  justify-content: space-between;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme: { colors } }) => colors.primaryColor10};
    `}

  :hover {
    background-color: ${({ theme: { colors } }) => colors.primaryColor10};
  }
`;

export const Divider = styled.div`
  border: 1px solid ${greyColor10};
`;

// const bgColor = {
//   quotes: lightPink, // lightPink
//   vocabs: cyan, // vocab
// };

export const OtherOptionsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem 1rem 1rem;
`;

export const ActionButtonsContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;



//------------------------------------- SUB MENU STYLED COMPS ----------------------------------------------------

export const SubMenuWrapperStyled = styled.div`
  background-color: ${white};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${greyColor10};
  position: absolute;
  top: 0;
  transition: all 0.3s ease 0s;
  width: fit-content;
  max-width: 33rem;
  ${({ top }) =>
    top && top === "auto"
      ? css`
          top: ${top};
        `
      : css`
          top: ${top}px;
        `}
  ${({ left }) =>
    left &&
    css`
      left: ${left}px;
    `}
  ${({ right }) =>
    right &&
    css`
      right: ${right}px;
    `}
  ${({ bottom }) =>
    bottom &&
    css`
      bottom: ${bottom}px;
    `}
  
  ${({ visibility }) =>
    visibility &&
    css`
      visibility: ${visibility};
    `}
`;

export const SubMenuContainerStyled = styled.div`
  /* height: 21.6rem; */
  max-height: 21.6rem;

  overflow-y: scroll;
  white-space: nowrap;

  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    width: 0px; /* webkit */
  }
`;

export const SubMenuItemStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem 1.5rem;
  button {
    flex-shrink: 0;
    div {
      pointer-events: none;
    }
  }
`;

export const AddTagWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-top: 1px solid ${greyColor15};
`;
export const AddTagContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
`;

export const AddOthersStyled = styled.div`
  display: flex;
  gap: 1.5rem;
  padding: 1rem 1.5rem;
  min-width: 25rem;
  align-items: center;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.primaryColorText};
  :hover {
    background-color: ${({ theme: { colors } }) => colors.primaryColor10};
    color: ${greyColor100};
  }
`;

export const ErrorContainerStyled = styled.div`
  color: ${red};
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
`;

export const TagSelectWrapperStyled = styled.div`
  h4 {
    pointer-events: none;
  }
`;
