export const reshapeEntityDataForBackwardCompat = ({
  comp,
  entityData
}) => {

  /**
   * very important that for every case below, 
   * there is a check to see if the entityData has already been reshaped
   * else strange bugs can occur
   */

  switch (comp) {
    case "KPRichInlineImage":
      if(entityData.value) return entityData; //meaning this entityData has already been reshaped
      return {
        value: [{ publicUrl: entityData.src }]
      };
    default:
      return entityData;
  }
};
