import { queryClient } from "../../../../..";
import {
  __GetContentTypeConfigNew,
  __GetProfileTypeConfig,
  __GetTagTypeConfig,
} from "../../../../../utils/getters/gettersV2";

const isContentType = (contentType) => {
  const allTpls = queryClient.getQueryData("allTpls");
  const tpl = allTpls.find((d) => d.kp_content_type === contentType);  
  const isContentType = tpl&&["publishing", "collections", "staticPages"].includes(
    tpl.general?.segment
  );
  return isContentType;
};
export const getTagTypeTitle = ({ tagType }) => {
  const isContConf = isContentType(tagType);
  const contConf = isContConf && __GetContentTypeConfigNew(tagType);
  const tagConf = __GetTagTypeConfig(tagType);
  const profileConf = __GetProfileTypeConfig(tagType);

  return contConf
    ? contConf.general.content.title || contConf.kp_content_type
    : tagConf
    ? tagConf.content.title || tagConf.tagType
    : (profileConf && profileConf.content.title) || profileConf?.id;
};
