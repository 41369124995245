import React, { useState, createContext, useContext } from "react";
import { UIContent } from "../UIContent";
import { useBannerContext } from "oolib";


export const FormValidationContext = createContext();

export const FormValidationContextProvider = ({ children }) => {
  
  const initState = {
    failedBlocks: [],
    invalidInputBlocks: [],
    makeVisible: false,
  }

  const {SET_ALERT_BANNER} = useBannerContext()

  const [formValidation, setFormValidation] = useState(initState);

  const resetFormValidation = () => setFormValidation(initState)

  const makeFormValidationVisible =(msg)=> {
    
    setFormValidation(prev => ({
      ...prev,
      makeVisible: true
    }))
    SET_ALERT_BANNER({
      color: 'red',
      msg: msg || UIContent.formValidationContext.defaultValidationErrorMsg,
      timeOut: 3000
    })
  }


  return (
    <FormValidationContext.Provider
      value={{ formValidation, setFormValidation, resetFormValidation, makeFormValidationVisible }}
    >
      {children}
    </FormValidationContext.Provider>
  );
};

export const useFormValidationContext = () => useContext(FormValidationContext);
