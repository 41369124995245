import {  SANS_2, SANS_3, colors, icons } from "oolib";
import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import { createContentCardDate } from "../../../../../../../../../utils/momentManipulate";
import { ContentSectionItemsStyled, ContentSectionStyled } from "./styled";
import { usePlatformBuilderContext } from "../../../../../../../../../contexts/platformBuilderContext";
import { notifLinkGenerator } from "./utils/notifLinkGenerator";
import { NotifMsgRenderer } from "./utils/NotifMsgRenderer";

const { greyColor70, greyColor100, primaryColor100 } = colors;
const { Circle, PencilLine } = icons;

const DEPR_genNotifMsg = (d) => (
  <div>
    <SANS_2>{d.kp_msg_desc}</SANS_2>
    <SANS_2 color={greyColor70} style={{ marginTop: ".5rem" }}>
      {createContentCardDate(d.kp_date)}
    </SANS_2>
  </div>
)



export default function ContentSection({ title, items, onClick }) {
  const {platformBuilderMode} = usePlatformBuilderContext()
  const LinkComp = platformBuilderMode ? Fragment : Link;
  return (
    <ContentSectionStyled>
      <SANS_3 style={{padding:"0 3rem"}} color={greyColor100}>{title}</SANS_3>
      {items.map((d) => ( 
        <LinkComp to={ d.activityType ? notifLinkGenerator(d) : d.kp_link /**backwards compat*/ } onClick={onClick}>
          <ContentSectionItemsStyled>
            <PencilLine style={{ flexShrink: 0 }} size={20} />
            {d.kp_msg_desc 
              ? DEPR_genNotifMsg(d)
              : <NotifMsgRenderer {...d}/>
            }
            {d.kp_isRead === false && (
              <Circle
                style={{ flexShrink: 0, marginTop:"2px" }}
                size={10}
                color={primaryColor100}
                weight="fill"
              />
            )}
          </ContentSectionItemsStyled>
        </LinkComp>
      ))}
    </ContentSectionStyled>
  );
}
