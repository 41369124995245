
export const baseShapeBoilerplateLex = {
    isLexical: true,
    editorState: {
        root: {
            children: '<children_array>',
            direction: "ltr",
            format: "",
            indent: 0,
            type: "root",
            version: 1
        }
    }
}

export const paragraphNodeBoilerplateLex = {
      children: '<children_array>',
      direction: "ltr",
      format: "",
      indent: 0,
      type: "paragraph",
      version: 1
}

export const textNodeBoilerplateLex = {
    detail: 0,
    format: 0,
    mode: "normal",
    style: "",
    text: '<some_text>',
    type: "text",
    version: 1
  }