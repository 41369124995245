import React, { useState, useEffect, useRef } from "react";
import KPFAQInput from './KPFAQInput';
import { ButtonSecondary } from 'oolib';

const KPFAQBlock = props => {

  let q_id = Object.keys(props.value)[0]
  let a_id = Object.keys(props.value)[1]

  let QDivRef = useRef(null);
  let ADivRef = useRef(null);

  const [blockInputState, setBlockInputState] = useState(props.value) //will always be supplied by a props.value from its parent KPFAQComp
  const [qHeight, setQHeight] = useState({
    collapsed: null,
    expanded: null
  }) //needed only when we are in readOnly mode
  const [expandBlock, setExpandBlock] = useState(false);

  const handleInputChange = (key, val) => {
		setBlockInputState({ ...blockInputState, [key]: val })
		props.onChange && props.onChange({ ...blockInputState, [key]: val })
	}

	const handleExpandBlock = () => setExpandBlock(!expandBlock)

	useEffect(() => {
		if (props.readOnly) {
			let collapsed =
				QDivRef.current && QDivRef.current.getBoundingClientRect().height
			let expanded =
				QDivRef.current &&
				ADivRef.current &&
				QDivRef.current.getBoundingClientRect().height +
					ADivRef.current.getBoundingClientRect().height
			setQHeight({ collapsed, expanded })
		}
	}, [])

	return (
		<div
			id={`kp_faq_single_block_${q_id}`}
			className={`kp-faq-single-block ${props.className}`}
			style={{
				height: props.readOnly
					? expandBlock === true
						? qHeight.expanded
						: qHeight.collapsed
					: 'auto',
			}}>
			<div ref={QDivRef} style={{ display: 'flex', alignItems: 'center' }}>
				<KPFAQInput
					id={q_id}
					editorPrefix='Q. '
					onChange={(key, val) => handleInputChange(key, val)}
					placeholder='Write Question Here...'
					readOnly={props.readOnly}
					style={{ flexGrow: '1' }}
					value={blockInputState[q_id]}
				/>
				{props.readOnly && (
					<ButtonSecondary
						onClick={handleExpandBlock}
						icon={
							expandBlock === false ? 'CaretDown' : 'CaretUp'
						}></ButtonSecondary>
				)}
			</div>
			<div ref={ADivRef}>
				<KPFAQInput
					id={a_id}
					editorPrefix='A. '
					onChange={(key, val) => handleInputChange(key, val)}
					placeholder='Write Answer Here...'
					readOnly={props.readOnly}
					value={blockInputState[a_id]}
				/>
			</div>
		</div>
	)
}

export default KPFAQBlock;
