import styled from "styled-components";
import { colors } from "oolib";

export const FormWrapperStyled = styled.div`
  padding: 1rem 0;
`;

export const BlockWrapperStyled = styled.div`
  padding: 1rem 2rem;
`;

export const StyledModalDoneButtonWrapper = styled.div`
  background-color: white;
  display: flex;
  gap: 2rem;
  padding: 1rem;
  position: sticky;
  justify-content: center;
  box-shadow: 2px 2px 10px ${colors.greyColor15};
  bottom: 0;
`;
