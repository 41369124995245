import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { useGetQueryData } from '../utils/react-query-hooks/general'

export const AppSettingsContext = createContext()

const appSettingsReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case 'SET_LANG':
			localStorage.setItem('APP_LANG', JSON.stringify(payload))
			return { ...state, lang: payload }
		case 'SET_PREV_LOC':
			let newPrevLoc = state.prevLoc ? [payload, ...state.prevLoc] : [payload]
			return { ...state, prevLoc: newPrevLoc }
		case 'REMOVE_LAST_PREV_LOC':
			let rmPrevLoc = JSON.parse(JSON.stringify(state.prevLoc))
			rmPrevLoc.shift()
			return { ...state, prevLoc: rmPrevLoc }
		default:
			return { ...state }
	}
}

export const getActiveLangFromLocalStorage = _AppLanguageOptions => {
	const toReturn = localStorage.getItem('APP_LANG')
	? JSON.parse(localStorage.getItem('APP_LANG'))
	: _AppLanguageOptions
	? _AppLanguageOptions[0]
	: { display: 'English', value: 'en' } //this is a fallback default if no _AppLanguageOptions is defined. although its not recommnded. _AppLanguageOptions should always be defined

	return toReturn;
}

export const AppSettingsProvider = ({ children }) => {
	
	const {
		deployment: { _AppLanguageOptions },
	} = useGetQueryData('platformConfigs');

	const initState = {
		lang: getActiveLangFromLocalStorage(_AppLanguageOptions)
	}

	const [APP_SETTINGS, APP_SETTINGS_DISPATCH] = useReducer(
		appSettingsReducer,
		initState,
	)

	const [thisLoc, setThisLoc] = useState(undefined);

	// used to modify the type styling in sass when lang is changed
	useEffect(() => {
		document.body.setAttribute('class', `-locale-${APP_SETTINGS.lang.value}-`)
	}, [APP_SETTINGS.lang])

	return (
		<AppSettingsContext.Provider
			value={{
				APP_SETTINGS,
				APP_SETTINGS_DISPATCH,
				thisLoc,
				setThisLoc
			}}>
			{children}
		</AppSettingsContext.Provider>
	)
}

export const useAppSettingsContext = () => {
	return useContext(AppSettingsContext)
}
