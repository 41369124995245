import { useLocation } from "react-router-dom";

export const useIsThisLinkActive = () => {
    
    const location = useLocation();

	const convertToString = location => {
		if(location?.pathname){
			return location.pathname + (location.search || '')
		}else{
			return location
		}
	}

    const isThisLinkActive = ({to}) => convertToString(to) === convertToString(location)

    const getActiveMenuOption = ({options}) => {
        let idx = options.findIndex((d) => d.to === convertToString(location))
        return idx !== -1 && options[idx]
    }

    return {isThisLinkActive, getActiveMenuOption }
	
}



