

/**
 * the reason it has the following data structure is because of legacy code.
 * the way vocab & quote annotation tags are applied to text strings is also
 * exactly the same as the structure of other normal tags.
 * i.e it ends up in data structures such as 'tags.annotations.vocabs.data[0].display and such...
 * 
 * EDIT:
 * Also by doing this, i guess unintentionally we have probably removed the dependency on 
 * the database for quotes and vocabs. we can no define tenant specific configs over here.
 * For now tho, we have to make sure we change nothin but the 'display', if we want the toggle
 * type to be known by a different name to users. e.g 'features' in the case of gelabs.
 */

import { getTenant } from "../../../../../../../../TENANT_CONFIGS"

const getToggleTagTypesLookupForThisTenant = () => {
    switch(getTenant()){
        case 'gelabs':
            return [
                {
                    tagType: "vocabs",
                    display: "Feature",
                    singular: "Feature",
                    tagId: "vocabulary",
                    _id: 'vocabulary',
                    className: '-is-vocabs-'
                },
                {
                    tagType: "quotes",
                    display: "Quote",
                    singular: "Quote",
                    tagId: "quote",
                    _id: "quote",
                    className: '-is-quotes-'
                  }
            ]

            default: 
            return [
                {
                    tagType: "vocabs",
                    display: "Vocabulary",
                    singular: "Vocabulary",
                    tagId: "vocabulary",
                    _id: 'vocabulary',
                    className: '-is-vocabs-'
                },
                {
                    tagType: "quotes",
                    display: "Quotes",
                    singular: "Quote",
                    tagId: "quote",
                    _id: "quote",
                    className: '-is-quotes-'
                  }
            ]
    }
}


export const toggleTagTypesLookup = getToggleTagTypesLookupForThisTenant()