import React, { Fragment, useState, useEffect } from 'react'
import { UploadButton, BlockLabel, getBlockLabelProps, useBannerContext } from 'oolib';
import ContentBlockShell from '../../generalUI/ContentBlockShell';

import { imageUploader } from '../../../utils/general'

//utils
import { convertResToGcsStructure } from '../../../utils/general';
import { replaceEntityData } from '../../../utils/richInputUtils';
import KPResourcesBlock from './KPResourcesBlock';


const DEPRECATED_KPResourceUpload = (props) => {

  const { onChange: parentOnChange } = props;
  const initResourcesState = [];
  const [resources, setResources] = useState(initResourcesState);
  const [uploading, setUploading] = useState(false);
  const {SET_ALERT_BANNER} = useBannerContext()

  useEffect(()=>{

    if(props.value)
    setResources(convertResToGcsStructure(props.value));

 },[props.value])


  const callback = (status, resData) => {
    if (status === "success") {
      const newResources = props.multiple === true
        ? [...resources, ...resData]
        : resData

      setResources(newResources);
      parentOnChange && parentOnChange(props.id, newResources, props.passChangeHandlerOps && props);

      //this only runs if comp is rendered inside rich editor
      if (props.isInEditor === true) {
        replaceEntityData(props.editor, props.entityKey, { value: newResources })
        props.handleSetTempReadOnly(false);
      }
    }
    setUploading(false);
  }

  const onChange = e => {
    const files = Array.from(e.target.files)
    setUploading(true);

    const formData = new FormData()
    files.forEach((file, i) => { formData.append(i, file) })
    formData.set("folderName", props.folderName);
    formData.set("allowedFormats", props.allowedFormats);
    // formData.set("flags", "attachment");
    imageUploader(formData, { type: 'files', SET_ALERT_BANNER }, callback);
  }

  const removeResource = id => {
    let newResources = resources.filter(d => d.id !== id);
    setResources(newResources);
    parentOnChange && parentOnChange(props.id, newResources, props.passChangeHandlerOps && props);

    if (props.isInEditor === true) {
      replaceEntityData(
        props.editor,
        props.entityKey,
        { value: newResources }
      )
    }
  }

  const content = () => {
    switch (true) {
      case uploading:
        return (
          <div className="kp-resource-uploading-icon-wrapper">Uploading...</div>
        )

      case resources.length > 0:
        return (
          <Fragment>
            <KPResourcesBlock
              resources={resources}
              removeResource={(id) => removeResource(id)}
              displayType={props.displayType}
              displayName={props.displayName}
              readOnly={props.readOnly}
              uploadOnChange={onChange}
              multiple={props.multiple}
              trackDownload={props.trackDownload}
            />
            { !props.readOnly && props.multiple &&
              <UploadButton
                variant='primary'
                invert= {props.invert || false}
                onChange={onChange}
                multiple={props.multiple}
                id={`${props.id}_resources_uploader` /*v.v.v.important that it has a unique id*/}
              >
                Upload More Resources
          </UploadButton>}
          </Fragment>

        )

      default:
        return (
          <Fragment>
            { !props.readOnly &&
              <UploadButton
                variant='secondary'
                icon= "UploadSimple"
                invert={ props.invert || false}
                value={ props.fileUploadBtnText || 'Upload Resources'}
                onChange={onChange}
                multiple={props.multiple}
                id={`${props.id}_resources_uploader` /*v.v.v.important that it has a unique id*/}
                /> }
          </Fragment>
        );
    }
  }

  return (
    <ContentBlockShell
      richInputProps={{
        isInEditor: props.isInEditor,
        editor: props.editor,
        block: props.block,
        handleSetTempReadOnly: props.handleSetTempReadOnly
      }}
      readOnly={props.readOnly}
      className={`kp-resource-uploader-wrapper ${props.className}-wrapper`}
    >
       {<BlockLabel {...getBlockLabelProps(props)} />}
      <div
        id={props.id}
        className={`kp-resource-uploader ${props.className}`}
      >
        {content()}
      </div>
    </ContentBlockShell>
  )
}

DEPRECATED_KPResourceUpload.defaultProps = {
  id: "kp-resource-uploader", //required
  // label: "resources", //optional
  multiple: true, //required
  className: "", //optional
  folderName: `attachments`, //required
  allowedFormats: ["jpg", "png", "pdf", "doc", "docx", "xls", "xlsx", "zip", "ppt", "pptx", "mp4", "csv"], //required
  readOnly: false
}

export default React.memo(DEPRECATED_KPResourceUpload);
