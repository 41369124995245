export const dashboardConfig = {
  cata: {
    contentTypes: [
      {
        action: "READ",
        resourceType: "immersion1Kenya",
        searchAndHighlightFields: ["main.raw.blocks.text"],
      },
      {
        action: "READ",
        resourceType: "SecondImmersionKenya",
        searchAndHighlightFields: [
          "main.afterMarriage.CTDesc.blocks.text",
          "main.afterMarriage.desc.blocks.text",
          "main.afterMarraige.personaDesc.blocks.text",
          "main.beforeMarriage.CTDesc.blocks.text",
          "main.beforeMarriage.desc.blocks.text",
          "main.beforeMarriage.personaDesc.blocks.text",
          "main.dropoffAttributes.desc.blocks.text",
          "main.duringMarriage.CTDesc.blocks.text",
          "main.duringMarriage.desc.blocks.text",
          "main.duringMarriage.personaDesc.blocks.text",
          "main.idealContraceptive.ideaConcept.blocks.text:",
          "main.idealContraceptive.relatedUserStory.blocks.text",
          "main.immersion2CTDecisionReasons.blocks.text",
          "main.immersion2Summary.blocks.text",
        ],
      },
      {
        action: "READ",
        resourceType: "ThirdImmersionKenya",
        searchAndHighlightFields: [
          "main.currentLifeStage.desc.blocks.text",
          "main.immersion3Summary.blocks.text",
          "main.otherLifeStages.blocks.text",
          "main.personaDescImmersion3.blocks.text",
          "main.selectedPrototype.basicNeed.blocks.text",
          "main.selectedPrototype.emotionalRole.blocks.text",
          "main.selectedPrototype.improvements.blocks.text",
          "main.selectedPrototype.keyValueProp.blocks.text",
          "main.selectedPrototype.primaryRole.blocks.text",
          "main.selectedPrototype.reasonForSelection.blocks.text",
          "main.selectedPrototype.socialRole.blocks.text",
        ],
      },
      {
        action: "READ",
        resourceType: "immersion1Nigeria",
        searchAndHighlightFields: ["main.rawNotes.blocks.text"],
      },
      {
        action: "READ",
        resourceType: "SecondImmersionNigeria",
        searchAndHighlightFields: [
          "main.immersion2CTDecisionReasons.blocks.text",
          "main.immersion2Summary.blocks.text",
          "main.idealContraceptive.blocks.text",
          "main.immersion2prioritizedAttributes.desc.blocks.text",
        ],
      },
      {
        action: "READ",
        resourceType: "ThirdImmersionNigeria",
        searchAndHighlightFields: [
          "main.selectedPrototype.reasonForSelection.blocks.text",
          "main.selectedPrototype.primaryRole.blocks.text",
          "main.selectedPrototype.socialRole.blocks.text",
          "main.selectedPrototype.emotionalRole.blocks.text",
          "main.selectedPrototype.basicNeed.blocks.text",
          "main.selectedPrototype.keyValueProp.blocks.text",
          "main.selectedPrototype.improvements.blocks.text",
          "main.immersion3Summary.blocks.text",
        ],
      },
      {
        action: "READ",
        resourceType: "SenegalResearchNotes",
        searchAndHighlightFields: [
          "main.title.autoComplete",
          "main.subtitle.autoComplete",
          "main.solutionSelection.howWouldYouMakeThisConceptBetterOrMoreSuitedToYourNeeds?.allText",
          "main.solutionSelection.whyWouldThisConceptBeUsefulbeneficialToYou?ShareAnExample.allText",
          "main.solutionSelection.whatIsTheNewBenefitOfUsingThisConcept?.allText",
          "main.solutionSelection.whatCanBeDroppedFromThisConceptAndWillItStillServeYourNeeds?.allText",
          "main.solutionSelection.whatSpecificAttributesOrQualitiesInThisConceptMadeYouChooseIt?WhyAreThoseAttributesImportantToYou?.allText",
          "main.attribute.notes.allText",
          "main.immersion4InitialNotes.blocks.text",
        ],
      },
    ],

    cardConfig: {
      commonConfig: {
        cardHeader: [
          {
            comp: "Title",
            valuePath: "main.title",
          },
        ],

        cardBody: [
          {
            comp: "KeywordHighlight",
            valuePath: "highlight",
            showMax: 3,
            suffix: " ...",
            maxChars: 100,
          },
        ],

        metaBlocks: [
          {
            comp: "Meta",
            props: {},
            useModifier: "date",
            valuePath: "meta.date",
          },
          {
            comp: "Meta",
            props: {},
            useModifier: "tplTitle",
            valuePath: "meta.kp_content_type",
          },
        ],
      },

      get: function () {
        return this.commonConfig;
      },
    },

    commonSearchFields: [
      "main.title.autoComplete",
      "main.subtitle.autoComplete",
    ],
  },
  demo: {
    contentTypes: [
      {
        action: "READ",
        resourceType: "gigWorkerSurveys",
        searchAndHighlightFields: [
          "main.careerAspirations.allText",
          "main.dayInYourLife.allText",
          "main.followUpWhatAreTheCommonIssuesThatLeadToArgumentsWithCustomers?.allText",
          "main.experienceImprovements.allText",
          "main.improvementsAndSuggestions.allText",
          "main.challengingIncidents.allText",
          "main.impactOnPersonalLife.allText",
          "main.supportOrResourcesNeeded.allText",
          "main.futureJob.allText",
          "main.careerAspirations.allText",
        ],
      },
      {
        action: "READ",
        resourceType: "secondaryResearchGigWorkersResearchStudies",
        searchAndHighlightFields: ["main.textOfDocument.allText"],
      },
      {
        action: "READ",
        resourceType: "stockResearchNotes",
        searchAndHighlightFields: ["main.raw.blocks.text"],
      },
    ],

    cardConfig: {
      commonConfig: {
        cardHeader: [
          {
            comp: "Title",
            valuePath: "main.title",
          },
        ],

        cardBody: [
          {
            comp: "KeywordHighlight",
            valuePath: "highlight",
            showMax: 3,
            suffix: " ...",
            maxChars: 100,
          },
        ],

        metaBlocks: [
          {
            comp: "Meta",
            props: {},
            useModifier: "date",
            valuePath: "meta.date",
          },
          {
            comp: "Meta",
            props: {},
            useModifier: "tplTitle",
            valuePath: "meta.kp_content_type",
          },
        ],
      },

      get: function () {
        return this.commonConfig;
      },
    },

    commonSearchFields: [
      "main.title.autoComplete",
      "main.subtitle.autoComplete",
    ],
  },
};
