import {
  $createRangeSelection,
  $getNodeByKey,
  $isParagraphNode,
} from "lexical";
import { makeArrayFromLength } from "oolib";
import { handleCreateAnnotation } from "../../../AnnoPlugin/handlers/handleCreateAnnotation";
import axios from "axios"
import { getTenant } from "../../../../../../../../TENANT_CONFIGS";
import { executeInQueue } from "../../../../../../../../utils/executeInQueue";
function removeNewlines(inputString) {
  // Use a regular expression to replace newline characters with an empty string
  var resultString = inputString.replace(/(\r\n|\n|\r)/gm, "");

  return resultString;
}



const $extractNodesIntoArray = ({
  initNode,
  extractedAry = [],
  depthIdx = 0,
}) => {
  if (
    $isParagraphNode(initNode)
    // || other ors will come here such as $isHeadingNode
  ) {
    extractedAry.push(initNode);
  } else {
    initNode.getChildren &&
      initNode.getChildren().forEach((n) => {
        $extractNodesIntoArray({
          extractedAry,
          initNode: n,
          depthIdx: depthIdx + 1,
        });
      });
  }
  if (depthIdx === 0) return extractedAry;
};

const extractTextAndSelectionMetaData = ({
  initNode,
  extractedObj = {
    text: [],
    nodeKeys: [],
    anchorOffsets: [],
    focusOffsets: [],
  },
  depthIdx = 0,
}) => {
  if (initNode.getType() === "text") {
    const splitWords = initNode.getTextContent().split(" ");
    console.log({ tCont: initNode.getTextContent(), splitWords });

    let anchorOffsetTracker = 0;
    const anchorOffsets = splitWords.map((word, wordI) => {
      if (wordI !== 0) {
        anchorOffsetTracker =
          anchorOffsetTracker + (splitWords[wordI - 1].length + 1); // + 1 for the space in between the words na..
      }
      return anchorOffsetTracker;
    });

    let focusOffsetTracker = splitWords[0].length;
    const focusOffsets = splitWords.map((word, wordI) => {
      if (wordI !== 0) {
        focusOffsetTracker = focusOffsetTracker + (word.length + 1); // + 1 for the space in between the words na..
      }
      return focusOffsetTracker;
    });

    const repeatSameNodeKeyAsAry = makeArrayFromLength(splitWords.length).map(
      () => initNode.getKey()
    );
    extractedObj.text = [...extractedObj.text, ...splitWords];
    extractedObj.nodeKeys = [
      ...extractedObj.nodeKeys,
      ...repeatSameNodeKeyAsAry,
    ];
    extractedObj.anchorOffsets = [
      ...extractedObj.anchorOffsets,
      ...anchorOffsets,
    ];
    extractedObj.focusOffsets = [...extractedObj.focusOffsets, ...focusOffsets];
  } else {
    initNode.getChildren().forEach((childNode) => {
      extractTextAndSelectionMetaData({
        initNode: childNode,
        extractedObj,
        depthIdx: depthIdx + 1,
      });
    });
  }
  if (depthIdx === 0) return extractedObj;
};
/**
 * LIMITATIONS:
 * if a string of text that needs to be annotated stretches across 2
 * separate paragraphs, or is partly a header and partly paragraph, and
 * other such situations, it wont work
 */
export const handleAIAnnotate = async ({ editor, handleSetAnnoData }) => {
  editor.update(() => {
    //STEP 1 get all the text in this editor
    const rootNode = $getNodeByKey("root");
    const allText = rootNode.getTextContent();
    const tenant = getTenant()
    console.log("step 1 complete", { allText });
    //---------------------------------
    //STEP 2 send this text to gpt and let it auto annotate
    //-- make api call to our backend, to some api called... api/AIAnnotate

    const sendData = async () => {
        const response = await axios.get(
                `/api/openNyai/AIAnnotate`,
                {
                  headers: { 'Content-Type': 'application/json' },
                  params: { allText, tenant },
                }
            )
        
        const data = response?.data

        console.log({GPTResponse: data})
    }

    sendData();

    const SAMPLE_RESPONSE = [
      {
        fragment: "self-learning is the process",
        tags: {
          themes: {
            collectionId: "themes",
            data: [
              {
                _id: "651d0b058dfca7000c8320ef",
                display: "Self Learning",
                tagId: "self_learning",
              },
            ],
          },
        },
      },
      {
        fragment:
          "global economy, largely attributable to the industrialization of developing countries.",
        tags: {
          themes: {
            collectionId: "themes",
            data: [
              {
                _id: "651d0b4b8dfca7000c8321b9",
                display: "21st Century Skills",
                tagId: "21st_century_skills",
              },
            ],
          },
        },
      },
      {
        fragment:
          "demand and supply of the labour market soon the world will turn to India",
        tags: {
          themes: {
            collectionId: "themes",
            data: [
              {
                _id: "651d0b908dfca7000c832255",
                display: "Leadership Development",
                tagId: "leadership_development",
              },
            ],
          },
        },
      },
      {
        fragment: "therefore i need",
        tags: {
          themes: {
            collectionId: "themes",
            data: [
              {
                _id: "651d0b9f8dfca7000c83228a",
                display: "Innovation",
                tagId: "innovation",
              },
            ],
          },
        },
      },
      //   {
      //       fragment: 'demand and supply of such a large workforce, India will also have to train, upgrade and skill its youth.',
      //       tags: {
      //           "themes": {
      //               "collectionId": "themes",
      //               "data": [
      //                   {
      //                       "_id": "651d0b238dfca7000c83213d",
      //                       "display": "Ed-Tech",
      //                       "tagId": "ed_tech"
      //                   }
      //               ]
      //           }
      //       }
      //   },
      //   {
      //       fragment: 'demand and supply of such a large workforce, India will also have to train, upgrade and skill its youth.',
      //       tags: {
      //           "themes": {
      //               "collectionId": "themes",
      //               "data": [
      //                   {
      //                       "_id": "651d0b3f8dfca7000c832192",
      //                       "display": "Gender",
      //                       "tagId": "gender"
      //                   }
      //               ]
      //           }
      //       }
      //   },
      //   {
      //       fragment: 'According to an analysis conducted by LinkedIn, employers in 2019 are looking for candidates with a mix of both hard and soft skills.',
      //       tags: {
      //           "themes": {
      //               "collectionId": "themes",
      //               "data": [
      //                   {
      //                       "_id": "651d0b4b8dfca7000c8321b9",
      //                       "display": "21st Century Skills",
      //                       "tagId": "21st_century_skills"
      //                   }
      //               ]
      //           }
      //       }
      //   },
      //   {
      //       fragment: 'as automation and algorithms create new high-quality jobs, demands for skills such as creativity, persuasion, collaboration, adaptability and time management are only going to increase.',
      //       tags: {
      //           "themes": {
      //               "collectionId": "themes",
      //               "data": [
      //                   {
      //                       "_id": "651d0bc58dfca7000c83230c",
      //                       "display": "STEM Mindsets",
      //                       "tagId": "stem_mindsets"
      //                   }
      //               ]
      //           }
      //       }
      //   },
      //   {
      //       fragment: 'On the other hand, technological advancement across industries also means that there is a high demand for hard skills such as cloud computing, artificial intelligence, analytical reasoning, people management and UX Design.',
      //       tags: {
      //           "themes": {
      //               "collectionId": "themes",
      //               "data": [
      //                   {
      //                       "_id": "651d0b4b8dfca7000c8321b9",
      //                       "display": "21st Century Skills",
      //                       "tagId": "21st_century_skills"
      //                   }
      //               ]
      //           }
      //       }
      //   },
      //   {
      //       fragment: 'The demands of the global workforce are rapidly changing and India is struggling to give its youth adequate and appropriate skills needed to enter employment.',
      //       tags: {
      //           "themes": {
      //               "collectionId": "themes",
      //               "data": [
      //                   {
      //                       "_id": "651d0b4b8dfca7000c8321b9",
      //                       "display": "21st Century Skills",
      //                       "tagId": "21st_century_skills"
      //                   }
      //               ]
      //           }
      //       }
      //   },
      //   {
      //       fragment: 'digital talent platforms such as Naukri, Monster, Indeed and LinkedIn.',
      //       tags: {
      //           "themes": {
      //               "collectionId": "themes",
      //               "data": [
      //                   {
      //                       "_id": "651d0b9f8dfca7000c83228a",
      //                       "display": "Innovation",
      //                       "tagId": "innovation"
      //                   }
      //               ]
      //           }
      //       }
      //   },
      //   {
      //       fragment: 'LinkedIn has 1 million company pages from India and over 350,000 weekly active job posts.',
      //       tags: {
      //           "themes": {
      //               "collectionId": "themes",
      //               "data": [
      //                   {
      //                       "_id": "651d0b9f8dfca7000c83228a",
      //                       "display": "Innovation",
      //                       "tagId": "innovation"
      //                   }
      //               ]
      //           }
      //       }
      //   },
      //   {
      //       fragment: 'LinkedIn has also launched India specific products such as LinkedIn Lite (for users with low connectivity) to help graduating students get their first job.',
      //       tags: {
      //           "themes": {
      //               "collectionId": "themes",
      //               "data": [
      //                   {
      //                       "_id": "651d0b9f8dfca7000c83228a",
      //                       "display": "Innovation",
      //                       "tagId": "innovation"
      //                   }
      //               ]
      //           }
      //       }
      //   }
    ];

    console.log("step 2 pending (gpt auto annotate)", { SAMPLE_RESPONSE });
    //---------------------------------

    // console.log({extractedNodes: $extractNodesIntoArray({
    //     initNode: $getNodeByKey('root')
    // })})

    // console.log({textAndMetaDta: extractedTextBasedNodes.map(node => extractTextAndSelectionMetaData({initNode: node}))})

    //now find the string match from gpt response and textAndSelectionMetaData
    executeInQueue({
      dataAry: SAMPLE_RESPONSE,
      callback: ({ fragment: gptAnnoString, tags: tagsResponseFromGPT }) => {
        /**
         * //STEP 3
         * find all nodes that have text directly inside them,
         * and pull them all into an array as such:
         * [
         *  <instance of node class>,
         *  ...
         * ]
         *
         * currently, we are focussing only on one such node type
         * that has text directly inside it - paragraph node.
         *
         * but to this we will add:
         * - heading node
         * - list item node
         * - quote node
         * maybe some others as well...
         */
        const extractedTextBasedNodes = $extractNodesIntoArray({
          initNode: $getNodeByKey("root"),
        });

        /**
         * //STEP 4
         * now for each of these text based nodes, extract out all the final children
         * which have the actual text in this format:
         * [
         *  {
         *    text: ['hello', 'world' 'another'],
         *    nodeKeys: ['3', '3', '6'], //basically we split words coming from the same node also and store them separately in the array above, and repeat their node keys in this array. will make our lives easier further down
         *    anchorOffset: [3, 4],
         *    focusOffset: [8, 9]
         *  }
         * ]
         */
        const textAndSelectionMetaData = extractedTextBasedNodes.map((node) =>
          extractTextAndSelectionMetaData({ initNode: node })
        );

        const nodeWithAnnoStringMatchFound = textAndSelectionMetaData.find(
          (obj) => {
            /**
             * this is also not straight forward. basically, if words belong to
             * same nodeKey, then join with ' '. Else join with ''.
             */
            const constructedString = obj.text.reduce((a, b, bIdx) => {
              if (bIdx === 0) return a + b;
              if (obj.nodeKeys[bIdx] === obj.nodeKeys[bIdx - 1])
                return a + " " + b;
              //else nodeKey has changed. so join without space
              return a + b;
            }, "");
            // console.log({constructedString})
            return constructedString.includes(gptAnnoString);
          }
        );

        if (nodeWithAnnoStringMatchFound) {
          //then set selection on it
          /**
           * now we have to get the node keys and offsets and such.
           * which is again not simple.
           * Lets get the first occurence of the first word in the substring, in
           * the obj.text ary. then check every consequent word (ignoring the ''),
           * and see if its a match. if yes great, i think we will have everything we
           * need. if not, move on to the next occurence of this first word
           */
          const onlyWordsSubString = gptAnnoString.split(" ").filter(Boolean);
          console.log({ nodeWithAnnoStringMatchFound, onlyWordsSubString });
          const firstWordMatchIndexes = nodeWithAnnoStringMatchFound.text
            .map((word, wordI) => {
              if (word === onlyWordsSubString[0]) return wordI;
              //else
              return undefined;
            })
            .filter((d) => d !== undefined);

          const stringMatched = (firstWordMatchIdx) => {
            const matchTracker = [firstWordMatchIdx];
            let prevWordIdxInMainString = firstWordMatchIdx;
            for (var y = 1; y < onlyWordsSubString.length; y++) {
              const nextWord = onlyWordsSubString[y];

              let nextWordIdxInMainText = prevWordIdxInMainString + 1;
              while (
                !nodeWithAnnoStringMatchFound.text[nextWordIdxInMainText]
              ) {
                nextWordIdxInMainText = nextWordIdxInMainText + 1;
              }
              const nextWordInMainText =
                nodeWithAnnoStringMatchFound.text[nextWordIdxInMainText];
              prevWordIdxInMainString = nextWordIdxInMainText;
              console.log({ nextWord, nextWordInMainText });
              if (nextWord === nextWordInMainText.trim()) {
                matchTracker.push(nextWordIdxInMainText);
              }
            }

            if (matchTracker.length === onlyWordsSubString.length) {
              return matchTracker;
            } else {
              return false;
            }
          };
          let stringMatch;
          console.log({ firstWordMatchIndexes });
          for (var x = 0; x < firstWordMatchIndexes.length; x++) {
            stringMatch = stringMatched(firstWordMatchIndexes[x]);
            if (stringMatch) break;
          }
          console.log({ stringMatch });
          const idxOfFirstWordinMatch = stringMatch[0];
          const idxOfLastWordinMatch = stringMatch[stringMatch.length - 1];
          const setTextNodeRangeConfig = {
            start: {
              nodeKey:
                nodeWithAnnoStringMatchFound.nodeKeys[idxOfFirstWordinMatch],
              anchorOffset:
                nodeWithAnnoStringMatchFound.anchorOffsets[
                  idxOfFirstWordinMatch
                ],
            },
            end: {
              nodeKey:
                nodeWithAnnoStringMatchFound.nodeKeys[idxOfLastWordinMatch],
              focusOffset:
                nodeWithAnnoStringMatchFound.focusOffsets[idxOfLastWordinMatch],
            },
          };
          console.log({ setTextNodeRangeConfig });
          const newSel = $createRangeSelection();
          newSel.setTextNodeRange(
            $getNodeByKey(setTextNodeRangeConfig.start.nodeKey),
            setTextNodeRangeConfig.start.anchorOffset,
            $getNodeByKey(setTextNodeRangeConfig.end.nodeKey),
            setTextNodeRangeConfig.end.focusOffset
          );
          // $setSelection(newSel)

          handleCreateAnnotation({
            editor,
            handleSetAnnoData,
            targetSelection: newSel,
            data: tagsResponseFromGPT,
          });
        }
      },
    });

    //3 for each annotation string returned by gpt, find the nodekey within which it occurs,
    //  and the anchor and offset within it. this is going to be daym difficult

    //4 now that we have all the information to make the selections, loop through that info and
    //  make the selection and apply the annotation
    //below is example of how to do the selection bit
    // const newSel = $createRangeSelection();
    // newSel.setTextNodeRange(
    //   $getNodeByKey('5'), 0,
    //   $getNodeByKey('5'), 4
    // )
    // $setSelection(newSel)

    //DONE!
  });
};
