import React from "react";
//generalUI

import { applyInlineStyle } from "../../../../../utils/richInputUtils";

import { useEffect } from "react";
import { colors, icons } from "oolib";
import { promptForLink } from "../../../../../utils/richInlineLinkUtils";
const {
  TextItalic,
  TextBolder,
  HighlighterCircle,
  TextUnderline,
  LinkIcon
} = icons;
const { white } = colors;

const KPRichInlineToolbar = (props) => {
  //col settings must be the bootstrap codes

  const { editorState, onEditorChange } = props.editor;

  

  useEffect(() => {
    
    if (editorState.getSelection()?.isCollapsed() === true) {
      props.setInlineToolbar({ show: false, position: {} });
    }
    
  }, [editorState.getSelection()?.isCollapsed()]);



  

  return (
    <div className="kp-rich-inline-toolbar" onClick={props.onClick}>
      <div
        className="kp-rich-inline-toolbar__tools-group"
        style={{ display: "flex" }}
      >
        <button
          className="kp-rich-format-toolbar__tool"
          onClick={() =>
            applyInlineStyle(
              "BOLD",
              editorState,
              onEditorChange,
              props.setInlineToolbar
            )
          }
        >
          <TextBolder size={20} color={white} />
        </button>
        <button
          className="kp-rich-format-toolbar__tool"
          onClick={() =>
            applyInlineStyle(
              "ITALIC",
              editorState,
              onEditorChange,
              props.setInlineToolbar
            )
          }
        >
          <TextItalic size={20} color={white} />
        </button>
        <button
          className="kp-rich-format-toolbar__tool"
          onClick={() =>
            applyInlineStyle(
              "UNDERLINE",
              editorState,
              onEditorChange,
              props.setInlineToolbar
            )
          }
        >
          <TextUnderline size={20} color={white} />
        </button>
        {props.formattingOptions !== "textFormatting" && (
          <button
            className="kp-rich-format-toolbar__tool"
            onClick={(e) =>
              promptForLink(e, editorState, props.setTextLinkState)
            }
          >
            <LinkIcon size={20} color={white} />
          </button>
        )}
        {props.showAnoBtn && (
          <button
            className="kp-rich-format-toolbar__tool"
            onMouseDown={(e) => {
              e.preventDefault();
              // if(props.handleOnClick){
              //  props.handleOnClick()
              // }
              // else{
              //   props.setShowAnoLightbox(props.inlineToolbar);

              // }

                props.setShowAnnoGUI(props.inlineToolbar);

              
              props.setInlineToolbar({ show: false, position: {} });
            }}
          >
            <HighlighterCircle size={20} color={white} />
          </button>
        )}
      </div>
    </div>
  );
};

export default KPRichInlineToolbar;
