
import { validationPlugin } from "./LinkEmbedWithInput"
import { highlightPlugin } from "./ListingModule"
import { uniqueStringValidationPlugin } from "./TextInput"
import { cata_uniqueStringValidationPlugin } from "./cata_uniqueStringValidation"
import { useMarkAsWinnerPlugin } from "./gsl_useMarkAsWinnerPlugin"
import {OAuthLoginButton} from "./mad_OAuthLoginButton"

export const pluginConfigs = {
    ved: {
        LinkEmbedWithInput: {
            validationPlugin
        }
    },
    gsl: {
        ListingModule: {
            highlightPlugin
        },
        contentTypesManager_contentTypeLanding_inPublish: {
            rowActionPlugin: useMarkAsWinnerPlugin
        }
    },
    mad: {
        EmailPassLogin: {
            OAuthLoginButton
        }
    },
    /**
     * for tpl blocks specific plugins, we can simply 
     * dump them here, and in the blockConfig.props,
     * we can have a prop called for example:
     * {
     *   plugin: { valiadation: <nameofpluginfunction> }
     * }
     * and that should do the job
     */
    tplBlockPlugins: {
        uniqueStringValidationPlugin, //gelabs
        cata_uniqueStringValidationPlugin
    }
}
