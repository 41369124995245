
import EditorState from "draft-js/lib/EditorState";

const replaceEntityData = (editorState, handleChange, entityKey, data) => {

	const contentStateWithNewEntityData = editorState.getCurrentContent().replaceEntityData(
		entityKey,
		data,
	)

	const newEditorState = EditorState.push( //why doesn't this cause a rerender?
    editorState,
    contentStateWithNewEntityData,
    'change-block-data'
  )

	handleChange(newEditorState, {firedFrom: 'RTEInsertLink'})
}

export default replaceEntityData;