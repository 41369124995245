import React from "react"

function WaterDrop(props) {
  return (
    <svg width={42} height={42} viewBox="0 0 42 42" fill="none" {...props}>
      <circle cx={21} cy={21} r={21} fill="#EDEFF1" />
      <mask id="prefix__a" fill="#fff">
        <path d="M27.603 23.199a6.801 6.801 0 01-13.603 0c0-3.27 3.42-6.51 5.813-11.068.41-.78 1.621-.806 2.053-.038 2.474 4.412 5.737 7.846 5.737 11.106z" />
      </mask>
      <path
        d="M27.603 23.199a6.801 6.801 0 01-13.603 0c0-3.27 3.42-6.51 5.813-11.068.41-.78 1.621-.806 2.053-.038 2.474 4.412 5.737 7.846 5.737 11.106z"
        fill="#E3F2FD"
      />
      <path
        d="M26.603 23.199A5.803 5.803 0 0120.801 29v2a7.803 7.803 0 007.802-7.801h-2zM20.801 29A5.801 5.801 0 0115 23.199h-2A7.801 7.801 0 0020.801 31v-2zM15 23.199c0-1.312.692-2.716 1.85-4.51 1.105-1.713 2.625-3.763 3.849-6.093l-1.77-.93c-1.171 2.227-2.558 4.076-3.76 5.94C14.017 19.39 13 21.24 13 23.198h2zm5.994-10.616c1.24 2.21 2.76 4.312 3.818 6.021 1.121 1.811 1.791 3.272 1.791 4.595h2c0-1.937-.961-3.823-2.09-5.647-1.192-1.926-2.54-3.746-3.775-5.948l-1.744.979zm-.295.013c.007-.014.043-.063.152-.065a.236.236 0 01.11.022.07.07 0 01.033.03l1.744-.979c-.427-.761-1.217-1.086-1.926-1.073-.707.014-1.481.368-1.884 1.135l1.77.93z"
        fill="#399EFF"
        mask="url(#prefix__a)"
      />
    </svg>
  )
}

export default WaterDrop
