import React from 'react';
import { 
  WrapperCardGrid, 
  SkeletonLoader, 
  makeArrayFromLength,
  PaddingTopBottom40,
  Container 
} from "oolib";
import { MediaItem } from './MediaItem';

const MediaGrid = ({ 
  status, 
  mediaCollection, 
  onItemClick 
}) => {
  const renderLibrary = () => {
    if (status === "loading") {
      return makeArrayFromLength(40).map((_, i) => (
        <SkeletonLoader key={i} style={{ height: "10rem" }} />
      ));
    }

    if (status === "error") {
      return <div>Error loading media content</div>;
    }

    return mediaCollection?.map((item, index) => (
      <div key={`${item.id}-${index}`}>
        <MediaItem
          item={item}
          index={index}
          onItemClick={onItemClick}
        />
      </div>
    ));
  };

  return (
    <Container style={{ flexGrow: 1 }}>
      <PaddingTopBottom40>
        <WrapperCardGrid minWidth="15rem">
          {renderLibrary()}
        </WrapperCardGrid>
      </PaddingTopBottom40>
    </Container>
  );
};

export default MediaGrid;