import React, { useMemo } from "react";
import { DecoratorNodeShell } from "../comps/DecoratorNodeShell";
import LinkEmbed from "../../../../_V2/LinkEmbed";
import { CardEmbed, SkeletonLoader } from "oolib";
import { getQueryParam } from "../../../../../../utils/general";
import { useGetContribution } from "../../../../../../utils/react-query-hooks/contributions";
import { RTEDefaultInternalEmbedConfig } from "../../plugins/ToolbarPlugin/LinkAndEmbedTools/LinkOrEmbedModalLex/configs/RTEDefaultInternalEmbedConfig";

//http://localhost:3000/published-page/newsReports?id=64927fa4d146ca000cf53fcb

export const InternalLinkEmbedNodeComp = ({
  //props for comp
  id,
  value,

  //props for shell
  nodeKey,

  //handlers
  onRemove,
}) => {
  /**
   * because this can become all sorts of complex, for now we are doing a simple thing:
   * - if the link is a '/published-page/' link, then make api call to get contr data,
   *   and use that data to Embed proper Card with 'configs'
   * - if it is any other internal link, for now, simply embed it using the LinkEmbed component
   *   (which is a poorly named comp. it actually is an external embed comp, that does the whole open graph fetching etc)
   */
  const { compToUse, publishedPageLinkExtracts } = useMemo(() => {
    let compToUse, publishedPageLinkExtracts;
    if (value.includes("/published-page/")) {
      //isPblPageLink
      //e.g pbl page link : www.abc.com/published-page/stories?id=1234
      compToUse = "CardEmbed";
      const urlSplit = value.split("/published-page/")[1].split("?"); //becomes: [ stories, id=1234 ]
      publishedPageLinkExtracts = {
        contentType: urlSplit[0],
        contentId: getQueryParam(`?${urlSplit[1]}`, "id").trim(),
      };
    } else {
      compToUse = "LinkEmbed";
    }

    return {
      compToUse,
      publishedPageLinkExtracts,
    };
  });

  const { data, status: queryStatus } = useGetContribution({
    contentType: publishedPageLinkExtracts?.contentType,
    contentId: publishedPageLinkExtracts?.contentId,
    mode: "view",
    enabled: !!publishedPageLinkExtracts, //if this exists, then it means its a published page link
  });

  const genCompToUse = ({ compToUse, queryStatus }) => {
    switch (true) {
      case compToUse === "LinkEmbed":
        return <LinkEmbed id={id} value={value} />;
      case queryStatus === "loading":
        return <SkeletonLoader style={{ height: "150px" }} />;
      case queryStatus === "error":
        return (
          <CardEmbed
            id={id}
            config={{ title: "title" }}
            data={{
              title: "Content Not Found :(",
            }}
            disabled
          />
        );
      default:
        return (
          <CardEmbed
            id={id}
            config={RTEDefaultInternalEmbedConfig}
            data={data.contribution}
            to={value}
          />
        );
    }
  };

  return (
    <DecoratorNodeShell nodeKey={nodeKey} onRemove={onRemove}>
      {genCompToUse({compToUse, queryStatus})}
    </DecoratorNodeShell>
  );
};
