export const extractGalleryImages = (data) => {
    return data.reduce((acc, item) => {
      const images = item.main?.uploadImagesRelatedToTheActivity ||
        item.main?.uploadImagesRelatedToThisActivity ||
        item.main?.uploadImagesRelatedToThisVisit ||
        item.main?.uploadImages || [];
      
      const id = item._id;
      
      const imageGalleryData = images.map(image => ({
        ...image,
        id,
        metaData: {
          stakeholder: item.tags?.stakeholder,
          subject: item.tags?.subject,
          facilityType: item.facilityType,
          contentType: item.meta.kp_content_type,
          country: item.tags?.country,
          states: item.tags?.states,
          districts: item.tags?.districts
        }
      }));
      
      return [...acc, ...imageGalleryData];
    }, []);
};