import { applyFilters } from "./applyFilters"
import { extractGalleryImages } from "./extractGalleryImages";
import { extractImages } from "./extractImages";
import { extractRepeaterImages } from "./extractRepeaterImages";

export const prepareData = ({ data, activeFilters }) => {
  const repeaterInstances = [];
  const imageInstances = [];
  const imageGalleryInstances = [];

  const flattened = data.pages
    ? data.pages.reduce((a, b) => [...a, ...b.data], [])
    : data.data;

  flattened.forEach((d) => {
    if (d.main?.uploadImagesRelatedToTheActivity ||
        d.main?.uploadImagesRelatedToThisActivity ||
        d.main?.uploadImagesRelatedToThisVisit ||
        d.main?.uploadImages) {
      imageGalleryInstances.push(d);
    }
    if (d.main?.images) {
      imageInstances.push(d);
    } else if (d.main?.imagesRepeater) {
      repeaterInstances.push(d);
    }
  });

  const flattendGalleryImages = extractGalleryImages(imageGalleryInstances);
  const flattenedRepeaterImages = extractRepeaterImages(repeaterInstances);
  const flattenedImages = extractImages(imageInstances);

  const toReturn = [
    ...flattenedRepeaterImages,
    ...flattenedImages,
    ...flattendGalleryImages
  ];

  return applyFilters(toReturn, activeFilters);
};
