/**
 * coachMarkURL - key value to filter out elements array which matches with current path on the page.
 * - "/" - for the root page
 * - "global" - for elements that are not present but updated on mutation i.e. rendered after  example Toolbar on RTE
 * - Any other URL - for elements that are specific to a page example - "/edit" for page that startswith '/edit'
 */

export const coachMarkConfig = {   
      gtdc: [
        {
          coachMarkURL: "/edit",
          elements: [
            {
              id: "stepNextButton",
              key: "stepNextButton",
              title: "Next",
              content: "Click here to go to next step when button is active, if any required section is missing then clicking on this button will highlight that section in red.",
              position: "bottom-right-top",
              actionText: "Okay",
            },
          ]
        }
      ],
      gsl: [
        {
          coachMarkURL: "/",
          elements: [
            {
              id: "okf_publish_cta",
              key: "okf_publish_cta",
              title: "Write A story",
              content: "Click here to start writing a story",
              position: "bottom",
              actionText: "Okay",
            }
          ]
        },
        {
          coachMarkURL: "/edit/stories",
          elements: [
            {
              id: "TplBlock_title",
              key: "TplBlock_title",
              title: "Title",
              content: "Enter the title for your story here",
              position: "right",
              actionText: "Okay",
            },
            {
              id: "TplBlock_tags.thematics", // src/utils/blockGenerators/index.js => ContentBlock - Every dynamically generated component through template is wrapped with dynamic id defined in props with suffix TplBlock_{id}
              key: "TplBlock_tagsDOTthematics", // Key value stored in DB when coachmark is viewed
              title: "Topic",
              content: "Select the topic of your story",
              position: "right",
              actionText: "Okay",
            },
            {
              id: "TplBlock_tags.countries",
              key: "TplBlock_tagsDOTcountries",
              title: "Country",
              content: "Select the country you are writing from",
              position: "left",
              actionText: "Okay",
            },
            {
              id: "TplBlock_main.context",
              key: "TplBlock_tagsDOTcontext",
              // title: "Context"
              content: `Click on the 'Hints' icon in each section to get guiding questions that will help you fill them out.`,
              position: "bottom",
              actionText: "Okay",
            },
            {
              id: "h_PrimaryCTA", // component => H_PrimaryCTA 
              key: "h_PrimaryCTA",
              title: "Publish Button",
              content: "Click on 'Publish' to share your story on the platform. The button will become active once you have completed all the sections of your story.",
              position: "bottom",
              actionText: "Okay",
            },
            {
              id: "h_CloseButton", // component => H_CloseButton
              key: "h_CloseButton",
              title: "Close Button",
              content: "Click on this button to save your story in drafts and close the editor.",
              position: "top-right-bottom",
              actionText: "Okay",
            },
        ]
        },
        {
          coachMarkURL: "global",
          elements: [
            {
              id: "StyledToolbar-main.context",
              key: "StyledToolbar-mainDOTcontext",
              title: "Toolbar",
              content: "Upload files, format text, Insert links, and more.",
              position: "bottom",
              actionText: "Okay",
            },
            // {
            //   id: "StyledToolbar-main.challenge",
            //   key: "StyledToolbar-mainDOTchallenge",
            //   title: "Toolbar",
            //   content: "Upload files, format text, Insert links, and more.",
            //   position: "bottom",
            //   actionText: "Okay",
            // },
          ]
        },
      ],
      nkms : [
        {
          coachMarkURL: "/",
          elements: [
            {
              id: "OKE-Header-myAccountMenuButton",
              key: "okeHeaderMyAccountMenuButton",
              title: "Profile",
              content: "Click here to set up and manage your profile and your resources.",
              position: "right-corner",
              actionText: "Okay",
            },
            {
              id: "All Resources",
              key: "allResources",
              title: "All Resources",
              content: "Find a range of organisational resources here.",
              position: "bottom",
              actionText: "Okay",
              onSidebar: true,
            },
            {
              id: "Resource Collections",
              key: "resourceCollections",
              title: "Resource Collections",
              content: "Explore different collections of resources on the KMS.",
              position: "bottom",
              actionText: "Okay",
              onSidebar: true, // on mobile these elements on different path, for example -> 
            },
            {
              id: "okf_publish_cta",
              key: "okf_publish_cta",
              title: "Publish Resource",
              content: "Click here to publish resources in an existing template.",
              position: "top-right-bottom",
              actionText: "Okay",
            },
            {
              id: "oke_Header_hamburgerButton",
              key: "oke_Header_hamburgerButton",
              title: "Menu",
              content: "Click on menu button to see more options",
              position: "left-corner",
              actionText: "Okay",
            },
            {
              id: "staticPage_landing_66c6f1c2bc91b3000b93c9e6",
              key: "about",
              title: "About",
              content: "Want to know more about the KMS and how to use it? Look here!",
              position: "bottom",
              actionText: "Okay",
              onSidebar: true,
            },
          ]
        },
      ]
}
  
