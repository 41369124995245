import { EditorState, RichUtils, SelectionState } from 'draft-js'
import { injectHttps } from 'oolib'



export const convertUrlStringsToInlineLinks = (
	editor,
	setTextLinkState,
	setCursorToEnd,
	cursorToEnd,
) => {
	const { editorState, setEditorState } = editor
	let wasLinkInserted = false
	let editorStateWithLink = 'unchanged'

	editorState
		.getCurrentContent()
		.getBlockMap()
		.forEach((block) => {
			const blockKey = block.getKey()
			const blockText = block.getText()

			const regex1 =
				/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?\s/gi
			let match

			while ((match = regex1.exec(blockText)) !== null) {
				const start = match.index
				const entityKeyStart = block.getEntityAt(start)

				if (!entityKeyStart) {
					const updateSelection = new SelectionState({
						anchorKey: blockKey,
						anchorOffset: start,
						focusKey: blockKey,
						focusOffset: start + (match[0].length - 1),
					})

					let newEditorState = EditorState.acceptSelection(
						editorState,
						updateSelection,
					)

					editorStateWithLink = insertLink(
						'',
						match[0],
						'external',
						{ editorState: newEditorState, setEditorState },
						setTextLinkState,
						setCursorToEnd,
						cursorToEnd,
					)
					wasLinkInserted = true
				}
			}
		})

	return editorStateWithLink
}

//------0.5 : convert selected text into a link
export const insertLink = (
	key,
	val,
	type,
	editor,
	setTextLinkState,
	setCursorToEnd,
	cursorToEnd,
	entityType = 'LINK',
	options = {},
) => {
	const { editorState, setEditorState, onEditorChange } = editor
	// e.preventDefault();
	const contentState = editorState.getCurrentContent()
	let contentStateWithEntity
	if (entityType === 'LINK') {
		contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
			url: injectHttps(val),
			type,
		})
	} else {
		contentStateWithEntity = contentState.createEntity(
			entityType,
			'MUTABLE',
			val,
		)
	}

	const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
	const newEditorState = EditorState.set(editorState, {
		currentContent: contentStateWithEntity,
	})
 

	onEditorChange &&
		onEditorChange(
			'',
			RichUtils.toggleLink(
				newEditorState,
				newEditorState.getSelection(),
				entityKey,
			),
		)

	setCursorToEnd(cursorToEnd + 1)

	setTextLinkState({
		value: '',
		showLinkInput: false,
	})

	let toReturn = RichUtils.toggleLink(
		newEditorState,
		newEditorState.getSelection(),
		entityKey,
	)

	if (options.setForceReadOnly) {
		options.setForceReadOnly(undefined)
	}

	return toReturn
}

export const promptForLink = (e, editorState, setTextLinkState) => {
	e.preventDefault()

	const selection = editorState.getSelection()
	if (!selection.isCollapsed()) {
		const contentState = editorState.getCurrentContent()
		const startKey = editorState.getSelection().getStartKey()
		const startOffset = editorState.getSelection().getStartOffset()
		const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
		const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

		let linkVal = ''
		if (linkKey) {
			const linkInstance = contentState.getEntity(linkKey)
			linkVal = linkInstance.getData().url
		}

		setTextLinkState({
			value: linkVal,
			showLinkInput: true,
		})
	}
}
