import React from "react";
import { PDFInput } from "oolib";
import { DecoratorNodeShell } from "../comps/DecoratorNodeShell";

export const PDFInputComp = ({
  //props for comp
  id,
  files,
  value,

  //props for shell
  nodeKey,

  //handlers
  onChange,
  onRemove,
}) => {

  return (
    <DecoratorNodeShell
      nodeKey={nodeKey}
      onRemove={onRemove}
      actions={
        [{ 
            icon: "FilePdf",
            display: "Replace PDF",
            onClick: () => {
              const actualReuploadButton = document.getElementById(`${id}_replacePDF`);
              actualReuploadButton.click()
            }
        }]
      }
    >
      <PDFInput
        isInRTE={true}
        id={id}
        files={files}
        value={value}
        onChange={onChange}
      />
    </DecoratorNodeShell>
  );
};
