import React from 'react'
import { mergeEntityData } from '../../utils/mergeEntityData'
import AtomicBlockShell from '../AtomicBlockShell'
import { IFrameInput } from 'oolib'

export const RTEIFrameInput = ({
    id,
    value,
    readOnly,
    readOnlyProp, 
    setReadOnly, 
    editorState, 
    handleChange,
    entityKey, 
    block,
    isInRTE
}) => {
  
  return (
    <AtomicBlockShell  {...{ id, readOnlyProp, setReadOnly, editorState, handleChange, block, readOnly }}>
        <IFrameInput 
            value={value} 
            setReadOnly={setReadOnly}
            onChange={(id, v) => {
                mergeEntityData(
                  editorState,
                  handleChange,
                  entityKey,
                  { value: v }
                  );
                }} 
            readOnly={readOnlyProp}
            isInRTE={isInRTE}
            />
    </AtomicBlockShell>
  )
}

