import { useQuery, useQueries } from "react-query";
import axios from "axios";
import {
  __GetProfileTypeConfig,
} from "../getters/gettersV2";
import { getVal } from 'oolib';
const useGetContentListing = ({ contentType, enabled = true, options = {} }) => {
  return useQuery(
    ["contentListing", contentType],
    () => axios.get(`/api/discovery/getData`,{
      params : {
        configs : JSON.stringify({
          contentTypes : [contentType]
        }) 
      }
    }).then((res) => res.data),
    { enabled, ...options }
  );
};

const useGetContentListings = (config = []) => {
  const queries = config.map(({ contentType, enabled }) => {
    return {
      queryKey: ["contentListing", contentType],
      queryFn: () =>
      axios.get(`/api/discovery/getData`,{
        params : {
          configs : JSON.stringify({
            contentTypes : [contentType]
          }) 
        }
      }).then((res) => res.data),
      enabled,
    };
  });

  return useQueries(queries);
};

export {
 
  useGetContentListing,
  useGetContentListings,
};
