import React from "react";
import { colors, SANS_3 } from "oolib";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {    // Update state so the next render will show the fallback UI.    
      return { hasError: true };  
    }
    
    componentDidCatch(error, errorInfo) {    // You can also log the error to an error reporting service    
      console.log({error, errorInfo})
      // logErrorToMyService(error, errorInfo);  
    }
    render() {
      if (this.state.hasError) {      // You can render any custom fallback UI      
        return this.props.ErrorComp || 
        // <div>
        // <SANS_3 bold>Something went wrong.</SANS_3>;    
        // </div>
        <div style={{padding: '2rem', border: `1px solid ${colors.red}`, backgroundColor: colors.lightRed }}>
            <SANS_3 semibold>{'Something went wrong.'}</SANS_3>
        </div>
      }
      return this.props.children; 
    }
  }

  export default ErrorBoundary;