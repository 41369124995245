import { $insertNodeToNearestRoot } from '@lexical/utils';
import { $createImageInputNode } from "../../../nodes/ImageInputNode";
import { $createVideoInputNode } from "../../../nodes/VideoInputNode";
import { $createPDFInputNode } from '../../../nodes/PDFInputNode';
import { $createAudioInputNode } from '../../../nodes/AudioInputNode';
import { $createIFrameInputNode } from '../../../nodes/IFrameInputNode';

export const handleChange = ({ nodeType, props = {}, editor }) => {
  const createNodeFns = {
    ImageInputNode: $createImageInputNode,
    VideoInputNode: $createVideoInputNode,
    PDFInputNode: $createPDFInputNode,
    AudioInputNode: $createAudioInputNode,
    IFrameInputNode: $createIFrameInputNode
  }
  editor.update(() => {
    let node = createNodeFns[nodeType](props);
    $insertNodeToNearestRoot(node);
  });
};
