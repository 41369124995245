import React from "react";
import { ModalConfirmAction, TextInput, icons } from "oolib";
import { SearchBarStyled, TextInputWrapperStyled } from "./styled";
import { useState } from "react";

const { ArrowClockwise,PaperPlaneRight } = icons;

export const SearchBar = ({
  onSubmit,
  isStreaming,
  deleteChat,
  deleteChatStatus,
}) => {
  const [value, setValue] = useState("");

  const handleOnClick = () => {
    if (isStreaming) return;
    onSubmit({ value });
    setValue("");
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      setTimeout(handleOnClick, 0);
    }
  };

  return (
    <SearchBarStyled>
      <ModalConfirmAction title="Are you sure you want to restart" enabled onConfirmText="Yes">
        <div
          style={{
            borderRadius: "6px",
            padding: ".9rem 1.2rem",
            background: "#dae8ff",
            cursor: "pointer",
          }}
          onClick={deleteChat}
        >
          {" "}
          <ArrowClockwise size={25} />
        </div>
        {/* <ArrowClockwise  disabled={deleteChatStatus} onClick={deleteChat}>
          {deleteChatStatus || "Restart"}
        </ButtonPrimary> */}
      </ModalConfirmAction>
      <TextInputWrapperStyled onKeyUp={handleOnKeyPress}>
        {" "}
        <TextInput
          style={{ borderRadius: "10px" }}
          placeholder="Ask any question..."
          value={value}
          onChange={(id, value) => setValue(value)}
        />
        <div
          onClick={handleOnClick}
          style={{
            position: "absolute",
            cursor: !isStreaming ? "pointer" : "disabled",
            right: "1.2rem",
            top: "1.2rem",
          }}
        >
          {!isStreaming ? (
            <PaperPlaneRight size={20} />
          ) : (
            <PaperPlaneRight color="#808080" size={20} />
          )}
        </div>
      </TextInputWrapperStyled>
    </SearchBarStyled>
  );
};
