import { colors } from "oolib";
import styled, { css } from "styled-components";


  
export const StyledRepeaterGroupsWrapper = styled.div`
    display: flex;
    flex-direction: column ;
    gap: 1rem;
`

  export const StyledRepeaterGroup = styled.div`
    padding: 2rem;
        border: 1px solid ${colors.greyColor10};
        column-gap: 1rem;
    
  `