import React from "react";
import { mergeEntityData } from "../../utils/mergeEntityData";
import AtomicBlockShell from "../AtomicBlockShell";
import { ImageInput } from "oolib";

export const RTEImageInput = (props) => {
  const {
    // the 3 below recieved from renderAtomicBlock (blockProps)
    editorState,
    handleChange,
    readOnlyProp,
    setReadOnly,
    // the 3 below recieved from entity.data (isnide createAtomicBlocEntity)
    value,
    files,
    multiple,
    //the following 2 is from atomicBlockRenderer
    block,
    entityKey,
    isInRTE,
  } = props;

  return (
    <AtomicBlockShell
      {...{
        setReadOnly,
        readOnlyProp,
        editorState,
        handleChange,
        block,
        /** temp not passed to single image renders cuz RTE earlier used to use ImageInput > SingleImageInput,
         * wherein, a actionmenu existed with edit + replace,
         * here these actions would work.
         *
         * Now thought, it uses ImageInput > InlineImageInput, which for now
         * does not have these actions, hence these wouldnt work. But they
         * will come back soon. So dont delete this code.
         * */
        ...(multiple
          ? {
              actions: [
                {
                  display: "Edit",
                  onClick: () => {
                    let actualEditButton = document.getElementById("editImage");
                    actualEditButton.click();
                  },
                },
                {
                  display: "Replace",
                  onClick: () => {
                    let actualReplaceButton =
                      document.getElementById("replaceImage");
                    actualReplaceButton.click();
                  },
                },
              ],
            }
          : {}),
      }}
    >
      <ImageInput
        value={value}
        files={files}
        onChange={(id, v) => {
          mergeEntityData(
            editorState,
            handleChange,
            entityKey,
            /**
             * the minute a value is set, we want to remove the files, so that on page refresh
             * the ImageInput doesnt try to re-upload the files again.
             */
            { value: v, files: undefined }
          );
        }}
        aspectRatio="4/3"
        readOnly={readOnlyProp}
        multiple={multiple}
        isInRTE={isInRTE}
      />
    </AtomicBlockShell>
  );
};
