import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppSettingsContext } from "../../../../contexts/appSettingsContext";
import { useGetQueryData, useGetQueryState } from "../../../../utils/react-query-hooks/general";
import { PRIMHeaderRouteBlacklist } from '../../config';
import HeaderUI from "./HeaderUI";
 
const PrimaryHeaderV2 = () => {
  // const { APP_SETTINGS, APP_SETTINGS_DISPATCH } = useContext(AppSettingsContext);
  const { APP_SETTINGS, APP_SETTINGS_DISPATCH, thisLoc, setThisLoc} = useAppSettingsContext()
  const appConfig = useGetQueryData('platformConfigs');
  
  const {
    deployment,
    _Nav,
  } = appConfig;

  const {_PrivatePlatform} = deployment;

  let location = useLocation();
  let history = useHistory();

  const { status : loadUserStatus, data : userData } = useGetQueryState('userData');

  //if secondary header is there,
  //then apply a -six-six- class to doc.body.
  //earlier we used this to adjust css on the platform, since we now have a 12rem header.
  //although maybe we need a better way than this...
  useEffect(() => {
    if (!_Nav.secondary) {
      document.body.setAttribute("class", "-six-six-");
    }
  }, []);

  //scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  },[location.pathname])


  //track prev locations, for breadcrumbs i think..
  useEffect(() => {
    
    if(
      thisLoc
    ){
      let lastPrevLoc = APP_SETTINGS.prevLoc && APP_SETTINGS.prevLoc[0]?.pathname + APP_SETTINGS.prevLoc[0]?.search;
      if(lastPrevLoc !== location.pathname + location.search){
        APP_SETTINGS_DISPATCH({
          type: 'SET_PREV_LOC',
          payload: thisLoc
        })
      }else{
        APP_SETTINGS_DISPATCH({
          type: 'REMOVE_LAST_PREV_LOC'
        })
      }
      
    }

    setThisLoc(location);

  }, [location.pathname]);


  //temp ACCESS CONTROL for private platforms
  useEffect(()=>{
    
    if(loadUserStatus === 'error'){
      /**temp till we have solid access control */
      if( 
        !userData?.user && 
        _PrivatePlatform && 
        [
          'verifyResetPasswordMail', 
          '/googleAuth', 
          '/api/OAuth' /**only for localhost convenience */
        ].every(d => !location.pathname.includes(d))
      ){
        history.push('/login')
      }
    }
  },[loadUserStatus])


  

//dont render if config says not to
if(PRIMHeaderRouteBlacklist.some(d => location.pathname.includes(d))){
  return null
}

  return loadUserStatus !== 'loading' && ( //status = userLoading status
    <HeaderUI config={{_Nav, deployment}}/>
  );
};

export default PrimaryHeaderV2;
