import { cloneDeep } from "lodash";
import { CheckboxInput, SANS_2, SkeletonLoader, TextInput, colors, icons } from "oolib";
import React, { useEffect, useRef, useState } from "react";

import {
  convertMultiValueToTagsValue,
  convertTagDocsDataToOptions,
  convertTagsValueToMultiValue,
  useGetTypeCategoryAndTags,
} from "../../../../../../../../DynamicTagsInputs/utils";

import { AddNewTagInput } from "../AddNewTagInput";
import {
  AddOthersStyled,
  StyledSearchWrapper,
  SubMenuContainerStyled,
  SubMenuItemStyled,
  SubMenuWrapperStyled,
} from "../../styled";

const { greyColor5 } = colors;
const { Plus } = icons;

//------------------------------------- SUB MENU COMPONENTS ----------------------------------------------------

export const TagsSubmenu = ({
  showTagsLightbox,
  containerRef,
  value,
  onChange,
}) => {
  const {
    data,
    status,
    // isRefetching, // <--
    // tagType,
    tagCategory,
    // tagSegment
  } = useGetTypeCategoryAndTags({
    tagTypeProp: showTagsLightbox.tagType,
    incExcTagsList: showTagsLightbox.incExcTagsList,
    enableDataFetch: !!showTagsLightbox.tagType,
  });

  const [ searchValue, setSearchValue ] = useState(undefined)
  const [ options, setOptions ] = useState([]) 
  const [ filteredOptions, setFilteredOptions ] = useState([])
  const subMenuRef = useRef();
  const scrollableRef = useRef();

  const [pos, setPos] = useState({ visibility: "hidden" });
  const [showAddTag, setShowAddTag] = useState(false);

  const {
    right: menuRight,
    height: menuHeight,
    bottom: menuBottom,
  } = containerRef.current.parentElement.getBoundingClientRect();

  useEffect(() => {
    setSearchValue('');
    setOptions([]);
    setFilteredOptions([]);
    // if(status!=="success") return
    const menuWidth = containerRef.current.offsetWidth;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const { top, height } = showTagsLightbox;

    const { width: subMenuWidth, height: subMenuHeight } =
      subMenuRef.current.getBoundingClientRect();

    const localPos = {
      left: menuWidth,
      top: top,
      visibility: "visible",
    };

    if (menuRight + subMenuWidth >= screenWidth) {
      localPos.right = localPos.left;
      delete localPos.left;
    }

    const mainMenuHeightNSubmenuHeightDiff = Math.abs(
      subMenuHeight - menuHeight
    );

    // console.log({ mainMenuHeightNSubmenuHeightDiff })

    if (menuBottom + top >= screenHeight) {
      localPos.top = -(
        menuHeight -
        (top + height + mainMenuHeightNSubmenuHeightDiff)
      );
    }

    setPos(localPos);
  }, [showTagsLightbox.tagType, status]);

  const handleOptionClick = (option) => {
    let prev = convertTagsValueToMultiValue(value[showTagsLightbox.tagType]);

    let newValue = prev?.length > 0 ? cloneDeep(prev) : undefined;
    if (newValue) {
      if (newValue.some((dd) => dd.value === option.value)) {
        newValue = newValue.filter((dd) => dd.value !== option.value); //deselect
      } else {
        newValue.push(option); //select
      }
    } else {
      newValue = [option];
    }

    onChange(
      showTagsLightbox.tagType,
      convertMultiValueToTagsValue(showTagsLightbox.tagType, newValue)
    );
  };
  
  useEffect(() => {
    if (status === "success") {
      const newOptions = convertTagDocsDataToOptions(data, "tags");
      setOptions(newOptions);
      setFilteredOptions(newOptions);
    }
  }, [status, data, showTagsLightbox.tagType]);

  useEffect(() => {
    if(!searchValue){
      setFilteredOptions(options)
    }else { 
      const filtered = searchValue ? options.filter(item =>
        item?.display?.toLowerCase().includes(searchValue?.toLowerCase())
      ): options;
      setFilteredOptions(filtered);
    }
  }, [searchValue])

  return (
    <SubMenuWrapperStyled {...pos} ref={subMenuRef}>
      <SubMenuContainerStyled ref={scrollableRef}>
        {status === "loading" ? (
          <SkeletonLoader
            style={{
              minWidth: "25rem",
              height: "21.6rem",
              backgroundColor: greyColor5,
            }}
          />
        ) : status === "error" ? (
          <span>Error</span>
        ) : (
          status === "success" &&
          <>
          <StyledSearchWrapper>
            <TextInput placeholder={"Search..."} style={{ padding:'0 15px'}} value={searchValue} onChange={(id, value) => setSearchValue(value)}/>
          </StyledSearchWrapper>
          {(filteredOptions.length > 0 ) ? filteredOptions.map((d) => {
            return (
              <SubMenuItemStyled
                onMouseDown={(ev) => {
                  ev.preventDefault();
                  /**
                   * stopPropagation prevents this click event from bubbling up to
                   * any of its parents, because of which several strage bugs
                   * get fixed. 1 such being the add tag textinput doesnt
                   * remove the selection from the 'to annotate' text.
                   *
                   * Another being, the svg checkbox doesnt trigget 'outside click'
                   * (for some reason svgs are not read as children of html parents)
                   * which would hide the lightbox.
                   * Not sure why this is. but worth understanding at some point
                   */
                  ev.stopPropagation();
                  handleOptionClick(d);
                }}
                key={d.value}
                id={d.value}
              >
                <CheckboxInput
                  S
                  option={d}
                  value={convertTagsValueToMultiValue(
                    value[showTagsLightbox.tagType]
                  )}
                />
              </SubMenuItemStyled>
            );
          })
         :
         <div style={{padding: '1rem 2rem'}}><SANS_2>No results!</SANS_2></div> 
        }
          </>
        )}
      </SubMenuContainerStyled>

      {status === "success" &&
        showTagsLightbox.allowCreateTag !== false &&
        (showAddTag ? (
          <AddNewTagInput
            showTagsLightbox={showTagsLightbox}
            tagCategory={tagCategory}
            handleClose={() => setShowAddTag(false)}
            value={value}
            handleAddTag={onChange}
            scrollableRef={scrollableRef}
          />
        ) : (
          <AddOthersStyled
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowAddTag(true);
            }}
          >
            <Plus weight="bold" size={12} />
            <SANS_2 semibold>Add Others</SANS_2>
          </AddOthersStyled>
        ))}
    </SubMenuWrapperStyled>
  );
};
