import React from "react";
import { StyledShell, StyledShellHead } from "./styled";
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import { ActionMenu } from "oolib";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

export const DecoratorNodeShell = ({
  children,
  onRemove,
  actions = [],
  nodeKey,
}) => {
  
  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey);
  const [editor, theme] = useLexicalComposerContext();

  const { invert, readOnly } = theme.getTheme().RTE_COMP_PROPS || {};

  return (
    <StyledShell 
      isSelected={isSelected} readOnly={readOnly}
      tabIndex={0}
      {...(
        // this temp toggle of editor editability needs
        // to happen only when we are in 'edit mode'
        !readOnly
        ? {
          onFocus: () => editor.setEditable(false),
          onBlur: () => editor.setEditable(true)
        } : {}
      )}
      
      >
      {!readOnly && <StyledShellHead>
        <ActionMenu
          align="right"
          invert={invert}
          actions={[
            ...actions,
            {
              icon: "Trash",
              display: "Remove",
              onClick: onRemove,
            },
          ]}
        />
      </StyledShellHead>}

      {children}
    </StyledShell>
  );
};
