import { mediaQuery } from "oolib";
import styled from "styled-components";

export const StyledSearchInputWrapper = styled.div`
  overflow-x: hidden;
  width: 100%;
  ${mediaQuery('md')}{
    max-width: 32rem;
  }

`;