import { mediaQuery,colors } from "oolib";
import styled from "styled-components";
const {white}  = colors



export const TagIdSliderContainerStyled = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

export const StyledStickyWrapper = styled.div`
  box-shadow: 2px 2px 1rem rgba(0, 0, 0, 0.05);
  /* z-index: 2; */
  /* position: sticky; */
  /* top: 0; */
  background-color: ${white} ;

  /* ${mediaQuery('md')}{
    top: ${({headerHeight})=> headerHeight + 'px'};
  } */
`;