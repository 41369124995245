
/**
 * the only reason this file exists is so that we have
 * all our socket event names consolidated in one single place.
 * i.e. this is just for the purpose of staying organised
 */

//global
export const ATTACH_USER_DATA = 'ATTACH_USER_DATA'
export const DETACH_USER_DATA = 'DETACH_USER_DATA'
export const DISCONNECT_USER = 'DISCONNECT_USER'

//TCI rel
export const ENTER_TCI_SPACE = 'ENTER_TCI_SPACE'
export const EXIT_TCI_SPACE = 'EXIT_TCI_SPACE'
export const UPDATE_TCI_TPL = 'UPDATE_TCI_TPL'
    