import { colors, SANS_3, Loader} from "oolib";
import React, { useMemo, useState } from "react";
import { useStretchDivToRemainingHeight } from "../../../utils/customHooks";
import ActivityAnalyticsList from "../../../pageTpls/PlatformBuilder/subModules/AdminActivityAnalyticsList";
import { Wrapper1000 } from 'oolib';
import { StyledDataModuleContainer } from "./styled";
import OverviewModule from "../OverviewModule";
import PiChart from "../PiChart";
import {
  countViolationTypes,
  getParalegalOptions,
  reShapeData
} from "./functions";
import { useGetThisContentTypeAnalyticsData } from "../../../utils/react-query-hooks/contributions/useGetThisContentTypeAnalyticsData";


const { greyColor5, white } = colors;

const NCTWGData = ({content}) => {
  
  const { data, status: contentAnalyticsStatus } = useGetThisContentTypeAnalyticsData({
    contentType: content.meta.kp_content_type,
    id: content._id,
  });

  return contentAnalyticsStatus === "loading" ? (
    <Loader/>
  ) : contentAnalyticsStatus === "error" ? (
    <SANS_3>Error</SANS_3>
  ) : (
    contentAnalyticsStatus === "success" && (
      <Dashboard
        caseReportsData={data.caseReportsData}
        activityAnalyticsData={data.activityAnalyticsData}
      />
    )
  );
};



export default NCTWGData;

const Dashboard = ({ caseReportsData, activityAnalyticsData }) => {
  
  const views = useMemo(() => {
    return getParalegalOptions(caseReportsData);
  });

  const [activeView, setActiveView] = useState(views[0]);

  const containerRef = useStretchDivToRemainingHeight();

  // filters : {
  //   'content.data.meta.community.internal.0._id' : profileId
  // },
  // populate : [ { path: 'content.data'} ]
  // })

  return (
    // <div>NCT WG DATA</div>
    <div style={{ backgroundColor: greyColor5, height: "100%" }}>
      <Wrapper1000>
        <div style={{ padding: "2rem 0" }}>
          {!caseReportsData || caseReportsData.length === 0 ? (
            <SANS_3 bold>
              Publish a case report to activate this dashboard!
            </SANS_3>
          ) : (
            <OverviewModule
              data={reShapeData(caseReportsData, activeView).newData}
              loading={!!!reShapeData(caseReportsData, activeView).newData}
              dataPointsConfig={
                reShapeData(caseReportsData, activeView).dataPointsConfig
              }
              views={views}
              activeView={activeView}
              dropdownLabel={'Paralegals'}
              onDropdownChange={(k, v) => setActiveView(v)}
            />
          )}
        </div>
        <StyledDataModuleContainer displayAnalytics={!!activityAnalyticsData}>
          <div style={{ backgroundColor: white, padding: "2rem" }}>
            <PiChart
                id={'NCTWGData_piChart'}
                legendOptions={countViolationTypes(caseReportsData).options}
                colorMap={countViolationTypes(caseReportsData).options.map(
                  (d) => d.color
                )}
                data={countViolationTypes(caseReportsData).counts}
              />
          </div>
          {activityAnalyticsData && (
            <div style={{ backgroundColor: white, overflow: 'auto', maxHeight: '100vh' }} ref={containerRef}>
              <ActivityAnalyticsList
                data={activityAnalyticsData}
                status={"success"}
              />
            </div> 
          )}
        </StyledDataModuleContainer>
      </Wrapper1000>
    </div>
  );
};
