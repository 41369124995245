


const config = {
    sm: {
      minFontSize: 10,
      maxFontSize: 20,
      padding: 5,
    },
    lg: {
      minFontSize: 20,
      maxFontSize: 42,
      padding: 10,
    },
  }

  export default config