import { $getSelection } from "lexical";
import { $isHeadingNode, $isQuoteNode } from "@lexical/rich-text";
import { $isListNode } from "@lexical/list";
import { $isLinkNode, $isAutoLinkNode } from "@lexical/link";
import { useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const getNodeInfoIf = (selection, $isXNode) => {
  const nodesInSelection = selection?.getNodes();

  /**
   * LETS TAKE HEADING NODE AS AN EXAMPLE
   * check to see if every node in selection is
   * either a heading node OR has a ancestor that is
   * a heading node. That guarantees that the entire
   * selection is some 'heading'
   */
  if (
    nodesInSelection?.every((thisNode) => {
      let allAncestorNodes = thisNode.getParents();
      return [thisNode, ...allAncestorNodes].some((node) => $isXNode(node));
    })
  ) {
    /**
     * Now that we know the entire selection is some heading,
     * all we need to do is get the first node, look through
     * itself and its ancestors to find the heading node, then return
     * all the node info. This includes tag, type, listType etc.
     */

    return [nodesInSelection[0], ...nodesInSelection[0].getParents()].find(
      (node) => $isXNode(node)
    );
  }
};

export const useGetActiveNodeInfoInSelection = () => {
  const [editor] = useLexicalComposerContext();
  const [activeNodeInfo, setActiveNodeInfo] = useState(undefined);
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        const _activeNodeInfo =
          getNodeInfoIf(selection, $isHeadingNode) ||
          getNodeInfoIf(selection, $isListNode) ||
          getNodeInfoIf(selection, $isQuoteNode) ||
          getNodeInfoIf(selection, $isLinkNode) ||
          getNodeInfoIf(selection, $isAutoLinkNode);
        if (_activeNodeInfo !== activeNodeInfo)
          setActiveNodeInfo(_activeNodeInfo); //basically only if a change has happened, then update state. optimization this is
      });
    });
  }, [editor, activeNodeInfo]);

  return activeNodeInfo;
};
