import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React from "react";
import { ToolbarButton } from "../comps/ToolbarButton";
import { config } from "./config";
import { handleClick } from "./handleClick";
import { useGetActiveFormatInSelection } from "./utils/useGetActiveFormatInSelection";

export const FormattingTools = () => {
  const [editor] = useLexicalComposerContext();
  const activeFormat = useGetActiveFormatInSelection();

  return (
    <>
      {config.map((conf) => {
        return (
          <ToolbarButton
            isActive={activeFormat?.includes(conf.format)}
            onClick={() => handleClick({ editor, format: conf.format })}
            icon={conf.icon}
          />
        );
      })}
    </>
  );
};
