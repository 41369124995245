import React from "react"
import InfoText from "../../../../../../components/generalUI/InfoText"

export const InvalidRemarkComp = ({parentRowRawData}) => {
    return (
        <>
        {parentRowRawData.remarks?.map(d => (
            <InfoText
            description={d}
            />
        ))}
        </>
        
    )
}