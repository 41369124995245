import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React from "react";
import { ToolbarButton } from "../comps/ToolbarButton";
import { handleClick } from "./handleClick";
import {config} from './config'
export const TableTool = ({activeNodeInfo}) => {
  const [editor] = useLexicalComposerContext();


  // const checkIsActive = ({listType, activeNodeInfo}) => {
  //   return listType === activeNodeInfo?.__listType
  // }

  return (
    
      <ToolbarButton
        // isActive={isActive}
        onClick={() => {
          handleClick({ /*isActive,*/ editor })
        }}
        icon={config.icon}
      />
    
  );
};
