
const setClassNamesToBlockTypes = (contentBlock) => {
	// consider passing the base typo here, then adding numbers of top of it to style the block types below
	const type = contentBlock.getType()
	switch (type){
		case 'blockquote':
			return 'CONTENT_QUOTE'
		case 'header-one':
			return 'CONTENT_H1'
		case 'header-two':
			return 'CONTENT_H2'
		case 'header-three':
			return 'CONTENT_H3'
		case 'unordered-list-item':
		case 'ordered-list-item':
			return 'CONTENT_BODY'
		case 'unstyled':
			return 'paragraph'
		default:
			return;	
	}
}

export default setClassNamesToBlockTypes;