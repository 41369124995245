import styled from "styled-components";
import { colors } from "oolib";

export const StyledWrapper = styled.div`
  /* padding-bottom: 10rem; */
  user-select: none;
  /* background-color: ${colors.greyColor5}; */
  height: 100%;
`;


export const StyledSectionWrapper = styled.div`
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-left: 1px dashed ${colors.greyColor10};
    padding-left: 1.5rem;
    margin-left: 1.5rem;
`

export const StyledSidebarBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
