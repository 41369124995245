import React from "react";
import { SANS_3, SANS_2 } from "oolib";

function calculateDaysElapsed(targetDate) {
  const currentDate = new Date();
  const target = new Date(targetDate);
  const diffTime = currentDate - target;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}

const ElapsedDays = ({ value, textComp = "SANS_3", ...textCompProps }) => {
  const TextComps = {
    SANS_2,
    SANS_3,
  };

  const TextComp = TextComps[textComp];

  const days = calculateDaysElapsed(value)

  return (
    <TextComp {...textCompProps}>
      {days} {days===1?"day":"days"}
    </TextComp>
  );
};

export default ElapsedDays;
