import { colors, transition } from "oolib";
import styled, { css } from "styled-components";

export const StyledToolbarButton = styled.button`
  height: 4rem;
  width: 4rem;
  padding: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isDisabled }) => css`
    cursor: ${isDisabled ? "not-allowed !important" : "pointer"};
  `}
  border: none;
  color: ${({ invert, isDisabled, theme }) => {
    return isDisabled
      ? colors?.greyColor40
      : invert
      ? theme?.colors.primaryColor40
      : colors.greyColor100;
  }};
  background-color: ${({ isActive, invert }) => {
    if (invert) return isActive ? colors.greyColor80 : colors.greyColor100;
    return isActive ? colors.greyColor10 : colors.greyColor5;
  }};
  &:hover {
    background-color: ${({ invert, isActive, theme, isDisabled }) =>
      !isActive &&
      !isDisabled &&
      (invert ? colors.greyColor70 : theme?.colors.primaryColor40)};
  }
  ${transition("background-color")};
`;
