import styled from "styled-components";

export const SearchBarStyled = styled.div`
  padding: 0rem 0 1rem 0;
  width: 100%;
  align-items: center;
  /* background-color: #ffffff; */
  display: flex;
  gap: 1rem;
`;

export const TextInputWrapperStyled = styled.div`
  flex: 1;
  position: relative;

  div div {
    border-radius: 6px;
    border: 2px solid #e6e6e6;
  }
`;
