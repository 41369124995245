import { Modal, TextInput } from "oolib";
import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { __GetConfigsBySegmentNew } from "../../../../../../utils/getters/gettersV2";
import { buildListingPageQueryForKS } from "../../../../../../utils/okf-ks-utils/buildListingPageQueryForKS";
import { useRTEContext } from "../../RTEContext";

import ListingModule from "../../../../../../pageTpls/ListingPage/comps/ListingModule";
import { fixedGetDataConfig } from "../../../../../../pageTpls/PublishedListing/config/fixedGetDataConfig";
import { useDebounce } from "../../../../../../utils/customHooks";
import { RTEDefaultInternalEmbedConfig } from "./configs/RTEDefaultInternalEmbedConfig";
import { createAtomicBlockForEmbedType } from "./utils/createAtomicBlockForEmbedType";
import { createLinkPreviewForEmbedType } from "./utils/createLinkPreviewForEmbedType";
import { RTEInsertLink } from "./utils/RTEInsertLink";
import {
  useGetEmbedTypeFromUrl
} from "./utils/useGetEmbedTypeFromUrl";
import { trackEventGA } from "../../../../../../trackers/GA/trackEventGA";

const StyledPaddingLeftRight20 = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
`;

export const LinkOrEmbedModal = ({ mode, editorState, handleChange }) => {
  const theme = useTheme();
  const { setShowLinkOrEmbedModal } = useRTEContext();

  // 2 separate states to manage the same thing, since one is debounced and one is not.
  const [searchInputText, setSearchInputText] = useState(""); // this is being read by the TextInput comp
  const [searchInputTextDebounced, setSearchInputTextDebounced] = useState(""); // this is being read by the react-query hook
  const debounce = useDebounce();
  const onSearchInputChange = (id, value) => {
    setSearchInputText(value);
    debounce(() =>{ 
      trackEventGA("Search Flow","LinkEmbedModule Searched")
      setSearchInputTextDebounced(searchInputText)
    });
  };

  const {
    data: contentData,
    status: contentStatus,
    error:contentError,
    fetchNextPage: contentFetchNextPage,
    isFetchingNextPage: contentIsFetchingNextPage,
  } = theme.useGetDataLazy({
    ...fixedGetDataConfig,
    contentTypes: __GetConfigsBySegmentNew("publishing").map(
      (d) => d.kp_content_type
    ),
    searchTerm: searchInputTextDebounced,
    ksConfig:
      searchInputTextDebounced &&
      buildListingPageQueryForKS({
        keyword: searchInputTextDebounced,
        config: { fields: ["main.title.blocks.text.autoComplete"] },
        activeSort: { kp_date_published: -1 },
      }),
    queryOptions: {
      enabled: !!searchInputTextDebounced,
    },
  });

  const { embedType, loading: loadingEmbedType } =
    useGetEmbedTypeFromUrl(searchInputText);

  const handleInsertLinkOrEmbed = ({ url, embedType }) => {
    if (mode === "link") {
      RTEInsertLink({
        url,
        editorState,
        handleChange,
      });
      setShowLinkOrEmbedModal(undefined);
    } else {
      createAtomicBlockForEmbedType({
        editorState,
        handleChange,
        embedType,
        urlString: url,
      });
      setShowLinkOrEmbedModal(undefined);
    }
  };

  return (
    <Modal
      desktopWidth="900px"
      onClose={() => {
        setShowLinkOrEmbedModal(undefined);
      }}
      title={
        mode === "link" ? "Insert Link" : mode === "embed" && "Insert Embed"
      }
    >
      <div
        onMouseDown={(e) => {
          /**
           * this ensures that any 'click' within the modal wont deselect the text in RTE.
           * BUT focus on text input STILL will deselect the text.
           * draft js tho, still remembers the previous selection state,
           * and everything works fine.
           */
          e.preventDefault();
        }}
      >
        <div style={{ padding: "3rem 2rem" }}>
          <TextInput
            placeholder="Paste link or search pages"
            value={searchInputText}
            onChange={onSearchInputChange}
            clearBtn={{ icon: "X" }}
            icon={"LinkSimple"}
          />
        </div>
        {searchInputText && (
          <>
            <StyledPaddingLeftRight20>
              <div
                onClick={() =>
                  embedType &&
                  handleInsertLinkOrEmbed({ url: searchInputText, embedType })
                }
              >
                {createLinkPreviewForEmbedType({
                  embedType,
                  loadingEmbedType,
                  urlString: searchInputText,
                })}
              </div>
            </StyledPaddingLeftRight20>
            <ListingModule
              error={contentError}
              status={contentStatus}
              data={contentData}
              listingStyle={"ListStyle"}
              listingConfig={{
                wrapper: StyledPaddingLeftRight20,
                onClick: (e, d) =>
                  handleInsertLinkOrEmbed({
                    url: `${window.location.protocol}//${window.location.host}/published-page/${d.meta.kp_content_type}?id=${d._id}`,
                    embedType: "internal-link",
                  }),
                configs: RTEDefaultInternalEmbedConfig,
              }}
              fetchNextPage={contentFetchNextPage} //fetchNextPage could be undefined if lazy load is disabled in listing page. so we make it a empty function as a fallback to avoid errors
              isFetchingNextPage={contentIsFetchingNextPage}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
