import { useEffect, useState } from "react"
import { sortData } from "oolib"

export const useSort_FRONTEND = ({
    tableConfig, 
	dataProp, 
    isBackendSort
}) => {
    const [data, setData] = useState(
		!isBackendSort && setInitSortedData_FRONTEND({tableConfig, dataProp})
	)
	const [activeSorts, setActiveSorts] = useState(
		!isBackendSort && setInitActiveSorts_FRONTEND({tableConfig, dataProp})
	)

	//i guess when you switch table from tabbar
	//you need to run this 
	//+ now we need this for backend sort etc.
	useEffect(() => {
		
		if(!isBackendSort){
			setData(setInitSortedData_FRONTEND({tableConfig, dataProp}))
			setActiveSorts(setInitActiveSorts_FRONTEND({tableConfig, dataProp}))
		}
		
	},[JSON.stringify(dataProp)])

    return ({data, setData, activeSorts, setActiveSorts})
}

const setInitActiveSorts_FRONTEND = ({tableConfig, dataProp}) => {
	let activeSorts = {}
	tableConfig.cellData.map((d) => {
		if (d.sortConfig) {
			activeSorts[d.colId] = d.defaultSort
				? (d.defaultSortBy || 'a')
				: 'none'
		}
	})
	return activeSorts
}


const setInitSortedData_FRONTEND = ({tableConfig, dataProp}) => {
	 //cuz sometimes data hasn't loaded yet. messy stuff. need to clean up
	if(!dataProp) return []
	let sortedData;
	tableConfig.cellData.map((d) => {
		if (d.sortConfig && d.defaultSort === true) {
			let sortBy = d.defaultSortBy || 'a'	
			sortedData = sortData({
				data: dataProp, 
				sortBy,
				...d.sortConfig 
			})
		}
	})
	return sortedData || dataProp
}