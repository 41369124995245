import { CheckboxList, colors, Container, TabBarStyle1, PaddingTopBottom40, useScreenWidth, SANS_2, ProfileImageInput} from "oolib";
import React, { useState } from "react";
import { DropdownSingle, DropdownMulti } from "oolib";
import TagsInputSingle from "../../../../components/inputs/DynamicTagsInputs/TagsInputSingle";
import TagsInputMulti from "../../../../components/inputs/DynamicTagsInputs/TagsInputMulti";





export const DropdownsTest = () => {

  useScreenWidth()

  const [val, setVal] = useState({})

  const staticOps = [
    { display: 'Armenia', value: 'option1', image: [{publicUrl: 'https://img-getpocket.cdn.mozilla.net/592x296/filters:format(jpeg):quality(60):no_upscale():strip_exif()/https%3A%2F%2Fs3.us-east-1.amazonaws.com%2Fpocket-curatedcorpusapi-prod-images%2Fb313de34-b4ff-4c3a-9d64-867607d7041d.jpeg'}]},
    { display: 'Belgrade', value: 'option2'},
    { display: 'Chinchpokli', value: 'option3'},
    { display: 'Dublin', value: 'optionssd4'},
    { display: 'Kurla', value: 'optioqwsvddn1'},
    { display: 'Howrah', value: 'optiosvn2'},
    { display: 'Calcutta', value: 'opdction3'},
    { display: 'Puerto Rico', value: 'optiovfsfn4'},
    { display: 'New York City', value: 'optiobvffbsn1'},
    { display: 'Berlin', value: 'optiadsvdon2'},
    { display: 'Constantinople', value: 'optiadson3'},
    { display: 'Gujarat', value: 'optionvsd4'}
  ]

  const configs = [
    {
      id: 'dropdownSingle',
      comp: 'DropdownSingle',
      label: 'Dropdown Single',
      injectOtherOption: true
    },
    {
      id: 'dropdownMulti',
      comp: 'DropdownMulti',
      label: 'Dropdown Multi',
      injectOtherOption: true
    },
    {
      id: 'dropdownSingle_searchable',
      comp: 'DropdownSingle',
      label: 'Searchable Dropdown Single',
      isSearchable: true,
      injectOtherOption: true
    },
    {
      id: 'dropdownMulti_searchable',
      comp: 'DropdownMulti',
      label: 'Searchable Dropdown Multi',
      isSearchable: true,
      injectOtherOption: true
    },
    {
      id: 'tagsInputSingle',
      comp: 'DropdownSingle',
      label: 'Tags Input Single',
      isTagsStyle: true,
      injectOtherOption: true
    },
    {
      id: 'tagsInputMulti',
      comp: 'DropdownMulti',
      label: 'Tags Input Multi',
      isTagsStyle: true,
      injectOtherOption: true
    },



    // ---


    {
      id: 'dropdownSingle_dy',
      comp: 'TagsInputSingle',
      label: 'Dropdown Single',
      tagType: 'colors',
      allowCreateTag: true,
      dynamicOps: true,
      isTagsStyle: false
    },
    {
      id: 'dropdownMulti_dy',
      comp: 'TagsInputMulti',
      label: 'Dropdown Multi',
      tagType: 'colors',
      allowCreateTag: true,
      dynamicOps: true,
      isTagsStyle: false
    },
    {
      id: 'dropdownSingle_searchable_dy',
      comp: 'TagsInputSingle',
      label: 'Searchable Dropdown Single',
      isSearchable: true,
      tagType: 'colors',
      allowCreateTag: true,
      dynamicOps: true,
      isTagsStyle: false
    },
    {
      id: 'dropdownMulti_searchable_dy',
      comp: 'TagsInputMulti',
      label: 'Searchable Dropdown Multi',
      isSearchable: true,
      tagType: 'colors',
      allowCreateTag: true,
      dynamicOps: true,
      isTagsStyle: false,
    },
    {
      id: 'tagsInputSingle_dy',
      comp: 'TagsInputSingle',
      label: 'Tags Input Single',
      isTagsStyle: true,
      tagType: 'colors',
      allowCreateTag: true,
      dynamicOps: true
    },
    {
      id: 'tagsInputMulti_dy',
      comp: 'TagsInputMulti',
      label: 'Tags Input Multi',
      isTagsStyle: true,
      tagType: 'colors',
      allowCreateTag: true,
      dynamicOps: true
    },
    {
      id: 'tagsInputSingle_dy',
      comp: 'TagsInputSingle',
      label: 'Tags Input Single NEW',
      isTagsStyle: true,
      tagType: 'colors',
      allowCreateTag: true,
      variant: 'radio',
      dynamicOps: true
    },
    {
      id: 'tagsInputMulti_dy',
      comp: 'TagsInputMulti',
      label: 'Tags Input Multi NEW',
      isTagsStyle: true,
      tagType: 'colors',
      allowCreateTag: true,
      variant: 'checkbox',
      dynamicOps: true
    }

  ]

  const comps = {
    DropdownSingle,
    DropdownMulti,
    TagsInputSingle,
    TagsInputMulti,
    TagsInputSingle,
    TagsInputMulti
  }

  const [activeProps, setActiveProps] = useState([])
  const [activeTab, setActiveTab] = useState('staticOps')

  return (
    <Container>
      <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
       <ProfileImageInput
          readOnly
          value={[{publicUrl: 'https://img-getpocket.cdn.mozilla.net/592x296/filters:format(jpeg):quality(60):no_upscale():strip_exif()/https%3A%2F%2Fs3.us-east-1.amazonaws.com%2Fpocket-curatedcorpusapi-prod-images%2Fb313de34-b4ff-4c3a-9d64-867607d7041d.jpeg'}]}
          size={30}
          imageTitle={'test'}
        />
        <SANS_2>hello</SANS_2>
      </div>
      
      <PaddingTopBottom40>
        <CheckboxList
          options={[
          
              {display: 'Read Only', value: 'readOnly'},
            {display: 'Invert', value: 'invert'},
            {display:"Disabled",value:"disabled"}

          ]}
          saveValueAsString
          value={activeProps}
          onChange={(k,v) => setActiveProps(v)}
          />
          <TabBarStyle1
            options={[
              {display:'Static Options', value: 'staticOps'},
              {display:'Dynamic Options', value: 'dynamicOps'},
            ]}
            saveValueAsString
            value={activeTab}
            onChange={(k,v) => setActiveTab(v)}
            />
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {configs.map(d => {
        let Comp = comps[d.comp]
        return (
          (d.dynamicOps && activeTab === 'dynamicOps') ||
          (!d.dynamicOps && activeTab === 'staticOps')
        ) && (
        <div
          key={d.id}
          style={{
            border: '1px solid black',
            width: '40vw',
            padding: '2rem',
            // height: '200px',
            backgroundColor: activeProps.includes('invert') ? colors.greyColor100 : colors.none
          }}
          >
            <Comp
            id={d.id}
              isSearchable={d.isSearchable}
              options={staticOps}
              label={d.label+" M"}
              onChange={(k,v) => setVal(prev => ({ ...prev, [d.id] : v}))}
              value={val[d.id]}
              isTagsStyle={d.isTagsStyle}
              tagType={d.tagType}
              allowCreateTag={d.allowCreateTag}
              injectOtherOption={d.injectOtherOption}
              displayCompStyle={d.displayCompStyle}
              invert={activeProps.includes('invert')}
              readOnly={activeProps.includes('readOnly')}
              disabled = {activeProps.includes("disabled")}
              variant={d.variant}
              />
               <Comp
              S
              isSearchable={d.isSearchable}
              options={staticOps}
              label={d.label+" S"}
              onChange={(k,v) => setVal(prev => ({ ...prev, [d.id] : v}))}
              value={val[d.id]}
              isTagsStyle={d.isTagsStyle}
              tagType={d.tagType}
              allowCreateTag={d.allowCreateTag}
              injectOtherOption={d.injectOtherOption}
              displayCompStyle={d.displayCompStyle}
              invert={activeProps.includes('invert')}
              readOnly={activeProps.includes('readOnly')}
              disabled = {activeProps.includes("disabled")}

              />
          </div>
      )})}
     
    </div>
    </PaddingTopBottom40>
    </Container>
  );
};
