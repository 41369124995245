import React from 'react'

import {SkeletonLoader,colors, makeArrayFromLength} from 'oolib'

export const TableSkeletonLoader = ({
    rowsCount = 4,
    dataLength = 0
}) => {

  return (
    <div>
      {makeArrayFromLength(rowsCount).map((d, i) => (
        <div key={i} style={{ height: "40px" }}>
          <SkeletonLoader
            style={{
              width: "100%",
              height: "100%",
              backgroundColor:
                (dataLength === 0 || dataLength % 2 === 0) 
                //meaning row before this loader is white (* note below)
                  ? i === 0 || i % 2 === 0
                    ? colors.greyColor3
                    : colors.white
                  : i === 0 || i % 2 === 0
                  ? colors.white
                  : colors.greyColor3,
            }}
          />
        </div>
      ))}
    </div>
  );
};


/**
 * dataLength is useful in the lazyload situation,
 * where, if you know the color of the last row on the table,
 * i.e. grey or white,
 * then you can make the skeleton loader also sync up with the same 
 * pattern of grey-white-grey-white or white-grey-white-grey
 */