import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { icons, UI_TITLE_SM } from 'oolib'
import {
  StyledFilterHeading,
  StyledSideBar
} from './styled'
import { StyledFilterMobileButton } from '../../styled'
import { SIDEBAR_WIDTH } from '../..'


const { CaretLeft } = icons

const framerSidebarPanel = {
  initial: { x: '-100%' },
  animate: { x: 0 },
  exit: { x: '-100%' },
  transition: { duration: 0.5 },
}

const SidebarFilter = ({ isMobile, setShowSideBarLeft, showSideBarLeft, filter }) => {
  return(<>
    {isMobile && (
      <StyledFilterHeading>
        <UI_TITLE_SM>Filters</UI_TITLE_SM>
        <StyledFilterMobileButton onClick={() => setShowSideBarLeft(!showSideBarLeft)}>
          <CaretLeft size={24} weight='bold'/>
        </StyledFilterMobileButton>
      </StyledFilterHeading>
    )}
    {filter} {/* Renders Filters  */}
  </>
)}

export const Sidebar = ({ showSideBarLeft, filter, actionBarHeight, tabsPanelHeight, isMobile, setShowSideBarLeft }) => (
  <AnimatePresence mode="wait" initial={false}>
    {(showSideBarLeft && filter) && (
      <StyledSideBar
        {...framerSidebarPanel}
        showSideBarLeft={showSideBarLeft}
        top={(actionBarHeight || 0) + (tabsPanelHeight || 0)}
        isMobile={isMobile}
        sidebarWidth={SIDEBAR_WIDTH}
      >
        <SidebarFilter
          isMobile={isMobile}
          setShowSideBarLeft={setShowSideBarLeft}
          showSideBarLeft={showSideBarLeft}
          filter={filter}
        />
      </StyledSideBar>
    )}
  </AnimatePresence>
)
