import { debounce } from "lodash";

const scrollDownIfNecessary =(selectionState)=> {
  const windowSelection = window.getSelection();
  if (
    selectionState?.hasFocus && 
    selectionState?.isCollapsed() &&
    windowSelection.anchorNode &&
    windowSelection.focusNode
  ) {
    
    //if change is a line break, then the second comment line would have to be used to trigger scroll
    const cursorTop = windowSelection.getRangeAt(0)?.getBoundingClientRect()?.top;
      // window.getSelection().getRangeAt(0).commonAncestorContainer?.getBoundingClientRect()?.top;
    if(cursorTop >= document.documentElement.clientHeight - 90 ){  // to scroll if user is typing in the last 3 lines of the window
      window.scrollBy({top: 90, behavior: 'smooth'}); // 30 charLimitBanner + 60 for two lines
    }
  }
}

export default  debounce(scrollDownIfNecessary, 150);