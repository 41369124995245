import React from "react";

import { colors, makeArrayFromLength, PaddingTopBottom15, SkeletonLoader, toArray, WrapperCardGrid } from "oolib";

import { Container } from "oolib";
import { sortBy } from "lodash";
import ErrorStates from "../../../ErrorStates";

const { greyColor5 } = colors;
export default function CompRenderer({
  useQueryResponse,
  render,
  returnPlainResponse,
}) {
  // const { status, data } = useQueryResponse

  // const everyStatusIs = (status) => {
  //   return toArray(useQueryResponse).every((d) => d.status === status);
  // };

  const someStatusIs = (status) => {
    return toArray(useQueryResponse).some((d) => d.status === status);
  };
  const foundError=(status)=>{
    return toArray(useQueryResponse).find((d)=>d.status===status)
  }
  

  switch (true) {
    case someStatusIs("loading"):
      return (
        <Container>
          <WrapperCardGrid>
            {makeArrayFromLength(4).map((d) => (
              <PaddingTopBottom15>
                <SkeletonLoader
                style={{
                  height:"10rem",
                  backgroundColor: greyColor5
                }}
                  
                />
              </PaddingTopBottom15>
            ))}
          </WrapperCardGrid>
        </Container>
      );
    case someStatusIs("error"):
      return <ErrorStates errorResponseObject={foundError("error").error}/>;
    /**
     * else, check to see what data is available against the successful calls, and
     * render that
     */
    default:
      if (returnPlainResponse) {
        // the reponse on search dashboard are paginated and follows the pages data structure of react query

        return render(useQueryResponse);
      }

      const consolidatedData = toArray(useQueryResponse)
        .map((d) => {
          return d.status === "success" ? d.data : [];
        })
        .reduce((a, b) => [...a, ...b], []);

      return render(
        sortBy(consolidatedData, (o) => o.main?.title?.blocks?o.main?.title?.blocks[0]?.text:undefined)
      );
  }
}
