import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import axios from "axios";

import { convertToRichText } from "../getters/gettersV2";
import { useGenErrorMsgBanners } from "../useGenErrorMsgBanners";
import { UIContent } from "../../UIContent";
import { useAppSettingsContext } from "../../contexts/appSettingsContext";
import { useBannerContext } from "oolib";

const getTagsV3 = (type, options = {}) => {
  let apiString, queryParams;

  if (options.tagCategory === "userTags") {
    apiString = "/api/users/getAllUsers";
    queryParams = {
      findQuery: JSON.stringify({
        profileType: type,
        ...(options.filters || {}),
      }),
      postDALFilter: JSON.stringify(options.postDALFilter || {}),
    };
  } else if (options.tagCategory === "contentTags") {
    apiString = "/api/discovery/getData";
    queryParams = {
      configs: JSON.stringify({
        contentTypes: [type],
        findQuery: {
          ...(options.filters || {}),
          kp_published_status: 'published'
        },
        activeLang: options.activeLang,
      }),
    };
  } else {
    apiString = `/api/v3/tags/${type}`;
    queryParams = {
      findQuery: JSON.stringify({
        ...(options.filters || {})
      })
    }
    
  }

  return axios.get(apiString, { params: queryParams }).then((res) => {
    return ["userTags", "contentTags"].includes(options.tagCategory)
      ? res.data.data
      : res.data;
  });
};

export const useGetTagsV3 = (type, options = {}) => {
  const { APP_SETTINGS } = useAppSettingsContext();
  const activeLang = APP_SETTINGS.lang.value;
  const {genErrorMsgBanners} = useGenErrorMsgBanners()
  //multiple
  const query = useQuery(
    ["tagsV3", type, options, activeLang],
    () => getTagsV3(type, { ...options, activeLang }),
    {
      enabled: options.enabled,
      onError: (err) => {
        genErrorMsgBanners({err})			
			},
    }
  );
  return query;
};

export const useMultipleGetTagsV3 = (tagTypes = [], options = {}) => {
  //tagTypes can come in as string or object
  let tagTypesData = tagTypes.every((d) => typeof d === "string")
    ? tagTypes.map((d) => ({ tagType: d }))
    : tagTypes;
  const multipleQueries = tagTypesData.map((d) => {
    return {
      queryKey: d.tagType,
      queryFn: () => getTagsV3(d.tagType, { ...options, ...(d.options || {}) }),
      enabled: options.enabled,
    };
  });

  return useQueries(multipleQueries);
};

export const useGetOneTagV3 = (type, id, options = {}) => {
  const { APP_SETTINGS } = useAppSettingsContext();
  const {SET_INFO_BANNER} = useBannerContext()
  const activeLang = APP_SETTINGS.lang.value;
  const {genErrorMsgBanners} = useGenErrorMsgBanners()
  //single
  let queryString =
    options.tagCategory === "userTags"
      ? `/api/users/getAllUsers?findQuery=${encodeURIComponent(
          JSON.stringify({ _id: id })
        )}`
      : options.tagCategory === "contentTags"
      ? `/api/content/viewContent/${type}/${id}`
      : `/api/v3/tags/${type}/${options.tagId}?populateTaggedResources=false`;
  const query = useQuery(
    ["tagsV3", type, id],
    () =>
      axios.get(queryString, { params: { activeLang } }).then((res) => {
        return options.tagCategory === "userTags"
          ? res.data.data[0]
          : options.tagCategory === "contentTags"
          ? res.data.contribution
          : res.data;
      }),
    { enabled: options.enabled,
    	onError: (err) => {
			
			  genErrorMsgBanners({err})	
			},
    
    }
  );
  return query;
};

export const useCreateTagV3 = () => {
  const queryClient = useQueryClient();
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation(
    ({ type, display, isUserGenerated, tagCategory }) => {
      if (tagCategory === "contentTags") {
        return axios.post(
          `/api/content/createAndPublishContent/${type}`,
          {
            main: { title: display },
            meta: { kp_content_type: type },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then(res => ({ data: res.data.content }));
      } else {
        return axios.post(
          `/api/v3/tags/${type}`,
          { display, isUserGenerated },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }
    },
    {
      onSuccess: (res, { type }) => {
        queryClient.invalidateQueries(["tagsV3", type]);
      },
      onError: (err) => {
        genErrorMsgBanners({ err });
      },
    }
  );
};

// this is the new create tag hook, since cata is using the old tags structure so once cata is done this function will replace the useCreateTagV3
export const useCreateTag = () =>
  useMutation({
    mutationFn: ({ tagCategory, tag }) =>
      axios.post(
        `/api/content/createAndPublishContent/${tagCategory}`,
        {
          main: { title: tag },
          meta: { kp_content_type: tagCategory },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    onError: (err) => console.log("tags error"),
    onSuccess: () => console.log("tags success"),
  });

export const useBatchRegisterTags = () => {
  // let dispatch = useDispatch();
  const { SET_ALERT_BANNER } = useBannerContext();
  const mutation = useMutation(
    ({ tagType, tagsData }) => {
      return axios.post(`/api/v3/tags/batchRegister/${tagType}`, tagsData, {
        headers: { "Content-Type": "application/json" },
      });
    },
    {
      onSuccess: () => {
        SET_ALERT_BANNER({
          color: "green",
          msg: "Tags registered successfully!",
          timeOut: 3000,
        });
      },
      onError: () =>
        SET_ALERT_BANNER({
          color: "red",
          msg: UIContent.general.serverErrorMsg,
          timeOut: 3000,
        }),
    }
  );

  return mutation;
};

export const useBatchRegisterMultipleTags = () => {
  // let dispatch = useDispatch();
  const { SET_ALERT_BANNER } = useBannerContext();
  const mutation = useMutation(
    ({ tagsData }) => {
      return axios.post(`/api/v3/tags/batchRegister/multipleTags`, tagsData, {
        headers: { "Content-Type": "application/json" },
      });
    },
    {
      onSuccess: () => {
        SET_ALERT_BANNER({
          color: "green",
          msg: "Multiple Tags registered successfully!",
          timeOut: 3000,
        });
      },
      onError: () =>
        SET_ALERT_BANNER({
          color: "red",
          msg: UIContent.general.serverErrorMsg,
          timeOut: 3000,
        }),
    }
  );

  return mutation;
};

export const useUpdateTagsOrderIndexes = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ tagType, data }) =>
      axios.patch(`/api/v3/tags/${tagType}/updateOrderIndexes`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: (res, { tagType }) => {
        queryClient.invalidateQueries(["tagsV3", tagType]);
      },
    }
  );
};

// might need at some point
//this will require for the activeFilters to be passed into the query key below
// const runConfigModifier = ({filterConfig, activeFilters}) => {
//   return filterConfig.map(f => {
//     if(!f.configModifier){
//       return f;
//     }else{

//       let { operator, findQueryKey, targetFilterId } = f.configModifier;
//       let targetActiveFilter = activeFilters.find(f => f.filterId === targetFilterId);
//       if(operator === 'FILTER_OPTIONS'){
//         return ({
//           ...f,
//           source: {
//             ...f.source,
//             findQuery: { [findQueryKey]: { $in: targetActiveFilter.values.map(d => d.value) } }
//           }
//         })
//       }
//     }
//   })
// }
