import { Loader } from 'oolib'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { __CheckSomeAccessToSomePlatformBuilderModule } from '../../../utils/accessControl'
import { useGetQueryState } from '../../../utils/react-query-hooks/general'

const RouteSomeAccessSomePlatformBuilderModule = ({
	component: Component,
	ACTIONS,
	location,
	modules,
	...rest
}) => {
	let { status, data: userData } = useGetQueryState('userData');

	

	const hasAccess = () => {
		
		if (!userData?.user) {
			// not logged in
			return {
				has: __CheckSomeAccessToSomePlatformBuilderModule({
					user:undefined, 
					actions: ACTIONS, 
					modules
				}),
				redirectTo: {
					pathname: '/login',
					state: {
						from: location.pathname + location.search,
					},
				},
			}
		}

		// else is logged in, and either has access to this content type or not.
		// if has, then let them through.
		// if not then simply redir back to the page they were coming from.
		return {
			has: __CheckSomeAccessToSomePlatformBuilderModule({
				user: userData?.user, 
				actions: ACTIONS, 
				modules
			}),
			redirectTo: '/',
		}
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				status === 'loading' ? (
					<Loader debug={'route access some content'}  />
				) : !hasAccess().has ? (
					<Redirect to={hasAccess().redirectTo} />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

export default RouteSomeAccessSomePlatformBuilderModule
