
import { queryClient } from "../../../../../..";
import { __CheckAccessToProfileType } from "../../../../../../utils/accessControl";

  //i.e  access CHECK | showListingPage CHECK
  export const getViewableProfileTypeOptions = () => {
    const {_ProfileTypes} = queryClient.getQueryData('platformConfigs')
    const {user} = queryClient.getQueryData('userData') || {}
    return _ProfileTypes
    .filter(
      (p) =>
        p.showListingPage !== false &&
        __CheckAccessToProfileType(user, "READ", p.id)
    )
    .map((p) => ({
      display: p.content?.title || p.id,
      value: p.id,
      to: `/community-listing/${p.id}`,
    }));
  }
  