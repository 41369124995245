import { OKELink } from 'oolib'
import React from 'react'




export const OKERichLink = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData()

  return (
    // <span contentEditable={false}> // this will allow the link to be clickable even in edit more. but it does cause some wierd behaviours of cursor disappearing which needs to be looked into
    <OKELink
      color={'black'} 
      link={url}
      >
      {props.children}
    </OKELink>
    // </span>
  )
}
