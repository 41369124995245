import {
  ButtonGhost, getBreakPoint, icons, SANS_3, useScreenWidth,
  DropdownSingle
} from "oolib";
import React from "react";
import { StyledSortWrapper } from './styled';




import { _Locale } from "../../locale/Locale";


const { SortDescending } = icons;

const Sort = ({
 sortConfig,
 activeSort,
 setActiveSort,
 popOutOfOverflowHiddenParent,
 alignDropdown
}) => {
  
  const screenWidth = useScreenWidth();
  
  return (
    <StyledSortWrapper id="okf_sort"> {/** Id is v. important for coachmark to work */}
    {
      <>
        {screenWidth >= getBreakPoint("md") && (
          <SANS_3
            semibold
            style={{ display: "flex", gap: "0.5rem" }}
          >
            <SortDescending size={20} />
           {_Locale("Sort")} 
          </SANS_3>
        )}
        <DropdownSingle
          optionsModalLabel= {"Sort"} 
          options={sortConfig}
          value={activeSort}
          onChange={(id, value) => setActiveSort(value)}
          className="sortDropdown"
          popOutOfOverflowHiddenParent={popOutOfOverflowHiddenParent}
          alignDropdown={alignDropdown}
          genCustomSelectComp={
            screenWidth < getBreakPoint("md") && (
              () => (
                <ButtonGhost
                  icon='SortDescending'
                  children={"Sort"} 
                  />
              )
            )}
        />
      </>
      //)
    }
  </StyledSortWrapper>
  );
};

export default Sort;
