import { isEmpty } from "lodash";
import { Accordion, ButtonGhost, ButtonPrimary, CheckboxInput, SANS_2, SANS_3, colors } from "oolib";
import React, { Fragment, useEffect, useState } from "react";

import { __GetTagTypeConfig } from "../../../../../utils/getters/gettersV2";




import { BadgeNumber } from "../../../../generalUI/BadgeNumber";
import { FilterInput } from "../../../../inputs/DynamicTagsInputs/FilterInput";
import {
  SideFilterHeaderStyled,
  SideFilterListStyled,
  SideFilterListWrapper,
  StyledAsideFilter,
  StyledAsideFilterContainer,
  StyledAsideFilterContentsWrapper,
  StyledFilterAccordionHeader,
  StyledOptionWrapper,
  StyledToggleButtonWrapper,
} from "./styled";


export const FilterAccordion = ({
  id,
  title,
  options,
  values,
  onChange: parentOnChange,
  children,
}) => {
  const [expand, setExpand] = useState(localStorage.getItem(id) || false);

  const handleOnClick = () => {
    setExpand((prev) => {
      return !prev;
    });
  };

  const handleOptionClick = (option) => {
    let isSelected = values.some((d) => d.value === option.value);
    if (!isSelected) {
      values.push(option);
    } else {
      values = values.filter((d) => d.value !== option.value);
    }

    parentOnChange({ id: title, filterType: "tagTypes" }, values);
  };

  return (
    <Accordion
      expand={expand}
      CustomHeader={
        <StyledFilterAccordionHeader onClick={handleOnClick}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <SANS_3 semibold>
              {__GetTagTypeConfig(title)?.content?.title || title}
            </SANS_3>
            {values && values.length ? <BadgeNumber number={values.length} /> : null}
          </div>
          <ButtonGhost icon={expand ? "Minus" : "Plus"} S />
        </StyledFilterAccordionHeader>
      }
      >
    <Fragment>
      {children}
      {options?.map((d) => (
        <StyledOptionWrapper
          key={d.value}
          onClick={() => {
            handleOptionClick(d);
          }}
        >
          <CheckboxInput option={d} value={values} />
        </StyledOptionWrapper>
      ))}
      </Fragment>
    </Accordion>
  );
};

const getAllSelectedCount = (object) => {
  if (isEmpty(object)) return 0;

  let count = 0;

  for (let i in object) {
    count += object[i].length;
  }

  return count;
};

export default function AsideFilter({
  onChange,
  selectedValues,
  updateActiveFilters,
  headerRef,
  activeFilters,
  filterConfig,
  presetActiveFilters,
  contentTypes,
  layout,
  height = undefined,
  expandedWidth : _expandedWidth
}){

  const expandedWidth = layout === "new" ? 300 : (_expandedWidth || 250);
  const [width, setWidth] = useState(expandedWidth);
  const [headerHeight, setHeaderHeight] = useState(height);
  
  useEffect(
    () =>{
      headerRef?.current &&
      setHeaderHeight(headerRef?.current.getBoundingClientRect().height)
    },
      
    []
  );

  const handleClearAllTags = () => {
    //empty all selected filter values
    updateActiveFilters(null, true);
  };

  const handleToggleSidebar = () => {
    setWidth((prev) => (prev === 0 ? 250 : 0));
  };

  const allCount = getAllSelectedCount(selectedValues);

  const generateFilters = (filterConfig) => {

    const genFilterInput = f => {
      const {filterId} = f
      return(
      <FilterInput                       
        key={filterId}
        comp='AccordionMultiSelect'                   
        value={selectedValues[filterId] || []}                       
        onChange={(_, value) => {
          onChange({
            id: filterId,                          
            value,
          });
        }}
        filterConfig={f}
        activeFilters={activeFilters}
        presetActiveFilters={presetActiveFilters}
        contentTypes={contentTypes}
        />
    )}

    return filterConfig.map((f, fI) => {
      if(f.configs){ //then we have filter sections
        return (<div key={f.sectionId} style={fI !== filterConfig.length - 1 ? { borderBottom: `1px solid ${colors.greyColor5}`} : {}}>
          {f.sectionTitle && <SANS_2 style={{
            paddingTop: fI !== 0 ? '1rem' : 0,
            paddingLeft: '2rem',
            paddingRight: '2rem',
            color: colors.greyColor50
            }}>{f.sectionTitle}</SANS_2>}
          {f.configs.map(ff => genFilterInput(ff))}
        </div>)
      }else{
        return genFilterInput(f)
      }
    })

  }
  return (
    <StyledAsideFilter top={headerHeight === 'auto' ? undefined : headerHeight}>
      <StyledAsideFilterContainer width={width}>
        <StyledAsideFilterContentsWrapper
          headerHeight={headerHeight}
          width={expandedWidth}
        >
          {
             (
              <Fragment>
                <SideFilterHeaderStyled style={{ justifyContent: expandedWidth === 300 && 'flex-end' }}>
                 {!expandedWidth === 300 && <SANS_3  semibold capitalize>
                    Filters
                  </SANS_3>}
                  <ButtonGhost onClick={handleClearAllTags}>
                    Clear All
                  </ButtonGhost>
                 
                </SideFilterHeaderStyled>
                <SideFilterListWrapper>
                  <SideFilterListStyled>
                    {generateFilters(filterConfig)}
                  </SideFilterListStyled>
                </SideFilterListWrapper>
              </Fragment>
            )
          }
        </StyledAsideFilterContentsWrapper>
        {layout !== "new" && <StyledToggleButtonWrapper>
          <ButtonPrimary onClick={handleToggleSidebar} icon="Faders" />{" "}
          {allCount !== 0 && (
            <div
              style={{ position: "absolute", top: "-1rem", right: "-.5rem" }}
            >
              <BadgeNumber number={allCount} />
            </div>
          )}
        </StyledToggleButtonWrapper>}
      </StyledAsideFilterContainer>
    </StyledAsideFilter>
  );
}
