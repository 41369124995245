import { TPL_TO_CELL_BLOCKS_LOOKUP } from "../TPL_TO_CELL_BLOCKS_LOOKUP";

export const transformTplBlocksToCellData = ({
  allTplBlockConfigs,
  isBackendSort,
  tableConfigProp,
}) => {
  return allTplBlockConfigs
    .map((block) => {
      let comp =
        TPL_TO_CELL_BLOCKS_LOOKUP({block, tableConfigProp})?.comp ||
        TPL_TO_CELL_BLOCKS_LOOKUP({block, tableConfigProp});
      if (!comp) return undefined;
      return {
        colId: block.valuePath,
        valuePath: block.valuePath,
        headerDisplay: extractHeaderDisplayFromBlockConfig(block),
        comp,
        props: {
          ...(block.props || {}),
          ...(TPL_TO_CELL_BLOCKS_LOOKUP({block, tableConfigProp})?.props || {}),
        },
        ...(isBackendSort
          ? { 
              backendSortConfig: TPL_TO_CELL_BLOCKS_LOOKUP({block, tableConfigProp})?.backendSortConfig || {} 
            }
          : {
              sortConfig: {
                fn: "alphabetical",
                path: block.valuePath, // this also wont work. we need valuepath to final string
              },
            }),
      };
    })
    .filter(Boolean);
};

//this is white parsing a tpl into table config
const extractHeaderDisplayFromBlockConfig = (block) =>
  block.props.label ||
  (block.valuePath === "main.title"
    ? "Title"
    : block.valuePath === "main.subtitle"
    ? "Subtitle"
    : block.valuePath);
