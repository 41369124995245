import { SANS_2, SANS_3, getText } from 'oolib';
import React from 'react';

import { formatters } from '../utils/formatters';

const TableText = ({
    value: _value, 
    tableRowData, 
    typoComp = 'SANS_3',
    suffix,
    formatter,
    ...typoCompProps
}) => {
    
    
    // ensures that if value doesnt exist or rich text data has not text, then dont return the comp
    // so the table elem defaults to showing a  '-'
    if(!_value || (_value.blocks && _value.blocks.length === 0) ) return null;

    let comps = { SANS_2, SANS_3 }

    let TYPO_COMP = comps[typoComp];

    

    const value = formatter
        ? formatters[formatter](getText(_value))
        : getText(_value)

    return (
        <TYPO_COMP {...(typoCompProps || {})}>
            {`${value}${suffix || ''}`}
        </TYPO_COMP>
    )

    
}

export default TableText;