import { $getSelection, $isRangeSelection } from "lexical";
import { customAlphabet } from "nanoid";

import {
    $wrapSelectionInMarkNode
} from "@lexical/mark";

export const handleCreateAnnotation = ({
    data,
    editor,
    handleSetAnnoData,
    targetSelection, //optional
    callback //optional
}) => {
    editor.update(() => {
        // sometimes $getSelection can be null. read why that is inside AnnoLightbox file
        const selection = targetSelection || $getSelection();

        if ($isRangeSelection(selection)) {
          const isBackward = selection.isBackward();
          const nanoidCustom = customAlphabet(
            "1234567890abcdefghijklmnopqrstuvwxyz",
            8
          );
          const dataId = nanoidCustom(); //create a new unique identifier for this anno data
          // Wrap content in a MarkNode
          $wrapSelectionInMarkNode(selection, isBackward, dataId);
          handleSetAnnoData({ type: "tags", data, dataId, disableParentOnChange: true });

          callback && callback()
        }
      });
}