import { motion } from "framer-motion";
import styled from "styled-components";
import { blurPage } from "../Shell/styled";
import { ButtonPrimary, mediaQuery } from "oolib"; 

export const StyledCoverSection = styled(motion.header)`
  /* position: ${({ isMobile }) => !isMobile && 'sticky'};
  top: 0; */
  background-color: white;
  z-index: 1000;
  ${blurPage}
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  /* width: fit-content; */
  & > *:first-child {
    grid-row:2;
  } 
  
  ${mediaQuery("md")} {
    /* width: unset; */
    align-items: center;
    grid-template-columns: 1fr 1fr;
    row-gap: 0;
    & > *:first-child {
      grid-row: unset;
    } 
  } 
`;


export const StyledMetaSection = styled.div`
  display: flex; 
  gap: 1rem; 
  flex-direction: column;
`;

export const StyledButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  /* align-self: center; */
  justify-self: start;

  ${mediaQuery("md")} {
    align-self: baseline; 
    justify-self: end;
  }

`;

export const StyledTPLActionsWrapper = styled.div`
   position: absolute; 
   top: 2rem; 
   right: 2rem; 
   z-index: 3000; 

`