import { css } from "styled-components";

const putInEmptySide =(elmCoords, arrowDegree)=> {
  const leftSideSpace = elmCoords.left;
  const rightSideSpace = document.documentElement.clientWidth - elmCoords.right;
  //30px marginal factor (10% of the narrowset phone 'iphone SE')
  if (leftSideSpace > rightSideSpace + 30) {
    return css`
      right: ${(document.documentElement.clientWidth - elmCoords.right) + elmCoords.width / 4}px; // 4 : start at the quarter of the element
    `;
  } else if (rightSideSpace > leftSideSpace + 30) {
    return css`left: ${elmCoords.left + elmCoords.width / 4}px;`;
  } else {
    // the target is centered or not to the left nor right by that much
    return css`
      left: ${elmCoords.left + elmCoords.width / 2}px;
      transform: translateX(-50%) ${arrowDegree? `rotate(${arrowDegree}deg)`: ''};
    `;//center the coachmark too
  }
}

const position2ndArg = (elmCoords, pos)=> {
  switch (pos) {
    case 'left':
      return css`left: ${elmCoords.left}px;`
    case 'right':
      return css`right: ${document.documentElement.clientWidth - elmCoords.right}px;`
    case 'top': 
      return  css`top: ${elmCoords.top}px;`
    case 'bottom':
      return css`bottom: ${document.documentElement.clientHeight - elmCoords.bottom}px;`
    default:
      return;  
  }
}

export const positionMark =(targetElmCoords, markPos, arrowDegree)=> {
  const possiblePoses = ['left', 'right', 'top',  'bottom']

  const arrowSpace = arrowDegree !== undefined? -13 : 10; //this function is called for arrow || coachmark, arrow width: 13px, leaving 10px space on the side for it
  const elmVerticalMiddle = targetElmCoords.top + targetElmCoords.height / 2 - 13;//-----------------------------↑

  const use2ndArg = markPos[1] && possiblePoses.includes(markPos[1]);

  switch (markPos[0]){
    case 'left':
      return css`
        right: ${(document.documentElement.clientWidth - targetElmCoords.right) + targetElmCoords.width + arrowSpace}px;
        ${use2ndArg? position2ndArg(targetElmCoords, markPos[1]): `top: ${elmVerticalMiddle}px`}
      `;
    case 'right':
      return css`
        left: ${targetElmCoords.right + arrowSpace}px;
        ${use2ndArg? position2ndArg(targetElmCoords, markPos[1]): `top: ${elmVerticalMiddle}px`}
    `;
    case 'top':
      return css`
        bottom: ${(document.documentElement.clientHeight - targetElmCoords.bottom) + targetElmCoords.height + arrowSpace}px;////////////////////// -100%???
        ${use2ndArg? position2ndArg(targetElmCoords, markPos[1]):putInEmptySide(targetElmCoords, arrowDegree)}
    `;
    case 'bottom':
      return css`
        top: ${targetElmCoords.bottom + arrowSpace}px;
        ${use2ndArg? position2ndArg(targetElmCoords, markPos[1]):putInEmptySide(targetElmCoords, arrowDegree)}
      `;
    default: 
      return css`position: relative`; // in case of a wrong position argument, re-center
  }
}