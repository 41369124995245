import { RichUtils, Modifier, EditorState } from "draft-js";
import { highlightPreviousBlocks } from "../utils";

const {toggleBlockType, getCurrentBlockType} = RichUtils;

const handleKeyCommand =({ command, editorState, handleChange })=> {
  ///////// if this function returns 'handled' or true this prevents any further progression of events ////

  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  
  if (command === 'split-block' && selectionState.isCollapsed()) { // stop heading styling on new line
    const currentBlockType = getCurrentBlockType(editorState);

    if (currentBlockType.startsWith('header')){
      const newContentState = Modifier.splitBlock(contentState, selectionState);
      let newEditorSate = EditorState.push(editorState, newContentState, 'split-block');
      newEditorSate = toggleBlockType(newEditorSate,'unstyled');
      handleChange(newEditorSate);
      return 'handled';
    }
  }

  if (
    command === 'backspace' && // if we try to delete an atomic block
    selectionState.isCollapsed() &&
    selectionState.getAnchorOffset() === 0 //from the block following it
  ){
      const currentBlockKey = selectionState.getStartKey();
      const previousBlock = contentState.getBlockBefore(currentBlockKey)

      if (previousBlock?.getType() === 'atomic'){
        highlightPreviousBlocks({
          blocksCount: 2, //this must be 2, otherwise --> error;draftjs inserts a new empty line with every atomic block.
          startKey: previousBlock.getKey(),
          selectionState,
          editorState,
          handleChange,
          contentState,
        }) //highlight but don't delete.. deletion will happen with the next backspace, since isCollapsed() above == false
        return 'handled'
      }
  }

  const modifiedEditorState = RichUtils.handleKeyCommand(editorState, command);

  if (modifiedEditorState){ // has a value if the command passed is recognized by draftjs 
    handleChange(modifiedEditorState);
    return 'handled'
  }
  return 'not-handled'
}

export default handleKeyCommand;