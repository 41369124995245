import React, { createContext, useReducer, useContext, useEffect } from 'react'
import { io } from "socket.io-client";
import { getTenant } from '../../TENANT_CONFIGS';
import * as types from './types'
const socket = process.env.REACT_APP_ENV === 'dev' && io.connect(
  process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_SERVER_URL // this needs to be the backend server url 
  : 'http://localhost:8080', 
  {
     extraHeaders: {
       "tenant": getTenant()
     }
  }
  )


export const SocketContext = createContext()



export const SocketContextProvider = ({ children }) => {

  


  return (
    <SocketContext.Provider
      value={{
        socket,
        ...types
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

export const useSocketContext = () => {
  return useContext(SocketContext)
}
