import styled from "styled-components";
import {colors} from 'oolib'
const {greyColor15}  = colors

export const NotificationBoxStyled = styled.div`
  position: absolute;
  height: 45rem;
  width: 35rem;
  background-color: white;
  right: -2rem;
  top: 6rem;
  z-index: 1000;

  /* padding: 0 1rem; */
  overflow: auto;
  border-radius: 5px 0px 0px 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
`;


export const NotificationBoxTitleStyled = styled.div`
  padding: 1.5rem 3rem;
  /* border-bottom: 1px solid ${greyColor15}; */
`;


export const NotificationBoxHeaderStyled = styled.div`
  position: sticky;
  background-color: white;
  top: 0;
`;
