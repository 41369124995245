
export const config = {
    icon: "LetterH",
    options: [
        {
         
            tag: 'h1',
            icon: "TextHOne",
        },
        {
         
            tag: 'h2',
            icon: "TextHTwo",
        },
        {
         
            tag: 'h3',
            icon: "TextHThree",
        }
    ]
}       
  
