
import { applyListShortcut, autoCapitalize } from '../utils';

const handleBeforeInput = ({
  char,
  editorState,
  handleChange,
  charLimit,
  editorVariant
}) => {
  // if it returns handled it means : I handled it, don't progress and call onChange
  const plainTextInEditor = editorState.getCurrentContent().getPlainText()
  
  if (charLimit) {
    if (plainTextInEditor.length + 1 > charLimit) return 'handled';
  }

  const selectionState = editorState.getSelection();
  const currentBlockKey = selectionState.getStartKey(); // will get our target block even in case of selecting then overwriting
  const activeBlock = editorState.getCurrentContent().getBlockForKey(currentBlockKey);
  

  // autocapitalizing the first letter of each block
  let isFirstCharacter;
  if (selectionState.isCollapsed()) {
    isFirstCharacter = activeBlock.getLength() === 0;
  } else {
    isFirstCharacter = selectionState[selectionState.getIsBackward()? "getFocusOffset": "getAnchorOffset"]() === 0; // in case of selecting and overwriting
  }

  if (isFirstCharacter) {
    autoCapitalize({char, editorState, handleChange, selectionState});
    return 'handled';
  }

  if(editorVariant === 'rich'){
    //lists addition keyboard shortcuts
    const blockText = activeBlock.getText()
    if (/^(\d[\.-]|[-*])$/.test(blockText) && char.charCodeAt(0) === 32){
      applyListShortcut({blockText, editorState, handleChange, selectionState});
      return 'handled';
    }
  }
  
  return 'not-handled';
}

export default handleBeforeInput;