
import { mediaQuery, SANS_4_5 } from "oolib";
import React, { Fragment } from "react";
import styled from "styled-components";

const StyledFallbackText = styled(SANS_4_5)`
text-align: center;
width: 100%;
    ${mediaQuery('sm')}{    
        text-align: left;
    }
`


export const FooterLogos = ({config}) => {

    const {deployment: {_Logos}} = config;

    const {
        footerLogos,
        logoFooter, //deprecated
        logo2Footer, //deprecated
        fallbackText
    } = _Logos

    let logosAry = (
        footerLogos ||
        [ //backwards compatibility
        logoFooter ? {publicUrl: logoFooter} : undefined,
        logo2Footer ?  {
            publicUrl: logo2Footer.src || logo2Footer,
            height: logo2Footer.height
        } : undefined
        ].filter(Boolean)
    )
        
    

    return (
        <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "4rem",
              flexWrap:'wrap',gap:"2rem"
            }}
          >
            {
                logosAry.length === 0
                ? <StyledFallbackText invert>{fallbackText}</StyledFallbackText>
                : logosAry.map((
                    d,
                    idx
                ) => {
                    const {publicUrl, height, altText, marginLeft, link} = d || {}
                    const Wrapper = link ? 'a' : Fragment;
                    const wrapperProps = link ? { href: link, target: '_blank' } : {}
                    return (
                        <Wrapper key={idx} {...wrapperProps}>
                        <img
                            key={publicUrl}
                            src={publicUrl}
                            style={{ 
                                height: height || '4rem',
                                marginLeft: idx === 0 ? 0 : (marginLeft || '0.5rem')
                            }}
                            alt={altText}
                            />   
                        </Wrapper>       
                    )
                })
            }
          </div>
    )
}