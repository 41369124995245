import { getVal } from "oolib";

export const handleInsertInOtherDraggableInstance = ({
  thisDraggableInstanceId,
  dragState,
  handleDragOver,
  ary,
  idValPath,
  resetDragState,
  handleReposition,
}) => {
  const {
    isWhere,
    draggedOn_draggableInstanceId,
    draggedOnElemId,
    dragElemData,
  } = dragState;

  if (thisDraggableInstanceId !== draggedOn_draggableInstanceId) {
    return;
  }
  //else
  let newAry = [...ary];
  let draggedOnElemIdx = newAry.findIndex(
    (d) => getVal(d, idValPath) === draggedOnElemId
  );

  let newIdx = isWhere === "above" ? draggedOnElemIdx : draggedOnElemIdx + 1;

  newAry.splice(newIdx, 0, dragElemData);

  handleDragOver.cancel();
  resetDragState(); //reset

  handleReposition(newAry);
};
