import { RichUtils } from "draft-js";

const { toggleInlineStyle, toggleBlockType } = RichUtils;

export const toggleTextFormat =({editorState, handleChange, scope, style})=> {
  if (scope === 'block') {
    handleChange(toggleBlockType(editorState, style));
  } else {
    handleChange(toggleInlineStyle(editorState, style));
  }
}

