import React, { useEffect, useState, useRef } from "react";

import {
  EditorState,
  CompositeDecorator,
  convertToRaw,
  convertFromRaw,
  ContentState,
  Modifier,
  convertFromHTML,
} from "draft-js";

// import editOnCopy from "draft-js/lib/editOnCopy";
import editOnCut from "draft-js/lib/editOnCut";

// import { debounce } from "lodash";
//COMPONENTS
import { colors, BlockLabel, getBlockLabelProps, useBannerContext } from "oolib";
//general UI

import KPLinkInput from './comps/KPLinkInput'

import KPRichEditor from './comps/KPRichEditor'
import KPRichToolbar from './comps/KPRichToolbar'
import KPRichInlineToolbar from './comps/KPRichInlineToolbar'

import { OKERichLink } from '../../generalUI/OKERichLink'


//utils
import {
  useTrackEditorFocus,
  createAtomicBlockEntity,
  renderAtomicBlock,
  setClassNamesToBlockTypes,
  handleKeyCommand,
  hideShowInlineToolbar,
  validateCharLimit,
  enforceFixedBlockType,
  handleInsertCTA,
} from "../../../utils/richInputUtils";

import { handleUploadChange } from "../../../utils/richInputUtils";
import { insertLink } from "../../../utils/richInlineLinkUtils";

//sass var exports

import { _Locale } from "../../locale/Locale";
import * as ANNO from "./annotation";
import {
  isEndToEndSelection,
  // getEditReadyPayload,
  retainAnnoChecker,
  // _consolidateAnnos,
  // removeAnnotation,
  // editAnnotation,
} from "./annotation/functions/helpers";

import { forceSelection } from "draft-js/lib/EditorState";
import AnnotationGUI from "./annotation/comps/GUIs";
import { useAppSettingsContext } from "../../../contexts/appSettingsContext";
import KPResourceSelectPopUp from "./comps/KPResourceSelectPopUp";

var changeTriggeredByEditorBlur = false;

const { greyColor100 } = colors;

// let count = 0;

const handleOnChange = (newEditorState, onChange, id, options = {}) => {
  let contentStateRaw = convertToRaw(newEditorState.getCurrentContent());

  /**
   * very critical that it is done this way.
   * the lowest return value of consolidated annos is {} which we need if all annos are removed from RAW
   * and we need to wipe the synth data clean (research notes)
   *
   * however, if there is another rich editor in the template
   * that also would return a {}, that will also wipe annos clean in synth
   * WE DONT WANT THAT.
   *
   * hence we run consolidateAnnos only if props.annotation.enable is true
   */
  // const consolidatedAnnotations = options.enableAnnotation
  //   ? { tags : _consolidateAnnos(newEditorState) }
  //   : undefined

  
  


  onChange &&
    onChange(
      id,
      contentStateRaw /*, { annotations: consolidatedAnnotations } */
    );
};

const KPRichInput = (props) => {
  /**
   * annotation hook
   */

  const {
    showAnoBtn,
    forceReadOnly,
    setForceReadOnly,
    editorBoundsRef,
    // showAnoLightbox,
    // setShowAnoLightbox,
  } = ANNO.useHandleAnnoSelection({ annotation: props.annotation });

  // let showAnoButton = showAnoBtn

  //------------------------------------------------Annotation states-----------------------------------------------
  const [annoData, setAnnoData] = useState();
  const [showAnnoGUI, setShowAnnoGUI] = useState({ position: {}, show: false });

  const isEndToEndRes = useRef();
  // const hackRef = useRef();

  // useEffect(()=>{
  //   hackRef.current  = showAnnoGUI
  // },[showAnnoGUI])

  // console.log({rerenderCount:++count})

  //#1 : init refs for editor & editor-wrapper. wrapper ref helps us track focus change. editor ref literally helps us control focus change.
  const editorRef = useRef(null);
  const wrapperRef = useRef(null);
  const inlineToolbarRef = useRef(null);

  const [retainAnnoOnPaste, setRetainAnnoOnPaste] = useState(false);
  const [tempReadOnly, setTempReadOnly] = useState(false); //for when the editor's child comp is focussed
  const [showCardLinkInput, setShowCardLinkInput] = useState(false);
  const [showCharLimitAlert, setShowCharLimitAlert] = useState(false);
  const [inlineToolbar, setInlineToolbar] = useState({
    show: false,
    position: {},
  });

  const [cursorToEnd, setCursorToEnd] = useState(0);

  const handleCloseCardLinkInput = () => setShowCardLinkInput(false);
  const handleSetTempReadOnly = (boolean) => setTempReadOnly(boolean);
  const handleCloseLinkInput = () =>
    setTextLinkState({ ...textLinkState, showLinkInput: false });

  const { SET_ALERT_BANNER } = useBannerContext();

  //temp hack to push cursor to end after link is inserted
  // useEffect(() => { setEditorState(EditorState.moveFocusToEnd(editorState)) }, [cursorToEnd]);

  const embedCardLinks = (val) => {
    createAtomicBlockEntity(
      { editorState, onEditorChange },
      "KPCardLinksDisplay",
      "IMMUTABLE",
      {
        value: val,
        contentTypeEmbedOptions: props.contentTypeEmbedOptions,
        profileTypeEmbedOptions: props.profileTypeEmbedOptions,
      }
    );
    setShowCardLinkInput(false);
  };

  const [editorFocussed, textLinkState, setTextLinkState] =
    useTrackEditorFocus(wrapperRef, props.trackEditorFocus);

  const [linkInputOnConfirmHandler, setLinkInputOnConfirmHandler] =
    useState("insertLink");

  //-------------- START SET INIT EDITOR STATE
  //draftjs basic data structure:
  /*{
    block : [
      ...
    ],
    entityMap : {}
  }*/

  const onAnnoClick = (data) => {
    setAnnoData(data.data);
    setShowAnnoGUI({ show: true, position: data.position });
  };

  useEffect(() => {
    if (showAnnoGUI.show) return;

    if (!annoData?.targetFragmentKey) return;
    const elems = document.getElementsByClassName(annoData.targetFragmentKey);

    for (let d of elems) {
      d.classList.remove("-active-");
    }
  }, [showAnnoGUI.show]);

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: (thisProps) => (
        <OKERichLink {...thisProps} invert={props.invert} />
      ),
    },
    ANNO.genAnnoDecorator({ ...props, onAnnoClick,wrapperRef, }),
  ]);

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator) //we cant createWithContent here, because if we have a LLLOT of content, then the convertFromRaw fires everytime on each render which is expensive.
  );


  const [firstUpdateHappened, setFirstUpdateHappened] = useState(false);

  //-------------- END SET INIT EDITOR STATE

  //-------------- START HANDLING EDITOR CHANGE

  // useImperativeHandle(ref, () => ({
  //   onEditorChange: (key, editorState) => onEditorChange(key, editorState)
  // }));

  const onEditorChange = (key, editorState, options = {}) => {
    if (!changeTriggeredByEditorBlur) {
      changeTriggeredByEditorBlur = false;

      let newEditorState = editorState;

      //-------------------------------------------------------------------//
      //NOTE: THIS WAS FOR CONVERTING TYPED TEXT INTO LINKS WHEN NECESSARY. VERY EXPENSIVE OPERATION. GOTTA BE A BETTER WAY TO DO THIS.

      // let editorStateWithLink = convertUrlStringsToInlineLinks(
      //                             {editorState, setEditorState},
      //                             setTextLinkState,
      //                             setCursorToEnd,
      //                             cursorToEnd
      //                           );

      // // this returns only if link is inserted so...
      // newEditorState = editorStateWithLink !== 'unchanged' ? editorStateWithLink : newEditorState;

      //-------------------------------------------------------------------//

      if (!!props.charLimit === true) {
        newEditorState = validateCharLimit(
          editorState,
          { showCharLimitAlert, setShowCharLimitAlert },
          { cursorToEnd, setCursorToEnd },
          props.charLimit
        );
      }

      setEditorState(newEditorState);

      setTimeout(
        () =>
          handleOnChange(newEditorState, props.onChange, props.id),
        0
      );
    } else {
      changeTriggeredByEditorBlur = false; //reset it.
    }
  };

  useEffect(() => {
    if (props.forceFocus === true) {
      //used only in comments. but this can probably be removed once we implement new 'controlled' structure
      editorRef.current.focus();
    }
  }, [props.forceFocus]);

  useEffect(() => {
    /**
     * this forceValue business is a temp hack, primarily to make the
     * title update on newsreports work on veditum.
     * 
     * this is extremely hacky however, and in the new RTE we must find another way,
     * 
     * because this value based useEffect causes all sorts of other bugs.. randomly removing a bit of annotation for example
     */
    if (props.value && props.forceValue === true) {
      if (!props.value.entityMap) props.value.entityMap = {}

      const oldSelectionState = editorState.getSelection();
      let newEditorState = EditorState.push(
        editorState,
        convertFromRaw(props.value),
        "change-block-data"
      );

      /**
       * for reasons i dont understand, sometimes, the focus offset gets deselected, unless this line is here.
       */
      if (
        oldSelectionState.getAnchorOffset() !== 0 ||
        oldSelectionState.getFocusOffset() !== 0
      ) {
        newEditorState = forceSelection(newEditorState, oldSelectionState);
      }

      setEditorState(newEditorState);
    }
  }, [props.value]);

  //side effects when editorState changes
  useEffect(() => {
    // --- enforce fixed block type if prop says so
    !props.readOnly &&
      props.fixedBlockType &&
      firstUpdateHappened &&
      enforceFixedBlockType(editorState, onEditorChange, props.fixedBlockType);
    // ^^^ these 10,000 useeffects are causing a lot of problems. Cant predict which one fires when, so data updates aren't reflecting in other use effects. for example, in readonly mode, the editorState update that happens in componentMount useffect, doesnt reflect here.

    // --- hide / show inline toolbar
    !props.readOnly &&
      (props.inlineFormattable ||
        props.richFormattable ||
        props.superRichFormattable ||
        ["textFormatting", "textAndImageFormatting"].indexOf(
          props.formattingOptions
        ) !== -1) &&
      hideShowInlineToolbar(setInlineToolbar, wrapperRef);

    // --- setting custom placeholder
    props.getEditorState && props.getEditorState(editorState);

    //-------------------------------------------------------------------//
    //NOTE: THIS WAS FOR CUSTOM PLACEHOLDER. VERY EXPENSIVE OPERATION

    //   let contentStateRaw = convertToRaw(editorState.getCurrentContent());
    // ( contentStateRaw.blocks.length > 1 &&
    //   richTextHasValue(contentStateRaw) === false  )
    //   ? setShowCustomPlaceholder(true)
    //   : setShowCustomPlaceholder(false)

    //-------------------------------------------------------------------//
  }, [editorState]);

  const {APP_SETTINGS} = useAppSettingsContext()

  useEffect(() => {
    let initEditorValue = props.value; //parent value
    let newEditorState;
    if (initEditorValue) {
      if (!initEditorValue.entityMap) initEditorValue.entityMap = {}; //for cases when data fetched from the DB doesnt already have the entitymap obj
      // let newEditorState = EditorState.set(editorState, { currentContent: convertFromRaw(initEditorValue) });
      newEditorState = EditorState.push(
        editorState,
        convertFromRaw(initEditorValue),
        ""
      );
      setEditorState(newEditorState);
      setFirstUpdateHappened(true);

      //for now it is important to repeat this here as well ( along with the editorState useEffect );
      //because on first load, the editorState useEffect fires, the editorState is empty, so the fixedBlockEnforcing erases whatever value that may be being fed into the editor.
      //to sort that out, we ensure in this useEffect ( which fires only on first load ) that the props.value is indeed passed into the editor while the fixedBlockType is being enforced
      !props.readOnly &&
        props.fixedBlockType &&
        enforceFixedBlockType(
          newEditorState,
          onEditorChange,
          props.fixedBlockType
        );
    } else {
      !props.readOnly &&
        props.fixedBlockType &&
        enforceFixedBlockType(
          editorState,
          onEditorChange,
          props.fixedBlockType
        );
    }
  }, [APP_SETTINGS.lang.value]);

  const [showCustomPlaceholder,
    //  setShowCustomPlaceholder
  ] = useState(false);  

  // if (!editorState.getSelection().isCollapsed() && inlineToolbar.show) {
  //   //return value {targetFragmentKey:'',data:tags}
  //   isEndToEndRes.current = isEndToEndSelection(editorState);
  // }

  if (window.getSelection().anchorOffset !== window.getSelection().focusOffset&& inlineToolbar.show) {
    //return value {targetFragmentKey:'',data:tags}
    isEndToEndRes.current = isEndToEndSelection(editorState);
  }

  const handleEditorBlur = () => {
    changeTriggeredByEditorBlur = true;

    setInlineToolbar({ show: false, position: {} });

    //-------------------------------------------------------------------//
    // ACTIVATE THIS IF USING DEBOUNCE > READ INLINE COMMENT
    // handleOnChange(editorState, props.onChange, props.id); //now, when you deselect the editor realllly quickly without giving time for the debounceOnChange to run (in editorOnChange), (this can happen if you write something and really quickly hit 'tab'), then the debounce doesn't run, and that stuff that you really quickly typed. doesnt get sent to the parent. which is why we have added this line. maybe there is a more elegant solution, but for later..
    //-------------------------------------------------------------------//

    //-------------------------------------------------------------------//
    //NOTE: THIS WAS FOR CUSTOM PLACEHOLDER. VERY EXPENSIVE OPERATION

    //   let contentStateRaw = convertToRaw(editorState.getCurrentContent());
    // ( contentStateRaw.blocks.length > 1 &&
    //   richTextHasValue(contentStateRaw) === false )
    //   ? setShowCustomPlaceholder(true)
    //   : setShowCustomPlaceholder(false)

    //-------------------------------------------------------------------//
  };

  //-------------- END HANDLING EDITOR CHANGE

  const handleDroppedFiles = (selection, files) => {
    if (files) {
      files.map((file) => {
        handleUploadChange(
          { target: { files } },
          "KPRichInlineImage",
          `images`,
          ["jpg", "jpeg", "png"],
          { editorState, onEditorChange },
          SET_ALERT_BANNER
        );
      });
    }
    return "handled";
  };

  // --------------------------------------------------------------------------------------------------------------------

  const handleCut = (editor, e) => {
    const res = retainAnnoChecker(editor._latestEditorState);

    editOnCut(editor, e);

    // only if , entity type = ANNO
    if (!res) {
      editor.setClipboard(null);
    }
    setRetainAnnoOnPaste(true);
  };

  const closeAnnotation = () => {
    setShowAnnoGUI({show:false,position:{}});
    setAnnoData(null);
  };
  const handleOnCopy = () => {};

  const handlePaste = (text, html, editorState) => {
    if (!retainAnnoOnPaste && html) {
      const blocksFromHTML = convertFromHTML(html);

      const pastedTextContentState = ContentState.createFromBlockArray(
        //returns content state
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const fragment = pastedTextContentState.getBlockMap();

      const newContentState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        fragment
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-fragment"
      );
      onEditorChange(null, newEditorState);
      return true;
    } else {
      setRetainAnnoOnPaste(false);
    }

    return false;
  };

  const annoGUIConfig = {
    comp: "LightBox",
    config: {
      tagTypesConfig: props.annotation?.tagTypesConfig,
      toggleTagTypesConfig: props.annotation?.toggleTagTypesConfig,
    },
  };

  // --------------------------------------------------------------------------------------------------------------

  const richEditor = (
    <KPRichEditor
      id={props.id}
      editorFocussed={editorFocussed}
      readOnly={
        forceReadOnly !== undefined
          ? forceReadOnly
          : props.readOnly === false
          ? tempReadOnly
          : props.readOnly
      }
      blockRendererFn={(block) =>
        renderAtomicBlock(
          block,
          { editorRef, editorState, onEditorChange },
          handleSetTempReadOnly,
          props.readOnly
        )
      }
      blockStyleFn={setClassNamesToBlockTypes}
      editorState={editorState}
      handleKeyCommand={
        !props.readOnly &&
        props.richFormattable &&
        ((command, editorState) =>
          handleKeyCommand(command, editorState, onEditorChange))
      }
      handleDroppedFiles={(selection, files) =>
        handleDroppedFiles(selection, files)
      }
      onChange={(editorState) => onEditorChange("", editorState)}
      onBlur={handleEditorBlur}
      ref={editorRef}
      placeholder={_Locale(props.placeholder)}
      className={`${props.invert ? "-invert-" : ""} ${
        props.textAlign === "center" ? "-text-align-center-" : ""
      }`}
      typeStyle={props.typeStyle}
      handleOnCopy={handleOnCopy}
      handlePaste={handlePaste}
      handleCut={handleCut}
    />
  );

  //#8 : finally render the html
  return (
    <div
      className={`kp-rich-editor-wrapper ${props.className} ${
        props.fixedBlockType ? props.fixedBlockType + "-fixed-editor" : ""
      }`}
    >
      <div className="kp-rich-editor__label-and-custom-placeholder-wrapper">
        <BlockLabel
          {...getBlockLabelProps(props)}
          errorMsgs={
            props.charLimit && showCharLimitAlert
              ? ["Character Limit Reached!"]
              : getBlockLabelProps(props)?.errorMsgs
          }
        />
        {showCustomPlaceholder && (
          <h4 className="serif h4 medium kp-rich-input__custom-placeholder">
            {_Locale(props.placeholder)}
          </h4>
        )}
      </div>
      <div
        ref={wrapperRef}
        id={props.id}
        className={`kp-editor-and-toolbar-wrapper ${
          props.listStyleImage ? props.listStyleImage : ""
        }`}
        style={{ position: "relative" }}
      >
        <div
          ref={inlineToolbarRef}
          id="inline_toolbar"
          className="kp-inline-toolbar"
          style={{
            top: inlineToolbar.position.y,
            left: inlineToolbar.position.x,
            display: inlineToolbar.show ? "block" : "none",
            borderRadius: "0.4rem",
            padding: "0 1rem",
            backgroundColor: greyColor100,
            zIndex: 10,
          }}
          onMouseDown={(ev) => ev.preventDefault()}
        >
          <KPRichInlineToolbar
            editor={{
              editorId: props.id,
              editorRef,
              editorState,
              onEditorChange,
            }}
            formattingOptions={props.formattingOptions}
            setTextLinkState={setTextLinkState}
            setInlineToolbar={setInlineToolbar}
            inlineToolbar={inlineToolbar}
            showAnoBtn={showAnoBtn}
            setShowAnnoGUI={setShowAnnoGUI}
          />
        </div>
        {props.annotation?.enable && showAnnoGUI.show ? (
          <AnnotationGUI
            onCreate={(data) =>
              ANNO.insertAnno(
                {
                  value: { tags: data.data },
                  editorState,
                  onEditorChange,
                  setCursorToEnd,
                  cursorToEnd,
                  setForceReadOnly,
                  setShowAnnoGUI,
                },
                closeAnnotation
              )
            }
            onRemove={(data) =>
              ANNO.removeAnnotation(
                {
                  editorState,
                  onEditorChange,
                  ...data,
                },
                closeAnnotation
              )
            }
            onEdit={(data) =>
              ANNO.editAnnotation(
                {
                  editorState,
                  onEditorChange,
                  ...data,
                },
                closeAnnotation
              )
            }
            onCancel={closeAnnotation}
            specialProps={{ isEndToEndRes: isEndToEndRes.current }}
            data={annoData}
            GUI={annoGUIConfig}
            position={showAnnoGUI?.position}
            editorWrapperRef={wrapperRef}
          />
        ) : null}

        <div ref={editorBoundsRef}>{richEditor}</div>
        {!props.readOnly && (props.hints || props.richFormattable) && (
          <div
            className="kp-rich-format-toolbar__hints-and-toolbar-wrapper"
            // style={{ height: props.hints && editorFocussed ? "5rem" : "auto" }}
          >

            {!props.readOnly &&
              props.richFormattable && ( //placing this outside the wrapper div, makes it disappear when we click on it. need to revisit the useTrackEditorFocus function to fix this
                <KPRichToolbar
                  editorFocussed={editorFocussed}
                  editor={{
                    editorId: props.id,
                    editorRef,
                    editorState,
                    setEditorState,
                  }}
                  onEditorChange={onEditorChange}
                  textLinkState={textLinkState}
                  setTextLinkState={setTextLinkState}
                  handleCloseLinkInput={handleCloseLinkInput}
                  handleShowCardLinkInput={() => setShowCardLinkInput(true)}
                  setLinkInputOnConfirmHandler={setLinkInputOnConfirmHandler}
                  superRichFormattable={props.superRichFormattable}
                  formattingOptions={props.formattingOptions}
                  hintsExists={!!props.hints}
                />
              )}
          </div>
        )}

        {textLinkState.showLinkInput === true && (
          <KPLinkInput
            id="inline_link_input"
            textInputProps={{
              id: "inline_link_input_external",
              value: textLinkState.value,
              /*onChange : (key, val) => updateTextLinkState(key, val),*/
              placeholder: "write/paste your link here",
            }}
            onConfirm={(key, val, type, displayText) => {
              linkInputOnConfirmHandler === "handleInsertCTA"
                ? handleInsertCTA(
                    "ButtonSecondary",
                    val,
                    type,
                    displayText,
                    { editorState, setEditorState, onEditorChange },
                    setTextLinkState,
                    setLinkInputOnConfirmHandler
                  )
                : insertLink(
                    key,
                    val,
                    type,
                    { editorState, setEditorState, onEditorChange },
                    setTextLinkState,
                    setCursorToEnd,
                    cursorToEnd
                  );
            }}
            linkType={
              linkInputOnConfirmHandler === "handleInsertCTA"
                ? "button"
                : "link"
            }
            //onConfirm for cta will fire
            onClose={handleCloseLinkInput}
          />
        )}

        {showCardLinkInput === true && (
          <KPResourceSelectPopUp
            onConfirm={(val) => embedCardLinks(val)}
            onCloseModal={handleCloseCardLinkInput}
            contentTypeEmbedOptions={props.contentTypeEmbedOptions}
            profileTypeEmbedOptions={props.profileTypeEmbedOptions}
          />
        )}
      </div>
    </div>
  );
};

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

KPRichInput.defaultProps = {
  id: "generic_rich_input", //this is Very Very important to have. If multiple editors in a page. IDs should be different.
  //label is optional
  //sublabel is optional
  richFormattable: true,
  readOnly: false,
  trackEditorFocus: true,
};

export default React.memo(KPRichInput);
