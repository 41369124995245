import { ModalSmall, useBannerContext } from "oolib";
import React, { useState } from "react";
import TagsInputSingle from "../components/inputs/DynamicTagsInputs/TagsInputSingle";
import { useQuickUpdateContent } from "../pageTpls/ContributeTpl/queryHooks/useQuickUpdateContent";
import { plugins_UIContent } from "./plugins_UIContent";

export const useMarkAsWinnerPlugin = ({ contentType }) => {
  const [showModal, setShowModal] = useState(undefined);
  const [selectedCampaign, setSelectedCampaign] = useState(undefined);

  const quickUpdateContent = useQuickUpdateContent();
  const { SET_INFO_BANNER } = useBannerContext();

  const Component = showModal && (
    <ModalSmall
      title={plugins_UIContent.gsl.rowActionPlugin.modalTitle}
      onClose={() => setShowModal(undefined)}
      onConfirmText={plugins_UIContent.gsl.rowActionPlugin.modalOnConfirmText}
      showActionPanel
      onConfirm={
        selectedCampaign
          ? () => {
              quickUpdateContent.mutate(
                {
                  contentType,
                  contentId: showModal._id,
                  body: {
                    "tags.campaigns": selectedCampaign,
                  },
                },
                {
                  onSuccess: () => {
                    setShowModal(undefined);
                    SET_INFO_BANNER(
                      plugins_UIContent.gsl.rowActionPlugin
                        .onSuccessBannerContent,
                      "green",
                      3000
                    );
                  },
                }
              );
            }
          : undefined
      }
    >
      <TagsInputSingle
        isTagsStyle={false}
        tagType={"campaigns"}
        value={selectedCampaign}
        onChange={(k, v) => setSelectedCampaign(v)}
      />
    </ModalSmall>
  );

  const actionsConfig = {
    gsl_markAsWinner: (e, data) => {
      setShowModal(data);
    },
  };

  const actions = [
    {
      display: plugins_UIContent.gsl.rowActionPlugin.actionMenuBtnDisplay,
      action: "gsl_markAsWinner",
    },
  ];

  const injectPluginConfigIntoListingPageConfig = ({
    base_listingPageConfigs,
    activePublishStatusTab,
  }) => {
    return activePublishStatusTab === "inPublish"
      ? {
          ...base_listingPageConfigs,
          listingConfig: {
            ...base_listingPageConfigs.listingConfig,
            ...(actionsConfig ? { actionsConfig } : {}),
            tableConfig: {
              ...base_listingPageConfigs.listingConfig.tableConfig,
              ...(actions ? { actions } : {}),
            },
          },
        }
      : base_listingPageConfigs;
  };

  return {
    actions,
    actionsConfig,
    Component,
    injectPluginConfigIntoListingPageConfig,
  };
};
