import React from 'react'
import { PDFInput } from 'oolib'
import { mergeEntityData } from '../../utils/mergeEntityData'
import AtomicBlockShell from '../AtomicBlockShell'

export const RTEPDFInput = ({
    id,
    value,
    files,
    readOnlyProp, 
    setReadOnly, 
    editorState, 
    handleChange,
    entityKey, 
    block,
    isInRTE 
}) => {
  
  
  return (
    <>
    { (files || value) && 
    <AtomicBlockShell {...{ 
      id, 
      readOnlyProp, 
      setReadOnly, 
      editorState, 
      handleChange, 
      block,
      actions: [    
      { 
        icon: "FilePdf",
        display: "Replace PDF",
        onClick: () => {
          const actualReuploadButton = document.getElementById('replacePDF');
          actualReuploadButton.click()
        }
      },
      ] 
      }}>
        <PDFInput 
          value={value}
          files={files}
          onChange={(id, v) => {
            mergeEntityData(
              editorState,
              handleChange,
              entityKey,
              { value: v, files: undefined }
              );
            }}
          readOnly={readOnlyProp}
          isInRTE={isInRTE}
          />
   </AtomicBlockShell>
    }
   </>
  )
}
