import { useEffect, useRef, useState } from "react";

export const useDynamicLayoutHeight = () => {
  const parentWrapperRef = useRef();

  const [gridHeightCSS, setGridHeightCSS] = useState("100vh");
  useEffect(() => {
    setGridHeightCSS(
      `calc(100vh - ${
        parentWrapperRef.current?.getBoundingClientRect()?.top || 0
      }px)`
    );
  }, []);

  return { parentWrapperRef, gridHeightCSS };
};