import {
  ButtonPrimary,
  Container,
  OKELink,
  PaddingTop10,
  SANS_3,
  SANS_3_4,
  SANS_7_8,
  SERIF_8_9,
} from "oolib";
import React from "react";
import styled from "styled-components";
import { errorStatesConfig } from "./config";
import image404 from "./../../images/errorSvgs/404.svg";
import image403 from "./../../images/errorSvgs/403.svg";
import image500 from "./../../images/errorSvgs/500.svg";
import image400 from "./../../images/errorSvgs/400.svg";
import image401 from "./../../images/errorSvgs/401.svg";
import { useHistory } from "react-router-dom";
import { isDeviceOnline } from "../../utils/environmentConfig";

const StyledContainer = styled(Container)`
  padding: ${(props) => (props.layout === "center" ? "2rem 0rem" : "4rem 0")};
  display: ${(props) => props.layout === "center" && "flex"};
  justify-content: ${(props) => props.layout === "center" && "center"};
  text-align: ${(props) => props.layout === "center" && "center"};
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
`;

const imgConfig = {
  image404,
  image403,
  image500,
  image400,
  image401,
};

// const Title = styled(SERIF_5_6)`
//   /* Custom styles here */
// `;

// const SubTitle = styled(SANS_3_4)`
//   /* Custom styles here */
// `;

const ErrorStates = ({ errorResponseObject, enableLinkToHomepage = true }) => {
  const status = isDeviceOnline()
    ? errorResponseObject.response?.status 
    : 500;
  const errMsgs = isDeviceOnline()
    ? errorResponseObject.response?.data?.errors
    : [ { msg: 'Try again when you have an internet connection.'} ];
  const history = useHistory();
  const ErrorContainer = ({
    layout,
    img,
    imgConfig,
    opacity,
    imgSize,
    title,
    subTitle,
    action,
  }) => {
    return (
      <StyledContainer layout={layout}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center horizontally
            justifyContent: "center", // Center vertically
            height: "100%", // Take the full height of the container
          }}
        >
          {img && (
            <img
              src={imgConfig[img]}
              alt=""
              style={{
                opacity: opacity
                  ? opacity
                  : layout === "center"
                  ? "0.8"
                  : "0.5",
                height: imgSize ? imgSize + "px" : "120px",
              }}
            />
          )}
          {title && (
            <PaddingTop10>
              <SERIF_8_9 style={{ fontSize: "20px" }}>{title}</SERIF_8_9>
            </PaddingTop10>
          )}
          {subTitle && (
            <PaddingTop10>
              <SANS_3_4 style={{ fontSize: "14px" }}>{subTitle}</SANS_3_4>
            </PaddingTop10>
          )}
          {action && (
            <PaddingTop10>
              <SANS_3>
                <OKELink to="#" onClick={action?.actionOnClick}>
                  {action?.btnText}
                </OKELink>
              </SANS_3>
            </PaddingTop10>
          )}
        </div>
      </StyledContainer>
    );
  };

  return (
    <div>
      {errMsgs?.map((errMsg, index) => {
        const { imgSize, img, title, layout, subTitle } = errorStatesConfig({
          msg: errMsg,
        })[status];

        return (
          <ErrorContainer
            key={index}
            layout={layout}
            imgConfig={imgConfig}
            imgSize={imgSize}
            img={img}
            subTitle={subTitle}
            title={title}
            action={enableLinkToHomepage ? {
              btnText: "Go to homepage",
              actionOnClick: () => history.push("/"),
            }: undefined}
          />
        );
      })}
    </div>
  );
};

export default ErrorStates;
