import React, { useEffect, useRef, useState } from "react";
import { SANS_2, colors, icons } from "oolib";
import { StyledButtons, StyledReactionsPanel, StyledWrapperIcons, StyledWrapperText } from "./styled";


export const ReactionsPanel = ({ typesOfReactions, handleOnClick, reactionIcon, fixPos, reactionsBtnRef }) => {
  
  const [ reactionTypeText, setReactionTypeText ] = useState(null)
  const [ panelDimensions, setPanelDimensions ] = useState(undefined)
  const iconsWrapperRef = useRef(null);
  
  useEffect(() => {
    // if(iconsWrapperRef.current){
      setPanelDimensions({ width: iconsWrapperRef.current?.offsetWidth, height: iconsWrapperRef.current?.offsetHeight, btnHeight: reactionsBtnRef.current?.offsetHeight })
    // }
  }, [])

  return(
    <>
     {reactionTypeText && 
          <StyledWrapperText panelDimensions={panelDimensions} fixPos={fixPos}>
            <SANS_2>{reactionTypeText}</SANS_2>
          </StyledWrapperText>
    }
    <StyledReactionsPanel fixPos={fixPos} panelDimensions={panelDimensions} >
      <StyledWrapperIcons ref={iconsWrapperRef}>
        {Object.keys(typesOfReactions)?.map((key) => {

          const reactionObj = typesOfReactions[key]
          const Icon = icons[reactionObj?.icon]
           
            return <> 
                <StyledButtons 
                  onClick={() => handleOnClick(key)} 
                  key={key}
                  onMouseEnter={() =>  setReactionTypeText(key.charAt(0).toUpperCase() + key.slice(1))} onMouseLeave={() => setReactionTypeText(null)} 
                > 
                  <Icon style={{ cursor: 'inherit' }} size={reactionObj?.icon === "ThumbsUp" ? 22 : 24} color={colors.primaryColorText} weight={reactionIcon === reactionObj?.icon ? "fill" : "regular"}/>
                </StyledButtons>
              </>
        })}
        </StyledWrapperIcons>
    </StyledReactionsPanel>
    </>
  )
}
