import { getBreakPoint, useScreenWidth } from "oolib";
import React, {useRef} from "react"
import { useInView } from "react-intersection-observer";


import { StyledStickyScrollbar, StyledTargetWrapper} from "./styled";

export const StickyHorizontalScrollbar =({style, children})=> {
  const scrollbarRef = useRef(null);

  const {ref: targetRef, inView: targetInView, entry} = useInView();
  const targetElm = entry?.target;

  const {ref: bottomRef, inView : reachedBottom} = useInView();
  
  const screenWidth = useScreenWidth();

  const syncHorizontalScroll =(ev)=> {

    if (!scrollbarRef.current) return;
    if (!targetElm) return 

    (ev.target === targetElm? 
      scrollbarRef.current: targetElm).scrollLeft = ev.target.scrollLeft;
  }
  
  return (
    <>
      <StyledTargetWrapper
        ref={targetRef}
        onScroll={syncHorizontalScroll}
      >
        {children}
      </StyledTargetWrapper>
      {screenWidth > getBreakPoint('md')  && ( // sticky stuff for desktop only
      <>
        <div // bottom indicator
          ref={bottomRef}
          style={{ height: 1,background: 'transparent'}}
        />

        { targetElm && !reachedBottom && targetInView && (
          <StyledStickyScrollbar
            style={{...style, width: targetElm.clientWidth}}
            ref={scrollbarRef}
            onScroll={syncHorizontalScroll}
          >
              <div style={{height: '1.5rem', minWidth: targetElm.scrollWidth}}></div>
          </StyledStickyScrollbar> 
        )}
      </>
      )}

    </>
  )
}
  