export const genBaseTableConfig = (contentType) => ({
    tableConfig: {
      link: {
        urlString: "/published-page",
        idPath: ["meta.kp_content_type", "_id"],
        idAs: ["param", "query"],
      },
      rowIdPath: "_id",
      // minWidth: '2000px',
      contentType,
      cellData: [],
    },
  });