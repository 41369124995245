import { SANS_4_5 } from "oolib";
import React from "react";
import { useIsThisLinkActive } from "../../../PrimaryHeaderV2/HeaderUI/utils/useIsThisLinkActive";
import { StyledMenuLink } from "../../index.styled";
import { useGetDisplayForLandingPageLinks } from "../../../../utils/useGetDisplayForLandingPageLinks";

export const HamLink = ({ display: _display, to, thisNavConfig, onClick }) => {
  
  const { display, TextLoader } = useGetDisplayForLandingPageLinks({
    thisNavConfig,
    _display,
  });

  const genDisplay = () =>
    TextLoader ? (
      <TextLoader />
    ) : (
      <SANS_4_5 semibold={isThisLinkActive({ to }) || undefined}>
        {display}
      </SANS_4_5>
    );

  const { isThisLinkActive } = useIsThisLinkActive();
  return (
    <li>
      <StyledMenuLink
        to={to}
        onClick={onClick}
        active={isThisLinkActive({ to })}
      >
       {genDisplay()}
      </StyledMenuLink>
    </li>
  );
};
