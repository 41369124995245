import { useEffect, useState } from 'react'
import {
	EditorState,
	AtomicBlockUtils,
	RichUtils,
	ContentState,
} from 'draft-js'
//comps
import KPRichInlineBlockRenderer from '../components/inputs/KPRichInput/comps/KPRichInlineBlockRenderer'


export const enforceFixedBlockType = (
	editorState,
	onEditorChange,
	fixedBlockType,
) => {
	const contentState = editorState.getCurrentContent()
	const selectionState = editorState.getSelection()

	if (contentState.getFirstBlock().getKey() === selectionState.getStartKey()) {
		if (RichUtils.getCurrentBlockType(editorState) !== fixedBlockType) {
			applyBlockFormatting(fixedBlockType, editorState, onEditorChange)
		}
	}
}

export const useTrackEditorFocus = (wrapperRef, trackEditorFocus = true) => {
	//very important that we change the editorFocussed & textLink States only, if its a new value,
	// otherwise in contribute tpl, every click re-renders all the components, which is a proper fuck up.

	const [editorFocussed, setEditorFocussed] = useState(false)
	const [textLinkState, setTextLinkState] = useState({
		value: '',
		showLinkInput: false,
	})

	const handleClickOutside = (event, editorFocussed, textLinkState) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			//clicked outside editor
			if (editorFocussed) setEditorFocussed(false)
			if (textLinkState.value !== '' && textLinkState.showLinkInput !== false) {
				setTextLinkState({ value: '', showLinkInput: false })
			}
		} else {
			if (!editorFocussed) setEditorFocussed(true)
		}
	} //clicked inside editor

	useEffect(() => {
		if (trackEditorFocus === true) {
			document.addEventListener('mousedown', (e) =>
				handleClickOutside(e, editorFocussed, textLinkState),
			)
			return () =>
				document.removeEventListener('mousedown', (e) =>
					handleClickOutside(e, editorFocussed, textLinkState),
				)
		}
	}, [trackEditorFocus, editorFocussed, textLinkState])

	return [editorFocussed, setEditorFocussed, textLinkState, setTextLinkState]
}

export const createAtomicBlockEntity = (
	editor,
	compToRender,
	mutability,
	data,
) => {
	const contentState = editor.editorState.getCurrentContent()
	const contentStateWithEntity = contentState.createEntity(
		compToRender,
		mutability,
		data,
	)

	const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

	const newEditorState = EditorState.set(editor.editorState, {
		currentContent: contentStateWithEntity,
	})

	editor.onEditorChange(
		'', //this is supposed to be the key which we never really use, so i think its useless.
		AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '),
	)

	return entityKey
}

//1 -> renderAtomicBlock : -> automatic : block, contentState
//2 -> general Atomic props that we pass -> ( below props ) -> refereed to as props.blockprops.
//3 -> very specific props passed as 'data' in createAtomicBlockEntity.

//------0.4 : function that calls another react component to render the atomic block.
export const renderAtomicBlock = (
	block,
	editor,
	handleSetTempReadOnly,
	readOnly,
) => {
	if (block.getType() === 'atomic') {
		return {
			component: KPRichInlineBlockRenderer,
			editable: false,
			props: { editor, handleSetTempReadOnly, readOnly },
		}
	}
	return null
}

export const replaceEntityData = (editor, entityKey, data) => {
	const contentState = editor.editorState.getCurrentContent()

	const contentStateWithNewEntityData = contentState.replaceEntityData(
		entityKey,
		data,
	)

	const newEditorState = EditorState.set(editor.editorState, {
		currentContent: contentStateWithNewEntityData,
	})

	editor.onEditorChange('', newEditorState)
}

export const removeBlock = (editor, block) => {
	const blockKey = block.getKey()

	var contentState = editor.editorState.getCurrentContent()
	var blockMap = contentState.getBlockMap()
	var newBlockMap = blockMap.remove(blockKey)
	var newContentState = contentState.merge({ blockMap: newBlockMap })
	var newEditorState = EditorState.push(
		editor.editorState,
		newContentState,
		'remove-image',
	)
	editor.onEditorChange('', newEditorState)
}

//#5 : set classes for different blocktypes
export const setClassNamesToBlockTypes = (contentBlock) => {
	const type = contentBlock.getType()
	if (type === 'blockquote') {
		return 'BLOCKQUOTE rich-text-space-after'
	}
	if (type === 'unordered-list-item') {
		return 'SERIF_3_4 show-list-style rich-text-space-after'
	} //ul and ol dont have this because, these draft js block types are referring to the li with the ul and ol. we dont want to put bot spacing on that. we want to put it on ul and ol. so tht that been delt with in the richinput scss file
	if (type === 'ordered-list-item') {
		return 'SERIF_3_4 show-list-style rich-text-space-after'
	}
	if (type === 'code-block') {
		return ''
	}
	if (type === 'header-one') {
		return 'sansSerif rich-text-space-after'
	}
	if (type === 'header-two') {
		return 'SERIF_5_6 rich-text-space-after'
	}
	if (type === 'header-three') {
		return 'SERIF_5_6 rich-text-space-after'
	}
	if (type === 'header-four') {
		return 'SERIF_5_6 rich-text-space-after'
	}
	if (type === 'header-five') {
		return 'h5 serif rich-text-space-after'
	}
	if (type === 'header-six') {
		return 'h6 serif rich-text-space-after'
	}
	if (type === 'unstyled') {
		return 'rich-text-space-after'
	}
}

//#6 : key command formatting
export const handleKeyCommand = (command, editorState, onEditorChange) => {
	const newState = RichUtils.handleKeyCommand(editorState, command)
	if (newState) {
		onEditorChange('', newState)
		return 'handled'
	}
	return 'not-handled'
}

export const applyInlineStyle = (
	styleName,
	editorState,
	onEditorChange,
	setInlineToolbar,
) => {
	onEditorChange('', RichUtils.toggleInlineStyle(editorState, styleName))
	setInlineToolbar({ show: false, position: {} })
}
//#7 : toggle blocks
//--1 : HEADER-2
export const applyBlockFormatting = (
	blockType,
	editorState,
	onEditorChange,
) => {
	onEditorChange('', RichUtils.toggleBlockType(editorState, blockType))
}

//used by function below
const prepFormData = (targetFiles, folderName, allowedFormats) => {
	const files = Array.from(targetFiles)
	const formData = new FormData()
	files.forEach((file, i) => {
		formData.append(i, file)
	})
	formData.set('folderName', folderName)
	formData.set('allowedFormats', allowedFormats)

	return formData
}

//--4 : inline file (img or other) upload


const checkIfFileFormatAreAllowed = (formData) => {
	let allowedFormatsAry = formData.get("allowedFormats").split(",");
  
	for (var value of formData.values()) {
	  if (value.type) {
		//if type property exists means its a file
		if (allowedFormatsAry.some((d) => value.type.includes(d)) === false) {
		  //if even 1 file doesnt have a format that is mentioned in the allowed formats array, then throw an err
  
		  return false;
		} else {
		  return true;
		}
	  }
	}
  };

export const handleUploadChange = (
	e,
	compToRender,
	folderName,
	allowedFormats,
	editor,
	SET_ALERT_BANNER
) => {
	e.preventDefault && e.preventDefault()

	const { editorState, onEditorChange } = editor

	const formData = prepFormData(e.target.files, folderName, allowedFormats)

	const fileFormatsAreAllowed = checkIfFileFormatAreAllowed(formData)

	if(!fileFormatsAreAllowed){
		SET_ALERT_BANNER(`you are only allowed to upload ${formData.get(
			'allowedFormats',
		)} format files`,
		'danger',4000)
	}

	if (fileFormatsAreAllowed) {
		createAtomicBlockEntity(
			{ editorState, onEditorChange },
			compToRender,
			'IMMUTABLE',
			{ src: '', loading: true, formData },
		) //set inline img block to loading...
	}

	//--> async function which triggers a callback providing the status of upload and uploaded img data
}

export const handleInsertCTA = (
	compToRender,
	link,
	linkType,
	displayText,
	editor,
	setTextLinkState,
	setLinkInputOnConfirmHandler,
) => {
	const { editorState, onEditorChange } = editor

	createAtomicBlockEntity(
		{ editorState, onEditorChange },
		compToRender,
		'IMMUTABLE',
		{ link: { val: link, linkType, displayText } },
	) //set inline img block to loading...

	setTextLinkState({
		value: '',
		showLinkInput: false,
	})

	setLinkInputOnConfirmHandler('insertLink')
}

export const hideShowInlineToolbar = (
	setInlineToolbar,
	wrapperRef
) => {
	if (
		window.getSelection().anchorOffset !== window.getSelection().focusOffset
	) {
		//meaning some text is selected
		let boundingRect = window
			.getSelection()
			.getRangeAt(0)
			.getBoundingClientRect()
		let wrapperRect =
			wrapperRef.current && wrapperRef.current.getBoundingClientRect()
		let pos = {
			x: boundingRect.x + boundingRect.width / 2 - wrapperRect.x,
			y: boundingRect.top - wrapperRect.top - 7.71, //7.71 is the height of that triangular tip. (used pythagoras, considering the sides of that 45 degree rotated square are 10px)
		}


		setInlineToolbar({
			show: true,
			position: pos,
		})
	}
}

export const validateCharLimit = (
	editorState,
	charLimitAlert,
	cursorSetting,
	charLimit,
) => {
	const { showCharLimitAlert, setShowCharLimitAlert } = charLimitAlert
	const { cursorToEnd, setCursorToEnd } = cursorSetting

	let contentState = editorState.getCurrentContent()

	if (contentState.getPlainText().length < charLimit) {
		showCharLimitAlert && setShowCharLimitAlert(false)
	} else {
		setShowCharLimitAlert(true)
	}

	if (contentState.getPlainText().length > charLimit) {
		let plainText = contentState.getPlainText()
		let newPlainText = plainText.slice(0, -(plainText.length - charLimit))

		let newContentState = ContentState.createFromText(newPlainText)
		let newEditorState = EditorState.push(editorState, newContentState, '')

		// setShowCharLimitAlert(true);
		setCursorToEnd(cursorToEnd + 1)

		return newEditorState
	} else {
		return editorState
	}
}

//# NOTE
//If in the future need to force collapse a selection then, some code to get you started:

// const selection = newEditorState.getSelection();
//
// const collapseSelection = new SelectionState({
//   anchorKey: selection.focusKey,
// 	anchorOffset: selection.focusOffset,
// 	focusKey: selection.focusKey,
// 	focusOffset: selection.focusOffset,
// 	isBackward: false,
// })
//
// const newerEditorState = EditorState.forceSelection(
// 	editorState,
// 	collapseSelection
// );
