import loadable from "@loadable/component";
import {
  ComboboxSingleLanguage,
  DropdownSingleLanguage,
} from "../components/inputs/Dropdowns";

import {
  DynamicDropdown,
  DynamicRefetchDropdown,
} from "../components/inputs/Dropdowns/DynamicDropdown";

import TagsInputMulti from "../components/inputs/DynamicTagsInputs/TagsInputMulti";

import {
  AudioInput,
  CheckboxList,
  CheckboxButton,
  DatePicker,
  DateRangePicker,
  DateTimePicker,
  DateTimeRangePicker,
  DropdownMulti,
  DropdownSingle,
  EmailInput,
  IFrameInput,
  ImageInput,
  NumberInput,
  PhoneInput,
  ProfileImageInput,
  RadioList,
  SimpleTable,
  SwitchDouble,
  SwitchSingle,
  TextInput,
  URLInput,
  VideoInput,
  PDFInput,
  ResourceInput,
  GroupQuestionsInputSingle
} from "oolib";
import TextInputToArray from "../components/inputs/KPInputs/KPTextInputArray";

import { StaticRichText } from "../components/inputs/StaticRichText"
import {
  SubtitleInput,
  SummaryInput,
  TitleInput,
} from "../components/inputs/TextEditor/RichTextEditor/derivedComps/PresetRichInputs";

import { AutoValuePathManager } from "../pageTpls/PlatformBuilder/globalUtilityComps/AutoValuePathManager";
import { TextToIDInput } from "../pageTpls/PlatformBuilder/globalUtilityComps/TextToIDInput";

import AddTagsInTCI from "../pageTpls/PlatformBuilder/comps/AddTagsInTCI";

import PluginSentinel from "../components/plugins/sentinel";
import { DropdownOptionsAs } from "../pageTpls/TCI/comps/DropdownOptionsAs";
import InfoText from "../components/generalUI/InfoText";




const DisplayConditionsConfigBlock = loadable(() =>
import('../pageTpls/TCI/comps/DisplayConditionsConfigBlock'),
{ resolveComponent: comp => comp.DisplayConditionsConfigBlock }
)



const LexicalTextEditor = loadable(
  () => import("../components/inputs/TextEditor/LexicalTextEditor")
);
const JSONInput = loadable(() => import("../components/inputs/_V2/JSONInput"));
const LinkEmbedWithInput = loadable(() =>
  import("../components/inputs/_V2/LinkEmbedWithInput")
);
const Timeline = loadable(() => import("../components/dataviz/Timeline"));

const KPRichInput = loadable(() => import("../components/inputs/KPRichInput"));
const RichTextEditor = loadable(() =>
  import("../components/inputs/TextEditor/RichTextEditor")
);

const KPFAQComp = loadable(() => import("../components/inputs/KPFAQComp"));


const KPMetaPrimary = loadable(() =>
  import("../components/generalUI/KPMetaPrimary")
);

const TplMetaBlock = loadable(() =>
  import("../components/generalUI/TplMetaBlock")
);
const Tags__Intro = loadable(() =>
  import("../components/generalUI/Tags__Intro")
);

// const KPCasesDataOverview = loadable(() =>
// 	import('../components/inputs/KPCasesDataOverview'),
// )
const KPLikeContactChannelsBlock = loadable(() =>
  import("../components/inputs/KPLikeContactChannelsBlock")
);

const KPMultiProfilesDisplay = loadable(() =>
  import("../components/generalUI/KPMultiProfilesDisplay")
);

const TplStaticText = loadable(() =>
  import("../components/inputs/TplStaticText")
);

const NumberRangeInput = loadable(() =>
  import("../components/inputs/NumberRangeInput")
);

const CardRadioInput = loadable(() =>
  import("../components/inputs/CardRadioInput")
);
const Repeater = loadable(() => import("../components/inputs/Repeater"));
const RepeaterTabs = loadable(() =>
  import("../components/inputs/RepeaterTabs")
);

const TagsInputSingle = loadable(() =>
  import("../components/inputs/DynamicTagsInputs/TagsInputSingle")
);
const TagsManager = loadable(() =>
  import("../components/inputs/DynamicTagsInputs/TagsManager")
);
const FragmentsSet = loadable(() =>
  import("../components/inputs/FragmentsDisplayComps/FragmentsSet")
);
const FragmentsDict = loadable(() =>
  import("../components/inputs/FragmentsDisplayComps/FragmentsDict")
);




export const tplBlocks = {
  TplMetaBlock,
  
  ProfileImageInput,
  ResourceInput, // is empty when the value.resourceData is empty array
  KPFAQComp,

  TextInput,
  NumberInput,
  PhoneInput,
  EmailInput,
  URLInput,

  VideoInput,
  AudioInput,
  KPMetaPrimary,

  KPLikeContactChannelsBlock,

  KPMultiProfilesDisplay,

  ComboboxSingleLanguage,
  DropdownSingleLanguage,
  DropdownSingle,
  DropdownMulti,

  SimpleTable,
  TplStaticText,
  Tags__Intro,

  DateRangePicker,
  DatePicker,

  IFrameInput,

  DynamicDropdown,
  DynamicRefetchDropdown,
  TagsInputMulti,
  TagsInputSingle,
  NumberRangeInput,
  DateTimePicker,
  DateTimeRangePicker,

  CardRadioInput,
  SwitchSingle,
  SwitchDouble,

  RadioList,
  CheckboxList,

  Repeater,
  RepeaterTabs,
  TitleInput,
  SubtitleInput,
  SummaryInput,
  TextInputToArray,

  TagsManager,
  InfoText,

  FragmentsSet,
  FragmentsDict,
  Timeline,
  LinkEmbedWithInput,
  JSONInput,
  PDFInput,
  ImageInput,
  LexicalTextEditor,

  // ---------- Platform Builder Specific ---------- //
  AutoValuePathManager, //only used in TCI for value path
  
  /**
   * TextToIDInput:
   * has a text input + a little display that converts inputted 
   * text into camelcase. Useful in use cases such 
   * as 'create tag category' where user types a name, and we 
   * wanna preview that what the 'kp_content_type' derived 
   * from that name would look like.
   */
  TextToIDInput, 
  AddTagsInTCI,
  DisplayConditionsConfigBlock,

  // ---------- Plugins ---------- //
  PluginSentinel,

  // ---------- DEPRECATEED ---------- //
  KPRichInput, 
  RichTextEditor,
  DropdownOptionsAs,
  GroupQuestionsInputSingle,
  StaticRichText,
  CheckboxButton
};
