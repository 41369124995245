import axios from 'axios'

export const refreshCardsData = async (cardsData, setCardsData, setLoading) => {
	if (
		cardsData &&
		cardsData.type === 'internal' &&
		cardsData.selectedAry.length > 0
	) {
		const promises = cardsData.selectedAry.map(async (d) => {
			try {
				let contentType = d.meta.kp_content_type
				let profileType = d.meta.profile_type
				if (profileType) {
					let id =
						['userProfiles', 'board'].indexOf(profileType) !== -1
							? d.user && d.user._id
							: d._id
					let res
					if (['userProfiles', 'organisations'].indexOf(profileType) !== -1) {
						res = await axios.get(`/api/${profileType}/${id}`)
					} else {
						res = await axios.get(`/api/groups/${profileType}/${id}`)
					}
					return res.data.profile
				} else {
					const res = await axios.get(
						`/api/content/viewContent/${contentType}/${d._id}`
					)
					return res.data.contribution
				}
			} catch (err) {
				if (err) console.log('err in cardUtils > refreshCardsData', err)
			}
		})
		const results = await Promise.all(promises)

		setCardsData({ ...cardsData, selectedAry: results.filter(Boolean) }) // cuz if a user doesnt have read access to this resourceType, the returned result will be 'undefined'
		setLoading(false)
	} else {
		setLoading(false)
	}
}
