import { getVal } from "oolib";
import {  __GetContentTypeConfigNew, __GetProfileTypeConfig } from "./getters/gettersV2";




//these special syntax are nothing but shortcuts
export const parseSpecialSyntax = ({string, content}) => {
  
    switch(true){
      case string === '$thisProfileType':
        let profileType = (
          content.profileType || //cuz in the new structure,we are trying to make userProfiles redundant as an independent doc, but make it rather a sub doc of the user doc
          content.meta.profile_type
        )
        let confP = __GetProfileTypeConfig(profileType) || {};
        return confP?.content.title
      case string === '$thisContentType':
        let conf = __GetContentTypeConfigNew(content.meta?.kp_content_type) || {};
        return conf.general?.content.title
      case !!string.match(/\$contentTypeConfig\(.*?\)/g): // this questionmark is VERY IMP. i have no idea why tho... need to read up. it has something to do with returning multiple matches. which is what we need.
        let toReturn = string;
        const valPathMatch = string.match(/\$contentTypeConfig\(.*?\)/g);
        for (let singleMatch of valPathMatch) {
          // a single match will look something like: $valuePath(_id)
          let contentTypeAndValuePath = singleMatch.replace("$contentTypeConfig(", "");
          contentTypeAndValuePath = contentTypeAndValuePath.replace(")", "");
          const contentType = contentTypeAndValuePath.split(',')[0].trim()
          const configValuePath = contentTypeAndValuePath.split(',')[1].trim()
          const contentTypeConfig = __GetContentTypeConfigNew(contentType);
          let replacementWord = getVal(contentTypeConfig, configValuePath);
          toReturn = toReturn.replace(singleMatch, replacementWord);
        }
        return toReturn;
      default:
        return undefined;
    }
  }

