import axios from "axios";
import { ButtonGhost, ButtonSecondary, Loader, SANS_3, TextInput } from "oolib";
import React, { useState } from "react";

export const Testsearch = () => {
  const [status, setStatus] = useState(null);
  const [input, setInput] = useState("");

  const handlOnChange = (e, value) => {
    setInput(value);
  };

  const handleOnClick = async () => {
    setStatus("running");

    const res = await axios({
      method: "post",
      url: "api/esIndexes/createBuild",
      data: {
        newIndexName: input,
      },
    });

    console.log(res);
    setInput("");

    setStatus("completed");
  };

  return (
    <div
      style={{
        padding: "5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {status === "completed" ? (
        <div style={{textAlign:"center"}}>
          <SANS_3>Done !</SANS_3>
          <ButtonGhost onClick={() => setStatus(null)}>Add New</ButtonGhost>
        </div>
      ) : status === "running" ? (
        <Loader />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <TextInput value={input} onChange={handlOnChange}></TextInput>
          <ButtonSecondary onClick={handleOnClick}>Submit</ButtonSecondary>
        </div>
      )}
    </div>
  );
};
