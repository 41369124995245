import uuid from 'uuid';

export const publishingTplDivisions = [
  { id: "intro_1", display: "Intro Division I" },
  { id: "intro_2", display: "Intro Division II" },
  { id: "head", display: "Head Division" },
  { id: "body", display: "Body Division" },
  { id: "tags", display: "Tags Division" },
];

export const defaultSettingsSpace = {
  display: "Settings",
  value: "settings", // only purpose is to be unique so that tabbar functions properly
  valuePath: "kp_settings",
  pages: true,
};

export const defaultTemplatesSpace = {
  display: "Templates",
  value: "templates", // only purpose is to be unique so that tabbar functions properly
  valuePath: "kp_templates.data",
  divisions: publishingTplDivisions,
};

export const tciSpaceOps = [defaultSettingsSpace, defaultTemplatesSpace];

export const spaceOps = {
  knowledgeResources: tciSpaceOps,
  worksheets: [
    {
      display: "Templates",
      value: "templates", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.data",
    },
  ],
  groupsStyle1: [
    {
      display: "Templates",
      value: "intro", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.intro",
    },
    {
      display: "Templates",
      value: "body", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.body",
    },
  ],
  applicationFormsStyle1: [
    {
      display: "Templates",
      value: "intro", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.intro",
    },
    {
      display: "Templates",
      value: "body", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.body",
    },
  ],
  knowledgeResources2: [
    defaultSettingsSpace,
    {
      display: "Templates",
      value: "intro", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.intro",
    },
    {
      display: "Templates",
      value: "body", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.body",
    },
  ],
  researchNotes2: [
    defaultSettingsSpace,
    {
      display: "Templates",
      value: "intro", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.intro",
    },
    {
      display: "Templates",
      value: "body", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.body",
    },
  ],
  researchNotes: [
    defaultSettingsSpace,
    {
      display: "RAW",
      value: "raw", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.raw.blocks",
    },
    {
      display: "Synth",
      value: "synth", // only purpose is to be unique so that tabbar functions properly
      valuePath: "kp_templates.synth.blocks",
    },
  ],
};

const defaultBlockConfigs = {
  KPMetaPrimary: {
    comp: "KPMetaPrimary",
    props: {
      data: {
        key: "Type",
        valuePath: ["meta.kp_content_type"],
      },
      invert: true,
    },
  },
  TplMetaBlock: {
    comp: "TplMetaBlock",
    displayOnly: true,
    props: {
      showSeparator: true,
      data: [
        { img: "kp_contributed_by.avatar", user: "kp_contributed_by.name" },
        { date: "kp_date_published" },
      ],
    },
  },
};

const divisionwiseDefaultBlocks = {
  knowledgeResources: {
    intro_1: [defaultBlockConfigs.KPMetaPrimary],
    intro_2: [defaultBlockConfigs.TplMetaBlock],
  },
};

export const createDivisions = (tplCategory, divisions) => {
  return (divisions || publishingTplDivisions).map((d) => {
    return {
      divisionDisplay: d.display,
      divisionId: d.id,
      blocks: [...(divisionwiseDefaultBlocks[tplCategory][d.id] || [])],
    };
  });
};

export const createPage = (options = {}) => ({
  sectionName: options.id || "page0",
  /**
   * above is for backwards compat
   * can remove once we move all mappers to this block mapper
   * */
  pageId: options.id || "page0",
  blocks: [],
});

const createSubSpaces = (tplCategory) => {
  switch (tplCategory) {
    case "researchNotes":
      let obj = {};
      spaceOps.researchNotes.map((d) => {
        obj[d.value] = {
          blocks: d.divisions
            ? createDivisions("researchNotes", d.divisions)
            : [],
        };
      });
      return obj;
    default:
      return;  
  }
};

export const genInitTplConfig = (tplConfigProp) => {
  switch (tplConfigProp.category) {
    case "researchNotes":
      return {
        kp_settings: [createPage()],
        kp_templates: createSubSpaces("researchNotes"),
        ...tplConfigProp,

        /** if tpl config has defs for kp_settings & kp_templates, then
         * it will overwrite the initState which is what we want
         */
      };
    case "worksheets":
      return {
        kp_templates: {
          data: [],
        },
        ...tplConfigProp,

        /** if tpl config has defs for kp_settings & kp_templates, then
         * it will overwrite the initState which is what we want
         */
      };

    case "groupsStyle1":
      return {
        
        kp_templates: {
          intro: [
            {
              comp: 'TitleInput',
              valuePath: 'main.title',
              isRequired: true,
              isConfigurable: false,
              props: {
                placeholder: 'Enter a Title'
              }
            },
            {
              comp: 'ImageInput',
              valuePath: 'main.coverImage',
              isRequired: true,
              isConfigurable: false,
              props: {
                placeholder: 'Enter a Title',
                aspectRatio: '5/3'
              }
            }
          ],
          body: [
            {
              sectionTitle: 'example tab',
              sectionId: uuid.v4(),
              blocks: []
            }
          ],
        },
        ...tplConfigProp,
        

        /** if tpl config has defs for kp_settings & kp_templates, then
         * it will overwrite the initState which is what we want
         */
      };
    case "knowledgeResources":
    default:
      return {
        kp_settings: [createPage()],
        kp_templates: {
          data: createDivisions("knowledgeResources"),
        },
        ...tplConfigProp,

        /** if tpl config has defs for kp_settings & kp_templates, then
         * it will overwrite the initState which is what we want
         */
      };
  }
};
