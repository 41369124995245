import { cloneDeep } from "lodash";
import { getAnnoExplorerConfigsForThisTenant } from "./getAnnoExplorerConfigsForThisTenant";

export const applyCardConfigs = (annoDoc) => {
  const clonedDoc = cloneDeep(annoDoc);
  const thisTenantConfigs = getAnnoExplorerConfigsForThisTenant();

  if (!thisTenantConfigs?.cardConfigs) return clonedDoc;
  const docTagsWhitelist = thisTenantConfigs.cardConfigs.docTags?.whitelist;
  const annoTagsWhitelist = thisTenantConfigs.cardConfigs.annoTags?.whitelist;
  let filteredDocTags;
  let filteredAnnoTags;

  const filterTagsDataAsPerWhitelist = ({ tagsData, whitelist }) => {
    return Object.keys(tagsData).reduce((a, key) => {
      if (whitelist.includes(key)) {
        return { ...a, [key]: tagsData[key] };
      } else {
        return a;
      }
    }, {});
  };
  if (!docTagsWhitelist || docTagsWhitelist.length === 0) {
    filteredDocTags = clonedDoc.docTags;
  } else {
    filteredDocTags = filterTagsDataAsPerWhitelist({
      tagsData: clonedDoc.docTags,
      whitelist: docTagsWhitelist,
    });
  }

  if (!annoTagsWhitelist || annoTagsWhitelist.length === 0) {
    filteredAnnoTags = clonedDoc.annoTags;
  } else {
    filteredDocTags = filterTagsDataAsPerWhitelist({
      tagsData: clonedDoc.annoTags,
      whitelist: annoTagsWhitelist,
    });
  }

  return {
    ...clonedDoc,
    docTags: filteredDocTags,
    annoTags: filteredAnnoTags,
  };
};
