
import { ButtonPrimary, colors, transition } from "oolib";
import styled from "styled-components";


export const StyledGridContainer = styled.div`
  grid-template-columns: ${({toggleState, lsbWidth, lsbClosedWidth, rsbWidth, rsbClosedWidth, lsbComp, rsbComp}) => {
    const currLsbWidth = toggleState.lsb ? lsbWidth : lsbClosedWidth;
    const currRsbWidth = toggleState.rsb ? rsbWidth : rsbClosedWidth
    switch(true){
      case !!lsbComp && !!rsbComp:
        return `${currLsbWidth}px 1fr ${currRsbWidth}px`
      case !!rsbComp:
        return `1fr ${currRsbWidth}px`
      case !!lsbComp:
        return `${currLsbWidth}px 1fr`  
    }
  }};
  height: ${({gridHeightCSS}) => gridHeightCSS};
  display: grid; 
  //disable transition during resizing, else it breaks the smoothness of the interaction.
  ${({resizeSidebarIsActive}) => !resizeSidebarIsActive.isActive && transition('grid-template-columns')}

  //temp
  /* background: aqua; */
  position: relative;
  overflow: hidden; //so that when the sidebars are collapsed, its content actually gets hidden
`

export const StyledLSBSection = styled.section`
  /* background: lightgreen; */
  max-height: 100%; 
  overflow: auto; 
  overflow-x: hidden;
  direction: rtl; //scrollbar on the left
  justify-self: ${({toggleAnimationStyle}) => toggleAnimationStyle === 'slide' ? 'right' : 'left'}; 
  width: ${({lsbWidth, toggleAnimationStyle}) => toggleAnimationStyle === 'slide' ? lsbWidth+'px' : '100%'};
  border-right: 1px solid ${colors.greyColor10};
  background-color: ${colors.white};
  user-select: none;
  /* position: relative; */
`

export const StyledLSBToggleButton = styled(ButtonPrimary)`
  position: absolute;
  top: 0;
  /* right: 0; */
  transform: translateX(100%);
  z-index: 10; //else in TCI, the template index overlap over this button
`


export const StyledRSBSection = styled.section`
  /* background: lightgreen; */
  max-height: 100%; 
  overflow: auto; 
  overflow-x: hidden;
  justify-self: ${({toggleAnimationStyle}) => toggleAnimationStyle === 'slide' ? 'left' : 'right'}; 
  width: ${({rsbWidth, toggleAnimationStyle}) => toggleAnimationStyle === 'slide' ? rsbWidth+'px' : '100%'};
  border-left: 1px solid ${colors.greyColor10};
  background-color: ${colors.white};
  user-select: none;
  /* position: relative; */
`

export const StyledRSBToggleButton = styled(ButtonPrimary)`
  position: absolute;
  top: 0;
  /* left: 0; */
  transform: translateX(-100%);
`