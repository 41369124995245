import { queryClient } from "../../../../..";
import { __CheckAccessViaConfig } from "../../../../../utils/accessControl";
import {
  checkAccessAndGenContentPageLinkProps,
  checkAccessAndGenContentLandingPageLinkProps,
  checkAccessAndGenProfilePageLinkProps,
} from "../../PrimaryHeaderV2/HeaderUI/utils/genPageLinkProps";
import { getViewableOptionsBySegment } from "../../PrimaryHeaderV2/HeaderUI/utils/getViewableOptionsBySegment";
import { getViewableProfileTypeOptions } from "../../PrimaryHeaderV2/HeaderUI/utils/getViewableProfileTypeOptions";


/**
 * this function basically 'normalizes' the Nav config syntax into simple
 * { display, value, to } objects.
 * Also it works recursively, if it find a nested 'folder'
 */

export const genLinksConfigAsOptions = (_Nav) => {
  const { user } = queryClient.getQueryData("userData") || {};
  let SECLinksSet;

  if (_Nav.secondary) {
    SECLinksSet = {
      ..._Nav.secondary,
      display: _Nav.secondary.display || "Secondary Links",
      value: _Nav.secondary.value || "secondaryLinks",
    };
    let navSecFolder = _Nav.secondary?.folder || _Nav.secondary?.menu;
    SECLinksSet.folder = navSecFolder?.reduce((acc, item) => {
      switch (true) {
        case item.preset === "collections":
          return [
            ...acc,
            ...getViewableOptionsBySegment({ segment: "collections" }),
          ];

        case item.preset === "staticPages":
          return [
            ...acc,
            ...getViewableOptionsBySegment({ segment: "staticPages" }),
          ];

        case item.preset === "publishing":
          return [
            ...acc,
            ...getViewableOptionsBySegment({ segment: "publishing" }),
          ];

        case item.preset === "profileTypes":
          return [...acc, ...getViewableProfileTypeOptions()];

        default: //item is obj with to display and accessControl(optional)
          let itemIsAccessible = item.accessControl
            ? __CheckAccessViaConfig({ ...item.accessControl, user })
            : true;
          return itemIsAccessible ? [...acc, item] : acc;
      }
    }, []);
  }

  let PRIMLinksSet = _Nav.primary
    .map((item) => {
      switch (true) {
        case item.preset === "collections":
          return {
            ...item,
            display: item.display || "Collections",
            value: item.value || "preset_collections",
            folder: getViewableOptionsBySegment({ segment: "collections" }),
          };

        case item.preset === "staticPages":
          return {
            ...item,
            display: item.display || "Pages",
            value: item.value || "preset_staticPages",
            folder: getViewableOptionsBySegment({ segment: "staticPages" }),
          };

        case item.preset === "publishing":
          return {
            ...item,
            display: item.display || "Content",
            value: item.value || "preset_publishing",
            folder: getViewableOptionsBySegment({ segment: "publishing" }),
          };

        case item.preset === "profileTypes":
          return {
            ...item,
            display: item.display || "People",
            value: item.value || "preset_people",
            folder: getViewableProfileTypeOptions(),
          };

        case !!item.folder || !!item.menu:
          const thisFolder = item.folder || item.menu //menu is the deprecated term
          return {
            ...item,
            folder: genLinksConfigAsOptions({ //recursive
              primary: thisFolder
            })
            // thisFolder.filter(
            //   (d) =>
            //     !d.accessControl ||
            //     __CheckAccessViaConfig({ ...d.accessControl, user }) === true
            // ),
          };

        case item.type === "page" && !!item.contentType:
          const contentPageLinkProps = checkAccessAndGenContentPageLinkProps(item.contentType);
          if(!contentPageLinkProps) return undefined;
          return {
            ...item,
            ...contentPageLinkProps,
          };

        case item.type === "page" && !!item.profileType:
          return {
            ...item,
            ...checkAccessAndGenProfilePageLinkProps(item.profileType),
          };

          case item.type === "landingPage" && !!item.contentType:
        return {
          ...item,
          ...checkAccessAndGenContentLandingPageLinkProps({contentType: item.contentType, _id: item._id})
        }
        

        default: //statically defined link i.e. { display, to, accessControl }
          return !item.accessControl
            ? item
            : __CheckAccessViaConfig({ ...item.accessControl, user }) === true
            ? item
            : undefined;
      }
    })
    .filter(Boolean);

  return [...PRIMLinksSet, ...(SECLinksSet ? [SECLinksSet] : [])];
};
