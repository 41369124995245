import { SANS_2, icons, getVal } from "oolib";
import React from "react";
import styled from "styled-components";
import {
  CardBodyStyled,
  CardFooterStyled,
  CardHeaderStyled,
  CardStyled,
} from "../AnnotationCard/styled";

import {
  Title,
  Attributes,
  Summary,
  ClampedRichInput,
  Tags,
  Meta,
  NewTags,
  NewAnnotations,
  NewAttributes,
  NewSummary,
  RepeaterWrapper,
  KeywordHighlight,
  SimpleTextClamped,
  NewAnnotationsClone,
  TitleSmall
} from "./comps";
import { applyModifier } from "./modifiers";

const Flag = ({ type, height, width }) => {
  const flagMapper = {
    immersion1Kenya: "KenyaFlag",
    SecondImmersionKenya: "KenyaFlag",
    ThirdImmersionKenya: "KenyaFlag",

    immersion1Nigeria: "NigeriaFlag",
    SecondImmersionNigeria: "NigeriaFlag",
    ThirdImmersionNigeria: "NigeriaFlag",
    SenegalResearchNotes:"SenegalFlag"

  };

  const IconComp = icons[flagMapper[type]];

  if (!IconComp) return null;

  return <IconComp size={30} />;
};

const MetaBlocksStyledWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  h4::before {
    content: "| ";
  }

  h4:first-child::before {
    content: "";
  }
`;

// 1.component map
const getComp = {
  Title,
  Attributes,
  Summary,
  ClampedRichInput,
  Tags,
  SANS_2,
  Meta,
  NewTags,
  NewSummary,
  NewAnnotations,
  NewAttributes,
  RepeaterWrapper,
  KeywordHighlight,
  SimpleTextClamped,
  NewAnnotationsClone,
  TitleSmall
};

// 2.block builder
export const BlocksBuilder = (props) => {
  const { resource, config, annoTagType, tagId } = props;
  console.log({ resource, config });

  if (!config) return null;

  return config.map((c) => {
    const {
      comp,
      valuePath,
      useModifier,
      label,
      showMax,
      maxChars,
      keyword,
      suffix,
      blocks,
      readOnly,
      id,
      noOfLines
    } = c;

    const Component = getComp[`${comp}`];

    // dyncamic route

    const replaceWith = valuePath?.match(/\[(.*)\]/g);

    const path = replaceWith
      ? valuePath?.replace(
          /\[(.*)\]/g,
          props[`${replaceWith[0].substring(1, replaceWith[0].length - 1)}`]
        )
      : valuePath;


    let value = getVal(resource, path);

    if(!value) return null;

    if (useModifier) {
      value = applyModifier(useModifier, value);
    }


    

    return (
      <Component
        value={value}
        tagId={tagId}
        showMax={showMax}
        maxChars ={maxChars}
        suffix = {suffix}
        annoTagType={annoTagType}
        label={label}
        keyword={keyword}
        blocks={blocks}
        readOnly={readOnly}
        id={id}
        noOfLines={noOfLines}
      ></Component>
    );
  });
};

// 3.card layout
export default function  CardBuilder({
  onCardClick,
  data,
  annoTagType,
  searchCardConfig,
  activeCardId,
  tagId,
  observerRef,
  index,

  listingCardConfig
}) {
  
  if(!data) return null;
  return data.map((d, i) => {
    const cardConfig =
    (listingCardConfig && listingCardConfig[d?.meta?.kp_content_type]) || listingCardConfig || searchCardConfig.get(d?.meta?.kp_content_type);

    return (
      <CardStyled
        active={activeCardId === d._id}
        type={d.meta.kp_content_type}
        onClick={() => onCardClick(d)}
        ref={data.length === i + 1 ? observerRef : null}
      >
        <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
          <CardHeaderStyled>
            <div>
              <BlocksBuilder
                annoTagType={annoTagType}
                resource={d}
                config={cardConfig?.cardHeader}
                tagId={tagId}
              />
            </div>
            <div>
              <Flag type={d.meta.kp_content_type} />{" "}
            </div>
          </CardHeaderStyled>
          <CardBodyStyled>
            <BlocksBuilder
              annoTagType={annoTagType}
              resource={d}
              config={cardConfig?.cardBody}
              tagId={tagId}
            />
          </CardBodyStyled>
        </div>
        <CardFooterStyled>
          <MetaBlocksStyledWrapper>
            <BlocksBuilder resource={d} config={cardConfig?.metaBlocks} />
          </MetaBlocksStyledWrapper>
        </CardFooterStyled>
      </CardStyled>
    );
  });
}
