import styled from "styled-components";

export const InfoTextStyled = styled.div`
  background-color: ${({bgColor})=>bgColor};
  padding: 1rem 2rem;
  width: fit-content;
  /* color: #383838; */
  display: flex;
  gap: 1rem;
  align-items: top;
`;
