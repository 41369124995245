import React, { useState, useEffect, useRef, Fragment} from 'react';

import { StyledDropdown, StyledTooltip, StyledOptionBtn, StyledToolbar } from './styled';

import { rteToolbarConfig } from './config/rteToolbarConfig';


import { icons, useBannerContext } from 'oolib';
import { useRTEContext } from '../../RTEContext';

import { UIContent } from '../../../../../../UIContent';

const UploadButton =(props)=> {
  const {accept, onChange, multiple} = props
  const inputRef = useRef(null);
  
  return (<>
      <input
        type="file"
        style={{display: 'none'}}
        multiple={multiple}
        accept={accept}
        onChange={onChange}
        ref={inputRef}
      />
      <StyledOptionBtn {...props} onClick={()=> inputRef.current.click()}/>
    </>);
}


const Toolbar = (props)=> {
  const {editorState, handleChange, showToolbar, EditorRef, id} = props;
  const [activeDropdown, setActiveDropdown] = useState(undefined);
  const {setShowLinkOrEmbedModal, setShowAnnoLightbox, someTextIsSelected, annotation} = useRTEContext()
  const {SET_ALERT_BANNER} = useBannerContext()

  useEffect(()=> {
    if (!showToolbar) setActiveDropdown(undefined);

  }, [showToolbar]);

  const btnComps = {
    StyledOptionBtn,
    UploadButton
  }

  const genOptionBtn = (conf) => {

    if(conf.id === 'anno' && !annotation?.enable) return null

    const BtnComp = btnComps[conf.btnComp];
    const Icon = icons[conf.icon]

    const isDisabled = !someTextIsSelected && conf.disableIfNoSelect

    return (
      <div style={{position: 'relative'}} key={conf.id}>
        <BtnComp
          isDisabled={isDisabled} //we use 'isDisabled' instead of 'disabled', because 'disabled' will actually disable the button and the ALERT won't fire. 
          accept={conf.accept}//for file upload
          multiple={conf.multiple}
          onClick={e => {
            if(isDisabled){
              SET_ALERT_BANNER(
                UIContent.RichTextEditor_Toolbar.disabledLinkBtnClickMsg,
                'red',
                3000
              )
            }else{
              setActiveDropdown(undefined);
              conf.onClick && conf.onClick({
                e,
                editorState,
                handleChange,
                setShowLinkOrEmbedModal,
                setShowAnnoLightbox,
                EditorRef //used by anno to figure out positioning of lightbox
              })
            }
          }}
          onChange={e => {
            setActiveDropdown(undefined);
            conf.onChange && conf.onChange({
              e,
              editorState,
              handleChange,
              setShowLinkOrEmbedModal,
              setShowAnnoLightbox
            })
          }}
          active={conf.checkIfActive && conf.checkIfActive({editorState})}
        >
          <Icon size={16} weight="bold"/>
        </BtnComp>
        {conf.tooltipText && <StyledTooltip  className='buttName'> {conf.tooltipText} </StyledTooltip>}
      </div>
    );
  }

  return (
    <Fragment>
      <StyledToolbar
        id={`StyledToolbar-${id}`}
        onMouseDown={(ev) => ev.preventDefault()}
        showToolbar={showToolbar}
      >
        {rteToolbarConfig.map((conf) => {
          const Icon = icons[conf.icon];
          if (conf.menu) {
            return (
              <div style={{ position: "relative" }} key={conf.id}>
                <StyledOptionBtn
                  onClick={() =>
                    setActiveDropdown((prev) =>
                      prev === conf.id ? undefined : conf.id
                    )
                  }
                  active={conf.menu.some((subConf) => {
                    return (
                      subConf.checkIfActive &&
                      subConf.checkIfActive({ editorState })
                    );
                  })}
                >
                  <Icon size={16} weight="bold" />
                </StyledOptionBtn>
                <StyledTooltip className="buttName">
                  {" "}
                  {conf.tooltipText}{" "}
                </StyledTooltip>
                <StyledDropdown isActive={activeDropdown === conf.id}>
                  {conf.menu.map(genOptionBtn)}
                </StyledDropdown>
              </div>
            );
          }
          return genOptionBtn(conf)
        })}
      </StyledToolbar>
    </Fragment>
  );

  
}

export default Toolbar;