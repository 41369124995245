import { cloneDeep } from "lodash";
import { nanoid } from "nanoid";
import { getVal } from 'oolib'
 
export const _HANDLE_DUPLICATE_BLOCK = ({
    block, 
    blockAryPath, 
    blockIdx,
    setTplConfig,
    SAVE_TPL_CHANGES
}) => {
    setTplConfig((prev) => {
      let newTplConfig = cloneDeep(prev);
      let thisAry = getVal( newTplConfig, blockAryPath);
      let newValuePath = block.valuePath+'_'+nanoid(4)
      /**
       * pending dynamic setting of 'copy_1', 'copy_2' etc for valpath and id
       */
      thisAry.splice(blockIdx + 1, 0, {
        ...block,
        valuePath: newValuePath,
        props: { ...block.props, id: newValuePath },
      });

      SAVE_TPL_CHANGES({newTplConfig});

      return newTplConfig;
    });
  };