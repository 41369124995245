import axios from 'axios'
import { useQuery } from 'react-query'

const getConfig = async () => {
	let response = await axios.get('/api/platformConfigs', {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	})
	return response.data
}

export const useGetPlatformConfigs = () => {
	const query = useQuery({
		queryKey: 'platformConfigs', 
		queryFn: getConfig,
		
	})
	return query
}


