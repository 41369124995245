import EditorState from "draft-js/lib/EditorState";

const removeBlock = ({editorState, handleChange, block}) => {
	const blockKey = block.getKey()

	var contentState = editorState.getCurrentContent()
	var blockMap = contentState.getBlockMap()
	var newBlockMap = blockMap.remove(blockKey)
	var newContentState = contentState.merge({ blockMap: newBlockMap })
	var newEditorState = EditorState.push(
		editorState,
		newContentState,
		'remove-block',
	)
	handleChange(newEditorState)
}

export default removeBlock;