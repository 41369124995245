
export const config = [
    {
      
        icon: "TextBolder",
        format: 'bold'
    },
    {
      
        icon: "TextItalic",
        format: 'italic'
    },
    {
      
        icon: "TextUnderline",
        format: 'underline'
    }
]









// "Cards",

// "Table",

