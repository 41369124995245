
import React, { Fragment } from 'react'
import { ButtonPrimary, UploadButton, colors, injectHttps } from 'oolib'
import KPPDFDisplay from '../../../inputs/DEPRECATED_KPPDFDisplay'



const { primaryColor10 } = colors; 
 
const KPResourcesBlock = (props) => {
	

	const handleClick = (link) => {
		window.open(injectHttps(link))
	}

	const generateResourceThumbnail = (d) => {
		switch (true) {
			case ['pdf'].indexOf(d.format) !== -1:
				return (
					<KPPDFDisplay
						src={d.publicUrl}
						id={d.id /*important. else wont work */}
					/>
				)
			case ['jpg', 'png', 'gif', 'webp'].indexOf(d.format) !== -1:
				return <img alt={`Thumbnail`} src={d.mediaLink} style={{ width: '100%' }} />
			default:
				//meaning its a 'raw' resource type
				return (
					<h2 className='h2 u-case' style={{ alignSelf: 'flex-start' }}>
						{' '}
						.{d.format}
					</h2>
				)
		}
	}

	const getResourceLinkInfo = (d) => {
		switch (true) {
			case ['pdf'].indexOf(d.format) !== -1:
				return {
					link: d.publicUrl || d.mediaLink,
					buttonComp: d.publicUrl ? 'ArrowLineUpRight' : 'DownloadSimple',
				} //all conditionalities here are for backwards compat
			case ['jpg', 'png', 'gif', 'webp'].indexOf(d.format) !== -1:
				return {
					link: d.publicUrl || d.mediaLink,
					buttonComp: d.publicUrl ? 'ArrowLineUpRight' : 'DownloadSimple',
				}
			default:
				//meaning its a 'raw' resource type
				return { link: d.mediaLink, buttonComp: 'DownloadSimple' }
		}
	}


	const generateResourceActions = (d) => {
		if (props.readOnly) {
			return (
				<a
					className='kp-resource__action kp-resource__action--download'
					href={getResourceLinkInfo(d).link}
					target={'_blank'}>
					<ButtonPrimary
						icon={getResourceLinkInfo(d).buttonComp}
						invert={props.invert}
					/>
					{/* <ActionComp size={24} color={white} weight="light" /> */}
					{/* </ButtonPrimary> */}
				</a>
			)
		} else {
			return (
				<Fragment>
					<ButtonPrimary
						// type="danger"
						// className='kp-resource__action kp-resource__action--delete'
						onClick={() => props.removeResource(d.id)}
						icon='Trash'
						style={{ flex: '1 0 auto' }}>
						{/* <TrashSimple size={24} color={white} weight="light" /> */}
					</ButtonPrimary>
					<UploadButton
						icon={'PencilSimple'}
						variant='primary'
						className='kp-resource__action kp-resource__action--edit'
						onChange={props.uploadOnChange}
						multiple={props.multiple}
						id={`${props.id}_resources_uploader` /*v.v.v.important that it has a unique id*/}
						/>
				</Fragment>
			)
		}
	}

	const generateResource = (d) => {
		// let ActionComp = actionComps[getResourceLinkInfo(d).buttonComp]
		switch (true) {
			case !props.readOnly:
				return (
					<div
						className='kp-resource-wrapper'
						style={{ backgroundColor: primaryColor10 }}>
						{generateResourceThumbnail(d)}
						<div className='kp-resource__actions-wrapper'>
							{generateResourceActions(d)}
						</div>
					</div>
				)
			case props.readOnly && props.displayType === 'thumbnail':
				return (
					<div
						className='kp-resource-wrapper'
						style={{ backgroundColor: primaryColor10 }}>
						{generateResourceThumbnail(d)}
						<div className='kp-resource__actions-wrapper'>
							{generateResourceActions(d)}
						</div>
					</div>
				)

			default:
				return (
					// <a className='kp-resource__action kp-resource__action--download' href={d.mediaLink }>
					<ButtonPrimary
						onClick={() => handleClick(getResourceLinkInfo(d).link)}
						icon={getResourceLinkInfo(d).buttonComp}
						invert={props.invert}
						value={props.displayName || 'Download Resource'}>
						{/* <ActionComp size={24}  /> */}
						{/* {props.displayName || 'Download Resource'} */}
					</ButtonPrimary>
					// </a>
				)
		}
	}

	return (
		<Fragment>
			<div className='kp-resources-block'>
				{props.resources.map((d) => (
					<div className='kp-col'>{generateResource(d)}</div>
				))}
			</div>
		</Fragment>
	)
}

KPResourcesBlock.defaultProps = {
	displayType: 'thumbnail', //alt : btn
}

export default KPResourcesBlock
