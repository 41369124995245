import React, {useState} from 'react'
import { Container, PaddingTop50, SANS_3_4, Wrapper700, IFrameInput } from 'oolib'

export const EmbedURL = () => {
    const [url, setUrl] = useState(undefined)

    const value = "https://docs.google.com/presentation/d/1IZObkknG16aiS-dkgs7PTPjavAbiAUjq/edit#slide=id.p1"

    return (
        <Container>
            <Wrapper700>
                <IFrameInput label="New Component" onChange={(k, v) => setUrl(v)} value={url}/>
                <PaddingTop50 />
                <SANS_3_4>Read Only</SANS_3_4>
                <IFrameInput value={value} readOnly/>
            </Wrapper700>
        </Container>
    )
}