import { __GetAllContentTypeConfig } from "../../../../../utils/getters/gettersV2";

export const getFilterType = (filterConfig) => {
    if(filterConfig.source.filterType === 'tagType'){
      const allContentTypes = __GetAllContentTypeConfig();
      if(allContentTypes.find((c) => c.kp_content_type === filterConfig.source.tagType)){
        return ({filterType: 'contentTagType'})
      }else{
        return ({filterType: 'tagType'})
      }
    }
    else if(filterConfig.source.filterType === 'profileTagType'){
        return ({filterType: 'usersType'})
    }
    else{
      return ({filterType: filterConfig.source.filterType})
    }
  }