import { baseShapeBoilerplateLex, paragraphNodeBoilerplateLex, textNodeBoilerplateLex } from "../dataShapeBoilerplates";


export const reshapeDraftJSToLex_variantSimple = value => {
    if(!value) return value
    if(value.isLexical) return value;
    if(value.blocks){
        baseShapeBoilerplateLex.editorState.root.children = value.blocks.map(bl => ({
            ...paragraphNodeBoilerplateLex,
            children: [
                {
                  ...textNodeBoilerplateLex,
                  text: bl.text
                }
              ]
        }))
        return baseShapeBoilerplateLex;
    }
}