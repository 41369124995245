import { Container } from "oolib";
import { ButtonPrimary, SANS_4_5 } from "oolib";
import React, { useState, useEffect } from "react";
import { TagIdSliderContainerStyled, StyledStickyWrapper } from "./styled";
import { useGetTagsV3 } from "../../../../utils/react-query-hooks/tags";

const TagIdSlider = React.forwardRef(
  ({ annoTagType, tagId, onClick, id, tagSliderConfig = {} }, ref) => {
    const [tagIdList, setTagIdList] = useState();

    const isContentType = tagSliderConfig.isContentType ? true : false; // prototypes are content types now
    const contentType = tagSliderConfig.contentType || annoTagType.value

    const { data, status } = useGetTagsV3(contentType, {
      tagCategory: isContentType ? "contentTags" : "",
      filters: {
        // kp_published_status: "published",
        ...tagSliderConfig.findQuery,
      },
    });

    useEffect(() => {
      if (status === "success") {
        const index = data.findIndex((d) => d.tagId === tagId.value);

        setTagIdList({
          data: isContentType
            ? data.map((d) => ({ tagId: d.tagId, display: d.main.title }))
            : data,
          curr: index,
        });
      }
    }, [status]);

    const handleSliderClick = (value) => {
      onClick(id, {
        display: tagIdList?.data[tagIdList.curr + value].display,
        value: tagIdList?.data[tagIdList.curr + value].tagId,
      });

      setTagIdList((prev) => ({ ...prev, curr: prev.curr + value }));
    };

    // return null;

    return (
      <StyledStickyWrapper ref={ref} id={id}>
        <Container>
          <TagIdSliderContainerStyled>
            <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
              <ButtonPrimary
                S
                disabled={!tagIdList ? true : tagIdList?.curr === 0}
                icon="ArrowLeft"
                onClick={() => handleSliderClick(-1)}
              />
              <SANS_4_5>{tagId?.display}</SANS_4_5>
            </div>
            <ButtonPrimary
              S
              disabled={
                !tagIdList
                  ? true
                  : tagIdList?.data.length - 1 === tagIdList?.curr
              }
              onClick={() => handleSliderClick(1)}
              icon="ArrowRight"
            />{" "}
          </TagIdSliderContainerStyled>
        </Container>
      </StyledStickyWrapper>
    );
  }
);

export default TagIdSlider;
