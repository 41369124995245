import React, { useEffect, useState } from 'react';
import { SANS_3, ButtonGhost, ButtonSecondary, SANS_0 } from 'oolib';

import { StyledCoachmark, StyledMarkArrow} from './styled';

const MovingCoachmark =(props)=> {
  const {
    observer,
    targetElm,
    handleNext,
    handleSkip,
    headline,
    text,
    position,
    arrow,
    image,
    rank
    } = props;

  const [pageYOffset, setPageYOffset] = useState(window.pageYOffset);

  useEffect(()=> {
    const updateScrollState =(ev)=>  {
      console.log(`${ev.type}ed while coachmark in view !!`)
      setPageYOffset(window.pageYOffset);
    };
    let resizeTimerId;
    const updateResizeState =(ev)=> {
      clearTimeout(resizeTimerId); // resize optimization bit
      resizeTimerId = setTimeout(()=> {
        console.log(`${ev.type}ed while coachmark in view !!`)
        setPageYOffset(window.pageYOffset)
      }, 500)
    }
    window.addEventListener('scroll', updateScrollState);
    window.addEventListener('resize', updateResizeState);
    return ()=> {
      window.removeEventListener('scroll', updateScrollState);
      window.removeEventListener('resize', updateResizeState);
      observer.unobserve(targetElm)
    }
  }, [])

  return (
    <>
    { arrow && position && position !== 'center' && (
      <StyledMarkArrow
        position={position}
        targetElm={targetElm}
        pageYOffset={pageYOffset}
        arrow={arrow}
      />
    )
    }
    <StyledCoachmark position={position} targetElm={targetElm} pageYOffset={pageYOffset} >
      <div style={{display:'flex', gap:'1.5rem', marginBottom: '2rem'}}>

        {image && <img src={image} alt="instruction illustration" width='60px' />}
        <div>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <SANS_3 semibold>{headline}</SANS_3>
            <SANS_0>{rank.order}</SANS_0>
          </div>
          <SANS_3 style={{marginTop: '0.5rem'}}>{text}</SANS_3>
        </div>
      </div>
      <div style={{display:'flex', justifyContent:'flex-end'}}>
        <ButtonGhost onClick={handleSkip}>Skip</ButtonGhost>
        <ButtonSecondary onClick={handleNext}>
          {rank.isLast? "Confirm": "Next"}
        </ButtonSecondary>
      </div>
    </StyledCoachmark>
    </>
  );
}

export default MovingCoachmark;