import { ActionMenu } from "oolib";
import React from "react";
import { StyledShell, StyledShellHead } from "./styled";
import { removeBlock } from "../../utils";

const AtomicBlockShell = ({
  children,
  readOnlyProp,
  setReadOnly,
  editorState,
  handleChange,
  block,
  actions = [],
  invert,
}) => {
  const handleRemoveBlock = () => {
    removeBlock({ editorState, handleChange, block });
    removeTempReadOnly();
  };
  const setTempReadOnly = () => {
    if (!readOnlyProp) {
      //temporarily set to readOnly, ONLY IF the editor's base readOnly state is false
      setReadOnly((prev) => {
        if (prev !== true) {
          return true;
        }
      });
    }
  };

  const removeTempReadOnly = () => {
    if (!readOnlyProp) {
      //reset to og readOnly === false / undefined, ONLY IF the editor's base readOnly state is false
      setReadOnly((prev) => {
        if (prev === true) {
          return false;
        }
      });
    }
  };
  

  return (
    <StyledShell 
      onFocus={setTempReadOnly}
      onBlur={removeTempReadOnly}
      tabIndex={0} //note: has to be camelcase to work
      readOnlyProp ={readOnlyProp}
    >
      {readOnlyProp !== true && (
        <StyledShellHead>
          <ActionMenu
            align="right"
            invert={invert}
            actions={[
              ...actions,
              {
                icon: "Trash",
                display: "Remove",
                onClick: handleRemoveBlock,
              },
            ]}
          />
        </StyledShellHead>
      )}

      {children}
    </StyledShell>
  );
};

export default AtomicBlockShell;
