import React from 'react';
import uuid from 'uuid';

import { RTEImageInput } from '../RTEImageInput';
import { RTEExternalEmbed } from '../RTEExternalEmbed';
import { RTEInternalEmbed } from '../RTEInternalEmbed';
import { RTEVideoInput } from '../RTEVideoInput';
import { RTEAudioInput } from '../RTEAudioInput'
import { RTEIFrameInput } from '../RTEIFrameInput'
import { RTESimpleTable } from '../RTESimpleTable'
import { RTEPDFInput } from '../RTEPDFInput'
import { reshapeEntityDataForBackwardCompat } from './utils/reshapeEntityDataForBackwardCompat.js';

const AtomicBlockRenderer =(props)=> {
  const comps = {
    RTEImageInput,
    RTEIFrameInput,
    RTEExternalEmbed,
    RTEInternalEmbed,
    RTEVideoInput,
    RTESimpleTable,
    RTEAudioInput,
    RTEPDFInput,
    //backwards compatibility below
    KPRichInlineImage: RTEImageInput,
    RTEEmbedURLFrames: RTEIFrameInput
  }
  
  const entityKey = props.block.getEntityAt(0); //automatically passed by renderAtomicBlock function
  
  let entity, Component, compProps;
  if(entityKey){
    entity = props.contentState.getEntity( entityKey ); //automatically passed by renderAtomicBlock function
    Component = comps[entity.getType()]
    compProps = {
      ...(reshapeEntityDataForBackwardCompat({
        comp: entity.getType(),
        entityData: entity.getData()
      })), 
      ...props.blockProps
    }; // props.blockProps: whatever you pass as 'props' field inside renderAtomicBlock
  }
  
  return Component ? (
    <Component
      isInRTE //just a bool to help identify is this comp is currently being rendered inside the RTE or not.
      entityKey = {entityKey}
      block={props.block}
      id = {uuid.v4()} //just ensuring every block rendered has a unique id. important for blocks containing file uploaders to work properly
      {...compProps} 
    />
  ) : null;
}

export default AtomicBlockRenderer;