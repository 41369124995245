import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { setAuthToken } from "../../axiosDefaults";

// import { useSocketContext } from '../../contexts/socketContext'
// import * as Sentry from "@sentry/react";

import { useGenErrorMsgBanners } from '../useGenErrorMsgBanners'
import { queryClient } from "../../index";
import { UIContent } from "../../UIContent";
import { __precachePrivateApis } from "../../utils/swUtils";
import { useUpdateOnboardingChecklist } from "./users";
import { useBannerContext } from "oolib";
import { useGetQueryData } from "./general";
import { useAppSettingsContext } from "../../contexts/appSettingsContext";


export const fetchCurrentUser = async () => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    return await axios.get("/api/auth/getUserData").then((res) => res.data);
  } else {
    throw { errors: [{ type: "noAuthToken", msg: "No Auth Token!" }] };
  }
};

export const useUserLoad = (options) => {
  const { platformConfigsLoading, platformConfigs, enabled } = options;
  console.log({userload: platformConfigs})
  const {SET_INFO_BANNER} = useBannerContext()
  // const {socket, ATTACH_USER_DATA} = useSocketContext()
  const query = useQuery("userData", fetchCurrentUser, {
    enabled,
    onSuccess: ({ user }) => {
      // socket.emit(
      // 	ATTACH_USER_DATA,
      // 	user
      // );
    },
  });
  if (query.status === "success") {
    query.isAuthenticated = true;
    if (
      process.env.NODE_ENV === "production" &&
      !platformConfigsLoading &&
      platformConfigs.deployment._EnablePWA
    ) {
      __precachePrivateApis({
        thisUserProfileType: query.data.user.profileType, 
        thisUserId: query.data.user._id,
        SET_INFO_BANNER
      });
    }
  } else {
    query.isAuthenticated = false;
  }
  return query;
};

export const useEmailLogin = () => {
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      axios.post("/api/auth/login", data, {
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: (res) => {
        localStorage.setItem("token", res.data.token);
        setAuthToken(localStorage.token);
        queryClient.invalidateQueries("userData");
      },
    },
    {
			onError: (err) => {
			
			  genErrorMsgBanners({err})
			},
		}
  );
};

export const usePhoneLogin = () => {
  const queryClient = useQueryClient();
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  const {
    deployment: { _AuthConfig: { _LoginTypes } = {} },
  } = useGetQueryData("platformConfigs");
  let apiString =
    _LoginTypes?.phone?.OTPService === "smsIndiaHub"
      ? "/api/auth/verifySmsIndiaOtp"
      : "/api/auth/verifyFirebaseIdToken";
  return useMutation(
    ({ idToken, phone, otp }) =>
      axios.post(
        apiString,
        { idToken, phone, otp },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    {
      onSuccess: (res) => {
        localStorage.setItem("token", res.data.token);
        setAuthToken(localStorage.token);
        queryClient.invalidateQueries("userData");
      },
    },
    {
			onError: (err) => {
			
			genErrorMsgBanners({err})
			},
		}
  );
};

export const useSendSmsIndiaOtp = () => {
  return useMutation(({ phone, otp }) =>
    axios.post(
      "/api/auth/sendSmsIndiaOtp",
      { phone, otp },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  );
};

export const useSignup = () => {
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation((data) =>
    axios.post("/api/users", data, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
    {
			onError: (err) => {
			
			 genErrorMsgBanners({err})
			},
		}
  );
};

export const useVerifSignupMail_resend = () => {
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation((token) =>
    axios.post(
      "/api/users/verifSignupMail/resend",
      {},
      {
        headers: {
          "x-auth-token": token,
        },
      }
    ),
    {
			onError: (err) => {
			
			 genErrorMsgBanners({err})
			},
		}
  );
};

export const useSendResetPasswordMail = () => {
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation((data) =>
    axios.post("/api/users/resetPasswordMail", data, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
    {
			onError: (err) => {
			genErrorMsgBanners({err})
			},
		}
  );
};

export const useUpdatePassword = () => {
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation((data) =>
    axios.post(
      "/api/users/update/forgotPassword",
      { password: data.password },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": data.token,
        },
      }
    ),
    {
			onError: (err) => {
			
			genErrorMsgBanners({err})
			},
		}
  );
};

export const useVerifyPwdToken = () => {
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation((data) =>
    axios.post(
      "/api/users/verifyResetPasswordMail",
      {},
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    ),
    {
			onError: (err) => {
			
			 genErrorMsgBanners({err})
			},
		}
  );
};


export const useResetPassword = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { SET_ALERT_BANNER } = useBannerContext();

  const updateOnboardingChecklist = useUpdateOnboardingChecklist();


  return useMutation(
    (data) =>
      axios.post("/api/users/update/resetPassword", data, {
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: (res, args) => {
        // console.log({args})
        //need to destroy current session for security reasons.
        const resetTokenAndRedirToLogin = () => {
          localStorage.removeItem("token");
          setAuthToken(null);
          queryClient.resetQueries("userData");
          history.push("/login");

          SET_ALERT_BANNER({
            color: "green",
            msg: UIContent.reactQueryHooks_auth.passwordChangedSuccessfullyMsg,
            timeOut: 4000,
          });
        };
        if (args.isForceUpdatePassword) {
          updateOnboardingChecklist.mutate(
            {
              property: "passwordUpdatedOnFirstLogin",
              value: true,
            },
            {
              onSuccess: () => {
                resetTokenAndRedirToLogin();
              },
            }
          );
        } else {
          resetTokenAndRedirToLogin();
        }
      },
      onError: () => {
        SET_ALERT_BANNER({
          color: "red",
          msg: UIContent.general.serverErrorMsg,
          timeOut: 4000,
        });
      },
    }
  );
};

export const useVerifyAccount = () => {
  return useMutation((data) =>
    axios.post(
      "/api/users/verifyEmail",
      { token: data.token },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": data.token,
        },
      }
    )
  );
};

export const useLogout = () => {
  const history = useHistory();
  // const {socket, DETACH_USER_DATA} = useSocketContext()

  const logout = () => {
    localStorage.removeItem("token");
    setAuthToken(null);
    // Sentry.setUser(null)
    queryClient.resetQueries("userData");
    history.push("/");
    // socket.emit(
    // 	DETACH_USER_DATA
    // )
  };
  return { logout };
};

//doesnt user react query
export const getOAuthLoginLink = async () => {
  try {
    let res = await axios.get("/api/OAuth/google");
    return res.data;
  } catch (err) {
    console.log("err in getOAUthLoginLink", err);
  }
};
