import {
  ButtonGhost,
  SANS_2,
  TextInput
} from "oolib";
import React, { useState } from "react";
import { useCreateTagV3 } from "../../../../../../../../../../utils/react-query-hooks/tags";
import {
  AddTagContainerStyled,
  AddTagWrapperStyled,
  ErrorContainerStyled
} from "../../styled";


export const AddNewTagInput = ({
  showTagsLightbox,
  handleClose,
  value,
  handleAddTag,
  scrollableRef,
  tagCategory,
}) => {
  const [text, setText] = useState("");
  const [showError, setShowError] = useState(false);
  // const InputRef = useRef()
  const createTagV3 = useCreateTagV3();

  const handleCreateTag = () => {
    createTagV3.mutate(
      {
        type: showTagsLightbox.tagType,
        display: text,
        isUserGenerated: true,
        tagCategory,
      },
      {
        onSuccess: (res) => {
          const d = {
            _id: res.data._id,
            display: res.data.main?.title || res.data.display,
            tagId: res.data.tagId,
          };

          let preVal = value[showTagsLightbox.tagType];

          if (preVal) {
            preVal.data.push(d);
          } else {
            preVal = { collectionId: showTagsLightbox.tagType, data: [d] };
          }

          handleAddTag(showTagsLightbox.tagType, preVal);
          setText("");
          scrollableRef.current.scrollBy({
            top: scrollableRef.current.scrollHeight,
            behavior: "smooth",
          });
        },
        onError: (err) => {
        
          setShowError(true);

          const elem = document.getElementById(err.response.data.data);
        
          scrollableRef.current.scrollTo({
            top: elem?.offsetTop,
            behavior: "smooth",
          });

          console.log(err, "create tag error");
        },
      }
    );
  };

  const handleOnChange = (id, value) => {
    setText(value);
  };

  const actionBtn = { text: "Add", onClick: handleCreateTag };
  return (
    <AddTagWrapperStyled>
      <AddTagContainerStyled>
        <SANS_2 semibold>Others, Please specify</SANS_2>
        <ButtonGhost
          S
          icon={"X"}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClose();
          }}
        ></ButtonGhost>
      </AddTagContainerStyled>
      <div
        onMouseDown={(e) => {
          /**
           * v.v. important that we stop the propagation
           *
           * because the ancestor TagTypeMenu's wrapper div has a
           * prevent default on its mousedown.
           * this seems to block the focus event from happening on the
           * TextInput and so it doesnt work.\
           *
           * e.stopPropagation (i guess stopping the propagation of TextInput's
           * mouseDown event, allows it to work)
           *
           * still not fully sure why and how this works...
           */
          e.stopPropagation();
        }}
      >
        <TextInput
          value={text}
          onChange={handleOnChange}
          actionBtn={actionBtn}
        />
        {showError && (
          <ErrorContainerStyled>
            <SANS_2>Tag is already present !</SANS_2>
          </ErrorContainerStyled>
        )}
      </div>
    </AddTagWrapperStyled>
  );
};