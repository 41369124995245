import {
    BlockLabel, Container, Divider, Wrapper700
} from "oolib";
import React, { useState } from "react";
import Draggable from "../../../components/generalUI/Draggable";
import { DraggableContextProvider } from "../../../components/generalUI/Draggable/draggableContext";

import { DraggableBlock } from "../../PlatformBuilder/comps/DraggableBlock";



export const DraggableTest = () => {
  
  

  const [draggableTestData, setDraggableTestData] = useState({
    data1: [
      { id: "1", display: "One" },
      { id: "2", display: "Two" },
      { id: "3", display: "Three" },
    ],
    data2: [
      { id: "4", display: "Four" },
      { id: "5", display: "Five" },
      { id: "6", display: "Six" },
    ]
  });

  
  

  return (
    <div>
      <Container>
  
        <Wrapper700>
          <DraggableContextProvider>
            {/* <div> */}
            <BlockLabel label="Section 1" />
            <Draggable
              draggableId="listener"
              idValPath={"id"}
              ary={draggableTestData.data1}
              renderChildren={(d) => <DraggableBlock title={d.display} />}
              handleReposition={(newAry) => setDraggableTestData(prev => ({...prev, data1: newAry}))}
            />
            <div style={{ margin: "1rem 0" }}>
              <Divider />
            </div>
            <BlockLabel label="Section 2" />
            <Draggable
              draggableId="mover"
              idValPath={"id"}
              ary={draggableTestData.data2}
              renderChildren={(d) => <DraggableBlock title={d.display} />}
              handleReposition={(newAry) => setDraggableTestData(prev => ({...prev, data2: newAry}))}
            />
            {/* </div> */}
          </DraggableContextProvider>

          
          </Wrapper700>
        </Container>
      </div>
    
  );
};


