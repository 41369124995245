
import { useBannerContext } from "oolib"
import { UIContent } from "../UIContent"



export const useGenErrorMsgBanners = () => {
    const {SET_ALERT_BANNER} = useBannerContext()
    const genErrorMsgBanners = ({err}) => {
        console.log({err: err.response?.data?.errors})
        const errMsgs = err.response?.data?.errors;
        errMsgs 
        ? errMsgs.forEach(({msg}) => {
            SET_ALERT_BANNER({
                color: "red",
                msg,
                timeOut: 4000
            })
        })
        : SET_ALERT_BANNER({
            color: "red",
            msg: UIContent.general.serverErrorMsg,
            timeOut: 4000
        })
    }

    return {genErrorMsgBanners}
}