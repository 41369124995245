import React, { forwardRef } from "react";

import { getBreakPoint, mediaQuery, useContainerQuery, useScreenWidth } from "oolib";
import styled, { css } from "styled-components";

// const StyledWrapperCardGrid = styled.div`
//   ${({ containerQuery }) => {
//     if (containerQuery) {
//       return css`
//         ${containerQuery(
//           "sm",
//           css`padding: 0 6rem;`
//         )}
//       `;
//     } else {
//       return css`
//         ${mediaQuery("sm")} {
//           padding: 0 6rem;
//         }
//       `;
//     }
//   }}
// `

// const WrapperCardGridComp = ({style, className, children, id, enableContainerQuery}, ref) => {
  
//   const { ref: selfRef, containerQuery } = useContainerQuery({
//     enabled: enableContainerQuery,
//   });

//   return (
//     <StyledWrapperCardGrid
//       ref={ref || selfRef}
//       containerQuery={containerQuery}
//       id={id}
//       style={style}
//       className={`Wrapper--cardGrid ${className || ""}`}
//     >
//       {children}
//     </StyledWrapperCardGrid>
//   );
// };

// export const WrapperCardGrid = forwardRef(WrapperCardGridComp)


// export const WrapperCardGrid = (props) => {
//   const { style, className, children, id } = props;
//   return (
//     <div
//       id={id}
//       style={style}
//       className={`Wrapper--cardGrid ${className || ""}`}
//     >
//       {children}
//     </div>
//   );
// };

//phase out . replaced by WrapperPageTitle
export const WrapperFlex = ({ style: styleProp, children, className }) => {
  let style = {
    display: "flex",
    alignItems: "center",
    ...(styleProp || {}),
  };

  return (
    <div className={className || ""} style={style}>
      {children}
    </div>
  );
};

//could be handy for usage in flex sections
export const WrapperFlexBlocks = ({
  styleDesktop: styleDesktopProp,
  styleMob: styleMobProp,
  children,
  className,
  WrapperMob = "div",
}) => {
  const screenWidth = useScreenWidth();
  const sm = getBreakPoint("sm");

  let styleDesktop = {
    gap: "1rem",
  };

  return screenWidth >= sm ? (
    <WrapperFlex style={styleDesktop}>{children}</WrapperFlex>
  ) : (
    <WrapperMob>{children}</WrapperMob>
  );
};


//usecase based
export const WrapperButtons = ({ style: styleProp, children, className }) => {
  let style = {
    display: "flex",
    columnGap: "1rem",
    alignItems: "center",
    ...styleProp,
  };

  return (
    <div className={`Wrapper--buttons ${className || ""}`} style={style}>
      {children}
    </div>
  );
};

export const WrapperPageTitle = ({ children, className, style: styleProp }) => {
  let style = {
    position: "relative",
    display: "flex",
    alignItems: "center",
    columnGap: "2rem",
    // justifyContent: "space-between",
    minHeight: "6rem",
    padding: "1rem 0",
    ...(styleProp || {}),
  };

  return (
    <div className={className || ""} style={style}>
      {children}
    </div>
  );
};

export const WrapperPageTitleSpaceBetween = (props) => {
  let style = {
    ...props.style,
    justifyContent: "space-between",
  };

  return <WrapperPageTitle {...props} style={style} />;
};

export const WrapperPageTitleCenter = (props) => {
  let style = {
    ...props.style,
    justifyContent: "center",
  };

  return <WrapperPageTitle {...props} style={style} />;
};

export const WrapperAbsoluteHeaderButton = (props) => (
  <div
    style={{
      position: "absolute",
      left: props.left ? 0 : "unset",
      right: props.right ? 0 : "unset",
      height: "100%",
      display: "flex",
      alignItems: "center",
    }}
  >
    {props.children}
  </div>
);



