import { colors, SANS_2, transition } from "oolib";
import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { trackEventGA } from "../../../../../../../trackers/GA/trackEventGA";
import { __CheckAccessToPublishCTA } from "../../../../../../../utils/accessControl";
import { useGetQueryData } from "../../../../../../../utils/react-query-hooks/general";
import { _Locale } from "../../../../../../locale/Locale";
import { usePlatformBuilderContext } from "../../../../../../../contexts/platformBuilderContext";

const {black, white, greyColor100} = colors

const StyledButton = styled.button`
  background-color: ${greyColor100};
  cursor: pointer;
  border-radius: ${({ isMobile }) => isMobile ? 0 : '2px' };
  height: 4rem;
  display: flex;
  align-items: center;
  ${({isMobile}) => isMobile && css`
    width: 100%;
    justify-content: center;
  `}
  margin-left: ${({ isMobile }) => isMobile ? 0 : '1rem' };
  padding-left: 2rem;
  padding-right: 2rem;
  // @include buttonText();
  color: ${white};
  ${transition('background-color')}
  border: 0;
  &:hover {
    background-color: ${black};
  }

`;

export const HeaderPublishButton = ({config, isMobile}) => {
  const {platformBuilderMode} = usePlatformBuilderContext()
  
  const {
    deployment: { _ContributeCTAText },
  } = config;

  const {user} = useGetQueryData('userData') || {}

  // if no text then don't show the button
  if(!_ContributeCTAText) return null

  if(!__CheckAccessToPublishCTA(user)) return null;
  const LinkComp = platformBuilderMode ? Fragment : Link;



  return (
    <LinkComp onClick={() => {trackEventGA("Publishing Flow","Clicked Publish CTA Button On Header")}} 
          to={"/choose-template"}>
      <StyledButton id="okf_publish_cta" isMobile={isMobile}> {/** Id is v. important for coachmark to work */}
        <SANS_2 invert={true}>
         {_Locale(_ContributeCTAText)}
        </SANS_2>
      </StyledButton>
    </LinkComp>
  );
};
