import { EditorState, AtomicBlockUtils } from "draft-js";

const createAtomicBlockEntity =( 
	editorState,
	handleChange,
	compToRender,
	data = {}
)=> {
	const contentStateWithEntity = editorState.getCurrentContent().createEntity(
    compToRender,
    "IMMUTABLE",
		data
  );
  
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
	
  let newEditorState = EditorState.push(
    editorState,
    contentStateWithEntity,
    'apply-entity'
  )
  newEditorState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')

  handleChange(newEditorState);

	return entityKey;
}

export default createAtomicBlockEntity;