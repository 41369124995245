import { createPage } from "../../../utils/templating/spaceAndDivisionConfigs";
import { getActiveTciSpaceData } from "../utils/getActiveTciSpaceData";

export const _handleAddPage = ({
    HANDLE_UPDATE_TPL_CONFIG,
    activeTciSpace,
    tplConfig
}) => {
    let activeSpaceDataLength = getActiveTciSpaceData({tplConfig, activeTciSpace}).length;
    let pageData = createPage({ id: `page${activeSpaceDataLength}` });
    HANDLE_UPDATE_TPL_CONFIG({
      valuePath: `${activeTciSpace.valuePath}.${activeSpaceDataLength}`,
      value: pageData,
    });
  };