import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { getTenant } from "../../TENANT_CONFIGS";

import Components from "./comps";
import { __CheckAccessToModule } from "../../utils/accessControl";
import { useGetQueryData } from "../../utils/react-query-hooks/general";

const tenantPluginRoutes = {
  wqn: [
    {
      path: "/WQMCourse",
      comp: "WQMCourse",
    },
  ],
  nkms: [
    {
      path: "/mediaLibrary",
      comp: "MediaLibrary",
    },
  ],
  gelabs: [
    {
      path: "/aiChat",
      comp: "AiChat",
      accessControl: true,
      moduleId: "aiChat",
      ACTION: "READ",
    },
  ],
};

export default function PluginRoutes(props) {
  const { path } = useRouteMatch();
  const userData = useGetQueryData("userData");
  const thisTenantPluginRoutes = tenantPluginRoutes[getTenant()];
  if (!thisTenantPluginRoutes) return null;

  return (
    <>
      {thisTenantPluginRoutes.map((d) => {
        const hasAccess = d.accessControl
          ? __CheckAccessToModule(userData?.user, d.ACTION, d.moduleId)
          : true;


        return hasAccess ? (
          <Route
            exact
            key={d.path}
            path={path + d.path}
            component={Components[d.comp]}
          />
        ) : <Redirect to={"/"} />;
      })}
    </>
  );
}
