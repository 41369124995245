import { getBreakPoint, Accordion } from 'oolib';
import React, {useCallback, useState} from 'react';

import {LegendRadioList, Viz} from './comps'
import { StyledChartWrapper } from './styled';


export const PiChart = ({legendOptions, colorMap, data, id}) => {
    const [legendValue, setLegendValue ] = useState(undefined);
    const [piChartLegendEvent, setPiChartLegendEvent] = useState(0);

    const LegendWrapper = useCallback((props)=> {//useCallback is a must, 
      //otherwise this subComponent will be redeclared each time user hovers over a legend, because PiChart rerenders
      return document.documentElement.offsetWidth > getBreakPoint('md')?
      <div className='legend'>
        {props.children}
      </div>
      :
      <Accordion title='Legend'>
        <div style={{paddingBottom: '10px'}}>{props.children}</div>
      </Accordion>
    }, [legendOptions])
  return (
      <StyledChartWrapper>
        <div className='pie'>
          <Viz
            id={id}
            colorMap={colorMap}
            data={data}
            {...{piChartLegendEvent, legendValue, setLegendValue}}
          />
        </div>
        <LegendWrapper>
          <LegendRadioList
            handleChangeOn='hover'
            options= {legendOptions}
            value={legendValue}
            onChange={(k, v) => {
                  setLegendValue(v)
                  setPiChartLegendEvent(p => p+1)
              }
            }
          />
        </LegendWrapper>
      </StyledChartWrapper>
  )
    
}

export default PiChart