import axios from "axios";
import { useInfiniteQuery } from "react-query";





export const useGetAllUsersLazy = ({
    activeFilters,
    activeSort,
    populateTaggedResources,
    // lookupConfig,
    queryOptions,
    limit,
    findQuery,
    projection,
    countData,
    searchTerm,
    ksConfig
  }) => {
   
    const configs = {limit, activeSort, activeFilters, findQuery, populateTaggedResources, countData, projection, searchTerm, ksConfig}
    // console.log({filters,length:filters.length})
    return useInfiniteQuery({
      queryKey: [
        "allUsers",
        "lazy",
        configs
      ],
      queryFn: ({ pageParam : skip = 0 }) => {
        return axios
          .get(`/api/users/getAllUsers`, {
            params: { ...configs, skip },
          })
          .then((res) => res.data);
      },
      getNextPageParam: (lastPage) => {
        return lastPage.skip
      },
      ...(queryOptions || {})
    });
  };
  