import { boxShadow1, colors, LABEL, SANS_2, SANS_3, SANS_4_5, TagLink, transition } from "oolib";
import React, { useState } from "react";
import { MetaPrimary } from "../../../../generalUI/V2_MetaComps/MetaPrimary";
import LexicalTextEditor from "../../../../inputs/TextEditor/LexicalTextEditor";
import { uiContent } from "../../uiContent";
import { getProperTitleFromContentTypeId } from "../../utils/getProperTitleFromContentTypeId";
import styled, { css } from "styled-components";
import {CaretUp} from 'phosphor-react'

const StyledCardShell = styled.div`
  border: 1px solid ${colors.greyColor5};
  border-radius: 0.5rem;
  cursor: pointer;
  background: ${colors.white};
  ${transition("box-shadow", "border-color")};
  &:hover {
    ${boxShadow1};
    border-color: ${colors.greyColor15};
  }
`;

const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: ${colors.white};
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 0.5rem;  
  display: inline-flex; 
  gap: 0.5rem; 
  align-items: center;
  ${transition('background-color')}
  :hover{
    background-color: ${colors.greyColor3};
  }
  border-radius: 0.5rem;
`

const StyledIconWrapper = styled.div`
  ${transition('transform')};
  ${({show}) => !show && css`
    transform: rotate(180deg);
  `}
  display: flex;
`

const TagSectionOnCard = ({ tagsData, title }) => {
  const [show, setShow] = useState(false)
  return (
  <div>
    <div style={{marginBottom: '-1.2rem'}}>
    <StyledButton onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setShow(prev => !prev)
        }}>
      <SANS_2
        style={{
          color: colors.greyColor50,
          
        }}
      >
        {title}
      </SANS_2>
      <StyledIconWrapper show={show} >
        <CaretUp color={colors.greyColor50} size={16}/>
      </StyledIconWrapper>
    </StyledButton>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: `1px solid ${colors.greyColor10}`,
        borderRadius: "0.5rem",
        padding: "1rem",
        ...(!show ? { paddingBottom: 0} : { paddingBottom: '1rem'}),
        paddingTop: '2rem',
        height: show ? 'auto' : 0,
        overflow: 'hidden'
      }}
    >
      
      {Object.values(tagsData).map((d) => {
        if (!d || !d?.data || d.data?.length === 0) return null;
        return (
          <div>
            <LABEL style={{ paddingBottom: "0.2rem" }}>
              {getProperTitleFromContentTypeId(d.collectionId)}
            </LABEL>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5rem",
              }}
            >
              {d.data?.map((dd) => (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <TagLink
                    target={"_blank"}
                    XS
                    display={dd.display}
                    to={`/published-page/${d.collectionId}?id=${dd._id}`}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      })}
      
    </div>
  </div>
)};

export const CardAnnoExplorer = ({ singleAnno, lastCardObserverRef }) => {
  
  return (
    <a
      target="_blank"
      href={`/published-page/${singleAnno.meta.kp_content_type}?id=${singleAnno._id}`}
    >
      <StyledCardShell
        key={singleAnno._id + singleAnno.annoKey}
        // className={singleAnnoI === thisAry.length - 1 && 'has ref'}
        ref={lastCardObserverRef}
      >
        <div style={{ padding: "2rem", paddingBottom: "0" }}>
          <div style={{ paddingBottom: "1rem" }}>
            <MetaPrimary
              valuePath={"meta.contentTypeTitle"}
              content={singleAnno}
              align="left"
            />
          </div>
          <div style={{ paddingBottom: "1rem" }}>
            <SANS_4_5>{singleAnno.title}</SANS_4_5>
          </div>
          <LexicalTextEditor value={singleAnno.fragment} readOnly />
        </div>
        <div
          style={{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <TagSectionOnCard
            {...{
              tagsData: singleAnno.annoTags,
              title: uiContent.annoTagsTitle,
            }}
            />

          <div>
            <TagSectionOnCard
              {
                ...{
                  tagsData: singleAnno.docTags,
                  title: uiContent.docTagsTitle,
                }
              }
              />
          </div>
        </div>
      </StyledCardShell>
    </a>
  );
};
