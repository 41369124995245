import { useEffect, useState } from "react";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl"



export const useValidateTplPublish = ({tplConfig}) => {

    const [enableTplPublish, setEnableTplPublish] = useState(true)

    useEffect(() => {

        /** CHECK FOR CLASHING VALUE PATHS
         * Note that, this is VERY UNLIKELY to happen, since we do not allow user to 'save' block/block edits, if we find a 
         * clashing valuepath.
         * However, there is still a scenario in which, we might end up in this situation where we have two saved blocks
         * with clashing valuepaths -> say a block is 'duplicated' from another template to this one, and its valuepath clashes
         * with a block in this template.
         * 
         * Hence we continue to keep this validation as an extreme precaution.
         */
        const allBlocks = extractAllBlocksFromTpl({tpl: tplConfig});
        const hasDuplicate = allBlocks
            .map(b => b.valuePath)
            .some((vp, idx, self) => {
                let otherIdx = self.findIndex(valuePath => valuePath === vp);
                let dupExists = idx !== otherIdx;
                if(!dupExists) return false;
                //else dup exists
                return (
                    //basically if the duplicate pair of blocks are an inputOnly - displayOnly pair, then its okay
                    (allBlocks[otherIdx].displayOnly && allBlocks[idx].inputOnly) ||
                    (allBlocks[otherIdx].inputOnly && allBlocks[idx].displayOnly)
                ) ? false : true
                /**
                 * PENDING:
                 * in general thiis function has to be strengthened,
                 * because this check above (regarding displayOnly & inputOnly), doesnt take care
                 * of 3 or more duplicate block scenarios
                 */ 
            })
        if(hasDuplicate) setEnableTplPublish(false)
        else !enableTplPublish && setEnableTplPublish(true)
        
    },[tplConfig])
    
    return {
        enableTplPublish
    }
}