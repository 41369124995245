/**
 * This function checks for elements on the page based on a configuration array.
 * 
 * It first checks if the configuration array is empty. If so, it returns null.
 * 
 * It then sets the maximum number of attempts and the delay between attempts. reason - sometimes on low network connections, the element may not be found on the page in first attempt
 * It initializes attempts from 0 to 50.
 * 
 * The checkElements function, which loops through the configuration array and checks if the element is found on the page.
 * If the element is found, it sets the elements state to the found items and the cmIndex state to 0, and stops any more attempts.
 * 
 * If the maximum number of attempts is reached and no elements are found, it sets the elements state to an empty array.
 * 
 * Finally, it returns a cleanup function that clears the interval.
 */

export const getElementsFoundOnPage = ({ setElements, setCmIndex, config }) => {
  if (!config || config.length < 1) return null;

  const maxAttempts = 30;
  const intervalDelay = 500;

  let attempts = 0;
  let foundElements = [];

  const checkElements = () => {
    try {
      const newItems = config
        .filter(item => !foundElements.some(found => found.id === (item.value || item.id)))
        .map((item) => {
          const element = document.getElementById(item?.value || item?.id);
          return element ? { ...item } : null;
        })
        .filter(Boolean);

      if (newItems.length > 0) {
        foundElements = [...foundElements, ...newItems];
        setElements(foundElements);
        setCmIndex(0);
        // console.log(`Total found: ${foundElements.length}`);
      }

      attempts++;
      if (attempts >= maxAttempts) {
        // console.log(`Max attempts (${maxAttempts}), found: ${foundElements.length}`);
        return true; // Stop checking
      }

      return false; // Continue checking
    } catch (error) {
      console.error("Error checking for elements:", error);
      return false; // Continue checking
    }
  };

  const intervalId = setInterval(() => {
    if (checkElements()) {
      clearInterval(intervalId);
    }
  }, intervalDelay);

  return () => clearInterval(intervalId);
};