import { extractAllBlocksFromTpl } from "../../../../utils/templating/extractAllBlocksFromTpl";
import { uiContent } from "../uiContent";
import { getAnnoExplorerConfigsForThisTenant } from "./getAnnoExplorerConfigsForThisTenant";


function getArraysIntersection(...arrays) {
  if (arrays.length === 0) return [];

  return arrays.reduce((acc, array) =>
    acc.filter((item) => array.includes(item))
  );
}

const getCombinations = (array) => {
  const result = [];
  for (let i = 1; i <= array.length; i++) {
    const combinations = array.reduce((acc, curr, idx, arr) => {
      if (idx <= arr.length - i) {
        const combination = arr.slice(idx, idx + i);
        acc.push(combination);
      }
      return acc;
    }, []);
    result.push(...combinations);
  }
  return result;
};

const findCommonGroups = (blocksPerTpl, elementKey) => {
  const tplCombinations = getCombinations(blocksPerTpl);
  
  return tplCombinations.map(combination => {
    const commonElements = getArraysIntersection(
      ...combination.map(tpl => tpl.blocks[elementKey] || [])
    );
    
    const largerCombinations = tplCombinations.filter(otherComb => 
      otherComb.length > combination.length &&
      combination.every(tpl => otherComb.includes(tpl))
    );
    
    const uniqueElements = commonElements.filter(element => 
      !largerCombinations.some(largerComb => 
        getArraysIntersection(
          ...largerComb.map(tpl => tpl.blocks[elementKey] || [])
        ).includes(element)
      )
    );
    
    return {
      contentTypes: combination.map(tpl => tpl.contentType),
      elements: uniqueElements
    };
  }).filter(group => group.elements.length > 0);
};

const getRollupPossibilities = ({contentType, allTpls}) => {
  const thisTagTpl = allTpls.find((d) => d.kp_content_type === contentType);
  const allBlocks = extractAllBlocksFromTpl({ tpl: thisTagTpl });

  return allBlocks.map(block => {
    if(block.valuePath?.startsWith("tags.")){
      return ({ tagType: contentType, childTagType: block.props?.tagType })
    }else if(block.props?.options?.length > 0){
      return ({ tagType: contentType, childValuePath: block.valuePath, filterDisplay: block.props?.label || block.props?.shortLabel || block.valuePath })
    }else{
      return undefined;
    }
  }).filter(Boolean);
};

const createFilterSection = (group, type, totalTpls, isRollup = false) => {
  const isCommon = group.contentTypes.length === totalTpls;
  const baseTitle = type === 'docTags' ? uiContent.docTagsTitle : uiContent.annoTagsTitle;
  
  return {
    id: `group_${group.contentTypes.join('_')}_${type}`,
    filterScope: type,
    title: isCommon 
      ? `Common ${isRollup ? 'Rollup ' : ''}${baseTitle}`
      : `${isRollup ? 'Rollup ' : ''}${baseTitle}: ${group.contentTypes.join(', ')}`,
    contentTypesCount: group.contentTypes.length,
    ...(isRollup ? { rollupTags: group.rollupPoss } : { tags: group.elements })
  };
};

export const autoGenFilterConfigsFromTpl = ({
  selectedTpls,
  _ProfileTypes,
  allTpls,
}) => {
  const blocksPerTpl = selectedTpls.map((tpl) => ({
    contentType: tpl.kp_content_type,
    blocks: extractAllBlocksFromTpl({ tpl }).reduce((a, b) => {
      if (b.valuePath.startsWith("tags.")) {
        return {
          ...a,
          docTags: [...(a.docTags || []), b.props.tagType],
        };
      } else if (b.props?.annotation?.enable) {
        return {
          ...a,
          annoTags: Array.from(
            new Set([
              ...(a.annoTags || []),
              ...(b.props.annotation.tagTypesConfig?.map((d) => d.tagType) ||
                []),
            ])
          ),
        };
      } else {
        return a;
      }
    }, {}),
  }));

  const docTagGroups = findCommonGroups(blocksPerTpl, 'docTags');
  const annoTagGroups = findCommonGroups(blocksPerTpl, 'annoTags');

  // Generate rollup possibilities for each doc tag group
  const rollupGroups = docTagGroups.map(group => ({
    contentTypes: group.contentTypes,
    rollupPoss: group.elements.flatMap(tagType =>
      getRollupPossibilities({contentType: tagType, allTpls})
    )
  }));

  // Create all filter sections with proper metadata
  const filterSections = [
    // Common docTags
    ...docTagGroups
      .filter(group => group.contentTypes.length === selectedTpls.length)
      .map(group => createFilterSection(group, 'docTags', selectedTpls.length)),

    // Common rollup docTags
    ...rollupGroups
      .filter(group => group.contentTypes.length === selectedTpls.length)
      .map(group => createFilterSection(group, 'docTags', selectedTpls.length, true)),

    // Common annoTags
    ...annoTagGroups
      .filter(group => group.contentTypes.length === selectedTpls.length)
      .map(group => createFilterSection(group, 'annoTags', selectedTpls.length)),

    // Template-specific docTags (sorted by number of templates)
    ...docTagGroups
      .filter(group => group.contentTypes.length < selectedTpls.length)
      .sort((a, b) => b.contentTypes.length - a.contentTypes.length)
      .map(group => createFilterSection(group, 'docTags', selectedTpls.length)),

    // Template-specific rollup docTags (sorted by number of templates)
    ...rollupGroups
      .filter(group => group.contentTypes.length < selectedTpls.length)
      .sort((a, b) => b.contentTypes.length - a.contentTypes.length)
      .map(group => createFilterSection(group, 'docTags', selectedTpls.length, true)),

    // Template-specific annoTags (sorted by number of templates)
    ...annoTagGroups
      .filter(group => group.contentTypes.length < selectedTpls.length)
      .sort((a, b) => b.contentTypes.length - a.contentTypes.length)
      .map(group => createFilterSection(group, 'annoTags', selectedTpls.length))
  ];

  const totalTagBasedFilterConfigs = filterSections.map((d) => ({
    sectionId: d.id,
    sectionTitle: d.title,
    configs: d.rollupTags
      ? d.rollupTags.map((dd) => {
        if(dd.childTagType){
          return ({
            filterId: `${d.filterScope}_${dd.tagType}_${dd.childTagType}`,
            source: _ProfileTypes.some((p) => p.id === dd.childTagType)
              ? {
                  filterType: "profileTagType",
                  profileTypes: [dd.childTagType],
                  scope: d.filterScope,
                }
              : {
                  filterType: "tagType",
                  tagType: dd.childTagType,
                  scope: d.filterScope,
                },
            target: {
              filterType: "rollupTagType_v2",
              tagType: dd.tagType,
              childTagType: dd.childTagType,
            },
          })
        }else if(dd.childValuePath){
          return ({
            filterId: `${d.filterScope}_${dd.tagType}_${dd.childValuePath.replaceAll('.', '_DOT_')}`,
            display: dd.filterDisplay,
            source: {
              filterType: "tplType",
              contentType: dd.tagType,
              valuePath: dd.childValuePath,
              scope: d.filterScope,
            },
            target: {
              filterType: "rollupTagType_v2",
              tagType: dd.tagType,
              childValuePath: dd.childValuePath+'.value',
            },
          })
        }
      })
      : d.tags.map((dd) => ({
          filterId: `${d.filterScope}_${dd}`,
          source: _ProfileTypes.some((p) => p.id === dd)
            ? {
                filterType: "profileTagType",
                profileTypes: [dd],
                scope: d.filterScope,
              }
            : {
                filterType: "tagType",
                tagType: dd,
                scope: d.filterScope,
              },
          target: {
            filterType: "tagType",
            tagType: dd,
          },
        })),
  }));
  
  return applyFilterConfigsAsPerTenant(totalTagBasedFilterConfigs);
};


const applyFilterConfigsAsPerTenant = (allFilters) => {
  const thisTenantConfigs = getAnnoExplorerConfigsForThisTenant();
  const applyTenantConfigs = (filterConfigs) => {
    const {whitelist} = thisTenantConfigs?.filterConfigs || {}
    
    if(!whitelist || whitelist.length === 0) return filterConfigs
    //else
    return filterConfigs.filter(f => whitelist.includes(f.filterId))
  }
  return allFilters.map(filterSection => {
    const finalFilterConfigs = applyTenantConfigs(filterSection.configs);
    
    if(finalFilterConfigs.length === 0) return undefined;
    //else
    return ({
      ...filterSection,
      configs: finalFilterConfigs
    })
  }).filter(Boolean)
}