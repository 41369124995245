import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
} from "lexical";

import { $createHeadingNode } from "@lexical/rich-text";
import { $setBlocksType } from "@lexical/selection";

export const handleClick = ({ isActive, tag, editor }) => {
  editor.update(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      $setBlocksType(selection, () =>
        isActive ? $createParagraphNode() : $createHeadingNode(tag)
      );
    }
  });
};
