import { DropdownSingle } from "oolib";
import React, { useRef, useState } from "react";
import { __GetAllContentTypeConfig } from "../../../utils/getters/gettersV2";
import { DataFetcherDataPivot } from "./DataFetcherDataPivot";

export const DataPivot = () => {
  const allTpls = useRef(__GetAllContentTypeConfig());
  const [selectedCT, setSelectedCT] = useState(undefined);

  const getCTOptions = () =>
    allTpls.current.map((d) => ({
      display: d.general?.content.title || d.kp_content_type,
      value: d.kp_content_type,
    }));

  return (
    <div>
      <DropdownSingle
        options={getCTOptions()}
        value={selectedCT}
        onChange={(k, v) => setSelectedCT(v)}
      />
      <DataFetcherDataPivot contentType={selectedCT?.value} />
    </div>
  );
};
