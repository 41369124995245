import { CheckboxButton, colors, DropdownSingle } from "oolib";
import React from "react";

const TableCheckPlusDropdown = (props) => {
  const { id, value, onChange, options } = props;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CheckboxButton
        isSelected={value?.enabled}
        // if checkbox is selected, then set the current dropdown value to the parent
        // this is important. (look at access control builder usage, where we need the first dropdow value to be registered by parent on first time click on checkbox)
        onChange={(k, v) =>
          onChange(
            id,
            v
              ? { ...value, enabled: true }
              : { ...value, enabled: false }
          )
        }
      />
      <DropdownSingle
        disabled={value?.enabled ? false : true}
        selectStyleOverride={{ backgroundColor: colors.none }}
        saveValueAsString
        value={value?.value}
        options={options}
        popOutOfOverflowHiddenParent
        onChange={(k, v) => {
          onChange(id, { ...value, value: v });
        }}
      />
    </div>
  );
};

export default TableCheckPlusDropdown;
