import { Container } from "oolib";
import React from "react";
import TableElem from "../../../../components/cards/TableElem";
import { dummyData, tableConfig } from "./configs";

export const TableTest = () => {
  return (
    <div>
      <TableElem 
        data={dummyData} 
        tableConfig={tableConfig} 
        TableWrapper={Container}
        />
    </div>
  );
};
