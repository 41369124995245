

export const toggleRichHighlightUIState = ({
    targetFragmentKey,
    UIState: _UIState,
    add_remove
}) => {
    const UIState = _UIState === 'hover' ? '-hovered-' : _UIState === 'active' && '-active-'
    let elems = document.getElementsByClassName(targetFragmentKey);
    for (let i = 0; i < elems.length; i++) {
        elems[i].classList[add_remove](UIState);
      }
}