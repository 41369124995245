import axios from "axios";
import {
  SANS_0,
  SANS_2,
  SANS_3,
  SANS_4,
  SANS_7_8,
  Section,
  Wrapper1000,
  icons,
} from "oolib";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { useRef } from "react";
import { throttle } from "lodash";
import { UserMessage } from "./comps/UserMessage/index.js";
import { AIMessage } from "./comps/AIMessage/index.js";
import { SearchBar } from "./comps/SearchBar/index.js";
import { queryClient } from "../../index.js";
import { useGetQueryData } from "../../utils/react-query-hooks/general.js";
// import { useCallback } from "react";

const { Stars } = icons;
const useLoadChat = (userId) =>
  useQuery({
    queryKey: ["chat", userId],
    queryFn: () =>
      axios.get(`/api/embeddings/getChat/${userId}`).then((data) => data.data),
  });

const getResults = async ({
  searchQuery,
  handleOnStreamComplete,
  handleOnChunkRecieved,
}) => {
  const baseUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_SERVER_URL
      : "";

  const response = await fetch(
    ` ${baseUrl}/api/embeddings/vectorSearch/?searchQuery=${searchQuery}`,
    {
      method: "GET",
      headers: {
        tenant: "gelabs",
        "x-auth-token": localStorage.getItem("token"),
      },
    }
  );

  console.log({ response });
  

  // const response = await axios.get("/api/embeddings/vectorSearch", {
  //   params: { searchQuery },
  // });

  const reader = response.body.getReader();

  let finalString = "";

  while (true) {
    const { value, done } = await reader.read();

    if (done) {
      handleOnStreamComplete(finalString);
      return;
    }

    const data = new TextDecoder().decode(value);

    finalString += data;

    console.log({ ChunkReceived: data, finalString });

    handleOnChunkRecieved(finalString);
  }
};

const ChatHistory = ({ chatHistory }) => {
  const lastMessageRef = useRef();

  useEffect(() => {
    const elem = lastMessageRef.current;
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  if (chatHistory.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <SANS_7_8>
          Ask Ai <Stars size={20} />
        </SANS_7_8>
        <SANS_2 style={{ marginLeft: "1rem" }}>(Beta version)</SANS_2>
      </div>
    );
  }

  return chatHistory.map((message, idx) => (
    <div
      // key={message._id}
      style={{
        alignSelf: message.author === "user" ? "flex-end" : "flex-start",
      }}
      ref={chatHistory.length - 1 === idx ? lastMessageRef : null}
    >
      {message.author === "user" ? (
        <UserMessage message={message} />
      ) : (
        <AIMessage message={message} />
      )}
    </div>
  ));
};

const useDeleteChat = () => {
  return useMutation(() => axios.delete("/api/embeddings/deleteChat"), {
    onSuccess: (res) => {
      queryClient.removeQueries("chat", res.data.userId);
    },
  });
};

const WrapperStyled = styled.div`
  position: fixed;
  z-index: -1;

  height: 100vh;
  width: 100%;

  background-image: radial-gradient(circle at bottom, #e3efff 5%, #ffffff 70%);
`;

export const AiChat = () => {
  const [stream, setStream] = useState("");
  const [streaming, setStreaming] = useState(false);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const { mutate: deleteChat, status: deleteChatStatus } = useDeleteChat();

  const userData = useGetQueryData("userData");

  const { data: chat, status, isRefetching } = useLoadChat(userData.user._id);

  const [chatHistory, setChatHistory] = useState([]);

  // if (status === "success" && !chatHistory) {
  //   setChatHistory(chat.userChat ? chat.userChat.messages : []);
  // }

  useEffect(() => {
    const handleWheelScroll = (event) => {
      if (event.deltaY !== 0) {
        setIsAutoScrollEnabled(false); // Disable auto-scroll on any manual scroll
      }
    };

    window.addEventListener("wheel", handleWheelScroll);

    return () => {
      window.removeEventListener("wheel", handleWheelScroll); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    if (status === "success" && !isRefetching && chat.userChat) {
      setChatHistory(chat.userChat.messages);
    }
  }, [status, isRefetching]);

  const streamCallback = (node) => {
    if (node) {
      if (stream) {
        if (isAutoScrollEnabled) {
          window.scrollBy(0, node.scrollHeight);
        }
      } else {
        node.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleOnChunkRecieved = (textChunk) => {
    setStream(textChunk);
  };

  const throttled = throttle(handleOnChunkRecieved, 100);

  const handleOnStreamComplete = (finalString) => {
    setChatHistory((prev) => [
      ...prev,
      {
        author: "assistant",
        content: { type: "text", value: finalString },
      },
    ]);

    setStream("");
    setStreaming(false);

    queryClient.refetchQueries("chat", userData.user._id);
  };

  const sendOurTextMessage = ({ value }) => {
    setChatHistory((prev) => [
      ...prev,
      { author: "user", content: { type: "text", value } },
    ]);

    setStreaming(true);

    setIsAutoScrollEnabled(true);

    getResults({
      searchQuery: value,
      handleOnChunkRecieved: throttled,
      handleOnStreamComplete,
    });
  };

  return (
    <div>
      <WrapperStyled />

      <Wrapper1000>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 6rem)",
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <Section
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                padding: "2rem 0",
              }}
            >
              {status === "loading" ? (
                <SANS_4>Loading...</SANS_4>
              ) : status === "error" ? (
                <SANS_4>Error...</SANS_4>
              ) : (
                <ChatHistory chatHistory={chatHistory} />
              )}

              {streaming ? (
                stream ? (
                  <div ref={streamCallback}>
                    <AIMessage key="stream" streaming={true} message={stream} />
                  </div>
                ) : (
                  <div ref={streamCallback}>
                    <AIMessage
                      key="loading..."
                      streaming={true}
                      message={"Loading..."}
                    />
                  </div>
                )
              ) : null}
            </Section>
          </div>

          <div style={{ position: "sticky", bottom: 0 }}>
            <SearchBar
              deleteChat={() =>
                deleteChat(undefined, { onSuccess: () => setChatHistory([]) })
              }
              deleteChatStatus={
                deleteChatStatus === "loading" || deleteChatStatus === "error"
                  ? deleteChatStatus
                  : undefined
              }
              isStreaming={streaming}
              onSubmit={sendOurTextMessage}
            />
          </div>
        </div>
      </Wrapper1000>
    </div>
  );
};
