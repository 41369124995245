import { toArray } from "oolib";
import { queryClient } from "../..";

// main function
export const extractAllBlocksFromTpl = ({ tpl, buildersWhitelist }) => {
  const {BASE_LAYOUT_CONFIGS} = queryClient.getQueryData('platformConfigs')

  if (BASE_LAYOUT_CONFIGS[tpl.category].version >= 1) {
    return extractAllBlocksFromVersion1UpTpls({ tpl, buildersWhitelist });
  } else {
    // version 0
    return extractAllBlocksFromVersion0Tpls({ tpl });
  }
};

// common util
/**
 * its important to track the section stack, will explain why, with one example:
 * in useCreateIndexList, we use extractAllBlocksFromTpl. then we get rid of all those blocks
 * which are currently 'hidden' because of display conditions, else, the Index will break.
 * 
 * Now, display conditions can be applied to sections as well, so in useCreateIndexList, we need
 * to be able to get rid of entire sections that are currently hidden. Hence we need section data
 */

/**
 * about blockPath:
 * 
 * its important to track blockpath for a very specific usecase: 
 * changing the valuepaths in display conditions, when a related block's valuepath is changed.
 * 
 * look in the file 'modifyValuesAndValuePaths' to understand this.
 */
const _recursExtractBlocks = ({ data, cb, sectionStack = [], blockPathPrefix='' }) => {
  data.forEach((d,idx) => {
    if (!d.blocks) {
      cb({...d, sectionStack, blockPath: `${blockPathPrefix}.${idx}` });
    } else {
      _recursExtractBlocks({ data: d.blocks, cb, sectionStack: [...sectionStack, d], blockPathPrefix: `${blockPathPrefix}.${idx}.blocks`});
    }
  });
};

/**
 * Version1Up extract fn. ( groupsStyle1, knowledgeResources2 etc. )
 * First we extract the blocks from tpl,
 * then we extract the blocks from settings.
 * So that the columns show in that order in the table.
 */
const extractAllBlocksFromVersion1UpTpls = ({ 
  tpl, 
  buildersWhitelist //sometimes we want to extract blocks only from some of the builder. this is when this arg comes in handy
}) => {
  const allBlocksAry = [];

  //in template
  //narrowdown to configs related to metabuilder or formbuilder
  for (let spaceId in tpl.kp_templates) {

    let conf = tpl.kp_templates[spaceId];

    if(conf.builder && buildersWhitelist && !buildersWhitelist.includes(conf.builder)){
      continue;
    }

    if (!conf.subSpaces) {
      _extractBlocksFromSomeBuilders({ conf, confPath: `kp_templates.${spaceId}`, allBlocksAry }); //conf path helps us build the blockPathPrefix inside this function
    } else {

      conf.subSpaces.forEach((sub, subIdx) => {
        _extractBlocksFromSomeBuilders({ 
          conf: sub, 
          confPath: `kp_templates.${spaceId}.subSpaces.${subIdx}`, 
          allBlocksAry 
        });
      })
      
    }
  }

  /**
   * if a buildersWhitelist exists, and kp_settings is NOT included in it,
   * then skip extracting blocks from kp_settings
   */
  if(buildersWhitelist && !buildersWhitelist.includes('kp_settings')) return allBlocksAry

  //extract from settings
  if (tpl.kp_settings && tpl.kp_settings.length > 0) {
    _recursExtractBlocks({
      data: tpl.kp_settings,
      cb: (block) => allBlocksAry.push(block),
      blockPathPrefix: 'kp_settings'
    });
  }
  return allBlocksAry;
};

// extract from Version1Up util
const _extractBlocksFromSomeBuilders = ({ conf, confPath, allBlocksAry }) => {
  if (conf.builder === "FormBuilder") {
    _recursExtractBlocks({
      data: conf.configs || [], //cuz it can be undefined when tpl is being created in tci
      cb: (block) => allBlocksAry.push(block),
      blockPathPrefix: `${confPath}.configs`
    });
  }

  if (conf.builder === "MetaBuilder") {
    const inputBlockConfigsToPush = Object.keys(conf.configs.inputs).map(inputBlockKey => {
      const value = conf.configs.inputs[inputBlockKey];
      return ({
        ...value,
        blockPath: `${confPath}.configs.inputs.${inputBlockKey}`
      })
    })
    allBlocksAry.push(...inputBlockConfigsToPush);
  }
};

//-------------------------------
//-------------------------------
//-------------------------------
//-------------------------------

// Version0 extract fn. ( everything else, i.e. worksheets, researchNotes, knowledgeResources )
// TO DEAL WITH DEPRECATED TPLS

const extractAllBlocksFromVersion0Tpls = ({
  tpl,
  removeDuplicateValPaths = true,
  extractFrom = ["kp_templates", "kp_settings"],
}) => {
  let allBlocksAry = [];

  let startingPoints = [];
  _recursFindAryBegin({
    data: _slimDownToExtractFromKeys({ tpl, extractFrom }),
    cb: (res) => startingPoints.push(...res),
  });

  _recursExtractBlocks({
    data: startingPoints,
    cb: (block) => allBlocksAry.push(block),
  });

  if (removeDuplicateValPaths) {
    allBlocksAry = _handleRemoveDuplicateValuePaths({ allBlocksAry });
  }

  return allBlocksAry;
};

/**
 * extract from Version0 util
 *
 * @param {
 *  tpl : { a: ... , b: ..., c: ... },
 *  extractFrom: ['a', 'b']
 * }
 *
 * @returns { a: ..., b: ... }
 */
export const _slimDownToExtractFromKeys = ({ tpl, extractFrom }) => {
  let toReturn = {};
  toArray(extractFrom).map((d) => {
    toReturn[d] = tpl[d];
  });
  return toReturn;
};

//extract from Version0 util
const _recursFindAryBegin = ({ data, cb }) => {
  if (Array.isArray(data)) {
    cb(data);
  } else if (data.constructor.name === "Object") {
    Object.keys(data).forEach((key) => {
      _recursFindAryBegin({ data: data[key], cb });
    });
  }
};

// extract from Version0 util
const _handleRemoveDuplicateValuePaths = ({ allBlocksAry }) => {
  /**
   * in the case of 'subjects' tpls, duplicate valPaths will be there
   * across the tpls. So we need to remove those if the flag says so
   * EDIT: subject has been deprecated, but no harm keeping this condition still
   */
  return allBlocksAry.filter((block, idx, self) => {
    /**
     * if idx is a match, its the first occurence in the array
     * hence it is kept,
     * all subsequent occurences are removed
     */
    return idx === self.findIndex((b) => b.valuePath === block.valuePath);
  });
};
