import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React from "react";
import { ToolbarDropdownButton } from "../comps/ToolbarDropdownButton";
import { ToolbarUploadButton } from "../comps/ToolbarUploadButton";
import { checkIsActive } from "./checkIsActive";
import { config } from "./config";
import { handleChange } from "./handleChange";


export const MediaTools = () => {
  const [editor] = useLexicalComposerContext();

  return (
    <>
      <ToolbarDropdownButton
        isActive={config.options.some((conf) => checkIsActive({ editor }))}
        icon={config.icon}
        noOfChildButtons={config.options.length}
        genChildButtons={(setOpen) =>
          config.options.map((conf) => {
            const isActive = checkIsActive({ editor });
            return (
              <ToolbarUploadButton
                icon={conf.icon}
                isActive={isActive}
                accept={conf.accept}
                multiple={conf.props?.multiple}
                onChange={(e) => {
                  handleChange({
                    nodeType: conf.nodeType,
                    props: { ...conf?.props, files: e.target.files },
                    editor,
                  });
                  setOpen(false);
                }}
              />
            );
          })
        }
      />
    </>
  );
};
