
import { sortData } from "oolib";
import { queryClient } from "../../index";
import {__GetAllContentTypeConfig} from '../../utils/getters/gettersV2'
import { __GetAccessibleContentTypes, __GetAccessibleContentTypesForTheUser } from "../../utils/accessControl";

export const additionalOptionsFns = {
  getTagTypes: () => {
    let wordTags = queryClient
      .getQueryData("platformConfigs")
      ._TagTypes.map((d) => ({ display: d.tagType, value: d.tagType }));
    wordTags = sortData({
      data: wordTags,
      path: "value",
      fn: "alphabetical",
    });
    let userTags = queryClient
      .getQueryData("platformConfigs")
      ._ProfileTypes.map((d) => ({
        display: "💁🏽‍♂️ " + d.content.title,
        value: d.id,
      }));
    let contentTags = __GetAllContentTypeConfig().map((d) => ({
      display: d.general?.content?.title,
      value: d.kp_content_type,
    }));
    return [...wordTags, ...userTags, ...contentTags];
  },
  getWordTagTypes: () => {
    return queryClient
      .getQueryData("platformConfigs")
      ._TagTypes.map((d) => ({ display: d.tagType, value: d.tagType }));
  },
  getContentTypes: () => {
    return __GetAllContentTypeConfig().map((d) => ({
      display: d.general?.content?.title || d.id,
      value: d.kp_content_type,
    }));
  },
  getContentTypeByAction: ({action}) => {
    const { data: userData } = queryClient.getQueryState("userData");
    return __GetAccessibleContentTypesForTheUser({
      user: userData.user,
      ACTION: action,
    }).map((d) => ({
      display: d.general?.content?.title || d.id,
      value: d.kp_content_type,
    }));
  },
  getProfileTypes: () => {
    return queryClient
      .getQueryData("platformConfigs")
      ._ProfileTypes.map((d) => ({
        display: d.content?.title || d.id,
        value: d.id,
      }));
  },
  getContentStatus: () => [
    { display: "Published", value: "published" },
    { display: "Draft", value: "draft" },
    { display: "Awaiting Review", value: "awaitingModeration" },
    { display: "Under Review", value: "underModeration" },
    { display: "Sent Back", value: "sentBack" },
    { display: "Under Edit", value: "editPublished" },
  ],
};
