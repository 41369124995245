export const exampleImages1 =  [
  {
    kind: "storage#object",
    id: "ash_prod__media/images/015452ea-371f-4113-b442-a7a1fad36568.jpeg/1641799896148219",
    selfLink: "https://www.googleapis.com/storage/v1/b/ash_prod__media/o/images%2F015452ea-371f-4113-b442-a7a1fad36568.jpeg",
    mediaLink: "https://storage.googleapis.com/download/storage/v1/b/ash_prod__media/o/images%2F015452ea-371f-4113-b442-a7a1fad36568.jpeg?generation=1641799896148219&alt=media",
    name: "images/015452ea-371f-4113-b442-a7a1fad36568.jpeg",
    bucket: "ash_prod__media",
    generation: "1641799896148219",
    metageneration: "1",
    contentType: "image/jpeg",
    storageClass: "STANDARD",
    size: "8745",
    md5Hash: "J2gKb/10KjLK4wRhmHwLQA==",
    crc32c: "UWu0IQ==",
    etag: "CPvR6JzVpvUCEAE=",
    timeCreated: "2022-01-10T07:31:36.162Z",
    updated: "2022-01-10T07:31:36.162Z",
    timeStorageClassUpdated: "2022-01-10T07:31:36.162Z",
    originalFilename: "Quest-logo-master-1.jpg",
    mediaHost: "gcs",
    publicUrl: "https://img-getpocket.cdn.mozilla.net/592x296/filters:format(jpeg):quality(60):no_upscale():strip_exif()/https%3A%2F%2Fs3.us-east-1.amazonaws.com%2Fpocket-curatedcorpusapi-prod-images%2Ffe2b724b-6f1a-42f9-bf06-e71b95fe7def.jpeg",
    format: "jpeg",
    width: 500,
    height: 500,
    cropX: 0,
    cropY: 0,
  },
  {
    kind: "storage#object",
    id: "ash_prod__media/images/015452ea-371f-4113-b442-a7a1fad36568.jpeg/1641799896148219",
    selfLink: "https://www.googleapis.com/storage/v1/b/ash_prod__media/o/images%2F015452ea-371f-4113-b442-a7a1fad36568.jpeg",
    mediaLink: "https://storage.googleapis.com/download/storage/v1/b/ash_prod__media/o/images%2F015452ea-371f-4113-b442-a7a1fad36568.jpeg?generation=1641799896148219&alt=media",
    name: "images/015452ea-371f-4113-b442-a7a1fad36568.jpeg",
    bucket: "ash_prod__media",
    generation: "1641799896148219",
    metageneration: "1",
    contentType: "image/jpeg",
    storageClass: "STANDARD",
    size: "8745",
    md5Hash: "J2gKb/10KjLK4wRhmHwLQA==",
    crc32c: "UWu0IQ==",
    etag: "CPvR6JzVpvUCEAE=",
    timeCreated: "2022-01-10T07:31:36.162Z",
    updated: "2022-01-10T07:31:36.162Z",
    timeStorageClassUpdated: "2022-01-10T07:31:36.162Z",
    originalFilename: "Quest-logo-master-1.jpg",
    mediaHost: "gcs",
    publicUrl: "https://img-getpocket.cdn.mozilla.net/592x296/filters:format(jpeg):quality(60):no_upscale():strip_exif()/https%3A%2F%2Fs3.us-east-1.amazonaws.com%2Fpocket-curatedcorpusapi-prod-images%2F72e99f36-fc54-4eda-809a-6a0964121bf7.jpeg",
    format: "jpeg",
    width: 500,
    height: 500,
    cropX: 0,
    cropY: 0,
  },
    // {
    //   bucket: "ash_dev__media",
    //   contentType: "image/jpeg",
    //   crc32c: "ln0NrA==",
    //   cropX: 0,
    //   cropY: 0,
    //   etag: "CPLR+Z/b9fkCEAE=",
    //   format: "jpeg",
    //   generation: "1662108118706418",
    //   height: 1080,
    //   id: "ash_dev__media/images/stars_sky_31563b9b-fcc0-4f29-aa32-572c042e9397.jpeg/1662108118706418",
    //   kind: "storage#object",
    //   md5Hash: "GdAv5FCU8mjYXUZrwbaNyw==",
    //   mediaHost: "gcs",
    //   caption: convertToRaw(
    //     ContentState.createFromText("Lorem ipsum dolor sit amet")
    //   ),
    //   mediaLink:
    //     "https://storage.googleapis.com/download/storage/v1/b/ash_dev__media/o/images%2Fstars_sky_31563b9b-fcc0-4f29-aa32-572c042e9397.jpeg?generation=1662108118706418&alt=media",
    //   metageneration: "1",
    //   name: "images/stars_sky_31563b9b-fcc0-4f29-aa32-572c042e9397.jpeg",
    //   originalFilename: "stars_sky.jpg",
    //   publicUrl:
    //     "https://storage.googleapis.com/ved_dev__media/images/abrar_burk_profile_006ff136-231b-48ed-ba58-0bf834554aab.jpeg",
    //   selfLink:
    //     "https://www.googleapis.com/storage/v1/b/ash_dev__media/o/images%2Fstars_sky_31563b9b-fcc0-4f29-aa32-572c042e9397.jpeg",
    //   size: 150481,
    //   storageClass: "STANDARD",
    //   timeCreated: "2022-09-02T08:41:58.708Z",
    //   timeStorageClassUpdated: "2022-09-02T08:41:58.708Z",
    //   updated: "2022-09-02T08:41:58.708Z",
    //   width: 1920,
    // },
    // {
    //   kind: "storage#object",
    //   id: "cata_dev__media/images/images (2)_cc1bc552-534c-405e-8461-2f04bef87f9c.jpeg/1661238994635720",
    //   selfLink:
    //     "https://www.googleapis.com/storage/v1/b/cata_dev__media/o/images%2Fimages%20(2)_cc1bc552-534c-405e-8461-2f04bef87f9c.jpeg",
    //   mediaLink:
    //     "https://storage.googleapis.com/download/storage/v1/b/cata_dev__media/o/images%2Fimages%20(2)_cc1bc552-534c-405e-8461-2f04bef87f9c.jpeg?generation=1661238994635720&alt=media",
    //   name: "images/images (2)_cc1bc552-534c-405e-8461-2f04bef87f9c.jpeg",
    //   bucket: "cata_dev__media",
    //   generation: "1661238994635720",
    //   metageneration: "1",
    //   contentType: "image/jpeg",
    //   storageClass: "STANDARD",
    //   size: 10944,
    //   md5Hash: "FiuUN8RzCwDNYkeFmEifIg==",
    //   crc32c: "fM2qkQ==",
    //   etag: "CMiXpsG13PkCEAE=",
    //   timeCreated: "2022-08-23T07:16:34.637Z",
    //   updated: "2022-08-23T07:16:34.637Z",
    //   timeStorageClassUpdated: "2022-08-23T07:16:34.637Z",
    //   originalFilename: "images (2).jpeg",
    //   mediaHost: "gcs",
    //   publicUrl:
    //     "https://storage.googleapis.com/ved_dev__media/images/me_3_552e29dd-086e-46c1-8e7d-9116011b9412.jpeg",
    //   format: "jpeg",
    //   width: 300,
    //   height: 168,
    //   cropX: 0,
    //   cropY: 0,
    // }
  ];

  export const exampleImages2 =  [
    {
      kind: "storage#object",
      id: "ash_prod__media/images/015452ea-371f-4113-b442-a7a1fad36568.jpeg/1641799896148219",
      selfLink: "https://www.googleapis.com/storage/v1/b/ash_prod__media/o/images%2F015452ea-371f-4113-b442-a7a1fad36568.jpeg",
      mediaLink: "https://storage.googleapis.com/download/storage/v1/b/ash_prod__media/o/images%2F015452ea-371f-4113-b442-a7a1fad36568.jpeg?generation=1641799896148219&alt=media",
      name: "images/015452ea-371f-4113-b442-a7a1fad36568.jpeg",
      bucket: "ash_prod__media",
      generation: "1641799896148219",
      metageneration: "1",
      contentType: "image/jpeg",
      storageClass: "STANDARD",
      size: "8745",
      md5Hash: "J2gKb/10KjLK4wRhmHwLQA==",
      crc32c: "UWu0IQ==",
      etag: "CPvR6JzVpvUCEAE=",
      timeCreated: "2022-01-10T07:31:36.162Z",
      updated: "2022-01-10T07:31:36.162Z",
      timeStorageClassUpdated: "2022-01-10T07:31:36.162Z",
      originalFilename: "Quest-logo-master-1.jpg",
      mediaHost: "gcs",
      publicUrl: "https://img-getpocket.cdn.mozilla.net/592x296/filters:format(jpeg):quality(60):no_upscale():strip_exif()/https%3A%2F%2Fs3.us-east-1.amazonaws.com%2Fpocket-curatedcorpusapi-prod-images%2Ffe2b724b-6f1a-42f9-bf06-e71b95fe7def.jpeg",
      format: "jpeg",
      width: 500,
      height: 500,
      cropX: 0,
      cropY: 0,
      caption: {
        blocks: [
          { text: 'Hello world caption for image 1'},
          { text: 'Multiline'}
        ],
        entityMap: {}
      }
    },
    {
      kind: "storage#object",
      id: "ash_prod__media/images/015452ea-371f-4113-b442-a7a1fad36568.jpeg/1641799896148219",
      selfLink: "https://www.googleapis.com/storage/v1/b/ash_prod__media/o/images%2F015452ea-371f-4113-b442-a7a1fad36568.jpeg",
      mediaLink: "https://storage.googleapis.com/download/storage/v1/b/ash_prod__media/o/images%2F015452ea-371f-4113-b442-a7a1fad36568.jpeg?generation=1641799896148219&alt=media",
      name: "images/015452ea-371f-4113-b442-a7a1fad36568.jpeg",
      bucket: "ash_prod__media",
      generation: "1641799896148219",
      metageneration: "1",
      contentType: "image/jpeg",
      storageClass: "STANDARD",
      size: "8745",
      md5Hash: "J2gKb/10KjLK4wRhmHwLQA==",
      crc32c: "UWu0IQ==",
      etag: "CPvR6JzVpvUCEAE=",
      timeCreated: "2022-01-10T07:31:36.162Z",
      updated: "2022-01-10T07:31:36.162Z",
      timeStorageClassUpdated: "2022-01-10T07:31:36.162Z",
      originalFilename: "Quest-logo-master-1.jpg",
      mediaHost: "gcs",
      publicUrl: "https://img-getpocket.cdn.mozilla.net/592x296/filters:format(jpeg):quality(60):no_upscale():strip_exif()/https%3A%2F%2Fs3.us-east-1.amazonaws.com%2Fpocket-curatedcorpusapi-prod-images%2F72e99f36-fc54-4eda-809a-6a0964121bf7.jpeg",
      format: "jpeg",
      width: 500,
      height: 500,
      cropX: 0,
      cropY: 0,
      caption: {
        blocks: [
          { text: 'Second colorful image'},
          { text: 'Newline once again'}
        ],
        entityMap: {}
      }
    },
    // {
    //   bucket: "ash_dev__media",
    //   contentType: "image/jpeg",
    //   crc32c: "ln0NrA==",
    //   cropX: 0,
    //   cropY: 0,
    //   etag: "CPLR+Z/b9fkCEAE=",
    //   format: "jpeg",
    //   generation: "1662108118706418",
    //   height: 1080,
    //   id: "ash_dev__media/images/stars_sky_31563b9b-fcc0-4f29-aa32-572c042e9397.jpeg/1662108118706418",
    //   kind: "storage#object",
    //   md5Hash: "GdAv5FCU8mjYXUZrwbaNyw==",
    //   mediaHost: "gcs",
    //   caption: convertToRaw(
    //     ContentState.createFromText("Lorem ipsum dolor sit amet")
    //   ),
    //   mediaLink:
    //     "https://storage.googleapis.com/download/storage/v1/b/ash_dev__media/o/images%2Fstars_sky_31563b9b-fcc0-4f29-aa32-572c042e9397.jpeg?generation=1662108118706418&alt=media",
    //   metageneration: "1",
    //   name: "images/stars_sky_31563b9b-fcc0-4f29-aa32-572c042e9397.jpeg",
    //   originalFilename: "stars_sky.jpg",
    //   publicUrl:
    //     "https://storage.googleapis.com/ved_dev__media/images/abrar_burk_profile_006ff136-231b-48ed-ba58-0bf834554aab.jpeg",
    //   selfLink:
    //     "https://www.googleapis.com/storage/v1/b/ash_dev__media/o/images%2Fstars_sky_31563b9b-fcc0-4f29-aa32-572c042e9397.jpeg",
    //   size: 150481,
    //   storageClass: "STANDARD",
    //   timeCreated: "2022-09-02T08:41:58.708Z",
    //   timeStorageClassUpdated: "2022-09-02T08:41:58.708Z",
    //   updated: "2022-09-02T08:41:58.708Z",
    //   width: 1920,
    // }
   
  ];