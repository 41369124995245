import React from "react";
import {
  getBreakPoint,
  OKELink,
  Accordion,
  useScreenWidth,
  SANS_4_5,
} from "oolib";

import config from "./config";
import { stitchLink } from "../../../../utils/general";
import { isEmpty } from "lodash";

const getMaxCount = (data) => {
  if (isEmpty(data)) return;

  let max = data[0].count;

  for (let i of data) {
    max = Math.max(max, i.count);
  }

  return max;
};

const WordCloud = ({ data, onClick, id, title, linkConfig, toolTipInfo }) => {
  const screenWidth = useScreenWidth();

  const deviceSize = screenWidth >= getBreakPoint("sm") ? "lg" : "sm";

  // return null

  const minFontSize = config[deviceSize].minFontSize;
  const maxFontSize = config[deviceSize].maxFontSize;

  const padding = config[deviceSize].padding;

  const maxCount = getMaxCount(data);

  const perUnit = maxCount ? (maxFontSize - minFontSize) / maxCount : 0;

  return (
    <div
      style={{
        backgroundColor: "white",
        boxShadow: "inset 0 -2px 0 #ebebeb",
      }}
    >
      <Accordion
        id={"wordCloud"}
        title={title || "Word Cloud"}
        expand={
          !localStorage.getItem("analysisDb_wordCloud")
            ? true
            : localStorage.getItem("analysisDb_wordCloud") === "true"
        }
        setExpand={(bool) => {
          localStorage.setItem("analysisDb_wordCloud", bool);
        }}
        infoTooltip={toolTipInfo}
        HeaderStyle={"HeaderStyle3"}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              gap: "1rem",
              padding: "20px",
            }}
            id="wordCloud"
          >
            {isEmpty(data) ? (
              <SANS_4_5>No Data</SANS_4_5>
            ) : (
              data.map((d) => (
                <div
                  className="SANS_4_5"
                  style={{
                    padding: `${padding}px`,
                    height: "fit-content",
                    fontSize: `${minFontSize + perUnit * d.count}px`,
                  }}
                >
                  {onClick || linkConfig ? (
                    <OKELink
                      disabled={d.count ? false : true}
                      onClick={
                        onClick
                          ? () =>
                              onClick(id, {
                                display: d.display,
                                value: d.tagId || d.value,
                              })
                          : undefined
                      }
                      to={linkConfig ? stitchLink(d, linkConfig) : undefined}
                      invertUnderline
                    >
                      {d.display}
                      <sub>{d.count}</sub>
                    </OKELink>
                  ) : (
                    <span>
                      {d.display}
                      <sub>{d.count}</sub>
                    </span>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default WordCloud;
