import React, {useState, useEffect} from 'react'


const CacheStatus = props => {


    const cacheGetTest = async (cacheKey, urlToMatch) => {

        let cacheCollection = await caches.open(cacheKey) 
        
        let allCache = await cacheCollection.matchAll()
        console.log({allCache, urlToMatch})
        let match = allCache.find(response => {
            return response.url.includes(urlToMatch.url)
        })
        if(!match) {
            setCacheStatus({urlToMatch, status: 'notCached'});
            return;
        }
        //else
        setCacheStatus({
            urlToMatch,
            status: 'cached',
            cacheDate: (match.headers.get('date'))
        })
        
    }


    const [cacheStatus, setCacheStatus] = useState(undefined);

    useEffect(() => {
        /**
         * commented for now.
         * getting an 'operation is insecure' error on caches.open...
         */
        cacheGetTest(
            'networkFirst_apis_v1', 
            { url: '/api/platformConfigs' }
        )
    },[])

    console.log({cacheStatus})

    return (
        <div/>
    )

}

export default CacheStatus;