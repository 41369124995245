import editOnCut from "draft-js/lib/editOnCut";
import { retainAnnoChecker } from "../../../KPRichInput/annotation/functions/helpers";


export const handleCutText = ({e, editor, setRetainAnnoOnPaste}) => {
    const res = retainAnnoChecker(editor._latestEditorState);

    editOnCut(editor, e);

    // only if , entity type = ANNO
    if (!res) {
      editor.setClipboard(null);
    }
    setRetainAnnoOnPaste(true);
}