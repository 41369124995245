import styled from "styled-components";
import { colors } from "oolib";

const {greyColor15, greyColor100, greyColor40, white} = colors;

const titleSubTitleStyling = ({ invert, theme : {colors} }) => `
& .StyledEditor {
  color: ${invert ? white : greyColor100};
}
& .StyledEditor span {
  font-weight: normal;
}
& .StyledEditor .public-DraftEditorPlaceholder-root{ 
  color: ${invert ? colors.primaryColor40 : colors.primaryColor100} !important;
}
`;

export const StyledTitleInputWrapper = styled.div`
  ${(props) => titleSubTitleStyling(props)}
`;

export const StyledSubtitleInputWrapper = styled.div`
  ${(props) => titleSubTitleStyling(props)}
  
  /* .StyledEditor{
    color: ${greyColor40};
  } */
`;

export const StyledSummaryInputWrapper = styled.div`
  &::after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid ${greyColor15};
    padding-top: 2rem;
  }
`;