import {
  convertFromRaw,
  EditorState,
  RichUtils,
} from "draft-js";
import { cloneDeep, isArray, mergeWith } from "lodash";
import { segrigateDocs } from "../../../../../utils/general";

const getChange = (add_sub, prevD, newD, key) => {
  let X = add_sub === "add" ? newD : prevD;
  let Y = add_sub === "add" ? prevD : newD;
  let delta = [];
  X.map((d) => {
    let existsInOther = Y.some((p) => p[key] === d[key]);
    if (!existsInOther) delta.push(d);
  });
  return delta;
};

export const _carefullyMergeRawAnnosIntoSynth = ({
  value,
  prevObj,
  // newObj: newObjProp,
  thisBlockValuePath,
}) => {
  /**
   * consolidateAnnos means, each individual anno is pulled out from entityMap of this RTE
   * and grouped together by tagId
   */
  let valueAsEditorState = EditorState.createWithContent(convertFromRaw(value));
  let consolidatedAnnos = {
    tags: _consolidateAnnos({
      editorState: valueAsEditorState,
      thisBlockValuePath,
    }),
  };

  //if new is empty obj return it
  //if new.tags is empty obj return it
  //else
  //cycle through each tag.tagtype key
  //get this value for tagtype & prev value for tag type

  let newObj = cloneDeep(consolidatedAnnos);
  /**
   * newObj no matter what will at least be {tags: {}}
   * if its not, then something is wrong in code elsewhere
   */

  /**
   * if a change has happened in an RTE that has annotation enabled, but has no annotations
   * then this is the response returned by _consolidateAnnos, and passed into this function.
   * i.e {tags: {}}
   */
  if (Object.values(newObj.tags).length === 0) {
    if (!prevObj?.tags || Object.keys(prevObj.tags).length === 0) {
      return newObj;
    }
    // else
    // remove all fragments that come from this valuepath
    let modifiedPrevObj = cloneDeep(prevObj);
    Object.keys(prevObj.tags).forEach((tagTypeKey) => {
      let newTagTypeObj = prevObj.tags[tagTypeKey];
      let prevTagTypeObj = modifiedPrevObj.tags[tagTypeKey];
      prevTagTypeObj.data = newTagTypeObj.data.map((tagIdObj) => {
        let newTagIdObj = cloneDeep(tagIdObj);
        newTagIdObj.fragments = newTagIdObj.fragments.filter((frag) => {
          return frag.extractedFromValuePath === undefined
            ? false //backwards compat, since previously we didnt have extractedFromValuePath
            : frag.extractedFromValuePath !== thisBlockValuePath;
        });
        return newTagIdObj;
      });
      // if this results in empty tagTypeObj.data, then remove that tagtype obj itself
      prevTagTypeObj.data = prevTagTypeObj.data.filter(
        (d) => d.fragments.length !== 0
      );
    });

    return modifiedPrevObj;
  }
  //else


  /**
   * say newObj has 1 tagType 'tagType1'
   * say prevObj has 1 tagType 'tagType2'
   * 
   * then we need to ensure that the 'tagType2' data is carried forward in the final data 
   * that is returned.
   * 
   * this ensures that
   */
  const carryForwardPrevTagTypes = !prevObj || Object.keys(prevObj.tags).length === 0 
  ? []
  : Object.keys(prevObj.tags).filter(tagTypeKey => !Object.keys(newObj.tags).includes(tagTypeKey) )

  let carryForwardPrevTagTypesData = {};
  carryForwardPrevTagTypes.forEach(tagTypeId => {
    carryForwardPrevTagTypesData[tagTypeId] = prevObj.tags[tagTypeId]
  })

  //--


  Object.keys(newObj.tags).forEach((tagTypeKey) => {
    let newTagTypeObj = newObj.tags[tagTypeKey]; //e.g attributes
    let prevTagTypeObj = prevObj?.tags[tagTypeKey]; //e.g attributes

    if (prevTagTypeObj && prevTagTypeObj.data?.length > 0) {
      //find what new tags have been added;
      let addTagIdObjs = getChange(
        "add",
        prevTagTypeObj.data,
        newTagTypeObj.data,
        "tagId"
      );
      //find those that have been subtracted
      let subTagIdObjs = getChange(
        "sub",
        prevTagTypeObj.data,
        newTagTypeObj.data,
        "tagId"
      );

      /**
       * by doing this, we ensure only those tagIds are subtracted
       * THAT CONTAIN ALL FRAGMENTS THAT CAME FROM THIS BLOCK
       */
      subTagIdObjs = subTagIdObjs.filter((d) =>
        d.fragments.every((frag) => {
          return frag.extractedFromValuePath === undefined
            ? true //backwards compat, since previously we didnt have extractedFromValuePath
            : frag.extractedFromValuePath === thisBlockValuePath;
        })
      );

      

      //in the prev - subtracted
      let carryForwardTagIdObjs = prevTagTypeObj.data
        .filter((d) => subTagIdObjs.every((dd) => dd.tagId !== d.tagId))
        .map((prev) => {
          // --- go to each prev tag.fragments
          let prevFragments = prev.fragments || [];
          let newFragments =
            newTagTypeObj.data.find((d) => d.tagId === prev.tagId)?.fragments ||
            [];
          // --- find added fragments
          // --- find subtracted fragments
          let addFrags = getChange("add", prevFragments, newFragments, "key");
          let subFrags = getChange("sub", prevFragments, newFragments, "key");

          /**
           * By doing this we ensure that only those fragments are subtracted
           * THAT CAME FROM THIS BLOCK
           */
          subFrags = subFrags.filter((frag) => {
            return frag.extractedFromValuePath === undefined
              ? true //backwards compat, since previously we didnt have extractedFromValuePath
              : frag.extractedFromValuePath === thisBlockValuePath;
          });

          let carryForwardFrags = prevFragments
            .filter((d) => subFrags.every((dd) => dd.key !== d.key))
            .map((prevFrag) => {
              // -------- retain new text, get other properties from prev
              // not sure why we do this...
              let newFragText = newFragments.find(
                (d) => d.key === prevFrag.key
              )?.text;
              return {
                ...prevFrag,
                text: newFragText || prevFrag.text, //if the new fragments are coming from a different RTE, then newFragText for an existing fragment will not be found. in which case retain prevFrag text.
              };
            });
          // --- create new fragment array [ (prev - sub), ...add ]
          let newFragsAry = [...carryForwardFrags, ...addFrags];
          return {
            ...prev,
            fragments: newFragsAry,
          };
        });
      // create new tagIdObj array [(prev-sub), ...add]
      let newTagIdObjsAry = [...carryForwardTagIdObjs, ...addTagIdObjs];

      newObj.tags[tagTypeKey].data = newTagIdObjsAry;
    }

  })
  return {
    ...newObj,
    tags: {
      ...newObj.tags,
      ...carryForwardPrevTagTypesData
    }
  }
}


// helper to insertAnno
export const _insertOverlayAnnoValues = ({
  prevVal,
  newVal,
  newFragmentKey,
  insertNewValAt,
}) => {
  if (insertNewValAt === "start") {
    return [
      {
        ...newVal,
        fragmentKey: newFragmentKey,
      },
      ...(prevVal || []),
    ];
  } else {
    return [
      ...(prevVal || []),
      {
        ...newVal,
        fragmentKey: newFragmentKey,
      },
    ];
  }
};

// helper to insertAnno
export const _createAndInsertEntityIntoEditorState = ({
  editorState,
  value,
  selection,
}) => {
  let contentStateWithEntity = editorState
    .getCurrentContent()
    .createEntity("ANNO", "MUTABLE", value);

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  let newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });

  newEditorState = RichUtils.toggleLink(newEditorState, selection, entityKey);

  return newEditorState;
};

// helper to insertAnno
export const _getBlocksWithSelection = ({ blocks, selectionState }) => {
  let selectionStartBlockKey = selectionState.getStartKey();
  let selectionEndBlockKey = selectionState.getEndKey();
  var lastWasEnd = false;

  let blocksWithSelection = blocks
    .skipUntil(function (block) {
      return block.getKey() === selectionStartBlockKey;
    })
    .takeUntil(function (block) {
      var result = lastWasEnd;

      if (block.getKey() === selectionEndBlockKey) {
        lastWasEnd = true;
      }

      return result;
    });

  return blocksWithSelection;
};

// helper to insertAnno
export const _getSelectionStartEndOffset = ({
  blocksWithSelection,
  selectionState,
}) => {
  let selectionStartBlockKey = selectionState.getStartKey();
  let selectionEndBlockKey = selectionState.getEndKey();

  let toReturn = [];

  blocksWithSelection.map(function (block) {
    var key = block.getKey();
    var text = block.getText();

    var start = 0;
    var end = text.length;

    if (key === selectionStartBlockKey) {
      start = selectionState.getStartOffset();
    }
    if (key === selectionEndBlockKey) {
      end = selectionState.getEndOffset();
    }

    toReturn.push({ start, end, block });
  });

  return toReturn;
};

//helper to genAnnoDecorator
export const _findAnnoEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "ANNO"
    );
  }, callback);
};

//helper to _consolidateAnnos
// ...mergeWith(cloneDeep(newVal), prevVal, _mergeCustomizer),
const _mergeCustomizer = (objValue, srcValue) => {
  if (isArray(objValue)) {
    let concated = objValue.concat(srcValue);
    let seg = segrigateDocs(concated, ["tagId", "key"]);

    return Object.values(seg).map((ary) =>
      mergeWith(...[...ary, _mergeCustomizer])
    );
    /**
     * basically,
     * if we find an array to merge, we simply merge them first,
     * then we segrigate as per a unique id that would identify objects
     * that are the same and need to be merged
     * then we merge those 'same objects' recursively,
     * cuz these objects might contain array of objs which need to be handled in the same way
     */
  }
};

//helper to _consolidateAnnos
const getInlineStyleRanges = (block, start, end) => {
  let annoCharacters = block.getCharacterList().slice(start + 1, end + 1); // counting for the starting character here ↓
  let annoInlineStyles = annoCharacters.reduce(
    (styles, ch) => styles.union(ch.getStyle()),
    block.getInlineStyleAt(start)
  );
  let inlineStyleRanges = [];

  for (let inlineStyle of annoInlineStyles) {
    block.findStyleRanges(
      (character) => character.hasStyle(inlineStyle),
      (styleStart, styleEnd) => {
        if (styleStart >= start && styleEnd <= end) {
          inlineStyleRanges.push({
            style: inlineStyle,
            offset: styleStart - start, //offset relative to the anno
            length: styleEnd - styleStart,
          });
        }
      }
    );
  }
  return inlineStyleRanges;
};

export const _consolidateAnnos = ({ editorState, thisBlockValuePath }) => {
  let contentState = editorState.getCurrentContent();
  const dataShape1 = [];
  contentState.getBlockMap().map((block) => {
    //get each entity data
    //and related fragment
    /**
     * [
     *  {
     *    fragid : 123,
     *    subFrag : 'xyz'
     *    tags : {
     *      tag1 : {
     *        colId: tag1,
     *        data : [
     *          {
     *            tagId, _id, display
     *          }
     *        ]
     *      }
     *    }
     *  },
     * *  {
     *    fragid : 456,
     *    subFrag : 'xyz'
     *    tags : {
     *      tag2 : {
     *        colId: tag2,
     *        data : [
     *          {
     *            tagId, _id, display
     *          }
     *        ]
     *      }
     *    }
     *  }
     * ],
     *  [
     *  {
     *    fragid : 123,
     *    subFrag : 'def'
     *    tags : {
     *      tag1 : {
     *        colId: tag1,
     *        data : [
     *          {
     *            tagId, _id, display
     *          }
     *        ]
     *      }
     *    }
     *  }
     * ]
     */

    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === "ANNO"
        );
      },
      (start, end) => {
        let text = block.getText();
        let type = block.getType();
        let blockKey = block.getKey();
        const inlineStyleRanges = getInlineStyleRanges(block, start, end);

        let subFrag = {
          text: text.substring(start, end),
          type,
          blockKey,
          inlineStyleRanges,
        };
        let thisEntityKey = block.getEntityAt(start);
        let relData = cloneDeep(
          contentState.getEntity(thisEntityKey).getData()
        );
        let relDataWithSubFrag = relData.map((d) => ({ ...d, subFrag }));
        dataShape1.push(relDataWithSubFrag);
      }
    );
  });

  //convert to single array, then segrigate

  /**
   *
   * {
   *  123 : [
   *    {
   *    fragid : 123,
   *    subFrag : 'def'
   *    tags : {
   *      tag1 : {
   *        colId: tag1,
   *        data : [
   *          {
   *            tagId, _id, display
   *          }
   *        ]
   *      }
   *    }
   *  },
   * {
   *    fragid : 123,
   *    subFrag : 'xyz'
   *    tags : {
   *      tag1 : {
   *        colId: tag1,
   *        data : [
   *          {
   *            tagId, _id, display
   *          }
   *        ]
   *      }
   *    }
   *  },
   *  ]
   * },
   * 456: [
   *  {
   *    fragid : 456,
   *    subFrag : 'xyz'
   *    tags : {
   *      tag2 : {
   *        colId: tag2,
   *        data : [
   *          {
   *            tagId, _id, display
   *          }
   *        ]
   *      }
   *    }
   *  }
   * ]
   *
   */

  if (dataShape1.length === 0) return {};

  let dataShape1Flattened = dataShape1.reduce((prev, curr) => [
    ...prev,
    ...curr,
  ]);
  let dataShape2 = segrigateDocs(dataShape1Flattened, "fragmentKey");
  /*
   *
   * //merge tag objects, ( .reduce maybe ? ) dont concat array
   * // we need to be able to identify the order in which the subfrags should be merged. some index identifier at the time of creation needed. maybe next to fragmentKey is fragIdx?
   * // but concat subfrag & call it fragment
   * // and merge all array against each property
   * // cycle through each obj ---> each tagTypeValue --> each tag --> and inject fragment + id into fragments Ary
   *
   * [
   *    {
   *    fragid : 123,
   *    fragment : 'defxyz'
   *    tags : {
   *      tag1 : {
   *        colId: tag1,
   *        data : [
   *          {
   *            tagId1, _id, display, fragments : [ { text: 'defxyz', key: 123 } ]
   *          }
   *        ]
   *      }
   *    }
   *  },
   *  {
   *    fragid : 456,
   *    fragment : 'xyz'
   *    tags : {
   *      tag1 : {
   *        colId: tag1,
   *        data : [
   *          {
   *            tagId1, _id, display, fragments : [ { text: 'abc', key: 456 } ]
   *          }
   *        ]
   *      },
   *      tag2 : {
   *        colId: tag2,
   *        data : [
   *          {
   *            tagId1, _id, display, fragments : [ { text: 'abc', key: 456 } ]
   *          }
   *        ]
   *      }
   *    }
   *  }
   * ]
   */

  let dataShape3 =
    Object.keys(dataShape2).length > 0 &&
    Object.keys(dataShape2).map((key) => {
      let valueAry = dataShape2[key];

      if (valueAry.length === 1) {
        return {
          ...valueAry[0],
          fragment: [valueAry[0].subFrag], //ary cuz rich text 'blocks' property is an ary
          subFrag: undefined,
        };
      }
      //else
      let segBySubFragBlockKey = segrigateDocs(valueAry, "subFrag.blockKey");

      let fragment = [];
      Object.values(segBySubFragBlockKey).forEach((_ary) => {
        /**
         * reorder the subfrags and make sure they are in the correct order.
         * how are we ensuring this? by getting elements by classname, they are returned in the correct
         * order from left to right (seems like). So we are using that as the basis to put sub frags in correct order
         *
         * however, im beginning to wonder if we even need this. because its possible that,
         * block.findEntityRanges above already returns in the proper left to right order.
         * In which case, we wont even have to run the below function.
         * But this should be carefully examined and tested if we choose to make this change.
         *
         * One possible bug with the approach below is:
         * consider the sentence 'hello hello world'
         * first hello is bold. second is not.
         * 'hello hello ' is 1 annotation
         * 'hello world' is another.
         * with the approach below, my guess is, after the stiching,
         * both 'hello hello ' will become bold. (because innerText is being
         * used to do the sorting, and innerText will be the same for both 'hello 's, and hence
         * only the first bold hello will be found by '.find')
         * But this is too much of an edge case to actually play out.
         */
         
        const ary = _ary
        // const elems = document.getElementsByClassName(_ary[0].fragmentKey)
        // let ary = Object.values(elems).map(el => {
        //   let innerText = el.innerText;
          
        //   return _ary.find(t => t.subFrag.text === innerText)
        // }).filter(Boolean)

        /**
         * Why filter Boolean is important:
         * imagine an annotation spans across 2 draftjs blocks.
         * imagine the segBySubFragBlockKey loop above is on block number 2
         * in such a case, the first item document.getElementsByClassName will return,
         * will be the text in block1.
         * in which case, _ary.find in the first iteration of Object.values(elems).map,
         * will find nothing, cuz _ary is holding the text from the second block.
         * Which would put an 'undefined' element in the array. which causes the subsequent steps to fail
         */

        let merged = { text: "" };
        ary
          .map((d) => d.subFrag)
          .forEach((d, i) => {
            if (i === 0) {
              merged.type = d.type;
              merged.blockKey = d.blockKey;
              merged.inlineStyleRanges = d.inlineStyleRanges;
            }
            merged.text = merged.text + d.text;
          });
        fragment.push(merged);
      });

      return {
        ...valueAry[0],
        fragment,
        // fragment: mergeSubFragsIntoFragment(valueAry)
      };
    });

  dataShape3.forEach((fragmentObj) => {
    fragmentObj.tags &&
      Object.values(fragmentObj.tags).forEach((tagTypeObj) => {
        tagTypeObj.data.map((tagObj) => {
          tagObj.fragments = [
            {
              text: fragmentObj.fragment,
              key: fragmentObj.fragmentKey,
              extractedFromValuePath: thisBlockValuePath,
            },
          ];
        });
      });
  });

  /*
   * //get rid of fragid and fragment. we just want an array of tags objects
   * // _.merge objects arrays should concat unless its the same id, in which case it should merge
   *
   * {
   *    tag1 : {
   *        colId: tag1,
   *        data : [
   *          {
   *            tagId1, _id, display, fragments : [ { text: 'defxyz', key: 123 }, { text: 'xyz', key: 456 } ]
   *          }
   *        ]
   *      },
   * tag2 : {
   *        colId: tag2,
   *        data : [
   *          {
   *            tagId1, _id, display, fragments : [ { text: 'xyz', key: 456 } ]
   *          }
   *        ]
   *      }
   * }
   *
   *  return { tags : *above object* }
   *
   */

  let dataShape3OnlyTags = cloneDeep(dataShape3.map((d) => d.tags));

  let mergeArgs = [...dataShape3OnlyTags, _mergeCustomizer];
  let dataShape4 = mergeWith(...mergeArgs);

  return dataShape4;
};

export const isEndToEndSelection = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  const selectionStartBlock = selectionState.getStartKey();
  const selectionStart = selectionState.getStartOffset();
  const selectionEndBlock = selectionState.getEndKey();
  const selectionEnd = selectionState.getEndOffset();

  const block = contentState.getBlockForKey(selectionStartBlock);

  const entitykey = block.getEntityAt(selectionStart);

  if (entitykey) {
    const entityInstance = contentState.getEntity(entitykey);
    //check if entity type is "ANNO"

    if (entityInstance.getType() === "ANNO") {
      const entityData = entityInstance.getData();

      

      for (let d of entityData) {
        const targetFragmentKey = d.fragmentKey;

        const elems = document.getElementsByClassName(targetFragmentKey);

        if (elems.length > 0) {
          const firstElem = elems[0];
          const lastElem = elems[elems.length - 1];

          const startBlockKey = firstElem.getAttribute("data-blockKey");
          const endBlockKey = lastElem.getAttribute("data-blockKey");

          const startRange = firstElem.getAttribute("data-start");
          const endRange = lastElem.getAttribute("data-end");

          if (
            endBlockKey === selectionEndBlock &&
            startBlockKey === selectionStartBlock &&
            endRange === selectionEnd &&
            startRange === selectionStart
          ) {
            // firstElem.click()

            return { targetFragmentKey, data: d.tags,mode:"edit" };
          }
        }
      }
    }
  }
};

const isAnnoType = (contentState, blockKey, offset) => {
  if (offset < 0) return false;
  const block = contentState.getBlockForKey(blockKey);

  const entitykey = block.getEntityAt(offset);

  if (entitykey) {
    const entityInstance = contentState.getEntity(entitykey);

    if (entityInstance.getType() === "ANNO") {
      return entityInstance.getData();
    }
  }

  return false;
};

export const retainAnnoChecker = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  const startBlockKey = selectionState.getStartKey();
  const start = selectionState.getStartOffset();
  const endBlockKey = selectionState.getEndKey();
  const end = selectionState.getEndOffset();

  const atStart = isAnnoType(contentState, startBlockKey, start);

  if (atStart) {
    if (atStart.length > 1) return false;

    const atStartMinusOne = isAnnoType(contentState, startBlockKey, start - 1);

    if (atStartMinusOne) {
      for (let i of atStartMinusOne) {
        if (atStart[0].fragmentKey === i.fragmentKey) return false;
      }

      //   if (atStart[0].fragmentKey === atStartMinusOne[0].fragmentKey)
      //     return false
      // }
    }
  }

  const atEndMinusOne = isAnnoType(contentState, endBlockKey, end - 1);

  if (atEndMinusOne) {
    if (atEndMinusOne.length > 1) return false;
    const atEnd = isAnnoType(contentState, endBlockKey, end);
    if (atEnd) {
      for (let i of atEnd) {
        if (atEndMinusOne[0].fragmentKey === i.fragmentKey) return false;
      }
    }
    // if (atEnd) {
    //   if (atEndMinusOne[0].fragmentKey === atEnd[0].fragmentKey) return false
    // }
  }
  return true;
};

export const getAllBlocksForFragmentKey = ({ targetFragmentKey }) => {
  const blockKeys = {};

  const elems = document.getElementsByClassName(targetFragmentKey);

  const length = elems.length;

  for (let d = 0; d < length; d++) {
    const blockKey = elems[d].getAttribute("data-blockKey");

    blockKeys[blockKey] = 1;
  }

  return blockKeys;
};

// export const removeAnnotation = (
//   { editorState, targetFragmentKey, onEditorChange },
//   callback
// ) => {
//   const blockKeys = getAllBlocksForFragmentKey({ targetFragmentKey });

//   const editReadyData = [];
//   let i = 0;

//   let contentState = editorState.getCurrentContent();
//   const selectionState = SelectionState.createEmpty();

//   // findEntityRanges gets execute for each entity range for a gi ven block

//   //forEach   Blockkey

//   // get the block key from props

//   for (let key in blockKeys) {
//     const block = contentState.getBlockForKey(key);

//     block.findEntityRanges(
//       (characterdata) => {
//         const entityKey = characterdata.getEntity();

//         if (
//           !entityKey ||
//           contentState.getEntity(entityKey).getType() !== "ANNO"
//         )
//           return false; // plain text

//         const entityInstance = contentState.getEntity(entityKey);

//         const entityData = entityInstance.getData();

//         // loop through each entity data
//         for (let e of entityData) {
//           if (e.fragmentKey === targetFragmentKey) {
//             editReadyData[i] = { data: entityData, entityKey, blockKey: key };

//             // the moment a match is found that means i can add the whole entityData to my editReadyData
//             // and return true

//             return true;
//           }
//         }

//         // if return true the below callback will execute with entity start and end
//         return false; // if return false the below callback will not execute
//       },
//       (start, end) => {
//         editReadyData[i++].range = { start, end };
//         // currently using this entity range to create selection state to remove the entity range for this selection
//         // see handleRemoveAllTags
//       }
//     );
//   }

//   editReadyData.forEach((d) => {
//     const oldData = d.data;

//     // for current entity data filter out all items that does not have a matching fragment key

//     const filterData = oldData.filter(
//       (d) => d.fragmentKey !== targetFragmentKey
//     );

//     // if filterData is empty that, entity range for this can be safely removed
//     // else if filterData is not empty then simple replace the entity data for the entity key

//     if (filterData.length === 0) {
//       const range = d.range;
//       const updatedSelection = selectionState.merge({
//         focusOffset: range.end,
//         anchorOffset: range.start,
//         isBackward: false,
//         focusKey: d.blockKey,
//         anchorKey: d.blockKey,
//       });

//       // from docs: not deprecated
//       contentState = Modifier.applyEntity(contentState, updatedSelection, null);
//     } else {
//       const newData = [...filterData];

//       contentState.replaceEntityData(d.entityKey, newData);
//     }
//   });

//   const newEditorState = EditorState.set(editorState, {
//     currentContent: contentState,
//   });

//   onEditorChange(null, newEditorState);

//   callback();
// };

// export const editAnnotation = (
//   { editorState, targetFragmentKey, onEditorChange, data },
//   callback
// ) => {
//   const blockKeys = getAllBlocksForFragmentKey({ targetFragmentKey });

//   const editReadyData = [];
//   let i = 0;

//   let contentState = editorState.getCurrentContent();

//   // findEntityRanges gets execute for each entity range for a gi ven block

//   //forEach   Blockkey

//   // get the block key from props

//   for (let key in blockKeys) {
//     const block = contentState.getBlockForKey(key);

//     block.findEntityRanges(
//       (characterdata) => {
//         const entityKey = characterdata.getEntity();

//         if (
//           !entityKey ||
//           contentState.getEntity(entityKey).getType() !== "ANNO"
//         )
//           return false; // plain text

//         const entityInstance = contentState.getEntity(entityKey);

//         const entityData = entityInstance.getData();

//         // loop through each entity data
//         for (let e of entityData) {
//           if (e.fragmentKey === targetFragmentKey) {
//             editReadyData[i] = { data: entityData, entityKey, blockKey: key };

//             // the moment a match is found that means i can add the whole entityData to my editReadyData
//             // and return true

//             return true;
//           }
//         }

//         // if return true the below callback will execute with entity start and end
//         return false; // if return false the below callback will not execute
//       },
//       (start, end) => {
//         editReadyData[i++].range = { start, end };
//         // currently using this entity range to create selection state to remove the entity range for this selection
//         // see handleRemoveAllTags
//       }
//     );
//   }

//   editReadyData.forEach((d) => {
//     const oldData = d.data;

//     for (let i = 0; i < oldData.length; i++) {
//       if (oldData[i].fragmentKey === targetFragmentKey) {
//         // if a match, just replace the tags object with the new selectedTags data

//         oldData[i].tags = data;
//       }
//     }

//     const newData = [...oldData];

//     //not doing a set editor state
//     // from docs : replaceEntityData modifies the contentState instance, do not returns anything
//     // this will not work when re-render does not happens, so call the editorChange to make sure this contentState gets updated
//     contentState.replaceEntityData(d.entityKey, newData);
//   });

//   const newEditorState = EditorState.set(editorState, {
//     currentContent: contentState,
//   });

//   onEditorChange(null, newEditorState);

//   callback();
// };

export const getEditReadyPayload = ({
  targetFragmentKey,
  contentState,
  tagsData,
}) => {
  const blockKeys = {};

  const elems = document.getElementsByClassName(targetFragmentKey);
  const EditorElem = document.getElementById("main.raw");

  const length = elems.length;

  for (let d = 0; d < length; d++) {
    const blockKey = elems[d].getAttribute("data-blockKey");

    blockKeys[blockKey] = 1;
  }

  const targetElem = elems[length - 1];

  const { top: EditorElemTop } = EditorElem.getBoundingClientRect();
  const { top: targetElemTop } = targetElem.getBoundingClientRect();

  const left = targetElem.offsetLeft;
  const top = targetElemTop - EditorElemTop;
  const height = targetElem.offsetHeight;

  // can be written more nicely
  // this contains list of the entity data who have  fragmentkey === targetFragmentKey
  // data structure : {data:EntityData,range:EntityRange,blockKey:blockKey}
  const editReadyData = [];
  let i = 0;

  // findEntityRanges gets execute for each entity range for a gi ven block

  //forEach   Blockkey

  // get the block key from props

  for (let key in blockKeys) {
    const block = contentState.getBlockForKey(key);

    block.findEntityRanges(
      (characterdata) => {
        const entityKey = characterdata.getEntity();

        if (
          !entityKey ||
          contentState.getEntity(entityKey).getType() !== "ANNO"
        )
          return false; // plain text

        const entityInstance = contentState.getEntity(entityKey);

        const entityData = entityInstance.getData();

        // loop through each entity data
        for (let e of entityData) {
          if (e.fragmentKey === targetFragmentKey) {
            editReadyData[i] = { data: entityData, entityKey, blockKey: key };

            // the moment a match is found that means i can add the whole entityData to my editReadyData
            // and return true

            return true;
          }
        }

        // if return true the below callback will execute with entity start and end
        return false; // if return false the below callback will not execute
      },
      (start, end) => {
        editReadyData[i++].range = { start, end };
        // currently using this entity range to create selection state to remove the entity range for this selection
        // see handleRemoveAllTags
      }
    );
  }

  // set the payload

  // const payload = {
  //   data: {
  //     targetFragmentKey,
  //     mode: "display",
  //     data: data[0].tags,
  //   },
  //   position: { x:left, y: top + height + 2 },

  //   // data: data[0].tags,
  //   // position: { left, top: top + height + 2 },
  //   // // position: targetElem.getBoundingClientRect(),

  //   // targetFragmentKey,
  //   // mode: "display",
  // };

  const payload = {
    data: {
      mode: "edit",
      tags: tagsData,
      targetFragmentKey,
    },

    position: { x: left, y: top + height + 2 },
  };

  //   data: {
  //     position: { left, top: top + height + 2 }, // fix this 2px border
  //     tags: tagsData,
  //     editReadyData,
  //     targetFragmentKey,
  //   },
  //   mode: "edit",
  // };

  // dispatch view tags with payload

  return payload;
};
