import { motion } from "framer-motion";
import { icons } from "oolib";
import React from "react";
const { CaretDown } = icons;

export const RotatingCaret = ({ position = "down", opacity = 1 }) => {
  return (
    <motion.div
      style={{
        display:
          "flex" /**this helps to align the Caret properly within this div */,
      }}
      initial={{ opacity: 1, rotate: position === "up" ? -180 : 0 }}
      animate={{ opacity, rotate: position === "up" ? -180 : 0 }}
      
      transition={{ type: "tween", ease: "easeOut" }}
    >
      <CaretDown size={12} weight="bold" />
    </motion.div>
  );
};
