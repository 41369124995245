import { useEffect, useState } from "react";

export const useGetCursorPosRelativeToEditor = ({
  EditorRef
}) => {
  const [pos, setPos] = useState(undefined);

  /**
   * a detailed explanation of the wierdness of getting 'cursor position' on 
   * paste, has been described in the useEffect below, but a couple of things i
   * want to mention here because, getSelection().getRangeAt(0).getBoundingClientRect()
   * still doesnt work very well. Alternates are:
   * - window.getSelection().anchorNode.getBounding... 
   *   problem here is, sometimes the anchor node is #text, 
   *   which doesnt have boundingClient... fn. In such a case, anchorNode.parentNode 
   *   would have to be used. Which is sometimes a div, sometimes a span. Confusing stuff
   *   needs to be explored.
   * Also could consider simply passing EditorRef into PastedLinkToEmbedLightbox
   * and doing all these complex calcs in the function inside.
   */
  const cursorPos= window.getSelection().rangeCount !== 0 && 
    window.getSelection().getRangeAt(0).getBoundingClientRect()
  
  useEffect(() => {
    /**
     * this part may seem a little wierd. so explanation:
     * when text is pasted in the editor a few strange things happen:
     * if there was no text previously in that line, then immediately after the paste,
     * the boundingClient of the window.getSelection().getRangeAt(0) returns
     * 0 for all properties. However, this somehow quickly gets updated in the RichTextEditor
     * to the actual proper values. We dont know why.
     *
     * Hence the logic below, in order to correctly place the lightbox
     * AND not have it move on cursor position change is:
     * 'if pos is not defined ONLY THEN try to set pos.'
     * 'And while going through this process of settings pos,
     * if you find that cursorPos.left (or any other property for that matter),
     * is 0, then don't even try setting pos.'
     */

    const editorRefBounds= EditorRef.current.getBoundingClientRect()

    if (!pos) {
      const { left, bottom } = cursorPos;
      const absLeft = left - editorRefBounds.left;
      const absTop = bottom - editorRefBounds.top;
      if (cursorPos.left === 0) return;
      setPos({
        left: `${absLeft}px`,
        top: `${absTop}px`,
      });
    }
  }, [cursorPos]);

  return {pos}
}
