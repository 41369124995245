
import { queryClient } from "../../../../../../.."
import { __CheckAccessViaConfig } from "../../../../../../../utils/accessControl"
import { getViewableOptionsBySegment } from "../../utils/getViewableOptionsBySegment"
import { getViewableProfileTypeOptions } from "../../utils/getViewableProfileTypeOptions"

export const genLinksConfig = (_Nav) => {
    
    const {user} = queryClient.getQueryData('userData') || {}
    const secFolder = _Nav.secondary?.folder || _Nav.secondary?.menu
    return secFolder?.reduce(
    (acc, item) => {

      switch(true){
        case item.preset === "collections":
        return [
          ...acc,
          ...getViewableOptionsBySegment({segment: 'collections'})
        ]
        
        case item.preset === "staticPages":
          return [
            ...acc,
            ...getViewableOptionsBySegment({segment: 'staticPages'})
          ]
        
        case item.preset === "publishing":
          return [
            ...acc,
            ...getViewableOptionsBySegment({segment: 'publishing'})
          ]
        
        case item.preset === "profileTypes":
          return [
            ...acc,
            ...getViewableProfileTypeOptions()
          ]

        default: //item is obj with to display and accessControl(optional)
        let itemIsAccessible = item.accessControl 
          ? __CheckAccessViaConfig({...item.accessControl, user})
          : true
        return itemIsAccessible 
          ? [ ...acc, item ]
          : acc
      }
    },
    []
  )}