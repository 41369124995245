import { SANS_2, SANS_3, colors, icons } from 'oolib'
import styled from 'styled-components';

const { primaryColor40, greyColor100, greyColor40, greyColor80} = colors;

const getColorBasedOnTplActions = ({ tplActions }) => ( tplActions ? primaryColor40 : greyColor100 );
const getHoverColorBasedOnTplActions = ({tplActions}) => ( tplActions ? greyColor40 : greyColor80 );

const baseStyles = {
  cursor: 'pointer',
  color: getColorBasedOnTplActions,
};

const hoverStyles = {
  color: getHoverColorBasedOnTplActions,
};

export const StyledBtnWrapper = styled.div`
  ${baseStyles}

  &:hover {
    & > * {
      ${hoverStyles}
    }
  }
`

export const StyledIconWrapper = styled.div`
    ${baseStyles}

    &:hover {
      ${hoverStyles}
    }
`;

export const StyledReactionText = styled(SANS_3)`
    ${baseStyles}

    &:hover {
      ${hoverStyles}
    }
`

export const StyledReactionText2 = styled(SANS_2)`
    ${baseStyles}

    &:hover {
      ${hoverStyles}
    }
`

export const StyledDivider = styled.div`
    width: 2px;
    height: 15px;
`

