import { css } from "styled-components";

export const tableThemeConfig = {
  table: "PlaygroundEditorTheme__table",
  tableAddColumns: "PlaygroundEditorTheme__tableAddColumns",
  tableAddRows: "PlaygroundEditorTheme__tableAddRows",
  tableCell: "PlaygroundEditorTheme__tableCell",
  tableCellActionButton: "PlaygroundEditorTheme__tableCellActionButton",
  tableCellActionButtonContainer:
    "PlaygroundEditorTheme__tableCellActionButtonContainer",
  tableCellEditing: "PlaygroundEditorTheme__tableCellEditing",
  tableCellHeader: "PlaygroundEditorTheme__tableCellHeader",
  tableCellPrimarySelected: "PlaygroundEditorTheme__tableCellPrimarySelected",
  tableCellResizer: "PlaygroundEditorTheme__tableCellResizer",
  tableCellSelected: "PlaygroundEditorTheme__tableCellSelected",
  tableCellSortedIndicator: "PlaygroundEditorTheme__tableCellSortedIndicator",
  tableResizeRuler: "PlaygroundEditorTheme__tableCellResizeRuler",
  tableSelected: "PlaygroundEditorTheme__tableSelected",
  tableSelection: "PlaygroundEditorTheme__tableSelection",
};

export const tableThemeCSS = css`
  .PlaygroundEditorTheme__table {
    border-collapse: collapse;
    border-spacing: 0;
    overflow-y: scroll;
    overflow-x: scroll;
    table-layout: fixed;
    width: 100%; // table takes entire space available
    margin: 30px 0;
  }
  .PlaygroundEditorTheme__tableSelection *::selection {
    background-color: transparent;
  }
  .PlaygroundEditorTheme__tableSelected {
    outline: 2px solid rgb(60, 132, 244);
  }
  .PlaygroundEditorTheme__tableCell { // indivual cell
    border: 1px solid #bbb;
    /* width: 150px; */
    height: 40px; // when new row is added height should be consistent 
    /* min-width: 75px; */
    vertical-align: top;
    text-align: start;
    padding: 6px 8px;
    position: relative;
    outline: none;
  }
  .PlaygroundEditorTheme__tableCellSortedIndicator {
    display: block;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #999;
  }
  .PlaygroundEditorTheme__tableCellResizer {
    position: absolute;
    right: -4px;
    height: 100%;
    width: 8px;
    cursor: ew-resize;
    z-index: 10;
    top: 0;
  }
  .PlaygroundEditorTheme__tableCellHeader {
    background-color: #f2f3f5;
    text-align: start;
  }
  .PlaygroundEditorTheme__tableCellSelected {
    background-color: #c9dbf0;
  }
  .PlaygroundEditorTheme__tableCellPrimarySelected {
    border: 2px solid rgb(60, 132, 244);
    display: block;
    height: calc(100% - 2px);
    position: absolute;
    width: calc(100% - 2px);
    left: -1px;
    top: -1px;
    z-index: 2;
  }
  .PlaygroundEditorTheme__tableCellEditing {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    border-radius: 3px;
  }
  .PlaygroundEditorTheme__tableAddColumns {
    position: absolute;
    top: 0;
    width: 20px;
    background-color: #eee;
    height: 100%;
    right: 0;
    animation: table-controls 0.2s ease;
    border: 0;
    cursor: pointer;
  }
  .PlaygroundEditorTheme__tableAddColumns:after {
    background-image: url(../images/icons/plus.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }
  .PlaygroundEditorTheme__tableAddColumns:hover {
    background-color: #c9dbf0;
  }
  .PlaygroundEditorTheme__tableAddRows {
    position: absolute;
    bottom: -25px;
    width: calc(100% - 25px);
    background-color: #eee;
    height: 20px;
    left: 0;
    animation: table-controls 0.2s ease;
    border: 0;
    cursor: pointer;
  }
  .PlaygroundEditorTheme__tableAddRows:after {
    background-image: url(../images/icons/plus.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }
  .PlaygroundEditorTheme__tableAddRows:hover {
    background-color: #c9dbf0;
  }
  @keyframes table-controls {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .PlaygroundEditorTheme__tableCellResizeRuler {
    display: block;
    position: absolute;
    width: 1px;
    background-color: rgb(60, 132, 244);
    height: 100%;
    top: 0;
  }
  .PlaygroundEditorTheme__tableCellActionButtonContainer {
    display: block;
    right: 5px;
    top: 6px;
    position: absolute;
    z-index: 4;
    width: 20px;
    height: 20px;
  }
  .PlaygroundEditorTheme__tableCellActionButton {
    background-color: #eee;
    display: block;
    border: 0;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    color: #222;
    cursor: pointer;
  }
  .PlaygroundEditorTheme__tableCellActionButton:hover {
    background-color: #ddd;
  }

`;
