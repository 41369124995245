import React, { useState, useRef } from "react";
import {
  convertMultiValueToTagsValue,
  convertTagDocsDataToOptions,
  convertTagsValueToMultiValue,
  useGetTypeCategoryAndTags,
} from "../utils";
import Dropdown from "./comps/dropdown";
import { useCreateTagV3 } from "../../../../utils/react-query-hooks/tags";
import { useBannerContext, getKeyCode } from "oolib";
import Checkbox from "./comps/checkbox";

export default function TagsInputMulti(props) {
  const {
    id,
    onChange,
    value: valueProp,
    tagType: tagTypeProp,
    filters: filtersProp,
    allowCreateTag,
    siblingValuePath,
    content,
    postDALFilter, // this is hack config, check getAllUsers in okf-be to learn more


    incExcTagsList,

    // ... can have several other props as well, check the Dropdown component to know which those are.

    //props that probably arent being used properly
    rememberIsUserGenerated,
    optionConfig,
    displayCompStyle,

    /** these have to be controllable ALTHOUGH TagsInputMulti will ALWAYS be isTagsStyle true & isSearchable false
     * but, Dropdowns of optionDataType === 'collectionsData', also use this component, and for dropdowns, these
     * two props have to be controllable
     */
    isSearchable = false,
    isTagsStyle = true,
    invert,
    readOnly,

    variant = "dropdown",
  } = props;


  //Note: in the old comp (TagInputMulti) useMemo was used for options, removed it for now lets see if it cause any performance issue then do it?

  const [createTagText, setCreateTagText] = useState(undefined);
  const filteredOptions = useRef([]);
  const createTag = useCreateTagV3();
  const { SET_INFO_BANNER } = useBannerContext();

  const [showOptionsState, setShowOptionsState] = useState(false);


  const {
    data,
    status,
    tagType,
    tagCategory,
    tagSegment,
    createTagAccess,
  } = useGetTypeCategoryAndTags({
    tagTypeProp,
    filtersProp,
    content,
    siblingValuePath,
    incExcTagsList,
    enableDataFetch: variant === "checkbox" ? true : showOptionsState,
    postDALFilter
  });

  const tagCreateIsEnabled =
    isTagsStyle &&
    allowCreateTag === true &&
    (tagCategory === "tags" || tagSegment === "collections") &&
    createTagAccess;

  const handleOnChange = (k, v) => {
    let forParent = !v //this can happen if 'X' is hit on the tag comp
      ? undefined
      : convertMultiValueToTagsValue(tagType, v);

    onChange && onChange(id, forParent);
  };

  const handleCreateTag = () => {
    createTag.mutate(
      {
        type: tagType,
        display: createTagText,
        isUserGenerated: rememberIsUserGenerated !== false ? true : undefined,
        tagCategory,
      },
      {
        onSuccess: (res) => {
          const v = convertTagDocsDataToOptions(
            [res.data],
            tagCategory,
            optionConfig
          );
          const prevValue = value ? value : [];

          handleOnChange(id, [...prevValue, ...v]);
          setCreateTagText(undefined);
        },
        onError: ({ err }) => {
          SET_INFO_BANNER(err.response?.data?.errors[0]?.msg, "red", 3000);
        },
      }
    );
  };

  const handleCreateTagOnKeyDown = (e) => {
    if (!tagCreateIsEnabled || createTag.isLoading) return;
    let code = getKeyCode(e);

    if (
      code === "Enter" &&
      createTagText &&
      filteredOptions.current.every((op) => op.display !== createTagText)
    )
      handleCreateTag();
  };

  const value = convertTagsValueToMultiValue(valueProp, tagCategory);


  

  return variant === "dropdown" ? (
    <Dropdown
    {...props}
      id={id}
      createTagText={createTagText}
      setCreateTagText={setCreateTagText}
      fetchTagStatus={status}
      createTagStatus={createTag.status}
      handleOnChange={handleOnChange}
      value={value}
      options={data}
      handleCreateTag={handleCreateTag}
      readOnly={readOnly}
      invert={invert}
      tagCreateIsEnabled={tagCreateIsEnabled}
      //
      isSearchable={isSearchable}
      isTagsStyle={isTagsStyle}
      displayCompStyle={displayCompStyle}
      optionConfig={optionConfig}
      tagCategory={tagCategory}
      filteredOptions={filteredOptions}
      handleCreateTagOnKeyDown={handleCreateTagOnKeyDown}
      setShowOptionsState={setShowOptionsState}
    />
  ) : (
    <Checkbox
    {...props}
      id={id}
      value={value}
      options={data}
      fetchTagStatus={status}
      handleOnChange={handleOnChange}
      handleCreateTag={handleCreateTag}
      createTagText={createTagText}
      setCreateTagText={setCreateTagText}
      createTagStatus={createTag.status}
      readOnly={readOnly}
      invert={invert}
      tagCreateIsEnabled={tagCreateIsEnabled}
    />
  );
}
