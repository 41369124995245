import {
  Divider,
  getBreakPoint,
  Modal,
  SANS_3,
  useScreenWidth,
} from "oolib";
import React from "react";

import NotificationContent from "../NotificationContent";
import { NotificationBoxStyled, NotificationBoxTitleStyled,NotificationBoxHeaderStyled } from "./styled";
import { _Locale } from "../../../../../../../../locale/Locale";

export default function NotificationBox({
  data,
  title = "Notifications",
  onClick,
}) {
  const screenWidth = useScreenWidth();

  const isMobile = screenWidth <= getBreakPoint("md"); //  create a custom hook?

  return isMobile ? (
    <Modal title={_Locale(title)} onClose={onClick}>
      <NotificationContent data={data} />
    </Modal>
  ) : (
    <NotificationBoxStyled>
      <NotificationBoxHeaderStyled>
      <NotificationBoxTitleStyled>
        <SANS_3 bold capitalize>
        {_Locale(title)}
        </SANS_3>
      </NotificationBoxTitleStyled>
      <div style={{ padding: "0 1rem" }}>
        {" "}
        <Divider />
      </div>
      </NotificationBoxHeaderStyled>
      <NotificationContent onClick={onClick} data={data} />
    </NotificationBoxStyled>
  );
}
