import React from 'react';
import {SANS_2, SANS_3} from 'oolib'

import {createContentCardDate } from '../../../../../../utils/momentManipulate';

const DateDisplay = ({ value, textComp = 'SANS_3', ...textCompProps }) => {

  const TextComps = {
    SANS_3,
    SANS_2
  }
  
  const TextComp = TextComps[textComp]

  const fnOptions = {
    format: 'DD/MM/YYYY',
    switchDisplayFormatAfter: 0 
  }
  
  const date = createContentCardDate(value, null, null, fnOptions);

  return (
    <TextComp {...textCompProps}>{date || ' '}</TextComp>
  )

}



export default DateDisplay;
