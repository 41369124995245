import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
} from "lexical";

import { $createQuoteNode } from "@lexical/rich-text";
import { $setBlocksType } from "@lexical/selection";

export const handleClick = ({ isActive, editor }) => {
  editor.update(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      $setBlocksType(selection, () =>
        isActive ? $createParagraphNode() : $createQuoteNode()
      );
    }
  });
};
