import { CardEmbed, Wrapper700 } from 'oolib';
import React from 'react'



const EmbedCardContent = () => {
  const data = {
    meta: { kp_content_type: { type: "Informational" } },
    title: "Recent Experience of “Rooftop Rainwater Harvesting & Reuse” at Sathopur MS, Nalanda, Bihar",
    desc: 'The annual average rainfall of Nalanda is 1,002.02 mm. But from March-July people are facing acute drinking water problem, i.e. could not able to get water for drinking especially in public institution. So, we took some schools for implementation of “Rooftop Rainwater Harvesting System”.',
    kp_date_published: "2023-01-12T07:10:11.081Z",
    titleOptional: 'This is an optional title',
    main: {
      images: [
        {
          kind: "storage#object",
          id: "wqn_dev__media/images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg/1673507383933894",
          selfLink:
            "https://www.googleapis.com/storage/v1/b/wqn_dev__media/o/images%2Fmountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          mediaLink:
            "https://storage.googleapis.com/download/storage/v1/b/wqn_dev__media/o/images%2Fmountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg?generation=1673507383933894&alt=media",
          name: "images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          bucket: "wqn_dev__media",
          generation: "1673507383933894",
          metageneration: "1",
          contentType: "image/jpeg",
          storageClass: "STANDARD",
          size: 67736,
          md5Hash: "L7+UQq57JsIfoxIy3vAASA==",
          crc32c: "14w7wA==",
          etag: "CMb3l+m8wfwCEAE=",
          timeCreated: "2023-01-12T07:09:43.936Z",
          updated: "2023-01-12T07:09:43.936Z",
          timeStorageClassUpdated: "2023-01-12T07:09:43.936Z",
          originalFilename: "Mountain.png",
          mediaHost: "gcs",
          publicUrl:
            "https://storage.googleapis.com/wqn_dev__media/images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          format: "jpeg",
          width: 1400,
          height: 700,
          cropX: 0,
          cropY: 0,
        },
      ],
      video: [
        {
          kind: "storage#object",
          id: "wqn_dev__media/video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4/1670394807648130",
          selfLink:
            "https://www.googleapis.com/storage/v1/b/wqn_dev__media/o/video%2F%2Fcda36a40-8157-4450-a239-a0f5c09709c4.mp4",
          mediaLink:
            "https://storage.googleapis.com/download/storage/v1/b/wqn_dev__media/o/video%2F%2Fcda36a40-8157-4450-a239-a0f5c09709c4.mp4?generation=1670394807648130&alt=media",
          name: "video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4",
          bucket: "wqn_dev__media",
          generation: "1670394807648130",
          metageneration: "1",
          contentType: "video/mp4",
          storageClass: "STANDARD",
          size: 16422874,
          md5Hash: "8ivmgxqLXSJCEvaygbagaw==",
          crc32c: "EYqPbg==",
          etag: "CIL/k8nx5vsCEAE=",
          timeCreated: "2022-12-07T06:33:27.649Z",
          updated: "2022-12-07T06:33:27.649Z",
          timeStorageClassUpdated: "2022-12-07T06:33:27.649Z",
          mediaHost: "gcs",
          originalFilename: "Screen sharing - 2022-12-07 10_59_42 AM.mp4",
          format: "mp4",
          publicUrl:
            "https://storage.googleapis.com/wqn_dev__media/video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4",
        },
      ],
    },
  };

  const data1 = {
    meta: { kp_content_type: { type: "Informational" } },
    kp_date_published: "2023-01-12T07:10:11.081Z",
    title: "Long Title : Recent Experience of “Rooftop Rainwater Harvesting & Reuse” at Sathopur MS, Nalanda, Bihar Recent Experience of “Rooftop Rainwater Harvesting & Reuse” at Sathopur MS, Nalanda, Bihar",
    desc: "Long desc : The annual average rainfall of Nalanda is 1,002.02 mm. But from March-July people are facing acute drinking water problem, i.e. could not able to get water for drinking especially in public institution. So, we took some schools for implementation of “Rooftop Rainwater Harvesting System”. The annual average rainfall of Nalanda is 1,002.02 mm. But from March-July people are facing acute drinking water problem, i.e. could not able to get water for drinking especially in public institution. So, we took some schools for implementation of “Rooftop Rainwater Harvesting System”",
    titleOptional: 'This is an optional title',
    main: {
      images: [
        {
          kind: "storage#object",
          id: "wqn_dev__media/images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg/1673507383933894",
          selfLink:
            "https://www.googleapis.com/storage/v1/b/wqn_dev__media/o/images%2Fmountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          mediaLink:
            "https://storage.googleapis.com/download/storage/v1/b/wqn_dev__media/o/images%2Fmountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg?generation=1673507383933894&alt=media",
          name: "images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          bucket: "wqn_dev__media",
          generation: "1673507383933894",
          metageneration: "1",
          contentType: "image/jpeg",
          storageClass: "STANDARD",
          size: 67736,
          md5Hash: "L7+UQq57JsIfoxIy3vAASA==",
          crc32c: "14w7wA==",
          etag: "CMb3l+m8wfwCEAE=",
          timeCreated: "2023-01-12T07:09:43.936Z",
          updated: "2023-01-12T07:09:43.936Z",
          timeStorageClassUpdated: "2023-01-12T07:09:43.936Z",
          originalFilename: "Mountain.png",
          mediaHost: "gcs",
          publicUrl:
            "https://storage.googleapis.com/wqn_dev__media/images/mountain_300b74ae-5635-42cb-9cbb-24fbbd5443df.jpeg",
          format: "jpeg",
          width: 1400,
          height: 700,
          cropX: 0,
          cropY: 0,
        },
      ],
      video: [
        {
          kind: "storage#object",
          id: "wqn_dev__media/video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4/1670394807648130",
          selfLink:
            "https://www.googleapis.com/storage/v1/b/wqn_dev__media/o/video%2F%2Fcda36a40-8157-4450-a239-a0f5c09709c4.mp4",
          mediaLink:
            "https://storage.googleapis.com/download/storage/v1/b/wqn_dev__media/o/video%2F%2Fcda36a40-8157-4450-a239-a0f5c09709c4.mp4?generation=1670394807648130&alt=media",
          name: "video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4",
          bucket: "wqn_dev__media",
          generation: "1670394807648130",
          metageneration: "1",
          contentType: "video/mp4",
          storageClass: "STANDARD",
          size: 16422874,
          md5Hash: "8ivmgxqLXSJCEvaygbagaw==",
          crc32c: "EYqPbg==",
          etag: "CIL/k8nx5vsCEAE=",
          timeCreated: "2022-12-07T06:33:27.649Z",
          updated: "2022-12-07T06:33:27.649Z",
          timeStorageClassUpdated: "2022-12-07T06:33:27.649Z",
          mediaHost: "gcs",
          originalFilename: "Screen sharing - 2022-12-07 10_59_42 AM.mp4",
          format: "mp4",
          publicUrl:
            "https://storage.googleapis.com/wqn_dev__media/video//cda36a40-8157-4450-a239-a0f5c09709c4.mp4",
        },
      ],
    },
  };
  return (
    <Wrapper700>
      <CardEmbed 
          data={data}
          to={"/published-listing/stories"}
          config={{
              title: "title",
              desc: "desc",
              optionalTitle: "Lorem ipsum dolor sit amet",
              //   video: "main.video",
              image: ["main.coverImage", "main.images"],
              metaBlock: [
                { key: "text", valuePath: "meta.kp_content_type.type" },
                { key: "date", valuePath: "kp_date_published" },
              ],
            }}/>

      <CardEmbed 
          data={data1}
          status="success"
          to={"https://www.amazon.in/Amozo-Cover-iPhone-Polycarbonate-Transparent/dp/B09J2MM5C6/ref=zg-bs_1389409031_sccl_1/258-5269693-4885515?pd_rd_w=UlqOp&content-id=amzn1.sym.40a67462-811c-4fe6-92cc-c87371e20166&pf_rd_p=40a67462-811c-4fe6-92cc-c87371e20166&pf_rd_r=AKFMCCBHGSJ3RBAY5E10&pd_rd_wg=FKu3T&pd_rd_r=a769f500-3a7d-4dfd-aa06-ae15268e021e&pd_rd_i=B09J2MM5C6&psc=1"}           
          config={{
              title: "title",
              desc: "desc",
              //   video: "main.video",
              video: ["main.video"],
              metaBlock: [
                { key: "text", valuePath: "meta.kp_content_type.type" },
                { key: "date", valuePath: "kp_date_published" },
              ],
            }}/>
      <CardEmbed 
          data={data1}
          status="error"
          to={"https://www.amazon.in/Amozo-Cover-iPhone-Polycarbonate-Transparent/dp/B09J2MM5C6/ref=zg-bs_1389409031_sccl_1/258-5269693-4885515?pd_rd_w=UlqOp&content-id=amzn1.sym.40a67462-811c-4fe6-92cc-c87371e20166&pf_rd_p=40a67462-811c-4fe6-92cc-c87371e20166&pf_rd_r=AKFMCCBHGSJ3RBAY5E10&pd_rd_wg=FKu3T&pd_rd_r=a769f500-3a7d-4dfd-aa06-ae15268e021e&pd_rd_i=B09J2MM5C6&psc=1"}           
          config={{
              title: "title",
              desc: "desc",
              titleOptional: "titleOptional",
              //   video: "main.video",
              video: ["main.video"],
              metaBlock: [
                { key: "name", valuePath: "meta.kp_contributed_by.name" },
                { key: "date", valuePath: "kp_date_published" },
              ],
            }}/>
    </Wrapper700>
  )
}

export {EmbedCardContent} 