import React, { useRef } from "react";
import { ToolbarButton } from "../ToolbarButton";

export const ToolbarUploadButton = ({
  icon,
  isActive,
  onChange,
  accept,
  multiple,
}) => {

  const inputRef = useRef(null);
  
  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        multiple={multiple}
        accept={accept}
        onChange={onChange}
        ref={inputRef}
      />
      <ToolbarButton
        icon={icon}
        isActive={isActive}
        onClick={() => {
          inputRef.current.click();
        }}
      />
    </>
  );
};