/**
 * this is the default card / list style listing config.
 * Needed because, we need it to extract configs for sharesummary and page meta,
 * Usually this would be fetched from that particular content type's tpl.listing.listingConfig
 *
 * BUT if this listingConfig happens to be a tableConfig, then that wont work, because tableConfig
 * has a very different structure. Hence. we need this default for fallback.
 */
export const defaultListingConfigForCardOrList = {
  configs: {
    cardLabel: "$thisContentType",
    title: "main.title",
    video: "main.video",
    image: ["main.coverImage", "main.images"],
    metaBlock: [
      {
        key: "name",
        valuePath: "meta.kp_contributed_by.name",
      },
      {
        key: "date",
        valuePath: "kp_date_published",
      },
    ],
  },
};
