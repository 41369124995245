import { LexicalTypeaheadMenuPlugin } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { colors, globalLightboxStyle } from 'oolib';
import styled from 'styled-components';

export const StyledUserListItem = styled.li`
    cursor: pointer;
    background-color: white;
    /* border: 1px solid ${colors.greyColor3}; */
    border-radius: 6px;
    padding: 5px 8px;
    /* ${globalLightboxStyle} */
    &:hover{
      background-color: ${colors.primaryColor10};
    }
`
export const StyledLexicalTypeaheadMenuPlugin = styled(LexicalTypeaheadMenuPlugin)`
  background-color: red;
  border-radius: 6px;
  /* border: 10px solid yellow; */
  #typeahead-menu{
    background-color: red;

  }
`

export const StyledTypeaheadWrapper = styled.div`
  position: fixed;
  z-index: 10000;
`;

export const StyledTypeahead = styled.div`
  background: white;
  border: 1px solid ${colors.greyColor10};
  border-radius: 6px;
  padding: 4px;
`