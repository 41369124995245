import { colors, transition } from "oolib";
import { css } from "styled-components";

const { yellowHighlight, blueHighlight, pinkHighlight }  = colors;

// export const yellowHighlight = "#FFE600";
// let blueHighlight = "#52E2EE";
// let pinkHighlight = "#FF9AF4";

export const annoThemeConfig = {

    mark: 'annoLex',
//   markOverlap: 'annoLex_overlap',

};

const commonCss = css`
    ${transition('border-color', 'background-color')};
    cursor: ${({ readOnly }) => (readOnly ? "pointer" : "text")};
    border-bottom: 2px solid;
    border-color: ${colors.none};
`


export const annoThemeCSS = css`

/**
the class logic below might be a little confusing to read, so here
is a simplistic explanation of what it does:
- normal tag annotations are in yellow 25% opacity.
- now when these normal yellow annotations overlap,
  the overlapped bit gets a bit darker (you can read this bit below and understand)
- normal yellow annos when hovered also turn to a 75% opacity + underline in blue
- now toggle tag annotations have their own unique colors. for now, quotes are pink & vocabs are blue
- these toggle tag annos when hovered only get a blue underline, no bg color change
- when ANY of these above annos are selected, they are set to a standard blue bg styling
*/
.annoLex {
    ${commonCss}
    background-color: ${yellowHighlight}25;
    padding: 0; //normalize native css

    &.-overlap-depth-2-{
        background-color: ${yellowHighlight}50; //50% opacity
    }

    &.-overlap-depth-3-{
        background-color: ${yellowHighlight}75; //75% opacity
    }

    &.-overlap-depth-4plus-{
        background-color: ${yellowHighlight}; //100% opacity
    }

    &.-is-quotes-{
        background-color: ${pinkHighlight};
    }

    &.-is-vocabs-{
        background-color: ${blueHighlight};
    }

    &.-hovered-:not(.-selected-){
        border-color: ${colors.blue};
    }

    &.-hovered-:not(.-selected-):not(.-is-vocabs-):not(.-is-quotes-){
        background-color: ${yellowHighlight}75; //75% opacity
        border-color: ${colors.blue};
    }

    &.-selected-{
        background-color: ${({ theme }) => theme?.colors.primaryColor40};
        border-color: ${colors.blue};
    }
  }
`;
