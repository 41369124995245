
//tests for all valid urls including data urls
export const isURLRegexTest = (url) => {
    if (
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(
        url
      ) ||
      url.startsWith('data:') || //this is temp. should find a way to integrate this logic into the regex above
      (process.env.NODE_ENV !== 'production' && url.includes('localhost')) 
      //if running code locally, we want localhost urls also to be processed as a valid url
      //(for testing purposes)
    ) {
      return true;
    } else {
      return false;
    }
  };