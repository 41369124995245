import {
  LABEL,
  Modal,
  PaddingBottom20,
  PaddingTopBottom20,
  SANS_3,
  TextInput,
  colors,
  EmptyStates
} from "oolib";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ListingPage from "../../../../../../../../pageTpls/ListingPage";
import { TotalCount } from "../../../../../../../../pageTpls/ListingPage/comps/TotalCount";
import { fixedGetDataConfig } from "../../../../../../../../pageTpls/PublishedListing/config/fixedGetDataConfig";
import { __GetConfigsBySegmentNew } from "../../../../../../../../utils/getters/gettersV2";
import { RTEDefaultInternalEmbedConfig } from "./configs/RTEDefaultInternalEmbedConfig";
import { createLinkPreviewForEmbedType } from "./utils/createLinkPreviewForEmbedType";
import { useGetEmbedTypeFromUrl } from "./utils/useGetEmbedTypeFromUrl";
import { useSearchInputText } from "./utils/useSearchInputText";

const StyledPaddingLeftRight20 = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
`;

const useTotResultsCount = () => {
  const initState = {
    totCount: 0,
    intCount: 0,
    extCount: 0,
    initState: true, //this way we know if the zeros are from initState or actual zeros due to no results
  };

  const [totResults, setTotResults] = useState(initState);
  const resetTotResults = () =>
    JSON.stringify(totResults) !== JSON.stringify(initState) &&
    setTotResults(initState);
  const setExtCount = (extCount) =>
    setTotResults((prev) => ({
      ...prev,
      totCount: prev.intCount + extCount,
      extCount,
      initState: false,
    }));
  const setIntCount = (intCount) =>
    intCount !== totResults.intCount &&
    setTotResults((prev) => ({
      ...prev,
      totCount: prev.extCount + intCount,
      intCount,
      initState: false,
    }));

  return {
    totResults,
    resetTotResults,
    setExtCount,
    setIntCount,
  };
};

export const LinkOrEmbedModalLex = ({ onClose, modalTitle, onSelect }) => {
  const { resetTotResults, totResults, setExtCount, setIntCount } =
    useTotResultsCount();

  const { searchInputText, searchInputTextDebounced, onSearchInputChange } =
    useSearchInputText();

  useEffect(() => {
    if (!searchInputText) resetTotResults();
  }, [searchInputText]);

  const { embedType, loading: loadingEmbedType } =
    useGetEmbedTypeFromUrl(searchInputText);

  useEffect(() => {
    if (embedType) setExtCount(1);
  }, [embedType]);

  const TotalCountComp = () =>
    totResults.totCount ? (
      <div style={{ paddingTop: "2rem" }}>
        <TotalCount totalCount={totResults.totCount || undefined} />
      </div>
    ) : null;

  const PreviewShell = ({ children, gapAfter = true }) => (
    <div
      style={{
        backgroundColor: colors.white,
        marginBottom: gapAfter ? "2rem" : "unset",
      }}
    >
      <PaddingTopBottom20>
        <StyledPaddingLeftRight20>{children}</StyledPaddingLeftRight20>
      </PaddingTopBottom20>
    </div>
  );
  const ExternalLinkPreviewComp = () => (
    <PreviewShell>
      <LABEL>{`External Link${
        totResults.extCount ? ` (${totResults.extCount})` : ""
      }`}</LABEL>
      <PaddingBottom20 />
      {!embedType ? (
        <SANS_3>No Such Link Found</SANS_3>
      ) : (
        <div
          onClick={() =>
            embedType && onSelect({ link: searchInputText, embedType })
          }
        >
          {createLinkPreviewForEmbedType({
            embedType,
            loadingEmbedType,
            urlString: searchInputText,
          })}
        </div>
      )}
    </PreviewShell>
  );

  const InternalLinkPreviewComp = () => (
    <PreviewShell gapAfter={false}>
      <LABEL>{`Platform Content Link${
        totResults.intCount ? ` (${totResults.intCount})` : ""
      }`}</LABEL>
      <PaddingBottom20 />
      {searchInputText && !totResults.initState && !totResults.intCount ? (
        <SANS_3>No Such Content Found</SANS_3>
      ) : (
        <ListingPage
          showCount={false}
          contentTypes={__GetConfigsBySegmentNew("publishing").map(
            (d) => d.kp_content_type
          )}
          configs={{
            getDataConfig: {
              ...fixedGetDataConfig,
              queryOptions: {
                enabled: !!searchInputTextDebounced,
              },
            },
            keywordSearchConfig: {
              fields: ["main.title.autoComplete"],
            },
            listingStyle: "ListStyle",
            listingConfig: {
              ListingWrapper: "div",
              onClick: (e, d) =>
                onSelect({
                  link: `${window.location.protocol}//${window.location.host}/published-page/${d.meta.kp_content_type}?id=${d._id}`,
                  embedType: "internal-link",
                }),
              configs: RTEDefaultInternalEmbedConfig,
            },
          }}
          showSearchInput={false}
          searchTermInputValue={searchInputTextDebounced}
          broadcastTotalCount={setIntCount}
        />
      )}
    </PreviewShell>
  );
  return (
    <Modal desktopWidth="600px" onClose={onClose} title={modalTitle}>
      <div
        onMouseDown={(e) => {
          /**
           * THIS WAS FOR DRAFT JS:
           * this ensures that any 'click' within the modal wont deselect the text in RTE.
           * BUT focus on text input STILL will deselect the text.
           * draft js tho, still remembers the previous selection state,
           * and everything works fine.
           *
           * FOR LEX WE WILL HAVE TO CHECK AND SEE
           */
          e.preventDefault();
        }}
      >
        <PaddingTopBottom20>
          <StyledPaddingLeftRight20>
            <TextInput
              placeholder="Paste link or search pages"
              value={searchInputText}
              onChange={onSearchInputChange}
              clearBtn={{ icon: "X" }}
              icon={"LinkSimple"}
            />
            <TotalCountComp />
          </StyledPaddingLeftRight20>
        </PaddingTopBottom20>

        {searchInputText &&
          (!totResults.initState && totResults.totCount === 0 ? (
            <EmptyStates preset={"searchDashBoardListing"}/>
          ) : (
            <div
              style={{ backgroundColor: colors.greyColor5, padding: "2rem" }}
            >
              <ExternalLinkPreviewComp />
              <InternalLinkPreviewComp />
            </div>
          ))}
      </div>
    </Modal>
  );
};
