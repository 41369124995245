import React from "react";
import { Tooltip, colors, getVal, icons } from "oolib";

import styled from "styled-components";

const { red, green } = colors;
const { CheckCircle, XCircle } = icons;
const StyledEllipsisRemoveWrapper = styled.div`
    h1,h2,h3,h4,h5,h6{
        white-space: normal;
        overflow: unset;
        text-overflow: unset;
    }
`
const RightWrong = ({ value, tableRowData, tooltipContentPath }) => {
  let isRight = value;


  const genTooltip = () => {
    const tooltipText = getVal(tableRowData, tooltipContentPath)?.join("\n");

    return (
      tooltipText && (
        <StyledEllipsisRemoveWrapper>
          <Tooltip
            text={tooltipText}
            position={"right"}
            popOutOfOverflowHiddenParent = {true}
            presetTarget={"infoIcon"}
          />
        </StyledEllipsisRemoveWrapper>
      )
    );
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
      {isRight ? (
        <CheckCircle size={20} color={green} />
      ) : (
        <XCircle size={20} color={red} />
      )}
      {tooltipContentPath && genTooltip()}
    </div>
  );
};

export default RightWrong;
