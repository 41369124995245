
export const _HANDLE_UPDATE_TPL_META = ({ 
    tplMeta,
    setTplConfig,
    SAVE_TPL_CHANGES
}) => {
    // --> { valuePath, value } || [ { valuePath, value } ]
    setTplConfig((prev) => {
      SAVE_TPL_CHANGES({newTplConfig: { ...prev, tplMeta }});
      return { ...prev, tplMeta };
    });
  };