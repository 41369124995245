import { Loader, SANS_3, colors, icons, transition } from "oolib";
import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { useDebounce } from "../../../../../../utils/customHooks";

const hoverCSS = css`
  color: ${({ invert }) => (invert ? colors.greyColor40 : colors.greyColor70)};
`;

const StyledButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${colors.none};
  cursor: pointer;
  color: ${({ invert }) =>
    invert ? colors.primaryColor40 : colors.greyColor100};
  & > * {
    ${transition("color")};
  }

  pointer-events: ${({disabled}) => disabled && 'none'};

  ${({ onTextClick }) =>
    onTextClick //means the icon and the text have separate outcomes on click, hence their hovers need to be disjointed
      ? css`
          & > * {
            &:hover {
              ${hoverCSS}
            }
          }
        `
      : css`
          &:hover {
            & > * {
              ${hoverCSS}
            }
          }
        `}
`;

const TextClickWrapper = styled.div`
  background-color: ${colors.none};
  border: none;
`;

export const ButtonContentEngagement = ({
  icon,
  iconWeight,
  type,
  onClick,
  onTextClick,
  isLoading,
  invert,
  text,
  disabled,// While content is being updated via api user should not be able to click on button to avoid multiple calls
  setShowReactionPanel,
  showReactionPanel,
  reactionsKeys
}) => {

  const Icon = icons[icon];
  const TextWrapper = onTextClick ? TextClickWrapper : Fragment;

  const debounce = useDebounce();

  return (
    <StyledButton onClick={() => onClick('like')} invert={invert} onTextClick={onTextClick} disabled={disabled}>
      {isLoading ? (
          <Loader S invert={invert} />
      ) : (
        <div 
          onMouseEnter={() => { reactionsKeys?.length > 1 && setShowReactionPanel(true);}}
          onMouseLeave={() => { debounce(() =>  (reactionsKeys?.length > 1 && showReactionPanel) && setShowReactionPanel(false), 3000); }}
          onTouchStart={() => { reactionsKeys?.length > 1 && setShowReactionPanel(true); }}
          // onTouchEnd={() => { debounce(() => (reactionsKeys.length > 1 && showReactionPanel) && setShowReactionPanel(false), 3000); }}
        >
        <Icon weight={iconWeight} size={icon === "ThumbsUp" ? 24 : type === "reaction" ? 28 : 24} stroke={colors.primaryColor40} />
        </div>
      )}
      {text && (
        <TextWrapper
          onClick={
            onTextClick
              ? (e) => {
                  e.stopPropagation();
                  onTextClick();
                }
              : () => {}
          }
        >
          <SANS_3 semibold>{text}</SANS_3>
        </TextWrapper>
      )}
    </StyledButton>
  );
};
