import React, { useState, Fragment, useEffect, useRef } from "react";
import { ButtonSecondary, BlockLabel } from 'oolib';
import KPFAQSingleBlock from './KPFAQSingleBlock';
import ContentBlockShell from '../../generalUI/ContentBlockShell';
//utils
import {replaceEntityData} from '../../../utils/richInputUtils';

const KPFAQComp = props => {

  const q_id = 'faq_q'; const a_id = 'faq_a';
  const makeEmptyDataObj = (idx) => { return { [`${q_id}_${idx}`] : null, [`${a_id}_${idx}`] : null } }
  //shape =
  //  [
  //    { faq_q_0 : null, faq_a_0 : null, },
  //    { faq_q_1 : null, faq_a_1 : null, }
  //    ...
  //  ]

  const allowHandleChange = useRef(false);

  const [data, setData] = useState( { val: props.value || [ makeEmptyDataObj(0) ] } )

  const addNewBlock = () => {
    let tempData = data.val;
    let idx = data.val.length;
    tempData.push(makeEmptyDataObj(idx));
    setData({ val : tempData});
  }

  const handleChange = (blockInputStateObj) => {
    if(!allowHandleChange.current) return;
    //else
    let tempData = data.val;
    tempData.map((d,i) => {
      if(Object.keys(d)[0] === Object.keys(blockInputStateObj)[0]){
        tempData.splice(i,1,blockInputStateObj);
      }
    })
    setData({val : tempData});
    props.onChange && props.onChange(props.id, tempData)

    //this only runs if comp is rendered inside rich editor
    

  }

  //hack. otherwise, handle on change fires on mount ( conditional appearing inn events) cuz of the rich editor and overwrites the
  // date component's date value in events template. i think memoization of generate contennt block also has a role to play here.
  useEffect(() => {
    allowHandleChange.current = true;
  }, [])



	return (
		<div onBlur={() => { //if we fire replaceEntityData on every onchange, its causing issues focus-blur. so another temp hack
      if(props.isInEditor === true){
        replaceEntityData(props.editor, props.entityKey, {value: data.val })
      }
    }}>
			<ContentBlockShell
				richInputProps={{
					isInEditor: props.isInEditor,
					editor: props.editor,
					block: props.block,
					onEditorChange: props.onEditorChange,
					handleSetTempReadOnly: props.handleSetTempReadOnly,
				}}
				readOnly={props.readOnly}
				className={`kp-faq-comp ${props.className}`}>
				<BlockLabel
					className='kp-faq-comp-label'
					label={props.label}
					sublabel={props.sublabel}
					isRequired={props.isRequired}
					readOnly={props.readOnly}
				/>
				<div className='kp-faq-blocks-wrapper'>
					{data.val.map((d, i) => (
						<Fragment key={i}>
							<KPFAQSingleBlock
								value={d}
								readOnly={props.readOnly}
								onChange={(blockInputStateObj) =>
									handleChange(blockInputStateObj)
								}
							/>
						</Fragment>
					))}
				</div>
				{!props.readOnly && (
					<div className='add-new-block-btn-wrapper'>
						<ButtonSecondary onClick={addNewBlock} icon='Plus' />
					</div>
				)}
			</ContentBlockShell>
		</div>
	)
}

KPFAQComp.defaultProps = {
  id : 'kp_faq_comp',
  label: 'faqs',//label is optional
  //sublabel is optional
  readOnly: false
}

export default KPFAQComp;
