/**
 * these are replacement configs.
 * it defines, which comp to use INSTEAD of the og comp
 * defined in the tpl.
 *
 * Since tables can use a slightly limited number of comps
 *
 * Replacements can be defined as a simple string as well as {comp: ... , props: {...}}
 * if defined as string, it will be considered as a 'comp'
 */

//deciding whether to set defaultSort to true or no
const setTrueIfNoOtherExplicitDefaultSort = ({ block, tableConfigProp }) =>
  block.comp === "TitleInput" &&
  tableConfigProp.cellData?.every(
    (d) => d.backendSortConfig?.defaultSort !== true
  )
    ? true
    : false;

const genBackendSortConfig = ({ sortPath, defaultSort = false }) => ({
  sort: { [sortPath]: defaultSort ? 1 : undefined },
  defaultSort,
});

export const TPL_TO_CELL_BLOCKS_LOOKUP = ({ block, tableConfigProp }) => {
  switch (block.comp) {
    case "TextInput":
      return {
        comp: "SANS_3",
        backendSortConfig: genBackendSortConfig({
          sortPath: block.valuePath,
        }),
      };

    case "DatePicker":
      return {
        comp: "DateDisplay",
        backendSortConfig: genBackendSortConfig({
          sortPath: block.valuePath
        })
      };

    case "TitleInput":
    case "SubtitleInput":
      return {
        comp: "RichTextAsPlainText",
        backendSortConfig: genBackendSortConfig({
          sortPath: `${block.valuePath}`,
          defaultSort: setTrueIfNoOtherExplicitDefaultSort({
            block,
            tableConfigProp,
          }),
        }),
      };
    
    case "KPRichInput":
    case "RichTextEditor":
      return {
        comp: "RichTextAsPlainText",
        backendSortConfig: genBackendSortConfig({
          sortPath: `${block.valuePath}.blocks.0.text`
        }),
      };
    
    case "SummaryInput":
    case "LexicalTextEditor":
      return {
        comp: "RichTextAsPlainTextLex",
        backendSortConfig: genBackendSortConfig({
          sortPath: `${block.valuePath}.editorState.root.children.0.children.0.text`
        }),
      };  

    case "TagsInputMulti":
    case "TagsInputSingle":
      //all other Tag components should be covered.
      return {
        comp: "TableTags",
        props: { valuePath: "data.display" },
        backendSortConfig: genBackendSortConfig({
          sortPath: `${block.valuePath}.data.0.display`,
        }),
      };

    case "CheckboxList": //new
    case "RadioList": //new
    case "DropdownSingle": //new
    case "DropdownMulti": //new
    case "SwitchDouble":
    case "SwitchSingle":
      let valuePathProp, sortPath;
      if(block.props.saveValueAsString){ // string shape
        valuePathProp = null
        sortPath = block.valuePath
      }
      else{ // display - value shape
        valuePathProp = 'display'
        sortPath = ['DropdownMulti', 'CheckboxList'].includes(block.comp)
          ? `${block.valuePath}.0.display`
          : `${block.valuePath}.display`
      }

      return {
        comp: "TableTags",
        props: { valuePath: valuePathProp },
        backendSortConfig: genBackendSortConfig({
          sortPath
        })
      };

    default:
      return undefined;
  }
};
