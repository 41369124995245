import {
  ContentState, convertFromHTML, EditorState, Modifier, SelectionState
} from 'draft-js';
import { replaceWithFragment } from 'draft-js/lib/DraftModifier';
import { handleDraftEditorPastedText } from 'draftjs-conductor';
import { isURLRegexTest } from '../comps/LinkOrEmbedModal/utils/isURLRegexTest';
import { RTEInsertLink } from '../comps/LinkOrEmbedModal/utils/RTEInsertLink';

const advancedHandlePastedText =({
  text,
  html,
  editorState,
  handleChange,
  charLimit,
  enableConductor,
  editorVariant,
  setShowConvertToEmbedLightbox,
  setRetainAnnoOnPaste,
  retainAnnoOnPaste
})=> {

  // console.log({text, html, editorState, sel: editorState.getSelection()})
  
  const currentChars = editorState.getCurrentContent().getPlainText()

  if (charLimit) {
    if (currentChars.length >= charLimit) return 'handled';

    const totalChars = currentChars + text

    if (totalChars.length > charLimit) {
      const pastedBlocks = ContentState.createFromText(
        text.substring(0, charLimit - currentChars.length)
      ).blockMap

      const newContentState = replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        pastedBlocks
      )

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'insert-fragment'
      )
      handleChange(newEditorState)
      return 'handled'
    }
  }

  /** 
   * if char limit was reached in the above logic, then 
   * the code wouldnt execute to this point.
   * This also means that, in the case of char limit, links & annos
   * will be ignored, because that logic comes below in this function
   * 
   * But since this code is executing, we know char limit wasnt an issue
  */

  //else
  /**
   * note that if 'not-handled' plays out, then in that case also 
   * links are retained, because, not-handled leads to draftjs' default
   * behaviour, which i think, is to retain links.
   */
  if(editorVariant === 'simple') return 'not-handled'

  //else
  // ----- init process of converting link to embed ----- //
  let thisBlockKey = editorState.getSelection().getStartKey()
  let thisContentBlock = editorState.getCurrentContent().getBlockForKey(thisBlockKey)
  
  /**
   * if this block has no other text apart from the one just pasted
   * AND the pasted text is a valid URL then..
   */
  let textInThisLine = thisContentBlock.getText();
  
  
  if(
    !textInThisLine.trim() &&
    isURLRegexTest(text)
  ){
    /**
     * note that in a case like this where the entire line of text
     * in a block is a valid link, then IF the link is also annotated,
     * then the annotation will not be carried forward. Only the link
     * will be, and the option to embed will show up obviously
     * 
     * (sub-point: currently our RTE is not capable of having a link and anno
     * entity on the same piece of text. lol. so what ive mentioned above is 
     * irrelevant for now)
     */

    //first insert text 
    let newContentState = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text
    )
    
    let newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );
    
// handleChange(newEditorState)
    let editorStateWithSelection = EditorState.acceptSelection(
      newEditorState,
      new SelectionState({
        anchorKey: thisBlockKey,
        anchorOffset: 0,
        focusKey: thisBlockKey,
        focusOffset: text.length,
      })
    )
    
    RTEInsertLink({
      url: text,
      editorState: editorStateWithSelection,
      handleChange
    })
    setShowConvertToEmbedLightbox({
      url: text
    })

    return 'handled'
  }
  // ----- end init process of converting link to embed ----- //  
  
  
  
  //else
  /**
   * this had something to do with
   */
  
  if (html) {

    /** commented out cuz it doesnt work. if uncommented in the future
     * care will have to be taken to make sure it works in conjuction with
     * the retain anno bit below
     */
    // preserveHeaderOnLastLineAndListOnFirstLine({
    //   html,
    //   handleChange,
    //   editorState
    // })

    if (!retainAnnoOnPaste) {
      const blocksFromHTML = convertFromHTML(html);

      const pastedTextContentState = ContentState.createFromBlockArray(
        //returns content state
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const fragment = pastedTextContentState.getBlockMap();

      const newContentState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        fragment
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-fragment"
      );
      handleChange(newEditorState);
      return 'handled';
    } else {
      setRetainAnnoOnPaste(false);
    }
    
  }

  /**
   * @note
   * this will not work, since if(html) is already handling things above
   * if we ever need to use conductor, this will have to be reviewed
   */
  if (enableConductor) {
    /*false by default, if true it uses 'draftjs-conductor package to ensure consistency on pasting/copying'
      one problem it solves is copying between editors while retaining empty lines (draftjs remove those) and other promising fixes
      which might prove useful in the future (ref: https://www.npmjs.com/package/draftjs-conductor)
    */
    let newState = handleDraftEditorPastedText(html, editorState)
    
    if (newState) {
      handleChange(newState)
      
      return 'handled'
    }
  }

  return 'not-handled'
}


export default advancedHandlePastedText