import { Loader } from 'oolib'
import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
// import { __CheckAccessToModule} from '../../../utils/accessControl'
import { useGetQueryState } from '../../../utils/react-query-hooks/general'

const RouteSuperAdmin = ({
	moduleId,
	ACTION,
	component: Component,
	path,
	...rest
}) => {
	let location = useLocation()

	let { status, data: userData } = useGetQueryState('userData')

	const hasAccess = () => {
		if (!userData?.user) {
			// not logged in
			return {
				has: false,
				redirectTo: {
					pathname: '/login',
					state: {
						from: location.pathname + location.search,
					},
				},
			}
		}

		// else is logged in, is user suerAdmin?
		// if is, then let them through.
		// if not then simply redir back to the page they were coming from.
		return {
			has: ['superAdmin', 'god'].indexOf(userData.user.role) !== -1,
			redirectTo: '/',
		}
	}

	return (
		<Route
			{...rest}
			path={path}
			render={(props) =>
				status === 'loading' ? (
					<Loader debug={'route super admin access'} />
				) : !hasAccess().has ? (
					<Redirect to={hasAccess().redirectTo} />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

export default RouteSuperAdmin
