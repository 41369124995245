import advancedHandlePastedText from "./advancedHandlePastedText";

import handleKeyCommand from "./handleKeyCommand";

import handleBeforeInput from "./handleBeforeInput";



import handlePastedorDroppedFiles from './handlePastedFiles.js';


// if handleReturn returns true, draft-js thinks that this event is being handled already, Enter key hit is ignored
const handleReturn =({
  charLimit,
  disableNewline,
  plainTextInEditor
}) => {

  //char limit reached
  if(charLimit <= plainTextInEditor.length) return 'handled'
  
  if(disableNewline) return 'handled'

  return 'not-handled';
}

export {
  handlePastedorDroppedFiles,
  advancedHandlePastedText,
  handleKeyCommand,
  handleBeforeInput,
  handleReturn
}