import React, {useEffect} from "react";
import { useGetOgs } from "../../../../utils/react-query-hooks/ogs";


import { CardEmbed, SkeletonLoader, toArray } from "oolib";
const LinkEmbed = ({
  id, 
  value, 
  readOnly, 
  onChange, 
  disableTo //used in RTE > LinkOrEmbedModal, to show a preview, but not let it route to another page. rather its wrapped in a div that has an onclick that does somethng else
}) => {
  /**
   * should also pass the meta (image title url)
   * into this comp (probably via the value only)
   * 
   * and then the logic should be:
   * use the url value.stringQuery to getOgs
   * if gotten then use that data to render the card
   * else use the static meta.
   */

  const { data, status } = useGetOgs({
    url: value,
    enabled: !!value,
  });

  useEffect(() => {
    if(status === 'success'){
      onChange && onChange(id ,{
        title: getOgTitle(),
        img: getOgImage(),
        url: getRequestUrl()
      })
    }
  },[status])

  const getOgImage = () => data.data.ogImage;
  const getOgTitle = () => data.data.ogTitle;
  const getRequestUrl = () => data.requestUrl;

  if(!value) return null;

  return (
    <div>
      
          {status === "loading" ? (
            <SkeletonLoader style={{height: "144px"}} />
          ) : status === "error" ? (
            <CardEmbed
              data={{
                title: 'Failed to load link preview :('
              }}
              config={{ title: 'title' }}
              to={disableTo ? undefined : value}
              />
          ) : (
            status === "success" && (
              <div>
              <CardEmbed
                data={{
                  title: getOgTitle(),
                  image: toArray(getOgImage()).map((d) => ({ publicUrl: d.url }))
                }}
                config={{ 
                  title: 'title',
                  image: 'image' 
                }}
                to={disableTo ? undefined : getRequestUrl()}
                urlInsteadOfMetaBlock={getRequestUrl()}
                />
              
              </div>
            )
          )}
      
    </div>
  );
};

export default LinkEmbed;
