export const configs_gelabs_annoBase = {
  contentTypes: {
    // whitelist: ["immersion1AFGDNigeria", "immersion1ATemplates", "immersion1BIDINigeria", "immersion1BFGDNigeria"]
},
  filterConfigs: {
    whitelist: [
      "annoTags_factors",
      "annoTags_influences",
      "annoTags_existingPrograms",
      "annoTags_findings",
      "docTags_participantsRegistrationTemplates_main_DOT_country",
      "docTags_participantsRegistrationTemplates_segmentationNigeria",
      "docTags_participantsRegistrationTemplates_main_DOT_age",
      "docTags_participantsRegistrationTemplates_main_DOT_maritalStatus",
      "docTags_participantsRegistrationTemplates_main_DOT_educationLevel",
      "docTags_participantsRegistrationTemplates_vaginalInsertableProductsUsed",
      "docTags_participantsRegistrationTemplates_useCases",
      "docTags_participantsRegistrationTemplates_accessToMobilePhones",
      "docTags_participantsRegistrationTemplates_main_DOT_numberOfChildren",
    ],
  },
  cardConfigs: {
    // docTags: {
    //     whitelist: [
    //         'documentStatus'
    //     ]
    // },
    // annoTags: {
    //     whitelist: []
    // }
  },
};
