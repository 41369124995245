import {
  Container,
  PaddingTopBottom20,
  PaddingTopBottom50,
  SANS_0,
  SANS_3_4,
  Wrapper700,
} from "oolib";
import React, { useState } from "react";
import { AddXS } from "oolib/dist/icons/custom";
import { PrivateTagsInputSingle } from "../../../components/inputs/DynamicTagsInputs/PrivateTagsInputSingle";
import { DynamicRefetchDropdown } from "../../../components/inputs/Dropdowns/DynamicDropdown";

export const Common = () => {
  const [showReactions, setShowReactions] = useState(false);
  const [showText, setShowText] = useState(false);
  const [clickResponse, setClickResponse] = useState("");

  return (
    <Container>
      <Wrapper700>
        <PrivateTagsInputSingle />
        <DynamicRefetchDropdown
        {...{
          "id": "v7up7i",
                    "label": "City ",
                    "sublabel": "Name the city your organisation's headquarter /central office is at ",
                    "variant": "combobox--single",
                    placeholder: 'Hello hello'
        }}
        />
        {/* <PaddingBottom40>
          <LinkEmbedWithInput value={val2} onChange={(k, v) => setVal2(v)} />
        </PaddingBottom40> */}
        <PaddingTopBottom20>
          <SANS_3_4 style={{ height: "4rem" }}>{clickResponse}</SANS_3_4>
        </PaddingTopBottom20>

        <PaddingTopBottom50>
          <div
            onMouseEnter={() => {
              console.log("enter");
              setShowReactions(true);
            }}
            onMouseLeave={() => {
              console.log("leave");
              setShowReactions(false);
            }}
            style={{
              width: "4rem",
              height: "4rem",
              background: "red",
              position: "relative",
            }}
          >
            {showText !== undefined && (
              <div
                style={{
                  position: "absolute",
                  top: "80px",
                  // transform: "translateY(100%)",
                  width: "160px",
                  background: "lightblue",
                }}
              >
                <SANS_0>{showText}</SANS_0>
              </div>
            )}
            {showReactions && (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: 0,
                  transform: "translateY(100%)",
                }}
              >
                {[1, 2, 3, 4].map((d, i) => (
                  <div
                    style={{
                      width: "4rem",
                      height: "4rem",
                      background: "aqua",
                      border: "1px solid green",
                    }}
                    onMouseDown={(e) => {
                      setClickResponse("do something on click of: " + d);
                      setShowReactions(false);
                      setShowText(undefined);
                    }}
                    onMouseEnter={(e) => {
                      // e.stopPropagation();
                      console.log(i);
                      setShowText(d);
                    }}
                    onMouseLeave={(e) => {
                      // e.stopPropagation();
                      console.log("undefine");
                      setShowText(undefined);
                    }}
                  >
                    <AddXS size={16} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </PaddingTopBottom50>
        {/* <PaddingTopBottom45>
          <LexicalTextEditor
            id={'editor1'}
            label='Editor 1'
            value={val}
            onChange={(k,v) => setVal(v)}
            />    
        </PaddingTopBottom45>
        <PaddingTopBottom45>
          <LexicalTextEditor
          id={'editor2'}
            label='Editor 2'
            value={val2}
            onChange={(k,v) => setVal2(v)}
            />    
        </PaddingTopBottom45>
        <PaddingTopBottom45>
          <LexicalTextEditor
          id={'editor3'}
            label='Editor 3'
            value={val3}
            onChange={(k,v) => setVal3(v)}
            />    
        </PaddingTopBottom45> */}
      </Wrapper700>
    </Container>
  );
};
