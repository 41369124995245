import React from 'react'
import { ButtonPrimary, WrapperCardGrid } from 'oolib'
import { SANS_2, SERIF_5_6 } from 'oolib'

const CardRadioInput = (props) => {
	const { value, options, onChange, id, style } = props

	return (
		<WrapperCardGrid style={style} id={id}>
			{options.map((d) => {
				let display = d.display || d.title
				let desc = d.desc || d.subtitle
				return (
					<CardRadio
						key={d.value}
						selected={d.value === value?.value}
						display={display}
						desc={desc}
						onClick={() => onChange && onChange(id, d)}
					/>
				)
			})}
		</WrapperCardGrid>
	)
}

export const CardRadio = (props) => {
	//col settings must be the bootstrap codes

	const { display, desc, action, selected, onClick } = props

	return (
		<div
			onClick={onClick}
			className={`Card--radio ${selected ? '-selected-' : ''}`}>
			<div>
				{display && <SERIF_5_6>{display}</SERIF_5_6>}
				{desc && <SANS_2 style={{ marginTop: '0.5rem' }}>{desc}</SANS_2>}
			</div>
			{action && (
				<div style={{ margin: '0 auto' }}>
					<ButtonPrimary
						style={{ marginTop: '1.5rem' }}
						children={action.text}
					/>
				</div>
			)}
		</div>
	)
}

export default CardRadioInput
