import React, { useEffect, useState } from "react";
import { useFormValidationContext } from "../../../contexts/formValidationContext";
import { useHandleContentChange } from "../../../utils/contributionFlowUtils";
import { formValidationCheckV2 } from "../../../utils/validation/formValidation";
import {ButtonPrimary, ButtonSecondary, ButtonGhost} from 'oolib'
import { FormConfigsRenderer } from "../layouts/GroupsStyle1/comps/FormConfigsRenderer";
import { cloneDeep } from "lodash";


export const useOutOfTheBoxForm = ({
    onSubmit,
    content: _content,
    configs,
    buttonConfig = {},
    FormWrapper,
    blockWrapper,
    makeFormValidationVisibleMsg,
  }) => {
    buttonConfig.comp = buttonConfig.comp || "ButtonPrimary";
    buttonConfig.props = buttonConfig.props || { children: "Done" };
  
    const {
      setFormValidation,
      formValidation,
      resetFormValidation,
      makeFormValidationVisible,
    } = useFormValidationContext();
  
    useEffect(() => {
      const { failedBlocks } = formValidationCheckV2({
        blocks: configs,
        content,
      });
      setFormValidation((p) => ({ ...p, failedBlocks }));
  
      return () => resetFormValidation();
    }, []);
  
    const [content, setContent] = useState(
      _content ? cloneDeep(_content) : {}
    );
  
    const { handleContentChange } = useHandleContentChange();
  
    const handleOnChange = (block, value, options = {}) =>
      handleContentChange({
        block,
        value,
        setContent,
        setFormValidation,
        blocksToValidate: configs,
        ...options,
      });
  
    const buttonComps = {
      ButtonPrimary,
      ButtonSecondary,
      ButtonGhost,
    };
    let ButtonComp = buttonComps[buttonConfig.comp];
  
    return {
      FORM: (
        <FormConfigsRenderer
          FormWrapper={FormWrapper}
          blockWrapper={blockWrapper}
          configs={configs}
          handleOnChange={handleOnChange}
          content={content}
          formValidation={formValidation}
          setFormValidation={setFormValidation}
        />
      ),
      SUBMIT_BUTTON: (
        <div
          onClick={() => {
            if (
              formValidation.failedBlocks.length > 0 ||
              formValidation.invalidInputBlocks.length > 0
            )
              makeFormValidationVisible(makeFormValidationVisibleMsg);
          }}
        >
          <ButtonComp
            {...buttonConfig.props}
            onClick={() => onSubmit(content)}
            disabled={
              formValidation.failedBlocks.length > 0 ||
              formValidation.invalidInputBlocks.length > 0
            }
          />
        </div>
      ),
    
    };
  };
  