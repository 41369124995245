import axios from "axios";
import { useEffect } from "react";
import { getTenant } from "../../TENANT_CONFIGS";

export const useSetAppropriateMetaTitle = () => {
  useEffect(() => {
    const setAppropriateMetaTitle = async () => {
      try {
        const thisAxiosInstance = axios.create({
          baseURL: process.env.PUBLIC_URL,
        }); //this way we override  the okf-be baseurl we set in axiosDefaults
        
        let response = await thisAxiosInstance.get(
          `/${getTenant()}/manifest.json`
        );
        document.title = response.data?.title;
        // console.log({response})
      } catch (err) {
        console.log("err in get manifest", err);
      }
    };
    // if (process.env.NODE_ENV === "production") {
      setAppropriateMetaTitle();
    // }
  }, []);
};
