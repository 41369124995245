import styled from "styled-components";
import { colors, globalHoverOnWhiteBG } from "oolib";
const {  greyColor5 } = colors;

export const NotificationButtonStyled = styled.div`
  padding: 1rem;
  border-radius: 2px;
  height: 6rem;
  padding: 0 1rem;
  display:flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${globalHoverOnWhiteBG}
  background-color: ${({ open }) => (open ? greyColor5 : "")};
`;
