import React, { useEffect, useRef, useState } from "react";
import { LinkPlugin as _LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { useGetActiveNodeInfoInSelection } from "../ToolbarPlugin/utils/useGetActiveNodeInfoInSelection";
import { getAnnoLightboxPosition } from "../AnnoPlugin/utils/getAnnoLightboxPosition";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { handleToggleLink } from "../ToolbarPlugin/LinkAndEmbedTools/handlers/handleToggleLink";
import styled, { css } from "styled-components";
import { ButtonGhost, OKELink, SANS_2, URLInput, boxShadow1, colors, ellipsis } from "oolib";
import { $getSelection } from "lexical";

// picked the LinkPlugin & AutoLinkPlugin implementation from here
// https://javascript.plainenglish.io/lexical-how-to-use-link-plugins-d9a7734977a0

const StyledLightboxContainer = styled.div`
  padding: 1rem;
  border-radius: 2px;
  ${boxShadow1};
  border: 1px solid ${colors.greyColor10};
  background-color: ${colors.white};
  display: flex;
  gap: 1rem;
  z-index: 1000000;
  position: absolute;
  ${({lightboxPos}) => css`
    left: ${lightboxPos.x}px;
    top: ${lightboxPos.y}px;
  `}
`

const StyledLinkPreviewWrapper = styled.div`
  max-width: 30rem;
  ${ellipsis};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LinkLightbox = ({url: _url, editor}) => {

  const [ linkMode, setLinkMode ] = useState('display') //alt : edit
  const [url, setUrl] = useState(_url)

  //we need to save this target selection,
  //cuz if the link is edited, the selection will shift to the urlinput in the 
  //lightbox, and this targetSelection wil be lost
  const targetSelectionForToggleLink = useRef(null);
  const lightboxPos = useRef(getAnnoLightboxPosition({editor}))
  useEffect(() => {
  return editor.getEditorState().read(() => {
    targetSelectionForToggleLink.current = $getSelection().clone()
  })
  },[])
  
  return (
    <StyledLightboxContainer lightboxPos={lightboxPos.current}>
      <StyledLinkPreviewWrapper>
      { linkMode === 'display'
      ? <SANS_2>
        <OKELink to={url}>{url}</OKELink>
      </SANS_2>
      : <URLInput
        
        S
        value={url}
        id='url'
        onChange={(k,v) => setUrl(v)}
        />}
        </StyledLinkPreviewWrapper>
        <div style={{display: 'flex', gap: '0.5rem'}}>
          {linkMode === 'display' ? <><ButtonGhost
            icon='PencilSimple'
            onClick={() => setLinkMode('edit')}
            />
          <ButtonGhost
            icon='Trash'
            onClick={() => handleToggleLink({editor, link: null})}
            /></> : 
            <>
            <ButtonGhost icon='Check' onClick={() => handleToggleLink({editor, link: url, selection: targetSelectionForToggleLink.current, callback: () => setLinkMode('display')})}/>
            <ButtonGhost icon='X' onClick={() => setLinkMode('display')}/>
            </>
            
            }
        </div>
    </StyledLightboxContainer>
  )
}

export function LinkPlugin() {

  const activeNodeInfo = useGetActiveNodeInfoInSelection()
  
  const urlRegExp = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
  );

  function validateUrl(url) {
    return url === "https://" || urlRegExp.test(url);
  }
  const [editor] = useLexicalComposerContext()

  /**
   * we do this wierd stuff cuz sometimes activeNodeInfo.getUrl throws
   * error and we dont know why. so we are just falling back to directly 
   * accessing url property from the node, so that page doesnt break
   */
  const getLinkNodeUrl = activeNodeInfo => {
    try{
      return activeNodeInfo?.getUrl()
    }catch(err){
      return activeNodeInfo?.__url
    }
  }

  return (
    <>
      {
        editor.isEditable() && 
        ['link'].indexOf(activeNodeInfo?.__type) !== -1 &&
        <LinkLightbox 
          key={activeNodeInfo?.getKey()}
          editor={editor}
          url={getLinkNodeUrl(activeNodeInfo)}
          />
        
      }
      {
        /**
         * what the plugin below does is, if you select some text and paste a link, that text gets
         * converted into a link
         */
      }
      <_LinkPlugin validateUrl={validateUrl} /> 
    </>
  );
}
