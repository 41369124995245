import styled from "styled-components";

export const AIMessageStyled = styled.div`
  ul {
    margin: 1rem;
  }
  h3 {
    margin: 1rem;
  }

  li {
    list-style: disc;
    margin: 1rem;
    font-size: 14px;

  }
  padding: 1.2rem;
  max-width: 55rem;
  width: fit-content;
  border-radius: 0.6rem;
  border-color: #e6e6e6;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px #0000000f;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;


