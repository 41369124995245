import { SANS_3, EmptyStates } from "oolib";
import React from "react";
import { _Locale } from "../../../../components/locale/Locale";



const returnText = (totalCount) => {

  switch (totalCount) {
    case "error": 
      return "Something went wrong...";
    case "loading":
      return `${_Locale('Loading')} ${_Locale('results')}...`;
    case 0:
      return "No results";
    default:
      return (
        `${_Locale('Showing')} ${totalCount} ${_Locale('results')}  `
      )
  }
};

export const TotalCount = ({ totalCount, style }) => {

  if (totalCount === undefined) return null;
 
  

  //else
  return (
    <div style={style}>
      <SANS_3>{returnText(totalCount)}</SANS_3>
    </div>
  );
};
