import React from "react";
import { WorldEngine } from "../../WorldEngine";


export const WorldEngineTest = () => {


    return (
        <WorldEngine/>
    )
}