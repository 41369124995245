import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useBannerContext } from 'oolib'




import { useGenErrorMsgBanners } from '../useGenErrorMsgBanners'

export const useGetPublishedLearningModules = (options = {}) => {
	return useGetLearningModules({filter: {status : 'published'}, ...options })
}



export const useGetLearningModules = (options = {}) => {

	const { filter, onSuccess } = options
	let filterQuery = filter ? `?filter=${encodeURIComponent(JSON.stringify(filter))}` : ''

	const query = useQuery('learningModules', () =>
		axios.get('/api/learningModule'+filterQuery).then((res) => res.data),
		{ onSuccess, enabled: options.enabled },

	)

	return query
}

export const useGetLearningModule = (id) => {
	const query = useQuery(['learningModule', id], () =>
		axios.get(`/api/learningModule/${id}`),
	)

	return query
}

export const useCreateLearningModule = () => {
	const queryClient = useQueryClient()
	const { SET_ALERT_BANNER } = useBannerContext()
	return useMutation(
		(body) =>
			axios.post('/api/learningModule', body, {
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		{
			onSuccess: (res) => {
				queryClient.invalidateQueries('learningModules')
				SET_ALERT_BANNER({
					color: 'green',
					msg: 'New Topic Page Created Successfully!',
					timeOut: 3000,
				})
			},
			onError: () => {
				SET_ALERT_BANNER({ color: 'red', msg: 'something went wrong', timeOut: 3000 })
			},
		},
	)
}

export const useUpdateLearningModule = () => {
	const queryClient = useQueryClient()
	const { SET_ALERT_BANNER } = useBannerContext()
	return useMutation(
		(formData) =>
			axios.put(`/api/learningModule/${formData._id}`, formData, {
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('learningModules')
				SET_ALERT_BANNER({
					color: 'green',
					msg: 'Learning Module Updated Successfully!',
					timeOut: 3000,
				})
			},
			onError: () => {
				SET_ALERT_BANNER({ color: 'red', msg: 'something went wrong', timeOut: 3000 })
			},
		},
	)
}

export const useUpdateLearningModulesOrder = () => {
	const queryClient = useQueryClient()
	const { SET_ALERT_BANNER } = useBannerContext()
	return useMutation(
		(ary) =>
			axios.put('/api/learningModule/update/order', ary, {
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		{
			onSuccess: (res) => {
				queryClient.invalidateQueries('learningModules')
				SET_ALERT_BANNER({
					color: 'green',
					msg: 'Learning Modules Order Updated Successfully!',
					timeOut: 3000,
				})
			},
			onError: () => {
				SET_ALERT_BANNER({ color: 'red', msg: 'something went wrong', timeOut: 3000 })
			}
		},
	)
}

export const useDeleteLearningModule = () => {
	const queryClient = useQueryClient()
	const { SET_ALERT_BANNER } = useBannerContext()
	return useMutation((id) => axios.delete(`/api/learningModule/${id}`), {
		onSuccess: (res) => {
			queryClient.invalidateQueries('learningModules')
			
			SET_ALERT_BANNER({
				color: 'green',
				msg: 'Topic Page Deleted Successfully',
				timeOut: 3000,
			})
		},
		onError: () => {
			SET_ALERT_BANNER({ color: 'red', msg: 'something went wrong', timeOut: 3000 })
		},
	})
}

export const useSaveUserAssessmentScores = () => {
	const queryClient = useQueryClient()
	const { SET_ALERT_BANNER } = useBannerContext()
	return useMutation(
		(formData) =>
			axios.post('/api/recommendations/saveScores_ASH', formData, {
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		{
			onSuccess: (res) => {
				queryClient.invalidateQueries('userData')

				SET_ALERT_BANNER({
					color: 'green',
					msg: 'Survey Recorded Successfully',
					timeOut: 3000,
				})
			},
			onError: () => {
				SET_ALERT_BANNER({ color: 'red', msg: 'something went wrong', timeOut: 3000 })
			},
		},
	)
}



export const useUpdateWorksheetResponses = () => {
	const queryClient = useQueryClient()
	const { genErrorMsgBanners } = useGenErrorMsgBanners();
	return useMutation(
		(data) =>
			axios.patch('/api/users/updateWorksheetResponses', data, {
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		{
			onSuccess: (res) => {
				queryClient.invalidateQueries('userData')
			
			},
			
				onError: (err) => {
				genErrorMsgBanners({err})
				},
			
		}
	
	)
}
