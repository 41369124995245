import { ImageInput } from "oolib";
import React from "react";
import { DecoratorNodeShell } from "../comps/DecoratorNodeShell";

export const ImageInputNodeComp = ({
  //props for comp
  id,
  files,
  value,
  multiple,

  //props for shell
  nodeKey,
  
  //handlers
  onChange,
  onRemove,

  readOnly
  
}) => {
  return (
    <DecoratorNodeShell
      nodeKey={nodeKey}
      onRemove={onRemove}
      {
        /** temp not passed to single image renders cuz RTE earlier used to use ImageInput > SingleImageInput,
         * wherein, a actionmenu existed with edit + replace,
         * here these actions would work.
         *
         * Now thought, it uses ImageInput > InlineImageInput, which for now
         * does not have these actions, hence these wouldnt work. But they
         * will come back soon. So dont delete this code.
         * */
        ...(multiple
          ? {
              actions: [
                {
                  display: "Edit",
                  icon: "PencilSimple",
                  onClick: () => {
                    let actualEditButton = document.getElementById(`${id}_editImage`);
                    actualEditButton.click();
                  },
                },
                {
                  display: "Replace",
                  icon: "UploadSimple",
                  onClick: () => {
                    let actualReplaceButton = document.getElementById(`${id}_replaceImage`);
                    actualReplaceButton.click();
                  },
                },
              ],
            }
          : {})
      }
    >
      <ImageInput
        isInRTE={true}
        id={id}
        files={files}
        value={value}
        multiple={multiple}
        onChange={onChange}
        readOnly={readOnly}
      />
    </DecoratorNodeShell>
  );
};
