import { Container } from 'oolib';
import React from 'react';

export const LeatletMapPreview = ({}) => {


    return (
        <Container>
         removed this a while back. can delete this page
        </Container>
    );
};



// "dataViz" : {
//   "getDataConfig" : {
//     "taggedResourcesCount":["newsReports", "tenderDocuments", "surveyReports", "proceedings"],
//     "contentTypes":["states"],
//     "findQuery":true,
//   },
//   "props" : {
//     "centerPosition": [23.5937, 82.9629],
//     "colorRange": ["#E21B32", "#FEE7E6"],
//     "zoom": 5,
//     "tabOptions" : [
//       { "value": "newsReports", "display": "News Reports", "valuePath": "newsReports"},
//       { "value": "tenderDocuments", "display": "Tender Documents", "valuePath": "tenderDocuments"},
//       { "value": "surveyReports", "display": "Survey Reports", "valuePath": "surveyReports"},
//       { "value": "proceedings", "display": "Court Documents", "valuePath": "proceedings"},
//       { "value": "total", "display": "Total Reports"}
//     ]
//   }
// }