import { DecoratorNode } from "lexical";
import React from "react";
import { ExternalLinkEmbedNodeComp } from "./Comp";
import { nanoid } from "nanoid";

export class ExternalLinkEmbedNode extends DecoratorNode {
  static getType() {
    return "ExternalLinkEmbedNode";
  }

  static clone(node) {
    return new ExternalLinkEmbedNode({
      value: node.__value,
      id: node.__id,
      key: node.__key,
    });
  }

  static importJSON(serializedNode) {
    return $createExternalLinkEmbedNode({
      value: serializedNode.value,
      id: serializedNode.id,
    });
  }

  exportJSON() {
    return {
      type: this.constructor.getType(),
      value: this.__value,
      id: this.__id,
    };
  }

  constructor({ value, id, key }) {
    super(key);
    this.__value = value;
    this.__id = id || nanoid(6);
  }

  createDOM() {
    return document.createElement("div");
  }

  updateDOM() {
    return false;
  }

  onChange(editor, value) {
    editor.update(() => {
      const self = this.getWritable();
      self.__value = value;
    });
  }

  onRemove(editor) {
    editor.update(() => {
      this.remove();
    });
  }

  decorate(editor) {
    return (
      <ExternalLinkEmbedNodeComp
        nodeKey={this.__key}
        id={this.__id}
        value={this.__value}
        onChange={(k, v) => this.onChange(editor, v)}
        onRemove={() => this.onRemove(editor)}
      />
    );
  }

  isInline(){ //somehow this is important so that if this block is at end of editor, and we do arrow down + enter, then a new line will be created. Else it will not.
    return false
  }
}

export function $createExternalLinkEmbedNode({ value, id, key }) {
  return new ExternalLinkEmbedNode({ value, id, key });
}

export function $isExternalLinkEmbedNode(node) {
  return node instanceof ExternalLinkEmbedNode;
}
