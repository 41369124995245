import AnimatedNumber from 'animated-number-react';
import { ButtonPrimary, Container, DropdownSingle, SANS_2, SANS_3, SANS_4_5, SkeletonLoader, colors, getVal } from 'oolib';
import React, { Fragment, useState } from 'react';

const { greyColor3 } = colors; 
 
const OverviewModule = ( {
  loading,
  data,
  numberPrefix,
  textAlign,
  
  views,
  activeView,
  dropdownLabel,
  genHeaderComp,
  onDropdownChange,
  cta,
  dataPointsConfig
}) => {
  

  const [changeWidth, setChangeWidth] = useState(false)
  const handleWidthSet = (e) => {
    setTimeout(() => {
      const container = e?.offsetWidth
      const innerContainer = e?.childNodes[1]?.childNodes[0]?.offsetWidth
      if (container - (innerContainer + 40) < 0) setChangeWidth(true)
    }, 100)
  }

  return (
    <Fragment>
      {loading ? (
        <Container style={{ padding: 0, paddingBottom: '1rem' }}>
          <SkeletonLoader style={{
            height:"10rem", 
            backgroundColor: greyColor3
          }} />
        </Container>
      ) : (
        <div className="DataDb__OverviewModule">
          {views && (
            <div className="OverviewModule__header">
              <div className="OverviewModule__header-row">
                <div className="OverviewModule__viewsDropdown-wrapper">
                  <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
                  {dropdownLabel && 
                  <SANS_3 bold className="OverviewModule__viewsDropdownLabel">
                    {dropdownLabel}
                  </SANS_3>}
                  <DropdownSingle
                    className="OverviewModule__viewsDropdown"
                    value={activeView}
                    options={views}
                    onChange={onDropdownChange}
                  />
                  </div>
                  {genHeaderComp && genHeaderComp(activeView, data)}
                </div>
              </div>
            </div>
          )}

          <div
            style={{ overflow: changeWidth ? 'auto' : '' }}
            className="OverviewModule__viz"
          >
            <div className="OverviewModule__viz-row">
              {Object.values(dataPointsConfig).map((d) => {
                let value = getVal( data, d.valuePath)
                let deltaValue =
                  d.deltaValuePath && getVal( data, d.deltaValuePath)
                if (deltaValue === 0) deltaValue = false
                
                return (
                  <div
                    key={d.id}
                    className="OverviewModule__viz-col"
                    style={{
                      width: changeWidth
                        ? 'auto'
                        : 100 / (Object.values(data).length - 1) + '%',
                      ...(textAlign ? { textAlign } : {}),
                    }}
                  >
                    {' '}
                    {/** minus 1 cuz we dont want to could the 'TT' data object */}
                    <div className="outer-container" ref={handleWidthSet}>
                      <SANS_2 className="bold">{d.display}</SANS_2>
                      <SANS_4_5>
                        <div className="inner-container">
                          {
                          typeof value  === 'number'
                          ? <AnimatedNumber
                          value={value}
                          formatValue={(value) =>
                            `${numberPrefix || ''}${Math.round(value).toLocaleString('en-IN')}`
                          }
                        />
                        : value /*this rendered things other than numbers also*/ } 
                          
                          {deltaValue && (
                            <span
                              className="meta"
                              style={{ marginLeft: '2rem' }}
                            >
                              <AnimatedNumber
                                value={deltaValue}
                                formatValue={(deltaValue) =>
                                  `${deltaValue > 0 ? '+' : ''}${Math.round(
                                    deltaValue
                                  ).toLocaleString('en-IN')}`
                                }
                              />
                            </span>
                          )}
                        </div>
                      </SANS_4_5>
                    </div>
                  </div>
                )
              })}
            </div>

            {cta && (
              <div style={{ padding: '1rem 0' }}>
                <a href={cta.link} target="_blank">
                  <ButtonPrimary
                    style={textAlign === 'center' ? { margin: '0 auto' } : {}}
                  >
                    {cta.text}
                  </ButtonPrimary>
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default OverviewModule
