import React, {Fragment, useState} from "react";
import { UploadButton, BlockLabel, getBlockLabelProps, useBannerContext } from 'oolib';
import ContentBlockShell from '../../generalUI/ContentBlockShell';
import {replaceEntityData} from '../../../utils/richInputUtils';
import {imageUploader} from '../../../utils/general';



const DEPRECATED_AudioEmbed = (props) => {
      
  const {
		id,
		className,
		value,
		onChange: parentOnChange,
		multiple,
		isInEditor,
		editor,
		entityKey,
		block,
		handleSetTempReadOnly,
		readOnly,
		folderName,
		allowedFormats,
		uploadBucketName,
		passChangeHandlerOps,
	} = props

  const audioData = value || [];

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const {SET_ALERT_BANNER} = useBannerContext()

  const handleUploadProgess = (loadedPercent) => {
    setUploadProgress(loadedPercent);
  }

  const handleErrorFeedback = (msg) => {
    setErrorMsg(msg);
    //reset other states
    setUploading(false);
    setUploadProgress(null);
  }

  

  const callback = (status, resData) => {
    
    if(status === 'success'){
      parentOnChange && parentOnChange(id, resData, passChangeHandlerOps && props);
      if(isInEditor === true){
        replaceEntityData(editor, entityKey, {value: resData })
      }
    }
    setUploading(false);
    setUploadProgress(null);
  }

  const handleChange = (e) => {
    const files = Array.from(e.target.files)
    setUploading(true);
    setErrorMsg(null);

    const formData = new FormData()
    
    files.forEach((file, i) => { formData.append(i, file) })
    formData.set("folderName", folderName);
    formData.set("allowedFormats", allowedFormats);
    if(uploadBucketName){
      formData.set("uploadBucketName", uploadBucketName);
    }
    
    imageUploader(
      formData, 
      { 
        type: 'files',
        jsonFileData : files,
        folderName,
        allowedFormats,
        handleUploadProgess, 
        handleErrorFeedback,
        SET_ALERT_BANNER
      }, 
      callback
    );
  }

  const genAudioPlayer = () => (
    <Fragment>
    { audioData.map((d) => (
        <audio controls src={d.mediaLink} >check</audio>
      )) }
    </Fragment>
  )

  const genInputBlock = () => (
    <Fragment>
    { audioData.length === 0
      ? <UploadButton
          onChange={handleChange}
          multiple={multiple} 
          value={uploading && uploadProgress === 100 ?
            'Almost Done...'
            : uploading ? 
              `Uploading... ${uploadProgress ? '( ' + uploadProgress + ' / 100 )' : ''}` 
              : errorMsg ?
                  errorMsg
                : 'Upload Audio'
          }
          id={`${id}_audioUploadBtn`}
          />
      : <div style={{display: 'flex', alignItems: 'center'}}> 
          { genAudioPlayer() } 
          <UploadButton 
            onChange={handleChange}
            multiple={multiple} 
            variant='primary'
            value = {uploading && uploadProgress === 100
              ? 'Almost Done...'
              : uploading 
                ? `Uploading... ${uploadProgress ? '( ' + uploadProgress + ' / 100 )' : ''}` 
                : errorMsg
                  ? errorMsg
                  : 'Replace Audio' }
            id={`${id}_audioUploadBtn`}
            />
        </div> }
    </Fragment>
  )

  const genDisplayBlock = () => (
    <div>{ genAudioPlayer() }</div>
  )

  const genBlock = () => (
    <Fragment>
    { readOnly ? genDisplayBlock() : genInputBlock()}
    </Fragment>
  )
  
  return (
    <ContentBlockShell
      richInputProps = {{
        isInEditor,
        editor,
        block,
        handleSetTempReadOnly
      }}
      readOnly = { readOnly }
      className={`AudioEmbed ${className}`}
      id={id}
    >
      <BlockLabel {...getBlockLabelProps(props)} />
      {genBlock()}
    </ContentBlockShell>
  )
};

DEPRECATED_AudioEmbed.defaultProps = {
  multiple : false,
  folderName : 'audio',
  allowedFormats : ['mp3', 'wav', 'm4a', 'aac', 'amr','ogg']
}


export default React.memo(DEPRECATED_AudioEmbed);