import moment from "moment";

import "moment/locale/pt"; // Import the Português locale
import "moment/locale/es"; // Import the Espanhol locale
import "moment/locale/id"; // Import the Bahasa Indonesia locale
import "moment/locale/ms"; // Import the Bahasa Malaysia/Malay locale
import "moment/locale/hi"; // Import the Hindi locale


import { useAppSettingsContext } from "../../contexts/appSettingsContext";

export default function MomentLocale() {
  const { APP_SETTINGS } = useAppSettingsContext();

  moment.locale(APP_SETTINGS.lang?.value);

  return null;
}

