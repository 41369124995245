import React from 'react'
import { Container, Wrapper1000 } from 'oolib'
import {
  StyledContentSection,
  StyledSection,
} from './styled'
import { SIDEBAR_WIDTH } from '../..'


const wrappers = {
  contained: Container, // Wrapper1000,
  fullWidth: Container,
}


export const ContentSection = ({ showSideBarLeft, contentConfig, isMobile }) => {
  const ContentWrapper = wrappers[contentConfig?.layout] || Wrapper1000

  return (
    <StyledContentSection
      sidebar={showSideBarLeft}
      isMobile={isMobile}
      sidebarWidth={SIDEBAR_WIDTH}
    >
      <StyledSection sidebar={showSideBarLeft} id={"StyledSection"}>
        <ContentWrapper style={{ 
            width: '100%', 
            // margin: contentConfig?.layout !== "contained" && '0', 
            padding: 0 }}>
          {contentConfig?.content || contentConfig}
        </ContentWrapper>
      </StyledSection>
    </StyledContentSection>
  )
}