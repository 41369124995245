import { SANS_2, SANS_3, getText } from "oolib";
import React from "react";


/**
 * @note since TableText component is more flexible now, since it converts
 * right text to plain text as well, this comp might need to be deprecated in future.
 */
const RichTextAsPlainText = ({ value, typoComp = 'SANS_3' }) => {

  if(!value || (value.blocks && value.blocks.length === 0) ) return null;

  let comps = { SANS_2, SANS_3 };
  let Comp = comps[typoComp];
  let textString = getText(value)
    
  
  return (
    <Comp>
      {textString}
    </Comp>
  );
};

export default RichTextAsPlainText;
