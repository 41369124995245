import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React from "react";
import { useAnnoContextLex } from "../../AnnoPlugin/context";
import { ToolbarButton } from "../comps/ToolbarButton";
import { handleAIAnnotate } from "./utils/handleAIAnnotate";

const config = {
  icon: "MagicWand",
};

export const AIAnnoTool = () => {
  const [editor] = useLexicalComposerContext();
  const { handleSetAnnoData } = useAnnoContextLex();

  return (
    <>
      <ToolbarButton
        onClick={() => 
          handleAIAnnotate({ editor, handleSetAnnoData })
        }
        icon={config.icon}
      />
    </>
  );
};
