import React from 'react';
import { Container, PaddingTopBottom5, Section, DropdownSingle, sortData } from 'oolib';
import { useSearchParamsState } from '../../utils/customHooks';
import * as pages from './pages';

const Playground = () => {
  
  let tabOptions = sortData({data: Object.keys(pages).map((d) => ({ display: d, value: d })), path: 'display', fn: 'alphabetical'});
  const [activeTab, setActiveTab] = useSearchParamsState({
    key: 'pgActiveTab',
    value: tabOptions[0].value,
  });

  const renderComp = () => {
    if (!activeTab) return null;
    //else
    let Comp = pages[activeTab];
    return <Comp />;
  };

  return (
    <div>
      <Section borderBottom>
        <Container>
          <PaddingTopBottom5>
            <DropdownSingle options={tabOptions} value={activeTab} onChange={(k, v) => setActiveTab(v)} saveValueAsString />
          </PaddingTopBottom5>
        </Container>
      </Section>
      {renderComp()}
    </div>
  );
};

export default Playground;


export const ShellPlayground = () => {

  return (
    <pages.ListingShell/>
  );
};
