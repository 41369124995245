import { icons, UI_BODY_BOLD } from "oolib";
import React from "react";
import { StyledFilterMobileButton } from "../Shell/styled";

import {
  StyledActionBar,
  StyledActionBlock,
  StyledActionsPanelWrapper,
  StyledAnimatedCaret,
  StyledFilterButtonWrapper,
  StyledFilterButton,
  StyledMobileActions
} from "./styled"

const { Funnel } = icons;


/**
 * panel wherein the entrypoints for filter, search and sort appear
 */
export const ActionsPanel = ({
  showSideBarLeft,
  setShowSideBarLeft,
  isMobile,
  search,
  sort,
  filtersExist,
  actionRef,
  tabsPanelHeight,
  totalWidthRef
}) => {

  const genSearchSortMobile = () => {
    return (
      <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          ref={totalWidthRef}
      >
        {search && search}
        {sort && sort}
      </div>
    );
  };

  const genSearchSortDesktop = () => (<>{search && search} {sort && sort}</>)

  return (
    <StyledActionsPanelWrapper ref={actionRef} tabsPanelHeight={tabsPanelHeight} sidebar={showSideBarLeft} isMobile={isMobile}>
      {isMobile ? (
        <StyledMobileActions>
          {filtersExist && (
            <StyledFilterMobileButton
              onClick={() => setShowSideBarLeft(!showSideBarLeft)}
            >
              <Funnel size={20} weight={"bold"} />
              <UI_BODY_BOLD style={{ marginLeft: "1rem" }}>Filters</UI_BODY_BOLD>
            </StyledFilterMobileButton>
          )}

          <div style={{ flexGrow: 1, maxWidth: '100svw - 2rem' }}>  {/* 2 rem is for padding */}
            {genSearchSortMobile()}
          </div>
        </StyledMobileActions>
      ) : (
        <StyledActionBar>
          {filtersExist && (
            <StyledFilterButtonWrapper
              animate={{
                width: !showSideBarLeft ? "max-content" : "300px", // 300px sidebar width
              }}
              initial={false}
              transition={{ type: "spring", stiffness: 125, damping: 30, duration: 0.5 }}
            >
              <StyledFilterButton
                onClick={() => setShowSideBarLeft(!showSideBarLeft)}
              >
                <UI_BODY_BOLD bold>Filters</UI_BODY_BOLD>
                <StyledAnimatedCaret
                  size={20}
                  weight={"bold"}
                  animate={{ rotate: showSideBarLeft ? 180 : 0 }}
                  initial={{ rotate: showSideBarLeft ? 180 : 0 }}
                  transition={{ type: "spring", stiffness: 400, damping: 50 }}
                />
              </StyledFilterButton>
            </StyledFilterButtonWrapper>
          )}

          {/* Action Block Desktop version */}
          <StyledActionBlock>
            {/* Search and sort component  */}
            {genSearchSortDesktop()}
          </StyledActionBlock>
        </StyledActionBar>
      )}
    </StyledActionsPanelWrapper>
  );
};
