import { Container, SANS_3, PaddingTopBottom20 } from 'oolib'
import React from 'react'
import DataWQN from '../../../pageTpls/DataWQN'

const { districtsSurveyed } = require('../../../staticData/general.json')

const WQNDataDb = ({
	className,
	content
}) => {
	
	const lookup = {
	  "balasore_district_fluorosis_platform": "balasore",
	  "nalgonda_district_fluorosis_platform": "nalgonda",
	  "jhabua_district_fluorosis_platform": "jhabua",
	  "chikkaballapur_district_ifm_platform": "chikballapur",
	  "dungarpur_district_ifm_platform": "dungarpur"
	}

	if(!lookup[content.tagId]){
		return <Container>
			<PaddingTopBottom20>
			<SANS_3 semibold>No data dashboard for this working group</SANS_3>
			</PaddingTopBottom20>
		</Container>
		
	}

	console.log({
		lk: lookup[content.tagId],
		REW: districtsSurveyed.findIndex((d) => d.value === lookup[content.tagId])
	})

	const genDisplayComp = () => (
		<DataWQN
			value={lookup[content.tagId]}
			noContainerFluid={true}
			noHeaderDd={true}
			title={
				districtsSurveyed[districtsSurveyed.findIndex((d) => d.value === lookup[content.tagId])]
					.name
			}
		/>
	)

	return (
		<div className={`kp-temp-data-db ${className}`}>
			{genDisplayComp()}
		</div>
	)
}

export default WQNDataDb
