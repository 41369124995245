import { colors, SANS_2, transition } from "oolib";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { __CheckAccessViaConfig } from "../../../../../../../utils/accessControl";
import { useGetQueryData } from "../../../../../../../utils/react-query-hooks/general";
import { _Locale } from "../../../../../../locale/Locale";
import { useIsThisLinkActive } from "../../utils/useIsThisLinkActive";
const { none, greyColor5 } = colors;

const StyledLinkWrapper = styled.li`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.primaryColor10 : none)};
  ${transition("background-color")}
  &:hover {
    background-color: ${greyColor5};
  }
`;

const StyledLink = styled(Link)`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const SECLink = ({
  to, // can be a location obj or a string
  display,
  accessControl
}) => {
  const { isThisLinkActive } = useIsThisLinkActive();
  const { user } = useGetQueryData("userData") || {};

  if (accessControl && !__CheckAccessViaConfig({ ...accessControl, user }))
    return null;

  return (
    <StyledLinkWrapper
      isActive={isThisLinkActive({ to })} key={to}
      >
      <StyledLink to={to}>
        <SANS_2 semibold>{_Locale(display)}</SANS_2>
      </StyledLink>
  </StyledLinkWrapper>
  );
};


