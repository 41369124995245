import { PaddingBottom20, PaddingTopBottom30, SANS_3, Wrapper700 } from 'oolib'
import React, {useState} from 'react'
import { VideoInput }from 'oolib'

export const VideoUpload = () => {
  const [url, setUrl] = useState(undefined)
  //https://www.youtube.com/watch?v=t-VLgggRe9U
  console.log({url})
  const videoSource = [
    {
        "kind": "storage#object",
        "id": "ash_dev__media/videos/file_example_mp4_480_1_5mg_b277aea6-c5dc-4aee-9f8e-13854fb35341.mp4/1679571840272509",
        "selfLink": "https://www.googleapis.com/storage/v1/b/ash_dev__media/o/videos%2Ffile_example_mp4_480_1_5mg_b277aea6-c5dc-4aee-9f8e-13854fb35341.mp4",
        "mediaLink": "https://storage.googleapis.com/download/storage/v1/b/ash_dev__media/o/videos%2Ffile_example_mp4_480_1_5mg_b277aea6-c5dc-4aee-9f8e-13854fb35341.mp4?generation=1679571840272509&alt=media",
        "name": "videos/file_example_mp4_480_1_5mg_b277aea6-c5dc-4aee-9f8e-13854fb35341.mp4",
        "bucket": "ash_dev__media",
        "generation": "1679571840272509",
        "metageneration": "1",
        "contentType": "video/mp4",
        "storageClass": "STANDARD",
        "size": 1570024,
        "md5Hash": "2QYdPahgGTLpj3nsi6HIdw==",
        "crc32c": "xoHWAQ==",
        "etag": "CP2QqNf88f0CEAE=",
        "timeCreated": "2023-03-23T11:44:00.275Z",
        "updated": "2023-03-23T11:44:00.275Z",
        "timeStorageClassUpdated": "2023-03-23T11:44:00.275Z",
        "originalFilename": "file_example_MP4_480_1_5MG.mp4",
        "mediaHost": "gcs",
        "publicUrl": "https://www.youtube.com/watch?v=t-VLgggRe9U"
    }
]
  return (
    <Wrapper700>
    <PaddingBottom20 />
    <VideoInput value={url} onChange={(k, v) => setUrl(v)} enableVideoUpload/>
    <PaddingTopBottom30/>
    <SANS_3>Read Only uploaded Video</SANS_3>
    <PaddingBottom20 />
    <VideoInput value={videoSource} readOnly/>
    <PaddingTopBottom30/>
    <SANS_3>Read Only embeded link</SANS_3>
    <PaddingBottom20 />
    <VideoInput value={"https://www.youtube.com/watch?v=t-VLgggRe9U"} readOnly/>
    </Wrapper700>
  )
}
