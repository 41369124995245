import { queryClient } from "../../../../../.."
import { __CheckAccessToContentType, __CheckAccessToProfileType } from "../../../../../../utils/accessControl"
import {  __GetContentTypeConfigNew, __GetProfileTypeConfig } from "../../../../../../utils/getters/gettersV2"


//listing page
  export const checkAccessAndGenContentPageLinkProps = contentType => {
    let conf = __GetContentTypeConfigNew(contentType)
    if(!conf) return undefined;
    let {user} = queryClient.getQueryData('userData') || {}
    if(!__CheckAccessToContentType(user, 'READ_IF_MEMBER', contentType, {ignoreExtendedIsMemberCheck: true} )) return {dontRender: true};
    return ({
      to: `/published-listing/${contentType}`,
      display: conf.general.content?.title 
    })
  }

  //landing page
  export const checkAccessAndGenContentLandingPageLinkProps = ({contentType, _id}) => {
    let {user} = queryClient.getQueryData('userData') || {}
    if(!__CheckAccessToContentType(user, 'READ_IF_MEMBER', contentType, {ignoreExtendedIsMemberCheck: true} )) return {dontRender: true};
    return ({
      to: `/published-page/${contentType}?id=${_id}`
      //no display is set cuz display for these is set at the component level via api call : component = PRIMLink, HamLink & FooterLink
    })
  }

  export const checkAccessAndGenProfilePageLinkProps = profileType => {
    let conf = __GetProfileTypeConfig(profileType)
    let {user} = queryClient.getQueryData('userData') || {}
    if(!__CheckAccessToProfileType(user, 'READ', profileType )) return {dontRender: true};
    
    return ({
      to: `/community-listing/${profileType}`,
      display: conf.content?.title || conf.id
    })
  }