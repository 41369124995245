import { cloneDeep } from "lodash";
import uuid from "uuid";
import { getVal } from 'oolib';

export const _HANDLE_DUPLICATE_SECTION = ({
    sectionPath,
    setTplConfig,
    SAVE_TPL_CHANGES
}) => {
    
    setTplConfig(prev => {
      let newTplConfig = cloneDeep(prev);

      const thisSectionConfig = getVal( newTplConfig, sectionPath);
      const sectionPathSplit = sectionPath.split('.')
      const thisIdx = parseInt(sectionPathSplit[sectionPathSplit.length-1]);
      const sectionAryPath = sectionPathSplit.filter((d,i) => i !== sectionPathSplit.length - 1).join('.')
      const newSectionConfig = {
        ...thisSectionConfig,
        sectionId: uuid.v4(),
        blocks: thisSectionConfig.blocks.map(block => ({
          ...block,
          valuePath: `${block.valuePath}_${uuid.v4()}`
        }))
      
      }
      
      let thisAry = getVal( newTplConfig, sectionAryPath);
      thisAry.splice(thisIdx+1, 0, newSectionConfig);

      SAVE_TPL_CHANGES({newTplConfig});

      return newTplConfig;
    })
     
  };