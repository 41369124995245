import {
  INSERT_ORDERED_LIST_COMMAND, 
  INSERT_UNORDERED_LIST_COMMAND,
  INSERT_CHECK_LIST_COMMAND,
  REMOVE_LIST_COMMAND
} from '@lexical/list'

export const handleClick = ({ isActive, listType, editor }) => {
  const commandLookup = {
    number: INSERT_ORDERED_LIST_COMMAND,
    bullet: INSERT_UNORDERED_LIST_COMMAND,
    check: INSERT_CHECK_LIST_COMMAND
  }
  
  isActive
  ? editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
  : editor.dispatchCommand(commandLookup[listType], undefined)
  
};
