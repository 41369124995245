export const errorStatesConfig = ({msg})=>{
    
 return{  
    401:{
        layout: "center",
        img: "image401",
        imgSize: 200,
        title: "You donot have permission to access this",
        subTitle:   msg.msg
    }, 
    400:{
        layout: "center",
        img: "image400",
        imgSize: 200,
        title: "Your client requested a malformed or illegal request",
        subTitle:   msg.msg
    },
    403: {
        layout: "center",
        img: "image403",
        imgSize: 200,
        title: "Error 403",
        subTitle:  msg.msg
    },
    404: {
        layout: "center",
        img: "image404",
        imgSize: 200,
        title: "You cannot find the page you are looking for",
        subTitle: msg.msg
    },
    500: {
        layout: "center",
        img: "image500",
        imgSize: 200,
        opacity:0.8,  
        title: "The server encountered an error",
        subTitle:  msg.msg
    },
    503: {
        layout: "center",
        img: "image500",
        imgSize: 200,
        opacity:0.8,  
        title: "Device is offline. Unable to fetch this page :(",
        subTitle:  msg.msg
    }}
}

export const statusInclude=(status)=>{
    return Object.keys(errorStatesConfig()).includes(status)
}