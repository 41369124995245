import React from "react";
import LinkEmbed from "../../../../_V2/LinkEmbed";
import AtomicBlockShell from "../AtomicBlockShell";

/*
   -> Entity Update
   -> Wrap the component in shell
   -> handleSetTempReadOnly()
*/

export const RTEExternalEmbed = ({
  id,
  value,
  readOnly,

  // --- RTE EMBED PROPS --- //
  entityKey,
  block,
  editorState,
  handleChange,
  readOnlyProp,
  setReadOnly,
  embedType = "external-link",
}) => {
  
  return (
    <AtomicBlockShell
      {...{ id, readOnlyProp, setReadOnly, editorState, handleChange, block, readOnly }}
      >
      <LinkEmbed value={value} />
    </AtomicBlockShell>
  );
};
