import axios from "axios";
import { useMutation } from "react-query";
import { queryClient } from "../../..";
import { UIContent } from "../../../UIContent";
import { useGenErrorMsgBanners } from "../../../utils/useGenErrorMsgBanners";
import { useBannerContext } from "oolib";

const getDeleteSuccessMsgAsPerSegment = (contentType) => {
    
    const tpl = queryClient.getQueryData(['tpl', contentType]);
    const segment = tpl?.general?.segment
    const successMsg = segment === 'collections'
    ? UIContent.reactQueryHooks_content.deleteTagSuccessfulMsg
    : UIContent.reactQueryHooks_content.deleteContentSuccessfulMsg
    
    return successMsg

}

export const useDeleteContent = () => {
    const { SET_ALERT_BANNER } = useBannerContext();
    const {genErrorMsgBanners} = useGenErrorMsgBanners()
    return useMutation(
      ({ contentType, id }) =>
        axios.delete(`/api/content/deleteContent/${contentType}/${id}`),
      {
        onSuccess: (res, {contentType}) => {
          SET_ALERT_BANNER(getDeleteSuccessMsgAsPerSegment(contentType), 'green', 5000);
          queryClient.invalidateQueries(["RelatedContent"]);
        },
        onError: (err) =>
            genErrorMsgBanners({ err })
      }
    );
  };
  
  