import React, {Fragment, useEffect} from "react";
import d3 from '../../../importGroups/d3Modules';

//be sure to pass the kpGaugeIdSufix. Its what makes the gauge appear in the appropriate position.
import { colors } from 'oolib' 
const { red, green } = colors; 
 
const KPGauge = ({ className, kpGaugeIdSufix, arcData}) => {

  var powerGauge;

  useEffect(()=>{
  powerGauge = gauge('#kp-gauge'+kpGaugeIdSufix, {
		size: 300,
		clipWidth: 300,
		clipHeight: 150,
		ringWidth: 25,
    focussedRingWidth: 25, //this variable controls the width of the arc in focus
		maxValue: 100,
		transitionMs: 4000,
	});
  d3.select('#kp-gauge'+kpGaugeIdSufix).selectAll('svg').remove();
	powerGauge.render();
  // powerGauge.update(arcData.yellow_value[0]);
  //value
  }, [arcData])

  var gauge = function(container, configuration) {
	var that = {};
	var config = {
		// size						: 710,
		// clipWidth					: 200,
		// clipHeight					: 110,
		ringInset					: 0,
		// ringWidth					: 20,

		pointerWidth				: 12,
		pointerTailLength			: 5,
		pointerHeadLengthPercent	: 0.075,

		minValue					: 0,
		// maxValue					: 100,

		minAngle					: -90,
		maxAngle					: 90,

		// transitionMs				: 750,

		tickData					  : [0, arcData && arcData.yellow_value[0]+arcData.orange_value[0], 100],
    // tickData            : [0, 40, 70, 100],
		labelFormat					: d3.format('d'),
		labelInset					: 10,

		arcColors					: [red, green]
	};
	var range = undefined;
	var r = undefined;
	var pointerHeadLength = undefined;
	// var value = 0;

	var svg = undefined;
	var arc = undefined;
	var scale = undefined;
	// var ticks = undefined;
	// var tickData = undefined;
	// var pointer = undefined;

	// var donut = d3.pie();

	function deg2rad(deg) {
		return deg * Math.PI / 180;
	}

	// function newAngle(d) {
	// 	var ratio = scale(d);
	// 	var newAngle = config.minAngle + (ratio * range);
	// 	return newAngle;
	// }

	function configure(configuration) {
		var prop = undefined;
		for ( prop in configuration ) {
			config[prop] = configuration[prop];
		}

		range = config.maxAngle - config.minAngle;
		r = config.size / 2;
		pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

		// a linear scale that maps domain values to a percent from 0..1
		scale = d3.scaleLinear()
			.range([0,1])
			.domain([config.minValue, config.maxValue]);

		// ticks = scale.ticks(config.majorTicks);
		// tickData = d3.range(config.majorTicks).map(function() {return 1/config.majorTicks;});

		arc = d3.arc()
			.innerRadius((d, i) => {
        if(i === 0){ //make the first arc thicker
          return r - config.focussedRingWidth - config.ringInset
        }else{
          return r - config.ringWidth - config.ringInset
        }

      })
			.outerRadius(r - config.ringInset)
			.startAngle((d, i) => {
        if(i !== config.tickData.length-1){
          var ratio = (d/config.maxValue);
  				return deg2rad(config.minAngle + (ratio * range));
        }
			})
			.endAngle(function(d, i) {
        if(i !== config.tickData.length-1){
          var ratio = (config.tickData[i+1]/config.maxValue);
          
  				return deg2rad(config.minAngle + (ratio * range));
        }

			});
	}
	that.configure = configure;

	function centerTranslation() {
		return 'translate('+r +','+ r +')';
	}

	function isRendered() {
		return (svg !== undefined);
	}
	that.isRendered = isRendered;

	function render(newValue) {
		svg = d3.select(container)
			.append('svg:svg')
				.attr('class', 'gauge')
				.attr('width', config.clipWidth)
				.attr('height', config.clipHeight)
        .attr('viewBox', '0,0,'+config.clipWidth+","+config.clipHeight)
        .style('width', "100%")
        .style('height', 'auto')


		var centerTx = centerTranslation();

		var arcs = svg.append('g')
				.attr('class', 'arc')
				.attr('transform', centerTx);

		arcs.selectAll('path')
				.data(config.tickData)
			  .enter().append('path')
				.attr('fill', (d, i) => {
					return config.arcColors[i];
				})
        .attr('opacity', (d, i) => {
					return i === 0 ? '1' : '0.2' ;
				})
				.attr('d', arc);

////// ***POINTER CODE*** 1of2 ///////
    // var pointerYOffset = 0.65*r;
		// var lineData = [ [config.pointerWidth / 2, -pointerYOffset],
		// 				[0, -pointerHeadLength-pointerYOffset],
		// 				[-(config.pointerWidth / 2), -pointerYOffset],
		// 				[config.pointerWidth / 2, -pointerYOffset] ];
		// var pointerLine = d3.line()
		// var pg = svg.append('g').data([lineData])
		// 		.attr('class', 'pointer')
		// 		.attr('transform', centerTx);
    //
		// pointer = pg.append('path')
		// 	.attr('d', pointerLine )
		// 	.attr('transform', 'rotate(' +config.minAngle +')');

		update(newValue === undefined ? 0 : newValue);
	}
	that.render = render;
	function update(newValue, newConfiguration) {
		if ( newConfiguration  !== undefined) {
			configure(newConfiguration);
		}
		// var ratio = scale(newValue);
		// var newAngle = config.minAngle + (ratio * range);
////// ***POINTER CODE*** 2of2 ///////
		// pointer.transition()
		// 	.duration(config.transitionMs)
		// 	.ease(d3.easeElastic)
		// 	.attr('transform', 'rotate(' +newAngle +')');
	}
	that.update = update;

	configure(configuration);

	return that;
};


    return(
      <Fragment>
      <div id={`kp-gauge${kpGaugeIdSufix}`} className={className}>
      </div>
      </Fragment>
    )
}





export default KPGauge;
