import { nanoid } from "nanoid";
import { ButtonPrimary, Container, PaddingTopBottom40, colors } from "oolib";
import React, { useState } from "react";
import { SidebarLayoutV2 } from "../../layout/SidebarLayoutV2";
import { useDynamicLayoutHeight } from "./utils/useDynamicLayoutHeight";
import { DataVizWidgetGenerator } from "./comps/DataVizWidgetGenerator";

export const InsightMatrix = () => {
  const { parentWrapperRef, gridHeightCSS } = useDynamicLayoutHeight();

  const [vizData, setVizData] = useState([{ id: "1" }]);

  return (
    <div ref={parentWrapperRef}>
      <SidebarLayoutV2
        gridHeightCSS={gridHeightCSS}
        lsbComp={<div>filters will come here</div>}
      >
        <Container style={{ height: '100%'}}>
          <PaddingTopBottom40>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {vizData.map((d, i) => {
                  if (i % 2 === 0) {
                    return (
                      <DataVizWidgetGenerator
                        id={d.id}
                        vizData={vizData[i]}
                        setVizData={setVizData}
                        index={i}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
                {vizData.length % 2 === 0 ? (
                  <ButtonPrimary
                    onClick={() =>
                      setVizData((prev) => [...prev, { id: nanoid(6) }])
                    }
                  >
                    Add another
                  </ButtonPrimary>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {vizData.map((d, i) => {
                  if (i % 2 === 1) {
                    return (
                      <DataVizWidgetGenerator
                        vizData={vizData[i]}
                        setVizData={setVizData}
                        id={d.id}
                        index={i}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
                {vizData.length % 2 === 1 ? (
                  <ButtonPrimary
                    onClick={() =>
                      setVizData((prev) => [...prev, { id: nanoid(6) }])
                    }
                  >
                    Add another
                  </ButtonPrimary>
                ) : null}
              </div>
            </div>
          </PaddingTopBottom40>
        </Container>
      </SidebarLayoutV2>
    </div>
  );
};
