

export const dummyData = [
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c379aae5f411da093bc3",
        "kp_date_published": "2022-07-17T08:08:25.037Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-dunga-10-3- Jebetui-hwov",
          "participantId": "kisumu-dunga-10-3- Jebetui-hwov",
          "fakename": " Jebetui"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608c",
                "display": "Dunga",
                "tagId": "dunga"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512e9",
                "display": "18-22",
                "tagId": "18_22"
              },
              {
                "_id": "61eec518b4272aec1e3512ea",
                "display": "23-26",
                "tagId": "23_26"
              },
              {
                "_id": "61eec518b4272aec1e3512ec",
                "display": "32-36",
                "tagId": "32_36"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b534ddd8187bea9029e8",
                "display": "Peri-Urban",
                "tagId": "peri_urban"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7d",
                "display": "NA",
                "tagId": "na"
              },
              {
                "_id": "61f1034c6d616b6beedf0f7f",
                "display": "13-17",
                "tagId": "13_17"
              },
              {
                "_id": "61f1034c6d616b6beedf0f80",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f88",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "620f269775b54830963c81e5",
                "display": "Never Married",
                "tagId": "never_married"
              },
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b821f8c3267c3b8700f3",
                "display": "1",
                "tagId": "1"
              },
              {
                "_id": "61e3b823f8c3267c3b8700f4",
                "display": "2",
                "tagId": "2"
              },
              {
                "_id": "61e3b82ef8c3267c3b8700f6",
                "display": "4+",
                "tagId": "4plus"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b965f8c3267c3b8700fa",
                "display": "Unemployed",
                "tagId": "unemployed"
              },
              {
                "_id": "61f755c7f966c333c36f60af",
                "display": "Housewife",
                "tagId": "housewife"
              },
              {
                "_id": "61f755c7f966c333c36f60b1",
                "display": "Small business owner",
                "tagId": "small_business_owner"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f2",
                "display": "Traditional User",
                "tagId": "traditional_user"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60ca",
                "display": "Never-used",
                "tagId": "never_used"
              },
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "61e3bcb2f8c3267c3b87010c",
                "display": "With Parents",
                "tagId": "with_parents"
              },
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a Nuclear Family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba0df8c3267c3b870100",
                "display": "Secondary Education",
                "tagId": "secondary_education"
              },
              {
                "_id": "61e3ba09f8c3267c3b8700ff",
                "display": "Primary Education",
                "tagId": "primary_education"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f7",
                "display": "Injectable",
                "tagId": "injectable"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60bb",
                "display": "Christian-Protestant",
                "tagId": "christian_protestant"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "625a724b32df0a000aab24b5",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-09T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d62",
                      "profileType": "researchersKenya",
                      "name": "Edgar Adera",
                      "id": "61f7b1f8c7dda14bf6798d62"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d62",
                          "display": "Edgar Adera"
                        },
                        {
                          "_id": "61f7b1f8c7dda14bf6798d64",
                          "display": "Florence Wanjugu"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608c",
                          "display": "Dunga",
                          "tagId": "dunga"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c379aae5f411da093bc8",
        "kp_date_published": "2022-07-17T08:08:25.155Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-dunga-10-3- Jelagat (Jeplangat)-kr8k",
          "participantId": "kisumu-dunga-10-3- Jelagat (Jeplangat)-kr8k",
          "fakename": " Jelagat (Jeplangat)"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608c",
                "display": "Dunga",
                "tagId": "dunga"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512e9",
                "display": "18-22",
                "tagId": "18_22"
              },
              {
                "_id": "61eec518b4272aec1e3512ea",
                "display": "23-26",
                "tagId": "23_26"
              },
              {
                "_id": "61eec518b4272aec1e3512ec",
                "display": "32-36",
                "tagId": "32_36"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b534ddd8187bea9029e8",
                "display": "Peri-Urban",
                "tagId": "peri_urban"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7d",
                "display": "NA",
                "tagId": "na"
              },
              {
                "_id": "61f1034c6d616b6beedf0f7f",
                "display": "13-17",
                "tagId": "13_17"
              },
              {
                "_id": "61f1034c6d616b6beedf0f80",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f88",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "620f269775b54830963c81e5",
                "display": "Never Married",
                "tagId": "never_married"
              },
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b821f8c3267c3b8700f3",
                "display": "1",
                "tagId": "1"
              },
              {
                "_id": "61e3b823f8c3267c3b8700f4",
                "display": "2",
                "tagId": "2"
              },
              {
                "_id": "61e3b82ef8c3267c3b8700f6",
                "display": "4+",
                "tagId": "4plus"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b965f8c3267c3b8700fa",
                "display": "Unemployed",
                "tagId": "unemployed"
              },
              {
                "_id": "61f755c7f966c333c36f60af",
                "display": "Housewife",
                "tagId": "housewife"
              },
              {
                "_id": "61f755c7f966c333c36f60b1",
                "display": "Small business owner",
                "tagId": "small_business_owner"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f2",
                "display": "Traditional User",
                "tagId": "traditional_user"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60ca",
                "display": "Never-used",
                "tagId": "never_used"
              },
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "61e3bcb2f8c3267c3b87010c",
                "display": "With Parents",
                "tagId": "with_parents"
              },
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a Nuclear Family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba0df8c3267c3b870100",
                "display": "Secondary Education",
                "tagId": "secondary_education"
              },
              {
                "_id": "61e3ba09f8c3267c3b8700ff",
                "display": "Primary Education",
                "tagId": "primary_education"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f7",
                "display": "Injectable",
                "tagId": "injectable"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60bb",
                "display": "Christian-Protestant",
                "tagId": "christian_protestant"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "625a724b32df0a000aab24b5",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-09T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d62",
                      "profileType": "researchersKenya",
                      "name": "Edgar Adera",
                      "id": "61f7b1f8c7dda14bf6798d62"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d62",
                          "display": "Edgar Adera"
                        },
                        {
                          "_id": "61f7b1f8c7dda14bf6798d64",
                          "display": "Florence Wanjugu"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608c",
                          "display": "Dunga",
                          "tagId": "dunga"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c470aae5f411da0957f2",
        "kp_date_published": "2022-07-17T08:12:32.847Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "nairobi-virtual-20-6- Raziya  -9yty",
          "participantId": "nairobi-virtual-20-6- Raziya  -9yty",
          "fakename": " Raziya  "
        },
        "tags": {
          "locationsNairobi_kenya": {
            "collectionId": "locationsNairobi_kenya",
            "data": [
              {
                "_id": "6250462ddd6d72000b466c50",
                "display": "Virtual",
                "tagId": "virtual"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607a",
                "display": "Nairobi",
                "tagId": "nairobi"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ea",
                "display": "23-26",
                "tagId": "23_26"
              },
              {
                "_id": "61eec518b4272aec1e3512ec",
                "display": "32-36",
                "tagId": "32_36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "620f269775b54830963c81e5",
                "display": "Never Married",
                "tagId": "never_married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7d",
                "display": "NA",
                "tagId": "na"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b821f8c3267c3b8700f3",
                "display": "1",
                "tagId": "1"
              },
              {
                "_id": "61e3b81ef8c3267c3b8700f2",
                "display": "0",
                "tagId": "0"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f88",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b929f8c3267c3b8700f7",
                "display": "Student",
                "tagId": "student"
              },
              {
                "_id": "61e3b965f8c3267c3b8700fa",
                "display": "Unemployed",
                "tagId": "unemployed"
              },
              {
                "_id": "61e3b96cf8c3267c3b8700fb",
                "display": "Working Fulltime",
                "tagId": "working_fulltime"
              },
              {
                "_id": "620f2a970b3ad13222f79c60",
                "display": "Self Employed",
                "tagId": "self_employed"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba1ff8c3267c3b870101",
                "display": "Graduate and Above",
                "tagId": "graduate_and_above"
              },
              {
                "_id": "620f2ba10b3ad13222f79c64",
                "display": "Enrolled in Undergrad/Diploma",
                "tagId": "enrolled_in_undergrad_diploma"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61e3ba7bf8c3267c3b870105",
                "display": "Christian",
                "tagId": "christian"
              },
              {
                "_id": "61f755c7f966c333c36f60bb",
                "display": "Christian-Protestant",
                "tagId": "christian_protestant"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "61e3bc8cf8c3267c3b87010a",
                "display": "By self",
                "tagId": "by_self"
              },
              {
                "_id": "61e3bcb2f8c3267c3b87010c",
                "display": "With parents",
                "tagId": "with_parents"
              },
              {
                "_id": "620f2cc00b3ad13222f79c65",
                "display": "With partner",
                "tagId": "with_partner"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f3",
                "display": "Hormonal Method User",
                "tagId": "hormonal_method_user"
              },
              {
                "_id": "61eec6d5b4272aec1e3512f4",
                "display": "Non-Hormonal User",
                "tagId": "non_hormonal_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61eec81bb4272aec1e3512f7",
                "display": "Injectable",
                "tagId": "injectable"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              },
              {
                "_id": "61eec81bb4272aec1e3512fb",
                "display": "Emergency Pill (EC)",
                "tagId": "emergency_pill_ec"
              },
              {
                "_id": "61eec81bb4272aec1e3512f9",
                "display": "Weekly Pill",
                "tagId": "weekly_pill"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62b055deecb91c000b7127fe",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-06-19T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d64",
                      "profileType": "researchersKenya",
                      "name": "Florence Wanjugu",
                      "id": "61f7b1f8c7dda14bf6798d64"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d63",
                          "display": "Mwaniki Nyaga"
                        },
                        {
                          "_id": "61f7b1f8c7dda14bf6798d64",
                          "display": "Florence Wanjugu"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607a",
                          "display": "Nairobi",
                          "tagId": "nairobi"
                        }
                      ]
                    },
                    "locationsNairobi_kenya": {
                      "collectionId": "locationsNairobi_kenya",
                      "data": [
                        {
                          "_id": "6250462ddd6d72000b466c50",
                          "display": "Virtual",
                          "tagId": "virtual"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          },
          "SecondImmersionKenya": {
            "collectionId": "SecondImmersionKenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62b0721a41784a000bf339ec",
                  "meta": {
                    "kp_content_type": "SecondImmersionKenya",
                    "date": "2022-06-19T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d64",
                      "profileType": "researchersKenya",
                      "name": "Florence Wanjugu",
                      "id": "61f7b1f8c7dda14bf6798d64"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d63",
                          "display": "Mwaniki Nyaga"
                        },
                        {
                          "_id": "61f7b1f8c7dda14bf6798d64",
                          "display": "Florence Wanjugu"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          },
          "ThirdImmersionKenya": {
            "collectionId": "ThirdImmersionKenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62b07e4cebc2f1000b97e707",
                  "meta": {
                    "kp_content_type": "ThirdImmersionKenya",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d64",
                      "profileType": "researchersKenya",
                      "name": "Florence Wanjugu",
                      "id": "61f7b1f8c7dda14bf6798d64"
                    },
                    "date": "2022-06-19T21:00:00.000Z"
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d63",
                          "display": "Mwaniki Nyaga"
                        },
                        {
                          "_id": "61f7b1f8c7dda14bf6798d64",
                          "display": "Florence Wanjugu"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c470aae5f411da0957ed",
        "kp_date_published": "2022-07-17T08:12:32.753Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "nairobi-virtual-20-6- Safiyya  -gkht",
          "participantId": "nairobi-virtual-20-6- Safiyya  -gkht",
          "fakename": " Safiyya  "
        },
        "tags": {
          "locationsNairobi_kenya": {
            "collectionId": "locationsNairobi_kenya",
            "data": [
              {
                "_id": "6250462ddd6d72000b466c50",
                "display": "Virtual",
                "tagId": "virtual"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607a",
                "display": "Nairobi",
                "tagId": "nairobi"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ea",
                "display": "23-26",
                "tagId": "23_26"
              },
              {
                "_id": "61eec518b4272aec1e3512ec",
                "display": "32-36",
                "tagId": "32_36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "620f269775b54830963c81e5",
                "display": "Never Married",
                "tagId": "never_married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7d",
                "display": "NA",
                "tagId": "na"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b821f8c3267c3b8700f3",
                "display": "1",
                "tagId": "1"
              },
              {
                "_id": "61e3b81ef8c3267c3b8700f2",
                "display": "0",
                "tagId": "0"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f88",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b929f8c3267c3b8700f7",
                "display": "Student",
                "tagId": "student"
              },
              {
                "_id": "61e3b965f8c3267c3b8700fa",
                "display": "Unemployed",
                "tagId": "unemployed"
              },
              {
                "_id": "61e3b96cf8c3267c3b8700fb",
                "display": "Working Fulltime",
                "tagId": "working_fulltime"
              },
              {
                "_id": "620f2a970b3ad13222f79c60",
                "display": "Self Employed",
                "tagId": "self_employed"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba1ff8c3267c3b870101",
                "display": "Graduate and Above",
                "tagId": "graduate_and_above"
              },
              {
                "_id": "620f2ba10b3ad13222f79c64",
                "display": "Enrolled in Undergrad/Diploma",
                "tagId": "enrolled_in_undergrad_diploma"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61e3ba7bf8c3267c3b870105",
                "display": "Christian",
                "tagId": "christian"
              },
              {
                "_id": "61f755c7f966c333c36f60bb",
                "display": "Christian-Protestant",
                "tagId": "christian_protestant"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "61e3bc8cf8c3267c3b87010a",
                "display": "By self",
                "tagId": "by_self"
              },
              {
                "_id": "61e3bcb2f8c3267c3b87010c",
                "display": "With parents",
                "tagId": "with_parents"
              },
              {
                "_id": "620f2cc00b3ad13222f79c65",
                "display": "With partner",
                "tagId": "with_partner"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f3",
                "display": "Hormonal Method User",
                "tagId": "hormonal_method_user"
              },
              {
                "_id": "61eec6d5b4272aec1e3512f4",
                "display": "Non-Hormonal User",
                "tagId": "non_hormonal_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61eec81bb4272aec1e3512f7",
                "display": "Injectable",
                "tagId": "injectable"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              },
              {
                "_id": "61eec81bb4272aec1e3512fb",
                "display": "Emergency Pill (EC)",
                "tagId": "emergency_pill_ec"
              },
              {
                "_id": "61eec81bb4272aec1e3512f9",
                "display": "Weekly Pill",
                "tagId": "weekly_pill"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62b055deecb91c000b7127fe",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-06-19T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d64",
                      "profileType": "researchersKenya",
                      "name": "Florence Wanjugu",
                      "id": "61f7b1f8c7dda14bf6798d64"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d63",
                          "display": "Mwaniki Nyaga"
                        },
                        {
                          "_id": "61f7b1f8c7dda14bf6798d64",
                          "display": "Florence Wanjugu"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607a",
                          "display": "Nairobi",
                          "tagId": "nairobi"
                        }
                      ]
                    },
                    "locationsNairobi_kenya": {
                      "collectionId": "locationsNairobi_kenya",
                      "data": [
                        {
                          "_id": "6250462ddd6d72000b466c50",
                          "display": "Virtual",
                          "tagId": "virtual"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          },
          "SecondImmersionKenya": {
            "collectionId": "SecondImmersionKenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62b071e941784a000bf33981",
                  "meta": {
                    "kp_content_type": "SecondImmersionKenya",
                    "date": "2022-06-19T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d64",
                      "profileType": "researchersKenya",
                      "name": "Florence Wanjugu",
                      "id": "61f7b1f8c7dda14bf6798d64"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d63",
                          "display": "Mwaniki Nyaga"
                        },
                        {
                          "_id": "61f7b1f8c7dda14bf6798d64",
                          "display": "Florence Wanjugu"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          },
          "ThirdImmersionKenya": {
            "collectionId": "ThirdImmersionKenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62b07e28ebc2f1000b97e6e3",
                  "meta": {
                    "kp_content_type": "ThirdImmersionKenya",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d64",
                      "profileType": "researchersKenya",
                      "name": "Florence Wanjugu",
                      "id": "61f7b1f8c7dda14bf6798d64"
                    },
                    "date": "2022-06-19T21:00:00.000Z"
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d63",
                          "display": "Mwaniki Nyaga"
                        },
                        {
                          "_id": "61f7b1f8c7dda14bf6798d64",
                          "display": "Florence Wanjugu"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c37daae5f411da093c6e",
        "kp_date_published": "2022-07-17T08:08:29.972Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-dunga-10-3-.-436z",
          "participantId": "kisumu-dunga-10-3-.-436z",
          "fakename": "."
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608c",
                "display": "Dunga",
                "tagId": "dunga"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b534ddd8187bea9029e8",
                "display": "Peri-Urban",
                "tagId": "peri_urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ed",
                "display": "36+",
                "tagId": "36"
              },
              {
                "_id": "61eec518b4272aec1e3512eb",
                "display": "27-31",
                "tagId": "27_31"
              },
              {
                "_id": "61eec518b4272aec1e3512ea",
                "display": "23-26",
                "tagId": "23_26"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f80",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f88",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f3",
                "display": "Hormonal Method User",
                "tagId": "hormonal_method_user"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a Nuclear Family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "620f2ba10b3ad13222f79c64",
                "display": "Enrolled in Undergrad/Diploma",
                "tagId": "enrolled_in_undergrad_diploma"
              },
              {
                "_id": "61e3ba09f8c3267c3b8700ff",
                "display": "Primary Education",
                "tagId": "primary_education"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60ac",
                "display": "4",
                "tagId": "4"
              },
              {
                "_id": "61e3b824f8c3267c3b8700f5",
                "display": "3",
                "tagId": "3"
              },
              {
                "_id": "61e3b821f8c3267c3b8700f3",
                "display": "1",
                "tagId": "1"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "620f2a970b3ad13222f79c60",
                "display": "Self Employed",
                "tagId": "self_employed"
              },
              {
                "_id": "61e3b965f8c3267c3b8700fa",
                "display": "Unemployed",
                "tagId": "unemployed"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60bb",
                "display": "Christian-Protestant",
                "tagId": "christian_protestant"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f7",
                "display": "Injectable",
                "tagId": "injectable"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "625a784932df0a000aab262e",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-09T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d62",
                      "profileType": "researchersKenya",
                      "name": "Edgar Adera",
                      "id": "61f7b1f8c7dda14bf6798d62"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d64",
                          "display": "Florence Wanjugu"
                        },
                        {
                          "_id": "61f7b1f8c7dda14bf6798d62",
                          "display": "Edgar Adera"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608c",
                          "display": "Dunga",
                          "tagId": "dunga"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c42eaae5f411da0950b7",
        "kp_date_published": "2022-07-17T08:11:26.336Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-dunga-17-3-42AHERENBShan-7ai5",
          "participantId": "kisumu-dunga-17-3-42AHERENBShan-7ai5",
          "fakename": "42AHERENBShan"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608c",
                "display": "Dunga",
                "tagId": "dunga"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ed",
                "display": "36+",
                "tagId": "36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7f",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60ac",
                "display": "4",
                "tagId": "4"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f87",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60b1",
                "display": "Small business owner",
                "tagId": "small_business_owner"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba0df8c3267c3b870100",
                "display": "Secondary Education",
                "tagId": "secondary_education"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61e3bfae5556707cd708b731",
                "display": "IUD",
                "tagId": "iud"
              },
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61f10e043408ae7238eb8737",
                "display": "FAM - Calendar",
                "tagId": "fam_calendar"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a0b8a035fe57000abd1490",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-16T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608c",
                          "display": "Dunga",
                          "tagId": "dunga"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c42eaae5f411da0950bc",
        "kp_date_published": "2022-07-17T08:11:26.434Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-dunga-17-3-42AHERENBShanee-jvp0",
          "participantId": "kisumu-dunga-17-3-42AHERENBShanee-jvp0",
          "fakename": "42AHERENBShanee"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608c",
                "display": "Dunga",
                "tagId": "dunga"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ed",
                "display": "36+",
                "tagId": "36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7f",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60ac",
                "display": "4",
                "tagId": "4"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f87",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60b1",
                "display": "Small business owner",
                "tagId": "small_business_owner"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba0df8c3267c3b870100",
                "display": "Secondary Education",
                "tagId": "secondary_education"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61e3bfae5556707cd708b731",
                "display": "IUD",
                "tagId": "iud"
              },
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61f10e043408ae7238eb8737",
                "display": "FAM - Calendar",
                "tagId": "fam_calendar"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a0b8a035fe57000abd1490",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-16T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608c",
                          "display": "Dunga",
                          "tagId": "dunga"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c42eaae5f411da0950c6",
        "kp_date_published": "2022-07-17T08:11:26.632Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-dunga-17-3-42AHERENBShani-f822",
          "participantId": "kisumu-dunga-17-3-42AHERENBShani-f822",
          "fakename": "42AHERENBShani"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608c",
                "display": "Dunga",
                "tagId": "dunga"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ed",
                "display": "36+",
                "tagId": "36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7f",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60ac",
                "display": "4",
                "tagId": "4"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f87",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60b1",
                "display": "Small business owner",
                "tagId": "small_business_owner"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba0df8c3267c3b870100",
                "display": "Secondary Education",
                "tagId": "secondary_education"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61e3bfae5556707cd708b731",
                "display": "IUD",
                "tagId": "iud"
              },
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61f10e043408ae7238eb8737",
                "display": "FAM - Calendar",
                "tagId": "fam_calendar"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a0b8a035fe57000abd1490",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-16T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608c",
                          "display": "Dunga",
                          "tagId": "dunga"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c42eaae5f411da0950c1",
        "kp_date_published": "2022-07-17T08:11:26.533Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-dunga-17-3-42AHERENBShany-hcp0",
          "participantId": "kisumu-dunga-17-3-42AHERENBShany-hcp0",
          "fakename": "42AHERENBShany"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608c",
                "display": "Dunga",
                "tagId": "dunga"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ed",
                "display": "36+",
                "tagId": "36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7f",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60ac",
                "display": "4",
                "tagId": "4"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f87",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60b1",
                "display": "Small business owner",
                "tagId": "small_business_owner"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba0df8c3267c3b870100",
                "display": "Secondary Education",
                "tagId": "secondary_education"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61e3bfae5556707cd708b731",
                "display": "IUD",
                "tagId": "iud"
              },
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61f10e043408ae7238eb8737",
                "display": "FAM - Calendar",
                "tagId": "fam_calendar"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a0b8a035fe57000abd1490",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-16T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608c",
                          "display": "Dunga",
                          "tagId": "dunga"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c432aae5f411da09512f",
        "kp_date_published": "2022-07-17T08:11:30.126Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-ahero-16-3-42AHERWRAImani-i4wu",
          "participantId": "kisumu-ahero-16-3-42AHERWRAImani-i4wu",
          "fakename": "42AHERWRAImani"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "62a0bb8c59d79a000af7c903",
                "display": "Ahero",
                "tagId": "ahero"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b539ddd8187bea9029e9",
                "display": "Rural",
                "tagId": "rural"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ed",
                "display": "36+",
                "tagId": "36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f80",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b82ef8c3267c3b8700f6",
                "display": "4+",
                "tagId": "4plus"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f88",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b96cf8c3267c3b8700fb",
                "display": "Working Fulltime",
                "tagId": "working_fulltime"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "620f2ba10b3ad13222f79c64",
                "display": "Enrolled in Undergrad/Diploma",
                "tagId": "enrolled_in_undergrad_diploma"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61e3bfae5556707cd708b731",
                "display": "IUD",
                "tagId": "iud"
              },
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61eec81bb4272aec1e3512f7",
                "display": "Injectable",
                "tagId": "injectable"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a0bb9559d79a000af7c90b",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-15T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "62a0bb8c59d79a000af7c903",
                          "display": "Ahero",
                          "tagId": "ahero"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed45",
                          "display": "IDI",
                          "tagId": "idi"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c44aaae5f411da09535b",
        "kp_date_published": "2022-07-17T08:11:54.304Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-manyatta-23-3-42MANYWRANathari-5sc1",
          "participantId": "kisumu-manyatta-23-3-42MANYWRANathari-5sc1",
          "fakename": "42MANYWRANathari"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f6085",
                "display": "Manyatta",
                "tagId": "manyatta"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ea",
                "display": "23-26",
                "tagId": "23_26"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f80",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b821f8c3267c3b8700f3",
                "display": "1",
                "tagId": "1"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f88",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61eec5ffb4272aec1e3512f1",
                "display": "Working Part Time",
                "tagId": "working_part_time"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba1ff8c3267c3b870101",
                "display": "Graduate and Above",
                "tagId": "graduate_and_above"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61e3ba7bf8c3267c3b870105",
                "display": "Christian",
                "tagId": "christian"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "61eec81bb4272aec1e3512f7",
                "display": "Injectable",
                "tagId": "injectable"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a22c6bb7f941000a79d7ba",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-22T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f6085",
                          "display": "Manyatta",
                          "tagId": "manyatta"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed45",
                          "display": "IDI",
                          "tagId": "idi"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c448aae5f411da095337",
        "kp_date_published": "2022-07-17T08:11:52.920Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-manyatta-22-3-42MANYWRARafiya-njed",
          "participantId": "kisumu-manyatta-22-3-42MANYWRARafiya-njed",
          "fakename": "42MANYWRARafiya"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f6085",
                "display": "Manyatta",
                "tagId": "manyatta"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512e9",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "620f269775b54830963c81e5",
                "display": "Never Married",
                "tagId": "never_married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7d",
                "display": "NA",
                "tagId": "na"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b81ef8c3267c3b8700f2",
                "display": "0",
                "tagId": "0"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f85",
                "display": "NA",
                "tagId": "na"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b929f8c3267c3b8700f7",
                "display": "Student",
                "tagId": "student"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba0df8c3267c3b870100",
                "display": "Secondary Education",
                "tagId": "secondary_education"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "61e3bcb2f8c3267c3b87010c",
                "display": "With parents",
                "tagId": "with_parents"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e583b56f332b3c4104e",
                "display": "Currently Not Sexually Active (~6 months)",
                "tagId": "currently_not_sexually_active_6_months"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd95f8c3267c3b870113",
                "display": "Non-User/Discontinuer",
                "tagId": "non_user_discontinuer"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a22c1cb7f941000a79d776",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-21T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f6085",
                          "display": "Manyatta",
                          "tagId": "manyatta"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed45",
                          "display": "IDI",
                          "tagId": "idi"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c447aae5f411da09530f",
        "kp_date_published": "2022-07-17T08:11:51.322Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-manyatta-10-3-42MANYWRARahma-7nh2",
          "participantId": "kisumu-manyatta-10-3-42MANYWRARahma-7nh2",
          "fakename": "42MANYWRARahma"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f6085",
                "display": "Manyatta",
                "tagId": "manyatta"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ed",
                "display": "36+",
                "tagId": "36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f80",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60ac",
                "display": "4",
                "tagId": "4"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f88",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60b1",
                "display": "Small business owner",
                "tagId": "small_business_owner"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "620f2ba10b3ad13222f79c64",
                "display": "Enrolled in Undergrad/Diploma",
                "tagId": "enrolled_in_undergrad_diploma"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61e3ba7bf8c3267c3b870105",
                "display": "Christian",
                "tagId": "christian"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61e3bfae5556707cd708b731",
                "display": "IUD",
                "tagId": "iud"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a22bebb7f941000a79d754",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-09T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f6085",
                          "display": "Manyatta",
                          "tagId": "manyatta"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed45",
                          "display": "IDI",
                          "tagId": "idi"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c444aae5f411da0952e7",
        "kp_date_published": "2022-07-17T08:11:48.261Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-manyatta-10-3-42MANYWRASalama-arxi",
          "participantId": "kisumu-manyatta-10-3-42MANYWRASalama-arxi",
          "fakename": "42MANYWRASalama"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f6085",
                "display": "Manyatta",
                "tagId": "manyatta"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ed",
                "display": "36+",
                "tagId": "36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7f",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b821f8c3267c3b8700f3",
                "display": "1",
                "tagId": "1"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f87",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60b1",
                "display": "Small business owner",
                "tagId": "small_business_owner"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba1ff8c3267c3b870101",
                "display": "Graduate and Above",
                "tagId": "graduate_and_above"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a2290db7f941000a79d710",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-09T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f6085",
                          "display": "Manyatta",
                          "tagId": "manyatta"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed45",
                          "display": "IDI",
                          "tagId": "idi"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c433aae5f411da095158",
        "kp_date_published": "2022-07-17T08:11:31.808Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-migosi-18-3-42MIGOWRAChinira-9ql9",
          "participantId": "kisumu-migosi-18-3-42MIGOWRAChinira-9ql9",
          "fakename": "42MIGOWRAChinira"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "6295bef00b586f000b38edb8",
                "display": "Migosi",
                "tagId": "migosi"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b534ddd8187bea9029e8",
                "display": "Peri-Urban",
                "tagId": "peri_urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ec",
                "display": "32-36",
                "tagId": "32_36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f81",
                "display": "23-26",
                "tagId": "23_26"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60ac",
                "display": "4",
                "tagId": "4"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f88",
                "display": "18-22",
                "tagId": "18_22"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61f755c7f966c333c36f60b1",
                "display": "Small business owner",
                "tagId": "small_business_owner"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba0df8c3267c3b870100",
                "display": "Secondary Education",
                "tagId": "secondary_education"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61e3bfae5556707cd708b731",
                "display": "IUD",
                "tagId": "iud"
              },
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "61eec81bb4272aec1e3512f7",
                "display": "Injectable",
                "tagId": "injectable"
              },
              {
                "_id": "620f2ec63b56f332b3c4104f",
                "display": "Implant",
                "tagId": "implant"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a0c1b641180b000ad4ec7a",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-17T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "6295bef00b586f000b38edb8",
                          "display": "Migosi",
                          "tagId": "migosi"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed45",
                          "display": "IDI",
                          "tagId": "idi"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c435aae5f411da095182",
        "kp_date_published": "2022-07-17T08:11:33.549Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-migosi-22-3-42MIGOWRAZalika-esmb",
          "participantId": "kisumu-migosi-22-3-42MIGOWRAZalika-esmb",
          "fakename": "42MIGOWRAZalika"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "6295bef00b586f000b38edb8",
                "display": "Migosi",
                "tagId": "migosi"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b539ddd8187bea9029e9",
                "display": "Rural",
                "tagId": "rural"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ed",
                "display": "36+",
                "tagId": "36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512ee",
                "display": "Married",
                "tagId": "married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f82",
                "display": "27-31",
                "tagId": "27_31"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b821f8c3267c3b8700f3",
                "display": "1",
                "tagId": "1"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f87",
                "display": "13-17",
                "tagId": "13_17"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b96cf8c3267c3b8700fb",
                "display": "Working Fulltime",
                "tagId": "working_fulltime"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "620f2ba10b3ad13222f79c64",
                "display": "Enrolled in Undergrad/Diploma",
                "tagId": "enrolled_in_undergrad_diploma"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61e3ba7bf8c3267c3b870105",
                "display": "Christian",
                "tagId": "christian"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "61e3bfae5556707cd708b731",
                "display": "IUD",
                "tagId": "iud"
              },
              {
                "_id": "61eec81bb4272aec1e3512f7",
                "display": "Injectable",
                "tagId": "injectable"
              },
              {
                "_id": "61eec81bb4272aec1e3512fb",
                "display": "Emergency Pill (EC)",
                "tagId": "emergency_pill_ec"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "62a0c21d41180b000ad4ec9b",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-21T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "6295bef00b586f000b38edb8",
                          "display": "Migosi",
                          "tagId": "migosi"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed45",
                          "display": "IDI",
                          "tagId": "idi"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c421aae5f411da094f74",
        "kp_date_published": "2022-07-17T08:11:13.543Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-nyalenda_a-16-3-42NYALWRAKAP-trdm",
          "participantId": "kisumu-nyalenda_a-16-3-42NYALWRAKAP-trdm",
          "fakename": "42NYALWRAKAP"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608a",
                "display": "Nyalenda A",
                "tagId": "nyalenda_a"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512e9",
                "display": "18-22",
                "tagId": "18_22"
              },
              {
                "_id": "61eec518b4272aec1e3512ea",
                "display": "23-26",
                "tagId": "23_26"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "620f269775b54830963c81e5",
                "display": "Never Married",
                "tagId": "never_married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7d",
                "display": "NA",
                "tagId": "na"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b96cf8c3267c3b8700fb",
                "display": "Working Fulltime",
                "tagId": "working_fulltime"
              },
              {
                "_id": "61eec5ffb4272aec1e3512f1",
                "display": "Working Part Time",
                "tagId": "working_part_time"
              },
              {
                "_id": "620f2a970b3ad13222f79c60",
                "display": "Self Employed",
                "tagId": "self_employed"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba1ff8c3267c3b870101",
                "display": "Graduate and Above",
                "tagId": "graduate_and_above"
              },
              {
                "_id": "620f2ba10b3ad13222f79c64",
                "display": "Enrolled in Undergrad/Diploma",
                "tagId": "enrolled_in_undergrad_diploma"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61e3ba7bf8c3267c3b870105",
                "display": "Christian",
                "tagId": "christian"
              },
              {
                "_id": "61f755c7f966c333c36f60bb",
                "display": "Christian-Protestant",
                "tagId": "christian_protestant"
              },
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              },
              {
                "_id": "620f2cc00b3ad13222f79c65",
                "display": "With partner",
                "tagId": "with_partner"
              },
              {
                "_id": "61e3bcb2f8c3267c3b87010c",
                "display": "With parents",
                "tagId": "with_parents"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "61f10e043408ae7238eb8737",
                "display": "FAM - Calendar",
                "tagId": "fam_calendar"
              },
              {
                "_id": "61f10e043408ae7238eb8738",
                "display": "FAM  - App",
                "tagId": "fam_app"
              },
              {
                "_id": "61eec81bb4272aec1e3512f9",
                "display": "Weekly Pill",
                "tagId": "weekly_pill"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b81ef8c3267c3b8700f2",
                "display": "0",
                "tagId": "0"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f85",
                "display": "NA",
                "tagId": "na"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "629f2949c17382000a1f7e73",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-15T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608a",
                          "display": "Nyalenda A",
                          "tagId": "nyalenda_a"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c421aae5f411da094f79",
        "kp_date_published": "2022-07-17T08:11:13.652Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-nyalenda_a-16-3-42NYALWRAKAPU-ll7r",
          "participantId": "kisumu-nyalenda_a-16-3-42NYALWRAKAPU-ll7r",
          "fakename": "42NYALWRAKAPU"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608a",
                "display": "Nyalenda A",
                "tagId": "nyalenda_a"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512e9",
                "display": "18-22",
                "tagId": "18_22"
              },
              {
                "_id": "61eec518b4272aec1e3512ea",
                "display": "23-26",
                "tagId": "23_26"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "620f269775b54830963c81e5",
                "display": "Never Married",
                "tagId": "never_married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7d",
                "display": "NA",
                "tagId": "na"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b96cf8c3267c3b8700fb",
                "display": "Working Fulltime",
                "tagId": "working_fulltime"
              },
              {
                "_id": "61eec5ffb4272aec1e3512f1",
                "display": "Working Part Time",
                "tagId": "working_part_time"
              },
              {
                "_id": "620f2a970b3ad13222f79c60",
                "display": "Self Employed",
                "tagId": "self_employed"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba1ff8c3267c3b870101",
                "display": "Graduate and Above",
                "tagId": "graduate_and_above"
              },
              {
                "_id": "620f2ba10b3ad13222f79c64",
                "display": "Enrolled in Undergrad/Diploma",
                "tagId": "enrolled_in_undergrad_diploma"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61e3ba7bf8c3267c3b870105",
                "display": "Christian",
                "tagId": "christian"
              },
              {
                "_id": "61f755c7f966c333c36f60bb",
                "display": "Christian-Protestant",
                "tagId": "christian_protestant"
              },
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              },
              {
                "_id": "620f2cc00b3ad13222f79c65",
                "display": "With partner",
                "tagId": "with_partner"
              },
              {
                "_id": "61e3bcb2f8c3267c3b87010c",
                "display": "With parents",
                "tagId": "with_parents"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "61f10e043408ae7238eb8737",
                "display": "FAM - Calendar",
                "tagId": "fam_calendar"
              },
              {
                "_id": "61f10e043408ae7238eb8738",
                "display": "FAM  - App",
                "tagId": "fam_app"
              },
              {
                "_id": "61eec81bb4272aec1e3512f9",
                "display": "Weekly Pill",
                "tagId": "weekly_pill"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b81ef8c3267c3b8700f2",
                "display": "0",
                "tagId": "0"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f85",
                "display": "NA",
                "tagId": "na"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "629f2949c17382000a1f7e73",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-15T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608a",
                          "display": "Nyalenda A",
                          "tagId": "nyalenda_a"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c421aae5f411da094f7e",
        "kp_date_published": "2022-07-17T08:11:13.740Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-nyalenda_a-16-3-42NYALWRAKAPUE-f9pl",
          "participantId": "kisumu-nyalenda_a-16-3-42NYALWRAKAPUE-f9pl",
          "fakename": "42NYALWRAKAPUE"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608a",
                "display": "Nyalenda A",
                "tagId": "nyalenda_a"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b52addd8187bea9029e7",
                "display": "Urban",
                "tagId": "urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512e9",
                "display": "18-22",
                "tagId": "18_22"
              },
              {
                "_id": "61eec518b4272aec1e3512ea",
                "display": "23-26",
                "tagId": "23_26"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "620f269775b54830963c81e5",
                "display": "Never Married",
                "tagId": "never_married"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f7d",
                "display": "NA",
                "tagId": "na"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b96cf8c3267c3b8700fb",
                "display": "Working Fulltime",
                "tagId": "working_fulltime"
              },
              {
                "_id": "61eec5ffb4272aec1e3512f1",
                "display": "Working Part Time",
                "tagId": "working_part_time"
              },
              {
                "_id": "620f2a970b3ad13222f79c60",
                "display": "Self Employed",
                "tagId": "self_employed"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba1ff8c3267c3b870101",
                "display": "Graduate and Above",
                "tagId": "graduate_and_above"
              },
              {
                "_id": "620f2ba10b3ad13222f79c64",
                "display": "Enrolled in Undergrad/Diploma",
                "tagId": "enrolled_in_undergrad_diploma"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61e3ba7bf8c3267c3b870105",
                "display": "Christian",
                "tagId": "christian"
              },
              {
                "_id": "61f755c7f966c333c36f60bb",
                "display": "Christian-Protestant",
                "tagId": "christian_protestant"
              },
              {
                "_id": "6210e1354c22ac176de094dd",
                "display": "NA/Unknown",
                "tagId": "na_unknown"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              },
              {
                "_id": "620f2cc00b3ad13222f79c65",
                "display": "With partner",
                "tagId": "with_partner"
              },
              {
                "_id": "61e3bcb2f8c3267c3b87010c",
                "display": "With parents",
                "tagId": "with_parents"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "61f10e043408ae7238eb8737",
                "display": "FAM - Calendar",
                "tagId": "fam_calendar"
              },
              {
                "_id": "61f10e043408ae7238eb8738",
                "display": "FAM  - App",
                "tagId": "fam_app"
              },
              {
                "_id": "61eec81bb4272aec1e3512f9",
                "display": "Weekly Pill",
                "tagId": "weekly_pill"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b81ef8c3267c3b8700f2",
                "display": "0",
                "tagId": "0"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f85",
                "display": "NA",
                "tagId": "na"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "629f2949c17382000a1f7e73",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-15T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608a",
                          "display": "Nyalenda A",
                          "tagId": "nyalenda_a"
                        }
                      ]
                    },
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed46",
                          "display": "FGD",
                          "tagId": "fgd"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      },
      {
        "kp_published_status": "published",
        "warnings": [],
        "_id": "62d3c41eaae5f411da094f49",
        "kp_date_published": "2022-07-17T08:11:10.852Z",
        "meta": {
          "kp_contributed_by": "61dff841c18e7d9ab3a8365b",
          "kp_content_type": "pKenya"
        },
        "main": {
          "title": "kisumu-obunga-16-3-42OBUWRAChauski-bj5n",
          "participantId": "kisumu-obunga-16-3-42OBUWRAChauski-bj5n",
          "fakename": "42OBUWRAChauski"
        },
        "tags": {
          "locationsKisumu_kenya": {
            "collectionId": "locationsKisumu_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f608b",
                "display": "Obunga",
                "tagId": "obunga"
              }
            ]
          },
          "countys_kenya": {
            "collectionId": "countys_kenya",
            "data": [
              {
                "_id": "61f755c7f966c333c36f607b",
                "display": "Kisumu",
                "tagId": "kisumu"
              }
            ]
          },
          "participantTypes": {
            "collectionId": "participantTypes",
            "data": [
              {
                "_id": "62d4d80fffdc3a498b7a2451",
                "tagId": "wra",
                "display": "WRA"
              }
            ]
          },
          "regions": {
            "collectionId": "regions",
            "data": [
              {
                "_id": "61e3b534ddd8187bea9029e8",
                "display": "Peri-Urban",
                "tagId": "peri_urban"
              }
            ]
          },
          "ageBrackets": {
            "collectionId": "ageBrackets",
            "data": [
              {
                "_id": "61eec518b4272aec1e3512ed",
                "display": "36+",
                "tagId": "36"
              }
            ]
          },
          "maritalStatus": {
            "collectionId": "maritalStatus",
            "data": [
              {
                "_id": "61eec59fb4272aec1e3512f0",
                "display": "Separated/Divorced",
                "tagId": "separated_divorced"
              }
            ]
          },
          "marriageAgeBrackets": {
            "collectionId": "marriageAgeBrackets",
            "data": [
              {
                "_id": "61f1034c6d616b6beedf0f84",
                "display": "36+",
                "tagId": "36"
              }
            ]
          },
          "numberOfChildren": {
            "collectionId": "numberOfChildren",
            "data": [
              {
                "_id": "61e3b824f8c3267c3b8700f5",
                "display": "3",
                "tagId": "3"
              }
            ]
          },
          "firstPregnancyAgeBrackets": {
            "collectionId": "firstPregnancyAgeBrackets",
            "data": [
              {
                "_id": "61f1036e6d616b6beedf0f86",
                "display": "<13",
                "tagId": "13"
              }
            ]
          },
          "occupations": {
            "collectionId": "occupations",
            "data": [
              {
                "_id": "61e3b96cf8c3267c3b8700fb",
                "display": "Working Fulltime",
                "tagId": "working_fulltime"
              }
            ]
          },
          "eduBackgrounds": {
            "collectionId": "eduBackgrounds",
            "data": [
              {
                "_id": "61e3ba0df8c3267c3b870100",
                "display": "Secondary Education",
                "tagId": "secondary_education"
              }
            ]
          },
          "religions": {
            "collectionId": "religions",
            "data": [
              {
                "_id": "61e3ba7bf8c3267c3b870105",
                "display": "Christian",
                "tagId": "christian"
              }
            ]
          },
          "livingSituations": {
            "collectionId": "livingSituations",
            "data": [
              {
                "_id": "620f2ce40b3ad13222f79c66",
                "display": "In a nuclear family",
                "tagId": "in_a_nuclear_family"
              }
            ]
          },
          "sexualStatus": {
            "collectionId": "sexualStatus",
            "data": [
              {
                "_id": "620f2e333b56f332b3c4104d",
                "display": "Currently Sexually Active",
                "tagId": "currently_sexually_active"
              }
            ]
          },
          "ctMethodExperiences": {
            "collectionId": "ctMethodExperiences",
            "data": [
              {
                "_id": "61e3bd8cf8c3267c3b870112",
                "display": "User",
                "tagId": "user"
              }
            ]
          },
          "ctMethodUsageBehaviours": {
            "collectionId": "ctMethodUsageBehaviours",
            "data": [
              {
                "_id": "61eec6d5b4272aec1e3512f3",
                "display": "Hormonal Method User",
                "tagId": "hormonal_method_user"
              },
              {
                "_id": "61eec6d5b4272aec1e3512f4",
                "display": "Non-Hormonal User",
                "tagId": "non_hormonal_user"
              },
              {
                "_id": "61eec6d5b4272aec1e3512f5",
                "display": "Multi Method User",
                "tagId": "multi_method_user"
              }
            ]
          },
          "ctMethodsTried": {
            "collectionId": "ctMethodsTried",
            "data": [
              {
                "_id": "61eec81bb4272aec1e3512f8",
                "display": "Oral Contraceptive Pill",
                "tagId": "oral_contraceptive_pill"
              },
              {
                "_id": "61e3bfae5556707cd708b731",
                "display": "IUD",
                "tagId": "iud"
              },
              {
                "_id": "61eec81bb4272aec1e3512f6",
                "display": "Male Condom",
                "tagId": "male_condom"
              },
              {
                "_id": "61eec81bb4272aec1e3512f7",
                "display": "Injectable",
                "tagId": "injectable"
              }
            ]
          }
        },
        "comments": [],
        "updates": [],
        "__v": 0,
        "taggedResources": {
          "immersion1Kenya": {
            "collectionId": "immersion1Kenya",
            "data": [
              {
                "_id": {
                  "kp_published_status": "published",
                  "_id": "629f1c9a3b4b89000accbfb0",
                  "meta": {
                    "kp_content_type": "immersion1Kenya",
                    "date": "2022-03-15T21:00:00.000Z",
                    "kp_contributed_by": {
                      "avatar": {
                        "userUploaded": []
                      },
                      "_id": "61f7b1f8c7dda14bf6798d61",
                      "profileType": "researchersKenya",
                      "name": "Emmanuel Nandokha",
                      "id": "61f7b1f8c7dda14bf6798d61"
                    }
                  },
                  "tags": {
                    "interviewTypes": {
                      "collectionId": "interviewTypes",
                      "data": [
                        {
                          "_id": "61f756f0c9a493340ce1ed45",
                          "display": "IDI",
                          "tagId": "idi"
                        }
                      ]
                    },
                    "researchersKenya": {
                      "collectionId": "researchersKenya",
                      "data": [
                        {
                          "_id": "61f7b1f8c7dda14bf6798d61",
                          "display": "Emmanuel Nandokha"
                        }
                      ]
                    },
                    "countys_kenya": {
                      "collectionId": "countys_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f607b",
                          "display": "Kisumu",
                          "tagId": "kisumu"
                        }
                      ]
                    },
                    "locationsKisumu_kenya": {
                      "collectionId": "locationsKisumu_kenya",
                      "data": [
                        {
                          "_id": "61f755c7f966c333c36f608b",
                          "display": "Obunga",
                          "tagId": "obunga"
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        }
      }
    ]
  

    export const tableConfig = {
        rowIdPath: "_id",
        expandComp: {
          comp: "ParticipantManagerV3ExpandComp",
        },
        minWidth:'1800px',
        freezeFirstCol: true,
        cellData: [
          {
            colId: "fakename",
            headerDisplay: "Fake Name",
            valuePath: [
              "main.fakename",
             
            ],
            propertyPath: null,
            comp: 'CommaSeparatedWords',
            props: {
              typoComp: 'SANS_2'
            },
            sortConfig: {
              fn: "alphabetical",
              path: [
                "main.fakename",
              
              ],
            },
            defaultSort: true, // means when the table is first rendered, it is sorted from first to last, by this col by default
            backendSortConfig: {
              sort: { 'main.fakename' : 1 },
              defaultSort: true
            },
            
          },
          {
            colId: "makeContact2",
            headerDisplay: "Immersion 2",
            valuePath: [
              "taggedResources.immersion1Kenya.data.0._id.meta.makeContact2",  
            ],
            propertyPath: 'display',
            sortConfig: {
              fn: "alphabetical",
              path: [
                "taggedResources.immersion1Kenya.data.0._id.meta.makeContact2.display",
              ],
            }
          },
          {
            colId: "tagId",
            headerDisplay: "Participant ID",
            valuePath: "main.participantId",
            propertyPath: null,
            sortConfig: {
              fn: "alphabetical",
              path: "main.participantId",
            },
          },
          {
            colId: "county",
            headerDisplay: "County",
            valuePath: [
              "tags.countys_kenya.data.0",
              
            ],
            propertyPath: "display",
            sortConfig: {
              fn: "alphabetical",
              path: [
                "tags.countys_kenya.data.0.display",
              ],
            },
          },
          {
            colId: "county",
            headerDisplay: "County",
            valuePath: [
              "tags.countys_kenya.data.0",
              
            ],
            propertyPath: "display",
            sortConfig: {
              fn: "alphabetical",
              path: [
                "tags.countys_kenya.data.0.display",
              ],
            },
          },
          {
            colId: "county",
            headerDisplay: "County",
            valuePath: [
              "tags.countys_kenya.data.0",
              
            ],
            propertyPath: "display",
            sortConfig: {
              fn: "alphabetical",
              path: [
                "tags.countys_kenya.data.0.display",
              ],
            },
          },
          {
            colId: "location",
            headerDisplay: "Location",
            valuePath: [
              "tags.locationsKisumu_kenya.data.0",
              "tags.locationsNairobi_kenya.data.0",
              
            ],
            propertyPath: "display",
            sortConfig: {
              fn: "alphabetical",
              path: [
                "tags.locationsKisumu_kenya.data.0.display",
              "tags.locationsNairobi_kenya.data.0.display",
              ],
            },
          },
        ],
      }