import styled from "styled-components";

export const StyledTargetWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden; // without this there is a wierd vertical scroll that comes on the child, which is quite un-explainable really..
`;

export const StyledStickyScrollbar = styled.div`
  overflow-x: auto;
  position: fixed;
  z-index: 2; //because if we have a frozen col, then those cells have z-index 1, and we need this to be more than that
  /* why not sticky? to avoid css limitation if a parent has overflow property set + 
   sticky isn't doing us any favors anyways since we hide the bar when reaching the bottom
  */
  bottom: 3px;
  ::-webkit-scrollbar { 
    height: 0.8rem; //for horizontal bars 
  }
`;