import styled, { css } from "styled-components";
import { colors, mediaQuery } from "oolib";
import { Link } from "react-router-dom";

const { black_opacity80, white, greyColor100 } = colors;

const caretCommonCss = css`
  position: absolute;
  cursor: pointer;
  z-index: 99999;
  top: 50%;
`;

export const StyledWrapper = styled.div`
  display: flex;
  user-select: none;
  flex-direction: column;
  
  ${mediaQuery('md')}{
    flex-direction: row;
  }

`
export const StyledCaretLeft = styled.div`
  ${caretCommonCss};
  transform: translateY(-50%);
  left: 1rem;
  
`;

export const StyledCaretRight = styled.div`
  ${caretCommonCss};
  transform: translateY(-50%);
  right: 2rem;
`;


export const StyledModalWrapper = styled.div`
    z-index: 99998;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${black_opacity80} ;
`;

export const StyledModal = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: calc(90svw - 4rem);

    ${mediaQuery('lg')}{
      width: 110rem; 
    }

    ${mediaQuery('xl')}{
      width: 120rem;
    }
`;

export const StyledActionWrapper = styled.div`
    z-index: 10;
    display: flex;
    gap: 1rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
`;

export const StyledSectionTagsWrapper = styled.div`
    height: 80svh;
    overflow-y: auto; 
    background: ${colors.greyColor5};
    border-right: 1px solid ${greyColor100};
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
    width: 100%;
    flex-direction: column;

    ${mediaQuery('lg')}{
      width: 20rem;
      padding: 2rem;
    }
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center;  */
  align-items: center;
  height: 100%;
  background: ${colors.greyColor5};
  width: 100%;
`;


export const StyleContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 80svh;

  ${mediaQuery("lg")}{
    flex-direction: row;
  }
`;


export const StyledLink = styled(Link)`
  height: 100%;
  overflow: hidden;
  border-bottom: 1px solid ${colors.greyColor40};
  
  ${mediaQuery('lg')}{
    width: 100rem;
    border-bottom: none;
  }
  
`


export const StyledTagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const StyledImage = styled.div`
  height: 90%;
  width: 90%;
  display: flex;
  justify-content: center;
  
  ${mediaQuery('lg')}{
    width: 95%;
  }

  ${mediaQuery('xl')}{
    width: 100%;
  }
  
  > div {
    ${mediaQuery('sm')}{
      width: auto;
    }
    width: 100%;
  }
`  