import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import InputTagTypes from "./comps/Input";
import DisplayTagTypes from "./comps/Display";
import { isEndToEndSelection } from "../../../functions/helpers";
import { useRTEContext } from "../../../../../TextEditor/RichTextEditor/RTEContext";
import { useHandleClickOutside } from "oolib";

const StyledWrapper = styled.div`
  position: absolute;
  z-index: 101;
  ${({ mode }) =>
    !mode &&
    css`
      transform: translateY(-100%);
    `}

  ${({ y }) =>
    y &&
    css`
      top: ${y}px;
    `}
  ${({ x }) =>
    x &&
    css`
      left: ${x}px;
    `}
`;




const AnnoLightbox = ({
  editorState,
  onRemove,
  onEdit,
  onCancel,
  onCreate,

  //lexical
  showAnnoLightbox: _showAnnoLightbox = {},
  setShowAnnoLightbox: _setShowAnnoLightbox,
  annotation: _annotation,
  readOnly: _readOnly
}) => {


  const ContainerRef = useRef();
  

  const RTEContext = useRTEContext();

  let data,position,setShowAnnoLightbox,annotation,readOnly;
  
  readOnly = RTEContext.readOnlyProp;
  annotation = RTEContext.annotation
  setShowAnnoLightbox = RTEContext.setShowAnnoLightbox
  data = RTEContext.showAnnoLightbox.data;
  position = RTEContext.showAnnoLightbox.position

  // handleOutside click
  useHandleClickOutside(ContainerRef, setShowAnnoLightbox)

  const { tagTypesConfig, toggleTagTypesConfig} = annotation || {}
  
  /**
   * this function checks if the current selection in the editor, 
   * is an end-to-end one, and if yes, it returns, the 
   * annotation's entity data behind it.
   */
  const endToEndSelectionData = editorState
  ? isEndToEndSelection(editorState)
  : false


  const DEFAULT_INITIAL_STATE = {
    data: {},
    targetFragmentkey: "",
    mode: "",
  };

  const intialLocalState = data || endToEndSelectionData || DEFAULT_INITIAL_STATE;

  const [localData, setLocalData] = useState(intialLocalState);


  

  const handleChange = (tagType, v) => {
    if (v?.data.length) {
      setLocalData((prev) => {
        prev.data[tagType] = v;
        return { ...prev };
      });
    } else {
      setLocalData((prev) => {
        delete prev.data[tagType];
        return { ...prev };
      });
    }
  };

  return (
    
      <StyledWrapper ref={ContainerRef} {...position} mode={localData.mode}>
        {localData.mode === "display" ? (
          <DisplayTagTypes
            data={localData.data}
            onRemove={() => onRemove(localData)}
            onEdit={() => {
              setLocalData((prev) => ({ ...prev, mode: "edit" }));
            }}
            readOnly={readOnly}
          />
        ) : (
          // using this id to get the light box container element, to fix the lightbox hiding when there's no scroll up space
          <div id="input-light-box">
            <InputTagTypes
              tagTypesConfig={tagTypesConfig || []}
              toggleTagTypes={toggleTagTypesConfig}
              onChange={(tagType, v) => handleChange(tagType, v)}
              value={localData.data}
              handleOnConfirm={() =>
                localData.mode === "edit"
                  ? onEdit(localData)
                  : onCreate(localData)
              }
              handleOnCancel={onCancel}
            />
          </div>
        )}
      </StyledWrapper>
    
  );
};

export default AnnoLightbox;
