import styled from "styled-components";

import { colors } from "oolib";

const { greyColor40} = colors;

export const StyledShell = styled.div`
 
  border: ${({readOnly, theme, isSelected }) => !readOnly  && `1px solid ${isSelected ? theme.colors.primaryColor100 : greyColor40}` };
  width: 100%;
  margin: auto;
  /* padding: 1rem; */
  padding: ${({readOnly }) => !readOnly  && `1rem`};
  
  & > * {width: 100%;}

  &::selection{
    background-color: ${colors.none} //this is to hide the ugly blue overlay when this node is selected
  }
  
`;


export const StyledShellHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 1rem;
`;

