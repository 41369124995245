export const defaultUserProfilesSortConfig = [
    {
      display: "A to Z",
      sort: { name: 1 },
      value: "newest",
    },
    {
      display: "Z to A",
      sort: { name: -1 },
      value: "oldest",
    },
  ];