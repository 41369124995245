import { colors } from "oolib"
import { queryClient } from "../../.."
import { getAccessControlEnabledPLBModules } from "../../../pageTpls/PlatformBuilder/config"
import { __CheckAccessToModule, __CheckSomeAccessToSomePlatformBuilderModule, __GetAccessibleContentTypes, __IsAdmin, __IsSuperAdmin } from "../../../utils/accessControl"

export const genMyDashboardMenuOptions = userData => {
    const {deployment : {
      _ShowModerationFunctions, _EnableLearningModule, _EnableParticipantManager
    }} = queryClient.getQueryData('platformConfigs')
  
    let profileSettingsLink =  {
      display: 'Profile Settings', 
      icon: 'GearSix', 
      link: `/my-dashboard/MyProfile`
    }
  
    
  
    let platformBuilderLink =  (
      __CheckSomeAccessToSomePlatformBuilderModule({
        user: userData?.user,
        actions: ['READ', 'UPDATE'],
        modules: getAccessControlEnabledPLBModules().map(m => m.value)
      }) && 
      {
        display: 'Admin Dashboard', 
        icon: 'Wrench', 
        link: `/platformBuilder`,
        // style:{ border: `1px solid ${colors.white}`}
      }
    )
  
    
  
    let myPublishedLink =  { 
      display: 'My Content', 
      link: { 
        pathname: `/my-dashboard/MyContent`, 
      } 
    }
  
    let myDraftsLink = { 
      display: 'Drafts', 
      link: { 
        pathname: `/my-dashboard/MyContent`, 
        search:`?activeTab=draft` 
      } 
    }
  
    let myInModerationLink = { 
      display: 'In Moderation', 
      link: { 
        pathname: `/my-dashboard/MyContent`, 
        search:`?activeTab=inReview` 
      } 
    }
  
    let modDashboardLink = userData?.user &&
      _ShowModerationFunctions &&
      __GetAccessibleContentTypes({user:userData?.user, ACTION:'MODERATE'}).length > 0 &&
      { 
        display: "Review Dashboard", 
        icon: "CheckSquareOffset",
        link: "/my-dashboard/ModQueue" 
      }
    
    let myLearningModulesLink = userData?.user && 
      _EnableLearningModule &&
      __CheckAccessToModule(userData?.user, 'READ', 'learningModules') &&
      { 
        display: "My Learning Modules", 
        icon: "Cards",
        link: "/my-dashboard/MyLearningModules" 
      }
  
      let learnerMonitoring = _EnableLearningModule &&
      __CheckAccessToModule(userData?.user, 'READ', 'learnerMonitoring') &&
      { 
        display: "Learner Monitoring", 
        icon: "MagnifyingGlass",
        link: "/my-dashboard/LearnerMonitoring" 
      }
  
      let participantManager = _EnableParticipantManager && 
      {
          display: 'Participant Manager',
          icon: 'Cards',
          link: '/my-dashboard/ParticipantManager',
      }
  
      
                            
    
       
    return [
      {
        // id : 'myContent',
        // title : 'My Content',
        links : [
          ...([myPublishedLink].filter(Boolean)),
          // ...([myDraftsLink].filter(Boolean)),
          // ...([myInModerationLink].filter(Boolean)),
        ]
      },
      {
        id: 'myAccount',
        links : [
          ...([profileSettingsLink].filter(Boolean)),
        ]
      },
      {
        id : 'primaryLinks',
        links : [
          ...([myLearningModulesLink].filter(Boolean)),
          ...([learnerMonitoring].filter(Boolean)),
          ...([participantManager].filter(Boolean)),
        ]
      },
      {
        id: 'advancedDashboards',
        links : [
          ...([modDashboardLink].filter(Boolean)),
          ...([platformBuilderLink].filter(Boolean))
        ]
      }
    ].filter(d => d.links.length > 0);
  }