import React from "react";
import { useCreateTpl } from "../../../../../../utils/react-query-hooks/tci";
import { useOutOfTheBoxForm } from "../../../../../ContributeTpl/utils/useOutOfTheBoxForm";
import { ModalForms, LoaderOverlay, useBannerContext } from "oolib";

import { BlockWrapperStyled, FormWrapperStyled } from "./styled";

import { tagCategoryFormConfig, tagsFormConfig } from "./config";
import { __GetContentTypeConfigNew } from "../../../../../../utils/getters/gettersV2";
import { useCreateAndPublishMultipleContent } from "../../../../../../utils/react-query-hooks/contributions/useCreateAndPublishMultipleContent";
import { queryClient } from "../../../../../..";

const CreateTagCategoryAndTags = ({
  onClose,
  onDone,
  tagCategory: tagCategoryProp,
}) => {
  const createTpl = useCreateTpl();
  const createTags = useCreateAndPublishMultipleContent();
  const { SET_ALERT_BANNER } = useBannerContext();

  const handleOnSave = async (formData) => {
    const { kp_content_type, tags = [], general } = formData;

    // if tagCategoryProp exists then don't create the tpl again
    if (!tagCategoryProp) {
      createTpl.mutate(
        {
          kp_content_type,
          category: "groupsStyle1",
          tpl: {
            general,
          },
          content: tags.map((tag) => ({
            main: { title: tag.value },
            meta: { kp_content_type },
          })),
          action: "CREATE_AND_PUBLISH",
        },
        {
          onError: () => {
            SET_ALERT_BANNER({
              color: "red",
              msg: "Something went wrong!",
              timeOut: 3000,
            });
            onClose()
          },
          onSuccess: () => {
            SET_ALERT_BANNER({
              color: "green",
              msg: "Tag category and tags created successfully!",
              timeOut: 3000,
            });
            onDone && onDone({ tagCategory: kp_content_type, tags });
            onClose()
          },

        }
      );
    } else {
      //create bulk content
      createTags.mutate(
        {
          data: {
            content: tags.map((tag) => ({
              main: { title: tag.value },
              meta: {  },
            })),
          },
          contentType: tagCategoryProp,
        },
        {
          onError: () => {
            SET_ALERT_BANNER({
              color: "red",
              msg: "Tags creation failed!",
              timeOut: 3000,
            });
          },
          onSuccess: () => {
            queryClient.invalidateQueries("contentListing", [tagCategoryProp])
            SET_ALERT_BANNER({
              color: "green",
              msg: "Tags created succefully!",
              timeOut: 3000,
            });
          },

          onSettled: () => onClose(),
        }
      );
    }
  };

  const { FORM, SUBMIT_BUTTON } = useOutOfTheBoxForm({
    onSubmit: handleOnSave,
    content: {
      general: { segment: "collections" }
    },
    configs: tagCategoryProp
      ? tagsFormConfig
      : tagCategoryFormConfig,
    FormWrapper: FormWrapperStyled,
    blockWrapper: BlockWrapperStyled,
    buttonConfig: {
      props: { children: "Save" },
    },
  });

  return (
    <ModalForms
      title={tagCategoryProp ? "Add New Tags" : "Create New Tag Category"}
      onClose={onClose}
      SUBMIT_BUTTON={SUBMIT_BUTTON}
    >
      {createTpl.isLoading ? (
        <LoaderOverlay loaderText={"Creating..."} />
      ) : null}
      <div>{FORM}</div>
    </ModalForms>
  );
};

export default CreateTagCategoryAndTags;
