
/**
 * basically for a given selection, we want to 
 * the lightbox to be rendered below the selection &
 * starting at the center point of the selection
 * 
 */
export const getAnnoLightboxPosition = ({editor}) => {
    const domSelection = window.getSelection();
    const editorHTMLNode = editor.getRootElement();
    if(domSelection.rangeCount === 0) return ({x: 0, y: 0})
    let selectionRect = domSelection.getRangeAt(0).getBoundingClientRect();
    let editorRect = editorHTMLNode && editorHTMLNode.getBoundingClientRect();
    const centerXOfSelection = selectionRect.x + selectionRect.width / 2;
    return ({
      x: centerXOfSelection - editorRect.x,
      y: selectionRect.bottom - editorRect.top + 5, //5px offset just to give a bit of a gap
    }) 
  }