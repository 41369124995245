import { OnChangePlugin as LexicalOnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import React, { useEffect } from "react";
import { useAnnoContextLex } from "../AnnoPlugin/context";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNodeByKey } from "lexical";


export const OnChangePlugin = ({id, parentOnChange}) => {

    const {annoData} = useAnnoContextLex()
    const [editor] = useLexicalComposerContext()
    /**
     * filter out the unused annodata, since unused anno data is only 
     * needed by the internal undo-redo logic, which the parent comp
     * doesnt have to care about
     */
    const filterOutUnusedAnnoData = (annoData) => {
        let annoDataToSend;
        if(annoData){
            annoDataToSend = Object.keys(annoData).reduce(
                (a,b) => {
                    if(annoData[b].IS_UNUSED){
                        return a
                    }else{
                        return ({
                            ...a,
                            [b]: annoData[b]
                        })
                    }
                },
                {}
            )
        }
        return annoDataToSend
    }

    const getAllText = () => {
        let allText = ''
        editor.getEditorState().read(() => {
            const rootNode = $getNodeByKey("root");
            allText = rootNode.getTextContent();
        })
    
        return allText
    } 

    function onEditorStateChange(editorState) {
        // editorState.read(() => {
        //     console.log({sel: $getSelection()})
        // })
        const valuePayload = {
            allText: getAllText(),
            isLexical: true, //useful for diffenciating between draftjs & lexical, since both will have to exist in OKF at the sametime
            editorState: editorState.toJSON(),
            annoData: filterOutUnusedAnnoData(annoData)
        }
        
        // console.log({valuePayload})
        parentOnChange && parentOnChange(id, valuePayload);
      }
    
      /**
       * the way lexical is designed, it would be quite an anti pattern to try and
       * store anno data within the editorstate. Hence the 'shape' of our LexicalTextEditor
       * value will be a bit different from the rest of our tplBlocks. It will have to 
       * be: { editorState: <EditorState>, annoData: <AnnoData> }
       * 
       */
      useEffect(() => {
        if(parentOnChange && !annoData?.disableParentOnChange){
            parentOnChange(id, {
                allText: getAllText(),
                isLexical: true, //useful for diffenciating between draftjs & lexical, since both will have to exist in OKF at the sametime
                editorState: editor.getEditorState().toJSON(),
                annoData: filterOutUnusedAnnoData(annoData)
            })
        }
        
      },[JSON.stringify(annoData), editor]) 
      // v.imp annoData is strigified else we go into endless loop. 
      // its not very optimum if annoData becomes big, but we dont have 
      // a better solution for now

    return (
        <LexicalOnChangePlugin ignoreSelectionChange={true} onChange={onEditorStateChange}/>
    )
}