import { convertToRaw, EditorState, RichUtils, SelectionState } from "draft-js";
import { injectHttps } from "oolib";

export const RTEInsertLink = ({ url, editorState, handleChange }) => {
  const selectionState = editorState.getSelection();

  const contentState = editorState.getCurrentContent();

  let contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", {
    url: injectHttps(url),
  });

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  let newEditorState = EditorState.push(
    editorState,
    contentStateWithEntity,
    'apply-entity'
  );

  console.log({ NEW: convertToRaw(newEditorState.getCurrentContent()) });

  newEditorState = RichUtils.toggleLink(
    newEditorState,
    selectionState,
    entityKey
  );

  console.log({ NEW2: convertToRaw(newEditorState.getCurrentContent()) });

  //get the focus of cursor to the end of the word/sentence just converted to link
  newEditorState = EditorState.forceSelection(
    newEditorState,
    new SelectionState({
      anchorKey: selectionState.getEndKey(),
      anchorOffset: selectionState.getEndOffset(),
      focusKey: selectionState.getEndKey(),
      focusOffset: selectionState.getEndOffset(),
    })
  );

  handleChange(newEditorState, { firedFrom: "RTEInsertLink" });
};
