import { colors, SANS_2, SANS_3, transition, ellipsis } from "oolib"
import styled, { css } from "styled-components"

export const StyledContainer = styled.div`
display: flex;
/* border: 1px solid ${colors.greyColor10}; */
min-height: 4rem;
${transition('background-color')};
${({theme, editable}) => editable &&  css`
  background-color: ${colors.greyColor5};
  border: 1px solid ${theme.colors.primaryColor100}
` };
`

export const StyledDragHandleWrapper = styled.div`
padding: 1rem 1rem;
cursor: grab;
&:active{
  cursor: grabbing;
}
display: flex;
align-items: center;
max-height: 4rem;
`
export const StyledContentWrapper = styled.div`
padding: 1.2rem 1rem 0 0;
flex: 1 1 auto;
min-width: 0;
`

export const StyledIconAndTitleWrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`

export const StyledTitleWrapper = styled.div`
    min-width: 0;
`

export const StyledTitle = styled(SANS_3)`
    ${ellipsis};
    line-height: 16px;
    position: relative;
  
    ${({onClickExists}) => onClickExists && css`
    
    &:after{
      ${transition('width')};
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: black;
    }
    `}
`

export const StyledTitleInput = styled.input`
border: none;
background-color: ${colors.none};
height: 1.6rem;
  &::placeholder{
    color: ${colors.greyColor40}
  }
`

export const StyledRightActionsWrapper = styled.div`
    max-height: 4.1rem;
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
`

export const StyledMoreContentWrapper = styled.div`
  padding-top: 0.7rem;
  padding-bottom: 1.25rem;
`

export const StyledSubtitle = styled(SANS_2)` 
  line-height: 1em;
`