import { OKELink, SANS_2, TextLoader } from "oolib";
import React from "react";
import styled from "styled-components";

import { useIsThisLinkActive } from "../../../../Header/PrimaryHeaderV2/HeaderUI/utils/useIsThisLinkActive";
import { useGetDisplayForLandingPageLinks } from "../../../../utils/useGetDisplayForLandingPageLinks";

const StyledLi = styled.li`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const FooterLink = ({
  to,
  display: _display,
  thisNavConfig,
  linkType,
  loading,
  onClick,
  icon,
}) => {
  const { isThisLinkActive } = useIsThisLinkActive();
  const { display, TextLoader } = useGetDisplayForLandingPageLinks({
    thisNavConfig,
    _display,
  });
  const genDisplay = () =>
    TextLoader ? (
      <TextLoader />
    ) : (
      <StyledLi isActive={isThisLinkActive({ to })}>
        <SANS_2>
          <OKELink link={to} invert={true} icon={icon}>
            {display}
          </OKELink>
        </SANS_2>
      </StyledLi>
    );

  return genDisplay();
};
