import styled from "styled-components";
import { colors } from "oolib";

import { positionMark } from "./utils";

const {white} = colors;

const StyledCoachmark = styled.div`
  background-color: ${white};
  padding: 2rem;
  max-width: 320px;
  border-radius: 6px;
  position: ${({position})=> !position || position === 'center'? 'relative': 'absolute'};

 
  ${({position, targetElm})=> {
    if (position) {
      const targetElmCoords = targetElm.getBoundingClientRect();
      const positionArgs = position.split(' ');

      return positionMark(targetElmCoords, positionArgs);
    } else return '';
  }}
  
`;

const arrowOrientation = {
  left: -90,
  right: 90,
  top: 0,
  bottom: 180
}

const StyledMarkArrow = styled.div`
  position: absolute;
  border-width: 13px;
  border-style: solid;
  border-color: ${white} transparent transparent transparent;
  transform: ${({position})=> `rotate(${arrowOrientation[position.split(' ')[0]]}deg)`};

  ${({position, targetElm})=> {
    const targetElmCoords = targetElm.getBoundingClientRect();
    const positionArgs = position.split(' ');

    return positionMark(targetElmCoords, positionArgs, arrowOrientation[positionArgs[0]]);
  }}

`;


export {StyledCoachmark, StyledMarkArrow}