import { EditorState, RichUtils} from 'draft-js';
import { replaceText } from 'draft-js/lib/DraftModifier';


const {toggleBlockType} = RichUtils;

const applyListShortcut =({blockText, editorState, handleChange, selectionState})=>{
	// the next 2 vars is to remove what the user added already 1[\.-] || - (smoother, no leftovers)
	const newContentState = replaceText(
		editorState.getCurrentContent(),
		selectionState.merge({focusOffset: 0, isBackward: true, hasFocus: true}),
		"",
		editorState.getCurrentInlineStyle()
	)
	let newEditorState = EditorState.push(
		editorState,
		newContentState,
		'remove-range'
	)
	//const listType = (/-|*/.test(blockText) === "-"? "un": "" )+"ordered-list-item"
	newEditorState = toggleBlockType(newEditorState, (/^[-*]/.test(blockText)? "un": "" )+"ordered-list-item");

	handleChange(newEditorState)
}

export default applyListShortcut;