import React from 'react'
import { Redirect, Route, useLocation, withRouter } from 'react-router-dom'
import { useGetQueryState } from '../../../utils/react-query-hooks/general'
import Loader from 'oolib';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const location = useLocation()
	let { status } = useGetQueryState('userData')

	return (
		<Route
			{...rest}
			render={(props) =>
				status === 'loading' ? (
					<Loader debug={'private route'} />
				) : status === 'error' ? (
					<Redirect
						to={{
							pathname: '/login',
							state: {
								from: location.pathname + location.search,
								authType: 'login',
							},
						}}
					/>
				) :  status === 'success' && (
					<Component {...props} />
				)
			}
		/>
	)
}

export default withRouter(PrivateRoute)
