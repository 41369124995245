import { colors } from "oolib";
import { useState } from "react";

  export const useResizeDataTableColumns = ({
    setColWidthConfig,
    colWidthConfig,
    theme,
    builderProps
  }) => {

    const [colResizeState, setColResizeState] = useState(undefined)

  const dragZoneWidth = 5;


  const handleResizeColumn = (e) => {
    e.preventDefault()
    e.stopPropagation();
    if(colResizeState){
      setColWidthConfig(prev => {
        return ({
          ...prev,
          [colResizeState.colId]: (colResizeState.initWidth + (e.clientX - colResizeState.mousePos)) + 'px'
        })
      })
    }  
  }


  const resetDragZoneIndicator = e => {
    if(colResizeState || e.currentTarget.id !== e.target.id) return;
    e.currentTarget.style.borderRight = `1px solid ${colors.greyColor10}`
    e.currentTarget.style.cursor = 'pointer'
  }

  const isInDragZone = e => {
    let mouseX = e.clientX;
    let {right: colRightEdge} = e.currentTarget.getBoundingClientRect();
    return mouseX > colRightEdge-dragZoneWidth;
  }

  const initColResizeState = (e, d) => {
    e.preventDefault()
    e.stopPropagation();
    if(isInDragZone(e)){
      setColResizeState({
        mousePos: e.clientX,
        initWidth: parseInt(colWidthConfig[d.colId].replace('px', '')),
        colId: d.colId
      });
    }
   
  }

  const hideShowDragZoneIndicator = e => {
    e.preventDefault()
    /** currentTarget is the element that this event is bound to */
    if(e.currentTarget.id !== e.target.id) return  //very important, else the indicator shows in some cases, when its not supposed to. we don't completely understand why...
    if(!colResizeState){
      if(isInDragZone(e)){
        e.currentTarget.style.borderRight = `1px solid ${theme.colors.primaryColor100}`
        e.currentTarget.style.cursor = 'col-resize'
      }else{
        e.currentTarget.style.borderRight = `1px solid ${colors.greyColor10}`
        e.currentTarget.style.cursor = 'pointer'
      }
    } 
  }

  const resetColResizeState = e => {
    setColResizeState(undefined);
    if(builderProps?.updateColWidthsInTableConfig){
      builderProps.updateColWidthsInTableConfig(colWidthConfig)
    }
    
  }

  return ({
    resetColResizeState,
    hideShowDragZoneIndicator,
    initColResizeState,
    resetDragZoneIndicator,
    handleResizeColumn,
    colResizeState,
    dragZoneWidth
  })
  }