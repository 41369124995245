import React from "react";
import { useState } from "react";
import { useMutation } from "react-query";
import { FeedbackStyled, ThumbsDownStyled, ThumbsUpStyled } from "./styled";
import { ButtonPrimary, SANS_2, TextInput, icons } from "oolib";
import axios from "axios";
import { queryClient } from "../../../../..";

const { ThumbsUp,ThumbsDown } = icons;

const useUpdateFeedback = () => {
  return useMutation(
    ({ reaction, comments, messageId }) =>
      axios.put("/api/embeddings/feedback", { reaction, comments, messageId }),
    {
      onSuccess: (res) => {
        console.log({ onSuccess: res });
        queryClient.invalidateQueries("chat", res.data.userId);
      },
    }
  );
};

export const Feedback = ({ reaction = "", comments = "", messageId }) => {
  const [userReaction, setUserReaction] = useState(reaction);
  const { mutate: feedback, status } = useUpdateFeedback();

  return (
    <FeedbackStyled
      style={{
        display: "flex",
        gap: "1rem",
        width: "100%",
        alignItems: "center",
      }}
    >
      <ThumbsUpStyled
        active={userReaction === "positive"}
        onClick={() => {
          setUserReaction("positive");
          feedback({ messageId, reaction: "positive" });
        }}
      >
        <ThumbsUp
          color={userReaction === "positive" ? "white" : "#808080"}
          size={20}
          setShowInput
        />
      </ThumbsUpStyled>

      <ThumbsDownStyled
        active={userReaction === "negative"}
        onClick={() => {
          setUserReaction("negative");
          feedback({ messageId, reaction: "negative" });
        }}
      >
        <ThumbsDown
          color={userReaction === "negative" ? "white" : "#808080"}
          size={20}
        />
      </ThumbsDownStyled>

      {userReaction === "negative" && !comments ? (
        <Input
          status={
            status === "loading" || status === "error" ? status : undefined
          }
          comments={comments}
          onSubmit={(comments) => feedback({ messageId, comments })}
        />
      ) : null}
      {userReaction === "positive" || comments  ? (
        <SANS_2>Thanks for your feedback!</SANS_2>
      ) : null}
    </FeedbackStyled>
  );
};

const Input = ({ onSubmit, comments, status }) => {
  const [value, setValue] = useState(comments);
  const handleOnChange = (id, val) => {
    setValue(val);
  };
  return (
    <div style={{ display: "flex", gap: ".5rem", width: "100%" }}>
      <div style={{ flex: "1" }}>
        <TextInput
          style={{ width: "100%" }}
          value={value}
          onChange={handleOnChange}
          S
          placeholder="Tell us what went wrong..."
        />
      </div>

      <ButtonPrimary disabled={status} S onClick={() => onSubmit(value)}>
        {status || "Submit"}
      </ButtonPrimary>
    </div>
  );
};
