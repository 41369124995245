
import ReactGA from 'react-ga4'




export const trackEventGA = (category, action, label) => {

   ReactGA.event({      
      category:category,
      action:action,
     ...(label)&&{label},  
    })
  }

