import React from "react";

const Tooth = (props) => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle cx="21" cy="21" r="21" fill="#EDEFF1"/>
    <mask id="path-2-inside-1" fill="white">
      <path d="M24.8239 11.0026C23.8175 11.0026 23.1284 11.3246 22.4621 11.6359C21.869 11.9133 21.3088 12.175 20.5455 12.1753C19.7819 12.175 19.2217 11.9133 18.6286 11.6359C18.0312 11.3568 17.4136 11.071 16.5672 11.0146C16.4553 11.0008 16.3558 10.9966 16.2699 11.0028H16.2672C13.3629 11.0028 11 13.3441 11 16.2222C11 17.5019 11.4476 18.5182 11.8806 19.5013C12.291 20.4331 12.6787 21.3131 12.7267 22.4233C12.7368 22.6571 12.7458 22.9022 12.755 23.1551C12.8714 26.3322 13.0308 30.6836 15.9902 30.9947C16.0257 30.9982 16.0602 31 16.0945 31C17.1155 30.9998 17.5418 29.5175 18.0352 27.8015C18.5765 25.918 19.1903 23.7831 20.5311 23.7411L20.5374 23.7406L20.5618 23.7411C21.901 23.7831 22.5146 25.918 23.0559 27.8015C23.5488 29.5178 23.9747 31 24.9959 31C25.03 31 25.0652 30.9982 25.1002 30.9944C28.0601 30.6833 28.2193 26.3318 28.3357 23.1544C28.3449 22.9015 28.3539 22.6566 28.364 22.4231C28.4119 21.3128 28.7997 20.4328 29.2101 19.5013C29.6431 18.5184 30.0909 17.5019 30.0909 16.2222C30.0909 13.3439 27.7282 11.0026 24.8239 11.0026Z"/>
    </mask>
    <path d="M24.8239 11.0026C23.8175 11.0026 23.1284 11.3246 22.4621 11.6359C21.869 11.9133 21.3088 12.175 20.5455 12.1753C19.7819 12.175 19.2217 11.9133 18.6286 11.6359C18.0312 11.3568 17.4136 11.071 16.5672 11.0146C16.4553 11.0008 16.3558 10.9966 16.2699 11.0028H16.2672C13.3629 11.0028 11 13.3441 11 16.2222C11 17.5019 11.4476 18.5182 11.8806 19.5013C12.291 20.4331 12.6787 21.3131 12.7267 22.4233C12.7368 22.6571 12.7458 22.9022 12.755 23.1551C12.8714 26.3322 13.0308 30.6836 15.9902 30.9947C16.0257 30.9982 16.0602 31 16.0945 31C17.1155 30.9998 17.5418 29.5175 18.0352 27.8015C18.5765 25.918 19.1903 23.7831 20.5311 23.7411L20.5374 23.7406L20.5618 23.7411C21.901 23.7831 22.5146 25.918 23.0559 27.8015C23.5488 29.5178 23.9747 31 24.9959 31C25.03 31 25.0652 30.9982 25.1002 30.9944C28.0601 30.6833 28.2193 26.3318 28.3357 23.1544C28.3449 22.9015 28.3539 22.6566 28.364 22.4231C28.4119 21.3128 28.7997 20.4328 29.2101 19.5013C29.6431 18.5184 30.0909 17.5019 30.0909 16.2222C30.0909 13.3439 27.7282 11.0026 24.8239 11.0026Z" fill="#E3F2FD" stroke="#399EFF" stroke-width="2" mask="url(#path-2-inside-1)"/>
  </svg>
);

export default Tooth;
