import { colors, transition, mediaQuery } from "oolib";
import styled, { css } from "styled-components";


const tooltipMove = '20px';
const tooltipHeight = '8px';
const tooltipShift = `calc(-2 * ${tooltipHeight})`;

export const StyledCoachMark = styled.div`
    /* border: 1px solid ${colors.greyColor10}; */
    box-sizing: border-box;
    position: fixed;
    z-index: 9999998;
    top: 0;
    left: 0;
    bottom: 0;
    filter: blur(3px);
    right: 0;
    box-shadow: 0px 0px 0px 5000px ${colors.black_opacity50};
    border-radius: 10px;
    ${transition('all')};

    ${({ dimension }) =>
      dimension &&
      css`
        top: ${dimension.top}px;
        left: ${dimension.left}px;
        height: ${dimension.height + 10}px;
        width: ${dimension.width + 10}px;
      `}
`


export const StyledTooltip = styled.div`
  box-sizing: content-box;
  position: fixed;
  z-index: 9999999;
  max-width: 300px;
  background-color: #ffffffdb;
  /* padding: ${tooltipMove}; */
  padding: 1.5rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  pointer-events: all !important;
 ${transition('all')};

  &::before {
    content: "";
    border-top-width: ${tooltipHeight};
    border-left-width: ${tooltipHeight};
    border-right-width: ${tooltipHeight};
    border-bottom-width: ${tooltipHeight};
    border-style: solid;
    background: transparent;
    border-color: #ffffffdb;
    position: absolute;
  }

  ${({ placement }) =>
    (placement === 'left' || placement === "right-corner") &&
    css`
      &::before {
        top: ${tooltipMove};
        right: ${tooltipShift};
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent;
      }
    `}

  ${({ placement }) =>
    placement === 'bottom' &&
    css`
      &::before {
        top: ${tooltipShift};
        left: ${tooltipMove};
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
      }
    `}

  ${({ placement }) =>
    placement === 'top' &&
    css`
      &::before {
        bottom: ${tooltipShift};
        left: ${tooltipMove};
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
      }
    `}

  ${({ placement }) =>
    (placement === 'right' || placement === "left-corner") &&
    css`
      &::before {
        left: ${tooltipShift};
        top: ${tooltipMove};
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    `}

  ${({ placement }) =>
     placement === 'bottom-right-top' &&
    css`
      ${mediaQuery('lg')}{
        transform: translate(-250px, 0px);
        min-width: 300px;
      }
      transform: translateX(-150px);
      min-width: 200px;
      &::before {
        ${mediaQuery('lg')}{
          transform: translate(250px, 0px);
        }
        transform: translateX(150px);
        bottom: ${tooltipShift};
        left: ${tooltipMove};
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
      }
   `}

  ${({ placement }) =>
     placement === 'top-right-bottom' &&
    css`
      ${mediaQuery('lg')}{
        transform: translateX(-200px);
        min-width: 300px;
      }
      transform: translateX(-180px);
      min-width: 250px;
      &::before {
        ${mediaQuery('lg')}{
          transform: translateX(200px);
        }
        transform: translateX(230px);
        top: ${tooltipShift};
        left: ${tooltipMove};
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
      }
   `}
`

export const StyledHeading = styled.div`

`

export const StyledContent = styled.div`

  padding: 0.5 0;

`

export const StyledActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    min-width: max-content;
`