import { throttle } from 'lodash'
import { indicatorThickness } from '../styled';

export const handleDragOver = throttle(
  (clientY, id, currentTarget, [dragState, setDragState], options = {}) => {
    //needed for touch drag. check the elementFromPoint fn. in index.js
    if (!currentTarget.classList.contains('dropzone')) return

    let { dragElemId, dragElem_draggableInstanceId } = dragState
    const {draggableInstanceId} = options;
    let parentContainerTop = options.parentContainerTop || 0
    let mouseY = clientY

    let { top, height } = currentTarget.getBoundingClientRect()

    const isWhere = mouseY < top + height / 2 ? 'above' : 'below'
    if (dragElemId) {
      setDragState((prev) => ({
        ...prev,
        indicatorStyle:
          dragElemId !== id
            ? {
                top:
                  isWhere === 'above'
                    ? `${top - (indicatorThickness/2) - parentContainerTop}px`
                    : `${top + height + (indicatorThickness/2) - parentContainerTop}px`,
                display: 'block',
              }
            : { display: 'none' },
        isWhere,
        draggedOnElemId: id,
        dragElem_draggableInstanceId, //id of the DraggableComponent that the dragElem belongs to
        draggedOn_draggableInstanceId: draggableInstanceId  //id of the DraggableComp that contains the element that is being dragged over
      }))
    }
  },
  500
)
