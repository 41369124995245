import React, { useState, Fragment } from "react";
import {
	ButtonSecondary,
	TextInput,
	PasswordInput,
	EmailInput,
	URLInput,
	NumberInput,
	PhoneInput,
	getBlockLabelProps,
	injectHttps
} from 'oolib';

import ContentBlockShell from '../../generalUI/ContentBlockShell';
import {replaceEntityData} from '../../../utils/richInputUtils'

import { BlockLabel } from "oolib";

const KPLikeContactChannelsBlock = (props) => {
	//col settings must be the bootstrap codes

	const {
		fieldsData,
		id,
		className,
		value,
		readOnly,
		onChange,
		invert,
		isInEditor,
		editor,
		entityKey,
		block,
		handleSetTempReadOnly,
	} = props

	/*NOTE:
	 * can use the property 'showText : true' against each object in field data,
	 * if we want to expose the text string and not just show icon
	 * eg. for emails and phonenumbers
	 */

	const defaultFieldsData = [
		{
			id: 'twitter',
			icon: 'TwitterLogo',
			placeholder: 'enter twitter handle',
		},
		{
			id: 'facebook',
			icon: 'FacebookLogo',
			placeholder: 'enter facebook link',
		},
		{
			id: 'linkedin',
			icon: 'LinkedinLogo',
			placeholder: 'enter linkedin link',
		},
		{
			id: 'youtube',
			icon: 'YoutubeLogo',
			placeholder: 'enter youtube link',
		},
		{
			id: 'instagram',
			icon: 'InstagramLogo',
			placeholder: 'enter instagram link',
		},
	]

	const inputTypeCorrespondant = {
		"text": TextInput,
		"password": PasswordInput,
		"email": EmailInput,
		"url": URLInput,
		"number": NumberInput,
		"tel": PhoneInput
	};
	let fieldsDataToUse = fieldsData || defaultFieldsData

	const createEmptyValObj = (data) => {
		let valObj = {}
		data.map((d) => (valObj[d.id] = ''))
		return valObj
	}

	const initVal = value || createEmptyValObj(fieldsDataToUse)

	const [_value, _setValue] = useState(initVal)

	const handleChange = (k, v) => {
		let newVal = { ..._value, [k]: v }
		_setValue(newVal)

		onChange && onChange(id, newVal)
		if (isInEditor === true) {
			replaceEntityData(editor, entityKey, { value: newVal })
		}
	}

	const genInputComp = () => (
		<div className='kp-like-contact-channels-input-group'>
			{fieldsDataToUse.map((d) => {
				const InputToRender = inputTypeCorrespondant[d.input_type || 'url']
				return (
					<InputToRender
						id={d.id}
						icon={d.icon}
						size='small'
						className='kp-like-contact-channels-input'
						placeholder={d.placeholder}
						onChange={(k, v) => handleChange(k, v)}
						value={value && value[d.id]}
					/>
				)
			})
			}
		</div>
	)

	const genDisplayComp = () => (
		<div className='kp-like-contact-channels--display'>
		
			<div className='kp-like-contact-channels--display__icons-group'>
				{fieldsDataToUse.map((d) => {
					let _value = d.value || (value && value[d.id])
					return (
						<Fragment>
							{_value && (
								<ButtonSecondary
									icon={d.icon}
									invert={invert}
									className={`socilaLinkIcon--button ${
										d.showText ? '-show-icon-' : ''
									}`}
									onClick={() => {
										window.open(
											d.link_prefix ? d.link_prefix + _value : injectHttps(_value),
											'_blank',
										)
									}}
								/>
							)}
						</Fragment>
					)
				})}
			</div>
		</div>
	)

	return (
		<ContentBlockShell
			richInputProps={{
				isInEditor,
				editor,
				block,
				handleSetTempReadOnly,
			}}
			readOnly={readOnly}
			className={`${className}`}
			id={id}>
			<div className={`kp-like-contact-channels`}>
				<BlockLabel {...getBlockLabelProps(props)} />
				{readOnly ? genDisplayComp() : genInputComp()}
			</div>
		</ContentBlockShell>
	)
}

KPLikeContactChannelsBlock.defaultProps = {};

KPLikeContactChannelsBlock.propTypes = {};


export default KPLikeContactChannelsBlock;
