import React from "react";
import TableElem from "../../components/cards/TableElem";

const ExpandComp = ({ parentRowRawData }) => {
  //because this is used as an expand comp inside the table, it will automatically get the row id

  const thisRowData = parentRowRawData;

  /**
   * d.taggedResources wont exist only in some cases when we are constantly
   * erasing db data and potentially forgetting to delete participantIds
   *
   */ 
  const tableReadyData =
    thisRowData?.taggedResources &&
    Object.values(thisRowData.taggedResources)
      .map((d) => {
        return (d.data || []).map(dd => {
          /**
           * if getData in participantManagerv3 uses lookup,
           * then contentD = dd,
           * else if it is a find that happens on the backend 
           * then contentD = dd._id
           */
          let contentD = dd._id; 
          if(!contentD) return undefined //if the content has been deleted, a null would be returned by dd._id

          let publishedStatusForTable = contentD.kp_published_status === 'published'
            ? contentD.editPublishedDocId ? `Under Edit By Author (${contentD.meta.kp_contributed_by?.name})` : `Published`
            : contentD.kp_published_status === 'underModeration'
              && `Under Review (${contentD.kp_moderator.name})`
              
          return ({ ...contentD, publishedStatusForTable})
        })
        .filter(Boolean)
        .filter(dd => ["published", "underModeration"].indexOf(dd.kp_published_status) !== -1)
        
      }).reduce((a,b) => [...a,...b], [])
      ;

  const tableConfig = {
    rowIdPath: "_id", // this is id of the interview content type
    link: {
      urlString: "/published-page",
      idPath: ["meta.kp_content_type", "_id"],
      idAs: ["param", "query"],
      target: '_blank'
    },
    cellData: [
      {
        colId: 'interviewName',
        headerDisplay: "Interview Name",
        valuePath: "meta.kp_content_type",
        propertyPath: null, 
        comp: "TableLink",
        props: {
          typoComp: "SANS_2",
          to: {
            urlString: "/published-page",
            idPath: ["meta.kp_content_type", "_id"],
            idAs: ["param", "query"],
            target: '_blank'
          },
        },
      },
      {
        colId: "status",
        headerDisplay: "Publish Status",
        valuePath: "publishedStatusForTable",
        propertyPath: null,
        comp: "StatusTag",
        width: 300,
        props: {
          statusTypeMap: {
            neutral: [],
            neutral2: [],
            success: ["Published"],
            danger: [],
          },
        },
      },
      {
        colId: 'interviewers',
        headerDisplay: "Interviewers",
        valuePath: [
          "tags.researchersKenya.data.0",
          "tags.researchersNigeria.data.0",
          "tags.researchersSenegal.data.0",
        ],
        propertyPath: "display",
        comp: "TableLink",
        props: {
          typoComp: "SANS_2",
          to: {
            urlString: "/profile/userProfiles",
            idPath: [
              "meta.kp_contributed_by.profileType",
              "meta.kp_contributed_by._id",
            ],
            idAs: ["param", "param"],
          },
        },
      },
      {
        colId: 'dateOfInterview',
        headerDisplay: "Date Of Interview",
        valuePath: "meta.date",
        propertyPath: null,
        comp: "DateDisplay",
        props: {
          textComp: "SANS_2",
        },
      }
    ],
  };

  return (
    <div>
      {tableReadyData && tableReadyData.length > 0 && (
        <TableElem
          data={tableReadyData}
          tableConfig={tableConfig}
        />
      )}
    </div>
  );
};

export default ExpandComp;
