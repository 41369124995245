import { line, area, curveMonotoneX, pie, arc } from 'd3-shape'
import { scaleLinear, scaleTime, scaleOrdinal, scaleBand } from 'd3-scale'
import { axisBottom, axisLeft, axisRight, axisTop } from 'd3-axis'
import { timeParse } from 'd3-time-format'
/**
 * @note
 * mouse is deprecated d3 6 onnwards. read : https://observablehq.com/@d3/d3v6-migration-guide#pointer
 */
import { entries } from 'd3-collection';
import { select, selectAll, mouse, event } from 'd3-selection'
import { transition } from 'd3-transition'
import { extent, max, min, bisector } from 'd3-array'
import { format } from 'd3-format'
import { geoMercator, geoPath } from 'd3-geo'




export default {
	line: line,
	area: area,
	scaleLinear: scaleLinear,
	scaleTime: scaleTime,
	scaleBand: scaleBand,
	select: select,
	selectAll: selectAll,
	format: format,
	pie: pie,
	arc: arc,
	curveMonotoneX: curveMonotoneX,
	timeParse: timeParse,
	extent: extent,
	min: min,
	max: max,
	bisector: bisector,
	mouse: mouse,
	get event() { return event; }, //read here for explanation of this wierdness: https://stackoverflow.com/questions/40012016/importing-d3-event-into-a-custom-build-using-rollup/40048292#40048292
	axisBottom,
	axisLeft,
	axisRight,
	axisTop,
	geoMercator,
	geoPath,
	transition,
	scaleOrdinal,
	entries
}
