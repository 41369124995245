export const setElementDimensions = ({element, setDimension}) => {
    if (!element) return;
        const rect = element?.getBoundingClientRect();
        if (!rect || (rect.top === 0 && rect.bottom === 0)) return;
        setDimension({
            height: rect.height,
            width: rect.width,
            left: rect.left - 5,
            right: window.innerWidth - rect.right - 5,
            top: rect.top - 5,
            bottom: window.innerHeight - rect.bottom - 5,
        });
};