import { VideoInput } from "oolib";
import React from "react";
import { DecoratorNodeShell } from "../comps/DecoratorNodeShell";

export const VideoInputNodeComp = ({
  //props for comp
  id,
  files,
  value,
  multiple,

  //props for shell
  nodeKey,

  //handlers
  onChange,
  onRemove,

  readOnly
}) => {
  
  /**
   * in RTE Video component url is stored similar to object 
   * structure for both extrenal url (embeded) and uploaded video 
   * (publicUrl in object array)
   */
  const isUploadedVideo = value => value && Object.keys(value?.[0]).length > 1 

  return (
    <DecoratorNodeShell
      nodeKey={nodeKey}
      onRemove={onRemove}
      actions={
        value && !isUploadedVideo(value)
          ? [
              {
                icon: "LinkSimple",
                display: "Edit URL",
                onClick: () => {
                  const actualReplaceButton =
                    document.getElementById(`${id}_replaceUrl`);
                  actualReplaceButton.click();
                  // setShowLinkOrEmbedModal({mode: 'embed'})
                },
              },
            ]
          : [
              {
                icon: "UploadSimple",
                display: "Re-upload Video",
                onClick: () => {
                  const actualReuploadButton =
                    document.getElementById(`${id}_uploadNewVideo`);
                  actualReuploadButton.click();
                },
              },
            ]
      }
    >
      <VideoInput
        isInRTE={true}
        id={id}
        files={files}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
    </DecoratorNodeShell>
  );
};
