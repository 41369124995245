import React, {Fragment} from "react";
import uuid from 'uuid'
import KPRichInlineImage from '../../comps/KPRichInlineImage';
import KPResourceUpload from '../../../DEPRECATED_KPResourceUpload';
import KPPDFDisplay from '../../../DEPRECATED_KPPDFDisplay';
import KPFAQComp from '../../../KPFAQComp';
import KPCardLinksDisplay from '../KPCardLinksDisplay';
import KPLikeContactChannelsBlock from '../../../KPLikeContactChannelsBlock';
import KPPPTEmbed from '../../../DEPRECATED_KPPPTEmbed';
import AudioEmbed from '../../../DEPRECATED_AudioEmbed';
import KPVideoEmbed from "../../../DEPRECATED_KPVideoEmbed";

import { ButtonPrimary, TextInput } from 'oolib';

const KPRichInlineBlockRenderer = props => {

  const Components = {
    TextInput,
    KPRichInlineImage,
    KPResourceUpload,
    KPCardLinksDisplay,
    KPPDFDisplay,
    KPPPTEmbed,
    KPVideoEmbed,
    KPFAQComp,
    AudioEmbed,
    ButtonPrimary,
    KPLikeContactChannelsBlock
  }

  const entityKey = props.block.getEntityAt(0); //automatically passed by renderAtomicBlock function
  
  let entity, Component, compProps;
  if(entityKey){
    entity = props.contentState.getEntity( entityKey ); //automatically passed by renderAtomicBlock function
    
    Component = Components[entity.getType()]; //automatically passed by renderAtomicBlock function
    compProps = {...entity.getData(), ...props.blockProps};
  }
  
  //props.blockProps === returned Object property 'props'. this is also preset by draftjs
  //entity.getData is === the last object that is passed into the createAtomicBlockEntity function. eg: { src: "", loading: true }

  return (
    <Fragment>
    { Component &&
      <Component
        block = {props.block}
        isInEditor = {true}
        entityKey = {entityKey}
        {...compProps}
        id = {uuid.v4()} //just ensuring every block rendered has a unique id. important for blocks containing file uploaders to work properly
        /> }
    </Fragment>
    
  )
};

export default KPRichInlineBlockRenderer;
