import { DropdownMulti, DropdownSingle } from "oolib";
import React from "react";



export { DropdownMulti } from 'oolib';
export { DropdownSingle } from "oolib";


export const Dropdown = (props) => {
 
  //backwards compatibility
  if(props.variant === 'single'){
    return <DropdownSingle {...props}/>
  }

  if(
    props.variant === 'combobox-single' ||
    props.variant === 'combobox--single'
  ){
    return <DropdownSingle {...props} isSearchable/>
  }

  if(props.variant === 'multi'){
    return <DropdownMulti {...props}/>
  }

  if(
    props.variant === 'combobox-multi' ||
    props.variant === 'combobox--multi'
  ){
    return <DropdownMulti {...props} isSearchable/>
  }

  if(props.variant === 'tags'){
    return <DropdownMulti {...props} isTagsStyle/>
  }

}


export const ComboboxSingleLanguage = (props) => {

  return <DropdownSingle 
    {...props} 
    optionsFn = {{ fn: 'getLanguagesArray'}} 
    isSearchable
    />;
};

export const DropdownSingleLanguage = (props) => {

  return <DropdownSingle 
    {...props} 
    optionsFn = {{ fn: 'getLanguagesArray'}} 
    />;
};