import React from "react";
import * as Comps from "../../../CONFIG_PARSER/TableCellBlocks";
import { getVal } from 'oolib';
import { blockIsPopulatedConditions } from '../../../../../../utils/validation/blockIsPopulatedConditions';
import { StyledRowCellContentWrapper, StyledTableRowCell } from "../../../styled";
import { useTheme } from "styled-components";


const TableCell = ({
    tableConfig,
    colConfig,
    data : tableRowData,
    rowId,
    colWidthConfig,
    handleCellChange,
    cellIdx,
    rowIdx
}) => {

  const { tableElem_handleCellChangeFns } = useTheme() || {}
  /**
   * in the future, this might be controlled by a state
   * if we bring in row editing functionalities.
   */
  const readOnly = true;


  const genCell = (colConfig) => {
    const genCellComp = (colConfig) => {
        let compName = colConfig.comp || "TableText";
        
        let props = {
          readOnly,
          tableRowData,
          ...(colConfig.props || {}),
          value: colConfig.valuePath
            ? getVal(tableRowData, colConfig.valuePath) &&
            getVal(getVal(tableRowData, colConfig.valuePath), colConfig.propertyPath || null)
            : // we do this cuz we need to find the final property that has a 'displayable' string to show in the dom
            colConfig.value
            ? colConfig.value
            : undefined
        };

        if(!blockIsPopulatedConditions({comp: compName}, props.value)){
          compName = 'TableText'
          props = { value: '-' }
        }

        let Comp = Comps[compName];
        
        
  
        return (
          
          <Comp
            {...props}
            id={rowId+colConfig.colId}
            /**
             * handleCellChange can directly be passed as a function prop to
             * TableElem or, it can be passed as tableConfig.handleCellChangeFn into the 
             * tableConfig, and this will work only if the same named function is made available
             * in OolibThemeProvider
             */
            { ...(
              handleCellChange
              ? {onChange: (k,v) => {
                handleCellChange(tableRowData, colConfig, v)
              }}
              : (tableConfig.handleCellChangeFn &&
                tableElem_handleCellChangeFns &&
                tableElem_handleCellChangeFns[tableConfig.handleCellChangeFn] )
                ? {onChange: (k,v) => {
                  tableElem_handleCellChangeFns[tableConfig.handleCellChangeFn](tableRowData, colConfig, v)
                }}
                : {}
            )}
            // onChange={(k,v) => {
            //   handleCellChange(tableRowData, colConfig, v)
            // }}
          />
          
        );
      };
      return (
        <StyledTableRowCell
          freezeFirstCol={tableConfig.freezeFirstCol}
          cellIdx={cellIdx}
          rowIdx={rowIdx}
          style={{
            width: colWidthConfig[colConfig.colId],
          }}
          >
            {colConfig.subColsConfig ? (
              <div style={{display: 'flex', height: '100%'}}>
                {colConfig.subColsConfig.map((dd) => (
                  <StyledRowCellContentWrapper
                    style={{width: `${100 / colConfig.subColsConfig.length}%`,}}
                    >
                    {genCellComp(dd)}
                  </StyledRowCellContentWrapper>
                ))}
              </div>
            ) : (
              <StyledRowCellContentWrapper>
                {genCellComp(colConfig)}
              </StyledRowCellContentWrapper>
              
            )}
          
        </StyledTableRowCell>
      );
  }

  return genCell(colConfig);
};

export default TableCell;
