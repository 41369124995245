import { useCallback } from "react";
import {useState} from "react"
import { useUpdateCoachmarkChecklist } from "../../../utils/react-query-hooks/users";


export const useCoachmark =({coachmarks, compIdentifier = ''})=> {
  
  const [elmsAttachedMarks, setElmsAttachedMarks] = useState([]);
  const [activeMarkIdx, setActiveMarkIdx] = useState(0);
  const [lastShownMark, setLastShownMark] = useState(0);



  const setElmToMark =(node, idx)=> {
    if (idx instanceof Array) {
      idx.forEach(i=> coachmarks[i].element = node);
    } else {
      coachmarks[idx].element = node;
    }

    if (coachmarks.every(mark=> mark.element instanceof Element)){
      setElmsAttachedMarks([...coachmarks])
    }
  };

  const useReliableRef =(idx, dep = null)=> {
    return useCallback((node)=> setElmToMark(node, idx), dep?[dep]:[])
  }


  const showNextMark =(condition = true)=> {
    if (condition) {
      if (activeMarkIdx + 1 === elmsAttachedMarks.length){
        removeCoachmark()
      }else {
        setActiveMarkIdx((activeMarkIdx === -1? lastShownMark:activeMarkIdx) + 1) // in case the mark was hidden temporarily if the condition isn't met 
      }
    } else {
      setLastShownMark(activeMarkIdx)
      setActiveMarkIdx(-1)
    }
  }
  const updateCoachmarkList = useUpdateCoachmarkChecklist();
  
  // const {_Branch} = useGetQueryData('platformConfigs');

  const removeCoachmark =()=> {
		if (process.env.REACT_APP_ENV !== 'dev') {
      updateCoachmarkList.mutate({property: compIdentifier, value: true})
    }
		setActiveMarkIdx(-1)
	}
  

  return {
    activeMark: elmsAttachedMarks[activeMarkIdx],
    rank: {
      order: `${activeMarkIdx + 1}/${elmsAttachedMarks.length}`,
      isLast: activeMarkIdx === elmsAttachedMarks.length-1
    },
    removeCoachmark,
    showNextMark,
    useReliableRef
  }

}