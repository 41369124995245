import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React from "react";
import { ToolbarDropdownButton } from "../comps/ToolbarDropdownButton";
import { config } from "./config";
import { handleClick } from "./handleClick";
import { ToolbarButton } from "../comps/ToolbarButton";

export const ListTools = ({activeNodeInfo}) => {
  const [editor] = useLexicalComposerContext();

  const checkIsActive = ({listType, activeNodeInfo}) => {
    return listType === activeNodeInfo?.__listType
  }

  return (
    <>
      <ToolbarDropdownButton
        isActive={config.options.some((conf) =>
          checkIsActive({listType: conf.listType, activeNodeInfo})
        )}
        icon={config.icon}
        noOfChildButtons={config.options.length}
        genChildButtons={setOpen => config.options.map((conf) => {
          const { listType } = conf;
          const isActive = checkIsActive({listType, activeNodeInfo})
          return (
            <ToolbarButton
              isActive={isActive}
              onClick={() => {
                handleClick({ isActive, listType, editor })
                setOpen(false)
              }}
              icon={conf.icon}
            />
          );
        })}
      />
    </>
  );
};
