import React from "react";
import { genLinksConfigAsOptions } from "../../../HamburgerMenuV2/utils/genLinksConfigAsOptions";
import { PRIMLink } from "../comps/PRIMLinkAndLoginLink";
import { PRIMMenu } from "../comps/PRIMMenu";
import {
  checkAccessAndGenContentLandingPageLinkProps,
  checkAccessAndGenContentPageLinkProps,
  checkAccessAndGenProfilePageLinkProps,
} from "../utils/genPageLinkProps";
import { getViewableOptionsBySegment } from "../utils/getViewableOptionsBySegment";
import { getViewableProfileTypeOptions } from "../utils/getViewableProfileTypeOptions";

export const PRIMLinksGenerator = ({ _Nav, builderProps }) => {
  return _Nav.primary.map((item, idx) => {
    switch (true) {
      //first lets deal with the presets
      case item.preset === "collections":
        return (
          <PRIMMenu
            display={item.display || "Collections"}
            id={item.display || "preset_collections"}
            key={item.value || "preset_collections"}
            options={getViewableOptionsBySegment({ segment: "collections" })}
          />
        );

      case item.preset === "staticPages":
        return (
          <PRIMMenu
            display={item.display || "Pages"}
            id={item.display || "preset_staticPages"}
            key={item.value || "preset_staticPages"}
            options={getViewableOptionsBySegment({ segment: "staticPages" })}
          />
        );

      case item.preset === "publishing":
        return (
          <PRIMMenu
            display={item.display || "Content"}
            id={item.display || "preset_publishing"}
            key={item.value || "preset_publishing"}
            options={getViewableOptionsBySegment({ segment: "publishing" })}
          />
        );

      case item.preset === "profileTypes":
        return (
          <PRIMMenu
            display={item.display || "People"}
            id={item.display || "preset_people"}
            key={item.value || "preset_people"}
            options={getViewableProfileTypeOptions()}
          />
        );

      case !!item.folder || !!item.menu:
        let thisFolder = item.folder || item.menu
        return (
          <PRIMMenu
            key={item.value}
            id={item.display}
            display={item.display}
            options={genLinksConfigAsOptions({primary: thisFolder})}
            builderProps={builderProps}
            />
        );

      case item.type === "page" && !!item.contentType:
        const contentPageLinkProps = checkAccessAndGenContentPageLinkProps(item.contentType);
        if(!contentPageLinkProps) return null;
        return (
          <PRIMLink
            id={item.display || item.value} /** Id is v. important for coachmark to work */
            key={item.value}
            {...contentPageLinkProps}
          />
        );

      case item.type === "page" && !!item.profileType:
        return (
          <PRIMLink
            id={item.display || item.value}
            key={item.value}
            {...checkAccessAndGenProfilePageLinkProps(item.profileType)}
          />
        );

      case item.type === "landingPage" && !!item.contentType:
        return (
          <PRIMLink
            id={item.display || item.value}
            key={item.value}
            thisNavConfig={item}
            {...checkAccessAndGenContentLandingPageLinkProps({contentType: item.contentType, _id: item._id})}
          />
        );

      default: //statically defined link i.e. { display, to, accessControl }
        return <PRIMLink {...item} key={item.value} id={item.display || item.value} />;
    }
  });
};
