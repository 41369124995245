import React from "react";
import { _Locale } from "../../locale/Locale";
import {
  SERIF_8_9,
  ActionMenu,
  ButtonCustom,
  ButtonPrimary,
  ButtonSecondary
} from "oolib";
import { StyledCTA, StyledPageCover, StyledPageCoverContentWrapper1, StyledPageCoverContentWrapper2, STYLED_SANS_3 } from "./styled";

const PageCover = (props) => {
  
	let ButtonComps = {
    ButtonCustom,
    ButtonPrimary,
    ButtonSecondary,
  };

  const genButton = (btn) => {
    
    const { text, action, comp, icon } = btn;
    //if text is undefined then don't show the button
    if(!text) return null;
    let Comp = comp ? ButtonComps[comp] : ButtonCustom;
    return (
      <StyledCTA
        as={Comp}
        icon={icon || "Plus"}
        invert={true}
        className="PageCover__cta"
        onClick={() => action()}
        value={text}
      />
    );
  };

  return (
    <StyledPageCover className={props.className || ''} style={props.style}>
      <StyledPageCoverContentWrapper1>
        <StyledPageCoverContentWrapper2>
          <SERIF_8_9 invert={true}>
            {_Locale(props.coverHeadline)}
          </SERIF_8_9>
          <STYLED_SANS_3 invert={true}>
            {_Locale(props.coverSubheadline)}
          </STYLED_SANS_3>
          {props.btn && genButton(props.btn)}
          {props.actionMenu && (
            <div style={{ marginTop: "2rem" }}>
              <ActionMenu {...props.actionMenu} invert={true} />
            </div>
          )}
        </StyledPageCoverContentWrapper2>
      </StyledPageCoverContentWrapper1>
    </StyledPageCover>
  );
};

export default PageCover;
