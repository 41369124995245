import {
  makeArrayFromLength,
  ButtonGhost,
  DropdownSingle,
  colors,
} from "oolib";
import React from "react";
import { convertTagDocsDataToOptions } from "../../../utils";

export default function Dropdown(props) {
  const {
    handleCreateTagOnKeyDown,
    handleOnChange,
    createTagText,
    setCreateTagText,
    fetchTagStatus,
    setShowOptionsState,
    isSearchable,
    isTagsStyle,
    displayCompStyle,
    optionConfig,
    value,
    options,
    tagCategory,
    filteredOptions,
    tagCreateIsEnabled,
    createTagStatus,
    handleCreateTag,
    readOnly,
    observerRef,
    isFetchingNextPage
  } = props;

  const genSkeletonLoaderOptions = () => makeArrayFromLength(8).map((d) => {
    return { loading: true };
  })

  return (
    <div onKeyDown={handleCreateTagOnKeyDown}>
      <DropdownSingle
        {...props}
        observerRef={observerRef}
        broadcastShowOptions={(bool) => setShowOptionsState(bool)}
        displayCompStyle={displayCompStyle}
        isSearchable={isSearchable}
        isTagsStyle={isTagsStyle}
        onChange={(k, v) => handleOnChange(k, v)}
        value={value}
        readOnly={readOnly}
        options={
          fetchTagStatus === "loading"
            ? genSkeletonLoaderOptions()
            : fetchTagStatus === "error"
            ? [{ display: "error loading options", value: "error" }]
            : fetchTagStatus === "success" &&
              [
                ...(convertTagDocsDataToOptions(options, tagCategory, optionConfig) || []),
                ...(isFetchingNextPage ? genSkeletonLoaderOptions() : [])
              ]
        }
        searchString={createTagText}
        setSearchString={setCreateTagText}
        setFilteredOptions={(ops) => {
          if (ops && ops.length !== filteredOptions.current.length)
            filteredOptions.current = ops;
        }}
        genCreateTagButton={(options) => {
          return (
            tagCreateIsEnabled &&
            createTagText &&
            options.every((op) => op.display !== createTagText) && (
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: colors.white,
                }}
              >
                <ButtonGhost
                  style={{ width: "100%", justifyContent: "flex-start" }}
                  disabled={createTagStatus === "loading"}
                  children={
                    createTagStatus === "loading"
                      ? "Creating..."
                      : `Create ${createTagText}`
                  }
                  icon="Plus"
                  iconSize="S"
                  onClick={(e) => {
                    handleCreateTag(e);
                  }}
                />
              </div>
            )
          );
        }}
      />
    </div>
  );
}
