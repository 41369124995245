import React from "react";
import { UserMessageStyled } from "./styled";
import { SANS_3 } from "oolib";

export const UserMessage = ({ message }) => {
  return (
    <UserMessageStyled key={message.id}>
      <SANS_3>{message.content.value}</SANS_3>
    </UserMessageStyled>
  );
};
