import { EditorState, Modifier, SelectionState } from "draft-js";
import { getBreakPoint, SANS_2, useHandleClickOutside, useScreenWidth, OptionsSingle } from "oolib";
import React, { useRef } from "react";
import { useRTEContext } from "../../RTEContext";
import { useGetCursorPosRelativeToEditor } from "../../utils/useGetCursorPosRelativeToEditor";
import { createAtomicBlockForEmbedType } from "../LinkOrEmbedModal/utils/createAtomicBlockForEmbedType";
import { useGetEmbedTypeFromUrl } from "../LinkOrEmbedModal/utils/useGetEmbedTypeFromUrl";

export const PastedLinkToEmbedLightbox = ({
  id,
  editorState,
  handleChange,
  EditorRef,
}) => {
  const options = [
    { display: "Create Embed", value: true },
    { display: "Keep as Link", value: false },
  ];

  const { showConvertToEmbedLightbox, setShowConvertToEmbedLightbox } =
    useRTEContext();

  const compRef = useRef(null);
  const screenWidth = useScreenWidth();
  useHandleClickOutside(compRef, setShowConvertToEmbedLightbox, {
    enabled: screenWidth >= getBreakPoint("sm"),
  });

  const { pos } = useGetCursorPosRelativeToEditor({ EditorRef });

  //we get the embed type here itself, instead of in RTEEmbed,
  //so that we might be able to update the lightbox text in the future
  //if we need to
  const { embedType, loading: embedTypeLoading } = useGetEmbedTypeFromUrl(
    showConvertToEmbedLightbox.url
  );

  const handleClick = (v) => {
    if (v === false) setShowConvertToEmbedLightbox(undefined);
    else {
      let thisBlockKey = editorState.getSelection().getStartKey();
      let thisContentBlock = editorState
        .getCurrentContent()
        .getBlockForKey(thisBlockKey);
      const removeSelection = new SelectionState({
        anchorKey: thisBlockKey,
        anchorOffset: 0,
        focusKey: thisBlockKey,
        focusOffset: thisContentBlock.getText().length,
      });

      let newContentState = Modifier.removeRange(
        editorState.getCurrentContent(),
        removeSelection,
        "forward"
      );
      let newEditorState = EditorState.push(
        editorState,
        newContentState,
        "remove-range"
      );

      createAtomicBlockForEmbedType({
        editorState: newEditorState,
        handleChange,
        embedType,
        urlString: showConvertToEmbedLightbox.url,
      });

      setShowConvertToEmbedLightbox(undefined);
    }
  };

  return (
    <div
      ref={compRef}
      style={{
        ...(screenWidth >= getBreakPoint("sm")
          ? {
              position: "absolute",
              ...pos,
            }
          : {}),
      }}
    >
      {embedTypeLoading ? (
        <SANS_2>{"Loading..."}</SANS_2>
      ) : (
        <div>
          {/* works but some css issues need sorting out. */}
          <OptionsSingle
            {...{
              options,
              showOptions: true,
              handleHideOptions: () => setShowConvertToEmbedLightbox(false),
              handleSelect: (op) => handleClick(op.value),
              enableUseSetYOrientation: false,
            }}
          />
          {/* {options.map((op) => (
        <ButtonGhost onClick={() => handleClick(op.value)}>
          {op.display}
        </ButtonGhost>
      ))} */}
        </div>
      )}
    </div>
  );
};
