import { Container, Wrapper700, PaddingTopBottom20, DatePicker, DateRangePicker, DateTimePicker, DateTimeRangePicker} from "oolib";
import React, { useState } from "react";


export const DateComps = () => {
  const [dp, setDp] = useState(undefined);
  const [dtp, setDtp] = useState(undefined)
  const [drp, setDrp] = useState(undefined);
  const [dtrp, setDtrp] = useState(undefined)

  console.log({
    dp,
    dtp,
    drp,
    dtrp
  })

  const config = [
    {
      comp: DatePicker,
      props: {
        value: dp,
        onChange: (k,v) => setDp(v)
      }
    },
    {
      comp: DateTimePicker,
      props: {
        value: dtp,
        onChange: (k,v) => setDtp(v)
      }
    },
    {
      comp: DateRangePicker,
      props: {
        value: drp,
        onChange: (k,v) => setDrp(v)
      }
    },
    {
      comp: DateTimeRangePicker,
      props: {
        value: dtrp,
        onChange: (k,v) => setDtrp(v)
      }
    }
  ]

  return (
    <Container>
      <Wrapper700>
      {config.map(obj => {
        const Comp = obj.comp;
        return (
        <PaddingTopBottom20>
          <Comp
            {...obj.props}
            />
        </PaddingTopBottom20>
      )})}
      </Wrapper700>
    </Container>
  );
};
